import { t, Trans } from '@lingui/macro';
import { formatDecimal, groupBy } from '@luminovo/commons';
import {
    CenteredLayout,
    colorSystem,
    Flexbox,
    MaxWidthLayout,
    Message,
    NonIdealState,
    Text,
} from '@luminovo/design-system';
import { Box, LinearProgress } from '@mui/material';
import { ImporterRow } from '../../../../types';
import { useUniversalImporter } from '../../context';
import { TablePreview } from './TablePreview';

export function StepPreview({ setStepIndex }: { setStepIndex: (index: number) => void }): JSX.Element {
    const { state } = useUniversalImporter();
    const { importerTable } = state;
    const failedRows = importerTable?.getRows().filter((r) => r.import?.success === false) ?? [];

    if (!importerTable) {
        return (
            <CenteredLayout>
                <NonIdealState title={t`Import failed`} description={t`Go back to the previous step and try again.`} />
            </CenteredLayout>
        );
    }

    const progressPercentage =
        (importerTable.getRows().filter((row) => row.import).length /
            importerTable.getRows().filter((row) => !!row.record).length) *
        100;

    const rows = importerTable.getRows();

    return (
        <MaxWidthLayout sx={{ position: 'relative', height: '100%' }}>
            <Flexbox flexDirection={'column'} height={'100%'} sx={{ gap: 4 }}>
                {progressPercentage > 0.01 && (
                    <LinearProgress
                        sx={{ position: 'absolute', left: 0, top: 0, width: '100%' }}
                        variant="determinate"
                        value={progressPercentage}
                    />
                )}

                {failedRows.length > 0 && <MessageBoxErrors rows={failedRows} setStepIndex={setStepIndex} />}

                <Flexbox flexDirection={'column'} gap="16px" height={'100%'}>
                    {rows.length > 0 && <TablePreview rows={rows} />}
                </Flexbox>
            </Flexbox>
        </MaxWidthLayout>
    );
}

function getTopErrors(rows: ImporterRow[]) {
    const grouped = groupBy(rows, (row) => {
        if (!row.import) return '';
        if (row.import.success !== false) return '';
        return row.import.message.slice(0, 15);
    });

    return Object.entries(grouped)
        .filter((x) => x[0].length > 0)
        .sort((a, b) => b[1].length - a[1].length)
        .slice(0, 10);
}

function MessageBoxErrors({
    rows,
    setStepIndex,
}: {
    rows: ImporterRow[];
    setStepIndex: (index: number) => void;
}): JSX.Element {
    const { dispatch } = useUniversalImporter();
    const topErrors = getTopErrors(rows);
    return (
        <Message
            attention="high"
            size="large"
            variant="yellow"
            title={t`Import failed`}
            message={
                <Box
                    sx={{
                        maxHeight: '30vh',
                        overflowY: 'auto',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                        width: 'fit-content',
                    }}
                >
                    {t`Some rows could not be imported. These are the most common errors:`}

                    {topErrors.length > 0 && (
                        <Box
                            sx={{
                                display: 'grid',
                                gap: '16px 16px',
                                gridTemplateColumns: '1fr auto ',
                                alignItems: 'center',
                                width: 'fit-content',
                            }}
                        >
                            <Text
                                style={{ position: 'sticky', top: 0, background: colorSystem.yellow[1] }}
                                variant="body-semibold"
                            >
                                <Trans>Example error</Trans>
                            </Text>
                            <Text
                                style={{ position: 'sticky', top: 0, background: colorSystem.yellow[1] }}
                                variant="body-semibold"
                            >
                                <Trans>Count</Trans>
                            </Text>
                            {topErrors.map(([_, count]) => {
                                const example = count[0];
                                const message = (example?.import?.success === false && example.import.message) || '';
                                return (
                                    <>
                                        <Text style={{ textAlign: 'left' }}>{message}</Text>
                                        <Text style={{ textAlign: 'right' }}>{formatDecimal(count.length)}</Text>
                                    </>
                                );
                            })}
                        </Box>
                    )}
                </Box>
            }
            action={{
                label: t`Restart import`,
                onClick: () => {
                    dispatch({ type: 'restartImport' });
                    setStepIndex(1);
                },
            }}
        />
    );
}
