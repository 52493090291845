import { getToken } from '@luminovo/auth';
import { http, UserDriverDetailsBulkPatchDTO, UserDriverDetailsPatchDTO } from '@luminovo/http-client';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDebugErrorHandler } from '../http/debugErrorHandler';
import { httpQueryKey } from '../http/httpQueryKey';

type DriverErrorHandler = (
    error: unknown,
    variables: { update: UserDriverDetailsPatchDTO; driverId: string },
    context: unknown,
) => unknown;

export const useMutationUpdateDriver = (errorHandler?: DriverErrorHandler) => {
    const queryClient = useQueryClient();
    const debugErrorHandler = useDebugErrorHandler();
    const onError = errorHandler ?? debugErrorHandler;
    const token = getToken();

    return useMutation({
        mutationFn: ({ driverId, update }: { driverId: string; update: UserDriverDetailsPatchDTO }) => {
            return http(
                'PATCH /user-drivers/:driverId',
                {
                    requestBody: update,
                    pathParams: { driverId },
                },
                token,
            );
        },
        onSuccess: async (data) => {
            await Promise.allSettled([
                queryClient.invalidateQueries({ queryKey: httpQueryKey('GET /user-drivers') }),
                queryClient.invalidateQueries({
                    queryKey: httpQueryKey('GET /user-drivers/:driverId', { pathParams: { driverId: data.data.id } }),
                }),
            ]);
        },
        onError,
    });
};

export const useMutationBulkUpdateDrivers = () => {
    const queryClient = useQueryClient();
    const debugErrorHandler = useDebugErrorHandler();
    const token = getToken();

    return useMutation({
        mutationFn: ({ updates }: { updates: UserDriverDetailsBulkPatchDTO }) => {
            return http('PATCH /user-drivers', { requestBody: updates }, token);
        },
        onSuccess: async (response) => {
            await Promise.allSettled([queryClient.invalidateQueries({ queryKey: httpQueryKey('GET /user-drivers') })]);
        },
        onError: debugErrorHandler,
    });
};
