import { MessageDescriptor } from '@lingui/core';
import { defineMessage } from '@lingui/macro';
import {
    NegotiationLineItemStatus,
    QuoteRequestLineItemAssignmentReason,
    QuoteRequestLineItemStatus,
    QuoteRequestStatus,
} from '@luminovo/http-client';
import { LeadTimeUnit } from './model/LeadTimeUnit';
export const quoteRequestStatusTranslations: Record<QuoteRequestStatus, MessageDescriptor> = {
    [QuoteRequestStatus.NotSent]: defineMessage({ message: 'Not sent' }),
    [QuoteRequestStatus.Sent]: defineMessage({ message: 'Sent' }),
    [QuoteRequestStatus.Overdue]: defineMessage({ message: 'Overdue' }),
    [QuoteRequestStatus.Discarded]: defineMessage({ message: 'Discarded' }),
    [QuoteRequestStatus.Received]: defineMessage({ message: 'Received' }),
};

export const quoteRequestLineItemStatusTranslations: Record<QuoteRequestLineItemStatus, MessageDescriptor> = {
    [QuoteRequestLineItemStatus.NotSent]: defineMessage({ message: 'Not sent' }),
    [QuoteRequestLineItemStatus.Sent]: defineMessage({ message: 'Sent' }),
    [QuoteRequestLineItemStatus.Overdue]: defineMessage({ message: 'Overdue' }),
    [QuoteRequestLineItemStatus.Received]: defineMessage({ message: 'Quoted' }),
    [QuoteRequestLineItemStatus.NoBid]: defineMessage({ message: 'No bid' }),
    [QuoteRequestLineItemStatus.Discarded]: defineMessage({ message: 'Discarded' }),
    [QuoteRequestLineItemStatus.Awarded]: defineMessage({ message: 'Awarded' }),
};

export const negotiationLineItemStatusTranslations: Record<NegotiationLineItemStatus, MessageDescriptor> = {
    [NegotiationLineItemStatus.NoQuoteRequests]: defineMessage({ message: 'No quote requests' }),
    [NegotiationLineItemStatus.NotSent]: defineMessage({ message: 'Not sent' }),
    [NegotiationLineItemStatus.Sent]: defineMessage({ message: 'Sent' }),
    [NegotiationLineItemStatus.PartiallyReceived]: defineMessage({ message: 'Partially quoted' }),
    [NegotiationLineItemStatus.Received]: defineMessage({ message: 'Quoted' }),
    [NegotiationLineItemStatus.NoBid]: defineMessage({ message: 'No bid' }),
    [NegotiationLineItemStatus.Awarded]: defineMessage({ message: 'Awarded' }),
};

export const quoteRequestLineItemAssignmentReasonTranslations: Record<
    QuoteRequestLineItemAssignmentReason,
    MessageDescriptor
> = {
    [QuoteRequestLineItemAssignmentReason.ManuallyAdded]: defineMessage({ message: 'Manually added' }),
    [QuoteRequestLineItemAssignmentReason.ReferenceSupplier]: defineMessage({ message: 'Reference supplier' }),
    [QuoteRequestLineItemAssignmentReason.LineCard]: defineMessage({ message: 'Line card' }),
    [QuoteRequestLineItemAssignmentReason.LineCardRule]: defineMessage({ message: 'Line card rule' }),
};

export const leadTimeUnitTranslations: Record<LeadTimeUnit, MessageDescriptor> = {
    days: defineMessage({ message: 'Days' }),
    weeks: defineMessage({ message: 'Weeks' }),
    months: defineMessage({ message: 'Months' }),
};
