import { t } from '@lingui/macro';
import { CollapsibleSidebar } from '@luminovo/design-system';
import { ChecklistRounded, ForwardToInboxRounded } from '@mui/icons-material';
import { route } from '../../../utils/routes';

export function NavigationSidebarSourcing({ rfqId }: { rfqId: string }) {
    // TODO add negotiations submenu
    // we need to fetch all the negotiations for the rfq
    // and then we need to add a submenu item for each negotiation
    return (
        <CollapsibleSidebar
            id="sourcing"
            title={t`Sourcing`}
            contents={[
                {
                    type: 'link',
                    href: route('/rfqs/:rfqId/sourcing', { rfqId }),
                    Icon: ChecklistRounded,
                    label: t`Overview`,
                },

                {
                    type: 'link',
                    href: route('/rfqs/:rfqId/sourcing/negotiations', { rfqId }),
                    Icon: ForwardToInboxRounded,
                    label: t`Quote requests`,
                    isSelected: (pathname) =>
                        pathname.startsWith(route('/rfqs/:rfqId/sourcing/negotiations', { rfqId })),
                },
            ]}
        />
    );
}
