import { t, Trans } from '@lingui/macro';
import {
    DestructivePrimaryButton,
    Dialog,
    DialogContent,
    DialogTitle,
    Flexbox,
    SecondaryButton,
    SecondaryIconButton,
    Text,
} from '@luminovo/design-system';
import { Delete } from '@mui/icons-material';
import { useDialogContext } from '../../../../components/contexts/ModalContext';
import { useHttpMutation } from '../../../../resources/mutation/useHttpMutation';

export const DeleteManufacturingScenarioTemplateInstanceButton = ({
    templateInstanceId,
}: {
    templateInstanceId: string;
}): JSX.Element => {
    const { openDialog } = useDeleteTemplateInstanceDialog(templateInstanceId);

    return (
        <SecondaryIconButton onClick={openDialog} size="medium">
            <Delete />
        </SecondaryIconButton>
    );
};

const useDeleteTemplateInstanceDialog = (templateInstanceId: string) => {
    const { setDialog, closeDialog } = useDialogContext();

    const { mutateAsync, isPending: isLoading } = useHttpMutation(
        'DELETE /manufacturing-scenario-template-instances/:manufacturingScenarioTemplateInstanceId',
        {
            snackbarMessage: t`Successfully deleted template`,
            onSuccess: closeDialog,
        },
    );

    const deleteTemplateInstance = async () => {
        await mutateAsync({
            pathParams: { manufacturingScenarioTemplateInstanceId: templateInstanceId },
            queryParams: {},
            requestBody: null,
        });
    };

    return {
        openDialog: () =>
            setDialog(
                <Dialog open={true} maxWidth={'xs'} onClose={closeDialog}>
                    <DialogTitle title={t`Remove manufacturing scenario template?`} />

                    <DialogContent style={{ paddingBottom: '24px' }}>
                        <Flexbox flexDirection={'column'} gap={8}>
                            <Text>
                                <Trans>
                                    Are you sure you want to remove this manufacturing scenario template and all
                                    associated activities and expenses?
                                </Trans>
                            </Text>
                            <Flexbox alignItems="center" justifyContent="end" gap="8px">
                                <SecondaryButton onClick={closeDialog}>
                                    <Trans>Cancel</Trans>
                                </SecondaryButton>
                                <DestructivePrimaryButton
                                    disabled={isLoading}
                                    onClick={async () => await deleteTemplateInstance()}
                                >
                                    <Trans>Remove</Trans>
                                </DestructivePrimaryButton>
                            </Flexbox>
                        </Flexbox>
                    </DialogContent>
                </Dialog>,
            ),
    };
};
