import { Plural, t, Trans } from '@lingui/macro';
import { isPresent } from '@luminovo/commons';
import { colorSystem, Flexbox, Link, Message, Tag, Text } from '@luminovo/design-system';
import { EmsPartNumberPartMatchFullPart, GenericFullPart, OtsComponentFull, RfqContext } from '@luminovo/http-client';
import { formatPart } from '@luminovo/sourcing-core';
import { CircularProgress } from '@mui/material';
import { useGenericPartDetailsDrawer } from '../../../../components/partSpecificationCards/GenericPart/GenericPartCardDetails';
import { CpnsTable } from '../../../../components/partSpecificationCards/Ipn/CpnsTable';
import {
    getComponentSpecificationsChips,
    renderComponentSpecificationWarning,
} from '../../../../components/partSpecificationCards/Ipn/IpnCardDetails';
import { TechParamsMultiChip } from '../../../../components/TechParamsMultiChip';
import { useIpn } from '../../../../resources/part/partHandler';
import { useRfQ } from '../../../../resources/rfq/rfqHandler';
import { useHasCustomers } from '../../../../utils/featureFlags';
import { useIsRfqEditable } from '../../../../utils/rfqUtils';
import { RenderOtsLinkedPart } from './RenderOtsLinkedPart';

const DetailsSection: React.FunctionComponent<{
    label: string | JSX.Element;
}> = ({ label, children }) => {
    return (
        <Flexbox flexDirection={'column'} gap={12}>
            <Text variant="h4" color={colorSystem.neutral[7]}>
                {label}
            </Text>
            {children}
        </Flexbox>
    );
};

const LabelWithText = ({ label, text }: { label: string; text: string }): JSX.Element => {
    return (
        <Flexbox justifyContent={'space-between'} gap={24}>
            <Text variant={'body-small-semibold'} color={colorSystem.neutral[7]}>
                {label}
            </Text>
            <Text variant={'body-small'} style={{ textAlign: 'right' }}>
                {text}
            </Text>
        </Flexbox>
    );
};

const ComponentSpecificationsInformation: React.FunctionComponent<{
    ipn: OtsComponentFull;
}> = ({ ipn }) => {
    return (
        <>
            {isPresent(ipn.component_specifications?.conflicts) &&
                isPresent(ipn.component_specifications) &&
                renderComponentSpecificationWarning(ipn.component_specifications.conflicts)}
            {isPresent(ipn.component_specification) && (
                <TechParamsMultiChip
                    componentSpecification={ipn.component_specification}
                    conflicts={ipn.component_specifications?.conflicts}
                />
            )}
        </>
    );
};

const RenderGenericLinkedPart = ({ part, rfqContext }: { part: GenericFullPart; rfqContext: RfqContext }) => {
    const { openDrawer } = useGenericPartDetailsDrawer();
    const { data: rfq } = useRfQ(
        rfqContext.type === 'WithinRfQ' ? rfqContext.rfq_id : '',
        rfqContext.type === 'WithinRfQ',
    );
    const { isRfqEditable } = useIsRfqEditable(rfq?.status, rfq?.is_archived, rfq?.workflow_type);
    return (
        <Flexbox gap="8px">
            <Link
                attention="high"
                onClick={(e) => {
                    e.stopPropagation();
                    openDrawer({ genericPartId: part.id, rfqContext, isEditEnable: isRfqEditable });
                }}
            >
                {formatPart(part)}
            </Link>
        </Flexbox>
    );
};

const LinkedParts = ({
    matches,
    suggestionsLength,
    rfqContext,
}: {
    matches: EmsPartNumberPartMatchFullPart[];
    suggestionsLength: number;
    rfqContext: RfqContext;
}) => {
    const suggestionsMessage = (
        <Plural
            value={suggestionsLength}
            one={t`No parts could be linked automatically based on synced ERP data. There is ${suggestionsLength} suggestion. We only use MPN and manufacturer data for automatically linking parts.`}
            other={t`No parts could be linked automatically based on synced ERP data. There are ${suggestionsLength} suggestions, however. We only use MPN and manufacturer data for automatically linking parts.`}
        />
    );

    if (matches.length === 0) {
        return (
            <Message
                size="large"
                attention="low"
                variant={'red'}
                title={t`No linked parts`}
                message={suggestionsMessage}
            />
        );
    }

    return (
        <Flexbox gap={4} flexDirection={'column'}>
            {matches.map((match) => {
                const partType = match.part.type;
                if (partType === 'Generic') {
                    return (
                        <RenderGenericLinkedPart
                            key={match.part.data.id}
                            part={match.part.data}
                            rfqContext={rfqContext}
                        />
                    );
                }
                if (partType === 'OffTheShelf') {
                    return (
                        <RenderOtsLinkedPart key={match.part.data.id} part={match.part.data} rfqContext={rfqContext} />
                    );
                }
                return <></>;
            })}
        </Flexbox>
    );
};

export const LinkedPartsTab = ({
    ipnId,
    rfqContext,
    showCpnsTable = true,
}: {
    ipnId: string;
    rfqContext: RfqContext;
    showCpnsTable?: boolean;
}): JSX.Element => {
    const { hasCustomers } = useHasCustomers();
    const { data: ipn, isLoading } = useIpn(ipnId, rfqContext, { refetchOnWindowFocus: true });

    if (isLoading || !ipn) {
        return (
            <Flexbox height={'80vh'} justifyContent="center" alignItems="center">
                <CircularProgress />
            </Flexbox>
        );
    }

    return (
        <Flexbox flexDirection="column" gap={24} style={{ backgroundColor: 'inherit' }}>
            {' '}
            <DetailsSection
                label={
                    <Flexbox justifyContent={'space-between'} alignItems={'center'}>
                        <Text variant="h5" color={colorSystem.neutral[8]}>
                            <Trans>Specification</Trans>
                        </Text>
                        {getComponentSpecificationsChips(ipn)}
                    </Flexbox>
                }
            >
                <ComponentSpecificationsInformation ipn={ipn} />
            </DetailsSection>
            <DetailsSection
                label={
                    <Flexbox gap={4} alignItems={'center'}>
                        <Text variant="h5" color={colorSystem.neutral[8]}>
                            <Trans>Linked parts</Trans>
                        </Text>
                    </Flexbox>
                }
            >
                <Flexbox flexDirection={'column'} gap={8}>
                    <LinkedParts
                        matches={ipn.matches}
                        suggestionsLength={ipn.suggestions.length}
                        rfqContext={rfqContext}
                    />
                </Flexbox>
            </DetailsSection>
            {(ipn.part_specifications.length > 0 || ipn.spns.length > 0) && (
                <DetailsSection
                    label={
                        <Flexbox gap={4} alignItems={'center'}>
                            <Text variant="h5" color={colorSystem.neutral[8]}>
                                <Trans>Synced ERP data</Trans>
                            </Text>
                        </Flexbox>
                    }
                >
                    <Flexbox flexDirection={'column'} gap={16}>
                        {ipn.part_specifications.map((data, i) => {
                            return (
                                <Flexbox
                                    flexDirection={'column'}
                                    gap={8}
                                    key={i}
                                    bgcolor={colorSystem.neutral.white}
                                    padding={'4px'}
                                    borderRadius={'4px'}
                                    border={`1px solid ${colorSystem.neutral[3]}`}
                                >
                                    <LabelWithText label={t`MPN`} text={data.mpn ?? '-'} />
                                    <LabelWithText label={t`Manufacturer`} text={data.manufacturer ?? '-'} />
                                    <LabelWithText label={t`Description`} text={data.description ?? '-'} />
                                    <LabelWithText label={t`Package`} text={data.package ?? '-'} />
                                    <LabelWithText label={t`Part type`} text={data.part_type ?? '-'} />
                                </Flexbox>
                            );
                        })}
                        {ipn.spns.map((data, i) => {
                            return (
                                <Flexbox flexDirection={'column'} gap={8} key={i}>
                                    <LabelWithText label={t`SPN`} text={data.supplier_part_number ?? '-'} />
                                    <LabelWithText label={t`Supplier`} text={data.supplier ?? '-'} />
                                </Flexbox>
                            );
                        })}
                    </Flexbox>
                </DetailsSection>
            )}
            {ipn.cpns.length > 0 && showCpnsTable && (
                <DetailsSection
                    label={
                        <Flexbox gap={4} alignItems="center">
                            <Text variant="h5" color={colorSystem.neutral[8]}>
                                {hasCustomers ? t`Customer part numbers` : t`Company part numbers`}
                            </Text>
                            <Flexbox flex={1} />
                            {ipn.is_restricted_to_customers && (
                                <Tag color="violet" attention="low" label={'Restricted'} />
                            )}
                        </Flexbox>
                    }
                >
                    <CpnsTable cpns={ipn.cpns} isRestrictedToCustomers={ipn.is_restricted_to_customers} />
                </DetailsSection>
            )}
        </Flexbox>
    );
};
