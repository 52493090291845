import { t, Trans } from '@lingui/macro';
import { colorSystem, Flexbox, Tag } from '@luminovo/design-system';
import { ExpenseConfigurationSummaryDTO } from '@luminovo/http-client';
import { Box, Divider, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { transEnum } from '../../../components/localization/TransEnum';
import { expenseLevelEnumPublicTranslations } from '../../../resources/expenses/expensesBackendTypes';
import { EditExpenseConfigurationNotes } from '../ActivitiesTable/EditExpenseConfigurationNotesDialog';
import { DriverNotes } from '../shared/ManufacturingSharedComponents';
import {
    categorySelectionPublicTranslations,
    processPublicTranslations,
} from '../shared/sharedManufacturingBackendTypes';
import { EmptyDrawerIcon } from './EmptyDrawerIcon';
import { ExpenseCost } from './expenseConfigurationCosts';
import { ExpenseConfigurationDrivers } from './expenseConfigurationDriverList';

const StyledBox = styled(Box)({
    border: `1px solid ${colorSystem.neutral[3]}`,
    height: '100%',
    borderRadius: '8px',
});

const StyledTypography = styled(Typography)({
    color: colorSystem.neutral[8],
});

export const EmptyConfigurationDrawer = ({ text }: { text: string }) => {
    return (
        <StyledBox
            padding={'24px'}
            display={'flex'}
            alignItems={'center'}
            flexDirection={'column'}
            gap={'24px'}
            textAlign={'center'}
        >
            <Typography
                variant="h1"
                style={{
                    color: colorSystem.neutral[7],
                }}
            >
                <Trans>Nothing to show yet</Trans>
            </Typography>

            <Typography
                variant="h3"
                style={{
                    color: colorSystem.neutral[6],
                }}
            >
                <Trans>Click on an {text} from the table to view details</Trans>
            </Typography>

            <EmptyDrawerIcon />
        </StyledBox>
    );
};

const ExpenseConfigurationDetails = ({ expense }: { expense: ExpenseConfigurationSummaryDTO }) => {
    const summaryBoxes = [
        {
            title: t`Process`,
            content: transEnum(expense.expense_configuration_details.process, processPublicTranslations),
        },
        {
            title: t`Category`,
            content: transEnum(expense.expense_configuration_details.category, categorySelectionPublicTranslations),
        },
    ];

    return (
        <Box
            padding={'24px'}
            paddingBottom={0}
            style={{
                borderBottom: `1px solid ${colorSystem.neutral[3]}`,
            }}
        >
            <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} marginBottom={'24px'}>
                <Flexbox alignItems={'center'} gap={'8px'}>
                    <StyledTypography variant="h2">
                        {expense.expense_configuration_details.expense_name}
                    </StyledTypography>
                    <Tag
                        color={'neutral'}
                        label={transEnum(
                            expense.expense_configuration_details.level,
                            expenseLevelEnumPublicTranslations,
                        )}
                    />
                    {expense.expense_configuration_details.expense_internal_number && (
                        <Typography variant="body1" style={{ color: colorSystem.neutral[7] }}>
                            #{expense.expense_configuration_details.expense_internal_number}
                        </Typography>
                    )}
                </Flexbox>
                {!expense.expense_configuration_details.expense_configuration_notes && (
                    <EditExpenseConfigurationNotes
                        expenseConfigurationId={expense.expense_configuration_details.expense_configuration_id}
                        notes={expense.expense_configuration_details.expense_configuration_notes ?? undefined}
                    />
                )}
            </Box>
            <Flexbox marginBottom={'24px'} justifyContent={'space-between'}>
                {expense.expense_configuration_details.expense_configuration_notes && (
                    <>
                        <DriverNotes
                            notes={expense.expense_configuration_details.expense_configuration_notes ?? ''}
                            maxLength={100}
                            boxProps={{ width: '100%' }}
                        />
                        <EditExpenseConfigurationNotes
                            expenseConfigurationId={expense.expense_configuration_details.expense_configuration_id}
                            notes={expense.expense_configuration_details.expense_configuration_notes ?? undefined}
                        />
                    </>
                )}
            </Flexbox>

            <Box display={'grid'} gridTemplateColumns="repeat(3, 1fr)">
                {summaryBoxes.map((box, index) => (
                    <Box key={index} marginBottom={'24px'}>
                        <StyledTypography variant="body2">{box.title}</StyledTypography>
                        <Typography>{box.content}</Typography>
                    </Box>
                ))}
            </Box>

            <Box marginBottom={'24px'}>
                <StyledTypography variant="body2">
                    <Trans>Description</Trans>
                </StyledTypography>
                <Typography>{expense.expense_configuration_details.expense_description ?? '-'}</Typography>
            </Box>
        </Box>
    );
};

export const ExpenseConfigurationDrawer = ({
    expense,
    batchSize,
}: {
    expense: ExpenseConfigurationSummaryDTO | undefined;
    batchSize: number | undefined;
}) => {
    if (expense === undefined) {
        return <EmptyConfigurationDrawer text={t`expense`} />;
    }
    return (
        <StyledBox key={expense.expense_configuration_details.expense_configuration_id}>
            <ExpenseConfigurationDetails expense={expense} />
            <Divider />
            <ExpenseConfigurationDrivers expense={expense} />
            <ExpenseCost expense={expense} batchSize={batchSize} />
        </StyledBox>
    );
};
