import { t } from '@lingui/macro';
import { assertUnreachable } from '@luminovo/commons';
import {
    DriverStatusDetailsDTO,
    DriverStatusWithDriverIdDTO,
    ManufacturingAssemblyDetailsDTO,
    TimeCalculation,
    TimeComponents,
} from '@luminovo/http-client';
import { DriverStatusIconType, DriverStatusIconWithTooltip } from '../utils/status';
import { useStatusInformationFromDriverStatus } from './driverStatusInformation';

const isZeroFixedTime = (timeComponent: TimeComponents): boolean => {
    return timeComponent.type === 'Fixed' && timeComponent.details.fixed_time.amount === '0';
};

const hasZeroFixedTime = (timeCalculation: TimeCalculation): boolean => {
    switch (timeCalculation.level) {
        case 'Unit':
            return (
                isZeroFixedTime(timeCalculation.details.unit_time_components) ||
                (timeCalculation.details.batch_time_components !== null &&
                    isZeroFixedTime(timeCalculation.details.batch_time_components))
            );
        case 'Batch':
            return isZeroFixedTime(timeCalculation.details.batch_time_components);
        case 'Project':
            return isZeroFixedTime(timeCalculation.details.project_time_components);
    }
};

export const DriverStatusIcon = ({
    driverStatus,
    rfqId,
    manufacturingAssemblyDetails,
    iconType,
    timeCalculation,
}: {
    driverStatus: DriverStatusDetailsDTO | undefined;
    timeCalculation?: TimeCalculation;
    rfqId: string;
    manufacturingAssemblyDetails: ManufacturingAssemblyDetailsDTO;
    iconType: DriverStatusIconType;
}) => {
    switch (driverStatus?.type) {
        // Activities which doesn't use drivers or Formula
        case undefined:
            const requiresManualInput = timeCalculation && hasZeroFixedTime(timeCalculation);
            if (requiresManualInput) {
                return (
                    <DriverStatusIconWithTooltip
                        status={'ZeroFixedTime'}
                        description={t`May require manual input`}
                        iconType={iconType}
                    />
                );
            }
            return <DriverStatusIconWithTooltip status={'Ok'} description={''} iconType={iconType} />;

        case 'InvalidFormula':
            return (
                <DriverStatusIconWithTooltip status={'Error'} description={t`Error in formula`} iconType={iconType} />
            );
        case 'Status':
            return (
                <DriverStatusWithTooltipIconForStatus
                    status={driverStatus.details}
                    rfqId={rfqId}
                    manufacturingAssemblyDetails={manufacturingAssemblyDetails}
                    iconType={iconType}
                />
            );
        default:
            assertUnreachable(driverStatus);
    }
};

const DriverStatusWithTooltipIconForStatus = ({
    status,
    rfqId,
    manufacturingAssemblyDetails,
    iconType,
}: {
    status: DriverStatusWithDriverIdDTO;
    rfqId: string;
    manufacturingAssemblyDetails: ManufacturingAssemblyDetailsDTO;
    iconType: DriverStatusIconType;
}): JSX.Element => {
    const info = useStatusInformationFromDriverStatus(status.status, rfqId, manufacturingAssemblyDetails);
    return (
        <DriverStatusIconWithTooltip
            status={info.type}
            description={info.description}
            iconType={iconType}
            tooltipText={info.tooltipText}
        />
    );
};
