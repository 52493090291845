import { t, Trans } from '@lingui/macro';
import { isPresent, throwErrorUnlessProduction } from '@luminovo/commons';
import { colorSystem } from '@luminovo/design-system';
import {
    BomLineBuildingErrorOutput,
    BomLineBuildingErrorOutputRuntype,
    BomProgressStage,
    LineScreenerErrorOutput,
    ScreenerHandlerOutput,
} from '@luminovo/http-client';
import { Dialog, DialogContent, Grid, LinearProgress, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { IssueNameEnum } from '../../resources/bomImporter/bomImporterIssuesEnum';
import { useHttpQuery } from '../../resources/http/useHttpQuery';
import { analytics } from '../../utils/analytics';
import { route } from '../../utils/routes';
import { assertUnreachable } from '../../utils/typingUtils';
import { ViewContext } from '../Bom/components/ModuleTableData';
import { ColumnTagsSheetsAction } from '../DesignItemDetails/components/AutocompleteColumnTags/types';
import { convertBomLineErrorOutputToBOMScreeningDialogState, isScreenerHandlerOutput } from './utils';

const convertBomProgressStageToUserFacingMessage = (bomProgressStage: BomProgressStage): string => {
    switch (bomProgressStage) {
        case BomProgressStage.FileReading:
            return t`Reading BOM`;
        case BomProgressStage.Screening:
            return t`Screening BOM`;
        case BomProgressStage.LineBuilding:
            return t`Building extractions`;
        case BomProgressStage.CrossChecking:
            return t`Cross checking parts - this can take up to 10 minutes`;
        case BomProgressStage.Completed:
            return t`BOM conversion completed`;
        default:
            assertUnreachable(bomProgressStage);
    }
};
// This is the bom importer loading dialog, after the task is accepted we query it's
// status here until we get the data
function BomImporterLoadingDialog({
    taskEndpoint,
    taskFinishedCallback,
    defaultMessage,
    taskCleanUpCallback,
    rfqId,
    dispatch,
    assemblyId,
    viewContext,
}: {
    taskCleanUpCallback: () => void;
    taskEndpoint: string;
    taskFinishedCallback: (data: LineScreenerErrorOutput | ScreenerHandlerOutput) => void;
    defaultMessage: string;
    rfqId: string;
    assemblyId: string;
    viewContext: ViewContext;
    dispatch: React.Dispatch<ColumnTagsSheetsAction>;
}) {
    const history = useHistory();
    const [progress, setProgress] = useState(0);
    const [message, setMessage] = useState<string>(defaultMessage);
    const [enableBomProgressQuery, setEnabledBomProgressQuery] = useState(false);

    // Without this delay in calling the backend, the backend returns a 404 because the endpoint is not ready.
    useEffect(() => {
        const timer = setTimeout(() => setEnabledBomProgressQuery(true), 1000);
        return () => {
            clearTimeout(timer);
        };
    });

    // Hack: we need to query the bom progress from two different endpoints depending on the taskendpoint what the backend returns.
    //       Using pathParams in the useHttpQuery hook does not work because the the pathParams gets encoded in the url. (2023-11-20 @ Rene)
    const enableScreeningProgressQuery = taskEndpoint.includes('/screener/tasks/');
    const enableLineBuilderProgressQuery = taskEndpoint.includes('/line-builder/tasks/');

    if (!enableScreeningProgressQuery && !enableLineBuilderProgressQuery) {
        throwErrorUnlessProduction('taskEndpoint is not a valid bom importer task endpoint');
    }

    const { data: screenerTaskData, isError: screenerTaskIsError } = useHttpQuery(
        'GET /bom-importer/screener/tasks/:taskId',
        { pathParams: { taskId: taskEndpoint.replace('/screener/tasks/', '') } },
        {
            refetchInterval: 1000,
            enabled: enableBomProgressQuery && enableScreeningProgressQuery,
            select: (res) => res.data,
        },
    );

    const { data: lineBuilderTaskData, isError: lineBuilderTaskIsError } = useHttpQuery(
        'GET /bom-importer/line-builder/tasks/:taskId',
        { pathParams: { taskId: taskEndpoint.replace('/line-builder/tasks/', '') } },
        {
            refetchInterval: 1000,
            enabled: enableBomProgressQuery && enableLineBuilderProgressQuery,
            select: (res) => res.data,
        },
    );

    const bomProgressData = screenerTaskData ?? lineBuilderTaskData;
    const isError = screenerTaskIsError ?? lineBuilderTaskIsError;

    useEffect(() => {
        if (isPresent(bomProgressData)) {
            if (bomProgressData.status === 'SUCCESS') {
                if (bomProgressData.data && 'data' in bomProgressData.data) {
                    const responseData: any = bomProgressData.data.data;
                    taskFinishedCallback(responseData);
                    taskCleanUpCallback();
                } else {
                    throw new Error('success response not processed properly');
                }
            }
            if (bomProgressData.status === 'PROGRESS') {
                if (bomProgressData.progress !== progress && bomProgressData.data && 'stage' in bomProgressData.data) {
                    setProgress(bomProgressData.progress);
                    if (bomProgressData.data.stage) {
                        setMessage(convertBomProgressStageToUserFacingMessage(bomProgressData.data.stage));
                    } else {
                        setMessage(t`Converting BOM...`);
                    }
                }
            }
            if (bomProgressData.status === 'FAILED') {
                taskCleanUpCallback();
                if (BomLineBuildingErrorOutputRuntype.guard(bomProgressData?.data?.data)) {
                    const errorOutput: BomLineBuildingErrorOutput = BomLineBuildingErrorOutputRuntype.check(
                        bomProgressData?.data?.data,
                    );
                    const errorData = convertBomLineErrorOutputToBOMScreeningDialogState(errorOutput);
                    // by convention, when there's an error, the sheet with the excel_lines with issues is stored at idx = 0
                    dispatch({ type: 'set-current-sheet', index: 0 });
                    const isDuplicatedDesignators = isScreenerHandlerOutput(errorData.outputWithIssues)
                        ? errorData.outputWithIssues.sheets[0].excel_lines
                              .flatMap((row) => row.issues)
                              .some((issue) => issue.name === IssueNameEnum.DUPLICATE_DESIGNATORS_FOUND)
                        : false;

                    if (isDuplicatedDesignators) {
                        /* eslint-disable camelcase */
                        analytics.track('could_not_upload_bom', {
                            assembly_uuid: assemblyId,
                            error_type: 'duplicated designators',
                        });
                        /* eslint-enable camelcase */
                    }

                    const page1Route =
                        viewContext.type === 'WithinRfQ'
                            ? route('/rfqs/:rfqId/bom/assembly/:assemblyId/bom-importer/page-1', {
                                  rfqId,
                                  assemblyId,
                              })
                            : route('/assemblies/:assemblyId/dashboard/bom-importer/page-1', { assemblyId }, { rfqId });
                    return history.push({
                        pathname: page1Route,
                        state: errorData,
                    });
                }
                /* eslint-disable camelcase */
                analytics.track('could_not_upload_bom', {
                    assembly_uuid: assemblyId,
                    error_type: 'bom import task failed',
                });
                /* eslint-enable camelcase */
                alert(
                    t`We are sorry but we failed to import this BOM. Please retry or contact support and provide the task ID: ${bomProgressData?.task_id}`,
                );
            }
        } else if (isError) {
            taskCleanUpCallback();
            /* eslint-disable camelcase */
            analytics.track('could_not_upload_bom', {
                assembly_uuid: assemblyId,
                error_type: 'bom progress data undefined',
            });
            /* eslint-enable camelcase */
            alert(t`We are sorry but we failed to import this BOM. Please retry or contact support.`);
        }
    }, [
        dispatch,
        bomProgressData,
        isError,
        progress,
        taskCleanUpCallback,
        taskFinishedCallback,
        history,
        rfqId,
        assemblyId,
        viewContext.type,
    ]);

    return (
        <Dialog open={true} maxWidth={'md'} scroll={'paper'} disableEscapeKeyDown={true}>
            <DialogContent style={{ minWidth: '46rem' }}>
                <Grid container direction={'column'} spacing={2}>
                    <Grid item xs={12} style={{ minWidth: '100%' }}>
                        <Typography style={{ color: colorSystem.neutral[7] }} variant="h4">
                            <Trans>Importing BOM</Trans>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} style={{ minWidth: '100%' }}>
                        {' '}
                        <Typography style={{ fontWeight: 700, fontSize: '21px' }} variant="h3">
                            {Math.round(progress)}%
                        </Typography>
                    </Grid>
                    <Grid item xs={12} style={{ minWidth: '100%' }}>
                        <LinearProgress
                            style={{ width: '100%' }}
                            color={'primary'}
                            variant="determinate"
                            value={progress}
                        />
                    </Grid>
                    <Grid item>
                        <Typography variant="body1">{message}</Typography>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}

export default BomImporterLoadingDialog;
