import { BaseParser, ParseResult, Parser } from '../parsers/Parser';

type Key = string | boolean | number;

export interface CasesProps<T extends Key> {
    /**
     * The list of cases to match the input against.
     */
    matches: Array<{ when: string[]; then: T }>;
}

class CasesParser<T extends Key> extends BaseParser<T> {
    constructor(props: CasesProps<T>) {
        const lowerCaseWhen = props.matches.map((match) => {
            return {
                when: match.when.map((when) => when.toLowerCase()),
                then: match.then,
            };
        });

        function parseCases(input: string): ParseResult<T> {
            const lowerCaseInput = input.toLowerCase();

            for (const { when, then } of lowerCaseWhen) {
                for (const whenCase of when) {
                    if (lowerCaseInput.startsWith(whenCase)) {
                        return { ok: true, value: then, rest: input.slice(whenCase.length) };
                    }
                }
            }

            return {
                ok: false,
                expected: `One of ${lowerCaseWhen.map((match) => match.when).join(', ')}`,
            };
        }

        super(parseCases);
    }
}

/**
 * A parser that matches a string to a value based on a list of cases.
 *
 * Useful for parsing enums.
 *
 * Example:
 *
 * ```ts
 * const parser = cases({
 *    matches: [
 *       { when: ['Yes', 'Ja'], then: 'TRUE' },
 *       { when: ['Nee', 'Niet', 'Nein'], then: 'FALSE' },
 *    ],
 * });
 * ```
 */
export const cases = <T extends Key>(props: CasesProps<T>): Parser<T> => {
    return new CasesParser(props);
};
