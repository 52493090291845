import { t } from '@lingui/macro';
import { isPresent } from '@luminovo/commons';
import { INTER, InteractiveMenu, ParametricInputTooltip, colorSystem } from '@luminovo/design-system';
import { PartCategoryDTO } from '@luminovo/http-client';
import { ArrowDropDownRounded } from '@mui/icons-material';
import { ClickAwayListener, styled } from '@mui/material';
import { useReducer, useRef, useState } from 'react';
import { PartCategoryPreferenceConfigured } from '../ManufacturerPreferencesFrontendTypes';
import { CategoryBlockView } from './CategoryBlockView';
import { CategorySelectActions, CategorySelectState, reducer } from './reducer';

function findAllParCategoriesToRemove(block: PartCategoryDTO, partCategories: PartCategoryDTO[]): Set<number> {
    const partCategoryIds = partCategories.map((partCategory) => partCategory.id);
    const descendants: Set<number> = new Set();

    function traverse(block: PartCategoryDTO) {
        descendants.add(block.id);

        const includedChild = block.children.find((childId) => partCategoryIds.includes(childId));
        if (includedChild !== undefined) {
            const childCategory = partCategories.find((pc) => pc.id === includedChild);
            if (childCategory) {
                traverse(childCategory);
            }
        }
    }

    traverse(block);
    return descendants;
}

export function PartCategoryField({
    partCategories,
    partCategoryPreferencesConfigured,
    partCategoryBlocks,
    isDisabled = false,
    onChange,
}: {
    partCategories?: PartCategoryDTO[];
    partCategoryPreferencesConfigured?: PartCategoryPreferenceConfigured[];
    partCategoryBlocks?: PartCategoryDTO[];
    isDisabled: boolean;
    onChange: (newPartCategoryId: number | undefined) => void;
}): JSX.Element {
    const ref = useRef<HTMLInputElement>(null);
    const [state, dispatch] = useReducer(reducer, {
        query: '',
        categoryBlocks: partCategoryBlocks ?? [],
        inputRef: ref,
    });
    const [showMenu, setShowMenu] = useState(false);

    const handleRemove = (block: PartCategoryDTO) => {
        const partCategoriesToRemove = findAllParCategoriesToRemove(block, state.categoryBlocks);
        dispatch({ type: 'removeCategory', categoriesToBeRemoved: partCategoriesToRemove });

        const newBlocks = state.categoryBlocks.filter((b) => {
            return !partCategoriesToRemove.has(b.id);
        });
        const newPartCategoryId = newBlocks.length === 0 ? undefined : newBlocks[newBlocks.length - 1].id;
        onChange(newPartCategoryId);
    };

    const blocks =
        state.categoryBlocks.length > 0 ? (
            <CategoryBlockView
                block={state.categoryBlocks[state.categoryBlocks.length - 1]}
                onRemove={handleRemove}
                isDisabled={isDisabled}
            />
        ) : null;

    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        const newQuery = event.target.value;

        dispatch({ type: 'onQueryChange', query: newQuery });
    }

    if (partCategories === undefined || partCategoryPreferencesConfigured === undefined) {
        return <DefaultContainer>{blocks}</DefaultContainer>;
    }

    const tooltip = showMenu
        ? displayMenu({
              state,
              dispatch,
              partCategories,
              onChange,
          })
        : '';

    const isSelectable =
        state.categoryBlocks.length === 0 ||
        state.categoryBlocks[state.categoryBlocks.length - 1].children.length !== 0;

    return (
        <ClickAwayListener
            onClickAway={() => {
                setShowMenu(false);
            }}
        >
            <DefaultContainer>
                {blocks}
                {state.categoryBlocks.length === 0 && <></>}
                {isSelectable && (
                    <>
                        <ParametricInputTooltip
                            arrow={false}
                            open={Boolean(tooltip)}
                            placement="bottom-start"
                            title={tooltip}
                        >
                            <DefaultInput
                                placeholder={isSelectable ? t`Select a part category` : ''}
                                value={state.query}
                                autoFocus={false}
                                onChange={handleChange}
                                ref={state.inputRef}
                                onFocus={() => {
                                    setShowMenu(true);
                                }}
                                // Fixes flaky chromatic test
                                aria-describedby={undefined}
                            />
                        </ParametricInputTooltip>
                        <ArrowDropDownRounded
                            style={{ width: 22, height: 22, color: colorSystem.neutral[7], cursor: 'pointer' }}
                            onClick={() => setShowMenu(true)}
                        />
                    </>
                )}
            </DefaultContainer>
        </ClickAwayListener>
    );
}

const DefaultContainer = styled('span')({
    display: 'inline-flex',
    padding: '4px 8px',
    alignItems: 'center',
    gap: 4,
    border: `1px solid ${colorSystem.neutral[3]}`,
    borderRadius: 4,
    width: '100%',
    boxSizing: 'border-box',
    minHeight: 40,
    flexWrap: 'wrap',
    background: colorSystem.neutral.white,
    '&:has(input:focus)': {
        border: `1px solid ${colorSystem.primary[5]}`,
        boxShadow: `0px 0px 0px 2px ${colorSystem.primary[3]}`,
    },
    '&:focus': {
        border: `1px solid ${colorSystem.primary[5]}`,
        boxShadow: `0px 0px 0px 2px ${colorSystem.primary[3]}`,
    },
    '&:has(input:hover)': {
        border: `1px solid ${colorSystem.primary[5]}`,
    },
});

const DefaultInput = styled('input')({
    border: 'none',
    outline: 'none',
    flexGrow: 1,
    padding: 0,
    margin: 0,
    background: 'transparent',
    fontFamily: INTER,
    fontSize: 14,
    lineHeight: '24px',
    '&::placeholder': {
        opacity: 1,
        color: colorSystem.neutral[6],
    },
});

function displayMenu({
    state,
    dispatch,
    partCategories,
    onChange,
}: {
    state: CategorySelectState;
    dispatch: React.Dispatch<CategorySelectActions>;
    partCategories: PartCategoryDTO[];
    onChange: (newPartCategoryId: number | undefined) => void;
}) {
    // At this point, all part categories have been sorted, so it's safe to edit the name of the first item
    // (the root node of the part category tree)
    partCategories[0].name = t`All categories`;

    const { categoryBlocks: blocks } = state;

    const rootNode = partCategories.find((category) => category.id === 1);
    if (blocks.length === 0 && !rootNode) {
        return null;
    }

    const partCategoriesToDisplay =
        blocks.length === 0
            ? [rootNode]
            : partCategories.filter((category) => blocks[blocks.length - 1].children?.includes(category.id));

    if (partCategoriesToDisplay.length === 0) {
        return null;
    }

    const title = blocks.length === 0 ? `Off-the-shelf part` : blocks[blocks.length - 1].name;

    return (
        <InteractiveMenu
            items={partCategoriesToDisplay.filter(isPresent).map((param) => ({
                id: param.id,
                label: param.name,
                value: param,
            }))}
            query={state.query}
            onSelect={(partCategory: PartCategoryDTO) => {
                dispatch({ type: 'selectPartCategory', categoryBlock: partCategory });
                onChange(partCategory.id);
            }}
            onSearch={() => {}}
            title={title}
            inputRef={state.inputRef}
            showIcons={false}
        />
    );
}
