import { t, Trans } from '@lingui/macro';
import { isEqual } from '@luminovo/commons';
import { colorSystem, Flexbox, Tag, Text, Tooltip } from '@luminovo/design-system';
import { BomItemApprovalStatus, BomItemIssue, DesignItemOriginTypes } from '@luminovo/http-client';
import { Box, CircularProgress, styled, Typography } from '@mui/material';
import { useIsMutating } from '@tanstack/react-query';
import * as React from 'react';
import { iconForStatus } from '../../../components/Icons/icons';
import { BomItem } from '../../../resources/designItem/bomItemFrontendTypes';
import { isPcbDesignItem } from '../../../resources/designItem/designItemFunctions';
import { themeLuminovo } from '../../../themes';
import { alphaNumericalStringSort, capitalizeFirstLetter } from '../../../utils/stringFunctions';
import { assertUnreachable } from '../../../utils/typingUtils';
import { AssemblyTableData, BomItemTableData, ModuleTableData } from '../../Bom/components/ModuleTableData';
import { getActiveWarnings } from '../hasActiveWarnings';
import { getWarningsLabelForTooltip } from './getActiveWarningsLabelForTooltip';
import { updateDesignItemsQueryKey } from './updateDesignItemsQueryKey';

const BomLinePreviewContainer = styled(Box)({
    cursor: 'pointer',
    boxSizing: 'border-box',
    border: `1px solid transparent`,
    '&:hover': {
        background: colorSystem.neutral[0],
    },
    background: themeLuminovo.palette.background.paper,
    width: '100%',
    height: '100%',
    display: 'grid',
    gap: 8,
    gridTemplateColumns: 'auto 1fr',
    padding: 8,
});

function BomItemOrigin({ bomItem }: { bomItem: BomItem }) {
    const isManual = bomItem.origin.type === DesignItemOriginTypes.Manual;
    const lineNumbers = bomItem.origin.originalRowNumbers ?? [];
    const maximumLineNumbersToShow = 3;

    if (isPcbDesignItem(bomItem)) {
        return (
            <Tooltip arrow title={t`This design item was automatically generated for you from the uploaded PCB.`}>
                <Text variant="body-small" style={{ color: colorSystem.neutral[6] }}>{t`Automatically generated`}</Text>
            </Tooltip>
        );
    }

    if (isManual) {
        return (
            <Tooltip arrow title={t`Design item or designator added manually`}>
                <Text variant="body-small" style={{ color: colorSystem.neutral[6] }}>{t`Manually added`}</Text>
            </Tooltip>
        );
    }

    return (
        <Text
            variant="body-small"
            style={{
                color: colorSystem.neutral[6],
                whiteSpace: 'nowrap',
            }}
        >
            #{lineNumbers.slice(0, maximumLineNumbersToShow).join(', ')}
            {lineNumbers.length > maximumLineNumbersToShow && (
                <Text variant="body-small" style={{ marginLeft: 4 }}>
                    +{lineNumbers.length - maximumLineNumbersToShow}
                </Text>
            )}
        </Text>
    );
}

function WarningTags({ issues }: { issues: BomItemIssue[] }) {
    const warnings = getActiveWarnings({ issues });
    const tooltipLabel = capitalizeFirstLetter(getWarningsLabelForTooltip({ issues }));

    if (warnings.length === 0) {
        return null;
    }
    if (warnings.length === 1) {
        return <Tag color={'yellow'} label={capitalizeFirstLetter(tooltipLabel)} attention="low" />;
    }

    return (
        <Tooltip title={tooltipLabel}>
            <span>
                <Tag color="yellow" label={t`${warnings.length} Warnings`} attention="low" />
            </span>
        </Tooltip>
    );
}

function LineTitle({ bomItem }: { bomItem: BomItem }) {
    return (
        <Flexbox width="100%" justifyContent="space-between" flexWrap="nowrap">
            <BomItemOrigin bomItem={bomItem} />
            <Flexbox maxWidth="150px" flexWrap="nowrap" justifyContent="flex-end" gap={2} overflow="hidden">
                {bomItem.isConsigned && <Tag color={'green'} label={t`Consigned`} attention="low" />}
                {!bomItem.doNotPlace && <WarningTags issues={bomItem.issues} />}
            </Flexbox>
        </Flexbox>
    );
}

const ApprovalStatus = React.memo(({ isMutating, status }: { isMutating: boolean; status: BomItemApprovalStatus }) => {
    return isMutating ? <CircularProgress size={'20px'} /> : iconForStatus({ status });
});

function BomItemPreview({
    selectedBomItemId,
    bomItem,
    style,
    onClick,
}: {
    selectedBomItemId?: string[] | string;
    bomItem: BomItemTableData;
    style?: React.CSSProperties;
    onClick: (module: BomItemTableData) => void;
}) {
    const designators = bomItem.designator;
    const isSelected = isEqual(selectedBomItemId, bomItem.id);
    const maximumDesignatorsToShow = 3;

    const isMutating = useIsMutating({ mutationKey: updateDesignItemsQueryKey(bomItem) }) > 0;

    return (
        <BomLinePreviewContainer
            onClick={() => {
                if (!isMutating) {
                    onClick(bomItem);
                }
            }}
            style={
                isSelected
                    ? {
                          border: `2px solid ${colorSystem.primary[3]}`,
                          borderRadius: 4,
                          ...style,
                      }
                    : {
                          cursor: isMutating ? 'progress' : 'pointer',
                          ...style,
                      }
            }
        >
            <ApprovalStatus isMutating={isMutating} status={bomItem.approvalStatus} />
            <LineTitle bomItem={bomItem} />
            <span />
            <Flexbox gap={4} flexWrap="wrap" alignItems="center" maxWidth="248px" textOverflow="ellipsis">
                <Typography color={'textSecondary'} noWrap variant={'h5'}>
                    {Array.from(designators)
                        .sort(alphaNumericalStringSort)
                        .slice(0, maximumDesignatorsToShow)
                        .join(', ')}
                    {designators.length > maximumDesignatorsToShow && (
                        <Text variant="body-small" style={{ marginLeft: 8 }}>
                            +{designators.length - maximumDesignatorsToShow}
                        </Text>
                    )}
                </Typography>

                {designators.length === 0 && (
                    <Typography display="inline" color="textSecondary">
                        <Trans>No designators</Trans>
                    </Typography>
                )}
            </Flexbox>
        </BomLinePreviewContainer>
    );
}

function SubAssemblyPreview({
    subAssembly,
    selectedModuleId,
    style,
    onClick,
}: {
    subAssembly: AssemblyTableData;
    selectedModuleId: string[] | string | undefined;
    style?: React.CSSProperties;
    onClick: (module: AssemblyTableData) => void;
}) {
    const subAssemblyType = subAssembly.type;
    const isSelected = selectedModuleId === subAssembly.id;

    return (
        <BomLinePreviewContainer
            onClick={() => onClick(subAssembly)}
            style={
                isSelected
                    ? {
                          border: `2px solid ${colorSystem.primary[3]}`,
                          borderRadius: 4,
                          ...style,
                      }
                    : { cursor: 'pointer', ...style }
            }
        >
            {iconForStatus({ status: subAssembly.approvalStatus })}

            <Typography variant={'h4'} style={{ color: colorSystem.neutral[6] }}>
                {subAssemblyType.toUpperCase()}
            </Typography>
            <span />

            <Typography variant={'subtitle1'} style={{ color: colorSystem.neutral[6] }}>
                {subAssembly.designator}
            </Typography>
        </BomLinePreviewContainer>
    );
}

export function BomLinePreview({
    module,
    selectedModuleId,
    style,
    onClick,
}: {
    module: ModuleTableData;
    selectedModuleId?: ModuleTableData['id'];
    style?: React.CSSProperties;
    onClick: (module: ModuleTableData) => void;
}) {
    if (module.moduleType === 'assembly') {
        return (
            <SubAssemblyPreview
                onClick={onClick}
                style={style}
                subAssembly={module}
                selectedModuleId={selectedModuleId}
            />
        );
    }
    if (module.moduleType === 'bomItem') {
        return <BomItemPreview onClick={onClick} style={style} bomItem={module} selectedBomItemId={selectedModuleId} />;
    }

    assertUnreachable(module);
}
