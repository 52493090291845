import { t, Trans } from '@lingui/macro';
import { Flexbox, PrimaryButton } from '@luminovo/design-system';
import { useIsAdminUser } from '../../../../components/contexts/CurrentUserDetailsContext';
import { CostingTemplatePageLayout } from '../../../../components/CostingTemplates/CostingTemplatePageLayout';
import { ReadOnlyAccessAlert } from '../../../../components/CostingTemplates/ManufacturingDatabasePage';
import { route } from '../../../../utils/routes';
import CalculationTemplatesTable from './components/CalculationTemplatesTable';

const CalculationTemplatesViewPage = (): JSX.Element => {
    const isAdmin = useIsAdminUser();
    return (
        <CostingTemplatePageLayout tabs={null} title={t`Calculation`}>
            <Flexbox gap={'12px'} flexDirection={'column'}>
                <ReadOnlyAccessAlert />
                {isAdmin && (
                    <Flexbox flexDirection={'row-reverse'}>
                        <PrimaryButton href={route('/calculation/templates/add-template')} size="medium">
                            <Trans>Add template</Trans>
                        </PrimaryButton>
                    </Flexbox>
                )}
                <CalculationTemplatesTable />
            </Flexbox>
        </CostingTemplatePageLayout>
    );
};

export default CalculationTemplatesViewPage;
