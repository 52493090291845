import { Trans, t } from '@lingui/macro';
import { usePermissions } from '@luminovo/auth';
import { assertUnreachable, id } from '@luminovo/commons';
import {
    Column,
    Flexbox,
    RenderHeadProps,
    RightBoxDrawer,
    Row,
    SecondaryIconButton,
    Tag,
    Text,
    Tooltip,
    colorSystem,
} from '@luminovo/design-system';
import {
    GenericFullPart,
    GenericPartTypes,
    IncompleteGenericFullPart,
    OtsComponentFull,
    OtsFullPart,
    PartSuggestionFull,
    PreferenceStatusEnum,
    RfqContext,
    isCustomComponentFull,
    isIncompleteGenericFullPart,
    isOtsComponentFull,
    isOtsFullPart,
} from '@luminovo/http-client';
import { LifecycleTooltip } from '@luminovo/sourcing-core';
import { Add, Info } from '@mui/icons-material';
import { useCallback } from 'react';
import { PartSuggestionAvailabilityView } from '../../../../../components/PartAvailabilityView';
import { PartComplianceView } from '../../../../../components/PartComplianceView';
import { EmissionsTooltip } from '../../../../../components/PartEmissionsView/EmissionsTooltip';
import { PartEmissionsView } from '../../../../../components/PartEmissionsView/PartEmissionsView';
import { PartLifecycleView } from '../../../../../components/PartLifecycleView';
import { PartSuggestionOriginView } from '../../../../../components/PartSuggestionOriginView';
import { useDrawerContext } from '../../../../../components/contexts/ModalContext';
import {
    RenderDescription,
    RenderManufacturer,
    RenderMpn,
    StickyTableCell,
} from '../../../../../components/partColumns';
import { CpnView } from '../../../../../components/partColumns/CpnView';
import { RenderDatasheet } from '../../../../../components/partColumns/RenderDatasheet';
import { MAX_WIDTH, RenderGenericTechParams } from '../../../../../components/partColumns/RenderDescription';
import { RenderIpn } from '../../../../../components/partColumns/RenderIpn';
import { RenderGenericPackageData, RenderPackage } from '../../../../../components/partColumns/RenderPackage';
import { StickyRightHeaderTableCell } from '../../../../../components/partColumns/StickyHeadTableCell';
import { TableCell } from '../../../../../components/partColumns/TableCell';
import { SectionOfScreen } from '../../../../../resources/part/partFrontendTypes';
import { usePartPackages } from '../../../../../resources/part/partHandler';
import { AddGenericCapacitorForm } from '../../AddGenericCapacitorForm';
import {
    createGenericCapacitorFormInitialValues,
    createGenericResistorFormInitialValues,
} from '../../AddGenericPartFunctions';
import { AddGenericResistorForm } from '../../AddGenericResistorForm';
import { ipnLinkingDialog } from '../../LinkPartsDialog/useIpnLinkingDialog';
import { designatorQtyMismatchTooltip } from '../hasDesignatorOrQtyMismatch';
import { useAddPartWithBlockedManufacturerDialog } from '../useAddPartWithBlockedManufacturerDialog';
import { useBomItemCandidateMpns } from '../useBomItemCandidateMpns';
import { ManufacturerTooltip } from './ManufacturerTooltip';
import { PartialMatchesContext } from './partialMatchesTypes';

enum partialMatchesColumnIds {
    columnIpn = 'partial-matches-ipn',
    columnCpn = 'partial-matches-cpn-rev',
    columnMpn = 'partial-matches-mpn',
    columnManufacturer = 'partial-matches-manufacturer',
    columnDescription = 'partial-matches-description',
    columnPackage = 'partial-matches-package',
    columnDatasheet = 'partial-matches-datasheet',
    columnAvailability = 'partial-matches-availability',
    columnLifecycle = 'partial-matches-lifecycle',
    columnCompliance = 'partial-matches-compliance',
    columnEmissions = 'partial-matches-emissions',
    columnOrigin = 'partial-matches-origin',
}

const columnIpn: Column<PartSuggestionFull, PartialMatchesContext> = {
    id: partialMatchesColumnIds.columnIpn,
    label: <Trans>IPN</Trans>,
    render: ({ data: suggestion }) => {
        const suggestionType = suggestion.type;
        if (suggestionType === 'IncompleteGeneric') {
            return <TableCell>-</TableCell>;
        }
        if (suggestionType === 'Component' || suggestionType === 'Generic' || suggestionType === 'OffTheShelf') {
            return <RenderIpn part={suggestion.part} />;
        }
        assertUnreachable(suggestionType);
    },
};

const columnCpn: Column<PartSuggestionFull, PartialMatchesContext> = {
    id: partialMatchesColumnIds.columnCpn,
    label: <Trans>CPN • Rev</Trans>,
    render: ({ data: suggestion }) => {
        const suggestionType = suggestion.type;
        if (suggestionType === 'IncompleteGeneric') {
            return <TableCell>-</TableCell>;
        }
        if (suggestionType === 'Component' || suggestionType === 'Generic' || suggestionType === 'OffTheShelf') {
            return (
                <TableCell>
                    <CpnView part={suggestion.part} />
                </TableCell>
            );
        }
        assertUnreachable(suggestionType);
    },
};
const RenderPartialMatchesMpn = ({ data: suggestion }: Row<PartSuggestionFull>, { bomItem }: PartialMatchesContext) => {
    const candidateMpns = useBomItemCandidateMpns(bomItem, [suggestion]);
    const suggestionType = suggestion.type;
    if (suggestionType === 'IncompleteGeneric') {
        return <TableCell>-</TableCell>;
    }
    if (suggestionType === 'Component' || suggestionType === 'Generic' || suggestionType === 'OffTheShelf') {
        return <RenderMpn part={suggestion.part} candidateMpns={candidateMpns} />;
    }
    assertUnreachable(suggestionType);
};

const columnMpn: Column<PartSuggestionFull, PartialMatchesContext> = {
    id: partialMatchesColumnIds.columnMpn,
    label: <Trans>MPN</Trans>,
    render: RenderPartialMatchesMpn,
};

const columnManufacturer: Column<PartSuggestionFull, PartialMatchesContext> = {
    id: partialMatchesColumnIds.columnManufacturer,
    label: (
        <Flexbox alignItems={'center'}>
            <Trans>Manufacturer</Trans>
            <ManufacturerTooltip>
                <Info style={{ color: colorSystem.neutral[5], height: 16 }} />
            </ManufacturerTooltip>
        </Flexbox>
    ),
    render: ({ data: suggestion }) => {
        const suggestionType = suggestion.type;
        if (suggestionType === 'IncompleteGeneric') {
            return (
                <TableCell>
                    <Tag color="neutral" attention="low" label={t`Generic`} />
                </TableCell>
            );
        }
        if (suggestionType === 'Component' || suggestionType === 'Generic' || suggestionType === 'OffTheShelf') {
            return <RenderManufacturer part={suggestion.part} />;
        }
        assertUnreachable(suggestionType);
    },
};

const columnDescription: Column<PartSuggestionFull, PartialMatchesContext> = {
    id: partialMatchesColumnIds.columnDescription,
    label: <Trans>Description</Trans>,
    render: ({ data: suggestion }) => {
        const suggestionType = suggestion.type;
        if (suggestionType === 'IncompleteGeneric') {
            return (
                <TableCell>
                    <Flexbox gap="4px" maxWidth={MAX_WIDTH}>
                        <Tag color="yellow" attention="low" label={t`Incomplete`} />
                        <RenderGenericTechParams genericPart={suggestion.part} />
                    </Flexbox>
                </TableCell>
            );
        }
        if (suggestionType === 'Component' || suggestionType === 'Generic' || suggestionType === 'OffTheShelf') {
            return <RenderDescription part={suggestion.part} />;
        }
        assertUnreachable(suggestionType);
    },
};

const columnPackage: Column<PartSuggestionFull, PartialMatchesContext> = {
    id: partialMatchesColumnIds.columnPackage,
    label: <Trans>Package</Trans>,
    render: ({ data: suggestion }) => {
        const suggestionType = suggestion.type;
        if (suggestionType === 'IncompleteGeneric') {
            return <RenderGenericPackageData packageId={suggestion.part.technical_parameters.package_id} />;
        }
        if (suggestionType === 'Component' || suggestionType === 'Generic' || suggestionType === 'OffTheShelf') {
            return <RenderPackage part={suggestion.part} />;
        }
        assertUnreachable(suggestionType);
    },
};

const columnDatasheet: Column<PartSuggestionFull, PartialMatchesContext> = {
    id: partialMatchesColumnIds.columnDatasheet,
    label: '',
    render: ({ data: suggestion }) => {
        const suggestionType = suggestion.type;
        if (suggestionType === 'IncompleteGeneric') {
            return <TableCell>-</TableCell>;
        }
        if (suggestionType === 'Component' || suggestionType === 'Generic' || suggestionType === 'OffTheShelf') {
            return <RenderDatasheet part={suggestion.part} />;
        }
        assertUnreachable(suggestionType);
    },
};

const columnAvailability: Column<PartSuggestionFull, PartialMatchesContext> = {
    id: partialMatchesColumnIds.columnAvailability,
    label: <Trans>Availability</Trans>,
    render: ({ data: suggestion }, { bomItem, rfqId, assemblyId }) => {
        const suggestionType = suggestion.type;
        if (suggestionType === 'IncompleteGeneric') {
            return <TableCell>-</TableCell>;
        }
        if (suggestionType === 'Component' || suggestionType === 'Generic' || suggestionType === 'OffTheShelf') {
            const isComponentRemoved =
                (isOtsComponentFull(suggestion.part) || isCustomComponentFull(suggestion.part)) &&
                suggestion.part.state === 'Removed';
            return (
                <TableCell>
                    <PartSuggestionAvailabilityView
                        bomItem={bomItem}
                        part={suggestion.part}
                        rfqId={rfqId}
                        disabled={isComponentRemoved}
                        assemblyId={assemblyId}
                    />
                </TableCell>
            );
        }
        assertUnreachable(suggestionType);
    },
};

const columnLifecycle = {
    id: partialMatchesColumnIds.columnLifecycle,
    label: (
        <Flexbox alignItems="center">
            <Trans>Lifecycle</Trans>
            <LifecycleTooltip>
                <Info style={{ color: colorSystem.neutral[5], height: 16 }} />
            </LifecycleTooltip>
        </Flexbox>
    ),
    render: ({ data: suggestion }: Row<PartSuggestionFull>): JSX.Element => {
        const suggestionType = suggestion.type;
        if (suggestionType === 'IncompleteGeneric') {
            return <TableCell>-</TableCell>;
        }
        if (suggestionType === 'Component' || suggestionType === 'Generic' || suggestionType === 'OffTheShelf') {
            const isComponentRemoved =
                (isOtsComponentFull(suggestion.part) || isCustomComponentFull(suggestion.part)) &&
                suggestion.part.state === 'Removed';
            return (
                <TableCell>
                    <PartLifecycleView part={suggestion.part} disabled={isComponentRemoved} />
                </TableCell>
            );
        }
        assertUnreachable(suggestionType);
    },
};

const columnCompliance = {
    id: partialMatchesColumnIds.columnCompliance,
    label: <Trans>Compliance</Trans>,
    render: (
        { data: suggestion }: Row<PartSuggestionFull>,
        { assemblyIndustry }: PartialMatchesContext,
    ): JSX.Element => {
        const suggestionType = suggestion.type;
        if (suggestionType === 'IncompleteGeneric') {
            return <TableCell>-</TableCell>;
        }
        if (suggestionType === 'Component' || suggestionType === 'Generic' || suggestionType === 'OffTheShelf') {
            const isComponentRemoved =
                (isOtsComponentFull(suggestion.part) || isCustomComponentFull(suggestion.part)) &&
                suggestion.part.state === 'Removed';
            return (
                <TableCell>
                    <PartComplianceView
                        part={suggestion.part}
                        assemblyIndustry={assemblyIndustry}
                        disabled={isComponentRemoved}
                    />
                </TableCell>
            );
        }
        assertUnreachable(suggestionType);
    },
};

const columnEmissions = {
    id: partialMatchesColumnIds.columnEmissions,
    label: '',
    renderHead: ({ sharedContext }: RenderHeadProps<PartialMatchesContext>) => (
        <TableCell>
            <Flexbox alignItems="center" color={colorSystem.neutral[7]}>
                <Trans>kgCO2e</Trans>
                <EmissionsTooltip>
                    <Info style={{ color: colorSystem.neutral[5], height: 16 }} />
                </EmissionsTooltip>
            </Flexbox>
        </TableCell>
    ),
    render: ({ data: suggestion }: Row<PartSuggestionFull>): JSX.Element => {
        const suggestionType = suggestion.type;
        if (suggestionType === 'IncompleteGeneric') {
            return <TableCell>-</TableCell>;
        }
        if (suggestionType === 'Component' || suggestionType === 'Generic' || suggestionType === 'OffTheShelf') {
            const isComponentRemoved =
                (isOtsComponentFull(suggestion.part) || isCustomComponentFull(suggestion.part)) &&
                suggestion.part.state === 'Removed';
            return (
                <TableCell isComponentRemoved={isComponentRemoved}>
                    <PartEmissionsView part={suggestion.part} />
                </TableCell>
            );
        }
        assertUnreachable(suggestionType);
    },
};

export function useGenericPartEditDrawer({
    rfqContext,
    onGenericPartCreation,
}: {
    rfqContext: RfqContext;
    onGenericPartCreation: (newPart: GenericFullPart | OtsFullPart | OtsComponentFull, origin: SectionOfScreen) => void;
}) {
    const { closeDrawer, setDrawer } = useDrawerContext();
    const { data: allPackages = [] } = usePartPackages('generic-part-creatable');

    return {
        openDrawer: ({ part }: { part: IncompleteGenericFullPart }) => {
            const packageDTO = allPackages.find((p) => p.id === part.technical_parameters.package_id) ?? null;
            setDrawer(
                <RightBoxDrawer onClose={closeDrawer}>
                    <Flexbox flexDirection={'column'} gap={20} padding={'32px'} minWidth={'400px'}>
                        <Text variant={'h2'}>
                            <Trans>Edit generic part</Trans>
                        </Text>
                        {part.type === GenericPartTypes.Resistor && (
                            <AddGenericResistorForm
                                initialValues={createGenericResistorFormInitialValues(part, packageDTO)}
                                onGenericPartCreation={onGenericPartCreation}
                                rfqContext={rfqContext}
                                onSettled={closeDrawer}
                            />
                        )}
                        {part.type === GenericPartTypes.Capacitor && (
                            <AddGenericCapacitorForm
                                initialValues={createGenericCapacitorFormInitialValues(part, packageDTO)}
                                onGenericPartCreation={onGenericPartCreation}
                                rfqContext={rfqContext}
                                onSettled={closeDrawer}
                            />
                        )}
                    </Flexbox>
                </RightBoxDrawer>,
            );
        },
    };
}

const RenderActions = ({
    suggestion,
    sharedContext,
}: {
    suggestion: PartSuggestionFull;
    sharedContext: PartialMatchesContext;
}) => {
    const { rfqId, bomItem, handleAddPartOption, openIpnLinkingDialog, isSubmitting, hasDesignatorQuantityMismatch } =
        sharedContext;
    const rfqContext: RfqContext = { type: 'WithinRfQ', rfq_id: rfqId };
    const { permissions } = usePermissions();

    const { openDrawer } = useGenericPartEditDrawer({ rfqContext, onGenericPartCreation: handleAddPartOption });
    const { part: suggestedPart, origin: suggestionOrigin } = suggestion;

    const handleAddPart = useCallback(() => {
        if (isIncompleteGenericFullPart(suggestedPart)) {
            return openDrawer({ part: suggestedPart });
        }

        const ipnLinkingData = ipnLinkingDialog({
            partOptions: bomItem.parts,
            partToAddAsPartOption: suggestedPart,
            permissions,
        });

        if (ipnLinkingData.shouldOpenIpnLinkingDialog) {
            const { ipn, partsToLink, partToAddAsPartOption } = ipnLinkingData;
            return openIpnLinkingDialog({
                ipn,
                partsToLink,
                partToAddAsPartOption,
                addPart: handleAddPartOption,
            });
        }

        return handleAddPartOption(suggestedPart, 'partialMatches', undefined, suggestionOrigin ?? undefined);
    }, [
        bomItem.parts,
        handleAddPartOption,
        openDrawer,
        openIpnLinkingDialog,
        permissions,
        suggestedPart,
        suggestionOrigin,
    ]);

    const { openDialog: openBlockedManuDialog } = useAddPartWithBlockedManufacturerDialog();

    const handleClick = useCallback(() => {
        if (isOtsFullPart(suggestedPart) && suggestedPart.preference_status === PreferenceStatusEnum.Blocked) {
            return openBlockedManuDialog(suggestedPart.mpn, suggestedPart.manufacturer.name, handleAddPart);
        }

        if (
            isOtsComponentFull(suggestedPart) &&
            suggestedPart.manufacturer_preference_status === PreferenceStatusEnum.Blocked
        ) {
            const manufacturer =
                suggestedPart.matches.reduce<string[]>((acc, { part }) => {
                    if (part.type === 'OffTheShelf' && part.data.preference_status === PreferenceStatusEnum.Blocked) {
                        acc.push(part.data.manufacturer.name);
                    }
                    return acc;
                }, [])[0] ?? '';

            return openBlockedManuDialog(suggestedPart.id, manufacturer, handleAddPart);
        }

        return handleAddPart();
    }, [suggestedPart, handleAddPart, openBlockedManuDialog]);

    return (
        <Tooltip title={hasDesignatorQuantityMismatch ? designatorQtyMismatchTooltip() : ''}>
            <span>
                <SecondaryIconButton
                    disabled={isSubmitting || hasDesignatorQuantityMismatch}
                    className={id('design/button_add_part_suggestion')}
                    onClick={(e) => {
                        e.stopPropagation();
                        handleClick();
                    }}
                    size="small"
                >
                    <Add fontSize="inherit" />
                </SecondaryIconButton>
            </span>
        </Tooltip>
    );
};

const columnSuggestionOrigin: Column<PartSuggestionFull, PartialMatchesContext> = {
    id: partialMatchesColumnIds.columnOrigin,
    label: <Trans>Origin</Trans>,
    overrides: {
        HeaderTableCell: StickyRightHeaderTableCell,
    },
    render: ({ data: suggestion }, sharedContext) => {
        return (
            <StickyTableCell>
                <Flexbox gap="24px" alignItems="center" justifyContent="space-between">
                    <PartSuggestionOriginView suggestion={suggestion} />
                    <RenderActions suggestion={suggestion} sharedContext={sharedContext} />
                </Flexbox>
            </StickyTableCell>
        );
    },
};

const allPartialMatchesColumns = [
    columnIpn,
    columnCpn,
    columnMpn,
    columnManufacturer,
    columnDescription,
    columnPackage,
    columnDatasheet,
    columnAvailability,
    columnLifecycle,
    columnCompliance,
    columnEmissions,
    columnSuggestionOrigin,
];

export const getPartialMatchesColumns = ({ hasPartOptionsWithCpns }: { hasPartOptionsWithCpns: boolean }) => {
    return allPartialMatchesColumns.filter((col) => {
        if (!hasPartOptionsWithCpns && col.id === partialMatchesColumnIds.columnCpn) {
            return false;
        }
        return true;
    });
};
