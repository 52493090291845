import {
    ApprovedIcon,
    CloseIcon,
    MinusIcon,
    PendingIcon,
    Tooltip,
    WarningCircleIcon,
    colorSystem,
} from '@luminovo/design-system';
import {
    ApprovalStatus,
    BomItemApprovalStatus,
    BomItemStatus,
    DoNotPlace,
    ManufacturingScenarioStatus,
} from '@luminovo/http-client';
import {
    CheckCircleOutlineRounded as CheckCircleOutlineRoundedIcon,
    ErrorOutline as ErrorOutlineIcon,
    ErrorRounded,
    HelpOutlineRounded as HelpOutlineRoundedIcon,
    HighlightOffRounded as HighlightOffRoundedIcon,
} from '@mui/icons-material';
import { styled } from '@mui/material';
import { Status } from '../../resources/frontendTypes';
import { themeLuminovo } from '../../themes';
import { approvalStatusTranslations } from '../i18n';
import { transEnum } from '../localization/TransEnum';

type StatusType = BomItemApprovalStatus | BomItemStatus | Status;

export const GreenCheckCircleOutlineRoundedIcon = styled(CheckCircleOutlineRoundedIcon)({
    color: themeLuminovo.palette.success.main,
});

const GreyHelpOutlineRoundedIcon = styled(HelpOutlineRoundedIcon)({
    color: colorSystem.neutral[5],
});

export const YellowHelpOutlineRoundedIcon = styled(ErrorOutlineIcon)({
    color: themeLuminovo.palette.warning.main,
});

export const WarningExclamationRoundedIcon = styled(ErrorRounded)({
    color: themeLuminovo.palette.warning.main,
});

export const RedHighlightOffRoundedIcon = styled(HighlightOffRoundedIcon)({
    color: themeLuminovo.palette.error.main,
});

export const iconForStatusWithTooltip = ({ status, tooltip }: { status: StatusType; tooltip: string }) => {
    return (
        <Tooltip title={tooltip} placement="right" arrow>
            <span>{iconForStatus({ status })}</span>
        </Tooltip>
    );
};

export const iconForStatus = ({ status, size }: { status: StatusType; size?: 'small' | 'large' }) => {
    const heightAndWidth = size === 'large' ? '20px' : '16px';
    switch (status) {
        case BomItemApprovalStatus.Approved:
        case ApprovalStatus.Approved:
        case Status.success: {
            return <ApprovedIcon height={heightAndWidth} width={heightAndWidth} />;
        }
        case BomItemApprovalStatus.Pending:
        case ApprovalStatus.Pending:
        case Status.info: {
            return <PendingIcon height={heightAndWidth} width={heightAndWidth} />;
        }
        case BomItemApprovalStatus.Rejected:
        case ApprovalStatus.Rejected:
        case Status.error: {
            return <CloseIcon height={heightAndWidth} width={heightAndWidth} />;
        }
        case BomItemApprovalStatus.DNP:
        case DoNotPlace.DNP: {
            return <MinusIcon height={heightAndWidth} width={heightAndWidth} />;
        }
        case BomItemApprovalStatus.Warning:
        case DoNotPlace.Warning:
        case ManufacturingScenarioStatus.Warning:
        case Status.warning:
            return <WarningCircleIcon style={{ fontSize: heightAndWidth }} />;
    }
};

export const iconForBomItemApprovalStatusWithTooltip = (status: BomItemApprovalStatus, tooltip?: string) => {
    return (
        <Tooltip title={tooltip ? tooltip : transEnum(status, approvalStatusTranslations)} placement="top" arrow>
            <span>{iconForStatus({ status })}</span>
        </Tooltip>
    );
};

export const iconForSolutionStatusWithTooltip = (status: Status) => {
    switch (status) {
        case Status.success: {
            return <GreenCheckCircleOutlineRoundedIcon />;
        }
        case Status.warning: {
            return <YellowHelpOutlineRoundedIcon />;
        }
        case Status.error: {
            return <RedHighlightOffRoundedIcon />;
        }
        case Status.info: {
            return <GreyHelpOutlineRoundedIcon />;
        }
    }
};
