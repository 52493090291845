import * as r from 'runtypes';
import { DriverIdParameterRuntype } from '../driver';
import { endpoint } from '../http/endpoint';
import { ManufacturingEntityStatusRuntype } from '../sharedManufacturingBackendTypes';
import {
    ActivitiesDTORuntype,
    ActivityDTORuntype,
    ActivityOverwriteDTORuntype,
    ActivityOverwritePatchDTORuntype,
    ActivityOverwritePostDTORuntype,
    ActivityPostDTORuntype,
} from './activityBackendTypes';

export const activitiesEndpoints = {
    'POST /activities': endpoint({
        description: 'Creates an activity',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: ActivityPostDTORuntype,
        responseBody: r.Record({
            data: ActivityPostDTORuntype,
        }),
    }),

    'GET /activities': endpoint({
        description: 'Returns an array of activities',
        pathParams: r.Undefined,
        queryParams: r.Record({
            active: r.Boolean.optional(),
            resource_id: r.String.optional(),
            driver_id: DriverIdParameterRuntype.optional(),
        }),
        requestBody: r.Undefined,
        responseBody: r.Record({
            data: ActivitiesDTORuntype,
        }),
    }),
    'GET /activities/:activityId': endpoint({
        description: 'Returns a single activity',
        pathParams: r.Record({ activityId: r.String }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({
            data: ActivityDTORuntype,
        }),
    }),
    'PATCH /activities/:activityId': endpoint({
        description: 'Updates an activity',
        pathParams: r.Record({ activityId: r.String }),
        queryParams: r.Undefined,
        requestBody: ActivityPostDTORuntype.Or(r.Record({ status: ManufacturingEntityStatusRuntype })),
        responseBody: r.Record({
            data: ActivityPostDTORuntype,
        }),
    }),

    'DELETE /activities/:activityId': endpoint({
        description: 'Deletes an activity',
        pathParams: r.Record({ activityId: r.String }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({ deleted: r.Number }),
    }),

    'POST /activities/:activityId/overwrites': endpoint({
        description: 'Creates an activity overwrite for a specific manufacturing scenario',
        pathParams: r.Record({
            activityId: r.String,
        }),
        queryParams: r.Undefined,
        requestBody: ActivityOverwritePostDTORuntype,
        responseBody: r.Record({
            data: ActivityOverwriteDTORuntype,
        }),
        invalidates: ['GET /manufacturing-scenarios/:manufacturingScenarioId/full-summary'],
    }),

    'PATCH /activities/:activityId/overwrites/:activityOverwriteId': endpoint({
        description: 'Updates an activity overwrite for a specific manufacturing scenario',
        pathParams: r.Record({
            activityId: r.String,
            activityOverwriteId: r.String,
        }),
        queryParams: r.Undefined,
        requestBody: ActivityOverwritePatchDTORuntype,
        responseBody: r.Record({
            data: ActivityOverwriteDTORuntype,
        }),
        invalidates: ['GET /manufacturing-scenarios/:manufacturingScenarioId/full-summary'],
    }),

    'DELETE /activities/:activityId/overwrites': endpoint({
        description: 'Deletes an activity overwrite',
        pathParams: r.Record({ activityId: r.String }),
        queryParams: r.Record({ manufacturing_scenario: r.String }),
        requestBody: r.Undefined,
        responseBody: r.Record({ deleted: r.Number }),
        invalidates: ['GET /activities', 'GET /manufacturing-scenarios/:manufacturingScenarioId/full-summary'],
    }),

    'GET /activities/:activityId/overwrites': endpoint({
        description: 'Fetch activity overwrite for activity manufacturing scenario combination',
        pathParams: r.Record({
            activityId: r.String,
        }),
        queryParams: r.Record({ manufacturing_scenario: r.String }),
        requestBody: r.Undefined,
        responseBody: r.Record({
            data: ActivityOverwriteDTORuntype,
        }),
    }),
};
