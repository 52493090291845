import { KeyboardTabRounded } from '@mui/icons-material';
import { Collapse, List, ListItemButton, ListItemIcon, styled, SvgIconProps } from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { usePersistedState } from '../../hooks/usePersistedState';
import { colorSystem } from '../../theme';
import { TertiaryIconButton } from '../buttons';
import { Flexbox } from '../Flexbox';
import { StickyLayout } from '../layout/StickyLayout';
import { Text } from '../Text';
import { Tooltip } from '../Tooltip';

type CollapsibleSidebarLinkItem = {
    type: 'link';
    href: string;
    label: string | JSX.Element;
    Icon: React.ComponentType<SvgIconProps>;
    disabled?: boolean;
    hidden?: boolean;
    /**
     * Override the default selection behavior.
     * By default, an item is selected when its href matches the current URL path
     * or if the current URL path starts with the href followed by a forward slash
     */
    isSelected?: (pathname: string) => boolean;
};
type CollapsibleSidebarItem = CollapsibleSidebarLinkItem;

interface CollapsibleSidebarProps {
    id: string;
    title: string;
    contents: Array<CollapsibleSidebarItem>;
    intialOpen?: boolean;
}

const StyledList = styled(List)({
    height: '100%',
    boxSizing: 'border-box',
});

const StyledListItemButton = styled(ListItemButton)({
    color: colorSystem.neutral[6],
    padding: '6px 0px',
    margin: '4px 0px',
    borderRadius: '8px',
    '&:hover': {
        color: colorSystem.neutral[8],
        background: colorSystem.neutral.white,
    },
    '&.Mui-selected': {
        color: colorSystem.primary[7],
        borderLeft: 'transparent',
        background: colorSystem.primary[1],
    },
    '&.Mui-selected:hover': {
        background: colorSystem.neutral[0],
    },
});

export function CollapsibleSidebar({ title, contents, id, intialOpen = true }: CollapsibleSidebarProps): JSX.Element {
    const [isOpen, setIsOpen] = usePersistedState(id, intialOpen);
    const visibleContents = contents.filter((link) => !link.hidden);

    return (
        <StickyLayout
            style={{
                borderRight: `1px solid ${colorSystem.neutral[2]}`,
                backgroundColor: 'white',
                zIndex: 3,
                height: '100%',
                padding: '12px',
                transition: 'all 0.2s ease-in',
                boxSizing: 'border-box',
                minWidth: isOpen ? '200px' : '64px',
            }}
        >
            <StyledList>
                <Flexbox justifyContent={'space-between'} alignItems={'center'}>
                    <Collapse in={isOpen} orientation="horizontal">
                        <Text variant={'h2'}>{title}</Text>
                    </Collapse>
                    <TertiaryIconButton onClick={() => setIsOpen((isOpen) => !isOpen)} disableRipple>
                        <KeyboardTabRounded
                            style={{ transform: isOpen ? 'rotate(180deg)' : undefined }}
                            fontSize="inherit"
                        />
                    </TertiaryIconButton>
                </Flexbox>

                {visibleContents.map((props, i) => {
                    return <CollapsibleSidebarListItem key={i} isOpen={isOpen} {...props} />;
                })}
            </StyledList>
        </StickyLayout>
    );
}

export function CollapsibleSidebarListItem({
    href,
    label,
    Icon,
    isOpen,
    disabled,
    isSelected,
}: CollapsibleSidebarLinkItem & { isOpen: boolean; selected?: boolean }): JSX.Element {
    const { pathname } = useLocation();
    const history = useHistory();
    const selected = isSelected ? isSelected(pathname) : pathname === href;

    return (
        <Tooltip variant="white" title={label} disableHoverListener={isOpen} placement="right">
            <span>
                <StyledListItemButton
                    onClick={() => disabled || history.push(href)}
                    disableRipple
                    selected={selected}
                    disabled={disabled}
                >
                    <ListItemIcon style={{ color: 'inherit', marginLeft: '-6px' }}>
                        <Icon fontSize="small" style={{ color: 'inherit' }} />
                    </ListItemIcon>

                    <Collapse in={isOpen} orientation="horizontal">
                        <ListItemText
                            primary={
                                <Text
                                    variant="h5"
                                    style={{
                                        color: 'inherit',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        paddingRight: '8px',
                                    }}
                                >
                                    {label}
                                </Text>
                            }
                        />
                    </Collapse>
                </StyledListItemButton>
            </span>
        </Tooltip>
    );
}
