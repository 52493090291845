import { colorSystem, Flexbox, Tag } from '@luminovo/design-system';
import { AssemblyTypeEnum } from '@luminovo/http-client';
import { Typography } from '@mui/material';
import React from 'react';
import { transEnum } from '../../../components/localization/TransEnum';
import { assemblyTypePublicTranslations } from '../../../resources/rfq/i18n';

interface DetailsProps {
    designator: string | undefined;
    type: AssemblyTypeEnum | undefined;
}

export const Details: React.FunctionComponent<DetailsProps> = ({ designator, type }) => {
    return (
        <Flexbox gap={64}>
            <Flexbox alignItems="center">
                <Typography variant="h1" style={{ color: colorSystem.neutral[8] }}>
                    {designator}
                </Typography>
                {type && (
                    <Tag
                        color={'neutral'}
                        label={transEnum(type, assemblyTypePublicTranslations)}
                        style={{ marginLeft: '8px', marginRight: '8px' }}
                    />
                )}
            </Flexbox>
        </Flexbox>
    );
};
