import { colorSystem, Flexbox } from '@luminovo/design-system';
import { styled } from '@mui/material';

export const BomImporterSidebarContainer = styled(Flexbox)({
    border: `1px solid ${colorSystem.neutral[2]}`,
    padding: '12px',
    borderRadius: 8,
    background: colorSystem.neutral[0],
    gap: '12px',
    flexDirection: 'column',
});
