import { defaultDropAnimationSideEffects, DragOverlay, DropAnimation } from '@dnd-kit/core';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Trans } from '@lingui/macro';
import { colorSystem, Flexbox, TertiaryIconButton, Text } from '@luminovo/design-system';
import { PCBV2CadFile } from '@luminovo/http-client';
import { VisibilityOff as VisibilityOffIcon, Visibility as VisibilityOnIcon } from '@mui/icons-material';
import { CircularProgress, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import { keepPreviousData } from '@tanstack/react-query';
import React from 'react';
import { createPortal } from 'react-dom';
import { useHttpQuery } from '../../../../resources/http/useHttpQuery';
import { analytics } from '../../../../utils/analytics';
import { PcbFileName } from '../../components/PcbFileName';
import { isPcbFilePreviewAvailable } from '../../utils/isPcbFilePreviewAvailable';
import { CadViewerAction } from '../utils/cadViewerTypes';
import { CadFileColorSelector } from './CadFileColorSelector';

const dropAnimation: DropAnimation = {
    sideEffects: defaultDropAnimationSideEffects({
        styles: {
            active: {
                opacity: '0.5',
            },
        },
    }),
};

const StyledPaper = styled(Paper)({
    display: 'var(--is-dragging-hide, flex)',
    backgroundColor: colorSystem.neutral.white,
    padding: '16px',
    borderRadius: '4px',
    border: `solid transparent`,
    marginInline: '8px',
    cursor: 'grab',
    height: '30px',
    '&:hover': {
        border: `solid ${colorSystem.primary[5]}`,
    },
});

type CadFileDetailsProps = {
    file: PCBV2CadFile;
    pcbId?: string;
    isActive?: boolean;
    assemblyId?: string;
    style?: React.CSSProperties;
    dispatch?: React.Dispatch<CadViewerAction>;
};

const ActiveCadFileCard: React.FunctionComponent<{ sortableProps: ReturnType<typeof useSortable> }> = ({
    sortableProps,
}) => {
    const { attributes, listeners, setNodeRef, transform, transition } = sortableProps;

    return (
        <StyledPaper
            {...listeners}
            {...attributes}
            ref={setNodeRef}
            elevation={3}
            style={{
                display: 'var(--is-dragging-view, flex)',
                backgroundColor: colorSystem.neutral[2],
                borderColor: colorSystem.neutral[5],
                borderStyle: 'dotted',
                boxShadow: '0px 2px 4px 0px rgba(82, 129, 183, 0.32)',
                transition,
                transform: CSS.Transform.toString(transform),
            }}
        />
    );
};

const BaseCadFileCard: React.FunctionComponent<
    CadFileDetailsProps & {
        sortableProps: ReturnType<typeof useSortable>;
    }
> = ({ file, assemblyId, pcbId, dispatch, style, sortableProps }) => {
    const shouldFetchData = assemblyId !== undefined && pcbId !== undefined && file.isVisible;
    const { data: svgString, isLoading: isLoadingFile } = useHttpQuery(
        'GET /ems/renderer/assemblies/:assemblyId/versions/:pcbId/files/:fileName',
        {
            pathParams: { assemblyId: assemblyId ?? '', pcbId: pcbId ?? '', fileName: file.name },
            queryParams: { format: 'svg' },
        },
        {
            enabled: shouldFetchData && isPcbFilePreviewAvailable(file),
            // TODO: This is a workaround until we have websockets - we pull every second.
            retryDelay: 1000,
            retry: true,
            placeholderData: keepPreviousData,
        },
    );

    const isLoading = shouldFetchData ? isLoadingFile : !isPcbFilePreviewAvailable(file);
    const isDisabled = isLoading || file.isVisible === false || svgString === null;
    const { attributes, listeners, setNodeRef, transform, transition } = sortableProps;

    const handleVisibilityChange = () => {
        dispatch?.({
            type: 'TOGGLE_CAD_VISIBILITY',
            payload: {
                id: file.id,
                isVisible: !file.isVisible,
            },
        });
    };

    const handleColorChange = (color: string) => {
        analytics.track('change_cad_file_color', {
            // eslint-disable-next-line camelcase
            pcb_id: pcbId,
            // eslint-disable-next-line camelcase
            assembly_uuid: assemblyId,
        });
        dispatch?.({
            type: 'UPDATE_CAD_COLOR',
            payload: {
                id: file.id,
                fileColor: color,
            },
        });
    };

    return (
        <StyledPaper
            {...listeners}
            {...attributes}
            ref={setNodeRef}
            elevation={3}
            style={{
                ...style,
                margin: 0,
                boxShadow: '0px 2px 4px 0px rgba(82, 129, 183, 0.32)',
                transition,
                transform: CSS.Transform.toString(transform),
            }}
        >
            <Flexbox
                alignItems={'center'}
                gap={'8px'}
                style={{
                    width: '100%',
                    opacity: isDisabled ? 0.5 : 1,
                }}
            >
                {isLoading ? (
                    <CircularProgress size={16} />
                ) : (
                    <TertiaryIconButton
                        onClick={handleVisibilityChange}
                        size="small"
                        style={{
                            color: colorSystem.neutral[7],
                            fontSize: '16px',
                        }}
                    >
                        {file.isVisible ? (
                            <VisibilityOnIcon fontSize="inherit" />
                        ) : (
                            <VisibilityOffIcon fontSize="inherit" />
                        )}
                    </TertiaryIconButton>
                )}
                <Flexbox flexDirection={'column'} style={{ width: '100%' }}>
                    <PcbFileName
                        text={file.name}
                        TextProps={{
                            variant: 'h5',
                            style: { marginRight: 'auto' },
                        }}
                    />
                    {svgString === null && (
                        <Text variant="body-small">
                            <Trans>No preview</Trans>
                        </Text>
                    )}
                </Flexbox>
                {!isLoading && <CadFileColorSelector fileColor={file.fileColor} onColorChange={handleColorChange} />}
            </Flexbox>
        </StyledPaper>
    );
};

export const CadFileCard = ({ file, pcbId, assemblyId, isActive, style, dispatch }: CadFileDetailsProps) => {
    const sortableProps = useSortable({ id: file.id });

    if (isActive) {
        return <ActiveCadFileCard sortableProps={sortableProps} />;
    }

    return (
        <BaseCadFileCard
            file={file}
            assemblyId={assemblyId}
            pcbId={pcbId}
            dispatch={dispatch}
            style={style}
            sortableProps={sortableProps}
        />
    );
};

export const DraggedCadFileCard: React.FunctionComponent<{ file: PCBV2CadFile }> = ({ file }): JSX.Element => {
    return createPortal(
        <DragOverlay dropAnimation={dropAnimation} adjustScale={true}>
            <CadFileCard
                file={file}
                style={{
                    display: 'var(--is-dragging-view, flex)',
                    boxShadow: '0px 2px 4px 0px rgba(82, 129, 183, 0.32)',
                    marginInlineStart: 0,
                    cursor: 'grabbing',
                }}
            />
        </DragOverlay>,
        document.body,
    );
};
