import * as r from 'runtypes';
import { endpoint } from '../http/endpoint';
import { CorestaffCredentialsInputDTORuntype, CorestaffResponseBodyDTORuntype } from './corestaffBackendTypes';

export const corestaffEndpoints = {
    'GET /3rdparty/corestaff/credentials': endpoint({
        description: 'Returns credentials for corestaff integration',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: CorestaffResponseBodyDTORuntype,
    }),
    'POST /3rdparty/corestaff/credentials': endpoint({
        description: 'Uploads credentials for corestaff API integration',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: CorestaffCredentialsInputDTORuntype,
        responseBody: CorestaffResponseBodyDTORuntype,
        invalidates: ['GET /3rdparty/corestaff/credentials'],
    }),
    'PATCH /3rdparty/corestaff/credentials': endpoint({
        description: 'Updates credentials for corestaff API integration',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: CorestaffCredentialsInputDTORuntype,
        responseBody: CorestaffResponseBodyDTORuntype,
        invalidates: ['GET /3rdparty/corestaff/credentials'],
    }),
    'DELETE /3rdparty/corestaff/credentials': endpoint({
        description: 'Deletes existing credentials for corestaff API integration',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({ deleted: r.Number }),
        invalidates: ['GET /3rdparty/corestaff/credentials'],
    }),
};
