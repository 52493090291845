import { t, Trans } from '@lingui/macro';
import { id } from '@luminovo/commons';
import { colorSystem, Flexbox, Message, SecondaryButton } from '@luminovo/design-system';
import { PcbSpecificationProperties, PCBV2, SpecificationStatusEnum } from '@luminovo/http-client';
import { InfoOutlined } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import { Prompt, useHistory } from 'react-router';
import { extractChangedFieldNames, updatePcbAnalytic } from '../../../resources/pcb/analytics/analytic';
import {
    hasDrillFiles,
    hasPcbBeenApprovedOnce,
    isLegacyGerber,
    isPcbAnalysisInProgress,
    isPcbSetupWithoutFiles,
} from '../../../resources/pcb/pcbFunctions';
import {
    useMutationUpdatePcbSpecification,
    useMutationUpdatePcbSpecificationStatus,
    usePcbOfAssembly,
} from '../../../resources/pcb/pcbHandlers';
import { route } from '../../../utils/routes';
import { ViewContext } from '../../Bom/components/ModuleTableData';
import { PcbActionToolbar, useCurrentRouteDetails } from '../components/FormActionToolbar';
import { getPcbAnalysisId } from '../components/PcbAnalysis';
import { PcbPdfExtractionModal } from '../components/PcbAnalysis/PcbPdfSpecificationAnalysisModal';
import { PcbLoading } from '../components/PcbLoading';
import { PcbPageLayout } from '../components/PcbMainLayout';
import { PcbModuleContextProvider } from '../components/PcbModuleContextProvider';
import { PdfExtractionMessageBox } from '../components/PcbPdfExtraction/PcbExtractionMessage';
import { PcbSidebarLayout } from '../components/PcbSidebarLayout';
import { useShouldShowPDFExtraction } from '../utils/pcbFeatureFlags';
import { usePcbApprovalState } from '../utils/usePcbApprovalState';
import { usePcbFormData } from '../utils/usePcbFormData';
import { PcbRenderer } from './components/PcbRenderer';
import { PcbSpecificationForm } from './components/PcbSpecificationForm';

type PcbModuleProps = {
    assemblyId: string;
    rfqId: string;
    isEditable: boolean;
    viewContext: ViewContext;
};

const usePcbSpecificationFormFunction = ({ pcb, assemblyId }: { pcb: PCBV2; assemblyId: string }) => {
    const { mutateAsync } = useMutationUpdatePcbSpecification({
        pcbId: pcb.id,
        specificationId: pcb.specifications[0]?.id ?? '',
    });
    const handleSubmit = async (form: PcbSpecificationProperties) => {
        const mutateData = await mutateAsync({ settings: form });
        return mutateData;
    };

    return {
        onSubmit: handleSubmit,
    };
};

function PcbModule({ assemblyId, rfqId, isEditable, pcb, viewContext }: PcbModuleProps & { pcb: PCBV2 }) {
    const [isEditing, setIsEditing] = React.useState(false);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const isPCBLoading = isPcbAnalysisInProgress(pcb);

    const { defaultValues, pcbCapabilities, useFormReturn, mergedValues, extractedPcbCapabilities } = usePcbFormData({
        pcb,
    });
    const { onSubmit } = usePcbSpecificationFormFunction({ pcb, assemblyId });

    const { mutateAsync: setPcbSpecificationStatus, isPending: isUpdatingSpecificationStatus } =
        useMutationUpdatePcbSpecificationStatus({
            pcbId: pcb.id,
            specificationId: pcb.specifications[0]?.id ?? '',
        });
    const wasPcbApprovedOnce = hasPcbBeenApprovedOnce(pcb);

    const {
        reset,
        setValue,
        formState: { dirtyFields },
    } = useFormReturn;

    const handleCancel = async () => {
        reset(defaultValues);
        setIsEditing(false);
        // We can only allow this if the PCB has been approved once before
        // The cancel button should be disabled otherwise
        if (wasPcbApprovedOnce) {
            await setPcbSpecificationStatus(SpecificationStatusEnum.Active);
        }
    };

    const { currentRoute } = useCurrentRouteDetails({ viewContext });
    const { onApprovalClick, isRouteApproved } = usePcbApprovalState({
        pcbId: pcb.id,
        currentRoute: currentRoute?.route,
    });

    const handleEdit = async () => {
        if (isEditing) return;
        if (isRouteApproved === true) {
            await onApprovalClick(false);
        }
        await setPcbSpecificationStatus(SpecificationStatusEnum.Changed);
    };

    const handleSubmit = useFormReturn.handleSubmit(async (form) => {
        onSubmit(form);
        if (isRouteApproved === false) {
            await onApprovalClick(true);
        }
        // Clean up the PCB analysis snackbar
        closeSnackbar(getPcbAnalysisId(pcb.id));
        localStorage.removeItem(getPcbAnalysisId(pcb.id));

        enqueueSnackbar(t`PCB specification saved.`, {
            variant: 'success',
        });

        updatePcbAnalytic({
            pcbId: pcb.id,
            rfqId,
            assemblyId,
            fields: extractChangedFieldNames(dirtyFields),
        });
    });

    React.useEffect(() => {
        setIsEditing(isRouteApproved === false && isEditable ? true : false);
    }, [isRouteApproved, isEditable]);

    const handleExtractionModalClose = (value: boolean) => {
        if (value) {
            handleEdit();
            setValue('board', mergedValues.board, { shouldDirty: true });
            setValue('layerStack', mergedValues.layerStack, { shouldDirty: true });
        }
    };

    const { shouldShowPDFExtraction } = useShouldShowPDFExtraction();

    return (
        <PcbSidebarLayout viewContext={viewContext} assemblyId={assemblyId} rfqId={rfqId} pcb={pcb}>
            <PcbModuleContextProvider>
                <FormProvider {...useFormReturn}>
                    {shouldShowPDFExtraction && (
                        <PcbPdfExtractionModal
                            pcb={pcb}
                            handleClose={handleExtractionModalClose}
                            extractedPcbCapabilities={extractedPcbCapabilities}
                        />
                    )}
                    <Prompt
                        message={t`This form has unsaved changes. Are you sure you want to lose your progress?`}
                        when={useFormReturn.formState.isDirty}
                    />
                    <PcbActionToolbar
                        viewContext={viewContext}
                        rfqId={rfqId}
                        assemblyId={assemblyId}
                        pcb={pcb}
                        pageTitle={t`PCB specification`}
                        formId={'pcb-specification-form'}
                        isEditing={isEditing}
                        isEditable={isEditable}
                        submitButtonOptions={{
                            id: id('design/button_pcb_specification_save'),
                            disabled: isUpdatingSpecificationStatus,
                        }}
                        cancelButtonOptions={{
                            onClick: handleCancel,
                            disabled: !wasPcbApprovedOnce || isUpdatingSpecificationStatus,
                        }}
                        editButtonOptions={{
                            onClick: handleEdit,
                            disabled: isUpdatingSpecificationStatus,
                        }}
                    />
                    {!isPCBLoading && !isPcbSetupWithoutFiles(pcb) && !hasDrillFiles(pcb) && (
                        <MissingDrillFilesNotification assemblyId={assemblyId} rfqId={rfqId} />
                    )}
                    <PcbPageLayout>
                        <Box
                            style={{
                                height: '100%',
                                overflow: 'scroll',
                                padding: '24px 16px',
                                backgroundColor: colorSystem.neutral[1],
                            }}
                            id={id('design/box_pcb_specification_form')}
                        >
                            {isLegacyGerber(pcb) && (
                                <Flexbox
                                    flexDirection={'row'}
                                    style={{
                                        alignItems: 'center',
                                        background: colorSystem.yellow[1],
                                        padding: '14px 24px',
                                    }}
                                >
                                    <InfoOutlined
                                        fontSize="small"
                                        fill={colorSystem.yellow[8]}
                                        style={{ marginRight: '4px', color: colorSystem.yellow[8] }}
                                    />
                                    <Flexbox alignItems={'center'}>
                                        <Typography variant="h5" style={{ color: colorSystem.yellow[8] }}>
                                            <Trans>Legacy Gerber files detected.</Trans>
                                        </Typography>
                                    </Flexbox>
                                </Flexbox>
                            )}
                            <form id="pcb-specification-form" onSubmit={handleSubmit}>
                                <PcbSpecificationForm
                                    pcb={pcb}
                                    assemblyId={assemblyId}
                                    isEditable={isEditable}
                                    isEditing={isEditing}
                                    pcbCapabilities={pcbCapabilities}
                                >
                                    <PdfExtractionMessageBox capabilities={extractedPcbCapabilities} pcbId={pcb.id} />
                                </PcbSpecificationForm>
                            </form>
                        </Box>
                        <PcbRenderer
                            pcb={pcb}
                            assemblyId={assemblyId}
                            rfqId={rfqId}
                            isRfqEditable={isEditable}
                            viewContext={viewContext}
                        />
                    </PcbPageLayout>
                </FormProvider>
            </PcbModuleContextProvider>
        </PcbSidebarLayout>
    );
}

function MissingDrillFilesNotification({ assemblyId, rfqId }: { assemblyId: string; rfqId: string }) {
    const history = useHistory();
    const ActionButton = () => {
        return (
            <SecondaryButton
                size="small"
                onClick={() =>
                    history.push(
                        route('/rfqs/:rfqId/bom/assembly/:assemblyId/pcb/files', {
                            assemblyId,
                            rfqId,
                        }),
                    )
                }
            >
                <Trans>Upload them here</Trans>
            </SecondaryButton>
        );
    };

    return (
        <Message
            attention="high"
            variant="yellow"
            title={t`We couldn't find any drill files in your project.`}
            size="small"
            overrides={{ ActionButton }}
        />
    );
}

function PcbModuleWrapper({ assemblyId, ...rest }: PcbModuleProps) {
    const { data: pcb } = usePcbOfAssembly({ assemblyId });

    if (!pcb) {
        return null;
    }

    return <PcbModule assemblyId={assemblyId} pcb={pcb} {...rest} />;
}

export const PcbSpecificationTab = ({ assemblyId, rfqId, isEditable, viewContext }: PcbModuleProps) => {
    return (
        <PcbLoading assemblyId={assemblyId} rfqId={rfqId} isEditable={isEditable} viewContext={viewContext}>
            <PcbModuleWrapper assemblyId={assemblyId} rfqId={rfqId} isEditable={isEditable} viewContext={viewContext} />
        </PcbLoading>
    );
};
