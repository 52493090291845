import * as r from 'runtypes';
import { UserDTORuntype } from '../user';

export type CommentType = r.Static<typeof CommentTypeRuntype>;
const CommentTypeRuntype = r.Union(
    r.Literal('Calculation'),
    r.Literal('DesignItem'),
    r.Literal('QuoteTracking'),
    r.Literal('Rfq'),
    r.Literal('Assembly'),
    r.Literal('QuoteRequest'),
);

export interface CommentEntity extends r.Static<typeof CommentEntityRuntype> {}
export const CommentEntityRuntype = r.Record({
    type: CommentTypeRuntype,
    data: r.Array(r.String).Or(r.String),
});

export type CommentCategory = r.Static<typeof CommentCategoryRuntype>;
export const CommentCategoryRuntype = r.Union(r.Literal('Internal'), r.Literal('Public'));

export interface CommentDTO extends r.Static<typeof CommentRuntype> {}
export const CommentRuntype = r.Record({
    id: r.String,
    content: r.String,
    created_by: UserDTORuntype.omit('customer').And(r.Record({ customer: r.String.nullable() })),
    resolved_at: r.String.nullable(),
    updated_at: r.String,
    category: CommentCategoryRuntype,
});
