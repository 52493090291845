/* eslint-disable camelcase */
import { t } from '@lingui/macro';
import { transEnum } from '@luminovo/commons';
import { UniversalImporter, onImportUsingIndividualMutations } from '@luminovo/universal-importer';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router';
import { useSuspenseHttpQuery } from '../../resources/http/useHttpQuery';
import { useHttpMutation } from '../../resources/mutation/useHttpMutation';
import { useSupplierAndStockLocation } from '../../resources/supplierAndStockLocation/supplierAndStockLocationHandler';
import { UrlParams, route } from '../../utils/routes';
import { errorCodeTranslations } from '../Error/errorCodeTranslations';

export function SupplierContactsImporterPage(params: UrlParams<'/supplier/:supplierId/supplier-contacts-importer'>) {
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const { data: contacts = [] } = useSuspenseHttpQuery(
        'GET /suppliers/:supplierId/supplier-contacts',
        {
            pathParams: {
                supplierId: params.pathParams.supplierId,
            },
        },
        {
            refetchOnWindowFocus: true,
            select: (res) => res.items,
        },
    );

    const { mutateAsync: updateSupplierContact } = useHttpMutation('PATCH /supplier-contacts/:supplierContactId', {
        snackbarMessage: null,
    });

    const { mutateAsync: createSupplierContact } = useHttpMutation('POST /supplier-contacts', {
        snackbarMessage: null,
    });

    const { data: supplier } = useSupplierAndStockLocation(params.pathParams.supplierId);

    const supplierName = supplier?.supplier.name ?? t`Supplier`;

    return (
        <UniversalImporter
            batchSize={5}
            title={t`Import ${supplierName} contacts`}
            onImportDone={() => {
                history.push(
                    // TODO: redirect to proper supplier page.
                    // Might not be possible once we enable importing
                    // supplier contacts for multiple suppliers.
                    route('/supplier', {
                        supplierId: params.pathParams.supplierId,
                    }),
                );
                enqueueSnackbar(t`Contacts imported successfully`, { variant: 'success' });
            }}
            onImportBatch={onImportUsingIndividualMutations({
                updateRecord: async (record) => {
                    const existingContact = contacts.find((contact) => contact.email === record.data.email);
                    if (!existingContact) {
                        throw new Error('Contact not found with email: ' + record.data.email);
                    }
                    return updateSupplierContact({
                        pathParams: { supplierContactId: existingContact.id },
                        requestBody: {
                            email: record.data.email,
                            first_name: record.data.first_name,
                            last_name: record.data.last_name,
                            position: record.data.position,
                            user_language: record.data.language,
                        },
                    });
                },
                insertRecord: async (record) => {
                    return createSupplierContact({
                        requestBody: {
                            email: record.data.email,
                            first_name: record.data.first_name,
                            last_name: record.data.last_name,
                            position: record.data.position,
                            user_language: record.data.language,
                            is_main_contact: record.data.is_main_contact,
                            supplier: params.pathParams.supplierId,
                        },
                    });
                },
                formatError: (error) => transEnum(error.code, errorCodeTranslations),
            })}
            config={{
                fields: [
                    {
                        id: 'first_name' as const,
                        label: t`First name`,
                        description: t`The first name of the contact.`,
                        parser: { type: 'string', options: { trim: true } },
                        columnIndices: [],
                        required: true,
                    },
                    {
                        id: 'last_name' as const,
                        label: t`Last name`,
                        description: t`The last name of the contact.`,
                        parser: { type: 'string', options: { trim: true } },
                        columnIndices: [],
                        required: true,
                    },
                    {
                        id: 'email' as const,
                        label: t`Email`,
                        description: t`The email address of the contact.`,
                        parser: { type: 'email', options: { records: contacts } },
                        columnIndices: [],
                        required: true,
                    },
                    {
                        id: 'position' as const,
                        label: t`Position`,
                        description: t`The position in the company e.g. Sales Manager.`,
                        parser: { type: 'string', options: { trim: true } },
                        columnIndices: [],
                        required: false,
                    },
                    {
                        id: 'is_main_contact' as const,
                        label: t`Is default contact`,
                        parser: {
                            type: 'boolean',
                            options: {},
                        },
                        defaultValue: { id: false, label: 'No' },
                        columnIndices: [],
                        required: false,
                        description: t`The default contact selected when sending emails.`,
                    },
                    {
                        id: 'language' as const,
                        label: t`Language`,
                        description: t`Communications to this contact will be in this language.`,
                        parser: {
                            type: 'language',
                            options: {},
                        },
                        defaultValue: { id: 'en', label: 'English' },
                        columnIndices: [],
                        required: true,
                    },
                ],
                skipRows: [],
            }}
        />
    );
}
