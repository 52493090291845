import { plural, t, Trans } from '@lingui/macro';
import { formatToIso8601Date, isEqual, isPresent, uniqBy, uniqueBy } from '@luminovo/commons';
import {
    CenteredLayout,
    Checkbox,
    colorSystem,
    DialogTitle,
    FieldCheckboxControlled,
    FieldDateControlled,
    FieldMultiSelectControlled,
    FieldTextControlled,
    Flexbox,
    FormItem,
    Message,
    PrimaryButton,
    ReleaseTag,
    SecondaryButton,
    Tag,
    TertiaryButton,
    Text,
    Tooltip,
    usePersistedVisibility,
} from '@luminovo/design-system';
import {
    ApprovalStatus,
    PartDTO,
    PartIdTypes,
    QuotePriceBulkRequestBodyDTO,
    SourcingScenarioDTO,
    SupplierAndStockLocationDTO,
    SupplierContactDTO,
    SupplierTag,
    SupportedPartsDTO,
} from '@luminovo/http-client';
import {
    extractPartDTO,
    extractPartOptions,
    formatSupplierAndStockLocationDTO,
    hasSupplierTag,
} from '@luminovo/sourcing-core';
import { GetApp, OpenInNewRounded as OpenInNewRoundedIcon } from '@mui/icons-material';
import { Box, CircularProgress, Dialog, DialogContent, FormLabel, styled } from '@mui/material';
import React from 'react';
import { useFormContext, useFormState, useWatch } from 'react-hook-form';
import { Link as RouterLink } from 'react-router-dom';
import { useDialogContext } from '../../../../components/contexts/ModalContext';
import { FormContainer } from '../../../../components/formLayouts/FormContainer';
import { useCustomer } from '../../../../resources/customer/customerHandler';
import { useDownloadSupplierQuotePriceTemplate } from '../../../../resources/export/exportHandler';
import { useRfQ } from '../../../../resources/rfq/rfqHandler';
import {
    useSourcingFullBulk,
    useSourcingScenariosOfRfq,
} from '../../../../resources/sourcingScenario/sourcingScenarioHandlers';
import { useSupportedPartsOfSupplier } from '../../../../resources/supplier/supplierHandler';
import {
    useGlobalApprovedSupplierAndStockLocations,
    useGlobalPreferredSupplierAndStockLocations,
    useNonExcludedSupplierAndStockLocations,
} from '../../../../resources/supplierAndStockLocation/supplierAndStockLocationHandler';
import { useSupplierContacts } from '../../../../resources/supplierContact/supplierContactHandler';
import { route } from '../../../../utils/routes';
import { useIsSourcingScenarioPcbOnlyTypeBulk } from '../../utils/useIsSourcingScenarioPcbOnlyType';
import {
    AssemblyWithSourcingScenarios,
    useGroupScenariosByAssembly,
} from '../SourcingPage/GroupedSourcingScenarioTable/AssemblyCentricSourcingScenarioTable';
import { useEmailManagerDialog } from './EmailManagerDialog';
import { EmailFileManagementInformation } from './types';

type GroupByKey = 'preferred' | 'approved' | 'other';

const StyledGetAppIcon = styled(GetApp)({
    fontSize: '18px',
    lineHeight: '20px',
});

const StyledOpenInNewRoundedIcon = styled(OpenInNewRoundedIcon)({
    color: colorSystem.neutral[7],
    fontSize: '14px',
    lineHeight: '16px',
});

type ValidationInformations = {
    sourcingValidationInformations: Array<{ sourcingScenarioId: string; part: PartDTO }>;
    supplierValidationInformations: Array<{
        suppliersAndStockLocation: SupplierAndStockLocationDTO;
        supportedParts: PartDTO[];
        supplierContacts: SupplierContactDTO[];
    }>;
};

type BySupplierConfigurationFormState = {
    rfqId: string;
    sourcingScenarios: SourcingScenarioDTO[];
    suppliersAndStockLocations: SupplierAndStockLocationDTO[];
    manuallySelectedPartIds: PartDTO[];
    shouldFilterByLineCard: boolean;
    generateAllQuantityCombinations: boolean;
    projectInformation: {
        include: boolean;
        subject: string;
        body: string;
    };
    dueDate: string;
    fileManagementInformation: EmailFileManagementInformation;
    assembly: AssemblyWithSourcingScenarios[];
    notes?: string;
};

function useValidationInformation({ rfqId }: { rfqId: string }): ValidationInformations | undefined {
    const { data: sourcingScenarios } = useSourcingScenariosOfRfq(rfqId);
    const { data: sourcingFullDTOs } = useSourcingFullBulk(sourcingScenarios?.map((s) => s.id));
    const { data: suppliersAndStockLocations } = useNonExcludedSupplierAndStockLocations();
    const { data: supplierContactDTOs } = useSupplierContacts({ refetchOnWindowFocus: true });

    const sourcingValidationInformations = sourcingFullDTOs?.flatMap((full) => {
        return full.solution_configurations_sourcing.items
            .flatMap((solutionConfiguration) =>
                extractPartOptions(solutionConfiguration, { filterByStatus: ApprovalStatus.Approved }),
            )
            .map((part) => ({
                sourcingScenarioId: full.sourcing_scenario_id,
                part: extractPartDTO(part),
            }));
    });

    const { data: supportedPartsDTOs } = useSupportedPartsOfSupplier(
        rfqId,
        sourcingValidationInformations?.flatMap(({ part }) => part),
        suppliersAndStockLocations?.map((s) => s.supplier.id),
    );

    const supplierValidationInformations = React.useMemo(() => {
        if (
            !isPresent(suppliersAndStockLocations) ||
            !isPresent(supplierContactDTOs) ||
            !isPresent(supportedPartsDTOs)
        ) {
            return undefined;
        }

        return suppliersAndStockLocations.map((suppliersAndStockLocation) => {
            /* eslint-disable camelcase */
            const supplierContacts = supplierContactDTOs.filter(
                ({ supplier }) => supplier === suppliersAndStockLocation.supplier.id,
            );
            const supportedParts = supportedPartsDTOs.flatMap(({ supplier, part_ids }) =>
                supplier === suppliersAndStockLocation.supplier.id ? part_ids : [],
            );

            const emptyLineCard: SupportedPartsDTO = {
                supplier: suppliersAndStockLocation.supplier.id,
                part_ids: [],
            };
            /* eslint-enable camelcase */

            return {
                suppliersAndStockLocation,
                supplierContacts,
                supportedParts: supportedParts ?? emptyLineCard,
            };
        });
    }, [suppliersAndStockLocations, supplierContactDTOs, supportedPartsDTOs]);

    if (!isPresent(supplierValidationInformations) || !isPresent(sourcingValidationInformations)) {
        return undefined;
    }

    return {
        supplierValidationInformations,
        sourcingValidationInformations,
    };
}

function useValidation({ sourcingValidationInformations, supplierValidationInformations }: ValidationInformations) {
    const { control } = useFormContext<BySupplierConfigurationFormState>();

    const selectedSourcingScenarios = useWatch({
        control: control,
        name: 'sourcingScenarios',
    });

    const shouldFilterByLineCard = useWatch({
        control: control,
        name: 'shouldFilterByLineCard',
    });

    const manuallySelectedPartIds = useWatch({
        control: control,
        name: 'manuallySelectedPartIds',
    });

    const seletedPartIds =
        manuallySelectedPartIds.length !== 0
            ? manuallySelectedPartIds
            : sourcingValidationInformations.flatMap(({ part }) => part);

    const allPartIds = sourcingValidationInformations
        .filter(({ sourcingScenarioId }) => selectedSourcingScenarios.some(({ id }) => id === sourcingScenarioId))
        .flatMap(({ part }) => part);

    const suppliersWithNoContact = supplierValidationInformations
        .filter(({ supplierContacts }) => supplierContacts.length === 0)
        .map(({ suppliersAndStockLocation }) => suppliersAndStockLocation);

    if (shouldFilterByLineCard) {
        const suppliersWithParts = supplierValidationInformations
            .filter(({ supportedParts }) =>
                seletedPartIds.some((selectedpart) =>
                    supportedParts.some((supportedPart) => isEqual(supportedPart, selectedpart)),
                ),
            )
            .map(({ suppliersAndStockLocation }) => suppliersAndStockLocation);

        return {
            shouldFilterByLineCard,
            seletedPartIds,
            suppliersWithParts,
            suppliersWithNoContact,
            hasOnlyStandardPartsSelected: false,
            hasOnlyCustomPartsSelected: false,
        };
    } else {
        const hasOnlyStandardPartsSelected = seletedPartIds.every((selectedPart) =>
            allPartIds.some(
                (part) =>
                    (part.type === PartIdTypes.Generic ||
                        part.type === PartIdTypes.OffTheShelf ||
                        part.type === PartIdTypes.Ipn) &&
                    isEqual(part, selectedPart),
            ),
        );
        const hasOnlyCustomPartsSelected = seletedPartIds.every((selectedPart) =>
            allPartIds.some(
                (part) =>
                    (part.type === PartIdTypes.Custom || part.type === PartIdTypes.CustomComponent) &&
                    isEqual(part, selectedPart),
            ),
        );

        const suppliersWithParts = supplierValidationInformations
            .filter(({ suppliersAndStockLocation, supportedParts }) => {
                const isOffTheShelfPartSupplier = hasSupplierTag(
                    suppliersAndStockLocation,
                    SupplierTag.OffTheShelfPartSupplier,
                );
                const isCustomPartSupplier =
                    hasSupplierTag(suppliersAndStockLocation, SupplierTag.CustomPartSupplier) ||
                    hasSupplierTag(suppliersAndStockLocation, SupplierTag.PcbSupplier);

                if (hasOnlyStandardPartsSelected && isCustomPartSupplier) {
                    return false;
                }

                if (hasOnlyCustomPartsSelected && isOffTheShelfPartSupplier) {
                    return false;
                }

                // Because the frontend can not differentiate between a custom part supplier and a pcb supplier, we have to use the line card
                if (isCustomPartSupplier) {
                    return seletedPartIds.some((selectedpart) =>
                        supportedParts.some((supportedPart) => isEqual(supportedPart, selectedpart)),
                    );
                }

                return true;
            })
            .map(({ suppliersAndStockLocation }) => suppliersAndStockLocation);

        return {
            shouldFilterByLineCard,
            seletedPartIds,
            suppliersWithParts,
            suppliersWithNoContact,
            hasOnlyStandardPartsSelected,
            hasOnlyCustomPartsSelected,
        };
    }
}

const LinkToSupplierAndStockLocation: React.FunctionComponent<{
    supplierAndStockLocation: SupplierAndStockLocationDTO;
}> = ({ supplierAndStockLocation }) => {
    const onClick = () => {
        window.open(
            route('/supplier/:supplierAndStockLocationId', {
                supplierAndStockLocationId: supplierAndStockLocation.id,
            }),
            '_blank',
            'noopener noreferrer',
        );
    };

    return (
        <TertiaryButton onClick={onClick} style={{ padding: '0', height: 18 }}>
            <Flexbox paddingRight={'8px'} gap={2} alignItems={'center'}>
                <StyledOpenInNewRoundedIcon />
                <Text variant={'body-small'} color={colorSystem.neutral[8]} style={{ whiteSpace: 'nowrap' }}>
                    {formatSupplierAndStockLocationDTO(supplierAndStockLocation)}
                </Text>
            </Flexbox>
        </TertiaryButton>
    );
};

const NoSupportedPartsError: React.FunctionComponent<{ validationInformations: ValidationInformations }> = ({
    validationInformations,
}) => {
    const { control, setValue } = useFormContext<BySupplierConfigurationFormState>();
    const { suppliersWithParts } = useValidation(validationInformations);

    const selectedSuppliersAndStockLocations = useWatch({
        control: control,
        name: 'suppliersAndStockLocations',
    });

    const onClick = () => {
        const onlySupplierWithParts = selectedSuppliersAndStockLocations.filter((s1) =>
            suppliersWithParts.some((s2) => s2.id === s1.id),
        );
        setValue('suppliersAndStockLocations', onlySupplierWithParts, { shouldValidate: true });
    };

    const selectedWithNoParts = selectedSuppliersAndStockLocations.filter((s1) =>
        suppliersWithParts.every((s2) => s2.id !== s1.id),
    );

    if (selectedWithNoParts.length === 0) {
        return null;
    }

    return (
        <Message
            variant={'red'}
            attention={'high'}
            size={'large'}
            title={t`The following suppliers don't support any parts of your BOM:`}
            message={
                <Flexbox flexDirection={'row'} maxWidth={'100%'} flexWrap={'wrap'} gap={8}>
                    {selectedWithNoParts.map((supplierAndStockLocation) => (
                        <LinkToSupplierAndStockLocation
                            supplierAndStockLocation={supplierAndStockLocation}
                            key={supplierAndStockLocation.id}
                        />
                    ))}
                </Flexbox>
            }
            action={{ label: t`Remove from the list`, onClick }}
        />
    );
};

const NoSupplierContactWarning: React.FunctionComponent<{ validationInformations: ValidationInformations }> = ({
    validationInformations,
}) => {
    const { control } = useFormContext<BySupplierConfigurationFormState>();
    const { suppliersWithParts, suppliersWithNoContact } = useValidation(validationInformations);

    const selectedSuppliersAndStockLocations = useWatch({
        control: control,
        name: 'suppliersAndStockLocations',
    });

    const selectedWithNoContacts = selectedSuppliersAndStockLocations.filter((s1) =>
        suppliersWithNoContact.some((s2) => s2.id === s1.id),
    );

    const filteredSupplierWihtNoContact = selectedWithNoContacts.filter((noContactSasl) =>
        suppliersWithParts.some((sasl) => sasl.id === noContactSasl.id),
    );

    if (filteredSupplierWihtNoContact.length === 0) {
        return null;
    }

    return (
        <Message
            variant={'yellow'}
            attention={'high'}
            size={'large'}
            title={t`The following suppliers have no contact attached. Click on the supplier to add the contact person or continue without sending an email to these:`}
            message={
                <Flexbox flexDirection={'row'} maxWidth={'100%'} flexWrap={'wrap'}>
                    {filteredSupplierWihtNoContact.map((supplierAndStockLocation) => (
                        <LinkToSupplierAndStockLocation
                            supplierAndStockLocation={supplierAndStockLocation}
                            key={supplierAndStockLocation.id}
                        />
                    ))}
                </Flexbox>
            }
        />
    );
};

const ShouldFilterByLineCardButton: React.FunctionComponent = () => {
    const { control } = useFormContext<BySupplierConfigurationFormState>();

    const manuallySelectedPartIds = useWatch({
        control: control,
        name: 'manuallySelectedPartIds',
    });

    const hasNoManuallySelectedParts = manuallySelectedPartIds.length === 0;

    return (
        <Flexbox gap={8} alignItems={'center'}>
            <FieldCheckboxControlled
                control={control}
                name="shouldFilterByLineCard"
                FieldProps={{
                    size: 'small',
                    disabled: hasNoManuallySelectedParts,
                }}
            />
            <Tooltip
                title={
                    hasNoManuallySelectedParts
                        ? t`This option is only available when parts are manually selected. You can now start the "quote by parts" process directly from the sourcing scenario table. To request a quote, check the boxes next to the desired parts and click the "Request Quote" button.`
                        : ''
                }
            >
                <Text variant="body" color={hasNoManuallySelectedParts ? colorSystem.neutral[7] : undefined}>
                    <Trans>Filter requested parts by line card</Trans>
                </Text>
            </Tooltip>
        </Flexbox>
    );
};

const FormItemSupplier: React.FunctionComponent<{ validationInformations: ValidationInformations }> = ({
    validationInformations,
}) => {
    const { control, setValue } = useFormContext<BySupplierConfigurationFormState>();

    const selectedSuppliersAndStockLocations = useWatch({
        control: control,
        name: 'suppliersAndStockLocations',
    });

    const {
        suppliersWithParts,
        suppliersWithNoContact,
        shouldFilterByLineCard,
        hasOnlyStandardPartsSelected,
        hasOnlyCustomPartsSelected,
    } = useValidation(validationInformations);

    const { data: suppliersAndStockLocations = [] } = useNonExcludedSupplierAndStockLocations();
    const { data: preferredSuppliersAndStockLocations = [] } = useGlobalPreferredSupplierAndStockLocations();
    const { data: approvedSuppliersAndStockLocations = [] } = useGlobalApprovedSupplierAndStockLocations();

    const quoatParnerSuppliersAndStockLocations = suppliersAndStockLocations.filter((sasl) => {
        return (
            !preferredSuppliersAndStockLocations.some((s) => s.id === sasl.id) &&
            !approvedSuppliersAndStockLocations.some((s) => s.id === sasl.id) &&
            hasSupplierTag(sasl, SupplierTag.QuotePartner)
        );
    });
    const nonQuoteParnerSuppliersAndStockLocations = suppliersAndStockLocations.filter((sasl) => {
        return (
            !preferredSuppliersAndStockLocations.some((s) => s.id === sasl.id) &&
            !approvedSuppliersAndStockLocations.some((s) => s.id === sasl.id) &&
            !hasSupplierTag(sasl, SupplierTag.QuotePartner)
        );
    });

    const otherSuppliersAndStockLocations = [
        ...quoatParnerSuppliersAndStockLocations,
        ...nonQuoteParnerSuppliersAndStockLocations,
    ];

    const options = [
        ...preferredSuppliersAndStockLocations,
        ...approvedSuppliersAndStockLocations,
        ...otherSuppliersAndStockLocations,
    ].filter((sals) => {
        if (hasOnlyStandardPartsSelected) {
            return hasSupplierTag(sals, SupplierTag.OffTheShelfPartSupplier);
        }

        if (hasOnlyCustomPartsSelected) {
            return (
                hasSupplierTag(sals, SupplierTag.PcbSupplier) || hasSupplierTag(sals, SupplierTag.CustomPartSupplier)
            );
        }

        return true;
    });

    const getOptionDisabled = React.useCallback(
        (sasl) => {
            return !suppliersWithParts.some((item) => item.id === sasl.id);
        },
        [suppliersWithParts],
    );

    const getChipColor = React.useCallback(
        (sasl: SupplierAndStockLocationDTO) => {
            if (suppliersWithParts.every(({ id }) => id !== sasl.id)) {
                return 'red';
            }
            if (suppliersWithNoContact.some(({ id }) => id === sasl.id)) {
                return 'yellow';
            }
            return 'neutral';
        },
        [suppliersWithParts, suppliersWithNoContact],
    );

    const preferredSelectableSupplier = preferredSuppliersAndStockLocations.filter((s) => !getOptionDisabled(s));
    const approvedSelectableSupplier = approvedSuppliersAndStockLocations.filter((s) => !getOptionDisabled(s));
    const otherSelectableSupplier = otherSuppliersAndStockLocations.filter((s) => !getOptionDisabled(s));

    const getGroupByStatus = React.useCallback(
        (groupByKey: GroupByKey): 'all' | 'indeterminate' | 'none' => {
            const selectableSupplier = {
                preferred: preferredSelectableSupplier,
                approved: approvedSelectableSupplier,
                other: otherSelectableSupplier,
            }[groupByKey];

            const selectedIds = selectedSuppliersAndStockLocations.map((s) => s.id);
            const selectableIds = selectableSupplier.map((s) => s.id);

            if (selectableIds.length === 0) {
                return 'none';
            }

            if (selectableIds.every((id) => selectedIds.includes(id))) {
                return 'all';
            }

            if (selectableIds.some((id) => selectedIds.includes(id))) {
                return 'indeterminate';
            }

            return 'none';
        },
        [
            selectedSuppliersAndStockLocations,
            preferredSelectableSupplier,
            approvedSelectableSupplier,
            otherSelectableSupplier,
        ],
    );

    const toggleGroup = React.useCallback(
        (groupByKey: GroupByKey, status: 'all' | 'indeterminate' | 'none') => {
            const selectableSupplier = {
                preferred: preferredSelectableSupplier,
                approved: approvedSelectableSupplier,
                other: otherSelectableSupplier,
            }[groupByKey];

            if (status === 'all') {
                const newValue = uniqBy(
                    selectedSuppliersAndStockLocations.filter((s) => !selectableSupplier.some((s1) => s1.id === s.id)),
                    (x) => x.id,
                );

                setValue('suppliersAndStockLocations', newValue, { shouldValidate: true });
            } else {
                const newValue = uniqBy(selectableSupplier.concat(selectedSuppliersAndStockLocations), (x) => x.id);
                setValue('suppliersAndStockLocations', newValue, { shouldValidate: true });
            }
        },
        [
            selectedSuppliersAndStockLocations,
            preferredSelectableSupplier,
            approvedSelectableSupplier,
            otherSelectableSupplier,
            setValue,
        ],
    );

    return (
        <FormItem
            label={t`Create request for the following suppliers`}
            required
            actions={<ShouldFilterByLineCardButton />}
        >
            <FieldMultiSelectControlled
                name="suppliersAndStockLocations"
                control={control}
                required={true}
                FieldProps={{
                    options: options,
                    getOptionLabel: formatSupplierAndStockLocationDTO,
                    placeholder: selectedSuppliersAndStockLocations.length === 0 ? t`No suppliers` : undefined,
                    getOptionKey: (sasl) => sasl.id,
                    getOptionColor: getChipColor,
                    virtualized: true,
                    disableCloseOnSelect: true,
                    groupBy: (sasl): GroupByKey => {
                        if (preferredSuppliersAndStockLocations.some((s) => s.id === sasl.id)) {
                            return 'preferred';
                        }
                        if (approvedSuppliersAndStockLocations.some((s) => s.id === sasl.id)) {
                            return 'approved';
                        }
                        return 'other';
                    },
                    getOptionDisabled: getOptionDisabled,
                    renderGroupLabel: (params) => {
                        //eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                        const groupByKey = params.group as GroupByKey;
                        const status = getGroupByStatus(groupByKey);
                        const label =
                            groupByKey === 'preferred'
                                ? t`Preferred suppliers`
                                : groupByKey === 'approved'
                                  ? t`Approved suppliers`
                                  : t`Other suppliers`;

                        return (
                            <Flexbox gap={8} paddingLeft={'12px'} alignItems={'center'}>
                                <Checkbox
                                    checked={status === 'all'}
                                    indeterminate={status === 'indeterminate'}
                                    size={'small'}
                                    onClick={() => toggleGroup(groupByKey, status)}
                                    disabled={groupByKey === 'other'}
                                />
                                <Text variant="h4">{label}</Text>
                            </Flexbox>
                        );
                    },
                    renderOption: (sasl, { selected }: { selected: boolean }) => {
                        const validationInformation = validationInformations.supplierValidationInformations.find(
                            (item) => item.suppliersAndStockLocation.id === sasl.id,
                        );

                        const numberOfContacts = validationInformation?.supplierContacts.length ?? 0;
                        const numberOfSupportedParts = validationInformation?.supportedParts.length ?? '-';

                        return (
                            <Flexbox key={sasl.id} gap={8} alignItems={'center'} width={'100%'} paddingLeft={'8px'}>
                                <Checkbox checked={selected} size={'small'} />
                                <Flexbox width={'100%'} justifyContent={'space-between'}>
                                    <Flexbox gap={4} alignItems={'baseline'}>
                                        <Text variant="body" color={colorSystem.neutral[8]}>
                                            {formatSupplierAndStockLocationDTO(sasl)}
                                        </Text>
                                        <Text variant="caption" color={colorSystem.neutral[6]}>
                                            {numberOfContacts !== 0 &&
                                                plural(numberOfContacts, {
                                                    one: `${numberOfContacts} contact`,
                                                    other: `${numberOfContacts} contacts`,
                                                })}
                                        </Text>
                                    </Flexbox>
                                    <Flexbox gap={4} alignItems={'baseline'}>
                                        {hasSupplierTag(sasl, SupplierTag.PcbSupplier) && (
                                            <Tag color="neutral" attention="low" label={'PCB'} />
                                        )}
                                        {hasSupplierTag(sasl, SupplierTag.CustomPartSupplier) && (
                                            <Tag color="neutral" attention="low" label={t`Custom part`} />
                                        )}
                                        {hasSupplierTag(sasl, SupplierTag.QuotePartner) && (
                                            <Tag color="teal" attention="low" label={t`Quote partner`} />
                                        )}
                                        <Text
                                            variant={'body-small'}
                                            color={colorSystem.neutral[7]}
                                            hidden={!shouldFilterByLineCard}
                                        >{`${numberOfSupportedParts} ${
                                            hasSupplierTag(sasl, SupplierTag.PcbSupplier) ? t`PCB(s)` : t`part(s)`
                                        }`}</Text>
                                    </Flexbox>
                                </Flexbox>
                            </Flexbox>
                        );
                    },
                }}
            />
            <NoSupportedPartsError validationInformations={validationInformations} />
            <NoSupplierContactWarning validationInformations={validationInformations} />
        </FormItem>
    );
};

const FormItemSourcingScenarios: React.FunctionComponent<{ sourcingScenarios: SourcingScenarioDTO[] }> = ({
    sourcingScenarios,
}) => {
    const { control } = useFormContext<BySupplierConfigurationFormState>();

    return (
        <FormItem label={t`Which scenarios should be considered?`} required>
            <FieldMultiSelectControlled
                name="sourcingScenarios"
                control={control}
                required={true}
                FieldProps={{
                    disableCloseOnSelect: true,
                    options: sourcingScenarios,
                    getOptionLabel: (option) => option.name,
                    getOptionKey: (option) => option.id,
                }}
            />
        </FormItem>
    );
};

const FormItemAssembly: React.FunctionComponent<{ groupedAssemblyData: AssemblyWithSourcingScenarios[] }> = ({
    groupedAssemblyData,
}) => {
    const { control } = useFormContext<BySupplierConfigurationFormState>();

    return (
        <FormItem label={t`Which assemblies should be considered?`} required>
            <FieldMultiSelectControlled
                name="assembly"
                control={control}
                required={true}
                FieldProps={{
                    disableCloseOnSelect: true,
                    options: groupedAssemblyData,
                    getOptionLabel: (option) => option.designator,
                    getOptionKey: (option) => option.id,
                }}
            />
        </FormItem>
    );
};

const FormItemDueDate: React.FunctionComponent = () => {
    const { control } = useFormContext<BySupplierConfigurationFormState>();

    return (
        <FormItem label={<Trans>Due date</Trans>}>
            <FieldDateControlled control={control} name={'dueDate'} />
        </FormItem>
    );
};

const FormItemNotes = () => {
    const { control } = useFormContext<BySupplierConfigurationFormState>();
    return (
        <FormItem
            label={t`Notes`}
            variant="description-as-tooltip"
            description={t`Use notes to add project specific information (e.g. application or start of production). They will be shown in the supplier portal and can be used in email templates.`}
        >
            <FieldTextControlled FieldProps={{ multiline: true, minRows: 3 }} control={control} name={'notes'} />
        </FormItem>
    );
};

const ActionsButtons: React.FunctionComponent<{
    validationInformations: ValidationInformations;
    isSourcingScenarioPcbOnlyType: boolean;
}> = ({ validationInformations, isSourcingScenarioPcbOnlyType }) => {
    const { control, handleSubmit } = useFormContext<BySupplierConfigurationFormState>();

    const rfqId = useWatch({
        control: control,
        name: 'rfqId',
    });

    const selectedSuppliersAndStockLocations = useWatch({
        control: control,
        name: 'suppliersAndStockLocations',
    });

    const generateAllQuantityCombinations = useWatch({
        control: control,
        name: 'generateAllQuantityCombinations',
    });

    const { suppliersWithParts, suppliersWithNoContact, seletedPartIds, shouldFilterByLineCard } =
        useValidation(validationInformations);

    const { errors } = useFormState();
    const hasErrors = Object.keys(errors).length > 0;
    const hasNoSupportedParts = selectedSuppliersAndStockLocations.every((s1) =>
        suppliersWithParts.every((s2) => s2.id !== s1.id),
    );
    const hasNoContactsAndNoParts = selectedSuppliersAndStockLocations.every(
        (sasl) =>
            suppliersWithNoContact.some((noContactsSasl) => noContactsSasl.id === sasl.id) ||
            suppliersWithParts.every((s2) => s2.id !== sasl.id),
    );

    const { mutateAsync: downLoadMutate, isPending: isLoading } = useDownloadSupplierQuotePriceTemplate();
    const { openDialog: openEmailManagerDialog } = useEmailManagerDialog();
    const { openDialog: onBackForEmailManagerDialog } = useRequestConfigurationDialog({
        rfqId,
    });

    const onNext = (formValues: BySupplierConfigurationFormState) => {
        const { rfqId, suppliersAndStockLocations, projectInformation, dueDate, fileManagementInformation, assembly } =
            formValues;

        const sourcingScenarios = isSourcingScenarioPcbOnlyType
            ? uniqueBy(
                  assembly.flatMap(({ sourcingScenarios }) => sourcingScenarios),
                  (s) => s.id,
              )
            : formValues.sourcingScenarios;
        const emailBodyInformation = {
            subject: projectInformation.subject,
            projectInformation: projectInformation.include ? projectInformation.body : undefined,
            dueDate: dueDate,
            fileManagementInformation: fileManagementInformation,
            generateAllQuantityCombinations: formValues.generateAllQuantityCombinations,
            notes: formValues.notes ?? '',
        };

        openEmailManagerDialog(
            {
                rfqId,
                suppliersAndStockLocations,
                sourcingScenarios,
                partIds: seletedPartIds,
                emailBodyInformation,
                shouldFilterByLineCard,
                generateAllQuantityCombinations,
            },
            () => onBackForEmailManagerDialog(formValues),
        );
    };

    const onDownload = async (formValues: BySupplierConfigurationFormState) => {
        const { rfqId, suppliersAndStockLocations, sourcingScenarios, fileManagementInformation } = formValues;
        const supplierIds = suppliersAndStockLocations.map((s) => s.id);
        const sourcingScenariosIds = sourcingScenarios.map((s) => s.id);

        /* eslint-disable camelcase */
        const requestBody: QuotePriceBulkRequestBodyDTO = {
            rfq: rfqId,
            part_ids: seletedPartIds.map((part) => part.data),
            should_filter_by_line_card: shouldFilterByLineCard,
            generate_all_quantity_combinations: generateAllQuantityCombinations,
            should_include_pcb_specification: fileManagementInformation.includePcbSpecification,
            should_include_shipping_panel_specification: fileManagementInformation.includeShippingPanelSpecification,
            supplier_ids: supplierIds,
            sourcing_scenario_ids: sourcingScenariosIds,
        };
        /* eslint-enable camelcase */

        await downLoadMutate({ requestBody });
    };

    return (
        <Flexbox flexDirection="row" gap={8} justifyContent="flex-end" paddingTop={'24px'}>
            <Flexbox gap={8}>
                <SecondaryButton
                    onClick={handleSubmit(onDownload)}
                    disabled={hasNoSupportedParts || isLoading || hasErrors}
                    isLoading={isLoading}
                    startIcon={<StyledGetAppIcon />}
                >
                    <Trans>Download requests</Trans>
                </SecondaryButton>
                <Tooltip
                    title={
                        !hasNoSupportedParts && hasNoContactsAndNoParts
                            ? t`Select a supplier with a contact attached to continue`
                            : ''
                    }
                >
                    <Box>
                        <PrimaryButton
                            onClick={handleSubmit(onNext)}
                            disabled={hasNoContactsAndNoParts || hasNoSupportedParts || isLoading || hasErrors}
                        >
                            <Trans>Next</Trans>
                        </PrimaryButton>
                    </Box>
                </Tooltip>
            </Flexbox>
        </Flexbox>
    );
};

const FormItemFileManagement: React.FunctionComponent = () => {
    const { control } = useFormContext<BySupplierConfigurationFormState>();

    const selectedSuppliersAndStockLocations = useWatch({
        control: control,
        name: 'suppliersAndStockLocations',
    });

    if (selectedSuppliersAndStockLocations.every((s) => hasSupplierTag(s, SupplierTag.OffTheShelfPartSupplier))) {
        return <></>;
    }

    return (
        <FormItem label={t`File management`}>
            <Box display="grid" gap={'16px'} gridTemplateColumns="1fr 1fr">
                <FormLabel
                    style={{
                        border: `1px solid ${colorSystem.neutral[2]}`,
                        padding: '8px 16px',
                        borderRadius: '8px',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '16px',
                    }}
                >
                    <FieldCheckboxControlled
                        control={control}
                        name="fileManagementInformation.includePcbSpecification"
                    />
                    <Flexbox flexDirection={'column'}>
                        <Text variant="h4">
                            <Trans>Include PCB specification</Trans>
                        </Text>
                        <Text variant="caption" style={{ color: colorSystem.neutral[7] }}>
                            <Trans>To all the assemblies in the request</Trans>
                        </Text>
                    </Flexbox>
                </FormLabel>
                <FormLabel
                    style={{
                        border: `1px solid ${colorSystem.neutral[2]}`,
                        padding: '8px 16px',
                        borderRadius: '8px',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '16px',
                    }}
                >
                    <FieldCheckboxControlled
                        control={control}
                        name="fileManagementInformation.includeShippingPanelSpecification"
                    />
                    <Flexbox flexDirection={'column'}>
                        <Text variant="h4">
                            <Trans>Include shipping panel specification</Trans>
                        </Text>
                        <Text variant="caption" style={{ color: colorSystem.neutral[7] }}>
                            <Trans>To all the assemblies in the request</Trans>
                        </Text>
                    </Flexbox>
                </FormLabel>
            </Box>
        </FormItem>
    );
};

function addDays(days: number, date = new Date()) {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}

const BySupplierConfigurationForm: React.FunctionComponent<{
    rfqId: string;
    oldFormState?: BySupplierConfigurationFormState;
    manuallySelectedPartIds: PartDTO[];
    prefilledSuppliersAndStockLocations: SupplierAndStockLocationDTO[];
}> = ({ rfqId, oldFormState, manuallySelectedPartIds, prefilledSuppliersAndStockLocations }) => {
    const { data: rfqDTO } = useRfQ(rfqId);
    const { data: customerDTO } = useCustomer(rfqDTO?.customer);
    const { data: sourcingScenarios } = useSourcingScenariosOfRfq(rfqId);
    const sourcingScenarioIds = sourcingScenarios?.map((s) => s.id) ?? [];
    const validationInformations = useValidationInformation({ rfqId });
    const { data: groupedAssemblyData } = useGroupScenariosByAssembly(sourcingScenarioIds);
    const { data: isSourcingScenarioPcbOnlyType } = useIsSourcingScenarioPcbOnlyTypeBulk(sourcingScenarioIds);

    if (
        !isPresent(rfqDTO) ||
        !isPresent(customerDTO) ||
        !isPresent(sourcingScenarios) ||
        !isPresent(validationInformations) ||
        !isPresent(isSourcingScenarioPcbOnlyType) ||
        !isPresent(groupedAssemblyData)
    ) {
        return (
            <CenteredLayout height={'400px'}>
                <CircularProgress />
            </CenteredLayout>
        );
    }

    const defaultValues: BySupplierConfigurationFormState = {
        rfqId,
        suppliersAndStockLocations: oldFormState?.suppliersAndStockLocations ?? prefilledSuppliersAndStockLocations,
        sourcingScenarios: oldFormState?.sourcingScenarios ?? sourcingScenarios,
        manuallySelectedPartIds: oldFormState?.manuallySelectedPartIds ?? manuallySelectedPartIds,
        shouldFilterByLineCard: oldFormState?.shouldFilterByLineCard ?? manuallySelectedPartIds.length === 0,
        projectInformation: oldFormState?.projectInformation ?? {
            include: false,
            subject: '',
            body: t`The request is for project ${rfqDTO.name} by our customer ${customerDTO.name}.`,
        },
        dueDate: oldFormState?.dueDate ?? formatToIso8601Date(addDays(7).toISOString()),

        fileManagementInformation: oldFormState?.fileManagementInformation ?? {
            includePcbSpecification: true,
            includeShippingPanelSpecification: true,
        },
        assembly: oldFormState?.assembly ?? groupedAssemblyData,
        generateAllQuantityCombinations: false,
        notes: oldFormState?.notes ?? '',
    };

    return (
        <FormContainer defaultValues={defaultValues} onSubmit={() => {}} UNSAFE_disableStablePropCheck>
            <Flexbox flexDirection={'column'} justifyContent="space-between" minHeight={'60vh'} paddingTop={'12px'}>
                <Flexbox flexDirection={'column'} gap={40}>
                    <FormItemSupplier validationInformations={validationInformations} />
                    {isSourcingScenarioPcbOnlyType ? (
                        <FormItemAssembly groupedAssemblyData={groupedAssemblyData} />
                    ) : (
                        <FormItemSourcingScenarios sourcingScenarios={sourcingScenarios} />
                    )}

                    <FormItemDueDate />

                    <FormItemFileManagement />

                    <FormItemNotes />

                    <FormItem label={t`Email templates`}>
                        <Text>
                            <Trans>
                                Email templates can be configured{' '}
                                <RouterLink to={route('/settings/organization/email-templates')}>here</RouterLink>.
                                Templates are pre-selected based on the supplier. You can still edit the final email in
                                the next screen.
                            </Trans>
                        </Text>
                    </FormItem>
                </Flexbox>
                <ActionsButtons
                    validationInformations={validationInformations}
                    isSourcingScenarioPcbOnlyType={isSourcingScenarioPcbOnlyType}
                />
            </Flexbox>
        </FormContainer>
    );
};

export function useRequestConfigurationDialog({
    rfqId,
    manuallySelectedPartIds = [],
    prefilledSuppliersAndStockLocations = [],
}: {
    rfqId: string;
    manuallySelectedPartIds?: PartDTO[];
    prefilledSuppliersAndStockLocations?: SupplierAndStockLocationDTO[];
}) {
    const { setDialog, closeDialog } = useDialogContext();

    return {
        openDialog: (oldFormState?: BySupplierConfigurationFormState) =>
            setDialog(
                <DialogContainer
                    rfqId={rfqId}
                    closeDialog={closeDialog}
                    manuallySelectedPartIds={manuallySelectedPartIds}
                    prefilledSuppliersAndStockLocations={prefilledSuppliersAndStockLocations}
                    oldFormState={oldFormState}
                />,
            ),
    };
}

function DialogContainer({
    closeDialog,
    rfqId,
    manuallySelectedPartIds = [],
    prefilledSuppliersAndStockLocations = [],
    oldFormState,
}: {
    closeDialog: () => void;
    rfqId: string;
    manuallySelectedPartIds?: PartDTO[];
    prefilledSuppliersAndStockLocations?: SupplierAndStockLocationDTO[];
    oldFormState?: BySupplierConfigurationFormState;
}) {
    const { isVisible, hide } = usePersistedVisibility({
        id: 'quote-request::email-templates',
        storage: localStorage,
        daysApart: 1,
        maxDisplayCount: 3,
    });

    if (isVisible) {
        return (
            <Dialog open={true} fullWidth={true} transitionDuration={0} onClose={() => closeDialog()}>
                <DialogContent style={{ paddingBottom: '24px' }}>
                    <CenteredLayout>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, padding: 5 }}>
                            <Flexbox sx={{ justifyContent: 'center', gap: 1, alignItems: 'center', marginBottom: 3 }}>
                                <Text variant="h2" style={{ textAlign: 'center' }}>
                                    <Trans>Introducing email templates</Trans>
                                </Text>
                                <ReleaseTag color="primary" label={t`NEW`} />
                            </Flexbox>
                            <Text style={{ textAlign: 'center' }}>
                                <span>
                                    <Trans>
                                        You can now configure the look and feel of quote requests using email templates.
                                    </Trans>
                                </span>
                                <ul style={{ textAlign: 'left', lineHeight: 1.8, marginTop: 16 }}>
                                    <li>
                                        <Trans>
                                            <b>Dynamic Variables</b>: Use variables like{' '}
                                            <span
                                                style={{
                                                    background: colorSystem.primary[1],
                                                    border: `1px solid ${colorSystem.primary[2]}`,
                                                }}
                                            >
                                                recipient.first_name
                                            </span>{' '}
                                            to tailor each email.
                                        </Trans>
                                    </li>
                                    <li>
                                        <Trans>
                                            <b>Save and reuse</b>: Create, save, and apply your templates and signature
                                            seamlessly.
                                        </Trans>
                                    </li>
                                    <li>
                                        <Trans>
                                            <b>Supplier specific templates</b>: Automate template selection for your
                                            suppliers.
                                        </Trans>
                                    </li>
                                </ul>
                            </Text>

                            <Flexbox sx={{ justifyContent: 'center', gap: 1 }}>
                                <SecondaryButton href={route('/settings/organization/email-templates')} size="medium">
                                    <Trans>View templates</Trans>
                                </SecondaryButton>
                                <PrimaryButton size="medium" onClick={() => hide()}>
                                    <Trans>Proceed with quote request</Trans>
                                </PrimaryButton>
                            </Flexbox>
                        </Box>
                    </CenteredLayout>
                </DialogContent>
            </Dialog>
        );
    }

    return (
        <Dialog open={true} maxWidth={'md'} fullWidth={true} transitionDuration={0} onClose={() => closeDialog()}>
            {!isVisible && (
                <DialogTitle
                    title={t`Request configuration`}
                    handleClose={() => closeDialog()}
                    style={{ borderBottom: `1px solid ${colorSystem.neutral[1]}` }}
                />
            )}
            <DialogContent style={{ paddingBottom: '24px' }}>
                <BySupplierConfigurationForm
                    rfqId={rfqId}
                    oldFormState={oldFormState}
                    manuallySelectedPartIds={manuallySelectedPartIds}
                    prefilledSuppliersAndStockLocations={prefilledSuppliersAndStockLocations}
                />
            </DialogContent>
        </Dialog>
    );
}
