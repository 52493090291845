import levenshtein from 'js-levenshtein';
import { ImporterCell, ParsedValue } from '../../../../types';

export function findBestAlternatives(
    cell: ImporterCell,
    alternatives: ParsedValue<unknown>[],
    { limit = 50 }: { limit?: number },
): ParsedValue<unknown>[] {
    return Array.from(alternatives)
        .sort((a, b) => {
            return levenshtein(a.label, cell.text) - levenshtein(b.label, cell.text);
        })
        .slice(0, limit);
}
