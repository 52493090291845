import { t } from '@lingui/macro';
import {
    ManufacturingDatabaseActions,
    ManufacturingDatabasePage,
} from '../../../components/CostingTemplates/ManufacturingDatabasePage';
import DriversTable from './DriversTable';
export const DriversViewPage = (): JSX.Element => {
    return (
        <ManufacturingDatabasePage
            DatabaseActionsComponent={ManufacturingDatabaseActions}
            DatabaseTableComponent={DriversTable}
            buttonText={t`Add driver`}
            path="/manufacturing/add-driver"
            descriptionText={t`Drivers are parameters that can be used to calculate Activities and Expenses (e.g. Number of SMT Parts or Number of Small Parts). `}
        />
    );
};
