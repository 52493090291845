import { t } from '@lingui/macro';
import { Toolbar } from '@luminovo/design-system';
import React from 'react';
import { AssemblyMenuButton } from '../../../../components/AssemblyMenuButton';
import { ProgressAwareTabs } from '../../../../components/ProgressAwareTabs';
import { useAssembly, useBomBreadcrumbs } from '../../../../resources/assembly/assemblyHandler';
import { usePcbOfAssembly } from '../../../../resources/pcb/pcbHandlers';
import { useRfQ } from '../../../../resources/rfq/rfqHandler';
import { useIsRfqEditable } from '../../../../utils/rfqUtils';
import { ViewContext } from '../../../Bom/components/ModuleTableData';
import { DeletePCBFilesButton } from './components/DeletePCBFilesButton';
import { DownloadPCBFilesButton } from './components/DownloadPCBFilesButton';
import { ExportSpecificationInPDFButton } from './components/ExportSpecificationInPDFButton';
import { PcbCommentsButton } from './components/PcbCommentsButton';

type ToolbarPcbProps = {
    assemblyId: string;
    rfqId: string;
    currentParentAssemblyId?: string | null | undefined;
};

export function ToolbarPcb({ assemblyId, rfqId, currentParentAssemblyId }: ToolbarPcbProps) {
    const { data: pcb } = usePcbOfAssembly({ assemblyId });
    const { data: assembly } = useAssembly(assemblyId);
    const { data: rfq } = useRfQ(rfqId);
    const { isRfqEditable } = useIsRfqEditable(rfq?.status, rfq?.is_archived, rfq?.workflow_type);
    const isPcbOnlyAssembly = assembly?.type.type === 'PcbOnly';

    const breadcrumbs = useBomBreadcrumbs({ assemblyId, rfqId, assembly, currentParentAssemblyId });
    const viewContext: ViewContext = React.useMemo(() => {
        return {
            type: 'WithinRfQ',
            rfqId,
        };
    }, [rfqId]);

    return (
        <Toolbar
            breadcrumbs={[...breadcrumbs, { title: t`PCB` }]}
            center={
                assembly &&
                assembly.type.type !== 'PcbOnly' && (
                    <ProgressAwareTabs
                        rfqId={rfqId}
                        assembly={assembly}
                        viewContext={viewContext}
                        currentParentAssemblyId={currentParentAssemblyId}
                    />
                )
            }
        >
            {isPcbOnlyAssembly && (
                <AssemblyMenuButton
                    assemblyId={assemblyId}
                    viewContext={viewContext}
                    currentParentAssemblyId={currentParentAssemblyId}
                />
            )}
            {pcb && <ExportSpecificationInPDFButton pcb={pcb} assemblyId={assemblyId} rfqId={rfqId} />}
            {pcb && <DownloadPCBFilesButton pcb={pcb} assemblyId={assemblyId} rfqId={rfqId} />}
            {pcb && isRfqEditable && <DeletePCBFilesButton pcb={pcb} assemblyId={assemblyId} rfqId={rfqId} />}
            <PcbCommentsButton assemblyId={assemblyId} rfqId={rfqId} />
        </Toolbar>
    );
}
