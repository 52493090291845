/* eslint-disable camelcase */
import * as r from 'runtypes';
import { ApiIntegrationStatusRuntype } from './3rdPartyBackendTypes';

export interface CorestaffCredentialsDTO extends r.Static<typeof CorestaffCredentialsDTORuntype> {}
export const CorestaffCredentialsDTORuntype = r.Union(
    r.Record({
        api_key: r.String,
    }),
);

export interface CorestaffCredentialsInputDTO extends r.Static<typeof CorestaffCredentialsInputDTORuntype> {}
export const CorestaffCredentialsInputDTORuntype = r.Record({
    api_key: r.String.optional(),
});

export interface CorestaffResponseBodyDTO extends r.Static<typeof CorestaffResponseBodyDTORuntype> {}
export const CorestaffResponseBodyDTORuntype = r.Record({
    data: CorestaffCredentialsDTORuntype.nullable(),
    status: ApiIntegrationStatusRuntype,
});
