import { colorSystem } from '@luminovo/design-system';
import { Box, styled } from '@mui/material';

export const MainContentContainer = styled(Box)({
    display: 'flex',
    boxSizing: 'border-box',
    alignItems: 'baseline',
    justifyContent: 'center',
    background: colorSystem.neutral[1],
    minWidth: '1300px',
});
