/**
 * This file contains the ids for the pcb analysis message modal.
 * The ids are used to store the state of the modal in the local storage.
 */

export const getPcbAnalysisId = (pcbId: string) => {
    return `pcb/pcb_analysis_message_id_${pcbId}`;
};

/**
 * This checks the if the pcb pdf analysis(extraction) message modal has been seen.
 */
export const getPcbPdfAnalysisId = (pcbId: string) => {
    return `pcb/pcb_pdf_analysis_message_id_${pcbId}`;
};

/**
 * This checks the if the pcb pdf extraction message modal has been seen.
 */
export const getPcbPdfExtractionId = (pcbId: string) => {
    return `pcb/pcb_pdf_extraction_id_${pcbId}`;
};
