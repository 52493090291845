import { QuantityUnit, SolutionConfigurationDTO } from '@luminovo/http-client';
import { useHttpQuery } from '../http/useHttpQuery';

export function useSolutionsOfSolutionConfiguration(solutionConfiguration?: SolutionConfigurationDTO) {
    return useHttpQuery(
        'GET /solutions',
        {
            queryParams: solutionConfiguration
                ? {
                      /* eslint-disable-next-line camelcase */
                      solution_conf: solutionConfiguration.id,
                      quantity: solutionConfiguration.aggregated_quantity.quantity,
                      unit: solutionConfiguration.aggregated_quantity.unit,
                  }
                : // eslint-disable-next-line camelcase
                  { quantity: 0, solution_conf: '', unit: QuantityUnit.Pieces },
        },
        {
            enabled: Boolean(solutionConfiguration),
            staleTime: 10_000,
        },
    );
}
