import { t } from '@lingui/macro';
import { useSnackbar } from 'notistack';
import * as React from 'react';
import { useHistory } from 'react-router';
import ConfirmationDialogBox from '../../../../../components/dialogBox/ConfirmationDialogBox';
import { useHttpMutation } from '../../../../../resources/mutation/useHttpMutation';
import { deletePcbAnalytic } from '../../../../../resources/pcb/analytics/analytic';
import { route } from '../../../../../utils/routes';
import { getAssemblyCadStorageKey } from '../../../CadViewerTab/utils/useCadFileData';
import { getPcbAnalysisId } from '../../PcbAnalysis';
import { getPcbPdfAnalysisId, getPcbPdfExtractionId } from '../../PcbAnalysis/utils/getPcbAnalysisId';

export function useDeletePCB({ pcbId, assemblyId, rfqId }: { pcbId: string; assemblyId: string; rfqId: string }) {
    const [isConfirmDeletionDialogOpen, setIsConfirmDeletionDialogOpen] = React.useState(false);

    const history = useHistory();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const {
        mutateAsync,
        isPending: isLoading,
        isError,
    } = useHttpMutation('DELETE /assemblies/:id/pcb', {
        enableInvalidateAllQueries: false,
        snackbarMessage: null,
        onSuccess: async () => {
            deletePcbAnalytic({
                pcbId,
                rfqId,
                assemblyId,
            });
            await Promise.all([
                localStorage.removeItem(getPcbAnalysisId(pcbId)),
                localStorage.removeItem(getAssemblyCadStorageKey(assemblyId)),
                localStorage.removeItem(getPcbPdfExtractionId(pcbId)),
                localStorage.removeItem(getPcbPdfAnalysisId(pcbId)),
            ]);
            closeSnackbar(getPcbAnalysisId(pcbId));
            history.push(route('/rfqs/:rfqId/bom/assembly/:assemblyId', { rfqId, assemblyId }));
        },
    });
    const { mutateAsync: deletePcbSpecificationExtraction } = useHttpMutation(
        'DELETE /pcb-pdf-specification-extraction/:pcbId',
        {
            snackbarMessage: null,
        },
    );

    const handleDeletePCB = async () => {
        await mutateAsync(
            {
                pathParams: { id: assemblyId },
            },
            {
                onError: () => {
                    enqueueSnackbar(t`Error deleting PCB files`, {
                        variant: 'error',
                    });
                },
            },
        );
        await deletePcbSpecificationExtraction({
            pathParams: { pcbId },
        });
    };

    return {
        onClick: () => {
            setIsConfirmDeletionDialogOpen(true);
        },
        dialog: (
            <ConfirmationDialogBox
                text={t`Are you sure you want to delete the associated PCB files and specification?`}
                closeIconIsShown={true}
                onConfirm={() => handleDeletePCB()}
                title={t`Delete PCB`}
                isDialogOpen={isConfirmDeletionDialogOpen}
                onReject={() => setIsConfirmDeletionDialogOpen(false)}
            />
        ),
        isLoading,
        isError,
    };
}
