import { t } from '@lingui/macro';
import { isPresent } from '@luminovo/commons';
import { CustomPartTypeEnum, PartLite, PartLiteTypes } from '@luminovo/http-client';

export function formatPartLite(part?: PartLite | null): string {
    if (!part) {
        return '-';
    }
    switch (part.kind) {
        case PartLiteTypes.OffTheShelf:
            return `${part.mpn}, ${part.manufacturer.name}`;
        case PartLiteTypes.Generic:
            return `Generic ${part.content.type}`;
        case PartLiteTypes.Unknown:
            return `Unknown ${part.mpn}, ${part.manufacturer.name}`;
        case PartLiteTypes.RawSpecification:
            if (isPresent(part.manufacturer.name) && isPresent(part.mpn)) {
                return `${part.mpn}, ${part.manufacturer.name}`;
            }
            if (isPresent(part.mpn)) {
                return `${part.mpn}`;
            }
            if (isPresent(part.manufacturer.name)) {
                return `${part.manufacturer.name}`;
            }
            return t`Unknown`;
        case PartLiteTypes.Ipn:
            return part.id;
        case PartLiteTypes.Custom:
            if (part.kind === PartLiteTypes.Custom && part.type.name === CustomPartTypeEnum.PCB) {
                return `PCB`;
            }
            return `Custom ${part.type.name} ${part.description ?? ''}`;
        case PartLiteTypes.CustomComponent:
            return part.id;
    }
}
