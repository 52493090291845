import { t } from '@lingui/macro';
import { assertUnreachable } from '@luminovo/commons';
import { Tag, TanStackTable, Tooltip, createColumnHelper, useTanStackTable } from '@luminovo/design-system';
import React from 'react';
import { formatImportStatus } from '../../../../model/formatImportStatus';
import { formatLinkStatus } from '../../../../model/formatLinkStatus';
import { getCellValue } from '../../../../model/getCellValue';
import { ImporterRow, LinkStatus } from '../../../../types';
import { useUniversalImporter } from '../../context';

export function TablePreview({ rows }: { rows: ImporterRow[] }): JSX.Element {
    const { state } = useUniversalImporter();
    const { config } = state;

    const fields = config.fields;

    const columnFields = React.useMemo(
        () =>
            fields.map((field) => {
                return columnHelper.enum((row) => getCellValue(row, field)?.label, {
                    id: field.id,
                    label: () => field.label ?? field.id,
                    size: 200,
                    getOptionLabel: (value) => value ?? '',
                });
            }),
        [fields],
    );

    const hasImporterRanAlready = state.importerTable?.getRows().some((r) => r.import);

    const statusColumns = React.useMemo(
        () => (hasImporterRanAlready ? [messageColumn, columnImportStatus] : [columnStatus]),
        [hasImporterRanAlready],
    );

    const columns = React.useMemo(() => [...columnFields, ...statusColumns], [columnFields, statusColumns]);

    const tableState = useTanStackTable({
        columns,
        data: rows,
        enableColumnOrdering: false,
        initialState: {
            columnPinning: {
                right: ['message', 'status'],
            },
        },
    });

    return (
        <TanStackTable
            size="small"
            table={tableState.table}
            overrides={{}}
            enableMenuBar={{
                quickFilters: true,
                resultCount: true,
                controlButtons: false,
            }}
        />
    );
}

const columnHelper = createColumnHelper<ImporterRow>();

const statuses: LinkStatus[] = ['skipped', 'insert', 'update'];

const columnStatus = columnHelper.enum(
    (row) => {
        return row.record?.action ?? 'skipped';
    },
    {
        quickFilters: statuses.map((status) => ({
            value: [status],
            label: () => formatLinkStatus(status),
            showCount: true,
            replaceExistingFilters: true,
        })),
        options: statuses,
        label: () => t`Status`,
        id: 'status',
        enableColumnFilter: true,
        enableSorting: false,
        initialPinning: 'right',
        cell: (opt) => {
            const importStatus = opt.row.original.import;
            if (importStatus) {
                if (importStatus.success) {
                    return <Tag attention="low" color="green" label={'Success'} />;
                }
                return (
                    <Tooltip placement="left" title={importStatus.message}>
                        <span>
                            <Tag attention="low" color="red" label={'Error'} />
                        </span>
                    </Tooltip>
                );
            }

            const status = opt.getValue();

            const statusIcon = () => {
                if (status === 'skipped') {
                    return <Tag attention="low" color="neutral" label={'Excluded'} />;
                }
                if (status === 'insert') {
                    return <Tag attention="low" color="violet" label={'New'} />;
                }
                if (status === 'update') {
                    return <Tag attention="low" color="blue" label={'Update'} />;
                }
                assertUnreachable(status);
            };

            return <>{statusIcon()}</>;
        },
        getOptionLabel: (status: LinkStatus) => {
            return formatLinkStatus(status);
        },
        size: 100,
    },
);

const messageColumn = columnHelper.text(
    (row) => {
        const importResult = row.import;
        if (importResult?.success) {
            return '';
        }
        return importResult?.message ?? '';
    },
    {
        id: 'message',
        label: () => t`Message`,
        size: 400,
        initialPinning: 'right',
        description: () => t`Import error message`,
    },
);

const importStatuses = [true, false, undefined];

const columnImportStatus = columnHelper.enum(
    (row) => {
        return row.import?.success;
    },
    {
        quickFilters: importStatuses.map((status) => ({
            value: [status],
            label: () => formatImportStatus(status),
            showCount: true,
            replaceExistingFilters: true,
        })),
        options: importStatuses,
        label: () => t`Status`,
        id: 'status',
        enableColumnFilter: true,
        enableSorting: false,
        initialPinning: 'right',
        cell: (opt) => {
            const importStatus = opt.row.original.import;
            if (!importStatus) {
                return <></>;
            }
            if (importStatus.success) {
                return <Tag attention="low" color="green" label={'Success'} />;
            }
            return (
                <Tooltip placement="left" title={importStatus.message}>
                    <span>
                        <Tag attention="low" color="red" label={'Error'} />
                    </span>
                </Tooltip>
            );
        },
        getOptionLabel: (status: (typeof importStatuses)[number]) => {
            return formatImportStatus(status);
        },
        size: 100,
    },
);
