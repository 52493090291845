import { t, Trans } from '@lingui/macro';
import { formatToLongDate } from '@luminovo/commons';
import {
    colorSystem,
    DestructiveTertiaryIconButton,
    Flexbox,
    MaxWidthLayout,
    PrimaryButton,
    SecondaryButton,
    Tag,
    Toolbar,
} from '@luminovo/design-system';
import { RfqDTO, RfqStatus } from '@luminovo/http-client';
import { DragAndDropUploadImage } from '@luminovo/manufacturing-core';
import { DeleteRounded as DeleteIcon, DescriptionRounded as FileIcon } from '@mui/icons-material';
import { Box, CircularProgress, Divider, Skeleton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import React from 'react';
import Dropzone from 'react-dropzone';
import { Prompt } from 'react-router';
import ConfirmationDialogBox from '../../components/dialogBox/ConfirmationDialogBox';
import DialogBox from '../../components/dialogBox/DialogBox';
import { PageLayout } from '../../components/PageLayout';
import { useCustomer } from '../../resources/customer/customerHandler';
import { usePageParams } from '../../resources/hooks';
import { useHttpFileUpload } from '../../resources/http/useHttpFileUpload';
import { useHttpQuery } from '../../resources/http/useHttpQuery';
import { useDeleteQuotation } from '../../resources/quotation/quotationHandler';
import { useMutationUpdateRfqStatus, useRfQ } from '../../resources/rfq/rfqHandler';
import { useContributors } from '../../resources/user/userHandler';
import { analytics } from '../../utils/analytics';
import { getFileNameFromResourcesString } from '../../utils/stringFunctions';

const forbiddenMIMETypes: string[] = ['application/x-ms-dos-executable', 'application/octet-stream'];

export function QuotationsPage() {
    const { rfqId } = usePageParams<'/rfqs/:rfqId/quotation'>();
    const { data: rfqData } = useRfQ(rfqId);

    const { data: quotationFile, isLoading: isLoadingQuotation } = useHttpQuery(
        /* eslint-disable-next-line spellcheck/spell-checker */
        'GET /rfqs/:rfqId/quotation',
        { pathParams: { rfqId } },
    );

    if (isLoadingQuotation) {
        return (
            <PageLayout
                header={<Toolbar breadcrumbs={[{ title: t`Quotation` }]} />}
                layout="fragment"
                style={{ backgroundColor: colorSystem.neutral[1] }}
                removeHubspotPaddingFix={true}
            >
                <MaxWidthLayout innerMaxWidth={'644px'}>
                    <QuotationBox>
                        <Flexbox flexDirection={'column'} gap={16}>
                            <Typography variant="h1" color="textSecondary">
                                <Trans>Quotation</Trans>
                            </Typography>
                            <SkeletonFileCard />
                        </Flexbox>
                    </QuotationBox>
                </MaxWidthLayout>
            </PageLayout>
        );
    }

    return (
        <PageLayout
            header={<Toolbar breadcrumbs={[{ title: t`Quotation` }]} />}
            layout="fragment"
            removeHubspotPaddingFix={true}
            style={{ backgroundColor: colorSystem.neutral[1] }}
        >
            <MaxWidthLayout innerMaxWidth={'644px'}>
                <QuotationUpload rfqId={rfqId} rfqData={rfqData} file={quotationFile?.data?.url} />
            </MaxWidthLayout>
        </PageLayout>
    );
}

function QuotationUpload({
    rfqId,
    rfqData,
    file,
}: {
    rfqId: string;
    rfqData: RfqDTO | undefined;
    file: string | undefined;
}) {
    const { enqueueSnackbar } = useSnackbar();
    const [isDeleteConfirmationDialogOpen, setIsDeleteConfirmationDialogOpen] = React.useState<boolean>(false);
    const [isSendConfirmationDialogOpen, setIsSendConfirmationDialogOpen] = React.useState<boolean>(false);
    const [quotationFileName, setQuotationFileName] = React.useState<string | undefined>(
        file ? getFileNameFromResourcesString(file) : undefined,
    );

    const { mutateAsync: mutateAsyncUpload, isPending: isUploading } = useHttpFileUpload(
        'GET /rfqs/:rfqId/quotation/upload',
        (res) => res.data.url,
        { snackbarMessage: t`Quotation uploaded`, maxFiles: 1 },
    );
    const { mutateAsync: mutateAsyncDelete, isPending: isDeleting } = useDeleteQuotation(rfqId);
    const { mutateAsync: mutateAsyncChangeStatus } = useMutationUpdateRfqStatus(rfqId);

    const customerData = useCustomer(rfqData?.customer);
    const customerName = customerData.data?.name;

    const { data: contributorsData } = useContributors(rfqId);
    const contributors = contributorsData?.external ?? [];
    const hasContributorFromCustomer = contributors.length > 0;

    const handleImport = (files: File[]) => {
        if (files.length === 0) {
            return;
        }
        if (forbiddenMIMETypes.includes(files[0].type)) {
            enqueueSnackbar(t`Cannot upload unsupported file type`, { variant: 'error' });
        }
        setQuotationFileName(files[0].name);
        mutateAsyncUpload({ pathParams: { rfqId }, files });
    };

    const handleDelete = (fileName: string) => {
        mutateAsyncDelete(getFileNameFromResourcesString(fileName));
        setQuotationFileName(undefined);
        mutateAsyncChangeStatus({ status: RfqStatus.QuotationInProgress });
    };

    const handleSave = () => {
        mutateAsyncChangeStatus({ status: RfqStatus.QuotationAvailable });
    };

    const trackSaveQuotation = () => {
        /* eslint-disable camelcase */
        analytics.track('save_quotation', {
            rfq_id: rfqId,
        });
        /* eslint-enable camelcase */
    };

    const trackSendQuotationToCustomer = () => {
        /* eslint-disable camelcase */
        analytics.track('send_quotation_to_customer', {
            rfq_id: rfqId,
        });
        /* eslint-enable camelcase */
    };

    const rfqCreationDate = rfqData ? formatToLongDate(rfqData.creation_date) : undefined;
    const isQuotationAvailable = rfqData?.status === RfqStatus.QuotationAvailable;
    const isSaveDisabled = file === undefined || isQuotationAvailable || isDeleting;

    return (
        <>
            <Prompt
                when={isUploading || isDeleting}
                message={t`Files are still loading. Are you sure you want to leave?`}
            />
            <QuotationBox>
                <Flexbox flexDirection={'column'} gap={8}>
                    <Flexbox gap={8}>
                        <Typography variant="h1" color="textSecondary">
                            <Trans>Quotation</Trans>
                        </Typography>
                        {hasContributorFromCustomer && isQuotationAvailable && <Tag color="green" label={t`Sent`} />}
                    </Flexbox>
                    {hasContributorFromCustomer && (
                        <Typography style={{ color: colorSystem.neutral[6] }}>
                            {isQuotationAvailable ? (
                                <Trans>
                                    If you want to send a new quotation, click the delete icon and upload a new version
                                </Trans>
                            ) : (
                                <Trans>
                                    Request sent by {customerName} on {rfqCreationDate}
                                </Trans>
                            )}
                        </Typography>
                    )}
                </Flexbox>
                <Flexbox flexDirection={'column'} gap={8}>
                    <Typography variant="h4" color="textSecondary">
                        <Trans>PDF file</Trans>
                    </Typography>
                    {!quotationFileName && !file && (
                        <Dropzone multiple={false} maxFiles={1} onDrop={(pdfFile) => handleImport(pdfFile)}>
                            {({ getRootProps, getInputProps }) => (
                                <div {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <DragAndDropUploadImage
                                        text={t`Click to upload or drag and drop your .pdf file here`}
                                    />
                                </div>
                            )}
                        </Dropzone>
                    )}
                    {isUploading && quotationFileName && (
                        <QuotationCard>
                            <Flexbox gap={8}>
                                <CircularProgress size={'20px'} />
                                <FileNameTypography variant="h4">{quotationFileName}</FileNameTypography>
                            </Flexbox>
                        </QuotationCard>
                    )}
                    {file && (
                        <>
                            <QuotationCard>
                                <Flexbox gap={8}>
                                    <a href={file} download={file} style={{ textDecoration: 'none' }}>
                                        <Flexbox gap={8}>
                                            <FileIcon fontSize="small" style={{ color: colorSystem.neutral[5] }} />
                                            <LinkTypography variant="h4">
                                                {getFileNameFromResourcesString(file)}
                                            </LinkTypography>
                                        </Flexbox>
                                    </a>
                                </Flexbox>

                                {isDeleting ? (
                                    <CircularProgress size={'20px'} />
                                ) : (
                                    <DestructiveTertiaryIconButton
                                        style={{ padding: 0 }}
                                        onClick={() => {
                                            setIsDeleteConfirmationDialogOpen(true);
                                        }}
                                    >
                                        <DeleteIcon
                                            fontSize="inherit"
                                            style={{ color: colorSystem.neutral[7], cursor: 'pointer' }}
                                        />
                                    </DestructiveTertiaryIconButton>
                                )}
                            </QuotationCard>
                            <ConfirmationDialogBox
                                title={t`Delete quotation`}
                                text={t`This file will be permanently removed. Are you sure you want to proceed?`}
                                isDialogOpen={isDeleteConfirmationDialogOpen}
                                onReject={() => setIsDeleteConfirmationDialogOpen(false)}
                                onConfirm={() => handleDelete(getFileNameFromResourcesString(file))}
                                deleteText={t`Yes, delete`}
                            />
                            <SendConfirmationDialogBox
                                isDialogOpen={isSendConfirmationDialogOpen}
                                onConfirm={() => {
                                    handleSave();
                                    trackSendQuotationToCustomer();
                                }}
                                onReject={() => setIsSendConfirmationDialogOpen(false)}
                            />
                        </>
                    )}
                </Flexbox>
                <Divider />
                <Flexbox justifyContent={'center'}>
                    <QuotationButton
                        hasContributorFromCustomer={hasContributorFromCustomer}
                        isDisabled={isSaveDisabled}
                        handleSave={handleSave}
                        trackSaveQuotation={trackSaveQuotation}
                        setIsSendConfirmationDialogOpen={setIsSendConfirmationDialogOpen}
                    />
                </Flexbox>
            </QuotationBox>
        </>
    );
}

const SendConfirmationDialogBox = ({
    isDialogOpen,
    onConfirm,
    onReject,
}: {
    isDialogOpen: boolean;
    onConfirm: () => void;
    onReject: () => void;
}) => {
    return (
        <DialogBox
            title={t`Send quotation`}
            isDialogOpen={isDialogOpen}
            onReject={onReject}
            maxWidth={'xs'}
            closeIconIsShown={false}
        >
            <Flexbox flexDirection={'column'} gap={32}>
                <Typography variant={'body1'}>
                    <Trans>
                        Sending the quotation will notify your customer and enable them to access the quotation in the
                        portal. Would you like to continue?
                    </Trans>
                </Typography>

                <Flexbox justifyContent={'space-between'}>
                    <SecondaryButton onClick={onReject}>
                        <Trans>Cancel</Trans>
                    </SecondaryButton>

                    <PrimaryButton
                        onClick={() => {
                            onConfirm();
                            onReject();
                        }}
                        style={{ width: '80px' }}
                    >
                        <Trans>Send</Trans>
                    </PrimaryButton>
                </Flexbox>
            </Flexbox>
        </DialogBox>
    );
};

const QuotationButton = ({
    hasContributorFromCustomer,
    isDisabled,
    handleSave,
    trackSaveQuotation,
    setIsSendConfirmationDialogOpen,
}: {
    hasContributorFromCustomer: boolean;
    isDisabled: boolean;
    handleSave: () => void;
    trackSaveQuotation: () => void;
    setIsSendConfirmationDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
    if (hasContributorFromCustomer) {
        return (
            <PrimaryButton disabled={isDisabled} onClick={() => setIsSendConfirmationDialogOpen(true)}>
                <Trans>Send to customer</Trans>
            </PrimaryButton>
        );
    }

    return (
        <PrimaryButton
            disabled={isDisabled}
            onClick={() => {
                handleSave();
                trackSaveQuotation();
            }}
            size="medium"
        >
            <Trans>Save quotation</Trans>
        </PrimaryButton>
    );
};

const SkeletonFileCard = () => {
    return (
        <QuotationCard>
            <Flexbox gap={8} alignItems={'center'}>
                <FileIcon fontSize="small" style={{ color: colorSystem.neutral[5] }} />
                <Skeleton width={200} height={25} />
            </Flexbox>
        </QuotationCard>
    );
};

const QuotationBox = styled(Box)(() => ({
    display: 'flex',
    flexDirection: 'column',
    background: 'white',
    padding: '24px 24px 42px 24px',
    borderRadius: '4px',
    gap: '32px',
}));

const QuotationCard = styled(Box)(() => ({
    display: 'flex',
    border: `1px solid ${colorSystem.neutral[3]}`,
    padding: '16px',
    borderRadius: '8px',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '16px',
}));

const LinkTypography = styled(Typography)({
    color: colorSystem.neutral[8],
    maxWidth: '426px',
    wordBreak: 'break-all',
    '&:hover': {
        color: colorSystem.primary[6],
    },
});

const FileNameTypography = styled(Typography)({
    wordBreak: 'break-all',
    color: colorSystem.neutral[8],
    maxWidth: '426px',
});
