import { compareByDate, isPresent } from '@luminovo/commons';
import React from 'react';
import { useHttpQuery } from '../http/useHttpQuery';
import { useOtsPartBulk } from '../part/partHandler';
import { AlertWithFullPart } from './alertTypes';
import { convertAlertDTOToAlert } from './utils';

function useAllAlerts() {
    return useHttpQuery(
        'GET /alerts/stock-level',
        {},
        {
            select: (data) => data.items.map(convertAlertDTOToAlert),
        },
    );
}

export function useAlert(alertId: string) {
    return useHttpQuery(
        'GET /alerts/stock-level/:id',
        { pathParams: { id: alertId } },
        { select: (data) => convertAlertDTOToAlert(data.data) },
    );
}

export function useAlertsWithParts() {
    const { data: alerts } = useAllAlerts();
    const { data: parts } = useOtsPartBulk(
        alerts?.map((alert) => alert.part),
        {
            type: 'OutsideRfQ',
        },
    );

    return React.useMemo(() => {
        if (!alerts || !parts) {
            return { data: undefined, isLoading: true as const };
        }

        const data = alerts
            .map((alert) => ({ ...alert, part: parts.find((p) => p.id === alert.part) }))
            .filter((alertWithPart): alertWithPart is AlertWithFullPart => isPresent(alertWithPart.part))
            .sort(compareByDate((item) => item.creationDate, { type: 'descending' }));

        return { data, isLoading: false as const };
    }, [alerts, parts]);
}
