import { t } from '@lingui/macro';
import { colorSystem, Dialog, DialogContent, DialogTitle } from '@luminovo/design-system';
import { useDialogContext } from '../../../../components/contexts/ModalContext';
import { useHttpMutation } from '../../../../resources/mutation/useHttpMutation';
import { useNegotiation } from '../../hooks/negotiationHandlers';
import { NegotiationForm, NegotiationFormState } from './NegotiationForm';

export function useDialogEditNegotiation({ negotiationId }: { negotiationId: number }) {
    const { setDialog, closeDialog } = useDialogContext();
    const { data: negotiation } = useNegotiation(negotiationId);

    const { mutateAsync } = useHttpMutation('PATCH /negotiation/:id', {
        snackbarMessage: t`Negotiation updated`,
    });

    const onSubmit = async (formValues: NegotiationFormState) => {
        if (formValues.name !== negotiation?.name) {
            await mutateAsync({
                pathParams: { id: negotiationId },
                requestBody: { name: formValues.name },
            });
        }
        closeDialog();
    };

    return {
        openDialog: () =>
            setDialog(
                <Dialog
                    open={true}
                    maxWidth={'sm'}
                    fullWidth={true}
                    transitionDuration={0}
                    onClose={() => closeDialog()}
                >
                    <DialogTitle
                        title={t`Edit negotiation`}
                        handleClose={() => closeDialog()}
                        style={{ borderBottom: `1px solid ${colorSystem.neutral[2]}` }}
                    />
                    <DialogContent>
                        <NegotiationForm
                            defaultValues={{
                                name: negotiation?.name ?? '',
                            }}
                            onSubmit={onSubmit}
                        />
                    </DialogContent>
                </Dialog>,
            ),
    };
}
