import { t, Trans } from '@lingui/macro';
import { isGermanLocale } from '@luminovo/commons';
import { colorSystem, Flexbox, MaxWidthLayout, PrimaryButton, TwinkleStarsIcon } from '@luminovo/design-system';
import { Create, FastForward } from '@mui/icons-material';
import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { PageLayout } from '../../../../components/PageLayout';
import { TeaserCard } from './TeaserCard';

/* very similar to the ManufacturingTeaserPage but decided not to refactor it out into
reusable components because it could be possible that they will differ slightly later on, and 
we want them to be flexible. 
*/

const getSurveyLink = (): string => {
    const LUMIQUOTE_CALCULATION_SURVEY_FORM_ENGLISH = 'https://forms.gle/sdonDTEqP5yacvxFA';
    const LUMIQUOTE_CALCULATION_SURVEY_FORM_GERMAN = 'https://forms.gle/isJAQqkbdWAkJmp46';
    if (isGermanLocale()) {
        return LUMIQUOTE_CALCULATION_SURVEY_FORM_GERMAN;
    } else {
        return LUMIQUOTE_CALCULATION_SURVEY_FORM_ENGLISH;
    }
};

const CalculationTeaserPage: React.FunctionComponent = (): JSX.Element => {
    return (
        <PageLayout style={{ backgroundColor: colorSystem.neutral[1] }}>
            <MaxWidthLayout>
                <Box marginTop={'96px'} marginBottom={'56px'}>
                    <Typography variant="h1" align="center" style={{ fontSize: '24px', color: colorSystem.neutral[9] }}>
                        <Trans>Available now: our calculation module</Trans>
                    </Typography>
                </Box>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={4}>
                        <TeaserCard
                            icon={<TwinkleStarsIcon />}
                            title={t`Simple`}
                            text={t`Benefit from the seamless integration of manufacturing and material costs to effectively add your overhead costs and profits.`}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TeaserCard
                            icon={<FastForward color="primary" />}
                            title={t`Fast`}
                            text={t`Accelerate the overhead calculation with the help of your predefined calculation templates.`}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TeaserCard
                            icon={<Create color="primary" />}
                            title={t`Customisable`}
                            text={t`Tailor the calculation to your individual needs with fully customisable overhead costs, profits, and discounts.`}
                        />
                    </Grid>
                </Grid>

                <Flexbox alignItems="center" justifyContent="center" gap={'8px'} marginTop={'64px'}>
                    <PrimaryButton onClick={() => window.open(getSurveyLink(), '_blank')}>
                        <Trans>Interested? Sign up here</Trans>
                    </PrimaryButton>
                </Flexbox>
            </MaxWidthLayout>
        </PageLayout>
    );
};

export default CalculationTeaserPage;
