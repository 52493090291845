import { colorSystem, Flexbox, Text } from '@luminovo/design-system';
import { Box } from '@mui/material';
import React from 'react';

import { PageLayoutCollapsibleSidebar } from '../PageLayoutCollapsibleSidebar';
import { CostingTemplatesSidebar } from './CostingTemplatesSidebar';
export const CostingTemplatePageLayout = ({
    tabs,
    children,
    title,
}: {
    tabs: React.ReactNode;
    children: React.ReactNode;
    title: string;
}): JSX.Element => {
    return (
        <PageLayoutCollapsibleSidebar sidebar={<CostingTemplatesSidebar />} layoutVariant="fullWidth">
            <Flexbox flexDirection={'column'}>
                <Text
                    variant={'h2'}
                    style={{
                        margin: '24px 0px 24px 0px',
                    }}
                >
                    {title}
                </Text>
                {tabs && <Box style={{ marginBottom: '24px' }}>{tabs}</Box>}
                <Flexbox
                    flexDirection={'column'}
                    padding={'20px'}
                    style={{ backgroundColor: colorSystem.neutral.white, borderRadius: '8px' }}
                >
                    {children}
                </Flexbox>
            </Flexbox>
        </PageLayoutCollapsibleSidebar>
    );
};
