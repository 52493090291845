/* eslint-disable camelcase */
import { t } from '@lingui/macro';
import { formatToLongDate } from '@luminovo/commons';
import {
    colorSystem,
    createColumnHelper,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FieldTextControlled,
    FormItem,
    Link,
    TanStackTable,
    Text,
    useTanStackTable,
} from '@luminovo/design-system';
import { DemandDTO } from '@luminovo/http-client';
import { formatQuantity } from '@luminovo/sourcing-core';
import { Launch } from '@mui/icons-material';
import React from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useHistory } from 'react-router';
import { useDialogContext } from '../../../../components/contexts/ModalContext';
import { FormContainer } from '../../../../components/formLayouts/FormContainer';
import { SubmitButton } from '../../../../components/formLayouts/SubmitButton';
import { useHttpQuery } from '../../../../resources/http/useHttpQuery';
import { useHttpMutation } from '../../../../resources/mutation/useHttpMutation';
import { route } from '../../../../utils/routes';

type CreateNegotiationFormValues = {
    name: string;
    demands: DemandDTO[];
    rfqId: string;
};

export function useCreateNegotiationDialog({ rfqId }: { rfqId: string }) {
    const { setDialog, closeDialog } = useDialogContext();
    const history = useHistory();
    const defaultValues: CreateNegotiationFormValues = {
        name: '',
        demands: [],
        rfqId,
    };

    const { mutateAsync } = useHttpMutation('POST /negotiation', {
        snackbarMessage: t`Negotiation created`,
        onSuccess: (data) => {
            history.push(
                route(`/rfqs/:rfqId/sourcing/negotiations/:negotiationId/line-items`, {
                    rfqId,
                    negotiationId: data.id,
                }),
            );
        },
    });

    const onSubmit = (form: CreateNegotiationFormValues) => {
        const requestBody = {
            name: form.name,
            demand_ids: form.demands.map((demand) => demand.id),
            rfq_id: rfqId,
            filter_by_categories: [],
            filter_manufacturers: [],
            filter_not_quoted_since: null,
            group_by_customer: false,
            group_by_site_groups: [],
            group_by_supplier_groups: [],
            group_by_sourcing_scenario: false,
        };
        mutateAsync({ requestBody });
    };

    return {
        openDialog: () =>
            setDialog(
                <Dialog open={true} maxWidth={'md'} onClose={() => closeDialog()}>
                    <FormContainer defaultValues={defaultValues} onSubmit={onSubmit}>
                        <DialogTitle title={`Create negotiation`} handleClose={() => closeDialog()} />
                        <DialogContent style={{ paddingBottom: '24px' }}>
                            <FormItemName />
                            <FormItemDemands />
                        </DialogContent>
                        <DialogActions>
                            <SubmitButton label={t`Create`} />
                        </DialogActions>
                    </FormContainer>
                </Dialog>,
            ),
    };
}

function FormItemName() {
    const { control } = useFormContext<CreateNegotiationFormValues>();
    return (
        <FormItem
            variant="description-inlined"
            description={t`The name of the negotiation. Use a meaningful name to identify the negotiation.`}
            required
            label={t`Name`}
        >
            <FieldTextControlled
                control={control}
                name="name"
                required
                max={80}
                FieldProps={{
                    placeholder: t`e.g. 2026 Passive components`,
                }}
            />
        </FormItem>
    );
}

function FormItemDemands() {
    const { control } = useFormContext<CreateNegotiationFormValues>();
    const rfqId = useWatch({ control, name: 'rfqId' });
    const { data } = useHttpQuery(
        'GET /rfqs/:rfqId/demands',
        {
            pathParams: { rfqId },
        },
        { select: (data) => data.items },
    );

    return (
        <FormItem
            variant="description-inlined"
            description={t`Select the demands you want to include in the negotiation.`}
            label={t`Demands`}
            sx={{ minHeight: 400, paddingBottom: 8 }}
        >
            <Controller
                control={control}
                name="demands"
                rules={{
                    validate: (value) => {
                        if (value.length === 0) {
                            return t`At least one demand must be selected`;
                        }
                    },
                }}
                render={({ fieldState }) => (
                    <>
                        {fieldState.error && (
                            <Text variant="body" color={colorSystem.red[7]}>
                                {fieldState.error.message}
                            </Text>
                        )}
                        <TableDemands data={data} />
                    </>
                )}
            />
        </FormItem>
    );
}

const columnHelper = createColumnHelper<DemandDTO>();

const columns = [
    columnHelper.enum('context', {
        size: 160,
        getOptionLabel: (value) => value ?? '-',
        label: () => t`Context`,
        renderType: 'text',
    }),

    columnHelper.text((row) => row.assembly?.designator ?? '-', {
        id: 'assembly',
        size: 180,
        label: () => t`Assembly`,
        cell: ({ row }) => {
            const { assembly } = row.original;

            if (!assembly) {
                return '-';
            }

            return (
                <Link
                    to={route('/assemblies/:assemblyId/dashboard', {
                        assemblyId: assembly.id,
                    })}
                    attention="high"
                    startIcon={<Launch fontSize="small" />}
                >
                    {assembly?.designator}
                </Link>
            );
        },
    }),

    columnHelper.number((row) => row.quantity.quantity, {
        id: 'quantity',
        size: 100,
        label: () => t`Quantity`,
        cell: ({ row }) => formatQuantity(row.original.quantity, { showPiecesUnit: false }),
    }),

    columnHelper.date('delivery_start_date', {
        size: 100,
        label: () => t`Delivery start date`,
        cell: ({ row }) => formatToLongDate(row.original.delivery_start_date, { ifAbsent: '-' }),
    }),

    columnHelper.date('delivery_end_date', {
        size: 100,
        label: () => t`Delivery end date`,
        cell: ({ row }) => formatToLongDate(row.original.delivery_end_date, { ifAbsent: '-' }),
    }),
];

function TableDemands({
    data,
}: {
    data?: DemandDTO[];
    onSelectionChange?: (demandIds: string[]) => void;
    showSelection?: boolean;
    selectedDemandIds?: string[];
    showImportButton?: boolean;
}): JSX.Element {
    const { setValue } = useFormContext<CreateNegotiationFormValues>();

    const handleRowSelectionChange = React.useCallback(
        (value: Record<string, boolean>) => {
            const selectedDemands = data?.filter((row) => value[String(row.id)]) ?? [];
            setValue('demands', selectedDemands, { shouldValidate: true });
        },
        [data, setValue],
    );

    const { table } = useTanStackTable({
        data,
        columns,
        enableSelection: {
            enabled: true,
            getRowId: (row) => row.id,
            onRowSelectionChange: handleRowSelectionChange,
        },
    });

    return <TanStackTable size="small" table={table} />;
}
