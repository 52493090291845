import { Trans } from '@lingui/macro';
import { colorSystem, FieldNumericController, FormItem, SecondaryButton, Text } from '@luminovo/design-system';
import {
    FormulaErrorDTO,
    FormulaOKResponseDTO,
    FormulaResponseDTO,
    FormulaTestResponseDTO,
} from '@luminovo/http-client';
import { Box, styled } from '@mui/material';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useAdvancedFormulaTestRun } from '../../../../../resources/formula/formulaHandler';
import { deprecatedYupResolver } from '../../../../../utils/deprecated_yupResolver';
import { createTestRunSchema } from './AdvancedFormulaTestRunSchema';

const StyledBox = styled(Box)({
    height: '150px',
    overflowY: 'auto',
});

const AdvancedFormulaTestRunResult = ({ formulaTestData }: { formulaTestData: FormulaTestResponseDTO }) => {
    if (formulaTestData.result === 'Error') {
        return (
            <Text variant="body" style={{ color: colorSystem.red[8] }}>
                <Trans>Error in formula</Trans>
            </Text>
        );
    }

    return (
        <Text variant="body" style={{ color: colorSystem.neutral[8] }}>
            {formulaTestData.details.return_value}
        </Text>
    );
};

const AdvancedFormulaTestRun = ({
    formula,
    validationData,
    onTestRunChange,
}: {
    formula: string;
    validationData: FormulaOKResponseDTO;
    onTestRunChange: (data: FormulaErrorDTO | undefined) => void;
}) => {
    const identifiers = validationData.details.identifiers;

    const { control, handleSubmit } = useForm<Record<string, string>>({
        mode: 'onChange',
        resolver: deprecatedYupResolver(createTestRunSchema(identifiers)),
    });
    const { mutateAsync, data: testRunData, isPending: isLoading } = useAdvancedFormulaTestRun(formula);

    React.useEffect(() => {
        if (testRunData) {
            if (testRunData.result === 'Error') {
                onTestRunChange(testRunData.details);
            } else {
                onTestRunChange(undefined);
            }
        }
    }, [testRunData, onTestRunChange]);

    return (
        <Box style={{ height: '100%', overflow: 'hidden' }}>
            <StyledBox>
                {identifiers.map((identifier, index) => (
                    <FormItem
                        label={identifier}
                        LabelProps={{ variant: 'body1' }}
                        key={index}
                        style={{
                            display: 'grid',
                            gridTemplateColumns: '1fr 1fr',
                            alignItems: 'center',
                            marginBlockEnd: '24px',
                        }}
                    >
                        <FieldNumericController
                            name={identifier}
                            control={control}
                            required
                            FieldProps={{ style: { height: '32px' } }}
                        />
                    </FormItem>
                ))}

                <Box display={'grid'} gridTemplateColumns={'1fr 1fr'} gap="8px" alignItems={'center'}>
                    <SecondaryButton
                        type="button"
                        size="medium"
                        style={{ width: '80px' }}
                        disabled={isLoading}
                        onClick={(event) => {
                            handleSubmit((data) => mutateAsync(data))(event);
                        }}
                    >
                        <Trans>Run test</Trans>
                    </SecondaryButton>
                    <Text>
                        <Trans>Result: </Trans>
                        &nbsp;
                        {testRunData && <AdvancedFormulaTestRunResult formulaTestData={testRunData} />}
                    </Text>
                </Box>
            </StyledBox>
        </Box>
    );
};

export const AdvancedFormulaTestRunBox = ({
    formula,
    validationData,
    onTestRunChange,
}: {
    formula: string;
    validationData?: FormulaResponseDTO;
    onTestRunChange: (data: FormulaErrorDTO | undefined) => void;
}) => {
    if (!validationData || validationData.result === 'Error') {
        return (
            <Text variant="body">
                <Trans>Please validate the formula before testing</Trans>
            </Text>
        );
    }

    return (
        <AdvancedFormulaTestRun formula={formula} validationData={validationData} onTestRunChange={onTestRunChange} />
    );
};
