import { id } from '@luminovo/commons';
import { UserType } from '@luminovo/http-client';
import { useUserType } from '../../../../components/contexts/CurrentUserDetailsContext';
import { BomItemTableData } from '../../../Bom/components/ModuleTableData';
import { BomItemCommentsButtonInner } from '../BomItemCommentsButtonInner';

export const BomItemCommentsButton = ({ rfqId, bomItem }: { rfqId?: string; bomItem: BomItemTableData }) => {
    const userType = useUserType();
    const isCustomer = userType === UserType.Customer;

    if (!rfqId) {
        return null;
    }
    return (
        <span id={isCustomer ? id('design/button_contact_ems') : id('design/button_add_comment')}>
            <BomItemCommentsButtonInner designItemIds={bomItem.id} rfqId={rfqId} />
        </span>
    );
};
