import { Trans } from '@lingui/macro';
import { colorSystem, Flexbox, RightBoxDrawer, SecondaryButton, Text } from '@luminovo/design-system';
import { GenericFullPart, GenericPartTypes, RfqContext } from '@luminovo/http-client';
import { Typography } from '@mui/material';
import React from 'react';
import { useDrawerContext } from '../../../components/contexts/ModalContext';
import { SectionOfScreen } from '../../../resources/part/partFrontendTypes';
import { AddGenericCapacitorForm } from './AddGenericCapacitorForm';
import { AddGenericResistorForm } from './AddGenericResistorForm';

type PartType = 'Resistor' | 'Capacitor';

interface AddGenericPartProps {
    rfqContext: RfqContext;
    onGenericPartCreation: (data: GenericFullPart, sectionOfScreen: SectionOfScreen) => void;
}

interface GenericTypeField {
    id: PartType;
    label: JSX.Element;
}

const fieldResistor: GenericTypeField = {
    id: 'Resistor',
    label: <Trans>Resistor</Trans>,
};

const fieldCapacitor: GenericTypeField = {
    id: 'Capacitor',
    label: <Trans>Capacitor</Trans>,
};

const genericTypeFields = [fieldResistor, fieldCapacitor];

function FieldToggle({
    selectedFieldId,
    onChange,
}: {
    selectedFieldId: PartType;
    onChange: (newType: PartType) => void;
}) {
    const buttons = Object.values(genericTypeFields).map((type) => {
        const style =
            type.id === selectedFieldId
                ? {
                      background: colorSystem.primary[2],
                      color: colorSystem.primary[7],
                      border: `1px solid ${colorSystem.primary[3]}`,
                  }
                : {};
        return (
            <SecondaryButton style={style} key={type.id} onClick={() => onChange(type.id)}>
                {type.label}
            </SecondaryButton>
        );
    });
    return (
        <Flexbox gap={8} flexDirection={'column'}>
            <Typography variant={'h4'} color={'textSecondary'}>
                <Trans>Type</Trans>
            </Typography>
            <Flexbox gap={8}>{buttons}</Flexbox>
        </Flexbox>
    );
}

const WrappedForm = ({
    onGenericPartCreation,
    rfqContext,
    onSettled,
}: AddGenericPartProps & { onSettled: () => void }) => {
    const [genericPartType, setGenericPartType] = React.useState<PartType>('Resistor');

    return (
        <Flexbox flexDirection={'column'} gap={20} padding={'32px'} minWidth={'400px'}>
            <Text variant={'h2'}>
                <Trans>Add generic part</Trans>
            </Text>

            <FieldToggle selectedFieldId={genericPartType} onChange={setGenericPartType} />
            {genericPartType === GenericPartTypes.Resistor && (
                <AddGenericResistorForm
                    onGenericPartCreation={onGenericPartCreation}
                    rfqContext={rfqContext}
                    onSettled={onSettled}
                />
            )}
            {genericPartType === GenericPartTypes.Capacitor && (
                <AddGenericCapacitorForm
                    onGenericPartCreation={onGenericPartCreation}
                    rfqContext={rfqContext}
                    onSettled={onSettled}
                />
            )}
        </Flexbox>
    );
};

export function useAddGenericPartDrawer({ onGenericPartCreation, rfqContext }: AddGenericPartProps) {
    const { closeDrawer, setDrawer } = useDrawerContext();

    return {
        openDrawer: () => {
            setDrawer(
                <RightBoxDrawer onClose={closeDrawer}>
                    <WrappedForm
                        onGenericPartCreation={onGenericPartCreation}
                        rfqContext={rfqContext}
                        onSettled={closeDrawer}
                    />
                </RightBoxDrawer>,
            );
        },
    };
}
