import { MonetaryValue } from '@luminovo/commons';
import { NegotiationLineItem } from '@luminovo/http-client';
import { getScenarioUnitPrice } from './extractors/extractorNegotionsLineItem';
import { monetaryValue } from './monetaryValueMath';

export function getScenarioVolume(li: NegotiationLineItem, scenarioId?: number): MonetaryValue | undefined {
    const unitPrice = getScenarioUnitPrice(li, scenarioId);

    if (!unitPrice) {
        return undefined;
    }

    return monetaryValue.scale(unitPrice, li.quantity.quantity);
}
