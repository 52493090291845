import { t } from '@lingui/macro';
import { CollapsibleSidebar } from '@luminovo/design-system';
import { CategoryOutlined, FactCheckOutlined } from '@mui/icons-material';
import { route } from '../../utils/routes';

export function CostingTemplatesSidebar() {
    return (
        <CollapsibleSidebar
            id="costing-templates"
            title={t`Costing templates`}
            contents={[
                {
                    type: 'link',
                    href: route('/manufacturing/activity'),
                    Icon: CategoryOutlined,
                    label: t`Manufacturing`,
                    isSelected: (pathname) => pathname.startsWith('/manufacturing/'),
                },
                {
                    type: 'link',
                    href: route('/calculation/templates'),
                    Icon: FactCheckOutlined,
                    label: t`Calculation`,
                },
            ]}
        />
    );
}
