import { compareByNumber } from '@luminovo/design-system';
import { QuoteRequestDTO, QuoteRequestStatus } from '@luminovo/http-client';

const priorityMap = {
    [QuoteRequestStatus.Received]: 0,
    [QuoteRequestStatus.Sent]: 1,
    [QuoteRequestStatus.NotSent]: 2,
    [QuoteRequestStatus.Overdue]: 3,
    [QuoteRequestStatus.Discarded]: 4,
};

export function compareQuoteRequestStatus() {
    return compareByNumber((quoteReqest: QuoteRequestDTO) => priorityMap[quoteReqest.status]);
}
