import { Packaging } from '@luminovo/http-client';
import { cases } from '../combinators/cases';
import { Parser } from '../parsers/Parser';

export interface PackagingProps {}

export const packaging = (_?: PackagingProps): Parser<Packaging> =>
    cases({
        matches: [
            {
                when: ['AmmoPack', 'Ammo Pack', 'Ammo-Pack', 'Ammo', 'Ammunition Pack'],
                then: Packaging.AmmoPack,
            },
            {
                when: ['Bag', 'Pouch', 'Sack'],
                then: Packaging.Bag,
            },
            {
                when: ['Bulk', 'Loose'],
                then: Packaging.Bulk,
            },
            {
                when: ['Carton', 'box', 'cardboard', 'container', 'package'],
                then: Packaging.Carton,
            },
            {
                when: ['ReReel', 'mousereel', 'Re-Reel', 'Re-Reeling', 'mouse reel', 'rewind reel'],
                then: Packaging.ReReel,
            },
            {
                when: ['tray', 'trays'],
                then: Packaging.Tray,
            },
            {
                when: ['tube'],
                then: Packaging.Tube,
            },
            {
                when: ['Reel', 'tape/reel', 'tape&reel', 'tr', 't&r', 'tape and reel', 'tape-reel', 'tape on reel'],
                then: Packaging.Reel,
            },
            {
                when: [
                    'Tape',
                    'cut tape',
                    'cut-tape',
                    'ct',
                    'Gurtabschnitt',
                    'strip',
                    'tape strip',
                    'component tape',
                    'punched tape',
                ],
                then: Packaging.Tape,
            },
        ],
    });
