import { QuoteRequestLineItemWarning } from './getQuoteRequestLineItemWarnings';
export function formatQuoteRequestLineItemWarning(warning: QuoteRequestLineItemWarning): string {
    switch (warning) {
        case QuoteRequestLineItemWarning.LargeMOQ:
            return 'Large MOQ';
        case QuoteRequestLineItemWarning.PartChanged:
            return 'Offered part changed';
        case QuoteRequestLineItemWarning.LargeUnitPrice:
            return 'Large unit price';
    }
}
