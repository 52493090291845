import { t, Trans } from '@lingui/macro';
import {
    Dialog,
    DialogContent,
    DialogTitle,
    Message,
    SecondaryButton,
    TertiaryIconButton,
} from '@luminovo/design-system';
import { TimeCalculation } from '@luminovo/http-client';
import { Edit } from '@mui/icons-material';
import { Grid } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDialogContext } from '../../../components/contexts/ModalContext';
import { FormContainer } from '../../../components/formLayouts/FormContainer';
import { SubmitButton } from '../../../components/formLayouts/SubmitButton';
import { useHttpQuery } from '../../../resources/http/useHttpQuery';
import { useHttpMutation } from '../../../resources/mutation/useHttpMutation';
import { RecursivePartial } from '../../../utils/typingUtils';
import { defaultValues as activityDefaultValues } from '../ActivityCreation/addActivityFormFunctions';
import { AddActivityFormInputs } from '../ActivityCreation/AddActivityFormTypes';
import {
    convertFixedBatchCalculationToForm,
    convertFixedProjectCalculationToForm,
    convertFixedUnitCalculationToForm,
    extractFormulaBatchType,
    extractFormulaProjectType,
    extractFormulaUnitType,
    extractLinearBatchTimeCalculationType,
    extractLinearProjectTimeCalculationType,
    extractLinearUnitTimeCalculationType,
    getSelectedBatchCalculationType,
} from '../ActivityCreation/editActivityFormFunctions';
import { TimeCalculationSection } from '../ActivityCreation/TimeCalculationSection/TimeCalculationSection';
import { generateTimeCalculationFromData } from '../ActivityCreation/timeComponentsFunctions';

export type ActivityOverwriteFormInputs = {
    activity: {
        level: 'Unit' | 'Batch' | 'Project';
        isActivityPerPanel: boolean;
    };
} & Omit<AddActivityFormInputs, 'resourcesDetails' | 'activity'>;

interface ActivityOverwriteDialogProps {
    activityId: string;
    manufacturingScenarioId: string;
    isManualOverwrite: boolean;
    level: 'Unit' | 'Batch' | 'Project';
    isActivityPerPanel: boolean;
}

export const useOverwriteActivityDialog = ({
    activityId,
    manufacturingScenarioId,
    level,
    isManualOverwrite,
    isActivityPerPanel,
}: ActivityOverwriteDialogProps) => {
    const { setDialog, closeDialog } = useDialogContext();

    const { mutateAsync } = useHttpMutation('POST /activities/:activityId/overwrites', {
        snackbarMessage: t`Successfully overwritten activity`,
        onSuccess: closeDialog,
    });

    const { mutateAsync: updateOverwrite } = useHttpMutation(
        'PATCH /activities/:activityId/overwrites/:activityOverwriteId',
        {
            snackbarMessage: t`Successfully overwritten activity`,
            onSuccess: closeDialog,
        },
    );

    const { mutateAsync: resetToDefault, isPending: isLoading } = useHttpMutation(
        'DELETE /activities/:activityId/overwrites',
        {
            snackbarMessage: t`Activity time restored`,
            onSuccess: closeDialog,
        },
    );

    const { data: activityOverwrite } = useHttpQuery(
        'GET /activities/:activityId/overwrites',
        {
            pathParams: { activityId },
            queryParams: { manufacturing_scenario: manufacturingScenarioId },
        },
        { enabled: isManualOverwrite && !isLoading },
    );
    const { data: originalActivity } = useHttpQuery('GET /activities/:activityId', {
        pathParams: { activityId },
    });

    const handleSubmit = async (data: ActivityOverwriteFormInputs) => {
        const timeCalculation = generateTimeCalculationFromData(data);
        if (isManualOverwrite && activityOverwrite?.data?.id) {
            await updateOverwrite({
                pathParams: { activityId, activityOverwriteId: activityOverwrite.data.id },
                requestBody: {
                    manufacturing_scenario: manufacturingScenarioId,
                    time_calculation: timeCalculation,
                },
            });
        } else {
            await mutateAsync({
                pathParams: { activityId },
                requestBody: {
                    manufacturing_scenario: manufacturingScenarioId,
                    time_calculation: timeCalculation,
                },
            });
        }
    };

    const handleResetToDefault = () => {
        resetToDefault({
            pathParams: { activityId },
            queryParams: { manufacturing_scenario: manufacturingScenarioId },
        });
    };

    const {
        activity,
        resourcesDetails,
        selectedBatchCalculationType,
        selectedProjectCalculationType,
        ...timeCalculations
    } = activityDefaultValues;

    const baseDefaultValues: RecursivePartial<ActivityOverwriteFormInputs> = useMemo(
        () => ({
            activity: { level, isActivityPerPanel },
            selectedBatchCalculationType: level === 'Batch' ? 'Fixed' : 'None',
            selectedProjectCalculationType: level === 'Project' ? 'Fixed' : undefined,
            selectedUnitCalculationType: level === 'Unit' ? 'Fixed' : undefined,
            ...timeCalculations,
        }),
        [level, isActivityPerPanel, timeCalculations],
    );

    const getTimeCalculationDefaults = (
        timeCalculation: TimeCalculation,
    ): RecursivePartial<Omit<ActivityOverwriteFormInputs, 'activity'>> => {
        return {
            selectedUnitCalculationType:
                timeCalculation.level === 'Unit' ? timeCalculation.details.unit_time_components?.type : undefined,
            selectedBatchCalculationType: getSelectedBatchCalculationType(timeCalculation) ?? 'None',
            selectedProjectCalculationType:
                timeCalculation.level === 'Project' ? timeCalculation.details.project_time_components?.type : undefined,
            fixedUnitCalculationType: convertFixedUnitCalculationToForm(timeCalculation),
            linearUnitCalculationType: extractLinearUnitTimeCalculationType(timeCalculation),
            fixedBatchCalculationType: convertFixedBatchCalculationToForm(timeCalculation),
            linearBatchCalculationType: extractLinearBatchTimeCalculationType(timeCalculation),
            formulaBatchCalculationType: extractFormulaBatchType(timeCalculation),
            formulaUnitCalculationType: extractFormulaUnitType(timeCalculation),
            formulaProjectCalculationType: extractFormulaProjectType(timeCalculation),
            fixedProjectCalculationType: convertFixedProjectCalculationToForm(timeCalculation),
            linearProjectCalculationType: extractLinearProjectTimeCalculationType(timeCalculation),
        };
    };

    const defaultValues: RecursivePartial<ActivityOverwriteFormInputs> = useMemo(() => {
        const activityData = isManualOverwrite ? activityOverwrite?.data : originalActivity?.data;

        if (activityData) {
            return {
                activity: { level, isActivityPerPanel },
                ...getTimeCalculationDefaults(activityData.time_calculation),
            };
        }

        return baseDefaultValues;
    }, [
        isManualOverwrite,
        activityOverwrite?.data,
        originalActivity?.data,
        level,
        isActivityPerPanel,
        baseDefaultValues,
    ]);

    const ActionButton = () => {
        return (
            <SecondaryButton size="small" onClick={() => handleResetToDefault()}>
                <Trans>Reset to default</Trans>
            </SecondaryButton>
        );
    };

    // Hidden field for the activity level this way we can reuse TimeCalculationSection from ActivityCreation
    const HiddenLevelField = () => {
        const { register, watch, reset } = useFormContext<ActivityOverwriteFormInputs>();

        const level = watch('activity.level');
        const unitType = watch('selectedUnitCalculationType');
        const batchType = watch('selectedBatchCalculationType');
        const projectType = watch('selectedProjectCalculationType');

        // Due to some weird validation error if user inputs value then delete it then change level
        // There persist required error so reset form to default except for the activity level and selected type
        useEffect(() => {
            if (level) {
                reset({
                    ...defaultValues,
                    activity: {
                        level,
                        isActivityPerPanel,
                    },
                    selectedUnitCalculationType: unitType,
                    selectedBatchCalculationType: batchType,
                    selectedProjectCalculationType: projectType,
                });
            }
        }, [level, unitType, batchType, projectType, reset]);
        return (
            <>
                <input type="hidden" {...register('activity.level')} />
                <input type="hidden" {...register('activity.isActivityPerPanel')} />
            </>
        );
    };

    const dialog = (
        <Dialog open={true} maxWidth="lg" fullWidth>
            <DialogTitle title={t`Edit activity time`} handleClose={closeDialog} />
            <DialogContent>
                {isManualOverwrite && (
                    <Message
                        size="small"
                        attention="high"
                        variant="blue"
                        title={t`This activity has been edited manually`}
                        overrides={{
                            ActionButton,
                        }}
                    />
                )}
                <FormContainer defaultValues={defaultValues} onSubmit={handleSubmit}>
                    <HiddenLevelField />
                    <TimeCalculationSection />
                    <Grid container spacing={2} justifyContent="flex-end" style={{ marginTop: '24px' }}>
                        <Grid item>
                            <SecondaryButton onClick={closeDialog}>
                                <Trans>Cancel</Trans>
                            </SecondaryButton>
                        </Grid>
                        <Grid item>
                            <SubmitButton>
                                <Trans>Create Overwrite</Trans>
                            </SubmitButton>
                        </Grid>
                    </Grid>
                </FormContainer>
            </DialogContent>
        </Dialog>
    );

    return {
        openDialog: () => setDialog(dialog),
    };
};

export const OverwriteActivityDialog: React.FC<ActivityOverwriteDialogProps> = (props) => {
    const { openDialog } = useOverwriteActivityDialog(props);

    return (
        <TertiaryIconButton onClick={openDialog} size="large">
            <Edit />
        </TertiaryIconButton>
    );
};
