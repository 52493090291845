import { t } from '@lingui/macro';
import { addYears, differenceInYears, isValid, parse } from 'date-fns';
import type { ParseResult, Parser } from '../types';

export interface ParseDateOptions {
    formats?: string[];
    parseEmptyAsNull?: boolean;
}

export const parseDateCommon = (value: string, parseFormats: string[]): ParseResult<string> => {
    if (parseFormats.length === 0) {
        return {
            status: 'error',
            message: t`No formats provided`,
        };
    }
    for (const format of parseFormats) {
        // date-fns is required because otherwise a date like 30th Feb 2023 will return true.
        const fnsParsed = parse(value, format, new Date());
        if (isValid(fnsParsed)) {
            if (Math.abs(differenceInYears(fnsParsed, new Date())) > 100) {
                // One common cause of the date being over 100 years away is that the parser is expecting a 4 digit format,
                // but the date is in the 2 digit year format. This automatically converts the year but tells the user we did it.
                const twoDigitYear = fnsParsed.getFullYear().toString();
                if (twoDigitYear.length === 2) {
                    const fourDigitYear = addYears(fnsParsed, 2000).getFullYear();
                    fnsParsed.setFullYear(fourDigitYear);
                    return {
                        status: 'warning',
                        value: { id: fnsParsed.toISOString(), label: fnsParsed.toLocaleDateString() },
                        message: t`We changed the year from 00${twoDigitYear} to ${fnsParsed.getFullYear()}.`,
                    };
                }
                return {
                    status: 'warning',
                    value: { id: fnsParsed.toISOString(), label: fnsParsed.toLocaleDateString() },
                    message: t`This date is over 100 years away.`,
                };
            }
            return {
                status: 'done',
                value: { id: fnsParsed.toISOString(), label: fnsParsed.toLocaleDateString() },
            };
        }
    }

    return {
        status: 'error',
        message: t`Unable to parse date using the following formats: ${parseFormats.join(', ')}`,
    };
};

export const parseDate: Parser<string | null> = async function (
    [value],
    opts: ParseDateOptions,
): Promise<ParseResult<string | null>> {
    const parseEmptyAsNull = opts.parseEmptyAsNull ?? true;
    const trimmedValue = value.trim();
    if (typeof value === 'string' && (trimmedValue.length === 0 || trimmedValue === '-') && parseEmptyAsNull) {
        return {
            status: 'done',
            value: { id: null, label: '' },
        };
    }

    const formats = opts.formats ?? [];

    return parseDateCommon(trimmedValue, formats);
};
