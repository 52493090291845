import { SecondaryButton } from '@luminovo/design-system';
import { QuoteRequestStatus } from '@luminovo/http-client';
import { Archive } from '@mui/icons-material';
import { useHistory } from 'react-router';
import { useHttpMutation } from '../../../resources/mutation/useHttpMutation';
import { useQuoteRequest } from '../hooks/negotiationHandlers';

export function ButtonDiscardQuoteRequest({ quoteRequestId }: { quoteRequestId: string }) {
    const history = useHistory();
    const { mutateAsync, isPending: isLoading } = useHttpMutation('PATCH /quote-request/:id', {
        snackbarMessage: 'Quote request updated',
        onSuccess: () => history.goBack(),
    });

    const { data: quoteRequest } = useQuoteRequest(quoteRequestId);

    if (!quoteRequest || quoteRequest.status === QuoteRequestStatus.NotSent) {
        return null;
    }

    return (
        <SecondaryButton
            size="medium"
            startIcon={<Archive />}
            isLoading={isLoading}
            onClick={() =>
                mutateAsync({
                    pathParams: { id: quoteRequestId },
                    requestBody: { discarded: quoteRequest.status !== QuoteRequestStatus.Discarded },
                })
            }
        >
            {quoteRequest.status === QuoteRequestStatus.Discarded ? 'Undo discard' : 'Discard'}
        </SecondaryButton>
    );
}
