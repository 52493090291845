import { EmailTemplateDTO } from '@luminovo/http-client';

export function getQuoteRequestEmailTemplateBySupplier(
    emailTemplates: EmailTemplateDTO[],
    supplierId?: string,
): EmailTemplateDTO | undefined {
    const quoteRequestTemplates = emailTemplates.filter((t) => t.email_type === 'QuoteRequest');
    const defaultQuoteReqTemplate = quoteRequestTemplates.find((t) => t.is_default);
    const supplierQuoteReqTemplate = quoteRequestTemplates.find((t) => t.suppliers.includes(supplierId ?? ''));
    const template = supplierQuoteReqTemplate ?? defaultQuoteReqTemplate;

    return template;
}
