import { Trans } from '@lingui/macro';
import { colorSystem, Link } from '@luminovo/design-system';
import { Box, styled, Typography } from '@mui/material';
import React from 'react';
import { PART_CARD_ROW_HEIGHT } from '../../themes';
import { HighlightableString } from '../../utils/highlighting/highlightableString';
import { Skeleton } from '../Spinners';

export const StyledLightTypography = styled(Typography)({
    color: colorSystem.neutral[6],
});

export const HighlightedValue = ({
    isLoading,
    fragments,
}: {
    isLoading?: boolean;

    fragments: HighlightableString;
}) => {
    const isFullMatch = fragments.length === 1 && fragments[0].isHighlighted;

    function highlightStyles(isHighlighted: boolean) {
        if (isFullMatch) {
            return {
                color: colorSystem.green[7],
                background: colorSystem.green[2],
                fontWeight: 'bold',
            };
        }
        if (isHighlighted) {
            return {
                color: colorSystem.primary[7],
                background: colorSystem.primary[2],
                fontWeight: 'bold',
            };
        }
        return { color: colorSystem.neutral[9] };
    }
    return (
        <>
            {isLoading ? (
                <Skeleton width={45} />
            ) : (
                fragments.map((fragment, i) => {
                    return (
                        <span
                            key={i}
                            style={{
                                ...highlightStyles(fragment.isHighlighted),
                                whiteSpace: 'nowrap',
                            }}
                        >
                            {fragment.fragment}
                        </span>
                    );
                })
            )}
        </>
    );
};

const StyledTypography = styled(Typography)({
    width: '100%',
    textAlign: 'center',
    fontWeight: 600,
});

const StyledBox = styled(Box)({
    color: colorSystem.neutral[7],
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    minHeight: PART_CARD_ROW_HEIGHT,
    cursor: 'pointer',
    backgroundColor: colorSystem.neutral[1],
    '&:hover': {
        color: colorSystem.primary[6],
        backgroundColor: colorSystem.neutral[1],
    },
});

export const DetailsButton = React.memo(
    ({ handleClick }: { handleClick: () => void }): JSX.Element => (
        <StyledBox justifyContent="center">
            <StyledTypography
                onClick={(event) => {
                    handleClick();
                    event.stopPropagation();
                }}
                variant="h5"
            >
                <Trans> View details </Trans>
            </StyledTypography>
        </StyledBox>
    ),
);

export const DatasheetButton = ({ datasheetUrl }: { datasheetUrl: string }): JSX.Element => {
    return (
        <StyledBox justifyContent="center">
            <StyledLink
                href={datasheetUrl}
                underline={'none'}
                target="_blank"
                rel="noopener noreferrer"
                onClick={(e: React.MouseEvent) => e.stopPropagation()}
                variant="h5"
            >
                <Trans> Open datasheet </Trans>
            </StyledLink>
        </StyledBox>
    );
};

const StyledLink = styled(Link)({
    color: 'inherit',
    '&:hover': {
        color: 'inherit',
    },
});
