import { isPresent } from '@luminovo/commons';
import {
    FullPart,
    isCustomComponentFull,
    isCustomFullPart,
    isGenericFullPart,
    isOtsComponentFull,
    isOtsFullPart,
    PartAvailability,
    StandardPartDTO,
    StandardPartTypes,
    StockInformationDTO,
} from '@luminovo/http-client';
import { useBulkSingleQuery } from '../../resources/batching/useBulkQuery';
import { BomItem } from '../../resources/designItem/bomItemFrontendTypes';
import { calculateRequiredStock } from '../../resources/sourcingScenario/calculateRequiredStock';
import { useSourcingScenariosOfRfq } from '../../resources/sourcingScenario/sourcingScenarioHandlers';
import { assertUnreachable } from '../../utils/typingUtils';

type AvailabilityType = 'inStock' | 'outOfStock' | 'insufficientStock';

export interface UseStockAvailabilityInfoResult {
    type: AvailabilityType;
    stock: StockInformationDTO;
    maxRequiredStock: number;
}

export function usePartAvailability({
    rfqId,
    assemblyId,
    bomItem,
    part,
}: {
    rfqId?: string;
    assemblyId: string;
    bomItem: BomItem;
    part: FullPart;
}): UseStockAvailabilityInfoResult | null {
    const { data: sourcingScenarios = [] } = useSourcingScenariosOfRfq(rfqId);
    const { minRequiredStock, maxRequiredStock } = calculateRequiredStock({ sourcingScenarios, bomItem });
    const { data: partAvailability } = useFetchInnerPartAvailability({ part, assemblyId });

    const stock = partAvailability?.stock;

    if (!isPresent(stock)) {
        return null;
    }

    const availableStock: number = stock.external.quantity?.quantity ?? 0;
    const availableInventory: number = stock.inventory.quantity?.quantity ?? 0;
    const maxAvailableStock: number = Math.max(availableStock, availableInventory);

    const type = (): AvailabilityType => {
        if (maxRequiredStock < maxAvailableStock) {
            return 'inStock';
        }
        if (minRequiredStock > maxAvailableStock) {
            return 'outOfStock';
        }
        return 'insufficientStock';
    };

    return {
        stock,
        type: type(),
        maxRequiredStock,
    };
}

function getPartType(part: FullPart): StandardPartTypes | undefined {
    if (isGenericFullPart(part)) {
        return StandardPartTypes.Generic;
    }
    if (isOtsFullPart(part)) {
        return StandardPartTypes.OffTheShelf;
    }
    if (isOtsComponentFull(part)) {
        return StandardPartTypes.Ipn;
    }
    if (isCustomFullPart(part) || isCustomComponentFull(part)) {
        // custom parts are not "standard".
        return undefined;
    }
    assertUnreachable(part);
}

function useFetchInnerPartAvailability({ part, assemblyId }: { part: FullPart; assemblyId: string }) {
    const partType = getPartType(part);
    // This hack is needed because `useBulkQuery` requires `ids` to be of type string.
    // By serialize `StandardPartDTO` into a string and parsing it back to an object in the `httpOptions`
    // function we avoid refactoring `useBulkQuery` for now. If that pattern does become common we should
    // still refactor and make `ids` generic. (2023-03-13 Rene)
    const id = isPresent(partType) ? JSON.stringify({ type: partType, data: part.id }) : undefined;

    return useBulkSingleQuery(
        'POST /parts/availability',
        id,
        {
            idExtractor: (item: PartAvailability) => JSON.stringify(item.part),
            httpOptions: (jsonEncodeStandartParts: string[]) => {
                const ids: StandardPartDTO[] = jsonEncodeStandartParts.map((item) => JSON.parse(item));
                return { requestBody: { assembly_id: assemblyId, ids } };
            },
            select: (res) => res.items,
        },
        {
            refetchInterval({ state }) {
                if (state.data === undefined || state.data.stock === null) {
                    return 5_000;
                }
                if (!state.data.stock.external.last_updated) {
                    return 5_000;
                }

                return false;
            },
        },
    );
}
