import { QuoteRequestDTO, UserType } from '@luminovo/http-client';
import { useCurrentUserDetailsContext } from '../../../../../components/contexts/CurrentUserDetailsContext';
import { isPresent } from '../../../../../utils/isPresent';
import { useQuoteRequest } from '../../../hooks/negotiationHandlers';
import { useHoursUntilQuoteFrozen } from '../../../hooks/useHoursUntilQuoteFrozen';

/**
 *
 * ```
 *      |...................|...............|
 *      |                   |               |
 *      |      ACTIVE       |    FROZEN     |
 *      |                   |               |
 *      |                   |               |
 * received_date    hours_until_frozen     now
 * ```
 */
export function useIsQuoteRequestFrozen(quoteRequestId?: string): boolean {
    const { data: quoteRequest } = useQuoteRequest(quoteRequestId);
    const { userType } = useCurrentUserDetailsContext();
    const hoursUntilFrozen = useHoursUntilQuoteFrozen();

    if (!quoteRequest || !isPresent(hoursUntilFrozen)) {
        return false;
    }

    // TODO(supplier-portal): remove this once we have a way to freeze quote requests for non-suppliers
    if (userType !== UserType.Supplier) {
        // freezing only applies to suppliers
        return false;
    }

    return isQuoteRequestFrozen(quoteRequest, hoursUntilFrozen);
}

function isQuoteRequestFrozen(quoteRequest: QuoteRequestDTO, hoursUntilFrozen: number) {
    const receivedAt = quoteRequest.received_date;
    if (quoteRequest.discarded) {
        return true;
    }
    if (!receivedAt) {
        return false;
    }

    return calculateHoursBetweenDates(new Date(receivedAt), new Date()) > hoursUntilFrozen;
}

function calculateHoursBetweenDates(from: Date, to: Date): number {
    return (to.getTime() - from.getTime()) / (1000 * 60 * 60);
}
