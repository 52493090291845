import { t } from '@lingui/macro';
import { colorSystem, Flexbox, Tag, Text, Tooltip } from '@luminovo/design-system';
import { RfqStatus } from '@luminovo/http-client';
import { formatAdditionalServiceTranslation, IndustryTag } from '@luminovo/manufacturing-core';
import { Divider } from '@mui/material';
import { ReactElement } from 'react';
import { useCurrentUserDetailsContext } from '../../../../../components/contexts/CurrentUserDetailsContext';
import { transEnum } from '../../../../../components/localization/TransEnum';
import { StyledRfqStatusBox } from '../../../../../components/StyledComponents';
import { useSuspenseHttpQuery } from '../../../../../resources/http/useHttpQuery';
import { assemblyTypePublicTranslations } from '../../../../../resources/rfq/i18n';
import { useRfQ } from '../../../../../resources/rfq/rfqHandler';
import { SectionDefaultContactPerson } from './SectionDefaultContactPerson';
import { SectionOrderOverview } from './SectionOrderOverview';
import { SourcingScenarioTags } from './SourcingScenarioTags';

const RFQ_STATUSES_WITHOUT_ORDER_SIZES = [RfqStatus.OrderInProduction, RfqStatus.OrderShipped];
const RFQ_STATUSES_WITH_ORDER_OVERVIEW = [
    RfqStatus.OrderConfirmed,
    RfqStatus.OrderInProduction,
    RfqStatus.OrderShipped,
];

const RfqInfo = ({ label, children }: { label: string; children: ReactElement }): JSX.Element => {
    return (
        <Flexbox flexDirection={'column'} alignItems="flex-start" gap={8}>
            <Text variant="h4" color={colorSystem.neutral[8]}>
                {label}
            </Text>
            {children}
        </Flexbox>
    );
};

export function CustomerDashboardRfqCard({ rfqId }: { rfqId: string }) {
    const { data: rfq } = useRfQ(rfqId);
    const { user } = useCurrentUserDetailsContext();
    const { data: additionalServices = [] } = useSuspenseHttpQuery(
        'GET /rfqs/:rfqId/additional-services',
        {
            pathParams: { rfqId },
        },
        { select: (resp) => resp.data },
    );

    if (!rfq) {
        return null;
    }

    const shouldShowOrderSizes = !RFQ_STATUSES_WITHOUT_ORDER_SIZES.includes(rfq.status);
    const shouldShowOrderOverview = RFQ_STATUSES_WITH_ORDER_OVERVIEW.includes(rfq.status);

    return (
        <Flexbox
            flexDirection={'column'}
            padding="32px"
            style={{ background: colorSystem.neutral.white }}
            borderRadius="8px"
            border={`1px solid ${colorSystem.neutral[3]}`}
            gap={32}
            minWidth={400}
        >
            <Flexbox justifyContent={'space-between'} alignItems="center">
                <Tooltip variant="white" title={rfq.name}>
                    <Text variant="h2" style={{ color: colorSystem.neutral[8], whiteSpace: 'nowrap' }}>
                        {rfq.name}
                    </Text>
                </Tooltip>
            </Flexbox>

            <RfqInfo label={t`RfQ status`}>
                <StyledRfqStatusBox rfqStatus={rfq.status} />
            </RfqInfo>

            <RfqInfo label={t`Assembly type`}>
                <>
                    {rfq.assemblies_types.map((assembly, i) => {
                        return (
                            <Tag
                                key={i}
                                label={transEnum(assembly.type, assemblyTypePublicTranslations)}
                                color="neutral"
                            />
                        );
                    })}
                </>
            </RfqInfo>

            {shouldShowOrderSizes && (
                <RfqInfo label={t`Order sizes`}>
                    <Flexbox gap={8} flexWrap="wrap">
                        <SourcingScenarioTags sourcingScenarioIds={rfq.sourcing_scenarios} />

                        {rfq.sourcing_scenarios.length === 0 && <Text>No order sizes</Text>}
                    </Flexbox>
                </RfqInfo>
            )}

            {shouldShowOrderOverview && (
                <SectionOrderOverview rfqId={rfq.id} divider={<Divider style={{ margin: '0 -32px' }} />} />
            )}

            <RfqInfo label={t`Industry`}>
                <IndustryTag industry={rfq.industry} />
            </RfqInfo>

            {additionalServices.length > 0 && (
                <RfqInfo label={t`Additional services`}>
                    <Text>
                        {
                            /* TODO: Use formatter from @luminovo/commons using Intl.ListFormat */
                            additionalServices
                                .map((additionalService) =>
                                    formatAdditionalServiceTranslation(additionalService, user.user_language),
                                )
                                .join(', ')
                        }
                    </Text>
                </RfqInfo>
            )}

            <Divider style={{ margin: '0 -32px' }} />

            <SectionDefaultContactPerson />
        </Flexbox>
    );
}
