import { t, Trans } from '@lingui/macro';
import { DestructivePrimaryButton, SecondaryButton } from '@luminovo/design-system';
import { Box, Grid, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import React from 'react';
import DialogBox, { DialogBoxBaseProps } from './DialogBox';

interface ConfirmationDialogBoxProps extends DialogBoxBaseProps {
    onConfirm: () => Promise<unknown> | void;
    title: string;
    text: string;
    deleteText?: string;
    children?: React.ReactNode;
}
const ConfirmationDialogBox: React.FunctionComponent<ConfirmationDialogBoxProps> = ({
    onReject,
    isDialogOpen,
    onConfirm,
    title,
    text,
    deleteText = t`Delete`,
    children,
}: ConfirmationDialogBoxProps): JSX.Element => {
    const { mutateAsync, isPending: isLoading } = useMutation({
        mutationFn: async () => {
            return onConfirm();
        },
    });

    return (
        <DialogBox
            title={title}
            isDialogOpen={isDialogOpen}
            onReject={onReject}
            maxWidth={'xs'}
            closeIconIsShown={false}
        >
            <Box>
                <Typography variant={'body1'}>{text}</Typography>
                {children && children}
                <Box mt={2}>
                    <Grid container justifyContent={'space-between'}>
                        <Grid item>
                            <SecondaryButton
                                onClick={() => {
                                    onReject();
                                }}
                                size="medium"
                                disabled={isLoading}
                            >
                                <Typography variant="h4">
                                    <Trans>Cancel</Trans>
                                </Typography>
                            </SecondaryButton>
                        </Grid>
                        <Grid item>
                            <DestructivePrimaryButton
                                disabled={isLoading}
                                isLoading={isLoading}
                                onClick={async () => {
                                    await mutateAsync();
                                    onReject();
                                }}
                                size="medium"
                            >
                                <Typography variant="h4">{deleteText}</Typography>
                            </DestructivePrimaryButton>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </DialogBox>
    );
};

export default ConfirmationDialogBox;
