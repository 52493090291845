import { colorSystem, Flexbox } from '@luminovo/design-system';
import { styled } from '@mui/material';

export const StatusContainer = styled(Flexbox)({
    height: 'auto',
    background: colorSystem.neutral.white,
    flexDirection: 'column',
    border: `1px solid ${colorSystem.neutral[2]}`,
    borderRadius: '12px',
});
