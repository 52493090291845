import { PCBV2 } from '@luminovo/http-client';
import { Box } from '@mui/material';
import { ViewContext } from '../../Bom/components/ModuleTableData';
import { PcbPriceRadar } from './PcbPriceRadar';

export const PcbCapabilityCheckWidget = ({
    pcb,
    rfqId,
    assemblyId,
    isRfqEditable,
    viewContext,
}: {
    pcb: PCBV2;
    rfqId: string;
    assemblyId: string;
    isRfqEditable: boolean;
    viewContext: ViewContext;
}) => {
    return (
        <Box
            style={{
                position: 'absolute',
                top: '16px',
                right: '16px',
                zIndex: 1,
                maxWidth: '500px',
                display: 'flex',
                flexDirection: 'column',
                gap: '8px',
                alignItems: 'end',
                maxHeight: '95%',
                overflow: 'hidden',
                overflowY: 'auto',
            }}
        >
            <PcbPriceRadar
                pcb={pcb}
                rfqId={rfqId}
                assemblyId={assemblyId}
                isRfqEditable={isRfqEditable}
                viewContext={viewContext}
            />
        </Box>
    );
};
