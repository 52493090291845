import { plural, t } from '@lingui/macro';
import { getToken } from '@luminovo/auth';
import {
    ActivitiesDTO,
    DriverIdDTO,
    ExpenseDTO,
    ManufacturingEntityStatusSelection,
    ManufacturingScenarioTemplateDTO,
    http,
} from '@luminovo/http-client';
import { RightAlignedStickyTableCell, formatDriverIdParameter } from '@luminovo/manufacturing-core';
import { Typography } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { ErrorText } from '../../../components/Spinners';
import ConfirmationDialogBox from '../../../components/dialogBox/ConfirmationDialogBox';
import InformationDialogBox, { InformationDialogBoxContent } from '../../../components/dialogBox/InformationDialogBox';
import { useDebugErrorHandler } from '../../../resources/http/debugErrorHandler';
import { httpQueryKey } from '../../../resources/http/httpQueryKey';
import { useHttpQuery } from '../../../resources/http/useHttpQuery';
import {
    useManufacturingScenarioTemplatesOfActivities,
    useManufacturingScenarioTemplatesOfExpenses,
} from '../../../resources/manufacturingScenarioTemplates/manufacturingScenarioTemplatesHandler';
import { route } from '../../../utils/routes';
import { capitalizeFirstLetter } from '../../../utils/stringFunctions';
import { ActionMenuComponent } from '../shared/manufacturingComponents';
import { useInformationAndConfirmationDialogs } from '../shared/manufacturingUtils';
interface UseDeleteDriver {
    deleteOnClick: () => Promise<unknown>;
    isDriverUsedInAnActivityOrExpense: boolean;
    activitiesOfDriver: ActivitiesDTO | undefined;
    expensesOfDriver: ExpenseDTO[] | undefined;
    isLoading: boolean;
    isError: boolean;
}

const useDeleteDriver = (driverId: DriverIdDTO): UseDeleteDriver => {
    const { enqueueSnackbar } = useSnackbar();
    const {
        data: activitiesData,
        isLoading: isActivitiesLoading,
        isError: isActivitiesError,
        /* eslint-disable-next-line camelcase */
    } = useHttpQuery('GET /activities', {
        queryParams: {
            // eslint-disable-next-line camelcase
            driver_id: formatDriverIdParameter(driverId),
        },
    });
    const {
        data: expensesData,
        isLoading: isExpensesLoading,
        isError: isExpensesError,
        /* eslint-disable-next-line camelcase */
    } = useHttpQuery('GET /expenses', {
        queryParams: {
            // eslint-disable-next-line camelcase
            driver_id: formatDriverIdParameter(driverId),
        },
    });

    const token = getToken();
    const debugErrorHandler = useDebugErrorHandler();
    const queryClient = useQueryClient();

    const { mutateAsync } = useMutation({
        mutationFn: () => http('DELETE /user-drivers/:driverId', { pathParams: { driverId: driverId.value } }, token),
        onError: debugErrorHandler,
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: httpQueryKey('GET /user-drivers') });
            enqueueSnackbar(t`Driver deleted`, { variant: 'info' });
        },
    });

    const activitiesOfDriver: ActivitiesDTO | undefined = activitiesData?.data;
    const expensesOfDriver: ExpenseDTO[] | undefined = expensesData?.data;
    const isLoading = isActivitiesLoading || isExpensesLoading;
    const isError = isActivitiesError || isExpensesError;
    const isDriverUsedInAnActivityOrExpense =
        (!isLoading && !isError && activitiesOfDriver !== undefined && activitiesOfDriver?.length > 0) ||
        (expensesOfDriver !== undefined && expensesOfDriver?.length > 0);

    return {
        deleteOnClick: () => mutateAsync(),
        isDriverUsedInAnActivityOrExpense,
        activitiesOfDriver,
        expensesOfDriver,
        isLoading,
        isError,
    };
};

interface DriverMenuProps {
    driverId: DriverIdDTO;
    status: string;
}

const generateTranslatedStr = (
    expensesOfDriver: ExpenseDTO[],
    activitiesOfDriver: ActivitiesDTO,
    templates: ManufacturingScenarioTemplateDTO[],
) => {
    const activitiesOfDriverStr: string = activitiesOfDriver
        ? activitiesOfDriver.map((activity) => activity.name).join(', ')
        : '';
    const expensesOfDriverStr: string = expensesOfDriver
        ? expensesOfDriver.map((expense) => expense.name).join(', ')
        : '';
    const templatesOfDriverStr: string = templates.map((template) => template.name).join(', ');

    const pluralisedActivity = plural(activitiesOfDriver?.length ?? 0, {
        one: `activity`,
        other: `activities`,
    });
    const pluralisedExpense = plural(expensesOfDriver?.length ?? 0, {
        one: `expense`,
        other: `expenses`,
    });

    const expenseLength = expensesOfDriver?.length ?? 0;
    const activitiesLength = activitiesOfDriver?.length ?? 0;

    var translatedString: string = '';
    if (expenseLength > 0 && activitiesLength > 0) {
        translatedString = t`This driver is used in ${pluralisedActivity} ${activitiesOfDriverStr} as well as ${pluralisedExpense} ${expensesOfDriverStr}.`;
        translatedString += ' ';
        switch (true) {
            case expenseLength === 1 && activitiesLength === 1: {
                translatedString += t`Deactivating it will also deactivate the respective activity as well as expense.`;
                break;
            }
            case expenseLength > 1 && activitiesLength === 1: {
                translatedString += t`Deactivating it will also deactivate the respective activities as well as expense.`;
                break;
            }
            case expenseLength === 1 && activitiesLength > 1: {
                translatedString += t`Deactivating it will also deactivate the respective activity as well as expenses.`;
                break;
            }
            case expenseLength > 1 && activitiesLength > 1: {
                translatedString += t`Deactivating it will also deactivate the respective activities as well as expenses.`;
                break;
            }
        }
    } else if (expenseLength > 0) {
        translatedString = t`This driver is used in ${pluralisedExpense} ${expensesOfDriverStr}.`;
        translatedString += ' ';
        if (expenseLength === 1) {
            translatedString += t`Deactivating it will also deactivate the respective expense.`;
        } else if (expenseLength > 1) {
            translatedString += t`Deactivating it will also deactivate the respective expenses.`;
        }
    } else if (activitiesLength > 0) {
        translatedString = t`This driver is used in ${pluralisedActivity} ${activitiesOfDriverStr}.`;
        translatedString += ' ';

        if (activitiesLength === 1) {
            translatedString += t`Deactivating it will also deactivate the respective activity.`;
        } else if (activitiesLength > 1) {
            translatedString += t`Deactivating it will also deactivate the respective activities.`;
        }
    }

    if (templates.length > 0) {
        translatedString += ' ';
        if (templates.length > 1) {
            translatedString += t`Since these are used in templates ${templatesOfDriverStr} they will also be deactivated.`;
        } else if (templates.length === 1) {
            translatedString += t`Since these are used in template ${templatesOfDriverStr} it will also be deactivated.`;
        }
    }

    return translatedString + ' ' + t`Proceed?`;
};

export const DriverMenuComponent: React.FunctionComponent<DriverMenuProps> = ({ driverId, status }): JSX.Element => {
    const queryClient = useQueryClient();
    const token = getToken();
    const {
        deleteOnClick,
        isDriverUsedInAnActivityOrExpense,
        isLoading,
        activitiesOfDriver,
        expensesOfDriver,
        isError: isErrorDeleteDriver,
    } = useDeleteDriver(driverId);
    const {
        openDialog: openDeleteDialog,
        closeDialog: closeDeleteDialog,
        isInformationDialogOpen: isInformationDialogDeleteOpen,
        isConfirmationDialogOpen: isConfirmationDialogDeleteOpen,
    } = useInformationAndConfirmationDialogs(isDriverUsedInAnActivityOrExpense);

    const {
        openDialog: openSetInactiveDialog,
        closeDialog: closeSetInactiveDialog,
        isConfirmationDialogOpen: isConfirmationDialogSetInactiveOpen,
    } = useInformationAndConfirmationDialogs(false);

    const { data: templatesOfActivitiesData, isError: isTemplatesActivitiesError } =
        useManufacturingScenarioTemplatesOfActivities(
            activitiesOfDriver?.map((activity) => activity.id) ?? [],
            activitiesOfDriver !== undefined && activitiesOfDriver.length > 0,
        );
    const { data: templatesOfExpensesData, isError: isTemplatesExpensesError } =
        useManufacturingScenarioTemplatesOfExpenses(
            expensesOfDriver?.map((expense) => expense.id) ?? [],
            expensesOfDriver !== undefined && expensesOfDriver.length > 0,
        );

    const isError = isErrorDeleteDriver || isTemplatesExpensesError || isTemplatesActivitiesError;

    const templates: ManufacturingScenarioTemplateDTO[] | undefined = [
        ...(templatesOfActivitiesData?.data ?? []),
        ...(templatesOfExpensesData?.data ?? []),
    ];

    const history = useHistory();

    const { mutateAsync: mutateAsyncPatch } = useMutation({
        mutationFn: (info: { token: string; status: ManufacturingEntityStatusSelection }) => {
            return http(
                'PATCH /user-drivers/:driverId',
                { requestBody: { status: info.status }, pathParams: { driverId: driverId.value } },
                token,
            );
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: httpQueryKey('GET /user-drivers') });
        },
    });

    const translatedString = generateTranslatedStr(expensesOfDriver ?? [], activitiesOfDriver ?? [], templates);

    return (
        <RightAlignedStickyTableCell>
            <ActionMenuComponent
                isActive={status === 'Active'}
                onClickActivate={function (event: React.MouseEvent<HTMLElement>): void {
                    if (status === 'Active' && isDriverUsedInAnActivityOrExpense) {
                        openSetInactiveDialog();
                    } else {
                        mutateAsyncPatch({
                            token,
                            status: status === 'Active' ? 'Inactive' : 'Active',
                        });
                    }
                }}
                onClickEdit={function (event: React.MouseEvent<HTMLElement>): void {
                    history.push(route('/manufacturing/driver/:driverId/edit', { driverId: driverId.value }));
                }}
                onClickDuplicate={function (event: React.MouseEvent<HTMLElement>): void {
                    history.push(route('/manufacturing/driver/:driverId/duplicate', { driverId: driverId.value }));
                }}
                isLoadingDelete={isLoading}
                onClickDelete={openDeleteDialog}
            />
            {isError && <ErrorText />}
            {isDriverUsedInAnActivityOrExpense && (
                <InformationDialogBox
                    title={t`Deleting not possible`}
                    onReject={closeDeleteDialog}
                    isDialogOpen={isInformationDialogDeleteOpen}
                >
                    <InformationDialogBoxContent
                        text={t`This driver cannot be deleted because it is used in the following entities.`}
                    >
                        <>
                            <>
                                {activitiesOfDriver !== undefined && activitiesOfDriver.length > 0 && (
                                    <>
                                        <Typography variant={'body1'}>
                                            {capitalizeFirstLetter(t`activities`) + ':'}
                                        </Typography>
                                        <ul>
                                            {activitiesOfDriver.map((activity) => (
                                                <li key={activity.id}>
                                                    <Typography variant={'body1'}> {activity.name} </Typography>
                                                </li>
                                            ))}
                                        </ul>
                                    </>
                                )}
                                {expensesOfDriver !== undefined && expensesOfDriver.length > 0 && (
                                    <>
                                        <Typography variant={'body1'}>
                                            {capitalizeFirstLetter(t`expenses`) + ':'}
                                        </Typography>
                                        <ul>
                                            {expensesOfDriver.map((expense) => (
                                                <li key={expense.id}>
                                                    <Typography variant={'body1'}> {expense.name} </Typography>
                                                </li>
                                            ))}
                                        </ul>
                                    </>
                                )}
                            </>
                        </>
                    </InformationDialogBoxContent>
                </InformationDialogBox>
            )}

            <ConfirmationDialogBox
                text={t`Are you sure you want to delete this driver?`}
                title={t`Delete driver`}
                onConfirm={() => deleteOnClick()}
                onReject={closeDeleteDialog}
                isDialogOpen={isConfirmationDialogDeleteOpen}
                deleteText={t`Yes, delete`}
            />

            {isDriverUsedInAnActivityOrExpense && (
                <ConfirmationDialogBox
                    text={t`Are you sure you want to set this driver as inactive?`}
                    title={t`Deactivate driver`}
                    onConfirm={() =>
                        mutateAsyncPatch({
                            token,
                            status: 'Inactive',
                        })
                    }
                    onReject={closeSetInactiveDialog}
                    isDialogOpen={isConfirmationDialogSetInactiveOpen}
                    deleteText={t`Yes, set inactive`}
                >
                    <Typography variant={'body1'}>{translatedString}</Typography>
                </ConfirmationDialogBox>
            )}
        </RightAlignedStickyTableCell>
    );
};
