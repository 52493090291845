/* eslint-disable camelcase */
import { assertPresent, compareByDate, isPresent, uniq } from '@luminovo/commons';
import { compareByNumber } from '@luminovo/design-system';
import {
    AwardedOffer,
    DemandDTO,
    NegotiationLineItem,
    NegotiationLineItemDTO,
    SupplierAndStockLocationDTO,
} from '@luminovo/http-client';
import { useHttpQuery, useSuspenseHttpQuery } from '../../../resources/http/useHttpQuery';
import { useSupplierAndStockLocations } from '../../../resources/supplierAndStockLocation/supplierAndStockLocationHandler';
import { resolveOtsPartsRecursively } from '../model/resolveOtsPartsRecursively';

function inlineSuppliersIntoNegotiationLineItem(
    lineItem: NegotiationLineItemDTO,
    suppliers: SupplierAndStockLocationDTO[] | undefined,
    demands: DemandDTO[] | undefined,
): NegotiationLineItem {
    const updatedAwardedOffers: AwardedOffer[] = lineItem.awarded_offers.map((offer) => ({
        ...offer,
        awarded_solution: {
            ...offer.awarded_solution,
            supplier_and_stock_location: suppliers?.find(
                (supplier) => supplier.id === offer.awarded_solution.supplier_and_stock_location_id,
            ),
        },
    }));
    return {
        ...lineItem,
        awarded_offers: updatedAwardedOffers,
        demands: demands?.filter((demand) => lineItem.demands.includes(demand.id)) ?? [],
    };
}

export function useNegotiations() {
    return useHttpQuery(
        'GET /negotiation',
        {},
        {
            select: (data) => Array.from(data.items).sort(compareByDate((n) => n.created_at)),
        },
    );
}

export function useNegotiation(negotiationId: number | undefined) {
    return useHttpQuery(
        'GET /negotiation',
        {},
        {
            select: (data) => data.items.find((item) => item.id === negotiationId),
        },
    );
}

export function useNegotiationLineItems(negotiationId: number | undefined) {
    const { data: suppliers } = useSupplierAndStockLocations();
    const { data: demands } = useDemandsInNegotiation(negotiationId);

    return useHttpQuery(
        'GET /negotiation/:id/line-items',
        {
            pathParams: {
                id: negotiationId ?? NaN,
            },
        },
        {
            enabled: isPresent(negotiationId),
            select: (data) => {
                return data.map((line_item) => inlineSuppliersIntoNegotiationLineItem(line_item, suppliers, demands));
            },
        },
    );
}

export function useQuoteRequest(quoteRequestId: string | undefined) {
    return useHttpQuery(
        'POST /quote-request/find',
        {
            requestBody: {
                quote_request_ids: isPresent(quoteRequestId) ? [quoteRequestId] : [],
            },
        },
        {
            enabled: isPresent(quoteRequestId),
            select: (data) => data.items[0],
        },
    );
}

/**
 * Unlike {@link useQuoteRequest}, fetches all quote requests and selects the one with the given `quoteRequestId`.
 * There is an implicity performance tradeoff here, the assumption being that you will often want to jump between quote requests.
 */
export function useSuspendedQuoteRequest(quoteRequestId: string) {
    return useSuspenseHttpQuery(
        'POST /quote-request/find',
        {
            requestBody: {},
        },
        {
            select: (data) => assertPresent(data.items.find((item) => item.id === quoteRequestId)),
        },
    );
}

export function useQuoteRequests(quoteRequestIds: string[] | undefined) {
    return useHttpQuery(
        'POST /quote-request/find',
        {
            requestBody: {
                quote_request_ids: quoteRequestIds ?? [],
            },
        },
        {
            select: (data) => data.items,
            enabled: isPresent(quoteRequestIds),
        },
    );
}

export function useQuoteRequestsByNumber(quoteRequestNumbers: number[] | undefined) {
    return useHttpQuery(
        'POST /quote-request/find',
        {
            requestBody: {
                quote_request_numbers: quoteRequestNumbers ?? [],
            },
        },
        {
            select: (data) => data.items,
            enabled: isPresent(quoteRequestNumbers),
        },
    );
}

export function useNegotiationQuoteRequests(negotiationId: number | undefined) {
    return useHttpQuery(
        'POST /quote-request/find',
        {
            requestBody: {
                negotiation_id: negotiationId ?? NaN,
            },
        },
        {
            select: (data) => Array.from(data.items).sort(compareByDate((n) => n.created_at)),
            enabled: isPresent(negotiationId),
        },
    );
}

export function useAwardScenarios(negotiationId: number | undefined) {
    return useHttpQuery(
        'POST /award-scenario/find',
        {
            requestBody: {
                negotiation_id: negotiationId ?? NaN,
            },
        },
        {
            select: (data) =>
                Array.from(data.items).sort(
                    compareByNumber((scenario) => {
                        if (scenario.kind.tag === 'Awarded') {
                            return -1;
                        }
                        if (
                            scenario.kind.tag === 'BestPriceAcrossAllQuotes' ||
                            scenario.kind.tag === 'LastPurchasePrice' ||
                            scenario.kind.tag === 'LastStandardPrice' ||
                            scenario.kind.tag === 'LastTargetPrice'
                        ) {
                            return 0;
                        }
                        if (scenario.kind.tag === 'Manual') {
                            return 1;
                        }
                        if (scenario.kind.tag === 'QuoteRequest') {
                            return 2;
                        }
                        // Default to first position
                        return 0;
                    }),
                ),
            enabled: isPresent(negotiationId),
        },
    );
}

export function useQuoteRequestLineItemsByQuoteRequest(quoteRequestId: string) {
    return useSuspenseHttpQuery(
        'GET /quote-request/:id/line-items',
        {
            pathParams: { id: quoteRequestId },
        },
        {
            select: (data) => data.items,
        },
    );
}

export function useQuoteRequestLineItemUniqueOtsParts(quoteRequestId: string | undefined) {
    const { data } = useHttpQuery(
        'GET /quote-request/:id/line-items',
        {
            pathParams: {
                id: quoteRequestId ?? '',
            },
        },
        {
            select: (data) => data.items,
            enabled: isPresent(quoteRequestId),
        },
    );

    return uniq(data?.map((part) => resolveOtsPartsRecursively(part.requested_part)).flatMap((parts) => parts));
}

export function useQuoteRequestLineItems(negotiationLineItemIds: number[] | undefined) {
    return useHttpQuery(
        'POST /quote-request/line-items/find',
        {
            requestBody: {
                negotiation_line_item_ids: negotiationLineItemIds ?? [],
            },
        },
        {
            select: (data) => data.items,
            enabled: isPresent(negotiationLineItemIds),
        },
    );
}

export function useNegotiationAggregatedStatuses(negotiationId: number | undefined) {
    return useHttpQuery(
        'GET /negotiation/:id/aggregated-statuses',
        {
            pathParams: {
                id: negotiationId ?? NaN,
            },
        },
        {
            enabled: isPresent(negotiationId),
        },
    );
}

export function useDemands() {
    return useHttpQuery(
        'GET /demand',
        {},
        {
            select: (data) => Array.from(data).sort(compareByDate((n) => n.created_at)),
        },
    );
}

export function useDemandsInNegotiation(negotiationId: number | undefined) {
    return useHttpQuery(
        'GET /negotiation/:id/demands',
        {
            pathParams: {
                id: negotiationId ?? NaN,
            },
        },
        {
            enabled: isPresent(negotiationId),
        },
    );
}
