import { getToken, isAuthorized, usePermissions, useToken } from '@luminovo/auth';
import { Currency, getLocale, logToExternalErrorHandlers } from '@luminovo/commons';
import { GlobalCurrencySettingsDTO, http } from '@luminovo/http-client';
import { useMutation, useQueryClient, useSuspenseQuery } from '@tanstack/react-query';
import { httpQueryKey } from '../http/httpQueryKey';
import { useHttpQuery } from '../http/useHttpQuery';
const currencySettingsQueryKey = ['global-currency-settings'];

function getDefaultCurrencySettings(): GlobalCurrencySettingsDTO {
    switch (getLocale()) {
        case 'es':
        case 'fr':
        case 'de':
            return {
                currency: Currency.EUR,
                exchange_rates: {},
            };

        case 'en':
        default:
            return {
                currency: Currency.USD,
                exchange_rates: {},
            };
    }
}

export function useGlobalCurrencySettings() {
    const { permissions } = usePermissions();

    return useSuspenseQuery({
        // eslint-disable-next-line @tanstack/query/exhaustive-deps
        queryKey: httpQueryKey('GET /organization-settings/organization-currency-settings'),
        queryFn: async (): Promise<GlobalCurrencySettingsDTO> => {
            /**
             * As an optimization, if the user doesn't have permissions to view organization settings,
             * return the default currency config object.
             *
             * This way we don't have to hit the API if we know the user doesn't
             * have access to this resource.
             *
             * If the user does actually have this permission, but a 403 is returned
             * return the defaultCurrencySettings object anyway. This indicates
             * that the semantics of view:organization_settings might have changed
             */
            if (!isAuthorized(permissions, ['view:organization_settings'])) {
                logToExternalErrorHandlers(
                    new Error('User does not have permission to fetch the global currency settings'),
                    {
                        fingerprint: ['DEBUG_useGlobalCurrencySettings'],
                    },
                );
                return getDefaultCurrencySettings();
            }

            const result = await http('GET /organization-settings/organization-currency-settings', {}, getToken());
            return result.data;
        },
        staleTime: 1000 * 10 /* 10 seconds */,
    });
}

export function useGlobalCurrency() {
    const { data = getDefaultCurrencySettings(), isLoading } = useGlobalCurrencySettings();
    return { preferredCurrency: data.currency, isLoading };
}

export function usePublicExchangeRate(currency?: Currency) {
    return useHttpQuery('GET /public-exchange-rate', { queryParams: { currency: currency! } }, { enabled: !!currency });
}

export const useUpdateAndAddGlobalCurrencySettingsMutation = (
    setEditState: React.Dispatch<React.SetStateAction<boolean>>,
) => {
    const { token } = useToken();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (values: GlobalCurrencySettingsDTO) => {
            return http('PATCH /organization-settings/organization-currency-settings', { requestBody: values }, token);
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: currencySettingsQueryKey });
            setEditState(false);
        },
    });
};
