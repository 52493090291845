import { transEnum } from '@luminovo/commons';
import { Tag } from '@luminovo/design-system';
import { QuoteRequestLineItemAssignmentReason } from '@luminovo/http-client';
import { quoteRequestLineItemAssignmentReasonTranslations } from '../i18n';

export function QuoteRequestLineItemAssignmentReasonTag({
    assignmentReason,
}: {
    assignmentReason: QuoteRequestLineItemAssignmentReason;
}) {
    switch (assignmentReason) {
        case QuoteRequestLineItemAssignmentReason.ManuallyAdded:
            return (
                <Tag
                    attention="low"
                    color={'blue'}
                    label={transEnum(assignmentReason, quoteRequestLineItemAssignmentReasonTranslations)}
                />
            );
        case QuoteRequestLineItemAssignmentReason.ReferenceSupplier:
            return (
                <Tag
                    attention="low"
                    color={'green'}
                    label={transEnum(assignmentReason, quoteRequestLineItemAssignmentReasonTranslations)}
                />
            );
        case QuoteRequestLineItemAssignmentReason.LineCard:
            return (
                <Tag
                    attention="low"
                    color={'teal'}
                    label={transEnum(assignmentReason, quoteRequestLineItemAssignmentReasonTranslations)}
                />
            );
        case QuoteRequestLineItemAssignmentReason.LineCardRule:
            return (
                <Tag
                    attention="low"
                    color={'violet'}
                    label={transEnum(assignmentReason, quoteRequestLineItemAssignmentReasonTranslations)}
                />
            );
    }
}
