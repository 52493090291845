/* eslint-disable camelcase */
import { t, Trans } from '@lingui/macro';
import { formatDecimal, transEnum } from '@luminovo/commons';
import { CenteredLayout, colorSystem, FormItem, Link, Text, Thumbnail } from '@luminovo/design-system';
import {
    CustomFullPart,
    CustomPartTypeEnum,
    ExistingPanelDTO,
    FileManagementPreferenceDTO,
    PanelDTO,
    PCBV2,
} from '@luminovo/http-client';
import { ComplianceStatusTranslations } from '@luminovo/sourcing-core';
import { DescriptionOutlined, FolderZipOutlined } from '@mui/icons-material';
import { Box, CircularProgress, Skeleton } from '@mui/material';
import { PropertiesTable } from '../../../modules/Negotiations/components/PropertiesTable';
import {
    useDownloadPCBFiles,
    useExportPanelSpecificationInPDF,
    useExportPcbSpecificationInPDF,
} from '../../../resources/export/exportHandler';
import { useHttpQuery, useSuspenseHttpQuery } from '../../../resources/http/useHttpQuery';
import { useCustomPart, useCustomPartResources } from '../../../resources/part/partHandler';
import { layerStackTypeTranslations } from '../../../resources/pcb/i18n';
import { usePcbValues } from '../../../resources/pcb/pcbHandlers';
import { getFileNameFromResourcesString } from '../../../utils/stringFunctions';

export function PcbDrawer({
    BackButton,
    EditButton,
    partId,
}: {
    BackButton: React.ComponentType;
    EditButton: React.ComponentType<{ customPartId: string }>;
    partId: string;
}) {
    const { data: part } = useCustomPart(partId);

    const pcbId = (part?.type.name === CustomPartTypeEnum.PCB && part.type?.content) || undefined;

    const { frontPreviewUrl, rearPreviewUrl, width, height, numLayers, layerStackType, pcb } = usePcbValues(pcbId);

    const { data: settings } = useSuspenseHttpQuery('GET /organization-settings/supplier-portal-settings', {});

    if (!part || !pcb) {
        return (
            <CenteredLayout sx={{ minHeight: '400px' }}>
                <CircularProgress />
            </CenteredLayout>
        );
    }

    return (
        <Box
            sx={{
                boxSizing: 'border-box',
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                padding: 2,
            }}
        >
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box sx={{ marginLeft: -2 }}>
                    <BackButton />
                </Box>
                <EditButton customPartId={part.id} />
            </Box>
            <FormItem label={t`PCB`}>
                <Text>{part.description}</Text>
            </FormItem>
            <FormItem label={t`Specification`}>
                <PropertiesTable
                    variant="side-by-side"
                    properties={{
                        [t`Width`]: formatDecimal(width, { ifAbsent: '-', suffix: ' mm' }),
                        [t`Height`]: formatDecimal(height, { ifAbsent: '-', suffix: ' mm' }),
                        [t`Number of layers`]: formatDecimal(numLayers, { ifAbsent: '-' }),
                        [t`Layer stack type`]: layerStackType
                            ? transEnum(layerStackType, layerStackTypeTranslations)
                            : '-',
                    }}
                />
            </FormItem>

            <FormItem label={t`Attachments`}>
                <DownloadPcbDocuments
                    pcb={pcb}
                    fileManagementPreference={{
                        should_include_pcb_specification: settings.should_include_pcb_specification ?? true,
                        should_include_shipping_panel_specification:
                            settings.should_include_shipping_panel_specification ?? true,
                    }}
                />

                <CustomPartAttachments part={part} />
            </FormItem>

            <FormItem label={t`Compliance`}>
                <PropertiesTable
                    variant="side-by-side"
                    properties={{
                        [t`RoHS compliance`]: transEnum(part.rohs_compliant, ComplianceStatusTranslations),
                        [t`REACH compliance`]: transEnum(part.reach_compliant, ComplianceStatusTranslations),
                    }}
                />
            </FormItem>

            <FormItem label={t`Preview`}>
                <Thumbnail
                    src={frontPreviewUrl.toString()}
                    width={'100%'}
                    height={'80px'}
                    borderRadius={'8px'}
                    ImageProps={{
                        style: {
                            borderRadius: '4px',
                        },
                    }}
                    style={{
                        padding: 4,
                        borderRadius: 8,
                        boxSizing: 'border-box',
                        border: `1px solid ${colorSystem.neutral[3]}`,
                        background: colorSystem.neutral[1],
                    }}
                />
                <Thumbnail
                    src={rearPreviewUrl.toString()}
                    width={'100%'}
                    height={'80px'}
                    borderRadius={'8px'}
                    ImageProps={{
                        style: {
                            borderRadius: '4px',
                        },
                    }}
                    style={{
                        padding: 4,
                        borderRadius: 8,
                        boxSizing: 'border-box',
                        border: `1px solid ${colorSystem.neutral[3]}`,
                        background: colorSystem.neutral[1],
                    }}
                />
            </FormItem>
            <div style={{ height: '100px' }} />
        </Box>
    );
}

function CustomPartAttachments({ part }: { part: CustomFullPart }) {
    const { data: resources = [], isLoading: isLoadingResources } = useCustomPartResources(part.id);

    if (isLoadingResources) {
        return (
            <>
                <Skeleton />
                <Skeleton />
                <Skeleton />
            </>
        );
    }

    return (
        <>
            {resources.map((resource, index) => {
                return (
                    <Link
                        href={resource}
                        download={resource}
                        key={index}
                        attention="high"
                        startIcon={<DescriptionOutlined />}
                    >
                        {getFileNameFromResourcesString(resource)}
                    </Link>
                );
            })}
        </>
    );
}

function DownloadPcbDocuments({
    pcb,
    fileManagementPreference,
}: {
    pcb: PCBV2;
    fileManagementPreference: FileManagementPreferenceDTO;
}) {
    const { data: panels } = useHttpQuery('GET /panels', {
        queryParams: {
            pcb_id: pcb.id,
        },
    });
    const { downloadPCBFiles } = useDownloadPCBFiles({
        pcbId: pcb.id,
    });

    const { mutateAsync: downloadPcbSpecification } = useExportPcbSpecificationInPDF(pcb);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Link startIcon={<FolderZipOutlined style={{ fontSize: '16px' }} />} onClick={() => downloadPCBFiles()}>
                <Trans>PCB files</Trans>
            </Link>
            {fileManagementPreference.should_include_pcb_specification && (
                <Link
                    startIcon={<DescriptionOutlined style={{ fontSize: '16px' }} />}
                    onClick={() => downloadPcbSpecification()}
                >
                    <Trans>PCB_specification.pdf</Trans>
                </Link>
            )}
            {fileManagementPreference.should_include_shipping_panel_specification && panels && (
                <>
                    {panels.map((panel, index) => (
                        <PanelSpecificationLink
                            key={isExistingPanel(panel) ? panel.data.id : panel.data.panel_details.id}
                            pcb={pcb}
                            panel={panel}
                            index={index + 1}
                        />
                    ))}
                </>
            )}
        </Box>
    );
}

export const isExistingPanel = (panelData: PanelDTO): panelData is ExistingPanelDTO => {
    return panelData.type === 'Existing';
};

const PanelSpecificationLink = ({ pcb, panel, index }: { pcb: PCBV2; panel: PanelDTO; index: number }) => {
    const { mutateAsync: downloadPanelSpecification } = useExportPanelSpecificationInPDF({
        pcbId: pcb.id,
        panelId: isExistingPanel(panel) ? panel.data.id : panel.data.panel_details.id,
        fileName: `panel-${index}`,
    });

    return (
        <Link
            startIcon={<DescriptionOutlined style={{ fontSize: '16px' }} />}
            onClick={() => downloadPanelSpecification()}
        >
            <Trans>Panel_specification_{index}.pdf</Trans>
        </Link>
    );
};
