import { Trans } from '@lingui/macro';
import { PrimaryButton } from '@luminovo/design-system';
import { CustomerPortalState } from '@luminovo/http-client';
import React from 'react';
import { useHistory } from 'react-router';
import { useHttpQuery } from '../../resources/http/useHttpQuery';
import { useMutationRequestQuotation, useRfQ } from '../../resources/rfq/rfqHandler';
import { id } from '../../utils/ids';
import { useIsRfqEditable } from '../../utils/rfqUtils';
import { route } from '../../utils/routes';
import {
    isEveryCustomerPortalStepDone,
    isEveryCustomerPortalStepDoneAndNoPcbPriceAvailable,
} from './CustomerDashboard/util/DashboardSteps';

export function ButtonRequestQuotation({
    rfqId,
    customerPortalState,
    overrides,
}: {
    rfqId: string;
    customerPortalState: CustomerPortalState;
    overrides?: { Button: React.ComponentType };
}): JSX.Element {
    const history = useHistory();
    const { Button } = overrides ?? { Button: PrimaryButton };
    const { data: rfq } = useRfQ(rfqId);
    const status = rfq?.status;
    const { isRfqEditable } = useIsRfqEditable(status, rfq?.is_archived, rfq?.workflow_type);
    const { mutateAsync: requestQuotation, isPending: isLoadingRequestQuotation } = useMutationRequestQuotation(rfqId);

    const canRequestQuotation = useCanRequestQuotation(rfqId, customerPortalState);

    if (!isRfqEditable || !rfq || !canRequestQuotation) {
        return <></>;
    }

    return (
        <Button
            id={id('oem_dashboard/button_request_quotation')}
            disabled={isLoadingRequestQuotation}
            onClick={async () => {
                await requestQuotation({ pathParams: { rfqId } });
                history.push(route('/rfqs/:rfqId/dashboard', { rfqId }));
            }}
        >
            <Trans>Request quotation</Trans>
        </Button>
    );
}

const useCanRequestQuotation = (rfqId: string, state: CustomerPortalState): boolean => {
    const { data: rfq, isLoading } = useHttpQuery('GET /rfqs/:rfqId', { pathParams: { rfqId } });
    if (!rfq || isLoading) {
        return false;
    }

    /* eslint-disable-next-line spellcheck/spell-checker */
    const isWorkflowTypeManualOrDontShow = rfq.data.workflow_type === 'Manual' || rfq.data.workflow_type === 'DontShow';
    const areAllStepsDone = isEveryCustomerPortalStepDone(state);

    const isAutomaticWorkflow = rfq.data.workflow_type === 'Automatic';
    const hasNoPcbOffer = isEveryCustomerPortalStepDoneAndNoPcbPriceAvailable(state);

    if ((isWorkflowTypeManualOrDontShow && areAllStepsDone) || (isAutomaticWorkflow && hasNoPcbOffer)) {
        return true;
    }

    return false;
};
