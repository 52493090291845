import { t } from '@lingui/macro';
import { ApprovalSwitchableChip } from '@luminovo/manufacturing-core';
import { useHttpMutation } from '../../../resources/mutation/useHttpMutation';

export type CalculationStatus =
    | {
          type: 'Frozen';
          frozenId: string;
      }
    | {
          type: 'Calculated';
          calculationAssemblyCostId: string;
      };

export const CalculationApprovalSwitchableChip = ({
    calculationStatus,
}: {
    calculationStatus: CalculationStatus;
}): JSX.Element => {
    const { mutateAsync: freezeCalculationMutation, isPending: isLoading } = useHttpMutation(
        'POST /calculations/frozen-scenario-cost',
        {
            snackbarMessage: t`Calculation frozen`,
        },
    );

    const { mutateAsync: unfreezeCalculationMutation, isPending: unfreezeIsLoading } = useHttpMutation(
        'DELETE /calculations/frozen-scenario-cost/:frozenId',
        {
            snackbarMessage: t`Calculation unfrozen`,
        },
    );

    const handleClick = () => {
        if (calculationStatus.type === 'Frozen') {
            unfreezeCalculationMutation({
                pathParams: {
                    frozenId: calculationStatus.frozenId,
                },
            });
        } else {
            freezeCalculationMutation({
                queryParams: {
                    // eslint-disable-next-line camelcase
                    calculation_assembly_cost_id: calculationStatus.calculationAssemblyCostId,
                },
            });
        }
    };

    return (
        <ApprovalSwitchableChip
            handleClick={handleClick}
            isChecked={calculationStatus.type === 'Frozen'}
            isLoading={isLoading || unfreezeIsLoading}
        />
    );
};
