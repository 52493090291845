import { Card, Flexbox, Tag, colorSystem } from '@luminovo/design-system';
import {
    AutomaticDetailsDTO,
    DriverOrigin,
    DriverStatusDTO,
    ManufacturingAssemblyDetailsDTO,
} from '@luminovo/http-client';
import { DriverTypeTag } from '@luminovo/manufacturing-core';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { themeLuminovo } from '../../../../../themes';
import { CalculationTextWrapper } from '../../../DriversView';
import { DriverNotes } from '../../../shared/ManufacturingSharedComponents';
import { DriverStatusInformationCard } from '../../DriverStatusInformationCard';

interface ManualDriverInformation {
    driverType: 'User+Manual';
    automaticDetails: undefined;
    driverName: string;
    driverOrigin?: DriverOrigin[];
    notes: string | undefined;
}

interface AutomaticDriverInformation {
    driverType: 'User+Automatic';
    automaticDetails: AutomaticDetailsDTO;
    driverName: string;
    driverOrigin?: DriverOrigin[];
    notes: string | undefined;
}

interface SystemDriverInformation {
    driverType: 'System+Automatic';
    automaticDetails: undefined;
    driverName: string;
    driverOrigin?: DriverOrigin[];
    notes: string | undefined;
}

export type DriverInformation = AutomaticDriverInformation | ManualDriverInformation | SystemDriverInformation;

function tagLabel(driverOrigin: DriverOrigin) {
    if (driverOrigin === DriverOrigin.Bom) {
        return driverOrigin.toUpperCase();
    } else {
        return driverOrigin;
    }
}

export const CardHeader = ({ driverInformation }: { driverInformation: DriverInformation }): JSX.Element => {
    const { notes, driverType, driverName, automaticDetails, driverOrigin } = driverInformation;
    /* eslint-disable spellcheck/spell-checker*/
    const isXlScreen: boolean = useMediaQuery(themeLuminovo.breakpoints.up('xl'));

    return (
        <Flexbox flexDirection={'column'} gap={'16px'}>
            <Flexbox gap={'16px'}>
                <DriverTypeTag driverType={driverType} variant="large" />
                <Flexbox
                    paddingTop={'4px'}
                    paddingBottom={'8px'}
                    flexDirection={'column'}
                    justifyContent={'space-between'}
                >
                    <Typography style={{ color: colorSystem.neutral[8] }} variant={'h2'}>
                        {driverName}
                    </Typography>
                    <Box maxWidth={isXlScreen ? '1000px' : '700px'}>
                        <CalculationTextWrapper automaticDetails={automaticDetails} />
                    </Box>
                </Flexbox>
                <Flexbox gap={'12px'} margin={'4px 8px 0px auto'} alignSelf="flex-start">
                    {driverOrigin?.map((driverOrigin) => (
                        <Tag color="neutral" key={`${driverName}-${driverOrigin}-tag`} label={tagLabel(driverOrigin)} />
                    ))}
                </Flexbox>
            </Flexbox>
            {notes && (
                <Flexbox gap={'16px'}>
                    <DriverNotes notes={notes} />
                </Flexbox>
            )}
        </Flexbox>
    );
};

export const DriverInformationCard = ({
    driverStatus,
    driverInformation,
    rfqId,
    manufacturingAssemblyDetails,
}: {
    driverStatus: DriverStatusDTO;
    driverInformation: DriverInformation;
    rfqId: string;
    manufacturingAssemblyDetails: ManufacturingAssemblyDetailsDTO;
}): JSX.Element => {
    return (
        <Card maxWidth="100%" overflow={'auto'} gap={'24px'} minHeight={'388px'}>
            <CardHeader driverInformation={driverInformation} />
            {driverStatus.type !== 'Ok' && (
                <DriverStatusInformationCard
                    status={driverStatus}
                    rfqId={rfqId}
                    manufacturingAssemblyDetails={manufacturingAssemblyDetails}
                />
            )}
        </Card>
    );
};
