import { t, Trans } from '@lingui/macro';
import { Checkbox, Chip, colorSystem, Text, TextField } from '@luminovo/design-system';
import { Cancel } from '@mui/icons-material';
import { FormControl, FormControlLabel, FormHelperText, styled, Tooltip } from '@mui/material';
import Autocomplete, { AutocompleteChangeDetails, AutocompleteChangeReason } from '@mui/material/Autocomplete';
import React from 'react';
import ConfirmationDialogBox from '../../../../components/dialogBox/ConfirmationDialogBox';
import { useIpnTooltip } from '../../../../components/partSpecificationCards/Ipn/IpnCardFunctions';
import { getTooltipForAcronym } from '../../../../utils/getTooltip';
import { getSelectedOptions, isTagAssignedToColumn, isTagSelectedByOtherColumn } from './reducer';
import { Column, ColumnTag, ColumnTagsSheetsAction, ColumnTagsSheetsState } from './types';

function getTooltip(text: string) {
    if (text === 'Tech. Parameters') {
        return t`Tag all columns with technical parameters for generic resistors or capacitors (e.g. 10Ω, 5%, 50V, 0603).`;
    }

    return getTooltipForAcronym(text);
}

function columnIdToText(id: number) {
    if (id === 0) {
        return t`first`;
    }
    if (id === 1) {
        return t`second`;
    }
    if (id === 2) {
        return t`third`;
    }
    if (id === 4) {
        return t`fourth`;
    }
    return t`${id + 1}th`;
}

function Option({ option, column }: { option: ColumnTag; column: Column }) {
    const associatedColumn = isTagSelectedByOtherColumn(option, column.id);
    const isDisabled = !!associatedColumn;
    const isSelected = isTagAssignedToColumn(option, column.id);
    const ipnTooltip = useIpnTooltip();
    const tooltip = option.label === 'IPN' ? ipnTooltip : getTooltip(option.label);

    return (
        <FormControl size="small">
            <Tooltip title={tooltip}>
                <FormControlLabel
                    control={<Checkbox size="small" checked={isSelected} style={{ margin: '0px 8px 0px 4px' }} />}
                    label={<Text>{option.label}</Text>}
                />
            </Tooltip>
            {isDisabled && (
                <FormHelperText>
                    <Trans>Assigned to the {columnIdToText(Number(associatedColumn?.id))} column</Trans>
                </FormHelperText>
            )}
        </FormControl>
    );
}

// eslint-disable-next-line spellcheck/spell-checker
const StyledTextField = styled(TextField)({
    '& .MuiInput-underline:before': {
        border: 'none !important',
    },
    '& .MuiInput-underline:hover:before': {
        border: 'none !important',
    },
    '& .MuiAutocomplete-endAdornment': {
        opacity: 1,
        margin: 0,
        padding: 0,
        top: '6px',
    },
    '& .MuiAutocomplete-popupIndicator': {
        padding: '0px',
    },
    '& .MuiAutocomplete-popupIndicator:hover': {
        background: 'unset',
    },
    '&:hover .MuiAutocomplete-endAdornment': {
        opacity: 1,
    },
    '& .MuiAutocomplete-tagSizeSmall': {
        marginLeft: 0,
    },
    fontSize: '0.75rem',
});
/* eslint-enable spellcheck/spell-checker */

export function AutocompleteColumnTags({
    column,
    state,
    dispatch,
    haveBomImporterLinesChanged,
    setHaveBomImporterLinesChanged,
}: {
    column: Column;
    state: ColumnTagsSheetsState;
    dispatch: React.Dispatch<ColumnTagsSheetsAction>;
    haveBomImporterLinesChanged?: boolean;
    setHaveBomImporterLinesChanged?: React.Dispatch<React.SetStateAction<boolean>>;
}) {
    const [isDialogOpen, setIsDialogOpen] = React.useState<{ isOpen: false } | { isOpen: true; onConfirm: () => void }>(
        { isOpen: false },
    );
    const selectedOptions = getSelectedOptions(state, column.id);

    const closeDialog = () => {
        setIsDialogOpen({ isOpen: false });
    };

    const ipnTooltip = useIpnTooltip();

    const handleChange = React.useCallback(
        (
            e,
            value: ColumnTag[],
            reason: AutocompleteChangeReason,
            details: AutocompleteChangeDetails<ColumnTag> | undefined,
        ) => {
            const handle = () => {
                if (reason === 'selectOption' && details?.option) {
                    dispatch({ type: 'assign-tag', tag: details.option, column });
                }
                if (reason === 'removeOption' && details?.option) {
                    dispatch({ type: 'remove-tag', tag: details.option, column });
                }
            };
            if (haveBomImporterLinesChanged) {
                setIsDialogOpen({
                    isOpen: true,
                    onConfirm: () => {
                        handle();
                        setHaveBomImporterLinesChanged && setHaveBomImporterLinesChanged(false);
                    },
                });
            } else {
                handle();
            }
        },
        [haveBomImporterLinesChanged, dispatch, column, setHaveBomImporterLinesChanged],
    );
    return (
        <>
            <Autocomplete
                size="small"
                style={{ height: 'fit-content' }}
                multiple
                disableClearable
                value={selectedOptions}
                onChange={handleChange}
                options={state.sheets[state.selectedSheetIndex].tags}
                disabledItemsFocusable={false}
                disableCloseOnSelect
                getOptionDisabled={(opt) => !!isTagSelectedByOtherColumn(opt, column.id)}
                isOptionEqualToValue={(left, right) => left.label === right.label}
                getOptionLabel={(option) => option.label}
                renderOption={(props, option) => (
                    <li {...props}>
                        <Option column={column} option={option} />
                    </li>
                )}
                renderInput={(params) => (
                    <StyledTextField
                        {...params}
                        size="small"
                        variant="standard"
                        placeholder={selectedOptions.length === 0 ? t`Click to add tags` : ''}
                    />
                )}
                renderTags={(value, getTagProps) => {
                    return value.map((option, index) => {
                        const tooltip = option.label === 'IPN' ? ipnTooltip : getTooltipForAcronym(option.label);
                        return (
                            <Tooltip title={tooltip} key={index}>
                                <Chip
                                    {...getTagProps({ index })}
                                    label={
                                        <Text variant="body-small" style={{ whiteSpace: 'normal' }}>
                                            {option.label}
                                        </Text>
                                    }
                                    color="primary"
                                    deleteIcon={<Cancel style={{ color: colorSystem.primary[7] }} />}
                                />
                            </Tooltip>
                        );
                    });
                }}
            />
            <ConfirmationDialogBox
                title={t`Make changes`}
                text={t`Are you sure you want to make these changes? Your progress on the next step will be overridden.`}
                isDialogOpen={isDialogOpen.isOpen}
                onReject={closeDialog}
                onConfirm={isDialogOpen.isOpen ? isDialogOpen.onConfirm : () => {}}
                deleteText={t`Continue`}
            />
        </>
    );
}
