import { t } from '@lingui/macro';
import { isPresent } from '@luminovo/commons';
import { CenteredLayout } from '@luminovo/design-system';
import { CustomLinkedPartDTO, PriceType, QuantityUnit, RfqContext } from '@luminovo/http-client';
import { CircularProgress } from '@mui/material';
import { useHistory } from 'react-router';
import { useAttachmentFileMutation, useHttpFileUpload } from '../../../resources/http/useHttpFileUpload';
import { useHttpMutation } from '../../../resources/mutation/useHttpMutation';
import { useGlobalCurrency } from '../../../resources/organizationSettings/currencySettingsHandler';
import { useNonExcludedSupplierAndStockLocations } from '../../../resources/supplierAndStockLocation/supplierAndStockLocationHandler';
import { CustomPartOfferForm, CustomPartOfferFormValues } from './CustomPartOfferForm';
import { convertToCustomPartOfferInputDTO } from './converters';

export function AddCustomPartOfferForm({
    rfqId,
    linkedPart,
}: {
    rfqId: string;
    linkedPart: CustomLinkedPartDTO;
}): JSX.Element {
    const rfqContext: RfqContext = { type: 'WithinRfQ', rfq_id: rfqId };
    const { preferredCurrency } = useGlobalCurrency();
    const history = useHistory();
    const { data: supplierAndStockLocations } = useNonExcludedSupplierAndStockLocations();

    const { mutateAsync: mutateAsyncCustomPartOffer } = useHttpMutation('POST /offers/custom-part', {
        snackbarMessage: null,
    });

    const { mutateAsync: mutateUpload } = useHttpFileUpload(
        `GET /offers/custom-part/:id/additional-files/upload-link`,
        (response) => response.data.url,
        { snackbarMessage: null },
    );

    const { mutateAsync: mutateAttachmentFile } = useAttachmentFileMutation<{ offerId: string }>({
        mutateUpload: (file, { offerId }) => mutateUpload({ pathParams: { id: offerId }, files: file.file }),
        onSuccess: () => history.goBack(),
        snackbarMessage: t`Manual offer successfully created`,
    });

    if (!isPresent(supplierAndStockLocations)) {
        return (
            <CenteredLayout height={'30vh'}>
                <CircularProgress />
            </CenteredLayout>
        );
    }

    const onSubmitCallback = async (values: CustomPartOfferFormValues) => {
        const requestBody = convertToCustomPartOfferInputDTO(values);
        const { data: offer } = await mutateAsyncCustomPartOffer({ requestBody });
        await mutateAttachmentFile({ context: { offerId: offer.id }, files: values.files });
    };

    const defaultSupplierAndStockLocation = supplierAndStockLocations[0];

    const defaultValues: CustomPartOfferFormValues = {
        linkedPart,
        priceType: PriceType.QuotePrice,
        supplierAndStockLocation: defaultSupplierAndStockLocation,
        currency: preferredCurrency,
        quantityUnit: {
            unit: QuantityUnit.Pieces,
            quantity: 1,
        },
        pricePoints: [
            {
                quantity: 1,
                amount: '0',
                lead_time_days: undefined,
            },
        ],
        validUntilDate: undefined,
        oneTimeCosts: [],
        notes: undefined,
        sourcingBatchSize: undefined,
        files: [],
    };

    return <CustomPartOfferForm rfqContext={rfqContext} onSubmit={onSubmitCallback} defaultValues={defaultValues} />;
}
