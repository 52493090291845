/* eslint-disable no-console */
import { Trans } from '@lingui/macro';
import { formatMonetaryValue, id } from '@luminovo/commons';
import { Flexbox, PrimaryButton, Text } from '@luminovo/design-system';
import { ChargeImmediatelyResponseDTO } from '@luminovo/http-client';
import { CircularProgress } from '@mui/material';
import { AddressElement, PaymentElement } from '@stripe/react-stripe-js';
import React from 'react';
import { useOrderSelectionOptionsOfRfq } from '../utils/useOrderSelectionOptionsOfRfq';
import { useStripeCheckoutFunction } from '../utils/useStripeCheckoutFunction';

export const OrderCheckoutForm = ({
    orderDetails,
    rfqId,
}: {
    orderDetails: ChargeImmediatelyResponseDTO;
    rfqId: string;
}) => {
    const { mutateAsync, isPending: isLoading } = useStripeCheckoutFunction(orderDetails);
    const { orderSelectionOptions, isExpired } = useOrderSelectionOptionsOfRfq(rfqId);
    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        await mutateAsync();
    };

    if (!orderSelectionOptions) {
        return <CircularProgress />;
    }

    const selectedPriceToPay =
        orderSelectionOptions.data[orderDetails.order_selection_option_id]?.price.data.total_price_before_tax
            .total_cost;

    return (
        <form onSubmit={handleSubmit}>
            <Flexbox marginBottom={'12px'}>
                <Text variant="h3">
                    <Trans>Billing information</Trans>
                </Text>
            </Flexbox>
            <PaymentElement id={id('checkout/e2e_stripe_payment_element')} />
            <Flexbox marginY={'12px'}>
                <Text variant="h3">
                    <Trans>Shipping information</Trans>
                </Text>
            </Flexbox>
            <AddressElement options={{ mode: 'shipping' }} id={id('checkout/e2e_stripe_address_element')} />
            <Flexbox justifyContent={'end'} style={{ marginBlock: '24px' }} alignItems={'center'} gap={'12px'}>
                <Text variant={'body-semibold'}>
                    {orderDetails.tax
                        ? `Paying ${formatMonetaryValue(selectedPriceToPay)} (cost) + ${formatMonetaryValue(
                              orderDetails.tax,
                          )} (tax)`
                        : `Paying ${formatMonetaryValue(selectedPriceToPay)}`}
                </Text>
                <PrimaryButton type="submit" isLoading={isLoading} disabled={isExpired}>
                    <Trans>Pay now</Trans>
                </PrimaryButton>
            </Flexbox>
        </form>
    );
};
