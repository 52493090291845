import { Plural, t, Trans } from '@lingui/macro';
import { formatDays, formatDecimal, isEqual, MonetaryValue } from '@luminovo/commons';
import { colorSystem, Flexbox, Text } from '@luminovo/design-system';
import {
    AvailabilityType,
    CustomOptionOfferDTO,
    PricePointsDTO,
    SolutionConfigurationDTO,
} from '@luminovo/http-client';
import { Box, styled, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import React from 'react';
import { Solution } from '../../../../types';

import { formatMonetaryValue, isPresent } from '@luminovo/commons';
import { InfoRounded } from '@mui/icons-material';
import { UnitOfMeasurementMismatchInfo } from './UnitOfMeasurementMismatchInfo';

const StyledTableCell = styled(TableCell)({
    paddingTop: 4,
    paddingBottom: 4,
    height: '100%',
    borderBottom: 'none',
    verticalAlign: 'top',
});

const PricingInfos: React.FunctionComponent<{
    offer: CustomOptionOfferDTO;
    solution?: Solution;
    showPanelPrice?: boolean;
}> = ({ solution, offer, showPanelPrice = false }): JSX.Element => {
    const pricePoints = offer.price_points;
    const sortedPricePoints = pricePoints.sort((a, b) => a.quantity - b.quantity);
    const signature = solution?.firstPurchaseOption.signature;

    const shippingTimeInDays = solution?.availability?.shipping_time_in_days;

    const isHighlighted = (pricePoint: PricePointsDTO, idx: number) => {
        if (!isPresent(solution)) {
            return idx === sortedPricePoints.length - 1;
        }

        if (isPresent(signature) && 'quantity' in signature && 'availability' in signature) {
            return (
                pricePoint.quantity === signature.quantity && isEqual(pricePoint.availability, signature.availability)
            );
        }

        return false;
    };

    return (
        <Flexbox flexDirection={'column'} gap={16}>
            <Text variant="h4" color={colorSystem.neutral[8]}>
                <Trans>Pricing</Trans>
            </Text>
            {shippingTimeInDays && (
                <Text variant="body-small" color={colorSystem.neutral[7]}>
                    <Flexbox alignItems={'center'} gap={'4px'}>
                        <InfoRounded fontSize="inherit" />
                        <Trans>This supplier has a shipping time of </Trans>{' '}
                        <Plural value={shippingTimeInDays} one="# day" other="# days" />
                    </Flexbox>
                </Text>
            )}

            <Box style={{ border: `1px solid ${colorSystem.neutral[2]}`, borderRadius: '8px' }}>
                <Table>
                    <TableHead style={{ borderBottom: `1px solid ${colorSystem.neutral[2]}` }}>
                        <TableRow>
                            <StyledTableCell>
                                <Text variant="body-small-semibold" color={colorSystem.neutral[8]}>
                                    <Trans>Quantity</Trans>
                                </Text>
                            </StyledTableCell>
                            {showPanelPrice && (
                                <StyledTableCell>
                                    <Text variant="body-small-semibold" color={colorSystem.neutral[8]}>
                                        <Trans>Panel price</Trans>
                                    </Text>
                                </StyledTableCell>
                            )}
                            <StyledTableCell>
                                <Text variant="body-small-semibold" color={colorSystem.neutral[8]}>
                                    <Trans>Unit price</Trans>
                                </Text>
                            </StyledTableCell>
                            <StyledTableCell>
                                <Text variant="body-small-semibold" color={colorSystem.neutral[8]}>
                                    <Trans>Lead time</Trans>
                                </Text>
                            </StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedPricePoints.map((pricePoint, idx) => {
                            const color = isHighlighted(pricePoint, idx)
                                ? colorSystem.primary[7]
                                : colorSystem.neutral[9];
                            const adjustedUnitPrice = calculateAdjustedUnitPrice({ solution, pricePoint });

                            return (
                                <TableRow key={idx}>
                                    <StyledTableCell>
                                        <Text variant="body-small" color={color}>
                                            {formatDecimal(pricePoint.quantity)}
                                        </Text>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <Text variant="body-small" color={color}>
                                            {formatMonetaryValue(pricePoint.unit_price, 'unit-price')}
                                        </Text>
                                    </StyledTableCell>
                                    {showPanelPrice && (
                                        <StyledTableCell>
                                            <Text variant="body-small" color={color}>
                                                {formatMonetaryValue(adjustedUnitPrice, 'unit-price')}
                                            </Text>
                                        </StyledTableCell>
                                    )}
                                    <StyledTableCell>
                                        <Text variant="body-small" color={color}>
                                            {pricePoint.availability.type === AvailabilityType.LeadTime ? (
                                                <>
                                                    {shippingTimeInDays ? (
                                                        <>
                                                            {pricePoint.availability.days}
                                                            {' + '}
                                                            {formatDays(shippingTimeInDays)}
                                                        </>
                                                    ) : (
                                                        formatDays(pricePoint.availability.days)
                                                    )}
                                                </>
                                            ) : (
                                                <Text variant="body-small" color={color}>{t`In stock`}</Text>
                                            )}
                                        </Text>
                                    </StyledTableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </Box>
        </Flexbox>
    );
};

const calculateAdjustedUnitPrice = ({
    solution,
    pricePoint,
}: {
    solution?: Solution;
    pricePoint: PricePointsDTO;
}): MonetaryValue => {
    const adjustedUnitPrice = Number(pricePoint.unit_price.amount) / (solution?.firstPurchaseOption.unit.quantity ?? 0);
    return { amount: adjustedUnitPrice.toString(), currency: pricePoint.unit_price.currency };
};

export const CustomPartOfferInfos: React.FunctionComponent<{
    offer: CustomOptionOfferDTO;
    solution?: Solution;
    solutionConfiguration?: SolutionConfigurationDTO;
    isPcbCustomPartOffer?: boolean;
}> = ({ offer, solution, solutionConfiguration, isPcbCustomPartOffer }): JSX.Element => {
    return (
        <Flexbox flexDirection={'column'} gap={24} paddingX={'16px'} paddingY={'24px'}>
            <PricingInfos offer={offer} solution={solution} showPanelPrice={isPcbCustomPartOffer} />
            <UnitOfMeasurementMismatchInfo offer={offer} solutionConfiguration={solutionConfiguration} />
        </Flexbox>
    );
};
