import { t } from '@lingui/macro';
import { formatRelativeTime, isPresent } from '@luminovo/commons';
import { colorSystem } from '@luminovo/design-system';
import { QuantityUnitDTO } from '@luminovo/http-client';
import { Skeleton, Tooltip, Typography } from '@mui/material';
import { formatQuantity } from '../utils/formatQuantity';
import { useWarnIfSuppliersNotConfigured } from '../utils/useWarnIfSuppliersNotConfigured';

function formatStock({ quantity, isLoading = false }: { quantity?: QuantityUnitDTO | null; isLoading?: boolean }) {
    if (!isPresent(quantity) && isLoading) {
        return <Skeleton width={'40px'} />;
    }

    if (!isPresent(quantity)) {
        return t`No offers`;
    }

    if (quantity.quantity === 0) {
        return 0;
    }

    return formatQuantity({
        quantity: quantity.quantity,
        unit: quantity.unit,
    });
}

function getTextColor(quantity?: number): string | undefined {
    if (!isPresent(quantity)) {
        return;
    }
    if (quantity === 0) {
        return colorSystem.red[6];
    }
    if (quantity > 10_000) {
        return colorSystem.green['7'];
    }

    return colorSystem.neutral[9];
}

function getTooltip({
    lastUpdatedAt,
    quantity,
    isLoading,
}: {
    lastUpdatedAt?: string | null;
    quantity?: QuantityUnitDTO | null;
    isLoading?: boolean;
}) {
    if (!isLoading && !isPresent(quantity)) {
        return t`No offers found`;
    }
    if (lastUpdatedAt) {
        return t`Last updated ${formatRelativeTime(lastUpdatedAt)}`;
    }
    return ''; // the empty tooltip is never shown
}

interface StockViewProps {
    lastUpdatedAt?: string | null;
    quantity?: QuantityUnitDTO | null;
    isLoading?: boolean;
}

/**
 * Renders a color-coded indicator of available stock quantity
 */
export function StockView({ lastUpdatedAt, quantity, isLoading }: StockViewProps) {
    const color = getTextColor(quantity?.quantity);

    useWarnIfSuppliersNotConfigured();

    return (
        <Tooltip title={getTooltip({ lastUpdatedAt, quantity, isLoading })}>
            <Typography
                component="div"
                variant="body1"
                style={{ color: color, cursor: isLoading ? 'progress' : undefined }}
            >
                {formatStock({ quantity, isLoading })}
            </Typography>
        </Tooltip>
    );
}
