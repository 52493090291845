export enum LeadTimeUnit {
    Days = 'days',
    Weeks = 'weeks',
    Months = 'months',
}

export function convertLeadTimeUnitToDays(unit: LeadTimeUnit): number {
    switch (unit) {
        case LeadTimeUnit.Days:
            return 1;
        case LeadTimeUnit.Weeks:
            return 7;
        case LeadTimeUnit.Months:
            return 31;
    }
}
