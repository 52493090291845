/* eslint-disable camelcase */
import { useAuth0 } from '@auth0/auth0-react';
import { Trans } from '@lingui/macro';
import { compareByString, id, isPresent, isProductionEnvironment } from '@luminovo/commons';
import { AvatarIcon, Flexbox, Text, Tooltip, colorSystem } from '@luminovo/design-system';
import { UserType } from '@luminovo/http-client';
import {
    Description,
    ExitToAppRounded,
    Fingerprint,
    Help,
    LogoutRounded,
    PrivacyTipRounded,
    Settings,
    ToggleOn,
} from '@mui/icons-material';
import { ListItem } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useQueryClient } from '@tanstack/react-query';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { ORIGIN, RoutesHistoryStateProps } from '../../const';
import { useDevFeatureFlags } from '../../devFeatureFlags';
import { Protected } from '../../permissions/Protected';
import { useToken } from '../../resources/hooks';
import { useSuspenseHttpQuery } from '../../resources/http/useHttpQuery';
import { useHttpMutation } from '../../resources/mutation/useHttpMutation';
import { SPACING_SMALL } from '../../themes';
import { analytics } from '../../utils/analytics';
import { showCookieConsentSettings } from '../../utils/analytics/cookieConsent';
import { getIconForLanguage } from '../../utils/getIconForLanguage';
import { languageTranslations } from '../../utils/internationalisation';
import { route } from '../../utils/routes';
import { typeSafeObjectKeys } from '../../utils/typingUtils';
import { useAuth0User } from '../contexts/Auth0UserContext';
import { useCurrentUserDetailsContext, useUserType } from '../contexts/CurrentUserDetailsContext';
import { TransEnum, transEnum } from '../localization/TransEnum';
import { NavbarTertiaryIconButton } from './NavbarTertiaryIconButton';

const getHelpCenterLink = (token: string): string => {
    return 'https://help.luminovo.com';
};

const LanguageSelection: React.FunctionComponent = () => {
    const queryClient = useQueryClient();
    const { user } = useCurrentUserDetailsContext();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl((value) => (isPresent(value) ? null : event.currentTarget));
    };

    const { mutateAsync } = useHttpMutation('PATCH /my/user', {
        snackbarMessage: null,
        onSuccess: () => {
            // Experiment: Alex and Andi reported a problem with loading spinners that do not disappear after changing the language. This might help or not - we don't know.
            queryClient.clear();
            // Since Intercom doesn't reload with the updated language, we need to reload the entire app and can't just invalidate the react-query cache.
            window.location.reload();
        },
    });

    return (
        <MenuItem onClick={handleOpen}>
            <Flexbox gap={6}>
                {getIconForLanguage(user.user_language)}
                <TransEnum text={user.user_language} translations={languageTranslations} />
            </Flexbox>

            <Menu
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        transform: 'translateX(-100%)',
                    },
                }}
            >
                {typeSafeObjectKeys(languageTranslations).map((key) => {
                    return (
                        <MenuItem
                            key={key}
                            onClick={() =>
                                // eslint-disable-next-line camelcase
                                mutateAsync({ requestBody: { user_language: key } })
                            }
                        >
                            <Flexbox alignItems="center" gap={16}>
                                {getIconForLanguage(key)}
                                <span>{transEnum(key, languageTranslations)}</span>
                            </Flexbox>
                        </MenuItem>
                    );
                })}
            </Menu>
        </MenuItem>
    );
};

const TenantSelection: React.FunctionComponent = () => {
    const { all_tenants_for_user } = useCurrentUserDetailsContext();
    const userType = useUserType();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl((value) => (isPresent(value) ? null : event.currentTarget));
    };

    if (all_tenants_for_user.length < 2 || userType !== UserType.Internal) {
        return null;
    }

    return (
        <MenuItem onClick={handleOpen}>
            <Flexbox>
                <ExitToAppRounded
                    fontSize="small"
                    style={{ marginRight: SPACING_SMALL, color: colorSystem.neutral[6] }}
                />
                <Trans>Switch tenant</Trans>
            </Flexbox>

            <Menu
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        transform: 'translateX(-100%)',
                    },
                }}
            >
                <ListItem>
                    <Text variant={'body-small'} color={colorSystem.neutral[7]}>
                        <Trans>Tenants</Trans>
                    </Text>
                </ListItem>
                {Array.from(all_tenants_for_user)
                    .sort(compareByString)
                    .map((key) => {
                        return (
                            <MenuItem key={key} onClick={() => window.open(`https://${key}.luminovo.com`, '_blank')}>
                                <Tooltip variant={'white'} title={`${key}.luminovo.com`} placement="left">
                                    <Text variant="inherit">{key}</Text>
                                </Tooltip>
                            </MenuItem>
                        );
                    })}
            </Menu>
        </MenuItem>
    );
};

const FeatureFlagsMenu: React.FunctionComponent = () => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const { featureFlags, setFeatureFlags } = useDevFeatureFlags();

    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl((value) => (isPresent(value) ? null : event.currentTarget));
    };

    return (
        <MenuItem onClick={handleOpen}>
            <Flexbox gap={6}>Feature flags</Flexbox>

            <Menu
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        transform: 'translateX(-78%)',
                    },
                }}
            >
                {typeSafeObjectKeys(featureFlags).map((key) => {
                    return (
                        <MenuItem
                            key={key}
                            onClick={() => setFeatureFlags({ ...featureFlags, [key]: !featureFlags[key] })}
                        >
                            <Flexbox alignItems="center" gap={16}>
                                {key} {featureFlags[key] ? '✔️' : '✖️'}
                            </Flexbox>
                        </MenuItem>
                    );
                })}
            </Menu>
        </MenuItem>
    );
};

export const Avatar: React.FunctionComponent<{ variant: 'light' | 'dark'; style?: React.CSSProperties }> = React.memo(
    ({ variant, style }) => {
        const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
        const history = useHistory<RoutesHistoryStateProps | undefined>();
        const { logout } = useAuth0();
        const { customer, user: currentUser, supplier } = useCurrentUserDetailsContext();
        const user = useAuth0User();
        const { token } = useToken();

        const { data: customerPortalSettings } = useSuspenseHttpQuery(
            'GET /organization-settings/customer-portal',
            {},
            {
                staleTime: 60_000,
                select: (res) => {
                    const { imprint_link, statement_link, terms_and_conditions_link } = res.customer_portal_settings;
                    return { imprint_link, statement_link, terms_and_conditions_link };
                },
            },
        );

        const isCompanySettingsEnabled = isPresent(customer);

        const handleClose = () => {
            setAnchorEl(null);
        };
        const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
            setAnchorEl(event.currentTarget);
        };

        const onOrganizationSettingsClick = () => {
            history.push(route('/settings/organization/users'), { previousUrl: history.location.pathname });
        };

        const onCompanySettingsClick = () => {
            history.push(route('/settings/company'), { previousUrl: history.location.pathname });
        };
        return (
            <>
                <NavbarTertiaryIconButton
                    id={id('navbar/button_avatar')}
                    style={{ margin: '12px', marginRight: '0', height: '20px', width: '20px', ...style }}
                    variant={variant}
                    disableTouchRipple
                    edge="end"
                    aria-haspopup="true"
                    onClick={handleProfileMenuOpen}
                >
                    <AvatarIcon
                        size="small"
                        user={{
                            firstName: currentUser.first_name,
                            lastName: currentUser.last_name,
                            email: currentUser.email,
                        }}
                    />
                </NavbarTertiaryIconButton>
                <Menu
                    anchorEl={anchorEl}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <MenuItem disabled>{user.email}</MenuItem>
                    <Protected requiredPermissions={() => !Boolean(customer) && !supplier}>
                        <MenuItem onClick={onOrganizationSettingsClick}>
                            <Settings
                                fontSize="small"
                                style={{ marginRight: SPACING_SMALL, color: colorSystem.neutral[6] }}
                            />
                            <Trans>Settings</Trans>
                        </MenuItem>
                    </Protected>
                    <MenuItem onClick={showCookieConsentSettings}>
                        <ToggleOn
                            fontSize="small"
                            style={{ marginRight: SPACING_SMALL, color: colorSystem.neutral[6] }}
                        />
                        <Trans>Privacy & Cookies</Trans>
                    </MenuItem>
                    {isCompanySettingsEnabled && (
                        <MenuItem key="privacyLink" onClick={onCompanySettingsClick}>
                            <Settings fontSize="small" style={{ marginRight: '6px', color: colorSystem.neutral[6] }} />
                            <Trans>Company settings</Trans>
                        </MenuItem>
                    )}
                    {customerPortalSettings && [
                        customerPortalSettings.imprint_link && customerPortalSettings.imprint_link !== '' && (
                            <MenuItem
                                key="imprintLink"
                                onClick={() => window.open(customerPortalSettings.imprint_link ?? '', '_blank')}
                            >
                                <Fingerprint fontSize="small" style={{ marginRight: SPACING_SMALL }} />
                                <Trans>Imprint</Trans>
                            </MenuItem>
                        ),
                        customerPortalSettings.statement_link && customerPortalSettings.statement_link !== '' && (
                            <MenuItem
                                key="privacyLink"
                                onClick={() => window.open(customerPortalSettings.statement_link ?? '', '_blank')}
                            >
                                <PrivacyTipRounded
                                    fontSize="small"
                                    style={{ color: colorSystem.neutral[6], marginRight: '8px' }}
                                />
                                <Trans>Privacy statement</Trans>
                            </MenuItem>
                        ),
                        customerPortalSettings.terms_and_conditions_link &&
                            customerPortalSettings.terms_and_conditions_link !== '' && (
                                <MenuItem
                                    key="termsAndConditionsLink"
                                    onClick={() =>
                                        window.open(customerPortalSettings.terms_and_conditions_link ?? '', '_blank')
                                    }
                                >
                                    <Description
                                        htmlColor={colorSystem.neutral[6]}
                                        fontSize="small"
                                        style={{ marginLeft: '1px', marginRight: '6px' }}
                                    />
                                    <Trans>Terms & Conditions</Trans>
                                </MenuItem>
                            ),
                    ]}
                    <Protected requiredPermissions={() => !Boolean(customer) && !supplier}>
                        <MenuItem onClick={() => window.open(getHelpCenterLink(token), '_blank')}>
                            <Help
                                fontSize="small"
                                style={{ marginRight: SPACING_SMALL, color: colorSystem.neutral[6] }}
                            />
                            <Trans>Help center</Trans>
                        </MenuItem>
                    </Protected>
                    {!isProductionEnvironment() && [
                        <MenuItem key="divider" divider />,
                        <MenuItem key="tools" disabled>
                            Testing tools
                        </MenuItem>,
                        <MenuItem
                            key={'auth0'}
                            onClick={() => {
                                const urlEncoded = encodeURIComponent(user.sub ?? '');
                                const encodedId = window.btoa(urlEncoded);
                                const url = `https://manage.auth0.com/dashboard/eu/luminovo-dev/users/${encodedId}`;
                                window.open(url);
                            }}
                        >
                            Auth0
                        </MenuItem>,
                        <MenuItem
                            key={'version'}
                            onClick={() => {
                                // Added this confirmation because I had previously accidentally pressed this button
                                // and reloading the page takes a while...
                                if (window.confirm('Are you sure you want to reload the page?')) {
                                    window.location.reload();
                                }
                            }}
                        >
                            <Tooltip title={`SHA:${Boolean(window.EPIBATOR_SHA) ? window.EPIBATOR_SHA : `unknown`}`}>
                                <span>{`Version: ${
                                    Boolean(window.LUMINOVO_VERSION) ? window.LUMINOVO_VERSION : 'unknown'
                                }`}</span>
                            </Tooltip>
                        </MenuItem>,
                        <FeatureFlagsMenu key={'featureFlags'} />,
                    ]}
                    <MenuItem divider disabled disableGutters />
                    <LanguageSelection />
                    <TenantSelection />
                    <MenuItem
                        onClick={() => {
                            analytics.track('logout');
                            logout({ logoutParams: { returnTo: ORIGIN } });
                        }}
                    >
                        <LogoutRounded
                            fontSize="small"
                            style={{ marginRight: SPACING_SMALL, color: colorSystem.neutral[6] }}
                        />
                        <Trans>Log out</Trans>
                    </MenuItem>
                </Menu>
            </>
        );
    },
);
