import { SecondaryButton } from '@luminovo/design-system';
import { Download } from '@mui/icons-material';
import { useDownloadQuoteRequestExcel } from '../../../resources/export/exportHandler';
import { useQuoteRequestLineItemsByQuoteRequest } from '../hooks/negotiationHandlers';
import { isCustomPartQuoteRequest } from '../pages/supplier-portal/SupplierPortalQuoteRequestPage/model';

export function ButtonDownloadQuoteRequestExcel({
    quoteRequestId,
    onDownload = () => {},
}: {
    quoteRequestId: string;
    onDownload?: () => void;
}) {
    const { data: quoteRequestLineItems = [] } = useQuoteRequestLineItemsByQuoteRequest(quoteRequestId);
    const { mutateAsync, isLoading } = useDownloadQuoteRequestExcel(quoteRequestId);

    if (isCustomPartQuoteRequest(quoteRequestLineItems)) {
        return <></>;
    }

    return (
        <SecondaryButton
            size="medium"
            startIcon={<Download />}
            isLoading={isLoading}
            onClick={async () => {
                await mutateAsync();
                onDownload();
            }}
        >
            Download template
        </SecondaryButton>
    );
}
