import { AwardScenarioDTO } from '@luminovo/http-client';

export function formatAwardScenario(awardScenario?: AwardScenarioDTO | null) {
    if (!awardScenario) {
        return '';
    }

    switch (awardScenario.kind.tag) {
        case 'Awarded':
            // TODO(negotiations): Change this when we support multiple awarded award scenarios
            return 'Awarded';
        case 'Manual':
            return awardScenario.name;
        case 'BestPriceAcrossAllQuotes':
            return 'Best price across all quotes';
        case 'LastPurchasePrice':
            return 'Last purchase price';
        case 'BestListPrice':
            return 'Best list price';
        case 'LastStandardPrice':
            return 'Last standard price';
        case 'LastTargetPrice':
            return 'Last target price';
        case 'QuoteRequest':
            return awardScenario.name;
    }
}

export function isAutomaticAwardScenario(awardScenario?: AwardScenarioDTO | null) {
    return awardScenario?.kind.tag !== 'Manual';
}
