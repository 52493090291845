import { uniqueId } from '@luminovo/commons';
import {
    AdditionalOtherCostsSection,
    AdditionalProfitAndDiscountSection,
    CustomCost,
    ExtraCost,
    ManufacturingCostSection,
    MaterialCostSection,
    PostProfitCostsSection,
    ProjectCostsSection,
    SummarySection,
    TableColumn,
} from './tableColumnTypes';

export const uniqueRowId = (): string => {
    return uniqueId();
};

type ExtraCostRow = `${ExtraCost['type']}-${string}`;
type Header = `header`;
type Buffer = `buffer`;
type SubtotalCost = `subtotal-cost`;

type CustomCostIds = Exclude<keyof CustomCost, 'type' | 'extraCosts'>;

type RowsOfSection<U extends TableColumn['sections'][number], T extends string> = `${U['type']}-${T}`;

type MaterialCostRowIds = RowsOfSection<
    MaterialCostSection,
    'cost' | 'excessMaterialCost' | 'price' | CustomCostIds | ExtraCostRow | Header | SubtotalCost
>;

type ManufacturingCostRowIds = RowsOfSection<
    ManufacturingCostSection,
    'cost' | 'price' | CustomCostIds | ExtraCostRow | Header | SubtotalCost
>;

type ProjectEntityId<T extends string> = `activity-${T}` | `expense-${T}`;

type ProjectRowsIds = RowsOfSection<
    ProjectCostsSection,
    Exclude<keyof ProjectCostsSection, 'type'> | Header | Buffer | ProjectEntityId<string> | 'one-time-cost'
>;

type AdditionalProfitIds = RowsOfSection<PostProfitCostsSection, ExtraCostRow> | Header;

type AdditionalOtherCostsIds = RowsOfSection<AdditionalOtherCostsSection, ExtraCostRow | Header>;

type AdditionalProfitAndDiscountIds = RowsOfSection<
    AdditionalProfitAndDiscountSection,
    Exclude<keyof AdditionalProfitAndDiscountSection, 'type'> | Header
>;

type SummaryIds = RowsOfSection<SummarySection, Exclude<keyof SummarySection, 'type'> | Header | Buffer>;

export type RowId =
    | MaterialCostRowIds
    | ManufacturingCostRowIds
    | ProjectRowsIds
    | AdditionalOtherCostsIds
    | AdditionalProfitAndDiscountIds
    | AdditionalProfitIds
    | SummaryIds;
