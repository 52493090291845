import { colorSystem } from '@luminovo/design-system';
import { MoreHoriz as MoreHorizIcon } from '@mui/icons-material';
import { Box, Fab, Menu, styled } from '@mui/material';
import React from 'react';
import { WIDTH_DASHBOARD_MENU, themeLuminovo } from '../themes';

interface NestedTableMenuProps {
    children: React.ReactNode;
    handleClick: (event: React.MouseEvent<HTMLElement>) => void;
    anchorEl: null | HTMLElement;
    open: boolean;
    handleClose: (event: React.MouseEvent<HTMLElement>) => void;
    button?: React.ReactNode;
}

const StyledMoreHorizIcon = styled(MoreHorizIcon)({
    color: colorSystem.neutral[7],
});

const StyledFab = styled(Fab)({
    backgroundColor: themeLuminovo.palette.background.default,
    boxShadow: 'none',
});

/**
 * @deprecated except in src/components/NestedTableMenuWithItems.tsx
 */
const NestedTableMenu: React.FunctionComponent<NestedTableMenuProps> = ({
    children,
    handleClick,
    anchorEl,
    open,
    handleClose,
    button,
}: NestedTableMenuProps): JSX.Element => {
    const menuButton = button ?? (
        <StyledFab onClick={handleClick} size={'small'}>
            <StyledMoreHorizIcon fontSize="small" />
        </StyledFab>
    );
    return (
        <Box data-testid="nested-table-menu" display="flex" alignItems={'center'}>
            {menuButton}
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                elevation={1}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                PaperProps={{
                    style: {
                        width: WIDTH_DASHBOARD_MENU,
                    },
                }}
            >
                {children}
            </Menu>
        </Box>
    );
};

export default NestedTableMenu;
