import { MessageDescriptor } from '@lingui/core';
import { defineMessage, t } from '@lingui/macro';
import { transEnum } from '@luminovo/commons';
import { createColumnHelper, Flexbox, Tag, TanStackTable, Text, useTanStackTable } from '@luminovo/design-system';
import { BomItemApprovalStatus } from '@luminovo/http-client';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import { Box } from '@mui/material';
import { Row } from '@tanstack/react-table';
import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router';
import { route } from '../../../../utils/routes';
import { AssemblyTableData, ViewContext } from '../../../Bom/components/ModuleTableData';

enum subassembliesColumnIds {
    columnDepth = 'depth',
    columnSubassembly = 'subassembly',
    columnCpnRevision = 'cpn-revision',
    columnQuantity = 'quantity',
    columnStatus = 'status',
}

type SubassembliesTableSharedContext = {
    viewContext: ViewContext;
};

const useSubassembliesTable = ({
    subassemblies,
    viewContext,
}: {
    subassemblies: AssemblyTableData[];
    viewContext: ViewContext;
}) => {
    const { push } = useHistory();
    const sharedContext: SubassembliesTableSharedContext = useMemo(() => ({ viewContext }), [viewContext]);

    const navigateToSubAssembly = useCallback(
        (row: Row<AssemblyTableData>) => {
            const subAssemblyRoute =
                viewContext.type === 'WithinRfQ'
                    ? route('/rfqs/:rfqId/bom/assembly/:assemblyId/details', {
                          rfqId: viewContext.rfqId,
                          assemblyId: row.original.id,
                      })
                    : route('/assemblies/:assemblyId/details', { assemblyId: row.original.id });
            push(subAssemblyRoute);
        },
        [push, viewContext],
    );
    return useTanStackTable<AssemblyTableData, SubassembliesTableSharedContext>({
        data: subassemblies,
        columns: [columnDepth, columnSubassembly, columnCpnRevision, columnQuantity, columnStatus],
        sharedContext,
        enableColumnHiding: true,
        enableColumnOrdering: true,
        enablePersistentColumnFilters: true,
        enablePersistentGlobalFilter: true,
        enableSaveAsDefault: {
            key: 'subassemblies-table',
            storage: 'local',
        },
        enablePersistentScrollPosition: true,
        onRowClick: navigateToSubAssembly,
    });
};

export const SubassembliesTable = ({
    subassemblies,
    viewContext,
}: {
    subassemblies: AssemblyTableData[];
    viewContext: ViewContext;
}): JSX.Element => {
    const { table } = useSubassembliesTable({ subassemblies, viewContext });

    return (
        <Box height="100%">
            <TanStackTable
                table={table}
                size="small"
                enableMenuBar={{
                    globalSearch: false,
                    quickFilters: false,
                    actionButton: false,
                    controlButtons: false,
                    exportExcelButton: false,
                }}
                MenuBarTitle={() => (
                    <Flexbox>
                        <Text variant="h3">{t`Subassemblies`}</Text>
                    </Flexbox>
                )}
            />
        </Box>
    );
};

const EmptyCell = () => {
    return <>-</>;
};

const columnHelper = createColumnHelper<AssemblyTableData>();

const columnDepth = columnHelper.array((row) => row.depths.map((d) => d), {
    id: subassembliesColumnIds.columnDepth,
    label: () => t`Depth`,
    size: 100,
    getOptionLabel: (opt) => opt.toString(),
    getOptionKey: (opt) => opt.toString(),
    cell: ({ row }) => {
        const depths = row.original.depths;
        if (depths.length === 0) {
            return <EmptyCell />;
        }
        return (
            <Flexbox alignItems="center" gap="4px">
                {depths.map((d) => (
                    <Tag key={d} color="neutral" attention="low" label={d.toString()} />
                ))}
            </Flexbox>
        );
    },
});

const columnSubassembly = columnHelper.text((row) => `${row.designator}`, {
    id: subassembliesColumnIds.columnSubassembly,
    label: () => t`Subassembly`,
    size: 200,
    cell: ({ row }) => {
        const origin = row.original.origin;
        const needsAttention = origin.type === 'BomImport' && origin.value.issues.length > 0;
        return (
            <Flexbox alignItems="center" gap="4px">
                {row.original.designator}
                {needsAttention && <ErrorOutlineRoundedIcon fontSize="small" color="warning" />}
            </Flexbox>
        );
    },
});

const columnCpnRevision = columnHelper.text(
    (row) => {
        if (row.origin.type !== 'BomImport') {
            return undefined;
        }
        const excelOrigin = row.origin.value.excel_origin;
        if (!excelOrigin) {
            return undefined;
        }
        const identifier = excelOrigin.identifier;
        return `${identifier.cpns[0] ?? '-'} • ${identifier.cpn_revisions[0] ?? '-'}`;
    },
    {
        id: subassembliesColumnIds.columnCpnRevision,
        label: () => t`CPN • Rev`,
        size: 100,
        cell: ({ row }) => {
            if (row.original.origin.type !== 'BomImport') {
                return <EmptyCell />;
            }
            const excelOrigin = row.original.origin.value.excel_origin;
            if (!excelOrigin) {
                return <EmptyCell />;
            }
            const identifier = excelOrigin.identifier;
            let formattedResult = identifier.cpns[0] ?? '-';
            const firstCpnRevisionIdentifier = identifier.cpn_revisions[0];
            if (firstCpnRevisionIdentifier) {
                formattedResult += ` • ${firstCpnRevisionIdentifier}`;
            }
            return formattedResult;
        },
    },
);

const columnQuantity = columnHelper.number((row) => row.quantity.quantity, {
    id: subassembliesColumnIds.columnQuantity,
    label: () => t`Qty`,
    size: 50,
    cell: ({ row }) => {
        const allAssemblyQuantities = Object.values(row.original.parents).join(', ');
        return allAssemblyQuantities;
    },
});

const columnStatus = columnHelper.enum(
    (row) => {
        if (row.origin.type === 'BomImport' && row.origin.value.issues.length > 0) {
            return BomItemApprovalStatus.Rejected;
        }
        return row.approvalStatus;
    },
    {
        id: subassembliesColumnIds.columnStatus,
        label: () => t`Status`,
        size: 100,
        options: [
            BomItemApprovalStatus.Approved,
            BomItemApprovalStatus.DNP,
            BomItemApprovalStatus.Rejected,
            BomItemApprovalStatus.Pending,
        ],
        getOptionLabel: (status) => transEnum(status, bomItemApprovalStatusTranslations),
        cell: (item) => {
            const label = transEnum(item.getValue(), bomItemApprovalStatusTranslations);
            switch (item.getValue()) {
                case BomItemApprovalStatus.Approved:
                    return <Tag attention="low" color="green" label={label} />;
                case BomItemApprovalStatus.DNP:
                    return <Tag attention="low" color="neutral" label={label} />;
                case BomItemApprovalStatus.Rejected:
                    return <Tag attention="low" color="yellow" label={label} />;
                case BomItemApprovalStatus.Pending:
                    return <Tag attention="low" color="neutral" label={label} />;
                default:
                    return <>-</>;
            }
        },
    },
);

export const bomItemApprovalStatusTranslations: Record<BomItemApprovalStatus, MessageDescriptor> = {
    [BomItemApprovalStatus.Approved]: defineMessage({ message: 'Approved' }),
    [BomItemApprovalStatus.DNP]: defineMessage({ message: 'Do not place' }),
    [BomItemApprovalStatus.Rejected]: defineMessage({ message: 'Needs attention' }),
    [BomItemApprovalStatus.Pending]: defineMessage({ message: 'Pending' }),
    [BomItemApprovalStatus.Warning]: defineMessage({ message: 'Needs attention' }),
};
