/* eslint-disable spellcheck/spell-checker */
import { isPresent, throwErrorUnlessProduction } from '@luminovo/commons';
import { useEffect, useState } from 'react';
import { useHttpMutation } from '../../../../../../resources/mutation/useHttpMutation';
import { usePcb } from '../../../../../../resources/pcb/pcbHandlers';
import { convertExtractedValuesToRequestBody } from './converters';
import { useExtractPcbSpecificationFromPdf } from './useExtractPcbSpecificationFromPdf';
import { useExtractedPcbSpecification } from './useExtractedPcbSpecification';

export const useExtractAndSavePcbSpecificationFromPdf = ({ pcbId }: { pcbId: string }) => {
    const { data: savedValues } = useExtractedPcbSpecification({ pcbId });
    const hasPreviouslyExtractedValues = isPresent(savedValues);

    // Get the pcb
    const { data: pcb } = usePcb(pcbId, hasPreviouslyExtractedValues === false);

    // Extract the data from the pcb using the pdf-extractor
    const extractedValues = useExtractPcbSpecificationFromPdf({
        files: pcb?.files,
        disabled: hasPreviouslyExtractedValues,
    });

    // Save the extracted values to the database
    const { mutateAsync } = useHttpMutation('POST /pcb-pdf-specification-extraction', {
        snackbarMessage: null,
    });
    const [isSaveInitiated, setIsSaveInitiated] = useState(false);

    useEffect(() => {
        const convertedSpecification = extractedValues
            ? {
                  result: convertExtractedValuesToRequestBody(extractedValues),
                  file: extractedValues.file,
              }
            : undefined;

        const savePcbSpecification = async () => {
            if (convertedSpecification === undefined) return null;

            setIsSaveInitiated(true); // Prevent further save attempts

            try {
                await mutateAsync({
                    requestBody: {
                        pcb: pcbId,
                        // eslint-disable-next-line camelcase
                        merged_results: convertedSpecification.result,
                        file: convertedSpecification.file,
                    },
                });
            } catch (error) {
                throwErrorUnlessProduction(error);
            }
        };

        if (
            pcb &&
            convertedSpecification &&
            convertedSpecification.result.length > 0 &&
            isSaveInitiated === false &&
            hasPreviouslyExtractedValues === false
        ) {
            savePcbSpecification();
        }
    }, [pcb, mutateAsync, pcbId, hasPreviouslyExtractedValues, extractedValues, isSaveInitiated]);

    return {
        extractedFile: extractedValues?.file,
        hasExtractedValues: hasPreviouslyExtractedValues,
    };
};

/* eslint-enable camelcase */
