import { Trans } from '@lingui/macro';
import { colorSystem, Flexbox } from '@luminovo/design-system';
import {
    ExchangedMonetaryValueBackendConvertedMVOnlyDTO,
    MonetaryValueBackend,
    ResourceDetailsDTO,
} from '@luminovo/http-client';
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { renderCurrency } from '../../activitiesTableComponents';

const StyledTypography = styled(Typography)({
    color: colorSystem.neutral[8],
    marginBlockEnd: '8px',
});

interface ResourceMultiplierInformation {
    id: string;
    costPerHour: ExchangedMonetaryValueBackendConvertedMVOnlyDTO;
    multiplier: number;
    costByMultiplier: MonetaryValueBackend;
}

const getResourceAndMultiplier = (resourcesDetails: ResourceDetailsDTO[]): ResourceMultiplierInformation[] => {
    return resourcesDetails.map((resource) => ({
        id: resource.resource.id,
        costPerHour: resource.resource.cost_per_hour,
        multiplier: Number(resource.multiplier),
        costByMultiplier: {
            amount: String(Number(resource.resource.cost_per_hour.converted_mv.amount) * Number(resource.multiplier)),
            currency: resource.resource.cost_per_hour.converted_mv.currency,
        },
    }));
};

const CostDetails = ({ resourcesDetails }: { resourcesDetails: ResourceDetailsDTO[] }): JSX.Element => {
    const resourceInformation: ResourceMultiplierInformation[] = getResourceAndMultiplier(resourcesDetails);

    return (
        <Flexbox gap={2} flexDirection={'column'}>
            {resourceInformation.map((resourceCostAndMultiplier) => (
                <Typography key={resourceCostAndMultiplier.id}>
                    {renderCurrency({
                        monetaryValue: resourceCostAndMultiplier.costPerHour.converted_mv ?? null,
                        nullPlaceholder: '-',
                        shouldAmountBePrecise: false,
                    })}
                    {resourceCostAndMultiplier.multiplier !== 1 && (
                        <>
                            *{resourceCostAndMultiplier.multiplier} ={' '}
                            {renderCurrency({
                                monetaryValue: resourceCostAndMultiplier.costByMultiplier,
                                nullPlaceholder: '-',
                                shouldAmountBePrecise: false,
                            })}
                        </>
                    )}
                </Typography>
            ))}
        </Flexbox>
    );
};

export const ActivityConfigurationCostDetails = ({
    resourcesDetails,
}: {
    resourcesDetails: ResourceDetailsDTO[];
}): JSX.Element => {
    return (
        <>
            <StyledTypography variant="h3">
                <Trans>Cost/hour</Trans>
            </StyledTypography>
            <CostDetails resourcesDetails={resourcesDetails} />
        </>
    );
};
