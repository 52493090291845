import * as r from 'runtypes';
import {
    FixedCostDTO,
    FixedCostRuntype,
    FixedFormulaRuntype,
    FractionCostDTO,
    FractionCostRuntype,
    FractionFormulaRuntype,
} from '../calculation/calculationBackendTypes';
import { SyntaxErrorRuntype } from '../formula';

export const FormulaCostTemplateRuntype = r.Record({
    type: r.Literal('Formula'),
    data: r.Record({
        formula: r.Union(FixedFormulaRuntype, FractionFormulaRuntype),
        errors: r.Array(SyntaxErrorRuntype).optional(),
    }),
});

type FormulaCostTemplateDTO = r.Static<typeof FormulaCostTemplateRuntype>;

export type UnitCostTemplateDTO = FixedCostDTO | FractionCostDTO | FormulaCostTemplateDTO;

const CostCellRuntype = r.Record({
    unit_cost: r.Union(FixedCostRuntype, FractionCostRuntype, FormulaCostTemplateRuntype),
    is_locked: r.Boolean,
});
export type CostCellTemplateDTO = r.Static<typeof CostCellRuntype>;

const ExtraCostRuntype = r.Record({
    name: r.String,
    cost: CostCellRuntype,
});
export type ExtraCostTemplateDTO = r.Static<typeof ExtraCostRuntype>;

const CustomCostTemplateRuntype = r.Record({
    extra_costs: r.Array(ExtraCostRuntype),
    profit: CostCellRuntype.nullable(),
    discount: CostCellRuntype.nullable(),
});

const AdditionalCostRuntype = r.Record({
    profit: CostCellRuntype.nullable(),
    discount: CostCellRuntype.nullable(),
    other_costs: r.Array(ExtraCostRuntype),
    post_profit_costs: r.Array(ExtraCostRuntype),
});

const ProjectCostTemplateRuntype = r.Record({
    profit: CostCellRuntype.nullable(),
});

// POST. Post is slightly different from the GET request, because it has a different structure for the Cost. POST only contains the custom_costs.

const FormulaCostPostRuntype = r.Record({
    type: r.Literal('Formula'),
    data: r.Union(FixedFormulaRuntype, FractionFormulaRuntype),
});

type FormulaCostPostDTO = r.Static<typeof FormulaCostPostRuntype>;

export type UnitCostPostTemplateDTO = FixedCostDTO | FractionCostDTO | FormulaCostPostDTO;

const CostCellPostRuntype = r.Record({
    unit_cost: r.Union(FixedCostRuntype, FractionCostRuntype, FormulaCostPostRuntype),
    is_locked: r.Boolean,
});

export type CostCellPostTemplateDTO = r.Static<typeof CostCellPostRuntype>;

const ExtraCostPostRuntype = r.Record({
    name: r.String,
    cost: CostCellPostRuntype,
});
export type ExtraCostPostDTO = r.Static<typeof ExtraCostPostRuntype>;

const CustomCostPostRuntype = r.Record({
    extra_costs: r.Array(ExtraCostPostRuntype),
    profit: CostCellPostRuntype.nullable(),
    discount: CostCellPostRuntype.nullable(),
});

export type CustomCostPostDTO = r.Static<typeof CustomCostPostRuntype>;

const ProjectCostPostRuntype = r.Record({
    profit: CostCellPostRuntype.nullable(),
});

const AdditionalCostPostRuntype = r.Record({
    profit: CostCellPostRuntype.nullable(),
    discount: CostCellPostRuntype.nullable(),
    other_costs: r.Array(ExtraCostPostRuntype),
    post_profit_costs: r.Array(ExtraCostPostRuntype),
});

const CalculationTemplateRuntypeBase = r.Record({
    name: r.String,
    notes: r.String.nullable(),
    include_excess_material_in_material_cost: r.Boolean,
    include_project_cost_in_manufacturing_cost: r.Boolean,
    include_one_time_cost_in_project_cost: r.Boolean,
});

export const CalculationTemplatePostRuntype = CalculationTemplateRuntypeBase.extend({
    material_cost: CustomCostPostRuntype,
    manufacturing_cost: CustomCostPostRuntype,
    additional_cost: AdditionalCostPostRuntype,
    project_cost: ProjectCostPostRuntype,
});

export const CalculationTemplateRuntype = CalculationTemplateRuntypeBase.extend({
    id: r.String,
    created_at: r.String,
    updated_at: r.String,
    material_cost: CustomCostTemplateRuntype,
    manufacturing_cost: CustomCostTemplateRuntype,
    additional_cost: AdditionalCostRuntype,
    project_cost: ProjectCostTemplateRuntype,
});

export const CustomerPortalCalculationSettingsRuntype = r.Record({
    calculation_template_id: r.String.nullable(),
});

export type CalculationTemplateResponseDTO = r.Static<typeof CalculationTemplateRuntype>;

export type CalculationTemplatePostDTO = r.Static<typeof CalculationTemplatePostRuntype>;
