import { getToken } from '@luminovo/auth';
import { isPresent } from '@luminovo/commons';
import { http } from '@luminovo/http-client';
import { useQueries } from '@tanstack/react-query';
import { useDebugErrorHandler } from '../../../../resources/http/debugErrorHandler';
import { httpQueryKey } from '../../../../resources/http/httpQueryKey';

type FormulaEntry = { isError: boolean; driverNames: string[] };

export interface FormulasContext {
    formulaDriversMap: Record<string, FormulaEntry>;
}

export const useFormulasContext = (formulas: string[]): FormulasContext => {
    const onError = useDebugErrorHandler();

    const queries = formulas.map((formula) => {
        return {
            queryKey: httpQueryKey('POST /formula/validate', { requestBody: { formula } }),
            queryFn: async () => {
                const formulaResponse = await http('POST /formula/validate', { requestBody: { formula } }, getToken());
                return { formula, formulaResponse };
            },
            onError,
        };
    });

    const formulaQueries = useQueries({ queries });

    const formulaDriversMap = formulaQueries
        .map((response) => {
            const { isLoading, data } = response;

            const formula = data?.formula;
            const formulaResponse = data?.formulaResponse;

            if (isLoading || !formula || !formulaResponse) {
                return undefined;
            }

            const isError = formulaResponse.result === 'Error';
            const identifiers = !isError ? formulaResponse.details.identifiers : [];

            // TODO: driver names are turned into identifiers by converting ' ' to '_'. You cannot easily
            // convert them back by replacing '_' with ' ' because the driver name could originally contain
            // underscores.
            const driverNames = identifiers.map((identifier) => identifier.replaceAll('_', ' '));

            return { formula, isError, driverNames };
        })
        .filter(isPresent)
        .reduce<Record<string, FormulaEntry>>((acc, curr) => {
            acc[curr.formula] = { isError: curr.isError, driverNames: curr.driverNames };
            return acc;
        }, {});

    return { formulaDriversMap };
};
