import { Currency } from '@luminovo/commons';
import {
    CalculationTemplatePostDTO,
    CostCellPostTemplateDTO,
    CustomCostPostDTO,
    ExtraCostPostDTO,
    UnitCostPostTemplateDTO,
} from '@luminovo/http-client';
import { CalculationTemplateFormInput, CostCell, CustomCosts, ExtraCost, ProjectCost, UnitCost } from './types';

const convertCostType = (unitCost: UnitCost, preferredCurrency: Currency): UnitCostPostTemplateDTO => {
    if (unitCost.type === 'percentage') {
        return {
            type: 'Fraction',
            data: unitCost.value.percentage,
        };
    } else if (unitCost.type === 'fixed')
        return {
            type: 'Fixed',
            data: { amount: unitCost.value.cost.amount, currency: preferredCurrency },
        };
    else {
        return {
            type: 'Formula',
            data:
                unitCost.value.type === 'percentage'
                    ? {
                          type: 'Fraction',
                          data: { script: unitCost.value.value },
                      }
                    : {
                          type: 'Fixed',
                          data: { script: unitCost.value.value, currency: preferredCurrency },
                      },
        };
    }
};

/* eslint-disable camelcase */
const convertCostCellToCostCellDTO = (costCell: CostCell, preferredCurrency: Currency): CostCellPostTemplateDTO => {
    // Sectional profit and discount can be removed from the form by the user.
    return {
        unit_cost: convertCostType(costCell.value.unitCost, preferredCurrency),
        is_locked: costCell.isLocked,
    };
};

const convertExtraCostArray = (extraCosts: ExtraCost[], preferredCurrency: Currency): ExtraCostPostDTO[] => {
    return extraCosts.map((extraCost) => ({
        name: extraCost.name,
        cost: {
            unit_cost: convertCostType(extraCost.unitCost, preferredCurrency),
            is_locked: extraCost.isLocked,
        },
    }));
};

const convertAdditionalCost = (
    additionalCost: CalculationTemplateFormInput['additionalCosts'],
    preferredCurrency: Currency,
): CalculationTemplatePostDTO['additional_cost'] => {
    return {
        other_costs: convertExtraCostArray(additionalCost.otherCosts, preferredCurrency),
        post_profit_costs: convertExtraCostArray(additionalCost.postProfitCosts, preferredCurrency),
        discount: additionalCost.discount
            ? convertCostCellToCostCellDTO(additionalCost.discount, preferredCurrency)
            : null,
        profit: additionalCost.profit ? convertCostCellToCostCellDTO(additionalCost.profit, preferredCurrency) : null,
    };
};

const convertCustomCost = (cost: CustomCosts, preferredCurrency: Currency): CustomCostPostDTO => {
    return {
        extra_costs: convertExtraCostArray(cost.extraCosts, preferredCurrency),
        discount: cost.discount ? convertCostCellToCostCellDTO(cost.discount, preferredCurrency) : null,
        profit: cost.profit ? convertCostCellToCostCellDTO(cost.profit, preferredCurrency) : null,
    };
};

const convertProjectCost = (
    cost: ProjectCost,
    preferredCurrency: Currency,
    isSeparateProjectCostSelected: boolean,
): CalculationTemplatePostDTO['project_cost'] => {
    return {
        profit:
            isSeparateProjectCostSelected && cost?.profit
                ? convertCostCellToCostCellDTO(cost.profit, preferredCurrency)
                : null,
    };
};

export const convertTemplateFormToCalculationTemplatePost = (
    templateForm: CalculationTemplateFormInput,
): CalculationTemplatePostDTO => {
    return {
        additional_cost: convertAdditionalCost(templateForm.additionalCosts, templateForm.currency),
        include_excess_material_in_material_cost: templateForm.includeExcessMaterialInMaterialCost,
        include_project_cost_in_manufacturing_cost: !templateForm.isSeparateProjectCostSelected,
        include_one_time_cost_in_project_cost: templateForm.includeOneTimeCostInProjectCost,
        manufacturing_cost: convertCustomCost(templateForm.manufacturingCost, templateForm.currency),
        material_cost: convertCustomCost(templateForm.materialCost, templateForm.currency),
        project_cost: convertProjectCost(
            templateForm.projectCost,
            templateForm.currency,
            templateForm.isSeparateProjectCostSelected,
        ),
        name: templateForm.name,
        notes: '', // notes field is not included in designs as of 22/12/2022.
    };
};
/* eslint-enable camelcase */
