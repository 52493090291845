/* eslint-disable camelcase */
import { t } from '@lingui/macro';
import { Currency } from '@luminovo/commons';
import { ExtractRequestBody, PriceType } from '@luminovo/http-client';
import { UniversalImporter } from '@luminovo/universal-importer';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router';
import { useHttpMutation } from '../../resources/mutation/useHttpMutation';
import { useGlobalCurrency } from '../../resources/organizationSettings/currencySettingsHandler';
import { route } from '../../utils/routes';
import { formatError } from '../Error/formatError';

export function InventoryOfferImporter() {
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const { mutateAsync: importComponents } = useHttpMutation('POST /offers/import', { snackbarMessage: null });
    const { preferredCurrency } = useGlobalCurrency();

    return (
        <UniversalImporter
            title={t`IPN offer importer`}
            batchSize={Infinity}
            hrefBack={route('/parts/components/ipn')}
            onImportDone={() => {
                enqueueSnackbar(t`Offers imported successfully`, {
                    variant: 'success',
                    anchorOrigin: {
                        horizontal: 'center',
                        vertical: 'top',
                    },
                });
                history.goBack();
            }}
            onImportBatch={async (batch) => {
                const requestBody: ExtractRequestBody<'POST /offers/import'> = batch.map((row) => {
                    return {
                        supplier:
                            row.data.supplierNumber && row.data.supplierNumber.length > 0
                                ? {
                                      type: 'External',
                                      supplier_number: row.data.supplierNumber,
                                  }
                                : {
                                      type: 'Internal',
                                  },
                        part: {
                            internal_part_number: row.data.ipn,
                        },
                        availability: {
                            available_stock: row.data.available_stock,
                            total_stock: row.data.total_stock,
                        },
                        prices: [
                            {
                                unit_price: {
                                    currency: row.data.currency as Currency,
                                    amount: row.data.unitPrice.toString(),
                                },
                                moq: row.data.moq,
                                mpq: row.data.mpq,
                            },
                        ],
                        price_type: row.data.priceType,
                        notes: row.data.notes,
                        packaging: row.data.packaging,
                        customer:
                            row.data.customerNumber && row.data.customerNumber.length > 0
                                ? {
                                      number: row.data.customerNumber,
                                      name: row.data.customerName.length > 0 ? row.data.customerName : undefined,
                                  }
                                : undefined,
                    };
                });

                return importComponents({
                    requestBody,
                })
                    .then((results) => {
                        return batch.map((batchItem) => {
                            const resultItem = results.find(
                                (resultItem) => batchItem.data.ipn === resultItem.part?.internal_part_number,
                            );

                            if (!resultItem) {
                                return {
                                    success: false as const,
                                    message: t`Unknown error`,
                                };
                            }

                            if (resultItem.status > 299) {
                                return {
                                    success: false as const,
                                    message: resultItem.description ?? t`Unknown error`,
                                };
                            }

                            return {
                                success: true as const,
                            };
                        });
                    })
                    .catch((error) => {
                        return batch.map((row) => {
                            return {
                                success: false as const,
                                message: formatError(error),
                            };
                        });
                    });
            }}
            config={{
                fields: [
                    {
                        id: 'ipn' as const,
                        columnIndices: [],
                        required: true,
                        parser: { type: 'ipn', options: { ipns: [] } },
                        label: t`IPN`,
                        description: t`The internal part number`,
                    },
                    {
                        id: 'available_stock' as const,
                        columnIndices: [],
                        required: false,
                        defaultValue: { id: 0, label: `0`, description: '' },
                        parser: { type: 'number', options: { min: 0 } },
                        label: t`Available stock`,
                        description: t`How much stock is available to use`,
                    },
                    {
                        id: 'total_stock' as const,
                        columnIndices: [],
                        required: false,
                        defaultValue: { id: null, label: t`Unknown`, description: '' },
                        parser: { type: 'number', options: { min: 0 } },
                        label: t`Total stock`,
                        description: t`The total amount of stock. Some of it might be reserved or not available for use`,
                    },
                    {
                        id: 'unitPrice' as const,
                        columnIndices: [],
                        required: true,
                        defaultValue: { id: 0, label: `0`, description: '' },
                        parser: { type: 'number', options: { min: 0 } },
                        label: t`Unit price`,
                        description: t`The price of a single unit`,
                    },
                    {
                        id: 'currency' as const,
                        columnIndices: [],
                        required: false,
                        parser: { type: 'currency', options: {} },
                        label: t`Currency`,
                        defaultValue: { id: preferredCurrency, label: preferredCurrency },
                        description: t`The unit price's currency`,
                    },
                    {
                        id: 'supplierNumber' as const,
                        columnIndices: [],
                        required: false,
                        parser: { type: 'string', options: { trim: true } },
                        label: t`Supplier number`,
                        description: t`The supplier of the component`,
                    },
                    {
                        id: 'priceType' as const,
                        columnIndices: [],
                        required: false,
                        parser: { type: 'priceType', options: {} },
                        label: t`Price type`,
                        defaultValue: { id: PriceType.StandardPrice, label: 'StandardPrice' },
                        description: t`The type of price for this offer. You can find a comprehensive description of the different price types here:https://help.luminovo.com/en/articles/84955-which-price-types-does-luminovo-support`,
                    },
                    {
                        id: 'packaging' as const,
                        columnIndices: [],
                        required: false,
                        parser: { type: 'packaging', options: {} },
                        label: t`Packaging`,
                        defaultValue: { id: null, label: t`Unknown` },
                        description: t`The packaging in which the part is delivered (e.g. reel, tape, etc.)`,
                    },
                    {
                        id: 'moq' as const,
                        columnIndices: [],
                        required: false,
                        defaultValue: { id: null, label: t`Unknown` },
                        parser: { type: 'number', options: { min: 0 } },
                        label: t`MOQ`,
                        description: t`The MOQ of the offer`,
                    },
                    {
                        id: 'mpq' as const,
                        columnIndices: [],
                        required: false,
                        parser: { type: 'number', options: { min: 0 } },
                        label: t`MPQ`,
                        defaultValue: { id: null, label: t`Unknown` },
                        description: t`The MPQ of the offer`,
                    },
                    {
                        id: 'notes' as const,
                        columnIndices: [],
                        required: false,
                        parser: { type: 'notes', options: {} },
                        label: t`Notes`,
                        defaultValue: { id: '', label: '-', description: '' },
                        description: t`Any additional information you want to add`,
                    },
                    {
                        id: 'customerName' as const,
                        columnIndices: [],
                        required: false,
                        parser: { type: 'string', options: { trim: false } },
                        label: t`Customer name`,
                        description: t`The name of the customer this offer is for. Leave blank if the offer is not linked to a specific customer.`,
                    },
                    {
                        id: 'customerNumber' as const,
                        columnIndices: [],
                        required: false,
                        parser: { type: 'string', options: { trim: false } },
                        label: t`Customer number`,
                        description: t`The number of the customer this offer is for. Leave blank if the offer is not linked to a specific customer.`,
                    },
                ],
            }}
        />
    );
}
