import { SecondaryIconButton, Tooltip } from '@luminovo/design-system';
import { Edit } from '@mui/icons-material';
import { useDialogEditNegotiation } from './NegotiationDialogs/EditNegotiationDialog';

export function ButtonEditNegotiation({ negotiationId }: { negotiationId: number }) {
    const { openDialog: openEdit } = useDialogEditNegotiation({ negotiationId });

    return (
        <Tooltip title={`Edit negotiation`}>
            <SecondaryIconButton onClick={openEdit} size="medium">
                <Edit fontSize="inherit" />
            </SecondaryIconButton>
        </Tooltip>
    );
}
