import { Trans } from '@lingui/macro';
import { colorSystem, Flexbox } from '@luminovo/design-system';
import { ExpenseConfigurationSummaryDTO, MonetaryValueBackend } from '@luminovo/http-client';
import { Box, Typography } from '@mui/material';
import { ErrorText, Skeleton } from '../../../components/Spinners';
import { useSuspenseHttpQuery } from '../../../resources/http/useHttpQuery';
import { getValueOf } from '../../../utils/typingUtils';
import { renderCurrency } from '../ActivitiesTable/activitiesTableComponents';
import { CalculationTypeCell, DriverCell } from '../shared/manufacturingComponents';
import { FormulaOfExpense } from './FormulaOfExpense';

const FixedCosts = ({
    monetaryValue,
    shouldPlusSignBeShown,
}: {
    monetaryValue: MonetaryValueBackend | null;
    shouldPlusSignBeShown?: boolean;
}): JSX.Element => {
    return (
        <Flexbox gap={8} alignItems={'center'}>
            {shouldPlusSignBeShown === true && <Typography>+</Typography>}
            <Typography>
                {renderCurrency({
                    monetaryValue,
                    nullPlaceholder: '-',
                    shouldAmountBePrecise: false,
                })}
            </Typography>
        </Flexbox>
    );
};

export const ExpenseCost = ({
    expense,
    batchSize,
}: {
    expense: ExpenseConfigurationSummaryDTO;
    batchSize: number | undefined;
}): JSX.Element => {
    const { data: expensesData, isLoading } = useSuspenseHttpQuery('GET /expenses', { queryParams: {} }, {}); // we could use GET /expenses/:expenseId but since we already call /expenses elsewhere it will save an extra call
    const expenseDTO = expensesData?.data.find((e) => e.id === expense.expense_configuration_details.expense_id);

    const costComponents = batchSize
        ? getValueOf(expense.expense_configuration_details.cost_components.costs_per_batch_size, batchSize.toString())
        : undefined;

    if (isLoading) {
        return <Skeleton />;
    }
    return (
        <Box padding={'24px'}>
            <Typography variant="h3" style={{ color: colorSystem.neutral[8] }}>
                <Trans>Expense calculation</Trans>
            </Typography>

            {expenseDTO ? (
                <Box marginTop={'8px'} marginBottom={'8px'}>
                    <CalculationTypeCell calculationType={expenseDTO.cost_components.type} />
                </Box>
            ) : (
                <ErrorText />
            )}

            {expenseDTO && expenseDTO.cost_components.type === 'Formula' && (
                <FormulaOfExpense expenseId={expenseDTO.id} />
            )}

            {expenseDTO && costComponents && (
                <>
                    {expenseDTO.cost_components.type === 'Linear' && (
                        <Flexbox gap={8} alignItems={'center'}>
                            {costComponents.variable_cost.result === 'Ok' ? (
                                <Typography>
                                    {renderCurrency({
                                        monetaryValue: costComponents.variable_cost.data?.original_mv ?? null,
                                        nullPlaceholder: '-',
                                        shouldAmountBePrecise: false,
                                    })}
                                </Typography>
                            ) : (
                                '-'
                            )}
                            <Typography>x </Typography>
                            <DriverCell driverId={expenseDTO.cost_components.details.variable_cost.driver} />
                            {costComponents.fixed_cost.result === 'Ok' &&
                                costComponents.fixed_cost.data?.original_mv && (
                                    <FixedCosts
                                        monetaryValue={costComponents.fixed_cost.data.original_mv}
                                        shouldPlusSignBeShown={true}
                                    />
                                )}
                        </Flexbox>
                    )}

                    {expenseDTO.cost_components.type === 'Fixed' &&
                        costComponents.fixed_cost.result === 'Ok' &&
                        costComponents.fixed_cost.data?.original_mv && (
                            <FixedCosts monetaryValue={costComponents.fixed_cost.data?.original_mv} />
                        )}
                </>
            )}
        </Box>
    );
};
