import { useAuth0 } from '@auth0/auth0-react';
import { isExpired, setToken, TokenContext } from '@luminovo/auth';
import { isPresent } from '@luminovo/commons';
import { CenteredLayout } from '@luminovo/design-system';
import { useQuery } from '@tanstack/react-query';
import * as React from 'react';
import { AUTH0_AUTHORIZATION_PARAMS } from '../../const';
import { withAuthenticationRequired } from '../../modules/withAuthenticationRequired';
import { SpinnerWithBackdrop } from '../Spinners';

export const ACCESS_TOKEN_QUERY_KEY = ['access-token'];

export const Auth0TokenContextProvider = withAuthenticationRequired(function Auth0TokenContextProvider(
    props: React.PropsWithChildren<{}>,
) {
    const { getAccessTokenSilently, loginWithRedirect, isLoading, isAuthenticated, user } = useAuth0();

    const { data: queryToken, isInitialLoading } = useQuery({
        // eslint-disable-next-line @tanstack/query/exhaustive-deps
        queryKey: ACCESS_TOKEN_QUERY_KEY,
        queryFn: () => {
            return getAccessTokenSilently({
                authorizationParams: AUTH0_AUTHORIZATION_PARAMS,
            });
        },
        enabled: isAuthenticated && !isLoading,

        refetchOnWindowFocus: true,
    });

    if (isLoading || isInitialLoading || !isPresent(queryToken)) {
        return (
            <CenteredLayout>
                <SpinnerWithBackdrop noBackdrop={true} />
            </CenteredLayout>
        );
    }

    if (isExpired(queryToken)) {
        loginWithRedirect({
            appState: {
                returnTo: window.location.pathname,
            },
            authorizationParams: { login_hint: user?.email },
        });
        return (
            <CenteredLayout>
                <SpinnerWithBackdrop noBackdrop={true} />
            </CenteredLayout>
        );
    }

    // Important: set the token before rendering the children
    setToken(queryToken);

    return <TokenContext.Provider value={queryToken}>{props.children}</TokenContext.Provider>;
});
