import { UpDownButtons } from '@luminovo/manufacturing-core';
import { useHttpMutation } from '../../../../resources/mutation/useHttpMutation';
import { Position } from './types';

export const TemplateInstanceUpDownButtons = ({
    templateInstanceId,
    rank,
    position,
}: {
    templateInstanceId: string;
    rank: number;
    position: Position;
}): JSX.Element => {
    const { mutateAsync, isPending: isLoading } = useHttpMutation(
        'PATCH /manufacturing-scenario-template-instances/:manufacturingScenarioTemplateInstanceId/rank',
        {
            snackbarMessage: null,
        },
    );

    const handleUpdateItems = async ({ direction }: { direction: 'up' | 'down' }) => {
        const nextRank = 'nextRank' in position ? position.nextRank : undefined;
        const previousRank = 'previousRank' in position ? position.previousRank : undefined;

        const newRank = direction === 'up' ? previousRank : nextRank;

        if (newRank === undefined) {
            return;
        }

        await mutateAsync({
            pathParams: {
                manufacturingScenarioTemplateInstanceId: templateInstanceId.toString(),
            },
            queryParams: {},
            requestBody: {
                rank: newRank,
            },
        });
    };

    return (
        <UpDownButtons
            isLoading={isLoading}
            isDisabledFirstRow={position.type === 'first' || position.type === 'first-and-last'}
            isDisabledLastRow={position.type === 'last' || position.type === 'first-and-last'}
            onClickUp={async () => await handleUpdateItems({ direction: 'up' })}
            onClickDown={async () => await handleUpdateItems({ direction: 'down' })}
        />
    );
};
