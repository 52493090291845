import { AssemblyResponseDTO, ManufacturingAssemblyDetailsDTO } from '@luminovo/http-client';
import { useAssemblies } from '../../../resources/assembly/assemblyHandler';
import { TextBreadcrumbWithAssemblyId, useAllBreadcrumbs } from '../../../resources/breadcrumbs/breadcrumbsHandler';
import { useSuspenseHttpQuery } from '../../../resources/http/useHttpQuery';
import { notUndefined } from '../../../utils/typingUtils';
import { AssemblyOverviewBlankCardProps, AssemblyOverviewCardProps } from './AssemblyOverviewCard';

const getParentAssemblyDesignators = (
    assemblyId: string,
    assemblyIdsWithDesignator: Map<string, string>,
    breadcrumbs: TextBreadcrumbWithAssemblyId[] | undefined,
) => {
    const parentAssemblyIds = breadcrumbs?.find((b) => b.id === assemblyId)?.assemblies;
    const parentAssemblyDesignators =
        parentAssemblyIds?.map((id) => {
            return assemblyIdsWithDesignator.get(id);
        }) ?? [];
    return parentAssemblyDesignators.filter(notUndefined);
};

const convertAssembliesToCardProps = (
    rfqId: string,
    assemblies: AssemblyResponseDTO[],
    breadcrumbs: TextBreadcrumbWithAssemblyId[] | undefined,
    details: ManufacturingAssemblyDetailsDTO[],
): (AssemblyOverviewBlankCardProps | AssemblyOverviewCardProps)[] => {
    const assemblyIdsWithDesignator = new Map(assemblies.map((a) => [a.id, a.designator]));
    const assemblyOverviews: (AssemblyOverviewBlankCardProps | AssemblyOverviewCardProps)[] = assemblies
        .filter((assembly) => assembly.id !== rfqId) // we do not want to show the BOM in the assembly overview
        .map((assembly) => {
            const assemblyDetails = details.find((detail) => detail.assembly_id === assembly.id);
            if (assemblyDetails) {
                //the type had to be explicitly declared here. If it wasn't and we returned the overview,
                // then there would have been no typescript errors.
                const assemblyOverview: AssemblyOverviewCardProps = {
                    assemblyType: assembly.type.type,
                    designator: assembly.designator,
                    parentAssemblies: getParentAssemblyDesignators(assembly.id, assemblyIdsWithDesignator, breadcrumbs),
                    manufacturingAssemblyDetailsId: assemblyDetails.id,
                    rfqId,
                    assemblyId: assembly.id,
                };
                return assemblyOverview;
            } else {
                //the type had to be explicitly declared here. If it wasn't and we returned the overview straight away,
                // then there would have been no typescript errors.
                const assemblyOverview: AssemblyOverviewBlankCardProps = {
                    rfqId,
                    assemblyType: assembly.type.type,
                    designator: assembly.designator,
                    assemblyId: assembly.id,
                    parentAssemblies: getParentAssemblyDesignators(assembly.id, assemblyIdsWithDesignator, breadcrumbs),
                    manufacturingAssemblyDetailsId: undefined,
                };
                return assemblyOverview;
            }
        });
    return assemblyOverviews;
};

export const useAssemblyOverviewPage = (rfqId: string) => {
    const { breadcrumbs, isLoading: isBreadcrumbsLoading } = useAllBreadcrumbs(rfqId);
    const allAssemblyIds = breadcrumbs?.map((bc) => bc.id);
    const { data: assemblies = [], isLoading: isLoadingAssemblies } = useAssemblies(allAssemblyIds);
    const { data: allManufacturingDetailsData, isLoading } = useSuspenseHttpQuery(
        'GET /manufacturing-assembly-details',
        {
            queryParams: { rfq_id: rfqId },
        },
        {},
    );
    const allManufacturingDetails = allManufacturingDetailsData?.data ?? [];
    return {
        isLoading: isLoading || isBreadcrumbsLoading || isLoadingAssemblies,
        assemblyOverviews: convertAssembliesToCardProps(rfqId, assemblies, breadcrumbs, allManufacturingDetails),
    };
};
