import { plural } from '@lingui/macro';
import { Flexbox, StickyLayout, colorSystem } from '@luminovo/design-system';
import { RowRecord, SingleOriginalExcelRow } from '@luminovo/http-client';
import { Box, Table, TableBody, TableCell, TableContainer, TableRow, Typography, styled } from '@mui/material';
import React from 'react';
import { BomImporterIssue, IssueNameEnum } from '../../../../resources/bomImporter/bomImporterIssuesEnum';
import { themeLuminovo } from '../../../../themes';
import { HighlightableString } from '../../../../utils/highlighting/highlightableString';
import {
    combineHighlightablesIntoOne,
    highlightNonOverlappingSubstringOccurrences,
} from '../../../../utils/highlighting/mpnHighlighting';
import { parseColumns } from '../../../BomImporter/parseColumns';
import { ColumnTagsSheetsAction, ColumnTagsSheetsState } from '../AutocompleteColumnTags/types';
import PaginationButtons from '../PaginationButtons';
import { parseRawOriginalLine } from '../parseRawOriginalLine';
import { BomImporterTableHeaderRow } from './BomTableHeader';
import { topBoxId } from './const';

interface Props {
    headerRowJson: RowRecord | undefined;
    excelRows: SingleOriginalExcelRow[];
    candidateMPNsForHighlights?: string[];
    /**
     * If present, will show the column tag mappings.
     */
    columnTags?: {
        tagsState: ColumnTagsSheetsState;
        dispatch: React.Dispatch<ColumnTagsSheetsAction>;
    };
    haveBomImporterLinesChanged?: boolean;
    setHaveBomImporterLinesChanged?: React.Dispatch<React.SetStateAction<boolean>>;
    showLineNumberColumn?: boolean;
    isEditable?: boolean;
}

interface HighlightedExcelRows {
    lineNumber: number | undefined;
    cellContents: undefined | HighlightableString[];
    issues: BomImporterIssue[];
}

const NAV_BAR_HEIGHT = '52px';
const ScrollableContainer = styled(Box)({
    padding: '16px 16px 0 16px',
    boxSizing: 'border-box',
    height: `calc(100vh - ${NAV_BAR_HEIGHT})`,
    width: '100vw ',
    maxWidth: '100vw',
    maxHeight: `calc(100vh - ${NAV_BAR_HEIGHT} - 100px)`,
    overflow: 'auto',
});

function toHighlightableString(str: string | HighlightableString): HighlightableString {
    return typeof str === 'string' ? [{ fragment: str, isHighlighted: false }] : str;
}

function BomTableCell({ content }: { content: HighlightableString }) {
    return (
        <TableCell>
            {toHighlightableString(content).map(({ fragment, isHighlighted }, i) => (
                <Typography
                    key={i}
                    style={{
                        color: isHighlighted ? themeLuminovo.palette.primary.main : colorSystem.neutral[9],
                    }}
                    variant={'subtitle1'}
                >
                    {fragment}{' '}
                </Typography>
            ))}
        </TableCell>
    );
}

const BomTableRow = ({
    cellContents,
    lineNumber,
    issues,
}: {
    lineNumber?: number;
    cellContents: HighlightableString[];
    issues?: BomImporterIssue[];
}) => {
    const hasRowIssues = issues ? issues.length > 0 : false;
    return (
        <TableRow style={{ background: hasRowIssues ? colorSystem.red[1] : '' }}>
            {lineNumber !== undefined && (
                <TableCell>
                    <Typography>{lineNumber}</Typography>
                </TableCell>
            )}
            {cellContents.map((content, key: number) => {
                return <BomTableCell key={key} content={content} />;
            })}
        </TableRow>
    );
};

// The table & its container are rotated by 180 degrees so that the scrollbar is moved to the top of the table.
const rotationAngle = 'rotateX(180deg)';

const StyledTableContainer = styled(TableContainer)({
    overflow: 'invisible',
    transform: rotationAngle,
});

const border = `1px solid ${colorSystem.neutral[2]}`;
const cellWidth = 150;

const StyledTable = styled(Table)({
    borderTop: border,
    borderLeft: border,
    transform: rotationAngle,
    '& td': {
        borderBottom: border,
        borderRight: border,
        width: cellWidth,
        maxWidth: cellWidth,
        minWidth: cellWidth,
        overflowWrap: 'break-word',
        verticalAlign: 'top',
    },
    '& th': {
        borderBottom: border,
        borderRight: border,
        width: cellWidth,
        maxWidth: cellWidth,
        minWidth: cellWidth,
        overflowWrap: 'break-word',
        position: 'sticky',
        // The top is needed for sticky scrolling
        top: 0,
        // The background is needed for sticky scrolling
        background: colorSystem.neutral.white,
        borderRadius: '4px',
    },
    background: colorSystem.neutral.white,
    borderSpacing: 0,
    borderCollapse: 'separate',
    margin: 0,
    borderRadius: '4px',
});

function highlightNothing(str: string): HighlightableString {
    return [
        {
            fragment: str,
            isHighlighted: false,
        },
    ];
}

function highlightCandidateMPNsInExcelRow({
    cellContents,
    candidateMPNs,
}: {
    cellContents: string[];
    candidateMPNs: string[];
}): HighlightableString[] {
    return cellContents.map((content: string): HighlightableString => {
        const highlightables: HighlightableString[] = candidateMPNs.map((candidateMPN) =>
            highlightNonOverlappingSubstringOccurrences({
                str: content,
                query: candidateMPN,
                caseSensitive: false,
            }),
        );
        const highlightable = combineHighlightablesIntoOne(highlightables);
        return highlightable.length > 0 ? highlightable : highlightNothing(content);
    });
}

function getBomLinesAndCountOfLinesSkipped(
    paginatedExcelRows: HighlightedExcelRows[],
): (HighlightedExcelRows | number)[] {
    let linesSkipped = 0;
    const resultsList: (HighlightedExcelRows | number)[] = [];

    paginatedExcelRows.forEach((row) => {
        const hasLineError = row.issues.some((issue) => issue.name === IssueNameEnum.DUPLICATE_DESIGNATORS_FOUND);
        const numberOfLinesSkippedToDisplay = linesSkipped;

        if (hasLineError) {
            linesSkipped = 0;

            numberOfLinesSkippedToDisplay > 0 && resultsList.push(numberOfLinesSkippedToDisplay);
            resultsList.push(row);
        } else {
            linesSkipped += 1;
        }
    });

    linesSkipped > 0 && resultsList.push(linesSkipped);

    return resultsList;
}

export const BomTable = ({
    headerRowJson,
    excelRows,
    candidateMPNsForHighlights,
    columnTags,
    haveBomImporterLinesChanged,
    setHaveBomImporterLinesChanged,
    showLineNumberColumn = false,
    isEditable,
}: Props) => {
    const highlightedExcelRows: HighlightedExcelRows[] = excelRows.map((row) => {
        const parsedRow: string[] | undefined = parseRawOriginalLine(row.raw_original_line);
        const lineNumber = showLineNumberColumn ? row.line_number : undefined;
        if (parsedRow === undefined) {
            return {
                lineNumber,
                cellContents: undefined,
                issues: row.issues,
            };
        }
        return {
            lineNumber,
            cellContents: highlightCandidateMPNsInExcelRow({
                cellContents: parsedRow,
                candidateMPNs: candidateMPNsForHighlights ?? [],
            }),
            issues: row.issues,
        };
    });

    const lineNumberColumn = showLineNumberColumn ? [{ id: '-1', label: '' }] : [];
    const columns = lineNumberColumn.concat(
        parseColumns({
            rawHeaderRow: headerRowJson,
            excelRows,
        }),
    );

    const hasBomIssues = excelRows.flatMap((row) => row.issues).length > 0;
    const excelLinesIfHasBomIssues = getBomLinesAndCountOfLinesSkipped(highlightedExcelRows);

    const linesPerPage = 100;
    const [pagination, setPagination] = React.useState({ start: 0, end: linesPerPage });
    const isPaginationNeeded = hasBomIssues
        ? excelLinesIfHasBomIssues.length > linesPerPage
        : excelRows.length > linesPerPage;
    const paginatedHighlightedExcelLines = hasBomIssues
        ? excelLinesIfHasBomIssues.slice(pagination.start, pagination.end)
        : highlightedExcelRows.slice(pagination.start, pagination.end);
    return (
        <>
            <ScrollableContainer>
                <StyledTableContainer>
                    <StyledTable size="small">
                        <BomImporterTableHeaderRow
                            columns={columns}
                            haveBomImporterLinesChanged={haveBomImporterLinesChanged}
                            setHaveBomImporterLinesChanged={setHaveBomImporterLinesChanged}
                            tagsState={columnTags?.tagsState}
                            dispatch={columnTags?.dispatch}
                            isEditable={isEditable}
                        />
                        <TableBody>
                            {paginatedHighlightedExcelLines.map((row, key) => {
                                return typeof row === 'number' ? (
                                    <React.Fragment key={key}>
                                        <TableRow>
                                            <TableCell colSpan={columns.length}>
                                                <Typography color={'textSecondary'}>
                                                    {plural(row, {
                                                        one: `# row skipped`,
                                                        other: `# rows skipped`,
                                                    })}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    </React.Fragment>
                                ) : (
                                    row.cellContents && (
                                        <React.Fragment key={key}>
                                            <BomTableRow
                                                lineNumber={row.lineNumber}
                                                cellContents={row.cellContents}
                                                issues={row.issues}
                                            />
                                        </React.Fragment>
                                    )
                                );
                            })}
                        </TableBody>
                    </StyledTable>
                </StyledTableContainer>
                {isPaginationNeeded && (
                    <StickyLayout
                        top="unset"
                        left="unset"
                        bottom="0"
                        right="0"
                        style={{ background: colorSystem.neutral[1] }}
                    >
                        <Flexbox alignItems="center" justifyContent="flex-end">
                            <PaginationButtons
                                pagination={pagination}
                                setPagination={setPagination}
                                topBoxId={topBoxId}
                                numberOfRows={excelRows.length}
                                linesPerPage={linesPerPage}
                            />
                        </Flexbox>
                    </StickyLayout>
                )}
            </ScrollableContainer>
        </>
    );
};
