import { t } from '@lingui/macro';
import { formatDecimal, formatRelativeTime, isPresent } from '@luminovo/commons';
import {
    colorSystem,
    FieldDateControlled,
    FieldTextControlled,
    Flexbox,
    FormItem,
    PrimaryButton,
    Tag,
    Text,
    Toolbar,
} from '@luminovo/design-system';
import { formatSupplierDTO } from '@luminovo/sourcing-core';
import { Box, Divider } from '@mui/material';
import { useFormContext, useFormState, useWatch } from 'react-hook-form';
import { FormContainer } from '../../../../../components/formLayouts/FormContainer';
import { SubmitButton } from '../../../../../components/formLayouts/SubmitButton';
import { LayoutCard } from '../../../../../components/LayoutCard';
import { PageLayoutCollapsibleSidebar } from '../../../../../components/PageLayoutCollapsibleSidebar';
import { useHttpMutation } from '../../../../../resources/mutation/useHttpMutation';
import { route, UrlParams } from '../../../../../utils/routes';
import { ButtonDeleteQuoteRequest } from '../../../../Negotiations/components/ButtonDeleteQuoteRequest';
import { ButtonDownloadQuoteRequestExcel } from '../../../../Negotiations/components/ButtonDownloadQuoteRequestExcel';
import { TableQuoteRequestLineItemsForm } from '../../../../Negotiations/components/TableQuoteRequestLineItemsForm';
import {
    useQuoteRequestLineItemsByQuoteRequest,
    useSuspendedQuoteRequest,
} from '../../../../Negotiations/hooks/negotiationHandlers';
import { NavigationSidebarSourcing } from '../../NavigationSidebarSourcing';

type FormValues = {
    dueDate: string;
    notes: string;
};

function InnerForm({ quoteRequestId }: { quoteRequestId: string }) {
    const { data: quoteRequest } = useSuspendedQuoteRequest(quoteRequestId);

    const defaultValues: FormValues = {
        dueDate: quoteRequest.due_date ?? '',
        notes: quoteRequest.notes ?? '',
    };

    const { data: quoteRequestLineItems } = useQuoteRequestLineItemsByQuoteRequest(quoteRequest.id);

    const { mutateAsync } = useHttpMutation('PATCH /quote-request/:id', {
        snackbarMessage: 'Quote request updated',
    });

    const onSubmit = (values: FormValues) => {
        mutateAsync({
            pathParams: { id: quoteRequest.id },
            requestBody: {
                // eslint-disable-next-line camelcase
                due_date: Boolean(values.dueDate) ? values.dueDate : null,
                notes: Boolean(values.notes) ? values.notes : null,
            },
        });
    };

    return (
        <FormContainer defaultValues={defaultValues} onSubmit={onSubmit}>
            <Box display="grid" gridTemplateColumns="300px 5fr" gap={'16px'} height={'100%'}>
                <Flexbox flexDirection="column" gap="16px">
                    <LayoutCard>
                        <Flexbox justifyContent="space-between" gap={8}>
                            <Text variant="h3" showEllipsis>
                                {formatSupplierDTO(quoteRequest.supplier)}
                            </Text>
                            <Tag attention="low" color={'neutral'} label={`#${quoteRequest.number}`} />
                        </Flexbox>
                        <Flexbox flexDirection="column" rowGap={1}>
                            <Text variant="body-small" color={colorSystem.neutral[8]}>{t`Line items to be sent`}</Text>
                            <Text variant="h4">{formatDecimal(quoteRequestLineItems?.length)}</Text>
                        </Flexbox>
                        <Divider />
                        <FormItemDueDate />
                        <FormItemNotes />
                        <SubmitButton size="small" label={t`Save`} />
                    </LayoutCard>
                </Flexbox>

                <LayoutCard style={{ height: '80vh' }}>
                    <TableQuoteRequestLineItemsForm lineItems={quoteRequestLineItems} />
                </LayoutCard>
            </Box>
        </FormContainer>
    );
}

function FormItemDueDate() {
    const { control } = useFormContext<FormValues>();
    const { errors } = useFormState<FormValues>();
    const dueDate = useWatch({ control, name: 'dueDate' });

    return (
        <FormItem label={t`Due date`}>
            <FieldDateControlled
                control={control}
                name="dueDate"
                inFuture
                minDate={new Date().toISOString()}
                FieldProps={{
                    placeholder: 'Select due date...',
                }}
            />
            <Text>{!isPresent(errors.dueDate) && Boolean(dueDate) && formatRelativeTime(dueDate)}</Text>
        </FormItem>
    );
}

function FormItemNotes() {
    const { control } = useFormContext<FormValues>();
    return (
        <FormItem label={t`Notes`}>
            <FieldTextControlled
                control={control}
                name="notes"
                FieldProps={{
                    placeholder: 'Add notes...',
                    multiline: true,
                    minRows: 2,
                }}
            />
        </FormItem>
    );
}

function ButtonSendQuoteRequests({
    quoteRequestId,
    negotiationId,
    rfqId,
}: {
    quoteRequestId: string;
    negotiationId: number;
    rfqId: string;
}) {
    const { data: quoteRequest } = useSuspendedQuoteRequest(quoteRequestId);

    return (
        <PrimaryButton
            size="medium"
            href={route(
                '/rfqs/:rfqId/sourcing/negotiations/:negotiationId/quote-requests/preview-email',
                {
                    rfqId,
                    negotiationId,
                },
                {
                    // TODO: This might break when to many quote requests are selected because we store it in the URL
                    quoteRequestNumbers: quoteRequest.number.toString(),
                },
            )}
        >
            {'Send request'}
        </PrimaryButton>
    );
}

function ToolbarInner({
    rfqId,
    negotiationId,
    quoteRequestId,
}: {
    rfqId: string;
    negotiationId: number;
    quoteRequestId: string;
}) {
    return (
        <Toolbar
            breadcrumbs={[
                {
                    title: `Sourcing negotiation`,
                    href: route(`/rfqs/:rfqId/sourcing/negotiations/:negotiationId/quote-requests`, {
                        rfqId,
                        negotiationId,
                    }),
                },
                {
                    title: `Quote request`,
                },
            ]}
        >
            <ButtonDeleteQuoteRequest quoteRequestId={quoteRequestId} />
            <ButtonDownloadQuoteRequestExcel quoteRequestId={quoteRequestId} />
            <ButtonSendQuoteRequests rfqId={rfqId} negotiationId={negotiationId} quoteRequestId={quoteRequestId} />
        </Toolbar>
    );
}

export function SourcingNegotiationQuoteRequestDetailsPage({
    pathParams,
}: UrlParams<'/rfqs/:rfqId/sourcing/negotiations/:negotiationId/quote-requests/:quoteRequestId'>) {
    const { rfqId } = pathParams;
    const negotiationId = Number(pathParams.negotiationId);
    const quoteRequestId = pathParams.quoteRequestId;
    return (
        <PageLayoutCollapsibleSidebar
            sidebar={<NavigationSidebarSourcing rfqId={rfqId} />}
            header={<ToolbarInner rfqId={rfqId} negotiationId={negotiationId} quoteRequestId={quoteRequestId} />}
            layoutVariant="fullWidth"
        >
            <InnerForm quoteRequestId={quoteRequestId} />
        </PageLayoutCollapsibleSidebar>
    );
}
