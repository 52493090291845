import { t, Trans } from '@lingui/macro';
import { isGermanLocale } from '@luminovo/commons';
import {
    colorSystem,
    CrosshairIcon,
    Flexbox,
    MaxWidthLayout,
    PrimaryButton,
    TwinkleStarsIcon,
} from '@luminovo/design-system';
import { FastForward } from '@mui/icons-material';
import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { PageLayout } from '../../../../components/PageLayout';
import { TeaserCard } from './TeaserCard';

const getSurveyLink = (): string => {
    const LUMIQUOTE_MANUFACTURING_SURVEY_FORM_ENGLISH =
        'https://docs.google.com/forms/d/e/1FAIpQLScKnPZ0IKBJD6BxswEd79fV7TF89PRTQ4rX-DPEjFJUXACJkw/viewform?usp=sf_link';
    const LUMIQUOTE_MANUFACTURING_SURVEY_FORM_GERMAN =
        'https://docs.google.com/forms/d/e/1FAIpQLScVeq_3sqrtETv5-iixNE74urAwVYj6F6KFatlWZLhd7l3yFQ/viewform?usp=sf_link';
    if (isGermanLocale()) {
        return LUMIQUOTE_MANUFACTURING_SURVEY_FORM_GERMAN;
    } else {
        return LUMIQUOTE_MANUFACTURING_SURVEY_FORM_ENGLISH;
    }
};

const ManufacturingTeaserPage: React.FunctionComponent = (): JSX.Element => {
    return (
        <PageLayout style={{ backgroundColor: colorSystem.neutral[1] }}>
            <MaxWidthLayout>
                <Box marginTop={'96px'} marginBottom={'56px'}>
                    <Typography variant="h1" align="center" style={{ fontSize: '24px', color: colorSystem.neutral[9] }}>
                        <Trans>Available now: our manufacturing module</Trans>
                    </Typography>
                </Box>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={4}>
                        <TeaserCard
                            icon={<TwinkleStarsIcon />}
                            title={t`Simple`}
                            text={t`Simply select the activities and expenses relevant to you using provided templates and automated suggestions.`}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TeaserCard
                            icon={<FastForward color="primary" />}
                            title={t`Fast`}
                            text={t`Accelerate your manufacturing costing calculation by a seamless and automated integration of relevant BOM parameters.`}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TeaserCard
                            icon={<CrosshairIcon />}
                            title={t`Accurate`}
                            text={t`No more guessing needed - with our sophisticated manufacturing module you are able to precisely calculate your manufacturing costs.`}
                        />
                    </Grid>
                </Grid>
                <Flexbox alignItems="center" justifyContent="center" gap={'8px'} marginTop={'64px'}>
                    <PrimaryButton onClick={() => window.open(getSurveyLink(), '_blank')}>
                        <Trans>Interested? Sign up here</Trans>
                    </PrimaryButton>
                </Flexbox>
            </MaxWidthLayout>
        </PageLayout>
    );
};

export default ManufacturingTeaserPage;
