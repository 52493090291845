import { t, Trans } from '@lingui/macro';
import { id } from '@luminovo/commons';
import { Card, colorSystem, Flexbox, SecondaryButton } from '@luminovo/design-system';
import { ManufacturingAssemblyDetailsOverviewDTO } from '@luminovo/http-client';
import {
    Add as AddIcon,
    KeyboardArrowDown as KeyboardArrowDownIcon,
    KeyboardArrowRight as KeyboardArrowRightIcon,
} from '@mui/icons-material';
import { Box, Button, Collapse, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { useParams } from 'react-router';
import { ManufacturingCacheRefreshButton } from '../../../../../components/ManufacturingCache/ManufacturingCacheRefreshButton';
import { ManufacturingCacheInformationText } from '../../../../../components/ManufacturingCache/ManufacturingCacheText';
import { useIsCalculationModuleActive, useManufacturingModuleVariant } from '../../../../../featureFlags';
import { useAddManufacturingScenarioDialog } from '../../../ManufacturingScenario/ManufacturingScenarioForm';
import { ExportManufacturingButton } from './components/ExportManufacturingButton';
import { ScenarioCombinationForCalculationDialog } from './components/ScenarioCombinationForCalculationDialog';
import ManufacturingScenarioTable from './ManufacturingAssemblyScenarioTable';

const AddManufacturingScenarioButton = ({ rfqId, assemblyId }: { rfqId: string; assemblyId: string }) => {
    const { openDialog } = useAddManufacturingScenarioDialog({ rfqId, assemblyId });
    return (
        <SecondaryButton startIcon={<AddIcon />} onClick={openDialog} size="medium">
            <Trans>Add scenario</Trans>
        </SecondaryButton>
    );
};

const ManufacturingScenarioTableCard = ({
    hasDriversFromSourcing,
    manufacturingAssemblyDetailsOverview,
    assemblyId,
}: {
    hasDriversFromSourcing: boolean;
    manufacturingAssemblyDetailsOverview: ManufacturingAssemblyDetailsOverviewDTO;
    assemblyId: string;
}) => {
    const [showManufacturingScenarioTable, setShowManufacturingScenarioTable] = React.useState(true);
    const [showSourcingCacheText, setShowSourcingCacheText] = React.useState(false);

    const { manufacturingAssemblyDetailsId, rfqId } = useParams<{
        manufacturingAssemblyDetailsId: string;
        rfqId: string;
    }>();

    const { isCalculationModuleActive } = useIsCalculationModuleActive();
    const isManufacturingFullVariant = useManufacturingModuleVariant() === 'full';

    const handleCollapseClick = () => {
        setShowManufacturingScenarioTable(!showManufacturingScenarioTable);
    };

    const StyledButton = styled(Button)({
        padding: 0,
        '&:hover': {
            backgroundColor: 'inherit',
        },
    });

    const StyledTableWrapper = styled(Box)({
        overflow: 'hidden',
        overflowX: 'scroll',
        // eslint-disable-next-line spellcheck/spell-checker
        maxWidth: 'calc(100vw - 98px)',
    });

    return (
        <Card maxWidth="100%" gap={0} id={id('manufacturing_scenario/manufacturing_scenarios_table_card')}>
            <Flexbox justifyContent="space-between">
                <Flexbox gap={8} alignItems="center">
                    <StyledButton disableRipple disableFocusRipple variant="text" onClick={handleCollapseClick}>
                        <Flexbox gap={4} alignItems="center">
                            {showManufacturingScenarioTable ? (
                                <KeyboardArrowDownIcon style={{ color: colorSystem.neutral[8] }} />
                            ) : (
                                <KeyboardArrowRightIcon style={{ color: colorSystem.neutral[8] }} />
                            )}
                            <Typography variant="h2" style={{ color: colorSystem.neutral[8] }}>
                                <Trans>Manufacturing scenarios</Trans>
                            </Typography>
                        </Flexbox>
                    </StyledButton>
                    {hasDriversFromSourcing && showSourcingCacheText && (
                        <>
                            <ManufacturingCacheRefreshButton assemblyId={assemblyId} />
                            <ManufacturingCacheInformationText assemblyId={assemblyId} />
                        </>
                    )}
                </Flexbox>
                <Flexbox gap={'16px'}>
                    <AddManufacturingScenarioButton rfqId={rfqId} assemblyId={assemblyId} />

                    <ExportManufacturingButton
                        text={t`Export all`}
                        manufacturingAssemblyDetailsId={manufacturingAssemblyDetailsId}
                    />

                    {isManufacturingFullVariant && isCalculationModuleActive && (
                        <ScenarioCombinationForCalculationDialog assemblyId={assemblyId} />
                    )}
                </Flexbox>
            </Flexbox>
            <Collapse in={showManufacturingScenarioTable}>
                <StyledTableWrapper id={id('manufacturing_scenario/manufacturing_scenarios_table')}>
                    <ManufacturingScenarioTable
                        manufacturingAssemblyDetailsOverview={manufacturingAssemblyDetailsOverview}
                        setShowSourcingCacheText={setShowSourcingCacheText}
                    />
                </StyledTableWrapper>
            </Collapse>
        </Card>
    );
};

export default ManufacturingScenarioTableCard;
