/* eslint-disable spellcheck/spell-checker */
/* eslint-disable spellcheck/spell-checker */
import { t, Trans } from '@lingui/macro';
import { isPresent, logToExternalErrorHandlers, uniq } from '@luminovo/commons';
import {
    colorSystem,
    Dialog,
    DialogContent,
    DialogTitle,
    FieldMultiSelectControlled,
    Flexbox,
    SecondaryButton,
    TertiaryButton,
    TertiaryIconButton,
    Text,
    XlsIcon,
    ZipIcon,
} from '@luminovo/design-system';
import {
    CurrentUserDetailsDTO,
    CustomerDTO,
    EmailTemplateDTO,
    PartDTO,
    QuotePriceBulkRequestBodyDTO,
    RfqDTO,
    SendQuotePriceRequestBodyDTO,
    SourcingScenarioDTO,
    SupplierAndStockLocationDTO,
    SupplierContactDTO,
    SupplierTag,
    SupportedPartsDTO,
    UserDTO,
} from '@luminovo/http-client';
import { formatSupplierAndStockLocationDTO, hasSupplierTag } from '@luminovo/sourcing-core';
import {
    GetApp as GetAppIcon,
    InfoOutlined as InfoOutlinedIcon,
    OpenInNewRounded as OpenInNewRoundedIcon,
} from '@mui/icons-material';
import { Box, ListItem, styled } from '@mui/material';
import * as React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useHistory } from 'react-router';
import { useCurrentUserDetailsContext } from '../../../../components/contexts/CurrentUserDetailsContext';
import { useDialogContext } from '../../../../components/contexts/ModalContext';
import { FormContainer } from '../../../../components/formLayouts/FormContainer';
import { SubmitButton } from '../../../../components/formLayouts/SubmitButton';
import { useCustomer } from '../../../../resources/customer/customerHandler';
import { useDownloadSupplierQuotePriceTemplate } from '../../../../resources/export/exportHandler';
import { useSuspenseHttpQuery } from '../../../../resources/http/useHttpQuery';
import { useHttpMutation } from '../../../../resources/mutation/useHttpMutation';
import { useRfQ } from '../../../../resources/rfq/rfqHandler';
import { useSupportedPartsOfSupplier } from '../../../../resources/supplier/supplierHandler';
import {
    useSupplierContacts,
    useSupplierContactsFromSupplier,
} from '../../../../resources/supplierContact/supplierContactHandler';
import { useAllOrganizationUsers } from '../../../../resources/user/userHandler';
import { route } from '../../../../utils/routes';
import { FormItemBody } from './components/FormItemBody';
import { FormItemEmailTemplate } from './components/FormItemEmailTemplate';
import { FormItemSubject } from './components/FormItemSubject';
import { useConfirmInviteSupplierContact } from './components/useConfirmInviteSupplierContact';
import { getQuoteRequestEmailTemplateBySupplier } from './getQuoteRequestEmailTemplateBySupplier';
import { renderQuoteRequestTemplate, RenderQuoteRequestTemplateProps } from './renderQuoteRequestTemplate';
import { EmailBodyInformation, EmailManagerFormState, SupplierEmail } from './types';

const StyledOpenInNewRoundedIcon = styled(OpenInNewRoundedIcon)({
    color: colorSystem.neutral[7],
    fontSize: '16px',
    lineHeight: '20px',
});

type InitialState = {
    rfqId: string;
    partIds: PartDTO[];
    suppliersAndStockLocations: SupplierAndStockLocationDTO[];
    sourcingScenarios: SourcingScenarioDTO[];
    shouldFilterByLineCard: boolean;
    generateAllQuantityCombinations: boolean;
    emailBodyInformation: EmailBodyInformation;
};

const StyledInfoOutlinedIconNeutral = styled(InfoOutlinedIcon)({
    color: colorSystem.neutral[6],
    fontSize: '20px',
    lineHeight: '24px',
});

const SupplierListItem: React.FunctionComponent<{
    supplierEmail: SupplierEmail;
    isSelected: boolean;
}> = ({ supplierEmail, isSelected }) => {
    const { setValue } = useFormContext<EmailManagerFormState>();
    const { supplierAndStockLocation, supplierContacts, supportedParts } = supplierEmail;
    const firstSupplierContact = supplierContacts.find((_, index) => index === 0);

    return (
        <ListItem
            key={supplierAndStockLocation.id}
            onClick={() => setValue('selectedSupplier', supplierAndStockLocation)}
            style={{ padding: 0, backgroundColor: isSelected ? colorSystem.primary[1] : undefined }}
        >
            <Flexbox
                flexDirection={'column'}
                gap={8}
                marginX={'24px'}
                paddingY={'20px'}
                width={'100%'}
                borderBottom={`1px solid ${colorSystem.neutral[2]}`}
            >
                <Flexbox justifyContent="space-between">
                    <Text
                        variant={'h4'}
                        color={isSelected ? colorSystem.primary[7] : colorSystem.neutral[8]}
                        style={{
                            maxWidth: '200px',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}
                    >
                        {formatSupplierAndStockLocationDTO(supplierAndStockLocation)}
                    </Text>
                    <Text variant={'body-small'} color={colorSystem.neutral[7]}>{`${supportedParts.length} ${
                        hasSupplierTag(supplierAndStockLocation, SupplierTag.PcbSupplier) ? t`PCB(s)` : t`part(s)`
                    }`}</Text>
                </Flexbox>

                {isPresent(firstSupplierContact) && (
                    <Text variant={'body-small'} color={isSelected ? colorSystem.primary[7] : colorSystem.neutral[8]}>
                        {`${firstSupplierContact.email}  •  ${firstSupplierContact.user_language.toLocaleUpperCase()}${
                            supplierContacts.length > 1 ? ` +${supplierContacts.length - 1}` : ''
                        }`}
                    </Text>
                )}
            </Flexbox>
        </ListItem>
    );
};

const SupplierList: React.FunctionComponent<{}> = () => {
    const { control } = useFormContext<EmailManagerFormState>();

    const selectedSupplier = useWatch({
        control: control,
        name: 'selectedSupplier',
    });

    const supplierEmails = useWatch({
        control: control,
        name: 'supplierEmails',
    });

    return (
        <Flexbox flexDirection={'column'} borderRight={`1px solid ${colorSystem.neutral[2]}`}>
            <Flexbox paddingX={'24px'} paddingY={'20px'} borderBottom={`1px solid ${colorSystem.neutral[2]}`}>
                <Text variant={'h2'} color={colorSystem.neutral[8]}>
                    <Trans>Recipients</Trans>
                </Text>
            </Flexbox>
            <Flexbox flexDirection={'column'} height={'60vh'} style={{ overflowY: 'auto' }}>
                {supplierEmails.map((supplierEmail, key) => (
                    <SupplierListItem
                        key={key}
                        supplierEmail={supplierEmail}
                        isSelected={selectedSupplier?.id === supplierEmail.supplierAndStockLocation.id}
                    />
                ))}
            </Flexbox>
        </Flexbox>
    );
};

const FormItemRecipient = ({ index, supplierEmail }: { index: number; supplierEmail: SupplierEmail }) => {
    const { control } = useFormContext<EmailManagerFormState>();

    const { data: supplierContacts = [] } = useSupplierContactsFromSupplier(
        supplierEmail.supplierAndStockLocation.supplier.id,
        {
            refetchOnWindowFocus: true,
        },
    );

    return (
        <>
            <Text variant={'h4'} color={colorSystem.neutral[6]}>
                <Trans>Recipient</Trans>
            </Text>
            <FieldMultiSelectControlled
                control={control}
                name={`supplierEmails.${index}.supplierContacts`}
                FieldProps={{
                    options: supplierContacts,
                    fullWidth: true,
                    disableClearable: true,
                    getOptionKey: (supplierContact) => supplierContact.id,
                    getOptionLabel: (supplierContact) =>
                        isPresent(supplierContact)
                            ? `${supplierContact.first_name} ${supplierContact.last_name}`
                            : t`No recipient selected`,
                    isOptionEqualToValue: (opt, value) => opt.id === value.id,
                }}
            />
        </>
    );
};

const FormItemCarbonCopy = ({ index, supplierEmail }: { index: number; supplierEmail: SupplierEmail }) => {
    const { control, setValue } = useFormContext<EmailManagerFormState>();

    const { data: internalUsers = [] } = useAllOrganizationUsers();
    const { data: supplierContacts = [] } = useSupplierContactsFromSupplier(
        supplierEmail.supplierAndStockLocation.supplier.id,
        {
            refetchOnWindowFocus: true,
        },
    );

    const recipient = useWatch({
        control: control,
        name: `supplierEmails.${index}.supplierContacts`,
    });

    const carbonCopy = useWatch({
        control: control,
        name: `supplierEmails.${index}.carbonCopy`,
    });

    React.useEffect(() => {
        setValue(
            `supplierEmails.${index}.carbonCopy`,
            carbonCopy.filter((cc) => !recipient.some((r) => r.id === cc.id)),
        );
        // We only want to reset the form when the recipient changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recipient]);

    return (
        <>
            <Text variant={'h4'} color={colorSystem.neutral[6]}>
                <Trans>Cc</Trans>
            </Text>
            <FieldMultiSelectControlled
                control={control}
                name={`supplierEmails.${index}.carbonCopy`}
                FieldProps={{
                    size: 'medium',
                    options: [...supplierContacts, ...internalUsers],
                    getOptionKey: (supplierContact) => supplierContact.id,
                    getOptionLabel: (supplierContact) => `${supplierContact.first_name} ${supplierContact.last_name}`,
                    isOptionEqualToValue: (opt, value) => opt.id === value.id,
                    getOptionDisabled: (opt) => recipient.some((r) => r.id === opt.id),
                    fullWidth: true,
                    disableCloseOnSelect: true,
                    placeholder: carbonCopy.length === 0 ? t`No other recipients selected` : '',
                    groupBy: (opt) => {
                        if (supplierContacts.some((s) => s.id === opt.id)) {
                            return t`Supplier contacts`;
                        }
                        if (internalUsers.some((s) => s.id === opt.id)) {
                            return t`Internal contributors`;
                        }
                        return t`Other contacts`;
                    },
                }}
            />
        </>
    );
};

const FormTitle = ({ index, supplierEmail }: { index: number; supplierEmail: SupplierEmail }) => {
    const handleOpenSupplierPage = () => {
        window.open(
            route('/supplier/:supplierAndStockLocationId', {
                supplierAndStockLocationId: supplierEmail.supplierAndStockLocation.id,
            }),
            '_blank',
            'noopener noreferrer',
        );
    };

    return (
        <Flexbox alignItems={'center'} justifyContent="space-between">
            <Text variant={'h3'} color={colorSystem.neutral[8]}>
                <Trans>Email</Trans>
            </Text>

            <Flexbox gap={4} alignItems={'center'}>
                <TertiaryButton
                    size="small"
                    onClick={handleOpenSupplierPage}
                    startIcon={<StyledOpenInNewRoundedIcon />}
                >
                    {formatSupplierAndStockLocationDTO(supplierEmail.supplierAndStockLocation)}
                </TertiaryButton>
            </Flexbox>
        </Flexbox>
    );
};

const Attachments = ({ supplierEmail }: { supplierEmail: SupplierEmail }) => {
    const { control } = useFormContext<EmailManagerFormState>();

    const rfqId = useWatch({
        control: control,
        name: 'rfqId',
    });

    const sourcingScenarios = useWatch({
        control: control,
        name: 'sourcingScenarios',
    });

    const shouldFilterByLineCard = useWatch({
        control: control,
        name: 'shouldFilterByLineCard',
    });

    const generateAllQuantityCombinations = useWatch({
        control: control,
        name: 'generateAllQuantityCombinations',
    });

    const { includeShippingPanelSpecification, includePcbSpecification } = useWatch({
        control,
        name: 'emailBodyInformation.fileManagementInformation',
    });

    const isQuotePortalLinkIncludedInEmail = supplierEmail.email.body.includes('/quote-portal/rfq/');

    const { mutateAsync } = useDownloadSupplierQuotePriceTemplate();

    const onClick = async () => {
        /* eslint-disable camelcase */
        const requestBody: QuotePriceBulkRequestBodyDTO = {
            rfq: rfqId,
            supplier_ids: [supplierEmail.supplierAndStockLocation.id],
            part_ids: supplierEmail.supportedParts.map((p) => p.data),
            sourcing_scenario_ids: sourcingScenarios.map((s) => s.id),
            should_filter_by_line_card: shouldFilterByLineCard,
            generate_all_quantity_combinations: generateAllQuantityCombinations,
            should_include_shipping_panel_specification: includeShippingPanelSpecification,
            should_include_pcb_specification: includePcbSpecification,
        };
        /* eslint-enable camelcase */

        await mutateAsync({ requestBody });
    };

    if (isQuotePortalLinkIncludedInEmail) {
        return null;
    }

    return (
        <Flexbox flexDirection={'column'} gap={8}>
            <Text variant={'h4'} color={colorSystem.neutral[6]}>
                <Trans>Attachments</Trans>
            </Text>
            <Flexbox
                justifyContent="space-between"
                alignItems={'center'}
                border={`1px solid ${colorSystem.neutral[2]}`}
                borderRadius={'4px'}
            >
                <Flexbox gap={8} paddingX={'12px'} alignItems={'center'}>
                    <XlsIcon overflow={'inherit'} />
                    <Text variant={'body-semibold'} color={colorSystem.neutral[8]}>
                        {`${formatSupplierAndStockLocationDTO(supplierEmail.supplierAndStockLocation)}.xls •
                        ${supplierEmail.supportedParts.length}
                        ${
                            hasSupplierTag(supplierEmail.supplierAndStockLocation, SupplierTag.PcbSupplier)
                                ? t`PCB(s)`
                                : t`part(s)`
                        }`}
                    </Text>
                </Flexbox>

                <TertiaryIconButton onClick={onClick}>
                    <GetAppIcon fontSize="inherit" />
                </TertiaryIconButton>
            </Flexbox>

            {includePcbSpecification &&
                hasSupplierTag(supplierEmail.supplierAndStockLocation, SupplierTag.PcbSupplier) && (
                    <Flexbox
                        justifyContent="space-between"
                        alignItems={'center'}
                        border={`1px solid ${colorSystem.neutral[2]}`}
                        borderRadius={'4px'}
                    >
                        <Flexbox gap={8} paddingY={'12px'} paddingX={'12px'} alignItems={'center'}>
                            <ZipIcon overflow={'inherit'} />
                            <Text variant={'body-semibold'} color={colorSystem.neutral[8]}>
                                {t`PCB-Specification.zip`}
                            </Text>
                        </Flexbox>
                    </Flexbox>
                )}
        </Flexbox>
    );
};

const EmailEditor: React.FunctionComponent<{ rfq: RfqDTO; customer: CustomerDTO }> = ({ rfq, customer }) => {
    const { control } = useFormContext<EmailManagerFormState>();

    const selectedSupplier = useWatch({
        control: control,
        name: 'selectedSupplier',
    });

    const supplierEmails = useWatch({
        control: control,
        name: 'supplierEmails',
    });

    const dueDate = useWatch({ control, name: 'emailBodyInformation.dueDate' }) ?? '';
    const notes = useWatch({ control, name: 'emailBodyInformation.notes' }) ?? '';

    const supplierEmail = supplierEmails.find((elem) => elem.supplierAndStockLocation.id === selectedSupplier?.id);
    const index = supplierEmails.findIndex((elem) => elem.supplierAndStockLocation.id === selectedSupplier?.id);

    const currentUserDetails = useCurrentUserDetailsContext();

    if (!isPresent(supplierEmail)) {
        logToExternalErrorHandlers(new Error('Debug: Email Manager opened without a supplier'));
        return (
            <Flexbox alignItems={'center'} justifyContent={'center'}>
                <Text variant="h4">
                    <Trans>No supplier selected</Trans>
                </Text>
            </Flexbox>
        );
    }

    const renderContext: RenderQuoteRequestTemplateProps = {
        customer,
        dueDate,
        recipient: supplierEmail.supplierContacts[0],
        rfq,
        sender: currentUserDetails,
        supplier: supplierEmail.supplierAndStockLocation.supplier,
        notes,
    };

    return (
        <Flexbox
            flexDirection={'column'}
            gap={24}
            paddingX={'24px'}
            paddingY={'20px'}
            height={'60vh'}
            style={{ overflowY: 'auto' }}
        >
            <FormTitle key={`title-${index}`} index={index} supplierEmail={supplierEmail} />
            <Box
                sx={{
                    display: 'grid',
                    gridTemplateColumns: 'auto 1fr',
                    gap: 2,
                    alignItems: 'center',
                }}
            >
                <FormItemEmailTemplate key={`template-${index}`} renderContext={renderContext} index={index} />
                <FormItemRecipient key={`recipient-${index}`} index={index} supplierEmail={supplierEmail} />
                <FormItemCarbonCopy key={`carbon-copy-${index}`} index={index} supplierEmail={supplierEmail} />
                <FormItemSubject key={`subject-${index}`} index={index} />
            </Box>
            <FormItemBody key={`body-${index}`} index={index} />
            <Attachments supplierEmail={supplierEmail} />
        </Flexbox>
    );
};

const ActionsButtons: React.FunctionComponent<{ onBack: () => void }> = ({ onBack }) => {
    const { control } = useFormContext<EmailManagerFormState>();

    const supplierEmails = useWatch({
        control: control,
        name: 'supplierEmails',
    });

    const numberOfValidEmails = supplierEmails.filter(
        (supplierEmail) => supplierEmail.supportedParts.length > 0,
    ).length;

    return (
        <Flexbox
            flexDirection="row"
            gap={8}
            justifyContent="space-between"
            paddingX={'20px'}
            paddingY={'24px'}
            style={{
                position: 'sticky',
                left: 0,
                bottom: 0,
                boxSizing: 'border-box',
                background: colorSystem.neutral.white,
                borderTop: `1px solid ${colorSystem.neutral[2]}`,
            }}
        >
            <SecondaryButton onClick={onBack}>
                <Trans>Back</Trans>
            </SecondaryButton>
            <Flexbox gap={26} alignItems={'center'}>
                <Flexbox gap={8} alignItems={'center'}>
                    <StyledInfoOutlinedIconNeutral />
                    <Text variant={'body-semibold'} color={colorSystem.neutral[8]}>
                        <Trans>We will send a copy of this to your email address.</Trans>
                    </Text>
                </Flexbox>

                <SubmitButton
                    label={
                        numberOfValidEmails > 1
                            ? t`Send ${numberOfValidEmails} emails`
                            : t`Send ${numberOfValidEmails} email`
                    }
                />
            </Flexbox>
        </Flexbox>
    );
};

function createEmailManagerFormState({
    initalState,
    currentUserDetails,
    supplierContactDTOs,
    supportedPartsDTOs,
    emailTemplates,
    rfq,
    customer,
    organizationUsers,
}: {
    rfq: RfqDTO;
    customer: CustomerDTO;
    initalState: InitialState;
    currentUserDetails: CurrentUserDetailsDTO;
    supplierContactDTOs: SupplierContactDTO[];
    supportedPartsDTOs: SupportedPartsDTO[];
    emailTemplates: EmailTemplateDTO[];
    organizationUsers: UserDTO[];
}) {
    const { shouldFilterByLineCard, suppliersAndStockLocations } = initalState;
    const supplierEmails = suppliersAndStockLocations.flatMap((supplierAndStockLocation) => {
        const supplierContacts = supplierContactDTOs
            .filter((item) => item.supplier === supplierAndStockLocation.supplier.id)
            .filter((item) => item.is_main_contact);

        const supplierLinecardSupportedParts = uniq(
            supportedPartsDTOs
                .filter((item) => item.supplier === supplierAndStockLocation.supplier.id)
                .flatMap((item) => item.part_ids),
        );

        const supportedParts = shouldFilterByLineCard ? supplierLinecardSupportedParts : initalState.partIds;

        if (supplierContacts.length === 0 || supportedParts.length === 0) {
            return [];
        }
        const template = getQuoteRequestEmailTemplateBySupplier(emailTemplates, supplierAndStockLocation.supplier.id);

        const renderContext: RenderQuoteRequestTemplateProps = {
            customer,
            dueDate: initalState.emailBodyInformation.dueDate ?? '',
            recipient: supplierContacts[0],
            rfq,
            sender: currentUserDetails,
            supplier: supplierAndStockLocation.supplier,
            notes: initalState.emailBodyInformation.notes,
        };
        const { subject, body } = renderQuoteRequestTemplate(template, renderContext);
        const ccUsers = template?.ccs?.filter(isPresent) ?? [];

        return [
            {
                supplierAndStockLocation,
                supplierContacts,
                carbonCopy: organizationUsers.filter((user) => ccUsers.includes(user.id)),
                supportedParts: Array.from(supportedParts).sort((a, b) => a.data.localeCompare(b.data)),
                email: {
                    template: template,
                    subject,
                    body,
                },
            },
        ];
    });

    const defaultValues: EmailManagerFormState = {
        ...initalState,
        selectedSupplier: supplierEmails[0]?.supplierAndStockLocation,
        supplierEmails: supplierEmails,
    };

    return { defaultValues };
}

const TitleComponent: React.FunctionComponent<{ title: React.ReactNode }> = ({ title }) => {
    return (
        <Text variant="h1" color={colorSystem.neutral[8]}>
            {title}
        </Text>
    );
};

const EmailManagerForm: React.FunctionComponent<{
    initalState: InitialState;
    onBack: () => void;
}> = ({ initalState, onBack }) => {
    const history = useHistory();
    const { rfqId, partIds, suppliersAndStockLocations } = initalState;

    const { data: rfq } = useRfQ(rfqId);
    const { data: customer } = useCustomer(rfq?.customer);
    const { data: organizationUsers } = useAllOrganizationUsers();

    const { closeDialog } = useDialogContext();
    const currentUserDetails = useCurrentUserDetailsContext();

    const { data: emailTemplates } = useSuspenseHttpQuery('GET /email-template', {}, { select: (res) => res.items });

    const { data: supplierContactDTOs } = useSupplierContacts({ refetchOnWindowFocus: false });
    const { data: supportedPartsDTOs } = useSupportedPartsOfSupplier(
        rfqId,
        partIds,
        suppliersAndStockLocations.map((s) => s.supplier.id),
    );

    const { sendInvite } = useConfirmInviteSupplierContact({
        disableOnError: true,
    });
    const { mutateAsync } = useHttpMutation('POST /export/send-quote-price-mail', {
        snackbarMessage: t`Emails successfully sent`,
        onSuccess: () => {
            closeDialog();
            history.push(route('/rfqs/:rfqId/sourcing', { rfqId: rfqId }));
        },
    });

    if (
        !isPresent(organizationUsers) ||
        !isPresent(supplierContactDTOs) ||
        !isPresent(supportedPartsDTOs) ||
        !rfq ||
        !customer ||
        !emailTemplates
    ) {
        return <></>;
    }

    const { defaultValues } = createEmailManagerFormState({
        rfq,
        customer,
        initalState,
        currentUserDetails,
        supplierContactDTOs,
        supportedPartsDTOs,
        emailTemplates,
        organizationUsers,
    });

    const onSubmit = async (form: EmailManagerFormState) => {
        const emailContainsQuotePortalLink = form.supplierEmails.some(({ email }) =>
            email.body.includes('/quote-portal/rfq/'),
        );
        /* eslint-disable camelcase */
        const requestBody: SendQuotePriceRequestBodyDTO = {
            rfq: form.rfqId,
            part_ids: partIds.map((p) => p.data),
            should_filter_by_line_card: form.shouldFilterByLineCard,
            generate_all_quantity_combinations: form.generateAllQuantityCombinations,
            sourcing_scenario_ids: form.sourcingScenarios.map((s) => s.id),
            due_date: form.emailBodyInformation.dueDate,
            should_include_shipping_panel_specification:
                form.emailBodyInformation.fileManagementInformation.includeShippingPanelSpecification,
            should_include_pcb_specification:
                form.emailBodyInformation.fileManagementInformation.includePcbSpecification,
            should_include_mail_attachments: !emailContainsQuotePortalLink,
            notes: form.emailBodyInformation.notes,
            mail_data: form.supplierEmails.map((supplierEmail) => {
                const { subject, body } = renderQuoteRequestTemplate(supplierEmail.email, {
                    customer,
                    dueDate: form.emailBodyInformation.dueDate ?? '',
                    recipient: supplierEmail.supplierContacts[0],
                    rfq,
                    sender: currentUserDetails,
                    supplier: supplierEmail.supplierAndStockLocation.supplier,
                    notes: form.emailBodyInformation.notes,
                });
                return {
                    supplier_id: supplierEmail.supplierAndStockLocation.id,
                    supplier_contacts: supplierEmail.supplierContacts.map((re) => re.id),
                    cc: supplierEmail.carbonCopy.map((cc) => cc.id),
                    email: {
                        subject,
                        body,
                    },
                };
            }),
        };
        /* eslint-enable camelcase */

        // Only send invites to pcb suppliers, that are not yet invited
        if (emailContainsQuotePortalLink) {
            const pcbSupplierContacts = form.supplierEmails
                .filter(
                    (s) =>
                        hasSupplierTag(s.supplierAndStockLocation, SupplierTag.PcbSupplier) ||
                        hasSupplierTag(s.supplierAndStockLocation, SupplierTag.CustomPartSupplier),
                )
                .flatMap((s) => s.supplierContacts)
                .filter((s) => s.state.state === 'Uninvited' || s.state.state === 'Invited');

            await sendInvite(pcbSupplierContacts);
        }

        await mutateAsync({ requestBody });
    };

    return (
        <FormContainer defaultValues={defaultValues} onSubmit={onSubmit}>
            <Box display="grid" gridTemplateColumns="2fr 4fr">
                <SupplierList />
                <EmailEditor rfq={rfq} customer={customer} />
            </Box>
            <ActionsButtons onBack={onBack} />
        </FormContainer>
    );
};

export function useEmailManagerDialog() {
    const { setDialog, closeDialog } = useDialogContext();

    return {
        openDialog: (initialState: InitialState, onBack: () => void) =>
            setDialog(
                <Dialog
                    open={true}
                    maxWidth={'lg'}
                    fullWidth={true}
                    transitionDuration={0}
                    onClose={() => closeDialog()}
                >
                    <DialogTitle
                        title={t`Email manager`}
                        handleClose={() => closeDialog()}
                        style={{ borderBottom: `1px solid ${colorSystem.neutral[2]}` }}
                        overrides={{ TitleComponent }}
                    />
                    <DialogContent style={{ padding: '0px' }}>
                        <EmailManagerForm initalState={initialState} onBack={onBack} />
                    </DialogContent>
                </Dialog>,
            ),
    };
}
