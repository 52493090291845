import { Trans, t } from '@lingui/macro';
import { SecondaryButton, Tooltip, useConfirmationDialog } from '@luminovo/design-system';
import { Refresh } from '@mui/icons-material';
import { useQueryClient } from '@tanstack/react-query';
import { useRunParsersQueryKey } from '../../hooks/useRunParsers';

export function ButtonRefresh(): JSX.Element {
    const queryClient = useQueryClient();
    const { modal, setOpen } = useConfirmationDialog({
        title: t`Reset changes`,
        description: t`Are you sure you want to reset all changes and re-import the file? All changes will be lost.`,
        onConfirmation: () => {
            queryClient.invalidateQueries({ queryKey: useRunParsersQueryKey });
        },
    });

    return (
        <>
            {modal}
            <Tooltip title={t`Re-import the file and reset all changes.`}>
                <SecondaryButton
                    size="medium"
                    startIcon={<Refresh fontSize="small" />}
                    onClick={() => {
                        setOpen(true);
                    }}
                >
                    <Trans>Reset changes</Trans>
                </SecondaryButton>
            </Tooltip>
        </>
    );
}
