import { UpDownButtons } from '@luminovo/manufacturing-core';
import { useHttpMutation } from '../../../resources/mutation/useHttpMutation';
import { ActivityTableRowData } from './types';

export function UpDownButtonsActivityConfigurations({
    activityConfiguration,
}: {
    activityConfiguration: ActivityTableRowData;
}) {
    const { mutateAsync, isPending: isLoading } = useHttpMutation(
        'PATCH /activity-configurations/:activityConfigurationId/rank',
        {
            snackbarMessage: null,
        },
    );

    const handleUpdateItems = async ({ direction }: { direction: 'up' | 'down' }) => {
        const activityConfigurationId =
            activityConfiguration.activityConfigurationSummary.activity_configuration_details.activity_configuration_id;

        const nextRank = 'nextRank' in position ? position.nextRank : undefined;
        const previousRank = 'previousRank' in position ? position.previousRank : undefined;

        const newRank = direction === 'up' ? previousRank : nextRank;

        if (newRank === undefined) {
            return;
        }

        await mutateAsync({
            pathParams: {
                activityConfigurationId,
            },
            queryParams: {},
            requestBody: {
                rank: newRank,
            },
        });
    };

    function handleUp() {
        handleUpdateItems({ direction: 'up' });
    }
    function handleDown() {
        handleUpdateItems({ direction: 'down' });
    }

    const position = activityConfiguration.position;

    return (
        <UpDownButtons
            isDisabledFirstRow={position.type === 'first' || position.type === 'first-and-last'}
            isDisabledLastRow={position.type === 'last' || position.type === 'first-and-last'}
            onClickUp={handleUp}
            onClickDown={handleDown}
            isLoading={isLoading}
        />
    );
}
