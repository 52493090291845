import { Tooltip } from '@luminovo/design-system';
import { Box } from '@mui/material';
import { NegotiationIpnLabel } from './NegotiationIpnLabel';

export function NegotiationIpnsLabel({ ipns }: { ipns: string[] }) {
    const [first, ...rest] = ipns;
    if (!first) {
        return <span>-</span>;
    }
    if (rest.length === 0) {
        return <NegotiationIpnLabel ipn={first} />;
    }
    return (
        <Tooltip
            variant="white"
            title={
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    {ipns.map((ipn, key) => (
                        <NegotiationIpnLabel key={key} ipn={ipn} />
                    ))}
                </Box>
            }
        >
            <span>
                {<NegotiationIpnLabel ipn={first} />} +{rest.length}
            </span>
        </Tooltip>
    );
}
