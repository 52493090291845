import { Trans } from '@lingui/macro';
import { PrimaryButton } from '@luminovo/design-system';
import { CalculationAssemblyPostDTO, http } from '@luminovo/http-client';
import { useMutation } from '@tanstack/react-query';
import { SubmitHandler } from 'react-hook-form';
import { useHistory } from 'react-router';
import { useDialogContext } from '../../../../../components/contexts/ModalContext';
import { useToken } from '../../../../../resources/hooks';
import { useDebugErrorHandler } from '../../../../../resources/http/debugErrorHandler';
import { route } from '../../../../../utils/routes';
import { CreateAssemblyDetailsFormInput } from './types';
import { useCalculationTemplateSelectionDialog } from './useCalculationTemplateSelectionDialog';

const useCreateAssemblyDetailsFunction = (rfqId: string, assemblyId: string) => {
    const { token } = useToken();
    const debugErrorHandler = useDebugErrorHandler();
    const history = useHistory();
    const { closeDialog } = useDialogContext();

    const { mutateAsync, isPending: isLoading } = useMutation({
        mutationFn: (values: { body: CalculationAssemblyPostDTO; calculationTemplateId: string | undefined }) => {
            return http(
                'POST /calculations/calculation-assembly-details',
                {
                    requestBody: values.body,
                    queryParams:
                        values.calculationTemplateId !== undefined
                            ? { create_from_calculation_template_id: values.calculationTemplateId } // eslint-disable-line camelcase
                            : {},
                },
                token,
            );
        },
        onError: debugErrorHandler,
        onSuccess: () => {
            closeDialog();
            history.push(route('/rfqs/:rfqId/calculation/:assemblyId/calculation', { rfqId, assemblyId }));
        },
    });

    const onSubmit: SubmitHandler<CreateAssemblyDetailsFormInput> = async (data) => {
        /* eslint-disable camelcase */
        return mutateAsync({
            body: {
                assembly_id: data.assemblyId,
                notes: data.notes,
                include_excess_material_in_material_cost: data.includeExcessMaterial,
            },
            calculationTemplateId: data.templateId,
        });
        /* eslint-enable camelcase */
    };

    return { onSubmit, isLoading };
};

// This button should do two things
// 1 - Create assembly details if it doesn't already exist, and then re-route to the calculation table page
// 2 - If it exist re-route to the calculation table page
const BuildCalculationButton = ({
    assemblyId,
    disabled,
    rfqId,
    isCalculationBuilt,
}: {
    assemblyId: string;
    rfqId: string;
    disabled: boolean;
    isCalculationBuilt: boolean;
}): JSX.Element => {
    const history = useHistory();

    const { onSubmit, isLoading: isSubmitting } = useCreateAssemblyDetailsFunction(rfqId, assemblyId);

    const { openDialog } = useCalculationTemplateSelectionDialog({ assemblyId, rfqId, onSubmit });

    const handleClick = async () => {
        if (isCalculationBuilt) {
            history.push(route('/rfqs/:rfqId/calculation/:assemblyId/calculation', { rfqId, assemblyId }));
        } else {
            openDialog();
        }
    };

    const isLoading = isSubmitting;

    return (
        <PrimaryButton
            disabled={isLoading || disabled}
            style={{ marginLeft: 'auto', marginTop: 'auto' }}
            onClick={handleClick}
            size="medium"
        >
            {isCalculationBuilt ? <Trans>View calculation</Trans> : <Trans>Build calculation</Trans>}
        </PrimaryButton>
    );
};

export default BuildCalculationButton;
