import { useSuspenseHttpQuery } from '../../../resources/http/useHttpQuery';

const DEFAULT_HOURS_UNTIL_FROZEN = 24 * 7 * 2; // two weeks

/**
 * Returns the number of hours until a quote request is frozen.
 */
export function useHoursUntilQuoteFrozen() {
    const { data: hoursUntilFrozen } = useSuspenseHttpQuery(
        'GET /organization-settings/supplier-portal-settings',
        {},
        { select: (res) => res.hours_until_frozen ?? DEFAULT_HOURS_UNTIL_FROZEN },
    );
    return hoursUntilFrozen ?? DEFAULT_HOURS_UNTIL_FROZEN;
}
