import { compareByString, Dictionary } from '@luminovo/commons';
import { colorSystem, Text, Tooltip } from '@luminovo/design-system';
import { SourcingBreadcrumbItemsDTO } from '@luminovo/http-client';
import { Grid, styled, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { route } from '../../../../utils/routes';

interface DesignatorsPopoverProps {
    uniqueDesignators: string;
    rfqId: string;
    groupedDesignators: Dictionary<SourcingBreadcrumbItemsDTO[]>;
}

const StyledLink = styled(Link)({
    color: colorSystem.neutral['7'],
    textDecoration: 'none',
    '&:hover': {
        color: colorSystem.primary['6'],
    },
});

export const DesignatorsPopover = ({ uniqueDesignators, groupedDesignators, rfqId }: DesignatorsPopoverProps) => (
    <Tooltip
        variant="white"
        placement="bottom-start"
        title={
            <>
                {Object.entries(groupedDesignators).map(([key, value], i) => {
                    const assemblyId =
                        value.map((val) => val.parent_assemblies[val.parent_assemblies.length - 1])[0].id ?? '';
                    return (
                        <Grid
                            container
                            key={i}
                            direction={'row'}
                            onClick={(event: React.MouseEvent<HTMLElement>) => {
                                event.stopPropagation();
                            }}
                        >
                            <Grid item>
                                <Typography variant={'body2'} color={'textSecondary'}>
                                    {value[0].parent_assemblies.map((pa) => pa.name).join(' > ') + ' >'}&nbsp;
                                </Typography>
                            </Grid>
                            <Grid item>
                                <StyledLink
                                    to={route(
                                        '/rfqs/:rfqId/bom/assembly/:assemblyId/details',
                                        { rfqId, assemblyId },
                                        {
                                            isReadonly: 'true',
                                            designItemId: value.map((val) => val.design_item_id).join(','),
                                            filters: null,
                                            bomTab: null,
                                            dashboardFilters: null,
                                            search: null,
                                            onlyShowItemsWithManufacturingWarnings: null,
                                            currentParentAssemblyId: null,
                                        },
                                    )}
                                >
                                    <Typography variant={'h4'}>
                                        {value
                                            .map((val) => val.designator)
                                            .sort(compareByString)
                                            .join(', ')}
                                    </Typography>
                                </StyledLink>
                            </Grid>
                        </Grid>
                    );
                })}
            </>
        }
    >
        <Text variant={'inherit'}>{uniqueDesignators}</Text>
    </Tooltip>
);
