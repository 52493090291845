import { t } from '@lingui/macro';
import {
    Column,
    DataTable,
    DialogActions,
    DialogContent,
    SecondaryButton,
    useDataTableState,
} from '@luminovo/design-system';
import { SubmitHandler, useFormContext } from 'react-hook-form';
import { FormContainer } from '../../../../components/formLayouts/FormContainer';
import { SubmitButton } from '../../../../components/formLayouts/SubmitButton';
import { selectionColumnWithDisabledRows } from '../../ManufacturingScenarioTemplateManagement/components/selectionColumn';

import { Dialog, DialogTitle } from '@luminovo/design-system';

export const EntitySelectionDialogForm = <T extends Entity>({
    title,
    closeDialog,
    entities,
    columns,
    persistenceId,
    defaultValues,
    onSubmit,
    defaultSelectedRowIds,
}: EntitySelectionProps<T>): JSX.Element => {
    return (
        <Dialog open={true} maxWidth={'xl'}>
            <DialogTitle title={title} handleClose={closeDialog} />
            <EntitySelectionForm
                onCancel={closeDialog}
                entities={entities}
                columns={columns}
                persistenceId={persistenceId}
                defaultValues={defaultValues}
                onSubmit={onSubmit}
                defaultSelectedRowIds={defaultSelectedRowIds}
            />
        </Dialog>
    );
};

interface Entity {
    id: string;
}

export type EntityFormState = {
    selectedIds: string[];
};
interface EntitySelectionProps<T extends Entity> {
    title: string;
    closeDialog: () => void;
    entities: T[];
    columns: Column<T>[];
    persistenceId: string;
    defaultValues: EntityFormState;
    onSubmit: SubmitHandler<EntityFormState>;
    defaultSelectedRowIds: string[];
}

const useColumns = <T extends Entity>(): Column<T> => {
    const { setValue, watch } = useFormContext<EntityFormState>();
    const selectedIds = watch('selectedIds');

    return selectionColumnWithDisabledRows({
        onClick: ({ rowId }: { rowId: string }) => {
            setValue(
                'selectedIds',
                selectedIds.includes(rowId)
                    ? selectedIds.filter((selectedId) => selectedId !== rowId)
                    : [...selectedIds, rowId],
            );
        },
    });
};

const InnerTable = <T extends Entity>({
    persistenceId,
    entities,
    columns,
    defaultSelectedRowIds = [],
}: {
    persistenceId: string;
    entities: T[];
    columns: Column<T>[];
    defaultSelectedRowIds?: string[];
}) => {
    const selectionColumn = useColumns();

    const tableState = useDataTableState<T>({
        persistenceId,
        columns: [selectionColumn, ...columns],
        items: entities,
        selectionOptions: {
            idExtractor: (item) => item.id,
            defaultSelectedIds: defaultSelectedRowIds,
        },
    });

    return <DataTable tableState={tableState} size={'medium'} />;
};

const EntitySelectionForm = <T extends Entity>({
    onCancel,
    entities,
    columns,
    persistenceId,
    defaultValues,
    onSubmit,
    defaultSelectedRowIds,
}: Omit<EntitySelectionProps<T>, 'title' | 'closeDialog'> & { onCancel: () => void }): JSX.Element => {
    return (
        <FormContainer defaultValues={defaultValues} onSubmit={onSubmit}>
            <DialogContent style={{ maxHeight: 500, minHeight: 500, overflow: 'scroll' }}>
                <InnerTable
                    columns={columns}
                    persistenceId={persistenceId}
                    entities={entities}
                    defaultSelectedRowIds={defaultSelectedRowIds}
                />
            </DialogContent>
            <DialogActions>
                <SecondaryButton onClick={onCancel}>{t`Cancel`}</SecondaryButton>
                {/* strangely adding two SecondaryButtons causes a correct padding, there is a 
                slight bug in the Submit button I think which means I need to put this marginLeft manually here
                */}
                <div
                    style={{
                        marginLeft: '12px',
                    }}
                >
                    <SubmitButton />
                </div>
            </DialogActions>
        </FormContainer>
    );
};
