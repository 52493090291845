import { t, Trans } from '@lingui/macro';
import { colorSystem, Flexbox, Text } from '@luminovo/design-system';
import { ResourceDTO, ResourceType } from '@luminovo/http-client';
import { ResourceIcon } from '@luminovo/manufacturing-core';
import { styled } from '@mui/material';
import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { SitesWithIconFetching } from '../../../../components/Site';
import { formatCurrency } from '../../../../utils/currencyUtils';
import { AddActivityFormInputs } from '../AddActivityFormTypes';
import { RemoveResourceButton } from './resourceButtons';

const ResourceAttribute = ({
    title,
    description,
    icon,
}: {
    title: string;
    description: string | React.ReactChild;
    icon?: React.ReactNode;
}): JSX.Element => {
    return (
        <Flexbox flexDirection={'column'} gap={'8px'} color={colorSystem.neutral[8]}>
            <Text variant="body-semibold">{title}</Text>
            <Flexbox gap={8} alignItems={icon ? 'center' : 'start'}>
                {icon}
                {typeof description === 'string' ? <Text>{description}</Text> : description}
            </Flexbox>
        </Flexbox>
    );
};

const CostText = ({
    formattedCurrency: formattedCost,
    color,
}: {
    formattedCurrency: string;
    color?: string;
}): JSX.Element => {
    return <Text color={color}>{formattedCost}</Text>;
};

const CostPerHour = ({ multiplier, resource }: { multiplier: string; resource: ResourceDTO }): JSX.Element => {
    const multiplierNumber = parseFloat(multiplier);
    const isMultiplierNumberNotANumber = Number.isNaN(multiplierNumber);

    const amount = resource.cost_per_hour.converted_mv.amount;
    const currency = resource.cost_per_hour.converted_mv.currency;
    const formattedCurrency = formatCurrency(amount, currency);

    const lightTextColor = colorSystem.neutral[7];

    const content =
        isMultiplierNumberNotANumber || multiplierNumber === 1 ? (
            <CostText formattedCurrency={formattedCurrency} />
        ) : (
            <Flexbox gap={4} flexWrap="wrap">
                <CostText formattedCurrency={formattedCurrency} color={lightTextColor} />
                <Text color={lightTextColor}>x&nbsp;{multiplierNumber}&nbsp;=</Text>
                <CostText formattedCurrency={formatCurrency(parseFloat(amount) * multiplierNumber, currency)} />
            </Flexbox>
        );

    return <ResourceAttribute title={t`Cost/hour`} description={content} />;
};

const InternalResourceNumber = ({ internalNumber }: { internalNumber: string | undefined }): JSX.Element => {
    return <ResourceAttribute title={t`Internal resource number`} description={internalNumber ?? '-'} />;
};

const ResourceTypeLabel = ({ resourceType }: { resourceType: ResourceType }): JSX.Element => {
    return (
        <ResourceAttribute
            title={t`Type`}
            description={resourceType}
            icon={<ResourceIcon resourceType={resourceType} heightAndWidth={'20px'} />}
        />
    );
};

const Description = ({ description }: { description: string | undefined }): JSX.Element => {
    return (
        <Flexbox flexDirection={'column'} gap={'8px'} color={colorSystem.neutral[8]} width="100%">
            <Text variant="body-semibold">
                <Trans>Description</Trans>
            </Text>
            <Flexbox gap={8} maxHeight="100px" overflow="hidden scroll">
                <Text>{description ?? '-'}</Text>
            </Flexbox>
        </Flexbox>
    );
};

const Row = styled(Flexbox)({
    '& > div:first-child': {
        width: '60%',
    },
    '& > div:last-child': {
        flex: 1,
    },
});

export const ResourceInformationBox = ({
    resource,
    index,
    useFormReturn,
    multiplier,
}: {
    resource: ResourceDTO | undefined;
    index: number;
    useFormReturn: UseFormReturn<AddActivityFormInputs>;
    multiplier: string;
}): JSX.Element => {
    if (!resource) {
        return <EmptyResourceInformationBox index={index} useFormReturn={useFormReturn} />;
    }

    return (
        <Flexbox gap={16} flexDirection="column" width="100%" position="relative">
            <Row gap={16}>
                <InternalResourceNumber internalNumber={resource.internal_number ?? undefined} />
                <ResourceTypeLabel resourceType={resource.type} />
            </Row>
            <Row gap={16}>
                <CostPerHour multiplier={multiplier} resource={resource} />
                <SitesWithIconFetching
                    sites={resource.site_id ? [resource.site_id] : []}
                    color={colorSystem.neutral[6]}
                />
            </Row>

            <Flexbox width="85%">
                <Description description={resource.description ?? undefined} />
            </Flexbox>

            <RemoveResourceButton
                index={index}
                {...useFormReturn}
                style={{ position: 'absolute', bottom: 0, right: 0 }}
            />
        </Flexbox>
    );
};

const EmptyResourceInformationBox = ({
    index,
    useFormReturn,
}: {
    index: number;
    useFormReturn: UseFormReturn<AddActivityFormInputs>;
}): JSX.Element => {
    return (
        <Flexbox
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            style={{
                position: 'relative',
                width: '100%',
                height: '100%',
            }}
        >
            <Text color={colorSystem.neutral[7]}>
                <Trans>Select a resource to see its details</Trans>
            </Text>
            <div style={{ position: 'absolute', bottom: 0, right: 0 }}>
                <RemoveResourceButton index={index} {...useFormReturn} />
            </div>
        </Flexbox>
    );
};
