import { t } from '@lingui/macro';
import { PCBV2 } from '@luminovo/http-client';
import React from 'react';
import { useForm } from 'react-hook-form';
import { Capability, createInitialPcbFormValues, usePcbCapabilities } from '../../../../resources/pcb/pcbFunctions';
import { useExtractedPcbCapabilities } from '../../components/PcbAnalysis/utils/usePcbPdfAnalysis/useExtractedPcbCapabilities';

const mergePcbCapabilities = ({
    extractedPcbCapabilities,
    analyzedPcbCapabilities,
}: {
    extractedPcbCapabilities: Capability[];
    analyzedPcbCapabilities: Capability[];
}): Capability[] => {
    // If the capability is present in extracted capabilities, use the extracted value
    // Otherwise, use the analyzed value
    const extractedMap = new Map(extractedPcbCapabilities.map((cap) => [cap.capabilityName, cap]));
    return analyzedPcbCapabilities.map(
        (analyzedCapability) => extractedMap.get(analyzedCapability.capabilityName) ?? analyzedCapability,
    );
};

export const usePcbFormData = ({ pcb }: { pcb: PCBV2 }) => {
    // Capabilities analyzed by server
    const analyzedPcbCapabilities = usePcbCapabilities({ pcb });
    const analyzedValues = React.useMemo(
        () =>
            createInitialPcbFormValues({
                pcb,
                fields: analyzedPcbCapabilities,
            }),
        [pcb, analyzedPcbCapabilities],
    );

    // Capabilities extracted from specification
    const extractedPcbCapabilities = useExtractedPcbCapabilities({
        pcb,
        pcbCapabilities: analyzedPcbCapabilities,
    });

    const mergedPcbCapabilities = React.useMemo(
        () =>
            mergePcbCapabilities({
                extractedPcbCapabilities: extractedPcbCapabilities,
                analyzedPcbCapabilities: analyzedPcbCapabilities,
            }),
        [extractedPcbCapabilities, analyzedPcbCapabilities],
    );

    const mergedValues = React.useMemo(
        () =>
            createInitialPcbFormValues({
                pcb,
                fields: mergedPcbCapabilities,
            }),
        [pcb, mergedPcbCapabilities],
    );

    // Set the default values
    const useFormReturn = useForm({
        defaultValues: analyzedValues,
        mode: 'onChange',
        reValidateMode: 'onChange',
    });

    const { reset, setError } = useFormReturn;

    React.useEffect(() => {
        const values = createInitialPcbFormValues({
            pcb,
            /**
             * Here's where my confusion is.
             * First stage, we fill the form with the values that were analyzed by the server.
             * After that, user accepts the values extracted from the pdf and we fill the form with the merged values.
             * But then more values are analyzed by the server and the form is filled with the new analyzed values.
             * This would remove the values that were extracted from the pdf.
             * I think the solution is to always fill the form with the analyzed values, but then we need to somehow
             * keep the values that were extracted from the pdf.
             */
            fields: analyzedPcbCapabilities,
        });

        if (Object.keys(values.board).length !== 0) reset(values);

        if (values.board.placementSide === 'none') {
            setError('board.placementSide', { type: 'required', message: t`Required` });
        }
    }, [pcb, reset, setError, analyzedPcbCapabilities]);

    return {
        useFormReturn,
        mergedValues, // form values of the values that were merged from the analyzed and extracted values
        defaultValues: analyzedValues, // form values of the values that were analyzed by the server
        pcbCapabilities: mergedPcbCapabilities, // capability objects of the values that were merged from the analyzed and extracted values
        extractedPcbCapabilities, // capability objects of the values that were extracted from the specification
        analyzedPcbCapabilities, // capability objects of the values that were analyzed by the server
    };
};
