import { formatDecimal, isPresent } from '@luminovo/commons';
import { QuoteRequestLineItemDTO } from '@luminovo/http-client';
import { monetaryValue } from './monetaryValueMath';

export enum QuoteRequestLineItemWarning {
    PartChanged,
    LargeUnitPrice,
    LargeMOQ,
}

export function getRowWarnings(lineItem: QuoteRequestLineItemDTO): QuoteRequestLineItemWarning[] {
    const warnings: QuoteRequestLineItemWarning[] = [];
    if (hasPartChanged(lineItem)) {
        warnings.push(QuoteRequestLineItemWarning.PartChanged);
    }
    if (doesUnitPriceHaveWarning(lineItem)) {
        warnings.push(QuoteRequestLineItemWarning.LargeUnitPrice);
    }
    if (hasMoqWarning(lineItem)) {
        warnings.push(QuoteRequestLineItemWarning.LargeMOQ);
    }
    return warnings;
}

export function hasPartChanged(lineItem: QuoteRequestLineItemDTO) {
    if (!isPresent(lineItem.requested_part) || !isPresent(lineItem.received_offer?.part)) {
        return false;
    }
    return lineItem.requested_part.id !== lineItem.received_offer.part.id;
}

export function doesUnitPriceHaveWarning(lineItem: QuoteRequestLineItemDTO) {
    if (!isPresent(lineItem.received_offer) || !isPresent(lineItem.target_price)) {
        return false;
    }

    const percentageDifference = monetaryValue.relativeDifference(
        lineItem.target_price,
        lineItem.received_offer.unit_price,
    );

    if (!percentageDifference) {
        return false;
    }

    // If it's greater than 180% of the target price
    return percentageDifference < -1.8;
}

export function hasMoqWarning(lineItem: QuoteRequestLineItemDTO) {
    if (!isPresent(lineItem.received_offer)) {
        return false;
    }

    return lineItem.received_offer.moq > lineItem.required_quantity.quantity * 0.5;
}

export function getUnitPriceToTargetPriceScale(lineItem: QuoteRequestLineItemDTO) {
    if (
        !isPresent(lineItem.received_offer) ||
        !isPresent(lineItem.target_price) ||
        monetaryValue.isZero(lineItem.received_offer.unit_price)
    ) {
        return '';
    }

    const unitPriceScalar = monetaryValue.getScalar(lineItem.received_offer.unit_price);
    const targetPriceScalar = monetaryValue.getScalar(lineItem.target_price);

    return formatDecimal(unitPriceScalar / targetPriceScalar, { maximumFractionDigits: 1, ifNaN: '-' });
}
