import { t } from '@lingui/macro';
import { colorSystem, FieldSelect, FormItem } from '@luminovo/design-system';
import { Box } from '@mui/material';
import { useUniversalImporter } from '../../context';
import { createCombinedDateFormats, DateFormatGlobalFieldValue } from '../../util/getDefaultDateFormat';

export function FormItemDateFormats(): JSX.Element {
    const { state, dispatch } = useUniversalImporter();
    const { dateFormat: selectedDateFormat } = state;

    return (
        <FormItem
            label={t`Date format`}
            variant="description-inlined"
            description={t`Choose which date format appears in your file.`}
            style={{ maxWidth: 800 }}
        >
            <FieldSelect<DateFormatGlobalFieldValue>
                value={selectedDateFormat}
                getOptionKey={(option) => option.id}
                renderOption={(option) => {
                    return (
                        <Box
                            sx={{
                                display: 'grid',
                                gridTemplateColumns: '1fr 1fr',
                                alignItems: 'center',
                                width: '100%',
                                color: colorSystem.neutral[8],
                            }}
                        >
                            <div style={{ color: colorSystem.neutral[9] }}>{option.label}</div>
                            <div>e.g. {option.example} </div>
                        </Box>
                    );
                }}
                error={!selectedDateFormat}
                onChange={(value: DateFormatGlobalFieldValue | null) => {
                    if (value) {
                        dispatch({ type: 'setDateFormat', dateFormat: value });
                    }
                }}
                options={createCombinedDateFormats()}
                getOptionLabel={(option) => `${option.label}`}
                fullWidth
                disableClearable
            />
        </FormItem>
    );
}
