import { ImporterConfig, ImporterTable, Table, TransformRecordActionHandler } from '../types';
import { ImmutableImporterTable } from './ImmutableImporterTable';

/**
 * Run the parsers on the table to create an importer table.
 *
 * Emits a table occassionally to allow the UI to show the progress of the parsing.
 * Note: In the current implementation, it only emits the initial table and the final table.
 */
export async function* runParsers<TConfig extends ImporterConfig>(
    table: Table,
    config: TConfig,
    transformRecordAction?: TransformRecordActionHandler<ImporterConfig>,
): AsyncGenerator<ImporterTable> {
    let result: ImporterTable = ImmutableImporterTable.fromTable({ table, config, transformRecordAction });
    yield result;

    const newTable = result.applyParsers({
        row: { from: 0, to: table.length },
        column: { from: 0, to: config.fields.length },
    });

    yield newTable;
}
