import { LayoutCard } from '../../../../components/LayoutCard';
import { AssemblyTableData, ViewContext } from '../../../Bom/components/ModuleTableData';
import { SubassembliesTable } from './SubassembliesTable';

export const SubassembliesContainer = ({
    subassemblies,
    viewContext,
}: {
    subassemblies: AssemblyTableData[];
    viewContext: ViewContext;
}) => {
    if (subassemblies.length === 0) {
        return null;
    }

    return (
        <LayoutCard style={{ height: 'clamp(400px, 40vh, 540px)' }}>
            <SubassembliesTable subassemblies={subassemblies} viewContext={viewContext} />
        </LayoutCard>
    );
};
