import { compareByString } from '@luminovo/commons';
import { useHttpQuery } from '../http/useHttpQuery';

export function useSupplierContacts(options: { refetchOnWindowFocus?: boolean } = {}) {
    return useHttpQuery('GET /supplier-contacts', {}, { select: (res) => res.items, ...options });
}

export function useSupplierContact(supplierContactId: string = '') {
    return useHttpQuery(
        'GET /supplier-contacts',
        {},
        {
            select: (res) => res.items.find((sc) => sc.id === supplierContactId),
            enabled: Boolean(supplierContactId),
        },
    );
}

export function useSupplierContactsFromSupplier(
    supplierId: string | undefined,
    options: { refetchOnWindowFocus?: boolean } = {},
) {
    return useHttpQuery(
        'GET /suppliers/:supplierId/supplier-contacts',
        {
            pathParams: { supplierId: supplierId ?? '' },
        },
        {
            select: (res) =>
                res.items.sort((a, b) => compareByString(a.first_name + a.last_name, b.first_name + b.last_name)),
            enabled: Boolean(supplierId),
            ...options,
        },
    );
}
