import { getToken } from '@luminovo/auth';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { logToExternalErrorHandlers, responseErrorHandler } from '../utils/customConsole';
import { notUndefined } from '../utils/typingUtils';
import { NotificationFunction } from './NotificationFunction';
import { FileWithUrlAndName } from './assembly/assemblyFrontendTypes';
import { uploadFileToBlobStorage } from './http/useHttpFileUpload';

const errorActions = (error: AxiosError, notificationFunction: NotificationFunction) => {
    logToExternalErrorHandlers(error);
    responseErrorHandler(error, notificationFunction);
    throw error;
};

/**
 * @deprecated use `src/resources/http/http.ts instead`
 */
export default class HttpService {
    private _headers: Map<string, string> = new Map<string, string>();
    private _config: AxiosRequestConfig = {};

    /**
     * @deprecated
     */
    public withToken(token: string): HttpService {
        this._headers.set('Authorization', `Bearer ${token}`);
        return this;
    }

    /**
     * @deprecated
     */
    public withHeaders(headers: Map<string, string>): HttpService {
        headers.forEach((value, key) => {
            this._headers.set(key, value);
        });
        return this;
    }

    /**
     * @deprecated
     */
    public withConfig(config?: AxiosRequestConfig): HttpService {
        this._config = { ...this._config, ...config };
        return this;
    }

    /**
     * @deprecated
     */
    /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
    public async postV2(url: string, data: unknown): Promise<any> {
        const config = this.createConfig();
        return axios
            .post(url, data, config)
            .then((response) => {
                return response;
            })
            .catch((error) => this.handleError(error));
    }

    private handleError(error: AxiosError): never {
        logToExternalErrorHandlers(error);
        const response = error.response;
        if (!response) throw error;

        throw response;
    }

    private createConfig(): AxiosRequestConfig {
        return { ...this._config, headers: Object.fromEntries(this._headers) };
    }

    /**
     * @deprecated
     */
    /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
    public async get(url: string, notificationFunction: NotificationFunction): Promise<any> {
        const config = this.createConfig();
        return axios
            .get(url, config)
            .then((response) => {
                return response;
            })
            .catch((e) => {
                return errorActions(e, notificationFunction);
            });
    }
}
/**
 * @deprecated
 */
export const multipleGetRequestsAllInfo = async (
    ids: string[],
    token: string,
    endPointFunction: (id: string) => string,
    enqueueSnackbar: NotificationFunction,
    /* eslint-disable-next-line  @typescript-eslint/no-explicit-any */
): Promise<any> => {
    const allPromises = ids.map((id) => new HttpService().withToken(token).get(endPointFunction(id), enqueueSnackbar));
    const allResults = await Promise.allSettled(allPromises);
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const allFulfilledResults: PromiseFulfilledResult<AxiosResponse>[] = allResults.filter(
        (p) => p.status === 'fulfilled',
    ) as PromiseFulfilledResult<AxiosResponse>[];

    return allFulfilledResults;
};

/**
 * @deprecated
 */
// eslint-disable-next-line max-params
export async function uploadResources(
    uploadFiles: (FileWithUrlAndName | undefined)[],
    endpoint: string,
    notificationFunction: NotificationFunction,
    captureAzureError?: () => void,
) {
    await new HttpService()
        .withToken(getToken())
        .get(endpoint, notificationFunction)
        .then(async (uploadResponse) => {
            const uploadUrl: string = uploadResponse.data.data.url;
            await Promise.all(
                uploadFiles
                    .map((f) => f?.file)
                    .filter(notUndefined)
                    .map((aFile) => uploadFileToBlobStorage(aFile, uploadUrl)),
            );
        })
        .catch((e) => {
            responseErrorHandler(e, notificationFunction);
            if (captureAzureError) {
                captureAzureError();
            }
        });
}
