import { assertUnreachable } from '@luminovo/commons';
import { Chip, Flexbox, Text, colorSystem } from '@luminovo/design-system';
import {
    FullPart,
    GenericPart,
    isCustomComponentFull,
    isCustomFullPart,
    isGenericFullPart,
    isOtsComponentFull,
    isOtsFullPart,
} from '@luminovo/http-client';
import {
    formatCapacitance,
    formatDielectricMaterial,
    formatPowerRating,
    formatResistance,
    formatTemperatureCoefficient,
    formatTolerance,
    formatVoltageRating,
} from '../../utils/converterUtils';
import { nthValueInArray } from '../../utils/typingUtils';
import { TechParamsMultiChip } from '../TechParamsMultiChip';
import { TableCell } from './TableCell';

export const MAX_WIDTH = '332px';

export const RenderDescription = ({ part, onClick }: { part: FullPart; onClick?: () => void }): JSX.Element => {
    if (isOtsFullPart(part) || isCustomFullPart(part) || isCustomComponentFull(part)) {
        const description = part.description ?? '-';
        const isComponentRemoved = isCustomComponentFull(part) && part.state === 'Removed';
        return (
            <TableCell isComponentRemoved={isComponentRemoved} onClick={onClick}>
                <Flexbox maxWidth={MAX_WIDTH}>
                    <Text
                        variant="body-small"
                        showEllipsis
                        color={isComponentRemoved ? colorSystem.neutral[6] : colorSystem.neutral[9]}
                    >
                        {description}
                    </Text>
                </Flexbox>
            </TableCell>
        );
    }
    if (isGenericFullPart(part)) {
        return (
            <TableCell onClick={onClick}>
                <Flexbox gap="4px" maxWidth={MAX_WIDTH}>
                    <RenderGenericTechParams genericPart={part.content} />
                </Flexbox>
            </TableCell>
        );
    }
    if (isOtsComponentFull(part)) {
        const description = nthValueInArray(part.part_specifications, 0)?.description ?? '-';
        return part.component_specification && part.component_specification.function_specification ? (
            <TableCell isComponentRemoved={part.state === 'Removed'} onClick={onClick}>
                <TechParamsMultiChip
                    componentSpecification={part.component_specification}
                    flexWrap="nowrap"
                    hidePackage={true}
                    disabled={part.state === 'Removed'}
                    fallback={<Text variant="body-small">{description}</Text>}
                />
            </TableCell>
        ) : (
            <TableCell isComponentRemoved={part.state === 'Removed'} onClick={onClick}>
                {description}
            </TableCell>
        );
    }
    assertUnreachable(part);
};

export const RenderGenericTechParams = ({ genericPart }: { genericPart: GenericPart }) => {
    if (genericPart.type === 'Resistor') {
        const {
            resistance,
            tolerance,
            power_rating: powerRating,
            voltage_rating: voltageRating,
            temperature_coefficient: temperatureCoefficient,
        } = genericPart.technical_parameters;

        return (
            <>
                <Chip color={'neutral'} label={genericPart.type} />
                {resistance && <Chip color={'neutral'} label={formatResistance(resistance)} />}
                {voltageRating && <Chip color={'neutral'} label={formatVoltageRating(voltageRating)} />}
                {powerRating && <Chip color={'neutral'} label={formatPowerRating(powerRating)} />}
                {tolerance && <Chip color={'neutral'} label={formatTolerance(tolerance)} />}
                {temperatureCoefficient && (
                    <Chip color={'neutral'} label={formatTemperatureCoefficient(temperatureCoefficient)} />
                )}
            </>
        );
    }
    if (genericPart.type === 'Capacitor') {
        const {
            dielectric: dielectricMaterial,
            capacitance,
            tolerance,
            voltage_rating: voltageRating,
        } = genericPart.technical_parameters;
        return (
            <>
                <Chip color={'neutral'} label={genericPart.type} />
                {capacitance && <Chip color={'neutral'} label={formatCapacitance(capacitance)} />}
                {voltageRating && <Chip color={'neutral'} label={formatVoltageRating(voltageRating)} />}
                {tolerance && <Chip color={'neutral'} label={formatTolerance(tolerance)} />}
                {dielectricMaterial && <Chip color={'neutral'} label={formatDielectricMaterial(dielectricMaterial)} />}
            </>
        );
    }
    assertUnreachable(genericPart);
};
