import { getToken } from '@luminovo/auth';
import { ActivityConfigurationsPostDTO, http } from '@luminovo/http-client';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { httpQueryKey } from '../../../../../resources/http/httpQueryKey';

export const useMutationAppendActivities = ({
    manufacturingScenarioId,
    onSuccesCallback,
}: {
    manufacturingScenarioId: string;
    onSuccesCallback: () => void;
}) => {
    const queryClient = useQueryClient();
    const token = getToken();

    return useMutation({
        mutationFn: (info: { values: ActivityConfigurationsPostDTO }) => {
            return http(
                'POST /activity-configurations/bulk',
                { requestBody: info.values, queryParams: {}, pathParams: {} },
                token,
            );
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: httpQueryKey('GET /manufacturing-scenarios/:manufacturingScenarioId/full-summary'),
            });
            onSuccesCallback();
        },
    });
};
