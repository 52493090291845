import { cases } from '../combinators/cases';
import { either } from '../combinators/either';
import { boolean } from '../parsers/boolean';
import { Parser } from '../parsers/Parser';

/**
 * Parses a non cancellable, non returnable flag.
 */
export const ncnr = (): Parser<boolean> => {
    return either(
        boolean(),
        cases({
            matches: [{ when: ['ncnr'], then: true }],
        }),
    );
};
