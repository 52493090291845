import { t, Trans } from '@lingui/macro';
import { isPresent } from '@luminovo/commons';
import { CheckableChip, Flexbox, PrimaryButton } from '@luminovo/design-system';
import { AssemblyResponseDTO, CustomerPortalAssemblyState, RfqDTO } from '@luminovo/http-client';
import { Send } from '@mui/icons-material';
import { styled } from '@mui/material';
import React from 'react';
import { useHistory, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { ViewContext } from '../../modules/Bom/components/ModuleTableData';
import { ButtonRequestQuotation } from '../../modules/RfqDashboard/ButtonRequestQuotation';
import { isEveryCustomerPortalStepDone } from '../../modules/RfqDashboard/CustomerDashboard/util/DashboardSteps';
import { Protected } from '../../permissions/Protected';
import { AssemblyTabsType } from '../../resources/assembly/assemblyFrontendTypes';
import { useIsBomDropzoneVisible } from '../../resources/assembly/assemblyHandler';
import { useQueryParams } from '../../resources/hooks';
import { useHttpQuery } from '../../resources/http/useHttpQuery';
import { useCustomerPortalState, useRfQ } from '../../resources/rfq/rfqHandler';
import { throwErrorUnlessProduction } from '../../utils/customConsole';
import { id } from '../../utils/ids';
import { findRouteId, route } from '../../utils/routes';
import { useIsCustomer } from '../contexts/CurrentUserDetailsContext';

export function parseDesignTab(tab: string | null | undefined): AssemblyTabsType {
    if (!tab) {
        return 'Bom';
    }
    // eslint-disable-next-line spellcheck/spell-checker
    if (tab === 'Bom' || tab === 'Cad' || tab === 'Manufacturing' || tab === 'Pnp') {
        return tab;
    }
    throwErrorUnlessProduction(new Error(`Unknown tab type: ${tab}`));
    return 'Bom';
}

interface TabConfig {
    isVisible: boolean;
    isDone: boolean;
}

type TabConfigs = Omit<
    {
        [Property in keyof CustomerPortalAssemblyState]: TabConfig;
    },
    'sourcing' | 'assembly_id'
>;

function useTabConfigs(rfqId: string, assembly: AssemblyResponseDTO, isCustomer: boolean): TabConfigs | undefined {
    const { data: customerState } = useHttpQuery(
        'POST /rfqs/:rfqId/customer-portal',
        {
            pathParams: { rfqId },
        },
        { enabled: isCustomer },
    );

    const { data: emsState, isError } = useHttpQuery(
        'GET /assemblies/:assemblyId/state',
        {
            pathParams: { assemblyId: assembly.id },
        },
        { enabled: !isCustomer },
    );

    if (!isCustomer) {
        const isPcbVisible = ['Pcba', 'PcbOnly'].includes(assembly.type.type);

        // If an error occurred, we mark all steps as not done
        if (!emsState || isError) {
            return {
                bom: { isVisible: true, isDone: false },
                pcb: { isVisible: isPcbVisible, isDone: false },
                cad: { isVisible: true, isDone: false },
                manufacturing: { isVisible: true, isDone: false },
                pnp: { isVisible: true, isDone: false },
            };
        }

        return {
            bom: { isVisible: true, isDone: emsState.is_bom_done },
            pcb: { isVisible: isPcbVisible, isDone: emsState.is_pcb_done },
            cad: { isVisible: true, isDone: emsState.is_cad_done },
            manufacturing: { isVisible: true, isDone: emsState.is_manufacturing_done },
            pnp: { isVisible: true, isDone: emsState.is_pnp_done },
        };
    }

    if (!customerState) {
        return undefined;
    }

    const assemblyState = customerState.assembly_states[assembly.id];
    if (!assemblyState) {
        return undefined;
    }

    /* eslint-disable spellcheck/spell-checker */
    return {
        bom: {
            isVisible: assemblyState.bom.requirement !== 'DontShow',
            isDone: assemblyState.bom.status.type === 'Done',
        },
        pcb: {
            isVisible: assemblyState.pcb.requirement !== 'DontShow',
            isDone: assemblyState.pcb.status.type === 'Done',
        },
        cad: {
            isVisible: assemblyState.cad.requirement !== 'DontShow',
            isDone: assemblyState.cad.status.type === 'Done',
        },
        manufacturing: {
            isVisible: assemblyState.manufacturing.requirement !== 'DontShow',
            isDone: assemblyState.manufacturing.status.type === 'Done',
        },
        pnp: {
            isVisible: assemblyState.pnp.requirement !== 'DontShow',
            isDone: assemblyState.pnp.status.type === 'Done',
        },
    };
    /* eslint-enable spellcheck/spell-checker */
}

export function useSelectedTab() {
    const { tab } = useQueryParams<'/rfqs/:rfqId/bom/assembly/:assemblyId'>();

    return parseDesignTab(tab);
}

const StyledLink = styled(Link)({
    textDecoration: 'none',
    color: 'unset',
    display: 'grid',
});

export const ProgressAwareTabs = React.memo(function ProgressAwareTabs({
    rfqId,
    assembly,
    tab,
    viewContext,
    currentParentAssemblyId,
}: {
    rfqId: string;
    assembly: AssemblyResponseDTO;
    currentParentAssemblyId: string | null | undefined;
    viewContext: ViewContext;
    tab?: AssemblyTabsType;
}) {
    const { pathname } = useLocation();

    const isCustomer = useIsCustomer();
    const tabConfigs = useTabConfigs(rfqId, assembly, isCustomer);

    const { id: assemblyId } = assembly;
    const { data: rfq } = useRfQ(rfqId);

    const isBomDropzoneVisible = useIsBomDropzoneVisible({ assemblyId });
    const routeId = findRouteId(pathname);

    const bomTabRoute: string = React.useMemo(() => {
        if (viewContext.type === 'WithinRfQ') {
            return isBomDropzoneVisible
                ? route(
                      '/rfqs/:rfqId/bom/assembly/:assemblyId',
                      { rfqId, assemblyId },
                      { currentParentAssemblyId, tab: null, monitoring: null },
                  )
                : route(
                      '/rfqs/:rfqId/bom/assembly/:assemblyId/details',
                      { rfqId, assemblyId },
                      {
                          isReadonly: null,
                          currentParentAssemblyId,
                          designItemId: null,
                          filters: null,
                          bomTab: null,
                          dashboardFilters: null,
                          search: null,
                          onlyShowItemsWithManufacturingWarnings: null,
                      },
                  );
        }
        return route('/assemblies/:assemblyId/details', { assemblyId });
    }, [assemblyId, currentParentAssemblyId, isBomDropzoneVisible, rfqId, viewContext.type]);

    const cadTabRoute: string = React.useMemo(() => {
        if (viewContext.type === 'AssemblyOverview') {
            return route(
                '/assemblies/:assemblyId/dashboard',
                { assemblyId },
                { tab: 'Cad', rfqId: assembly.rfq, isMonitoringOpen: undefined },
            );
        }
        return route(
            '/rfqs/:rfqId/bom/assembly/:assemblyId',
            { rfqId, assemblyId },
            { tab: 'Cad', monitoring: null, currentParentAssemblyId },
        );
    }, [assembly.rfq, assemblyId, currentParentAssemblyId, rfqId, viewContext.type]);

    const filesTabRoute: string = React.useMemo(() => {
        if (viewContext.type === 'AssemblyOverview') {
            return route(
                '/assemblies/:assemblyId/dashboard',
                { assemblyId },
                { tab: 'Manufacturing', rfqId: assembly.rfq, isMonitoringOpen: undefined },
            );
        }

        return route(
            '/rfqs/:rfqId/bom/assembly/:assemblyId',
            { rfqId, assemblyId },
            { tab: 'Manufacturing', monitoring: null, currentParentAssemblyId },
        );
    }, [assembly.rfq, assemblyId, currentParentAssemblyId, rfqId, viewContext.type]);

    const isBOMChipSelected = React.useMemo(() => {
        if (viewContext.type === 'WithinRfQ') {
            return isBomDropzoneVisible
                ? routeId === '/rfqs/:rfqId/bom/assembly/:assemblyId' && tab === 'Bom'
                : routeId === '/rfqs/:rfqId/bom/assembly/:assemblyId/details';
        }

        return (
            (routeId === '/assemblies/:assemblyId/dashboard' && tab === 'Bom') ||
            routeId === '/assemblies/:assemblyId/details'
        );
    }, [isBomDropzoneVisible, routeId, tab, viewContext.type]);

    const pnpTabRoute = React.useMemo(() => {
        if (viewContext.type === 'WithinRfQ')
            return route(
                '/rfqs/:rfqId/bom/assembly/:assemblyId/pnp-importer',
                { rfqId, assemblyId },
                { currentParentAssemblyId },
            );

        return route(
            '/assemblies/:assemblyId/dashboard',
            { assemblyId },
            { tab: 'Pnp', rfqId: assembly.rfq, isMonitoringOpen: undefined },
        );
    }, [assembly.rfq, assemblyId, currentParentAssemblyId, rfqId, viewContext.type]);

    const isPnPImporterTabSelected = React.useMemo(() => {
        if (viewContext.type === 'WithinRfQ') {
            return routeId === '/rfqs/:rfqId/bom/assembly/:assemblyId/pnp-importer';
        }
        return routeId === '/assemblies/:assemblyId/dashboard' && tab === 'Pnp';
    }, [routeId, tab, viewContext.type]);

    const isPcbTabSelected = React.useMemo(() => {
        return (
            routeId === '/rfqs/:rfqId/bom/assembly/:assemblyId/pcb' ||
            routeId === '/rfqs/:rfqId/bom/assembly/:assemblyId/pcb/files' ||
            routeId === '/rfqs/:rfqId/bom/assembly/:assemblyId/pcb/drills' ||
            routeId === '/rfqs/:rfqId/bom/assembly/:assemblyId/pcb/stackup' ||
            routeId === '/rfqs/:rfqId/bom/assembly/:assemblyId/pcb/specification' ||
            routeId === '/rfqs/:rfqId/bom/assembly/:assemblyId/pcb/cad-viewer' ||
            routeId === '/assemblies/:assemblyId/pcb' ||
            routeId === '/assemblies/:assemblyId/pcb/files' ||
            routeId === '/assemblies/:assemblyId/pcb/drills' ||
            routeId === '/assemblies/:assemblyId/pcb/stackup' ||
            routeId === '/assemblies/:assemblyId/pcb/specification' ||
            routeId === '/assemblies/:assemblyId/pcb/cad-viewer'
        );
    }, [routeId]);

    const pcbTabRoute = React.useMemo(() => {
        if (viewContext.type === 'WithinRfQ')
            return route(
                '/rfqs/:rfqId/bom/assembly/:assemblyId/pcb',
                { rfqId, assemblyId },
                { designItemId: null, isReadonly: null, currentParentAssemblyId },
            );

        return route('/assemblies/:assemblyId/pcb', { assemblyId }, { rfqId });
    }, [assemblyId, currentParentAssemblyId, rfqId, viewContext.type]);

    return (
        <Flexbox alignItems={'center'} gap={8}>
            <StyledLink
                style={{ display: tabConfigs?.bom.isVisible ? undefined : 'none' }}
                id={id('design/tab_bom')}
                to={bomTabRoute}
            >
                <CheckableChip done={tabConfigs?.bom.isDone} selected={isBOMChipSelected} label={t`BOM`} />
            </StyledLink>

            <StyledLink
                style={{ display: tabConfigs?.pcb.isVisible ? undefined : 'none' }}
                id={id('design/tab_pcb')}
                to={pcbTabRoute}
            >
                <CheckableChip done={tabConfigs?.pcb.isDone} selected={isPcbTabSelected} label={t`PCB`} />
            </StyledLink>

            <StyledLink
                style={{ display: tabConfigs?.pnp.isVisible ? undefined : 'none' }}
                id={id('design/tab_pnp')}
                to={pnpTabRoute}
            >
                <CheckableChip done={tabConfigs?.pnp.isDone} selected={isPnPImporterTabSelected} label={t`PnP`} />
            </StyledLink>

            <StyledLink
                style={{ display: tabConfigs?.cad.isVisible ? undefined : 'none' }}
                id={id('design/tab_cad')}
                to={cadTabRoute}
            >
                <CheckableChip done={tabConfigs?.cad.isDone} selected={tab === 'Cad'} label={t`CAD`} />
            </StyledLink>

            <StyledLink
                style={{ display: tabConfigs?.manufacturing.isVisible ? undefined : 'none' }}
                id={id('design/tab_files')}
                to={filesTabRoute}
            >
                <CheckableChip
                    done={tabConfigs?.manufacturing.isDone}
                    selected={tab === 'Manufacturing'}
                    label={t`Files`}
                />
            </StyledLink>

            {rfq && (
                <Protected requiredPermissions={() => isCustomer}>
                    <CustomerActionButton rfq={rfq} />
                </Protected>
            )}
        </Flexbox>
    );
});

export function ImportedAssemblyProgressTab({ assemblyId }: { assemblyId: string }) {
    const { pathname } = useLocation();

    const routeId = findRouteId(pathname);

    return (
        <Flexbox alignItems={'center'} gap={8}>
            <StyledLink id={id('design/tab_bom')} to={route('/assemblies/:assemblyId/details', { assemblyId })}>
                <CheckableChip
                    done={true}
                    selected={
                        routeId === '/assemblies/:assemblyId/dashboard' || routeId === '/assemblies/:assemblyId/details'
                    }
                    label={t`BOM`}
                />
            </StyledLink>

            <CheckableChip selected={false} disabled label={t`PCB`} />

            <CheckableChip disabled done={false} selected={false} label={t`PnP`} />

            <CheckableChip disabled done={false} selected={false} label={t`CAD`} />

            <CheckableChip done={false} disabled selected={false} label={t`Files`} />
        </Flexbox>
    );
}

function CustomerActionButton({ rfq }: { rfq: RfqDTO }): JSX.Element | null {
    const rfqId = rfq.id;

    const history = useHistory();
    const { data: customerPortalState } = useCustomerPortalState(rfqId);

    if (!isPresent(customerPortalState)) {
        return null;
    }

    const allStepsDone = isEveryCustomerPortalStepDone(customerPortalState);

    if (rfq.workflow_type === 'Automatic' && allStepsDone) {
        return (
            <PrimaryButton
                startIcon={<Send />}
                size="medium"
                onClick={async () => {
                    history.push(route('/rfqs/:rfqId/dashboard', { rfqId }));
                }}
                disabled={!allStepsDone}
            >
                <Trans>View available quotation</Trans>
            </PrimaryButton>
        );
    }

    return (
        <ButtonRequestQuotation
            rfqId={rfqId}
            customerPortalState={customerPortalState}
            overrides={{
                Button: (props) => {
                    return (
                        <PrimaryButton
                            id={id('design/button_request_quotation')}
                            startIcon={<Send />}
                            {...props}
                            size="medium"
                        />
                    );
                },
            }}
        />
    );
}
