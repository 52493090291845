import { colorSystem, SidebarLayout, Toolbar } from '@luminovo/design-system';
import * as React from 'react';
import { HomeNavbar } from '../../../components/Navbar';
import { route } from '../../../utils/routes';
import { NegotiationsContextProvider } from '../context/NegotiationsContext';
import { useNegotiation, useNegotiationLineItems, useQuoteRequest } from '../hooks/negotiationHandlers';
import { formatPartLite } from '../model/formatPartLite';
import { formatQuoteRequest } from '../model/formatQuoteRequest';
import { getNegotiationLineItemParts } from '../model/getNegotiationLineItemParts';

function NegotiationsToolbarFallback() {
    return (
        <Toolbar
            hideLastBreadcrumb={true}
            breadcrumbs={[
                {
                    title: 'Negotiations',
                    href: route('/negotiations'),
                },
                {
                    title: '...',
                },
            ]}
        />
    );
}

export function NegotiationsLayout({
    children,
    actions,
    breadcrumbsTitle,
    negotiationId,
    negotiationLineItemId,
    quoteRequestId,
    style,
    leftSidebar,
}: {
    children: React.ReactNode;
    actions?: React.ReactNode;
    breadcrumbsTitle?: string;
    negotiationId?: number;
    negotiationLineItemId?: number;
    quoteRequestId?: string;
    style?: React.CSSProperties;
    leftSidebar?: React.ReactNode;
}) {
    return (
        <NegotiationsContextProvider negotiationId={negotiationId}>
            <SidebarLayout
                contentBackground={colorSystem.neutral[1]}
                navbar={<HomeNavbar />}
                sidebar={leftSidebar}
                content={children}
                defaultSidebarWidth="400px"
                style={style}
                fallback={() => <></>}
                toolbar={
                    <React.Suspense fallback={<NegotiationsToolbarFallback />}>
                        <NegotiationsLayoutToolbar
                            negotiationId={negotiationId}
                            negotiationLineItemId={negotiationLineItemId}
                            quoteRequestId={quoteRequestId}
                            breadcrumbsTitle={breadcrumbsTitle}
                            actions={actions}
                        />
                    </React.Suspense>
                }
            />
        </NegotiationsContextProvider>
    );
}

function NegotiationsLayoutToolbar({
    breadcrumbsTitle,
    negotiationId,
    negotiationLineItemId,
    quoteRequestId,
    actions,
}: {
    breadcrumbsTitle?: string;
    negotiationId?: number;
    negotiationLineItemId?: number;
    quoteRequestId?: string;
    actions?: React.ReactNode;
}) {
    const breadcrumbNegotiation = useBreadcrumbsNegotiation(negotiationId);
    const breadcrumbNegotiationLineItem = useBreadcrumbsNegotiationLineItem(negotiationId, negotiationLineItemId);
    const breadcrumbQuoteRequest = useBreadcrumbsQuoteRequest(negotiationId, quoteRequestId);

    return (
        <Toolbar
            breadcrumbs={[
                {
                    title: 'Negotiations',
                    href: route('/negotiations'),
                },
                ...breadcrumbNegotiation,
                ...breadcrumbNegotiationLineItem,
                ...breadcrumbQuoteRequest,
                ...[
                    {
                        title: breadcrumbsTitle ?? '',
                    },
                ].filter((x) => x.title),
            ]}
        >
            {actions}
        </Toolbar>
    );
}

function useBreadcrumbsNegotiation(negotiationId?: number) {
    const { data: negotiation } = useNegotiation(negotiationId);

    if (negotiationId === undefined) {
        return [];
    }

    return [
        {
            href: route('/negotiations/:negotiationId/summary/line-items', { negotiationId }),
            title: negotiation?.name ?? '',
        },
    ];
}

function useBreadcrumbsNegotiationLineItem(negotiationId?: number, negotiationLineItemId?: number) {
    const { data: negotiationLineItem } = useNegotiationLineItems(negotiationId);
    const lineItem = negotiationLineItem?.find((item) => item.id === negotiationLineItemId);

    if (negotiationId === undefined || !lineItem) {
        return [];
    }

    return [
        {
            href: route('/negotiations/:negotiationId/summary/line-items', { negotiationId }),
            title: getNegotiationLineItemParts(lineItem).map(formatPartLite).join(', '),
        },
    ];
}

function useBreadcrumbsQuoteRequest(negotiationId?: number, quoteRequestId?: string) {
    const { data: quoteRequest } = useQuoteRequest(quoteRequestId);

    if (quoteRequestId === undefined || !quoteRequest || !negotiationId) {
        return [];
    }

    return [
        {
            href: route('/negotiations/:negotiationId/quote-requests/:quoteRequestId', {
                negotiationId,
                quoteRequestId,
            }),
            title: formatQuoteRequest(quoteRequest),
        },
    ];
}
