import { isPresent, omit } from '@luminovo/commons';
import { ArrowDropDownRounded } from '@mui/icons-material';
import { Autocomplete, Box, styled } from '@mui/material';
import * as React from 'react';
import { colorSystem } from '../../../theme';
import { Checkbox } from '../../Checkbox';
import { Chip } from '../../Chip';
import { Flexbox } from '../../Flexbox';
import { Text } from '../../Text';
import { assertUniqueOptionLables } from '../FieldSelect/FieldSelect';
import { FieldText } from '../FieldText';
import { VirualizedGroupContainer, generateVirtualizeProps } from './VirtualizeComponents';
import { FieldMultiSelectProps } from './types';

const GroupHeader = styled('div')({
    position: 'sticky',
    top: '-8px',
    padding: '4px 10px',
    backgroundColor: colorSystem.neutral.white,
    zIndex: 1,
});

const GroupItems = styled('ul')({
    padding: 0,
});

function FieldMultiSelectInner<TValue>(
    {
        value,
        options,
        virtualized = false,
        onChange,
        getOptionKey,
        getOptionLabel,
        getOptionColor = () => 'neutral',
        renderOption,
        placeholder,
        error,
        helperText,
        renderGroupLabel,
        chipBoxProps,
        ...rest
    }: FieldMultiSelectProps<TValue>,
    outerRef: React.ForwardedRef<unknown>,
): JSX.Element {
    assertUniqueOptionLables({ options, getOptionLabel, getOptionKey });
    const virtualizeProps = virtualized ? generateVirtualizeProps() : {};

    return (
        <Autocomplete
            multiple={true}
            autoSelect={true}
            ref={outerRef}
            // TODO: Our type system doesn't detect when TValue is `null`, we need to fix this.
            value={value ?? []}
            options={options}
            onChange={(event, value) => {
                // Ignores "blur" events caused by disableCloseOnSelect to not change the selections unintended.
                if (rest.disableCloseOnSelect && event.type === 'blur') {
                    /* no-op */
                } else {
                    onChange(value);
                }
            }}
            getOptionLabel={getOptionLabel}
            isOptionEqualToValue={(option, value) => getOptionKey(option) === getOptionKey(value)}
            renderTags={(value, getTagProps) => {
                const hasChipBoxProps = isPresent(chipBoxProps);
                const chip = value.map((item, index) => (
                    <Chip
                        label={getOptionLabel(item)}
                        color={getOptionColor(item)}
                        style={{ marginLeft: '4px', marginRight: '4px', marginTop: '2px', marginBottom: '2px' }}
                        {...getTagProps({ index })}
                    />
                ));

                if (hasChipBoxProps) {
                    return <Box {...chipBoxProps}>{chip}</Box>;
                }

                return chip;
            }}
            renderOption={
                isPresent(renderOption)
                    ? (props, option, state) => <li {...props}>{renderOption(option, state)}</li>
                    : (props, option, { selected }: { selected: boolean }) => (
                          <li {...props}>
                              <Flexbox key={getOptionKey(option)} gap={8} alignItems={'center'}>
                                  <Checkbox checked={selected} size={'small'} />
                                  <Text variant="body" color={colorSystem.neutral[8]}>
                                      {getOptionLabel(option)}
                                  </Text>
                              </Flexbox>
                          </li>
                      )
            }
            renderInput={(props) => (
                // Hack: `value` and `onChange` are requried but have no effect because
                // the FieldText internal values are overridden by `props.inputProps.value`.
                <FieldText
                    {...props}
                    type="text"
                    placeholder={placeholder}
                    value={null}
                    onChange={() => {}}
                    error={error}
                    helperText={helperText}
                />
            )}
            renderGroup={
                isPresent(renderGroupLabel) && virtualized
                    ? (params) => (
                          <VirualizedGroupContainer innerChildren={params.children} label={renderGroupLabel(params)} />
                      )
                    : (params) => (
                          <li key={params.key}>
                              <GroupHeader>
                                  <Text variant="h5" color={colorSystem.neutral[8]}>
                                      {params.group}
                                  </Text>
                              </GroupHeader>
                              <GroupItems>{params.children}</GroupItems>
                          </li>
                      )
            }
            autoHighlight={true}
            popupIcon={<ArrowDropDownRounded />}
            {...virtualizeProps}
            {...omit(rest, 'inputRef')}
        />
    );
}

// To restrict the type of props that can be passed into the FieldSelectInner, https://stackoverflow.com/a/58473012
declare module 'react' {
    function forwardRef<T, P = {}>(
        render: (props: P, ref: React.Ref<T>) => React.ReactElement | null,
    ): (props: P & React.RefAttributes<T>) => React.ReactElement | null;
}
export const FieldMultiSelect = React.forwardRef(FieldMultiSelectInner);
