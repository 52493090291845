import { t } from '@lingui/macro';
import { getToken, isExpired } from '@luminovo/auth';
import { isProductionEnvironment } from '@luminovo/commons';
import { Flexbox, PrimaryButton, TertiaryButton } from '@luminovo/design-system';
import { HttpError } from '@luminovo/http-client';
import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import * as React from 'react';
import { ACCESS_TOKEN_QUERY_KEY } from '../../components/contexts/Auth0TokenContext';
import { transEnum } from '../../components/localization/TransEnum';
import { errorCodeTranslations } from '../../modules/Error/errorCodeTranslations';
import { logToExternalErrorHandlers } from '../../utils/customConsole';

const snackbarKey = 'debug-error-handler';

type GlobalErrorHandler = {
    onError: (options: {
        /**
         * The error that should be handled.
         */
        error: unknown;
        /**
         * If true, the error will not be displayed to the user.
         */
        silent: boolean;
        /**
         * If true, error with the error code `unknown` will not be displayed to the user.
         */
        silentUnknownError: boolean;
    }) => void;
};

export function useGlobalErrorHandler(): GlobalErrorHandler {
    const showDebugErrorMessage = useShowDebugErrorMessage();
    const { enqueueSnackbar } = useSnackbar();

    const onError = React.useCallback(
        ({ error, silent, silentUnknownError }: { error: unknown; silent: boolean; silentUnknownError: boolean }) => {
            const { known, message } = composeErrorMessage(error);

            if (error instanceof Error && !known) {
                logToExternalErrorHandlers(error);
            }

            if (!(error instanceof Error) && !known) {
                logToExternalErrorHandlers(new Error('An unknown error occurred: ' + JSON.stringify(error)));
            }

            const isUnknownAndShouldBeIgnored = silentUnknownError && !known;
            if ((isProductionEnvironment() || known) && !silent && !isUnknownAndShouldBeIgnored) {
                enqueueSnackbar(message, { key: message, variant: 'error', preventDuplicate: true });
            }

            if (!isProductionEnvironment()) {
                if (!silent) {
                    showDebugErrorMessage(error);
                } else {
                    // eslint-disable-next-line
                    console.error(error);
                }
            }
        },
        [enqueueSnackbar, showDebugErrorMessage],
    );

    return { onError };
}

/**
 * A hook that provides an error handler that displays the error and it's stack
 * trace in a snackbar.
 *
 * @param option.silent If true, the error will not be displayed in production. Defaults to false.
 */
export function useDebugErrorHandler(): (error: unknown) => void {
    const queryClient = useQueryClient();
    const showDebugErrorMessage = useShowDebugErrorMessage();
    const { enqueueSnackbar } = useSnackbar();

    return (error: unknown) => {
        const { status, known, message } = composeErrorMessage(error);

        if (error instanceof Error && !known) {
            logToExternalErrorHandlers(error);
        }

        if (!(error instanceof Error) && !known) {
            logToExternalErrorHandlers(new Error('An unknown error occurred: ' + JSON.stringify(error)));
        }

        if (status === 401 && isExpired(getToken())) {
            queryClient.invalidateQueries({ queryKey: ACCESS_TOKEN_QUERY_KEY });
        }

        if (isProductionEnvironment() || known) {
            enqueueSnackbar(message, { key: message, variant: 'error', preventDuplicate: true });
        }

        if (!isProductionEnvironment()) {
            showDebugErrorMessage(error);
        }
    };
}

function composeErrorMessage(error: unknown) {
    if (error instanceof HttpError) {
        return {
            status: error.status,
            known: Boolean(errorCodeTranslations[error.code]) && error.code !== 'unknown',
            message: transEnum(error.code, errorCodeTranslations),
        };
    }
    return {
        status: undefined,
        known: false,
        message: t`Sorry, there was a problem. Please try again later or contact our customer support.`,
    };
}

function useShowDebugErrorMessage() {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    return React.useCallback(
        (error: unknown) => {
            if (!(error instanceof Error)) {
                // eslint-disable-next-line
                console.error(error);
                return;
            }

            enqueueSnackbar('', {
                key: snackbarKey,
                preventDuplicate: true,
                content: (
                    <div
                        style={{
                            /* eslint-disable spellcheck/spell-checker */
                            marginTop: '24px',
                            padding: '16px',
                            borderRadius: '8px',
                            background: '#FFF0F1',
                            color: '#FF001F',
                            fontSize: '16px',
                            overflow: 'auto',
                            width: 'calc(100vw - 72px)',
                            height: 'calc(60vh - 72px)',
                            boxShadow: `0.3px 0.5px 0.7px hsl(0deg 0% 63% / 0.36),
                                0.8px 1.6px 2px -0.8px hsl(0deg 0% 63% / 0.36),
                                2.1px 4.1px 5.2px -1.7px hsl(0deg 0% 63% / 0.36),
                                5px 10px 12.6px -2.5px hsl(0deg 0% 63% / 0.36)`,
                        }}
                    >
                        <Flexbox gap={8}>
                            <PrimaryButton onClick={() => closeSnackbar(snackbarKey)}>Close</PrimaryButton>
                            <TertiaryButton onClick={() => navigator.clipboard.writeText(error.message)}>
                                Copy
                            </TertiaryButton>
                        </Flexbox>
                        <pre>{error.stack}</pre>
                    </div>
                ),
                autoHideDuration: null,
            });
        },
        [enqueueSnackbar, closeSnackbar],
    );
}
