import { isPresent } from '@luminovo/commons';
import type { PDFDocumentProxy } from 'pdfjs-dist';
import React, { useContext } from 'react';
import { Mode, PdfViewerState } from '../model/PdfViewerState';
import { ImmutableRegionNetwork, RegionNetwork } from '../model/RegionNetwork/RegionNetwork';
import { updateAttributes } from '../model/RegionNetwork/attributes/updateAttribute';
import { link } from '../model/RegionNetwork/link';
import { Attribute, Region } from '../model/RegionNetwork/types';
import { BoundingBox } from '../model/boundingBox';
const initialState: PdfViewerState = {
    regs: new ImmutableRegionNetwork([]),
    mode: { type: 'default' },
    attributes: ['Unit price', 'MOQ', 'MPQ', 'Stock', 'Std. Factory lead time', 'Supplier part number', 'Packaging'],
    previousState: undefined,
    pdfDocumentProxy: undefined,
};

type ActionAssociateRegion = {
    type: 'associateRegion';
    regionIdentifier: Region;
    boundingBox: BoundingBox;
    attribute: string;
};

type Action =
    | {
          type: 'setRegions';
          regions: RegionNetwork;
      }
    | {
          type: 'setMode';
          mode: Mode;
      }
    | ActionAssociateRegion
    | { type: 'escape' }
    | { type: 'clearAssociations'; region: Region }
    | { type: 'approveSuggestions' }
    | { type: 'historyUndo' }
    | { type: 'historyReset' }
    | { type: 'removeSuggestion'; regionIds: string[] }
    | { type: 'addAttributes'; regionId: string; attrs: Attribute[] }
    | { type: 'setSelectedCell'; selectedCell?: { rowIndex: number; columnIndex: number } }
    | { type: 'selectNextCell' }
    | { type: 'addManualRow'; attributes?: Attribute[] }
    | { type: 'setPdfDocumentProxy'; pdfDocumentProxy: PDFDocumentProxy };

function reducer(state: PdfViewerState, action: Action): PdfViewerState {
    switch (action.type) {
        case 'historyUndo': {
            return state.previousState ?? state;
        }
        case 'historyReset': {
            return { ...state, previousState: undefined };
        }

        case 'selectNextCell': {
            const selectedCell = state.selectedCell;
            if (!selectedCell) {
                return state;
            }
            const { rowIndex, columnIndex } = selectedCell;
            const nextRowIndex = rowIndex + 1;
            const nextColumnIndex = columnIndex;
            return {
                ...state,
                selectedCell: {
                    rowIndex: nextRowIndex,
                    columnIndex: nextColumnIndex,
                },
            };
        }
        case 'setSelectedCell': {
            return {
                ...state,
                selectedCell: action.selectedCell,
            };
        }
        case 'addAttributes': {
            const { regionId, attrs } = action;
            const newRegs = state.regs.updateRegions({ id: regionId }, (r) => {
                const newAttributes = updateAttributes(r.attributes, attrs);

                return {
                    ...r,
                    attributes: newAttributes,
                };
            });
            const newLinks = link({ db: newRegs });
            const withLinks = newRegs.setLinks(newLinks);

            return appendHistory(
                {
                    ...state,
                    regs: withLinks,
                },
                state,
            );
        }

        case 'setRegions': {
            return {
                ...state,
                regs: action.regions,
                previousState: undefined,
            };
        }

        case 'clearAssociations': {
            return appendHistory(
                {
                    ...state,
                    regs: state.regs.removeLinks({ type: 'link', with: action.region }),
                },
                state,
            );
        }

        case 'setMode': {
            if (action.mode.type === 'inspect') {
                state.regs.findRegions({ ids: action.mode.selectedRegionIds });
            }

            return {
                ...state,
                mode: action.mode,
            };
        }
        case 'associateRegion': {
            return appendHistory(
                {
                    ...state,
                    // TODO(fhur)
                },
                state,
            );
        }
        case 'escape': {
            return {
                ...state,
                regs: state.regs.removeLinks({ type: 'suggestion' }),
                mode: { type: 'default' },
            };
        }

        case 'addManualRow': {
            const rows = state.regs.findRegions({ attribute: 'isRow' }).flatMap((r) => {
                return r.attributes.map((a) => (a.attr === 'isRow' ? a.value : undefined)).filter(isPresent);
            });
            const nextRowId = Math.max(0, ...rows) + 1;

            function appendEmptyPart(attributes: Attribute[]): Attribute[] {
                if (attributes.some((a) => a.attr === 'part')) {
                    return attributes;
                }
                return [
                    ...attributes,
                    {
                        attr: 'part',
                        value: null,
                    },
                ];
            }

            const reg: Region = {
                id: 'manual-row' + nextRowId,
                content: '',
                box: new BoundingBox(0, nextRowId, 0, 0),
                pageNumber: Infinity,
                attributes: appendEmptyPart([
                    {
                        attr: 'isRow',
                        value: nextRowId,
                    },
                    ...(action.attributes ?? []),
                ]),
            };

            const newRegs = state.regs.addRegion(reg);

            return {
                ...state,
                regs: newRegs,
            };
        }

        case 'setPdfDocumentProxy': {
            return {
                ...state,
                pdfDocumentProxy: action.pdfDocumentProxy,
            };
        }

        case 'approveSuggestions': {
            // TODO(fhur)
            return state;
        }
        case 'removeSuggestion': {
            // TODO(fhur)
            return state;
        }
    }
}

function appendHistory(newState: PdfViewerState, oldState: PdfViewerState): PdfViewerState {
    return {
        ...newState,
        previousState: oldState,
    };
}

const PdfViewerContext = React.createContext<[PdfViewerState, React.Dispatch<Action>]>([initialState, () => {}]);

export const PdfViewerContextProvider = (props: React.PropsWithChildren<{}>): JSX.Element => {
    const value = React.useReducer(reducer, initialState);
    return <PdfViewerContext.Provider value={value}>{props.children}</PdfViewerContext.Provider>;
};

export function usePdfViewerState(): [PdfViewerState, React.Dispatch<Action>] {
    return useContext(PdfViewerContext);
}
