import { Trans } from '@lingui/macro';
import { colorSystem, columnWidth, Flexbox, Tab, Tabs } from '@luminovo/design-system';
import { ActivityConfigurationSummaryDTO, CalculationType, Duration, VariableTime } from '@luminovo/http-client';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { transEnum } from '../../../../../components/localization/TransEnum';
import { TabPanel } from '../../../../../components/TabPanel';
import { durationUnitPublicTranslations } from '../../../../../resources/activity/activityBackendTypes';
import { assertUnreachable } from '../../../../../utils/typingUtils';
import { CalculationTypeCell, DriverCell } from '../../../shared/manufacturingComponents';
import { doesUnitActivityConfigurationHaveBatchTimeCalculation } from '../../activitiesTableComponents';
import {
    getActivityFixedTime,
    getActivityTimeCalculation,
    getActivityVariableTime,
} from '../../activityConfigurationDrawerFunctions';
import { FormulaOfActivity } from './FormulaOfActivity';

const StyledTypography = styled(Typography)({
    color: colorSystem.neutral[8],
    marginBlockEnd: '8px',
});

const DisplayFixedActivityTimeCalculation = ({
    calculationType,
    fixedUnitTime,
}: {
    calculationType: CalculationType;
    fixedUnitTime: Duration;
}): JSX.Element => {
    return (
        <Flexbox gap={'8px'} flexDirection={'column'}>
            <CalculationTypeCell calculationType={calculationType} />
            <Typography variant={'body1'}>
                {fixedUnitTime?.amount} {transEnum(fixedUnitTime?.unit, durationUnitPublicTranslations)}
            </Typography>
        </Flexbox>
    );
};

const DisplayLinearActivityTimeCalculation = ({
    calculationType,
    variableUnitTime,
    fixedUnitTime,
}: {
    calculationType: CalculationType;
    variableUnitTime: VariableTime;
    fixedUnitTime?: Duration | null;
}): JSX.Element => {
    return (
        <Flexbox gap={'8px'} flexDirection={'column'}>
            <CalculationTypeCell calculationType={calculationType} />
            <Flexbox gap={'8px'} alignItems="center" flexWrap={'wrap'}>
                <Typography variant={'body1'}>
                    {variableUnitTime?.variable_unit.amount}{' '}
                    {transEnum(variableUnitTime?.variable_unit.unit, durationUnitPublicTranslations)}
                </Typography>
                <Typography style={{ color: colorSystem.neutral[6] }}>x</Typography>
                <DriverCell textMaxWidth={columnWidth.extraLarge} driverId={variableUnitTime.driver} />
                {fixedUnitTime && (
                    <>
                        <Typography style={{ color: colorSystem.neutral[6] }}>+</Typography>
                        <Typography variant={'body1'}>
                            {fixedUnitTime?.amount} {transEnum(fixedUnitTime?.unit, durationUnitPublicTranslations)}
                        </Typography>
                    </>
                )}
            </Flexbox>
        </Flexbox>
    );
};

const getLinearTimeCalculation = (activity: ActivityConfigurationSummaryDTO) => {
    const { batchVariableTime, unitBatchVariableTime, projectVariableTime } = getActivityVariableTime(activity);
    const { batchTimeCalculation, unitBatchTimeCalculation, projectTimeCalculation } = getActivityFixedTime(activity);

    switch (activity.activity_configuration_details.cost_components.level) {
        case 'Unit':
            if (!doesUnitActivityConfigurationHaveBatchTimeCalculation(activity)) return <></>;
            const activityUnitBatchTimeCalculation = getActivityTimeCalculation(activity, 'Batch');
            if (activityUnitBatchTimeCalculation !== 'Linear') {
                throw new Error('activity batch time calculation should be linear');
            }
            return (
                <>
                    {unitBatchTimeCalculation.fixed && (
                        <DisplayFixedActivityTimeCalculation
                            calculationType={activityUnitBatchTimeCalculation}
                            fixedUnitTime={unitBatchTimeCalculation.fixed}
                        />
                    )}
                    {unitBatchVariableTime && (
                        <DisplayLinearActivityTimeCalculation
                            calculationType={activityUnitBatchTimeCalculation}
                            variableUnitTime={unitBatchVariableTime}
                            fixedUnitTime={unitBatchTimeCalculation.linear}
                        />
                    )}
                </>
            );

        case 'Batch':
            const activityBatchTimeCalculation = getActivityTimeCalculation(activity, 'Batch');
            if (activityBatchTimeCalculation !== 'Linear') {
                throw new Error('activity batch time calculation should be linear');
            }
            return (
                <>
                    {batchTimeCalculation.fixed && (
                        <DisplayFixedActivityTimeCalculation
                            calculationType={activityBatchTimeCalculation}
                            fixedUnitTime={batchTimeCalculation.fixed}
                        />
                    )}

                    {batchVariableTime && (
                        <DisplayLinearActivityTimeCalculation
                            calculationType={activityBatchTimeCalculation}
                            variableUnitTime={batchVariableTime}
                            fixedUnitTime={batchTimeCalculation.linear}
                        />
                    )}
                </>
            );

        case 'Project':
            const activityProjectTimeCalculation = getActivityTimeCalculation(activity, 'Project');
            if (activityProjectTimeCalculation !== 'Linear') {
                throw new Error('activity project time calculation should be linear');
            }
            return (
                <>
                    {projectTimeCalculation.fixed && (
                        <DisplayFixedActivityTimeCalculation
                            calculationType={activityProjectTimeCalculation}
                            fixedUnitTime={projectTimeCalculation.fixed}
                        />
                    )}

                    {projectVariableTime && (
                        <DisplayLinearActivityTimeCalculation
                            calculationType={activityProjectTimeCalculation}
                            variableUnitTime={projectVariableTime}
                            fixedUnitTime={batchTimeCalculation.linear}
                        />
                    )}
                </>
            );

        default:
            assertUnreachable(activity.activity_configuration_details.cost_components);
    }
};

const getFixedTimeCalculation = (
    activity: ActivityConfigurationSummaryDTO,
    timeLevel: 'Batch' | 'Project',
): JSX.Element => {
    const activityTimeCalculation = getActivityTimeCalculation(activity, timeLevel);
    if (activityTimeCalculation !== 'Fixed') {
        throw new Error('activity batch time calculation should be fixed');
    }
    const level = activity.activity_configuration_details.cost_components.level;
    const { batchTimeCalculation, unitBatchTimeCalculation, projectTimeCalculation } = getActivityFixedTime(activity);

    switch (level) {
        case 'Batch':
            return (
                <>
                    {batchTimeCalculation.fixed && (
                        <DisplayFixedActivityTimeCalculation
                            calculationType={activityTimeCalculation}
                            fixedUnitTime={batchTimeCalculation.fixed}
                        />
                    )}
                </>
            );
        case 'Unit':
            return (
                <>
                    {unitBatchTimeCalculation.fixed && (
                        <DisplayFixedActivityTimeCalculation
                            calculationType={activityTimeCalculation}
                            fixedUnitTime={unitBatchTimeCalculation.fixed}
                        />
                    )}
                </>
            );
        case 'Project':
            return (
                <>
                    {projectTimeCalculation.fixed && (
                        <DisplayFixedActivityTimeCalculation
                            calculationType={activityTimeCalculation}
                            fixedUnitTime={projectTimeCalculation.fixed}
                        />
                    )}
                </>
            );
        default:
            assertUnreachable(level);
    }
};

const BatchActivityTimeCalculation = ({ activity }: { activity: ActivityConfigurationSummaryDTO }): JSX.Element => {
    const activityBatchTimeCalculation = getActivityTimeCalculation(activity, 'Batch');

    switch (activityBatchTimeCalculation) {
        case undefined:
            return <></>;

        case 'Fixed':
            return getFixedTimeCalculation(activity, 'Batch');

        case 'Linear':
            return getLinearTimeCalculation(activity);

        case 'Formula':
            return (
                <FormulaOfActivity
                    activityId={activity.activity_configuration_details.activity_id}
                    timeCalculation={activity.activity_configuration_details.activity_time_calculation}
                    timeLevel={'Batch'}
                />
            );

        default:
            assertUnreachable(activityBatchTimeCalculation);
    }
};

const ProjectActivityTimeCalculation = ({ activity }: { activity: ActivityConfigurationSummaryDTO }): JSX.Element => {
    const activityProjectTimeCalculation = getActivityTimeCalculation(activity, 'Project');

    switch (activityProjectTimeCalculation) {
        case undefined:
            return <></>;

        case 'Fixed':
            return getFixedTimeCalculation(activity, 'Project');

        case 'Linear':
            return getLinearTimeCalculation(activity);

        case 'Formula':
            return (
                <FormulaOfActivity
                    activityId={activity.activity_configuration_details.activity_id}
                    timeCalculation={activity.activity_configuration_details.activity_time_calculation}
                    timeLevel={'Project'}
                />
            );

        default:
            assertUnreachable(activityProjectTimeCalculation);
    }
};

const UnitActivityTimeCalculation = ({ activity }: { activity: ActivityConfigurationSummaryDTO }): JSX.Element => {
    const activityUnitTimeCalculation = getActivityTimeCalculation(activity, 'Unit');

    if (activityUnitTimeCalculation === undefined) {
        return <></>;
    }
    const { unitVariableTime } = getActivityVariableTime(activity);
    const { unitTimeCalculation } = getActivityFixedTime(activity);

    switch (activityUnitTimeCalculation) {
        case 'Fixed':
            return (
                <>
                    {unitTimeCalculation.fixed && (
                        <DisplayFixedActivityTimeCalculation
                            calculationType={activityUnitTimeCalculation}
                            fixedUnitTime={unitTimeCalculation.fixed}
                        />
                    )}
                </>
            );
        case 'Linear':
            return (
                <>
                    {unitVariableTime && (
                        <DisplayLinearActivityTimeCalculation
                            calculationType={activityUnitTimeCalculation}
                            variableUnitTime={unitVariableTime}
                            fixedUnitTime={unitTimeCalculation.linear}
                        />
                    )}
                </>
            );
        case 'Formula':
            return (
                <FormulaOfActivity
                    activityId={activity.activity_configuration_details.activity_id}
                    timeCalculation={activity.activity_configuration_details.activity_time_calculation}
                    timeLevel={'Unit'}
                />
            );
    }
};

export const ActivityConfigurationTimeCalculation = ({
    activity,
}: {
    activity: ActivityConfigurationSummaryDTO;
}): JSX.Element => {
    const [activeTab, setActiveTab] = React.useState<string>();
    const activityLevel = activity.activity_configuration_details.cost_components.level;
    const doesUnitActivityHaveBatchCalculation = doesUnitActivityConfigurationHaveBatchTimeCalculation(activity);

    let selectedTab: string;
    switch (activityLevel) {
        case 'Batch':
            selectedTab = 'batchLevel';
            break;
        case 'Project':
            selectedTab = 'projectLevel';
            break;
        case 'Unit':
            selectedTab = 'unitLevel';
            break;
    }

    React.useEffect(() => {
        setActiveTab(selectedTab);
    }, [selectedTab]);
    const handleTabChange = React.useCallback((_event, value) => setActiveTab(value), [setActiveTab]);

    return (
        <Box padding={'24px'} style={{ gap: '16px' }}>
            <StyledTypography variant="h3">
                <Trans>Time calculation</Trans>
            </StyledTypography>

            {doesUnitActivityHaveBatchCalculation && (
                <Tabs value={activeTab} size="small" onChange={handleTabChange}>
                    <Tab value="unitLevel" label={<Trans>Unit level</Trans>} />
                    <Tab value="batchLevel" label={<Trans>Batch level</Trans>} />
                </Tabs>
            )}

            <TabPanel value={activeTab} index="unitLevel">
                {/* Unit level, unit time calculation */}
                <UnitActivityTimeCalculation activity={activity} />
            </TabPanel>

            <TabPanel value={activeTab} index="batchLevel">
                {/* Batch level, time calculation OR Unit level, batch time calculation */}
                <BatchActivityTimeCalculation activity={activity} />
            </TabPanel>

            <TabPanel value={activeTab} index="projectLevel">
                {/* Project level time calculation */}
                <ProjectActivityTimeCalculation activity={activity} />
            </TabPanel>
        </Box>
    );
};
