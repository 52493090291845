import { Trans } from '@lingui/macro';
import { isPresent } from '@luminovo/commons';
import { colorSystem, Flexbox, Message, TertiaryButton, Text } from '@luminovo/design-system';
import { CommentEntity, UserType } from '@luminovo/http-client';
import { Check as CheckIcon, Lock, Public } from '@mui/icons-material';
import { Divider } from '@mui/material';
import { useIsMutating } from '@tanstack/react-query';
import React from 'react';
import {
    QUERY_KEY_MUTATION_CREATE_COMMENT,
    useComments,
    useMutationUpdateComment,
} from '../../resources/comments/commentsHandler';
import { useCurrentUserDetailsContext, useUserType } from '../contexts/CurrentUserDetailsContext';
import { CloseDrawerButton } from '../contexts/ModalContext';
import { AssemblyCommentsBox, CommentsBox, CommunicationsDrawerThread } from './CommentsBox';

interface Props {
    threads: Array<CommunicationsDrawerThread>;
}

export function CommentsDrawer({ threads }: Props): JSX.Element {
    const userType = useUserType();

    switch (userType) {
        case UserType.Internal:
            return <InternalUserDrawer threads={threads} />;
        case UserType.Customer:
        case UserType.Supplier:
            return <ExternalUserDrawer threads={threads} />;
    }
}

function ExternalUserDrawer({ threads }: Props): JSX.Element {
    const orgName = useCurrentUserDetailsContext().organization.name;
    const publicThread = threads.find((c) => c.category === 'Public');

    return (
        <Flexbox flexDirection={'column'} paddingBottom={'200px'}>
            <Flexbox alignItems="center" justifyContent="space-between" paddingRight={'8px'}>
                <CloseDrawerButton />
                {isPresent(publicThread) && <ResolveCommentsButton thread={publicThread} />}
            </Flexbox>
            <Flexbox paddingX="16px" gap={12} flexDirection={'column'}>
                <Text variant="h3">
                    <Trans>Comments</Trans>
                </Text>

                <Message
                    size="small"
                    variant="blue"
                    attention="low"
                    message={
                        <Trans>
                            Use comments to engage with {orgName} and ask questions.
                            <br /> Mention {orgName} members directly with the '@' symbol
                        </Trans>
                    }
                />
            </Flexbox>
            <Divider style={{ marginTop: '12px' }} />

            <Flexbox padding=" 8px 16px" paddingBottom={'0px'} flexDirection={'column'}>
                {threads
                    .filter((c) => c.category === 'Public')
                    .map((thread, i) => {
                        return (
                            <React.Fragment key={i}>
                                {(() => {
                                    switch (thread.commentType) {
                                        case 'Assembly':
                                            return (
                                                <AssemblyCommentsBox thread={thread} assemblyId={thread.assemblyId} />
                                            );
                                        case 'QuoteRequest':
                                            //TODO: we should create a CommentsBox for QuoteRequest and remove the rfqId
                                            return <CommentsBox thread={thread} rfqId={'quoteRequestRfQId1'} />;
                                        default:
                                            return <CommentsBox thread={thread} rfqId={thread.rfqId} />;
                                    }
                                })()}
                                <Divider />
                            </React.Fragment>
                        );
                    })}
            </Flexbox>
        </Flexbox>
    );
}

function InternalUserDrawer({ threads }: Props): JSX.Element {
    // Group threads by groupId
    const groupedThreads = threads.reduce(
        (acc, thread) => {
            const groupId = thread.group?.groupId || 'ungrouped';
            if (!acc[groupId]) {
                acc[groupId] = {
                    label: thread.group?.label || null,
                    threads: [],
                };
            }
            acc[groupId].threads.push(thread);
            return acc;
        },
        {} as { [key: string]: { label: string | null; threads: typeof threads } },
    );

    return (
        <Flexbox flexDirection={'column'} paddingBottom={'200px'}>
            <CloseDrawerButton />
            {Object.entries(groupedThreads).map(([groupId, groupData]) => (
                <React.Fragment key={groupId}>
                    {groupData.label && (
                        <Flexbox
                            flexDirection={'column'}
                            gap={6}
                            alignItems="baseline"
                            paddingX={'12px'}
                            paddingY={'8px'}
                        >
                            <Text variant="h4">{groupData.label}</Text>
                        </Flexbox>
                    )}
                    {groupData.threads.map((thread, i) => (
                        <Flexbox key={i} padding="8px 16px" gap={'8px'} flexDirection={'column'}>
                            <ThreadHeader thread={thread} />
                            {(() => {
                                switch (thread.commentType) {
                                    case 'Assembly':
                                        return <AssemblyCommentsBox thread={thread} assemblyId={thread.assemblyId} />;
                                    case 'QuoteRequest':
                                        //TODO: we should create a CommentsBox for QuoteRequest and remove the rfqId
                                        return <CommentsBox thread={thread} rfqId={'quoteRequestRfQId2'} />;
                                    default:
                                        return <CommentsBox thread={thread} rfqId={thread.rfqId} />;
                                }
                            })()}
                            <Divider />
                        </Flexbox>
                    ))}
                </React.Fragment>
            ))}
        </Flexbox>
    );
}

function ResolveCommentsButton({ thread }: { thread: CommunicationsDrawerThread }) {
    const entity: CommentEntity = {
        type: thread.commentType,
        data: thread.commentType === 'DesignItem' ? Array.from(thread.typeIds).sort() : thread.typeIds,
    };

    const { data: comments = [], isLoading: isLoadingFetch } = useComments(entity, thread.category);

    const { mutateAsync: updateComment, isPending: isLoadingUpdate } = useMutationUpdateComment();
    const onResolve = React.useCallback(async () => {
        return Promise.all(comments.map((comment) => updateComment({ id: comment.id, resolved: true })));
    }, [updateComment, comments]);

    // We need to disables the resolve button because we optimistically populate the react-query cache
    // with new comments that have a temporary id.
    const isMutating = useIsMutating({ mutationKey: QUERY_KEY_MUTATION_CREATE_COMMENT }) > 0;

    if (comments.some((comments) => !isPresent(comments.resolved_at))) {
        return (
            <TertiaryButton
                size="medium"
                onClick={() => onResolve()}
                disabled={isLoadingFetch || isLoadingUpdate || isMutating}
                startIcon={<CheckIcon />}
            >
                <Trans>Resolve comments</Trans>
            </TertiaryButton>
        );
    } else {
        return <></>;
    }
}

function ThreadHeader({ thread }: { thread: CommunicationsDrawerThread }): JSX.Element {
    return (
        <Flexbox flexDirection={'column'} gap={4}>
            <Flexbox alignItems="center" justifyContent="space-between" minHeight={'32px'}>
                <Text variant="h3" color={colorSystem.neutral[7]}>
                    {thread.category === 'Internal' ? <Trans>Internal comments</Trans> : <Trans>Public comments</Trans>}
                </Text>
                <ResolveCommentsButton thread={thread} />
            </Flexbox>
            {thread.category === 'Internal' ? (
                <InternalInfoBox />
            ) : (
                <Message
                    attention="low"
                    size="small"
                    variant="primary"
                    message={
                        <Flexbox flexDirection="column">
                            <Text>
                                <Trans>These comments are visible to all contributors of this RfQ.</Trans>
                            </Text>
                            <Text>
                                <Trans>You can add or invite external users via the share button.</Trans>
                            </Text>
                        </Flexbox>
                    }
                    overrides={{
                        MessageIcon: () => <Public style={{ alignSelf: 'start' }} fontSize="small" />,
                    }}
                />
            )}
        </Flexbox>
    );
}

const InternalInfoBox = () => {
    const orgName = useCurrentUserDetailsContext().organization.name;
    return (
        <Message
            attention="low"
            size="small"
            variant="primary"
            message={<Trans>These comments are only visible for members of {orgName}.</Trans>}
            overrides={{ MessageIcon: () => <Lock style={{ alignSelf: 'start' }} fontSize="small" /> }}
        />
    );
};
