import { t } from '@lingui/macro';
import { uniqBy } from '@luminovo/commons';
import {
    Checkbox,
    colorSystem,
    FieldMultiSelectControlled,
    FieldRadioControlled,
    FieldSelectControlled,
    Flexbox,
    FormItem,
    Text,
    Tooltip,
} from '@luminovo/design-system';
import { formatSupplierDTO } from '@luminovo/sourcing-core';
import { Add } from '@mui/icons-material';
import { RadioGroup } from '@mui/material';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { SubmitButton } from '../../../../../components/formLayouts/SubmitButton';
import {
    useGlobalApprovedSupplierAndStockLocations,
    useGlobalPreferredSupplierAndStockLocations,
    useNonExcludedSupplierAndStockLocations,
} from '../../../../../resources/supplierAndStockLocation/supplierAndStockLocationHandler';
import { QuoteRequestAssignmentFormValues } from './types';

export const FormItemSupplierSelectionStrategy: React.FunctionComponent = () => {
    const { control } = useFormContext<QuoteRequestAssignmentFormValues>();

    return (
        <FormItem label={`Choose supplier by`}>
            <FieldSelectControlled
                name="supplierSelectionStrategy"
                control={control}
                required={true}
                FieldProps={{
                    disableClearable: true,
                    getOptionLabel: (strategy) => {
                        switch (strategy.type) {
                            case 'SupplierPartTypeMatches':
                                return `Manually`;
                            case 'SupplierLineCard':
                                return `Supplier line card`;
                        }
                    },
                    options: [
                        { type: 'SupplierPartTypeMatches' },
                        { type: 'SupplierLineCard', partOptionSelectionStrategy: 'OnlyMatchingPartOptions' },
                    ],
                }}
            />
        </FormItem>
    );
};

export const FormItemSelectionStrategy: React.FunctionComponent = () => {
    const { control } = useFormContext<QuoteRequestAssignmentFormValues>();
    const supplierSelectionStrategyType = useWatch({ control, name: 'supplierSelectionStrategy.type' });

    if (supplierSelectionStrategyType !== 'SupplierLineCard') {
        return null;
    }

    return (
        <RadioGroup>
            <FormItem label={`What should be sent to the selected suppliers?`}>
                <Flexbox alignItems={'center'} gap={8}>
                    <FieldRadioControlled
                        control={control}
                        name="supplierSelectionStrategy.partOptionSelectionStrategy"
                        FieldProps={{ fieldValue: 'OnlyMatchingPartOptions', size: 'small' }}
                    />
                    <Text>Only part options matching line card</Text>
                </Flexbox>
                <Flexbox alignItems={'start'} gap={8}>
                    <FieldRadioControlled
                        control={control}
                        name="supplierSelectionStrategy.partOptionSelectionStrategy"
                        FieldProps={{ fieldValue: 'AllPartOptions', size: 'small' }}
                    />

                    <Text>Part options matching line card plus all approved alternatives</Text>
                </Flexbox>
            </FormItem>
        </RadioGroup>
    );
};

export const FormItemSuppliers: React.FunctionComponent = () => {
    type GroupByKey = 'preferred' | 'approved' | 'other';
    const groupLabels = {
        preferred: t`Preferred suppliers`,
        approved: t`Approved suppliers`,
        other: t`Other suppliers`,
    };
    const { control, setValue } = useFormContext<QuoteRequestAssignmentFormValues>();
    const selectedSuppliers = useWatch({ control, name: 'selectedSuppliers' });

    const { data: suppliersAndStockLocations = [] } = useNonExcludedSupplierAndStockLocations();
    const { data: preferredSuppliersAndStockLocations = [] } = useGlobalPreferredSupplierAndStockLocations();
    const { data: approvedSuppliersAndStockLocations = [] } = useGlobalApprovedSupplierAndStockLocations();

    const unfilteredPreferredSuppliers = preferredSuppliersAndStockLocations.map((sasl) => sasl.supplier);
    const unfilteredApprovedSuppliers = approvedSuppliersAndStockLocations.map((sasl) => sasl.supplier);
    const unfilteredOtherSuppliers = suppliersAndStockLocations.map((sasl) => sasl.supplier);

    const preferredSuppliers = unfilteredPreferredSuppliers;
    const approvedSuppliers = unfilteredApprovedSuppliers.filter(
        (supplier) => !preferredSuppliers.some((s) => s.id === supplier.id),
    );
    const otherSuppliers = unfilteredOtherSuppliers
        .filter((supplier) => !preferredSuppliers.some((s) => s.id === supplier.id))
        .filter((supplier) => !approvedSuppliers.some((s) => s.id === supplier.id));

    const options = uniqBy([...preferredSuppliers, ...approvedSuppliers, ...otherSuppliers], (supplier) => supplier.id);

    const getGroupByStatus = React.useCallback(
        (groupByKey: GroupByKey): 'all' | 'indeterminate' | 'none' => {
            const selectableSuppliers = {
                preferred: preferredSuppliers,
                approved: approvedSuppliers,
                other: otherSuppliers,
            }[groupByKey];

            const selectedIds = selectedSuppliers.map((s) => s.id);
            const selectableIds = selectableSuppliers.map((s) => s.id);

            if (selectableIds.length === 0) {
                return 'none';
            }

            if (selectableIds.every((id) => selectedIds.includes(id))) {
                return 'all';
            }

            if (selectableIds.some((id) => selectedIds.includes(id))) {
                return 'indeterminate';
            }

            return 'none';
        },
        [selectedSuppliers, preferredSuppliers, approvedSuppliers, otherSuppliers],
    );

    const toggleGroup = React.useCallback(
        (groupByKey: GroupByKey, status: 'all' | 'indeterminate' | 'none') => {
            const selectableSuppliers = {
                preferred: preferredSuppliers,
                approved: approvedSuppliers,
                other: otherSuppliers,
            }[groupByKey];

            if (status === 'all') {
                const newValue = uniqBy(
                    selectedSuppliers.filter((s) => !selectableSuppliers.some((s1) => s1.id === s.id)),
                    (x) => x.id,
                );

                setValue('selectedSuppliers', newValue, { shouldValidate: true });
            } else {
                const newValue = uniqBy(selectableSuppliers.concat(selectedSuppliers), (x) => x.id);
                setValue('selectedSuppliers', newValue, { shouldValidate: true });
            }
        },
        [selectedSuppliers, preferredSuppliers, approvedSuppliers, otherSuppliers, setValue],
    );

    return (
        <FormItem label={t`Suppliers`}>
            <FieldMultiSelectControlled
                name="selectedSuppliers"
                control={control}
                FieldProps={{
                    chipBoxProps: {
                        maxHeight: '200px',
                        overflow: 'auto',
                    },
                    options: options,
                    getOptionLabel: formatSupplierDTO,
                    placeholder: selectedSuppliers.length === 0 ? t`No suppliers` : undefined,
                    getOptionKey: (supplier) => supplier.id,
                    virtualized: true,
                    disableCloseOnSelect: true,
                    groupBy: (supplier): GroupByKey => {
                        if (preferredSuppliers.some((s) => s.id === supplier.id)) {
                            return 'preferred';
                        }
                        if (approvedSuppliers.some((s) => s.id === supplier.id)) {
                            return 'approved';
                        }
                        return 'other';
                    },
                    renderGroupLabel: (params) => {
                        //eslint-disable-next-line @typescript-eslint/consistent-type-assertions
                        const groupByKey = params.group as GroupByKey;
                        const label = groupLabels[groupByKey] || t`Unknown group`;
                        const status = getGroupByStatus(groupByKey);

                        return (
                            <Flexbox gap={8} paddingLeft={'12px'} alignItems={'center'}>
                                <Checkbox
                                    checked={status === 'all'}
                                    indeterminate={status === 'indeterminate'}
                                    size={'small'}
                                    onClick={() => toggleGroup(groupByKey, status)}
                                    disabled={groupByKey === 'other'}
                                />
                                <Text variant="h4">{label}</Text>
                            </Flexbox>
                        );
                    },
                    renderOption: (supplier, { selected }: { selected: boolean }) => {
                        // TODO: Add here the number of contacts
                        return (
                            <Flexbox key={supplier.id} gap={8} alignItems={'center'} width={'100%'} paddingLeft={'8px'}>
                                <Checkbox checked={selected} size={'small'} />
                                <Flexbox width={'100%'} justifyContent={'space-between'}>
                                    <Flexbox gap={4} alignItems={'baseline'}>
                                        <Text variant="body" color={colorSystem.neutral[8]}>
                                            {formatSupplierDTO(supplier)}
                                        </Text>
                                        <Text variant="body-small" color={colorSystem.neutral[7]}>
                                            {supplier.supplier_number}
                                        </Text>
                                    </Flexbox>
                                </Flexbox>
                            </Flexbox>
                        );
                    },
                }}
            />
        </FormItem>
    );
};

export const ButtonAddToQuoteRequest: React.FunctionComponent<{
    disabled: boolean;
    groupMode: 'manufacturers' | 'partOptions';
}> = ({ disabled, groupMode }) => {
    const { control } = useFormContext<QuoteRequestAssignmentFormValues>();
    const selectedSuppliers = useWatch({ control, name: 'selectedSuppliers' });
    const hasNoSuppliers = selectedSuppliers.length === 0;

    return (
        <Tooltip
            variant="white"
            title={
                disabled
                    ? groupMode === 'manufacturers'
                        ? 'Select at least one manufacturer'
                        : 'Select at least one part option'
                    : ''
            }
        >
            <div>
                <SubmitButton
                    disabled={disabled || hasNoSuppliers}
                    size="medium"
                    label={'Add to quote request'}
                    startIcon={<Add />}
                />
            </div>
        </Tooltip>
    );
};
