import { t, Trans } from '@lingui/macro';
import { transEnum } from '@luminovo/commons';
import { Chip, colorSystem, Flexbox, Message, Tag, Text } from '@luminovo/design-system';
import { AssemblyTypeEnum } from '@luminovo/http-client';
import { CalculationTypeChip } from '@luminovo/manufacturing-core';
import { Box, CircularProgress, Skeleton } from '@mui/material';
import React from 'react';
import { SitesWithIcon, useSiteNames } from '../../../../components/Site';
import { useHttpQuery, useSuspenseHttpQuery } from '../../../../resources/http/useHttpQuery';
import { useCalculationWithoutManufacturingEnabled } from '../../../../resources/organizationSettings/calculationWithoutManufacturingHandler';
import { assemblyTypePublicTranslations } from '../../../../resources/rfq/i18n';
import { DividerWithMargins } from '../assemblyOverviewCardComponents';
import { AssemblyPath } from './components/AssemblyPath';
import BuildCalculationButton from './components/BuildCalculationButton';
import { StyledBoxGrid } from './components/StyledBoxGrid';

interface AssemblyOverviewCalculationsCardProps {
    designator: string;
    assemblyType: AssemblyTypeEnum;
    assemblyId: string;
    rfqId: string;
    manufacturingAssemblyDetailsId: string | undefined;
}

const CalculationChipWrapper = ({ assemblyId }: { assemblyId: string }) => {
    const { data } = useSuspenseHttpQuery(
        'GET /calculations/calculation-assembly-costs',
        {
            /* eslint-disable-next-line camelcase */
            queryParams: { assembly_id: assemblyId },
        },
        { select: (res) => res.data },
    );
    if (!data) return <CircularProgress />;
    const type = data[0]?.type;
    return <CalculationTypeChip calculationType={type} />;
};

export const AssemblyOverviewCalculationsCard: React.FunctionComponent<AssemblyOverviewCalculationsCardProps> = ({
    designator,
    assemblyType,
    assemblyId,
    rfqId,
    manufacturingAssemblyDetailsId,
}: AssemblyOverviewCalculationsCardProps): JSX.Element => {
    const { data: calculationWithoutManufacturingSettings } = useCalculationWithoutManufacturingEnabled();
    const isCalculationWithoutManufacturingEnabled =
        calculationWithoutManufacturingSettings?.calculation_without_manufacturing_enabled ?? true;
    const warningText = isCalculationWithoutManufacturingEnabled
        ? t`This assembly was not assigned a quantity in any sourcing scenario`
        : t`Manufacturing information not submitted`;
    const { data: scenarioCombinationData } = useSuspenseHttpQuery(
        'GET /calculations/scenario-combinations',
        {
            /* eslint-disable-next-line camelcase */
            queryParams: { assembly_id: assemblyId },
        },
        {
            select: (res) => {
                const scenarioCombinations = res;
                const batchSizes: number[] = [...new Set(scenarioCombinations.flatMap((i) => i.batch_sizes))].sort(
                    (a, b) => a - b,
                );
                const orderSizes: number[] = [...new Set(scenarioCombinations.map((i) => i.order_size))].sort(
                    (a, b) => a - b,
                );

                return { scenarioCombinations, batchSizes, orderSizes };
            },
        },
    );

    const { data: calculationAssemblyDetailsData } = useSuspenseHttpQuery(
        'GET /calculations/calculation-assembly-details',
        {
            // eslint-disable-next-line camelcase
            queryParams: { assembly_id: assemblyId },
        },
        {},
    );

    const { data: manufacturingScenariosData, isLoading: manufacturingScenariosDataIsLoading } = useHttpQuery(
        'GET /manufacturing-scenarios',
        {
            /* eslint-disable-next-line camelcase */
            queryParams: { manufacturing_assembly_details_id: manufacturingAssemblyDetailsId },
        },
        {
            enabled: manufacturingAssemblyDetailsId !== undefined,
        },
    );

    const { siteNames } = useSiteNames();

    let sites = new Set<string>();

    manufacturingScenariosData?.data.forEach((manufacturingScenario) => {
        manufacturingScenario.sites.forEach((siteId) => {
            sites.add(siteId);
        });
    });

    if (!scenarioCombinationData || !calculationAssemblyDetailsData) return <CircularProgress />;

    const isCalculationBuilt = calculationAssemblyDetailsData.data[0] !== undefined;
    const { scenarioCombinations, batchSizes, orderSizes } = scenarioCombinationData;
    const isManufacturingInformationAvailable =
        scenarioCombinations?.length &&
        scenarioCombinations.length > 0 &&
        batchSizes.length > 0 &&
        orderSizes.length > 0;

    return (
        <StyledBoxGrid>
            <Flexbox flexDirection="column">
                <Flexbox
                    alignItems={'center'}
                    style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        maxWidth: '100%',
                    }}
                >
                    <Text variant="h2" color="textSecondary" style={{ maxWidth: '300px' }} showEllipsis>
                        {designator}
                    </Text>
                    <Tag
                        color={'neutral'}
                        label={transEnum(assemblyType, assemblyTypePublicTranslations)}
                        style={{ marginLeft: '8px' }}
                    />
                </Flexbox>
                <AssemblyPath assemblyId={assemblyId} />
            </Flexbox>
            <DividerWithMargins />
            {isManufacturingInformationAvailable ? (
                <Box>
                    <Flexbox gap={8}>
                        <Text variant="h4" style={{ color: colorSystem.neutral[6] }}>
                            <Trans>Order sizes</Trans>
                        </Text>
                        {orderSizes.map((os) => (
                            <Chip key={os} color="neutral" label={os.toString()} />
                        ))}
                    </Flexbox>
                    <Flexbox gap={8} marginTop={'12px'}>
                        <Text variant="h4" style={{ color: colorSystem.neutral[6] }}>
                            <Trans>Batch sizes</Trans>
                        </Text>
                        {batchSizes.map((batchSize) => (
                            <Chip key={batchSize} color="green" label={batchSize.toString()} />
                        ))}
                    </Flexbox>
                    <Flexbox marginTop={'12px'}>
                        {manufacturingScenariosDataIsLoading ? (
                            <Skeleton variant="rectangular" />
                        ) : (
                            <SitesWithIcon sites={Array.from(sites)} siteNamesMap={siteNames} />
                        )}
                    </Flexbox>
                </Box>
            ) : (
                <Box>
                    <Message variant="yellow" attention="high" title={t`Warning`} message={warningText} size="large" />
                </Box>
            )}

            <Flexbox>
                {isCalculationBuilt && <CalculationChipWrapper assemblyId={assemblyId} />}
                <BuildCalculationButton
                    assemblyId={assemblyId}
                    disabled={!isManufacturingInformationAvailable}
                    rfqId={rfqId}
                    isCalculationBuilt={isCalculationBuilt}
                />
            </Flexbox>
        </StyledBoxGrid>
    );
};
