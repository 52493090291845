import { useQueryClient } from '@tanstack/react-query';
import { httpQueryKey } from './http/httpQueryKey';

export const useInvalidateAssemblyQueries = (assemblyId: string | undefined) => {
    const queryClient = useQueryClient();

    if (assemblyId === undefined) {
        return {
            invalidateAssemblyDescendants: async () => undefined,
            invalidateAssemblyDescendantsSummary: async () => undefined,
            invalidateAssemblyHistoryQuery: async () => undefined,
        };
    }

    const invalidateAssemblyDescendants = () =>
        queryClient.invalidateQueries({ queryKey: httpQueryKey('GET /assemblies/:assemblyId/descendants') });

    const invalidateAssemblyDescendantsSummary = () =>
        queryClient.invalidateQueries({ queryKey: httpQueryKey('GET /assemblies/:assemblyId/descendants-summary') });

    const invalidateAssemblyHistoryQuery = () => {
        return queryClient.invalidateQueries({
            queryKey: httpQueryKey('GET /assemblies/:id/history', {
                pathParams: { id: assemblyId },
                // eslint-disable-next-line camelcase
                queryParams: { include_design_items: true },
            }),
        });
    };
    return {
        invalidateAssemblyDescendants,
        invalidateAssemblyDescendantsSummary,
        invalidateAssemblyHistoryQuery,
    };
};
