import { isPresent } from '@luminovo/commons';
import { MenuButton, MenuItem, ReleaseTag } from '@luminovo/design-system';
import { Upload } from '@mui/icons-material';
import { useHistory } from 'react-router';
import { route } from '../../../utils/routes';
import { useQuoteRequest } from '../hooks/negotiationHandlers';

export function ButtonNegotiationQuoteImporter({
    negotiationId,
    quoteRequestId,
}: {
    negotiationId: number;
    quoteRequestId: string;
}) {
    const history = useHistory();

    const { data: quoteRequest } = useQuoteRequest(quoteRequestId);

    if (!isPresent(quoteRequest)) {
        return null;
    }

    return (
        <MenuButton icon={<Upload />} appearance="secondary" size="medium" label="Upload quote">
            <MenuItem
                onClick={() =>
                    history.push(
                        route('/negotiations/:negotiationId/quote-importer/:quoteRequestId', {
                            quoteRequestId,
                            negotiationId,
                        }),
                    )
                }
                label="Excel importer 1.0"
            />
            <MenuItem
                label={
                    <>
                        <span style={{ marginRight: 4 }}>Excel importer 2.0</span>{' '}
                        <ReleaseTag label="BETA" color="primary" />
                    </>
                }
                onClick={() =>
                    history.push(
                        route(
                            '/negotiations/:negotiationId/quote-importer/:quoteRequestId',
                            { quoteRequestId, negotiationId },
                            { newImporter: 'true' },
                        ),
                    )
                }
            ></MenuItem>
        </MenuButton>
    );
}
