import { DestructiveSecondaryIconButton, Tooltip, useConfirmationDialog } from '@luminovo/design-system';
import { Delete } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';
import { useHistory } from 'react-router';

export function ButtonDelete({
    title,
    description,
    onDelete,
    isLoading,
    href,
    cta,
}: {
    title: string;
    description: string | JSX.Element;
    onDelete: () => Promise<unknown>;
    isLoading: boolean;
    href?: string;
    cta?: string | JSX.Element;
}) {
    const history = useHistory();

    const { modal, setOpen } = useConfirmationDialog({
        title,
        description,
        onConfirmation: async () => {
            await onDelete();
            if (href) {
                history.replace(href);
            }
        },
        cta,
    });

    return (
        <>
            {modal}
            <Tooltip title={title}>
                <DestructiveSecondaryIconButton size="medium" onClick={() => setOpen(true)}>
                    {isLoading ? <CircularProgress size={16} /> : <Delete fontSize="inherit" />}
                </DestructiveSecondaryIconButton>
            </Tooltip>
        </>
    );
}
