import { t, Trans } from '@lingui/macro';
import { formatDecimal } from '@luminovo/commons';
import { colorSystem, Flexbox, Tag } from '@luminovo/design-system';
import {
    GenericCapacitor,
    GenericFullPart,
    GenericPart,
    GenericPartTypes,
    GenericResistor,
    OtsFullPart,
    RfqContext,
} from '@luminovo/http-client';
import { PreferredManufacturerTagWithText } from '@luminovo/sourcing-core';
import { Skeleton, styled, Typography } from '@mui/material';
import React from 'react';
import { assertUnreachable } from '../../../utils/typingUtils';
import { PartCardLayout } from '../PartCardLayout';
import { DetailsButton } from '../PartSpecificationCardFields';
import { useMpnMatches } from '../useMpnMatches';
import { useGenericPartDetailsDrawer } from './GenericPartCardDetails';
import { TechParamsGenericPart } from './TechParamsGenericPart';

function getMpnMatchesCount({
    isIncomplete,
    mpnMatches,
}: {
    isIncomplete: boolean;
    mpnMatches: OtsFullPart[];
}): string {
    return isIncomplete ? '?' : formatDecimal(mpnMatches.length);
}

function getEditState({ isIncomplete, isEditable }: { isIncomplete: boolean; isEditable: boolean }): boolean {
    return isIncomplete ? false : isEditable;
}

const GenericResistorCard = ({
    resistorSpec,
    id,
    cardStyle,
    isIncomplete,
    isEditable,
    onAddAlsoRemove = () => {},
    rfqContext,
    isPreferredManufacturer,
}: {
    resistorSpec: GenericResistor;
    id?: string;
    cardStyle?: React.CSSProperties;
    isIncomplete: boolean;
    isEditable: boolean;
    onAddAlsoRemove?: (newPart: GenericFullPart) => void;
    rfqContext: RfqContext;
    isPreferredManufacturer: boolean;
}): JSX.Element => {
    const { isLoading: isLoadingMpnMatches, mpnMatches } = useMpnMatches(id, rfqContext);

    const mpnMatchesCount = !isLoadingMpnMatches ? getMpnMatchesCount({ isIncomplete, mpnMatches }) : '';
    const isEditEnable = getEditState({ isIncomplete, isEditable });

    const { openDrawer } = useGenericPartDetailsDrawer();

    return (
        <PartCardLayout
            headerSection={
                <>
                    <Flexbox alignItems={'center'} gap={8}>
                        <Trans>
                            <LightTypography variant="h5">Generic</LightTypography>
                            <Typography variant="h5" color="textSecondary">
                                Resistor
                            </Typography>
                        </Trans>
                        <LightTypography variant="h5">
                            <Trans>Parts</Trans>
                        </LightTypography>
                        {isLoadingMpnMatches ? (
                            <Skeleton width={20} />
                        ) : (
                            <Typography variant="h5" color="textSecondary">
                                {mpnMatchesCount}
                            </Typography>
                        )}
                    </Flexbox>
                    <Flexbox gap={8} alignItems="center">
                        {isPreferredManufacturer && <PreferredManufacturerTagWithText />}
                    </Flexbox>
                </>
            }
            headerHover={
                <DetailsButton
                    handleClick={() =>
                        openDrawer({
                            genericPart: resistorSpec,
                            mpnMatches,
                            isEditEnable,
                            onAddAlsoRemove,
                            rfqContext,
                        })
                    }
                />
            }
            footerSection={
                <>
                    <Flexbox alignItems={'center'} gap={4}>
                        {!isLoadingMpnMatches && mpnMatches.length === 0 && (
                            <Tag color="red" attention="low" label={isIncomplete ? t`Incomplete` : t`No matches`} />
                        )}
                        <TechParamsGenericPart genericPart={resistorSpec} />
                    </Flexbox>
                </>
            }
            cardStyle={cardStyle}
        />
    );
};

const GenericCapacitorCard = ({
    capacitorSpec,
    id,
    cardStyle,
    isIncomplete,
    isEditable,
    onAddAlsoRemove = () => {},
    rfqContext,
    isPreferredManufacturer,
}: {
    capacitorSpec: GenericCapacitor;
    id?: string;
    cardStyle?: React.CSSProperties;
    isIncomplete: boolean;
    isEditable: boolean;
    onAddAlsoRemove?: (newPart: GenericFullPart) => void;
    rfqContext: RfqContext;
    isPreferredManufacturer: boolean;
}): JSX.Element => {
    const { isLoading: isLoadingMpnMatches, mpnMatches } = useMpnMatches(id, rfqContext);

    const mpnMatchesCount = !isLoadingMpnMatches ? getMpnMatchesCount({ isIncomplete, mpnMatches }) : '';
    const isEditEnable = getEditState({ isIncomplete, isEditable });

    const { openDrawer } = useGenericPartDetailsDrawer();

    return (
        <PartCardLayout
            headerSection={
                <>
                    <Flexbox alignItems={'center'} gap={8}>
                        <Trans>
                            <LightTypography variant="h5">Generic</LightTypography>
                            <Typography variant="h5" color="textSecondary">
                                Capacitor
                            </Typography>
                        </Trans>
                        <LightTypography variant="h5">
                            <Trans>Parts</Trans>
                        </LightTypography>
                        {isLoadingMpnMatches ? (
                            <Skeleton width={20} />
                        ) : (
                            <Typography variant="h5" color="textSecondary">
                                {mpnMatchesCount}
                            </Typography>
                        )}
                    </Flexbox>
                    <Flexbox gap={8} alignItems="center">
                        {isPreferredManufacturer && <PreferredManufacturerTagWithText />}
                    </Flexbox>
                </>
            }
            headerHover={
                <DetailsButton
                    handleClick={() =>
                        openDrawer({
                            genericPart: capacitorSpec,
                            mpnMatches,
                            isEditEnable,
                            onAddAlsoRemove,
                            rfqContext,
                        })
                    }
                />
            }
            footerSection={
                <>
                    <Flexbox alignItems={'center'} gap={4}>
                        {!isLoadingMpnMatches && mpnMatches.length === 0 && (
                            <Tag color="red" attention="low" label={isIncomplete ? t`Incomplete` : t`No matches`} />
                        )}
                        <TechParamsGenericPart genericPart={capacitorSpec} />
                    </Flexbox>
                </>
            }
            cardStyle={cardStyle}
        />
    );
};

// Need memoization to reduce the number of backend calls, as the POST request in the useMutation does not get cached
export const GenericPartCard = React.memo(function GenericPartCard({
    part,
    isIncomplete = false,
    isEditable = false,
    cardStyle,
    onAddAlsoRemove,
    rfqContext,
}: {
    part: GenericFullPart;
    isIncomplete?: boolean;
    isEditable?: boolean;
    cardStyle?: React.CSSProperties;
    onAddAlsoRemove?: (newPart: GenericFullPart, idToRemove?: string) => void;
    rfqContext: RfqContext;
}): JSX.Element {
    const partId = part.id;

    const onAddAlsoRemoveHandler = React.useCallback(
        (part: GenericFullPart) => {
            onAddAlsoRemove && onAddAlsoRemove(part, partId);
        },
        [partId, onAddAlsoRemove],
    );

    if (part.content.type === GenericPartTypes.Capacitor) {
        return (
            <GenericCapacitorCard
                isIncomplete={isIncomplete}
                isEditable={isEditable}
                capacitorSpec={part.content}
                id={partId}
                rfqContext={rfqContext}
                cardStyle={cardStyle}
                onAddAlsoRemove={onAddAlsoRemoveHandler}
                isPreferredManufacturer={part.is_manufacturer_preferred}
            />
        );
    } else if (part.content.type === GenericPartTypes.Resistor) {
        return (
            <GenericResistorCard
                isIncomplete={isIncomplete}
                isEditable={isEditable}
                resistorSpec={part.content}
                id={partId}
                rfqContext={rfqContext}
                cardStyle={cardStyle}
                onAddAlsoRemove={onAddAlsoRemoveHandler}
                isPreferredManufacturer={part.is_manufacturer_preferred}
            />
        );
    }
    assertUnreachable(part.content);
});

const LightTypography = styled(Typography)({
    color: colorSystem.neutral[5],
});

export const IncompleteGenericPartCard = React.memo(function GenericPartCard({
    part,
    id,
    isIncomplete = false,
    isEditable = false,
    cardStyle,
    onAddAlsoRemove,
    rfqContext,
}: {
    part: GenericPart;
    id?: string;
    isIncomplete?: boolean;
    isEditable?: boolean;
    cardStyle?: React.CSSProperties;
    onAddAlsoRemove?: (newPart: GenericFullPart, idToRemove?: string) => void;
    rfqContext: RfqContext;
}): JSX.Element {
    const onAddAlsoRemoveHandler = React.useCallback(
        (part: GenericFullPart) => {
            onAddAlsoRemove && onAddAlsoRemove(part, id);
        },
        [id, onAddAlsoRemove],
    );

    if (part.type === GenericPartTypes.Capacitor) {
        return (
            <GenericCapacitorCard
                isIncomplete={isIncomplete}
                isEditable={isEditable}
                capacitorSpec={part}
                id={id}
                rfqContext={rfqContext}
                cardStyle={cardStyle}
                onAddAlsoRemove={onAddAlsoRemoveHandler}
                isPreferredManufacturer={false}
            />
        );
    } else if (part.type === GenericPartTypes.Resistor) {
        return (
            <GenericResistorCard
                isIncomplete={isIncomplete}
                isEditable={isEditable}
                resistorSpec={part}
                id={id}
                rfqContext={rfqContext}
                cardStyle={cardStyle}
                onAddAlsoRemove={onAddAlsoRemoveHandler}
                isPreferredManufacturer={false}
            />
        );
    }
    assertUnreachable(part);
});
