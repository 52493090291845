import { Trans, t } from '@lingui/macro';
import { isPresent } from '@luminovo/commons';
import { CenteredLayout, Flexbox, StickyLayout, Text, colorSystem } from '@luminovo/design-system';
import {
    ExistingPanelDTO,
    PCBV2,
    PanelPreferenceDTO,
    PanelizationFormModeDTO,
    PerPcbPanelDTO,
    PerPcbPanelPostDTO,
    PerSourcingScenarioPanelDTO,
    PerSourcingScenarioPanelPostDTO,
    SourcingScenarioDTO,
} from '@luminovo/http-client';
import { Box, CircularProgress } from '@mui/material';
import React from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useHttpQuery } from '../../../resources/http/useHttpQuery';
import { useHttpMutation } from '../../../resources/mutation/useHttpMutation';
import { editPcbPanelAnalytics, updatePcbPanelAnalytics } from '../../../resources/pcb/analytics/analytic';
import { usePcbOfAssembly } from '../../../resources/pcb/pcbHandlers';
import { useRfQ } from '../../../resources/rfq/rfqHandler';
import { useSourcingScenarios } from '../../../resources/sourcingScenario/sourcingScenarioHandlers';
import { PcbActionToolbar } from '../components/FormActionToolbar';
import { PcbFormToggleButton, PcbFormToggleButtonGroup } from '../components/PcbFormToggleButton';
import { PcbLoading } from '../components/PcbLoading';
import { PcbPageLayout } from '../components/PcbMainLayout';
import { PcbSidebarLayout } from '../components/PcbSidebarLayout';
import { PcbModuleProps } from '../utils/types';
import { DefaultPanelForm } from './components/DefaultPanelForm';
import { ExistingPanelForm } from './components/ExistingPanelForm';
import { PanelRenderer } from './components/PanelRenderer';
import {
    convertPanelDetailsFormValuesToDTO,
    convertPanelExistingFormValuesToDTO,
} from './utils/convertFormValuesToDTO';
import { getPanelFormInitialValues } from './utils/getPanelFormInitialValues';
import { PanelFormState, ScopeType } from './utils/types';

export const PanelizationTab = ({ assemblyId, rfqId, isEditable, viewContext }: PcbModuleProps) => {
    return (
        <PcbLoading assemblyId={assemblyId} rfqId={rfqId} isEditable={isEditable} viewContext={viewContext}>
            <PcbModuleWrapper assemblyId={assemblyId} rfqId={rfqId} isEditable={isEditable} viewContext={viewContext} />
        </PcbLoading>
    );
};

function PcbModuleWrapper(props: PcbModuleProps) {
    const { data: pcb } = usePcbOfAssembly({ assemblyId: props.assemblyId });

    if (!pcb) {
        return <></>;
    }

    return (
        <PcbSidebarLayout assemblyId={props.assemblyId} pcb={pcb} rfqId={props.rfqId} viewContext={props.viewContext}>
            <PanelizationModule {...props} pcb={pcb} />
        </PcbSidebarLayout>
    );
}

const PanelizationModule = ({ assemblyId, pcb, rfqId, isEditable, viewContext }: PcbModuleProps & { pcb: PCBV2 }) => {
    const { data: rfq, isLoading: isLoadingRfqs } = useRfQ(rfqId);
    const sourcingScenarioIds = rfq?.sourcing_scenarios ?? [];
    const { data: sourcingScenarios, isLoading: isLoadingSourcingScenarios } =
        useSourcingScenarios(sourcingScenarioIds);
    const { data: panelPreferences, isLoading: isLoadingPanelPreferences } = useHttpQuery(
        'GET /pcb-panel-preferences',
        {},
    );

    if (
        isLoadingRfqs ||
        !isPresent(rfq) ||
        isLoadingSourcingScenarios ||
        !isPresent(sourcingScenarios) ||
        isLoadingPanelPreferences ||
        !isPresent(panelPreferences)
    ) {
        return <></>;
    }

    const filteredSourcingScenarios = sourcingScenarios.filter((sourcingScenario) =>
        sourcingScenario.assembly_quantities.items.some((item) => item.assembly === assemblyId),
    );

    return (
        <PanelModuleWrapper
            viewContext={viewContext}
            assemblyId={assemblyId}
            isEditable={isEditable}
            pcb={pcb}
            rfqId={rfqId}
            sourcingScenarios={filteredSourcingScenarios}
            panelPreferences={panelPreferences}
        />
    );
};

const usePanelFormData = ({ pcbId }: { pcbId: string }) => {
    return useHttpQuery(
        'GET /panels',
        {
            queryParams: {
                // eslint-disable-next-line camelcase
                pcb_id: pcbId,
            },
        },
        {
            select: (data) => ({
                // Why do we use as here?
                // We would like to immediately filter the data into the correct types and avoid more assertion functions down the line
                perPcbSavedData: data.filter((panelData) => panelData.type === 'PerPcb') as PerPcbPanelDTO[],
                perScenarioSavedData: data.filter(
                    (panelData) => panelData.type === 'PerSourcingScenario',
                ) as PerSourcingScenarioPanelDTO[],
                existingPanelSavedData: data.filter((panelData) => panelData.type === 'Existing') as ExistingPanelDTO[],
            }),
        },
    );
};

const isPerSourcingScenario = (
    panelData: PerSourcingScenarioPanelPostDTO[] | PerPcbPanelPostDTO,
): panelData is PerSourcingScenarioPanelPostDTO[] => {
    return Array.isArray(panelData);
};

const usePanelFormFunction = ({
    pcbId,
    perPcbSavedData,
    perScenarioSavedData,
    panelizationFormMode,
    existingPanelSavedData,
}: {
    pcbId: string;
    perPcbSavedData: PerPcbPanelDTO[] | undefined;
    perScenarioSavedData: PerSourcingScenarioPanelDTO[] | undefined;
    existingPanelSavedData: ExistingPanelDTO[] | undefined;
    panelizationFormMode: PanelizationFormModeDTO;
}) => {
    const { mutateAsync } = useHttpMutation('POST /panels', {
        snackbarMessage: t`Preference updated`,
    });
    const { mutateAsync: mutateAsyncPatch } = useHttpMutation('PATCH /panels/:panelId', {
        snackbarMessage: t`Preference updated`,
    });
    const { mutateAsync: mutateAsyncBulk } = useHttpMutation('POST /panels/bulk', {
        snackbarMessage: t`Preference updated`,
    });
    const { mutateAsync: mutateAsyncDeletePcbPanel } = useHttpMutation('DELETE /panels/:panelId', {
        snackbarMessage: null,
    });

    const handleSubmitDetailsPanel: SubmitHandler<PanelFormState> = async (formState) => {
        if (formState.scope === 'ExistingPanel') return;
        const data = convertPanelDetailsFormValuesToDTO({ values: formState, pcbId });

        if (isPerSourcingScenario(data)) {
            if (data.length === 0) {
                return;
            }
            await mutateAsyncBulk({ requestBody: { inserts: data } });
            await handleDelete({ scope: 'PerSourcingScenario' });
        } else {
            const perPcbPanelDetailsId = perPcbSavedData?.[0]?.data.panel_details.id;
            if (perPcbPanelDetailsId) {
                await mutateAsyncPatch({
                    pathParams: {
                        panelId: perPcbPanelDetailsId,
                    },
                    requestBody: {
                        type: 'Details',
                        data: { ...data.data.panel_details, id: perPcbPanelDetailsId },
                    },
                });
            } else {
                await mutateAsync({ requestBody: data });
            }
            await handleDelete({ scope: 'PerPcb' });
        }
    };

    const handleSubmitExistingPanel: SubmitHandler<PanelFormState> = async (formState) => {
        if (formState.scope !== 'ExistingPanel') return;
        const data = convertPanelExistingFormValuesToDTO({ values: formState, pcbId });
        const panelId = existingPanelSavedData?.[0]?.data.id;

        if (panelId) {
            await mutateAsyncPatch({
                pathParams: {
                    panelId,
                },
                requestBody: {
                    type: 'Existing',
                    data: { ...data.data, id: panelId },
                },
            });
        } else {
            await mutateAsync({ requestBody: data });
        }
        await handleDelete({ scope: 'ExistingPanel' });
    };

    const handleSubmit: SubmitHandler<PanelFormState> = async (formState) => {
        if (panelizationFormMode === 'Details') {
            await handleSubmitDetailsPanel(formState);
        } else {
            await handleSubmitExistingPanel(formState);
        }
    };

    const handleDelete = async ({ scope }: { scope: ScopeType }) => {
        const perScenarioPanelIds = (perScenarioSavedData ?? []).map((data) => data.data.panel_details.id);
        const perPcbPanelIds = (perPcbSavedData ?? []).map((data) => data.data.panel_details.id);
        const existingPanelIds = (existingPanelSavedData ?? []).map((data) => data.data.id);
        let panelIdsToDelete: string[] = [];

        if (scope === 'PerSourcingScenario') {
            panelIdsToDelete = [...perPcbPanelIds, ...existingPanelIds];
        }
        if (scope === 'PerPcb') {
            panelIdsToDelete = [...perScenarioPanelIds, ...existingPanelIds];
        }
        if (scope === 'ExistingPanel') {
            panelIdsToDelete = [...perScenarioPanelIds, ...perPcbPanelIds];
        }
        if (panelIdsToDelete.length === 0) return;
        await Promise.all(panelIdsToDelete.map((panelId) => mutateAsyncDeletePcbPanel({ pathParams: { panelId } })));
    };

    return { onSubmit: handleSubmit };
};

const PanelModuleWrapper = ({
    sourcingScenarios,
    rfqId,
    assemblyId,
    pcb,
    isEditable,
    panelPreferences,
    viewContext,
}: PcbModuleProps & {
    pcb: PCBV2;
    sourcingScenarios: SourcingScenarioDTO[];
    panelPreferences: PanelPreferenceDTO[];
}) => {
    const { data, isLoading } = usePanelFormData({
        pcbId: pcb.id,
    });
    const { perScenarioSavedData, perPcbSavedData, existingPanelSavedData } = data ?? {
        perPcbSavedData: undefined,
        perScenarioSavedData: undefined,
        existingPanelSavedData: undefined,
    };

    if (
        isLoading ||
        !isPresent(perScenarioSavedData) ||
        !isPresent(perPcbSavedData) ||
        !isPresent(existingPanelSavedData)
    ) {
        return (
            <CenteredLayout>
                <CircularProgress />
            </CenteredLayout>
        );
    }

    return (
        <PanelModuleForm
            viewContext={viewContext}
            perPcbSavedData={perPcbSavedData}
            perScenarioSavedData={perScenarioSavedData}
            existingPanelSavedData={existingPanelSavedData}
            sourcingScenarios={sourcingScenarios}
            rfqId={rfqId}
            assemblyId={assemblyId}
            pcb={pcb}
            isEditable={isEditable}
            panelPreferences={panelPreferences}
        />
    );
};

const PanelModuleForm = ({
    perPcbSavedData,
    perScenarioSavedData,
    existingPanelSavedData,
    sourcingScenarios,
    rfqId,
    assemblyId,
    pcb,
    isEditable,
    panelPreferences,
    viewContext,
}: PcbModuleProps & {
    perScenarioSavedData: PerSourcingScenarioPanelDTO[];
    perPcbSavedData: PerPcbPanelDTO[];
    existingPanelSavedData: ExistingPanelDTO[];
    sourcingScenarios: SourcingScenarioDTO[];
    pcb: PCBV2;
    panelPreferences: PanelPreferenceDTO[];
}) => {
    const [isEditing, setIsEditing] = React.useState(false);
    const [activeTab, setActiveTab] = React.useState<PanelizationFormModeDTO>(
        existingPanelSavedData.length ? 'Existing' : 'Details',
    );
    const [currentlyDisplayedPanelField, setCurrentlyDisplayedPanelField] = React.useState<number>(0);

    const formValue = getPanelFormInitialValues({
        sourcingScenarios,
        scope: existingPanelSavedData.length
            ? 'ExistingPanel'
            : perScenarioSavedData.length
              ? 'PerSourcingScenario'
              : 'PerPcb',
        perScenarioSavedData,
        perPcbSavedData,
        existingPanelSavedData,
    });

    const useFormReturn = useForm<PanelFormState>({
        reValidateMode: 'onChange',
        mode: 'onBlur',
        defaultValues: formValue,
    });

    const { onSubmit } = usePanelFormFunction({
        pcbId: pcb.id,
        perPcbSavedData,
        perScenarioSavedData,
        existingPanelSavedData,
        panelizationFormMode: activeTab,
    });

    const { reset, formState, setValue } = useFormReturn;
    const { isDirty: isFormStateDirty, dirtyFields } = formState;
    const isMoreThanScopeDirty = dirtyFields ? Object.keys(dirtyFields).some((key) => key !== 'scope') : false;
    const isDirty = isFormStateDirty && isMoreThanScopeDirty;

    const handleEdit = () => {
        editPcbPanelAnalytics({
            assemblyId,
            pcbId: pcb.id,
            rfqId,
        });
        setIsEditing(true);
    };

    const handleCancel = () => {
        reset(formValue);
        setIsEditing(false);
    };

    const handleTabChange = (value: PanelizationFormModeDTO) => {
        const formValue = getPanelFormInitialValues({
            sourcingScenarios,
            scope: value === 'Details' ? 'PerPcb' : 'ExistingPanel',
            perScenarioSavedData,
            perPcbSavedData,
            existingPanelSavedData,
        });
        setValue('data', formValue.data);
        setValue('scope', formValue.scope);
        setCurrentlyDisplayedPanelField(0);
        setActiveTab(value);
    };

    const handleSubmit = useFormReturn.handleSubmit(async (form) => {
        try {
            await onSubmit(form);
            reset(form);
            updatePcbPanelAnalytics({
                assemblyId,
                pcbId: pcb.id,
                rfqId,
                fields: dirtyFields,
                scope: form.scope,
            });
        } catch {}
        setIsEditing(false);
    });

    return (
        <FormProvider {...useFormReturn}>
            <form onSubmit={handleSubmit} id="pcb-panel-form">
                <PcbActionToolbar
                    viewContext={viewContext}
                    rfqId={rfqId}
                    assemblyId={assemblyId}
                    pcb={pcb}
                    pageTitle={t`Shipping panel`}
                    isEditing={isEditing}
                    isEditable={isEditable}
                    isDirty={isDirty}
                    editButtonOptions={{ onClick: handleEdit }}
                    cancelButtonOptions={{ onClick: handleCancel }}
                />
                <PcbPageLayout
                    wrapperStyle={{
                        gridTemplateColumns: '438px 1fr',
                    }}
                >
                    <Box
                        style={{
                            height: '100%',
                            overflowX: 'hidden',
                            overflowY: 'auto',
                            position: 'relative',
                            backgroundColor: colorSystem.neutral[1],
                        }}
                    >
                        <StickyLayout
                            style={{
                                backgroundColor: colorSystem.neutral[1],
                                padding: '16px',
                            }}
                        >
                            <PcbFormToggleButtonGroup
                                disabled={!isEditing}
                                value={activeTab}
                                exclusive
                                defaultValue={'Details'}
                                onChange={(_, value) => handleTabChange(value)}
                                size="small"
                                fullWidth
                            >
                                <PcbFormToggleButton value="Details">
                                    <Text variant="body-small">
                                        <Trans>Generate custom panel</Trans>
                                    </Text>
                                </PcbFormToggleButton>
                                <PcbFormToggleButton value="Existing">
                                    <Text variant="body-small">
                                        <Trans>Existing panel setup</Trans>
                                    </Text>
                                </PcbFormToggleButton>
                            </PcbFormToggleButtonGroup>
                        </StickyLayout>
                        <Flexbox flexDirection={'column'} gap={'16px'} style={{ padding: '0 16px 16px' }}>
                            {activeTab === 'Details' && (
                                <DefaultPanelForm
                                    rfqId={rfqId}
                                    assemblyId={assemblyId}
                                    isEditing={isEditing}
                                    panelPreferences={panelPreferences}
                                    sourcingScenarios={sourcingScenarios}
                                    pcb={pcb}
                                    perScenarioSavedData={perScenarioSavedData}
                                    perPcbSavedData={perPcbSavedData}
                                    existingPanelSavedData={existingPanelSavedData}
                                    currentlyDisplayedPanelField={currentlyDisplayedPanelField}
                                    setCurrentlyDisplayedPanelField={setCurrentlyDisplayedPanelField}
                                />
                            )}
                            {activeTab === 'Existing' && <ExistingPanelForm isEditing={isEditing} />}
                        </Flexbox>
                    </Box>
                    <Box style={{ height: `calc(-155px + 100vh)` }}>
                        <PanelRenderer
                            pcb={pcb}
                            activeTab={activeTab}
                            currentlyDisplayedPanelField={currentlyDisplayedPanelField}
                        />
                    </Box>
                </PcbPageLayout>
            </form>
        </FormProvider>
    );
};
