import { t, Trans } from '@lingui/macro';
import { isPresent } from '@luminovo/commons';
import {
    colorSystem,
    DestructivePrimaryButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    SecondaryButton,
    Text,
} from '@luminovo/design-system';
import { SolutionConfigurationDTO, SolutionTag } from '@luminovo/http-client';
import {
    hasSolutionConfigurationManualCost,
    hasSolutionTag,
    isConsignedSolution,
    Solution,
} from '@luminovo/sourcing-core';
import { styled, ToggleButton, ToggleButtonGroup } from '@mui/material';
import React from 'react';
import { useDialogContext } from '../../../../components/contexts/ModalContext';

import { useMutationUpdateSolutionConfiguration } from '../../../../resources/solutionConfiguration/solutionConfigurationHandler';

const StyledToggleButton = styled(ToggleButton)({
    color: colorSystem.neutral[7],
    '&.MuiToggleButton-root.Mui-selected': {
        backgroundColor: colorSystem.neutral.white,
        color: colorSystem.neutral[9],
        boxShadow: `0px 2px 4px 1px rgba(76,83,102,0.15)`,
    },
    '&.MuiToggleButton-root.Mui-disabled': {
        color: colorSystem.neutral[6],
    },
    '&.MuiToggleButtonGroup-groupedHorizontal': {
        borderRadius: 4,
        margin: 0,
        border: 0,
    },
});

const StyledToggleButtonGroup = styled(ToggleButtonGroup)({
    height: 24,
    backgroundColor: colorSystem.neutral[2],
    padding: 4,
    gap: 4,
});

export function ButtonSolutionSelection({
    solutionConfigurationDTO,
    solution,
}: {
    solutionConfigurationDTO: SolutionConfigurationDTO;
    solution?: Solution;
}): JSX.Element {
    const automaticallySelected =
        isPresent(solution) &&
        hasSolutionTag(solution, SolutionTag.AutoSelected) &&
        !hasSolutionTag(solution, SolutionTag.Selected);

    const { mutateAsync, isPending: patchLoading } = useMutationUpdateSolutionConfiguration(
        solutionConfigurationDTO.id,
    );

    const { openDialog } = useManualCostWarningDialog();

    const updateSolutionToken = React.useCallback(async () => {
        const solutionToken = automaticallySelected ? (solution?.token ?? null) : null;
        await mutateAsync({ solutionToken });
    }, [automaticallySelected, solution, mutateAsync]);

    const isSelectAutomaticallyDisabled = solution === undefined || patchLoading || isConsignedSolution(solution);
    const handleChange = (event: React.ChangeEvent<{}>, value: boolean) => {
        event.stopPropagation();
        if (!isSelectAutomaticallyDisabled && value !== null) {
            if (hasSolutionConfigurationManualCost(solutionConfigurationDTO)) {
                openDialog(updateSolutionToken);
            } else {
                updateSolutionToken();
            }
        }
    };

    return (
        <StyledToggleButtonGroup
            style={{ cursor: patchLoading ? 'progress' : 'pointer' }}
            value={automaticallySelected}
            exclusive
            onChange={handleChange}
        >
            <StyledToggleButton disabled={patchLoading} value={true}>
                <Text variant="body" color="inherit">
                    <Trans>Automatic</Trans>
                </Text>
            </StyledToggleButton>
            <StyledToggleButton disabled={patchLoading} value={false}>
                <Text variant="body" color="inherit">
                    <Trans>Manual</Trans>
                </Text>
            </StyledToggleButton>
        </StyledToggleButtonGroup>
    );
}

export function useManualCostWarningDialog() {
    const { setDialog, closeDialog } = useDialogContext();

    return {
        openDialog: (updateSolutionToken: () => void) =>
            setDialog(
                <Dialog open={true} fullWidth maxWidth={'sm'} onClose={() => closeDialog()}>
                    <DialogTitle
                        title={t`Changing the selected solution will delete the manually added costs`}
                        handleClose={closeDialog}
                    />
                    <DialogContent>
                        <Text variant="body">
                            <Trans>
                                There are some manually added costs attached to the selected solution. If you select a
                                different solution these will be deleted. Are you sure you want to proceed?
                            </Trans>
                        </Text>
                    </DialogContent>
                    <DialogActions>
                        <SecondaryButton onClick={closeDialog}>
                            <Trans>Keep the solution</Trans>
                        </SecondaryButton>
                        <DestructivePrimaryButton
                            onClick={() => {
                                updateSolutionToken();
                                closeDialog();
                            }}
                        >
                            <Trans>Change and delete costs</Trans>
                        </DestructivePrimaryButton>
                    </DialogActions>
                </Dialog>,
            ),
    };
}
