import { t, Trans } from '@lingui/macro';
import { formatDecimal } from '@luminovo/commons';
import {
    colorSystem,
    FieldNumericControlled,
    Flexbox,
    SecondaryButton,
    TertiaryIconButton,
    Text,
    Tooltip,
} from '@luminovo/design-system';
import { SolutionConfigurationDTO, SolutionTag } from '@luminovo/http-client';
import { findSolutionTag, formatQuantity, Solution } from '@luminovo/sourcing-core';
import { Delete, Edit } from '@mui/icons-material';
import { Box } from '@mui/material';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { FormContainer } from '../../../../components/formLayouts/FormContainer';
import { SubmitButton } from '../../../../components/formLayouts/SubmitButton';
import { useHttpMutation } from '../../../../resources/mutation/useHttpMutation';
import { ScrapDetails } from '../../../Sourcing/components/Formula/ScrapDetails';
type FormState = { scrap: number | null };

const FormInner: React.FunctionComponent<{ onCancel: () => void }> = ({ onCancel }): JSX.Element => {
    const { control } = useFormContext<FormState>();

    return (
        <>
            <FieldNumericControlled name={'scrap'} control={control} min={0} />
            <Flexbox gap={4} paddingTop={'12px'} justifyContent="flex-end">
                <SecondaryButton size={'small'} onClick={onCancel}>
                    <Trans>Cancel</Trans>
                </SecondaryButton>
                <SubmitButton size="small" style={{ width: 'fit-content' }} />
            </Flexbox>
        </>
    );
};

export const SolutionConfigurationQuantity: React.FunctionComponent<{
    solution: Solution | null;
    solutionConfiguration: SolutionConfigurationDTO;
}> = ({ solution, solutionConfiguration }): JSX.Element => {
    const [isEditable, setIsEditable] = React.useState<boolean>(false);
    const { mutateAsync, isPending: isLoading } = useHttpMutation('PATCH /solution-configurations', {
        snackbarMessage: t`Scrap quantity updated`,
        onSuccess: () => setIsEditable(false),
    });

    const scrapTag = findSolutionTag(solution, SolutionTag.ScrapQuantity);
    const scrap = scrapTag?.content ?? solutionConfiguration.scrap_quantity;

    const onSubmit = async (formValues: FormState) => {
        await mutateAsync({
            requestBody: {
                items: [
                    {
                        id: solutionConfiguration.id,
                        update: { manual_scrap_quantity: formValues.scrap },
                    },
                ],
            },
        });
    };

    if (isEditable) {
        return (
            <Flexbox flexDirection={'column'} gap={8} width={'100%'}>
                <Flexbox justifyContent={'space-between'} width={'100%'} alignItems="center">
                    <Text variant="h5" color={colorSystem.neutral[8]}>
                        <Trans>Scrap quantity</Trans>
                    </Text>

                    <TertiaryIconButton size="small" disabled={isLoading} onClick={() => onSubmit({ scrap: null })}>
                        <Delete fontSize="inherit" />
                    </TertiaryIconButton>
                </Flexbox>
                <FormContainer defaultValues={{ scrap: scrap?.amount }} onSubmit={onSubmit}>
                    <FormInner onCancel={() => setIsEditable(false)} />
                </FormContainer>
            </Flexbox>
        );
    }

    return (
        <Box display="grid" columnGap={'20px'} alignItems="center" gridTemplateColumns={'1fr 1fr'} rowGap={'4px'}>
            <Text color={colorSystem.neutral[6]} variant="h4">
                <Trans>Quantity</Trans>
            </Text>

            <Flexbox justifyContent={'space-between'} width={'100%'} alignItems={'center'}>
                <Text variant="h4" color={colorSystem.neutral[6]}>
                    <Trans>Scrap quantity</Trans>
                </Text>

                <TertiaryIconButton size="small" onClick={() => setIsEditable(true)}>
                    <Edit fontSize="inherit" />
                </TertiaryIconButton>
            </Flexbox>

            <Text>{formatQuantity(solutionConfiguration.aggregated_quantity)}</Text>

            {scrap ? (
                <Tooltip variant="white" title={<ScrapDetails scrapType={scrap.type} />}>
                    <Text>{formatDecimal(scrap.amount)}</Text>
                </Tooltip>
            ) : (
                <Text>-</Text>
            )}
        </Box>
    );
};
