import { t, Trans } from '@lingui/macro';
import { colorSystem, Flexbox, Text } from '@luminovo/design-system';
import { PCBV2 } from '@luminovo/http-client';
import { Info } from '@mui/icons-material';
import { Box } from '@mui/material';
import React from 'react';
import { ViewContext } from '../../../Bom/components/ModuleTableData';
import { PcbCapabilityCheckWidget } from '../../components/PcbCapabilityCheckWidget';
import { PcbPreviewer } from '../../components/SVGPreview';
import { PcbSide } from '../../components/SVGPreview/PCBColorSchemas';

export function PcbRenderer({
    pcb,
    assemblyId,
    rfqId,
    isRfqEditable,
    viewContext,
}: {
    pcb: PCBV2;
    assemblyId: string;
    rfqId: string;
    isRfqEditable: boolean;
    viewContext: ViewContext;
}) {
    const [pcbSide, setPcbSide] = React.useState<PcbSide>('front');

    const handlePcbSideChange = () => {
        setPcbSide((side) => (side === 'front' ? 'rear' : 'front'));
    };

    return (
        <Box style={{ height: 'calc(100vh - 155px)' }} position={'relative'}>
            <PcbPreviewer
                assemblyId={assemblyId}
                rfqId={rfqId}
                pcb={pcb}
                pcbSide={pcbSide}
                onPcbSideChange={handlePcbSideChange}
            />
            <Text
                variant="body-small-semibold"
                style={{ position: 'absolute', left: 5, top: 5, color: colorSystem.neutral.white, cursor: 'pointer' }}
                onClick={() => setPcbSide((side) => (side === 'front' ? 'rear' : 'front'))}
            >
                {pcbSide === 'front' ? t`Showing front` : t`Showing rear`}
            </Text>
            <Box
                style={{
                    position: 'absolute',
                    bottom: '8px',
                    color: colorSystem.neutral.white,
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                    opacity: 0.7,
                }}
            >
                <Flexbox
                    style={{
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        gap: '8px',
                        padding: '8px',
                        backgroundColor: colorSystem.neutral[2],
                        borderRadius: '12px',
                    }}
                >
                    <Info
                        fontSize="small"
                        style={{
                            color: colorSystem.neutral[5],
                        }}
                    />
                    <Text
                        variant="body-small"
                        style={{
                            color: colorSystem.neutral[6],
                        }}
                    >
                        <Trans>Rendered image might differ from actual PCB</Trans>
                    </Text>
                </Flexbox>
            </Box>
            <PcbCapabilityCheckWidget
                pcb={pcb}
                rfqId={rfqId}
                assemblyId={assemblyId}
                isRfqEditable={isRfqEditable}
                viewContext={viewContext}
            />
        </Box>
    );
}
