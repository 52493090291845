import { isPresent } from '@luminovo/commons';
import { colorSystem, Flexbox, Link, StatusChip, Tag, Text, Tooltip } from '@luminovo/design-system';
import {
    NegotiationLineItem,
    NegotiationLineItemStatus,
    QuoteRequestDTO,
    QuoteRequestLineItemAggregatedStatusesDTO,
    QuoteRequestLineItemStatus,
} from '@luminovo/http-client';
import { formatSupplierDTO } from '@luminovo/sourcing-core';
import { Box, Skeleton } from '@mui/material';
import React from 'react';
import { route } from '../../../utils/routes';
import { QuoteRequestStatusChip } from '../components/QuoteRequestStatusChip';
import { useQuoteRequestLineItems, useQuoteRequests } from '../hooks/negotiationHandlers';
import { compareQuoteRequestStatus } from '../model/compareQuoteRequestStatus';

interface NegotiationLineItemQuoteRequestSummary {
    status: NegotiationLineItemStatus;
    label: string;
}

export function getNegotiationLineItemStatusColor(status: NegotiationLineItemStatus): keyof typeof colorSystem {
    switch (status) {
        case NegotiationLineItemStatus.NoQuoteRequests:
            return 'yellow';
        case NegotiationLineItemStatus.NotSent:
            return 'neutral';
        case NegotiationLineItemStatus.Sent:
            return 'blue';
        case NegotiationLineItemStatus.PartiallyReceived:
            return 'green';
        case NegotiationLineItemStatus.Received:
            return 'green';
        case NegotiationLineItemStatus.NoBid:
            return 'violet';
        case NegotiationLineItemStatus.Awarded:
            return 'primary';
    }
}

export function getNegotiationLineItemStatusRawColor(status: NegotiationLineItemStatus): string {
    const color = getNegotiationLineItemStatusColor(status);
    if (status === NegotiationLineItemStatus.PartiallyReceived) {
        return colorSystem[color][4];
    }
    return colorSystem[color][5];
}

export function calculateNegotiationLineItemQuoteRequestSummary(
    item?: QuoteRequestLineItemAggregatedStatusesDTO['statuses_by_negotiation_line_item'][number],
): NegotiationLineItemQuoteRequestSummary {
    // Filter out Discarded statuses
    const relevantStatuses = item?.statuses.filter((s) => s.status !== QuoteRequestLineItemStatus.Discarded) ?? [];

    const totalCount = relevantStatuses.reduce((sum, status) => sum + status.count, 0);

    if (totalCount === 0) {
        return {
            status: NegotiationLineItemStatus.NoQuoteRequests,
            label: 'No quote requests',
        };
    }

    const statusCounts = relevantStatuses.reduce(
        (acc, { status, count }) => {
            acc[status] = (acc[status] || 0) + count;
            return acc;
        },
        {} as Record<QuoteRequestLineItemStatus, number>,
    );

    const awardedCount = statusCounts[QuoteRequestLineItemStatus.Awarded] || 0;
    const sentCount = statusCounts[QuoteRequestLineItemStatus.Sent] || 0;
    const receivedCount = statusCounts[QuoteRequestLineItemStatus.Received] || 0;
    const noBidCount = statusCounts[QuoteRequestLineItemStatus.NoBid] || 0;
    const notSentCount = statusCounts[QuoteRequestLineItemStatus.NotSent] || 0;
    const overdueCount = statusCounts[QuoteRequestLineItemStatus.Overdue] || 0;

    if (awardedCount > 0) {
        return {
            status: NegotiationLineItemStatus.Awarded,
            label: `Awarded`,
        };
    }

    if (noBidCount === totalCount) {
        return {
            status: NegotiationLineItemStatus.NoBid,
            label: `${totalCount}/${totalCount} submitted`,
        };
    }

    if (notSentCount === totalCount - noBidCount) {
        return {
            status: NegotiationLineItemStatus.NotSent,
            label: `${totalCount} not sent`,
        };
    }

    if (receivedCount > 0) {
        if (sentCount === 0 && notSentCount === 0 && overdueCount === 0) {
            return {
                status: NegotiationLineItemStatus.Received,
                label: `${totalCount}/${totalCount} submitted`,
            };
        }
        return {
            status: NegotiationLineItemStatus.PartiallyReceived,
            label: `${receivedCount}/${totalCount} submitted`,
        };
    }

    if (sentCount > 0 || overdueCount > 0) {
        const submittedCount = sentCount + noBidCount + overdueCount;
        return {
            status: NegotiationLineItemStatus.Sent,
            label: `${submittedCount}/${totalCount} sent`,
        };
    }
    // This should never happen, but TypeScript requires a return statement
    throw new Error('Unexpected status combination');
}

export function NegotiationLineItemQuoteRequestSummaryChip({
    negotiationId,
    negotiationLineItem,
    quoteRequestSummary,
}: {
    negotiationId: number;
    negotiationLineItem: NegotiationLineItem;
    quoteRequestSummary?: NegotiationLineItemQuoteRequestSummary;
}): JSX.Element {
    if (!isPresent(quoteRequestSummary)) {
        return <Skeleton width={60} />;
    }

    const { label } = quoteRequestSummary;

    return (
        <Tooltip
            variant="white"
            placement="left-end"
            disableMaxWidth={true}
            title={<TooltipContent negotiationLineItem={negotiationLineItem} negotiationId={negotiationId} />}
        >
            <span>
                <StatusChip label={label} color={getNegotiationLineItemStatusColor(quoteRequestSummary.status)} />
            </span>
        </Tooltip>
    );
}

function TooltipContent({
    negotiationLineItem,
    negotiationId,
}: {
    negotiationLineItem: NegotiationLineItem;
    negotiationId: number;
}) {
    const { data: quoteRequestLineItems, isLoading: isQuoteRequestLineItemLoading } = useQuoteRequestLineItems(
        negotiationLineItem ? [negotiationLineItem.id] : [],
    );
    const quoteRequestIds = Array.from(new Set(quoteRequestLineItems?.map((x) => x.quote_request_id) ?? []));
    const { data: quoteRequests = [], isLoading: isQuoteRequestsLoading } = useQuoteRequests(quoteRequestIds);

    if (isQuoteRequestLineItemLoading || isQuoteRequestsLoading) {
        return (
            <Flexbox alignItems="center" gap={20}>
                <Skeleton width={100} />
                <Skeleton width={50} />
            </Flexbox>
        );
    }

    const rows = quoteRequests.sort(compareQuoteRequestStatus()).map((quoteRequest: QuoteRequestDTO) => (
        <React.Fragment key={quoteRequest.number}>
            <Link
                to={route(
                    '/negotiations/:negotiationId/quote-requests/:quoteRequestId',
                    {
                        negotiationId,
                        quoteRequestId: quoteRequest.id,
                    },
                    {
                        highlightNegotiationLineItemId: String(negotiationLineItem?.id),
                    },
                )}
                attention="high"
                style={{ marginRight: 16 }}
            >
                {formatSupplierDTO(quoteRequest.supplier)}
            </Link>
            <Flexbox gap={4}>
                <Tag attention="low" color={'neutral'} label={`#${quoteRequest.number}`} />
                <Text variant="body-small">{<QuoteRequestStatusChip status={quoteRequest.status} />}</Text>
            </Flexbox>
        </React.Fragment>
    ));

    return (
        <Flexbox flexDirection="column" gap={'8px'} padding={'8px'}>
            <Text variant="h3">Quote requests summary</Text>
            <Flexbox
                flexDirection="column"
                gap={'8px'}
                onClick={(e) => e.stopPropagation()}
                minWidth={80}
                maxHeight={248}
                overflow="auto"
            >
                <Box display={'grid'} gap={1} gridTemplateColumns={'1fr auto'}>
                    {rows.length > 0 ? (
                        rows
                    ) : (
                        <Text variant="body-small" color={colorSystem.neutral[6]}>
                            No quote requests created yet.
                        </Text>
                    )}
                </Box>
            </Flexbox>
        </Flexbox>
    );
}
