import { plural, t } from '@lingui/macro';
import { Message, usePersistedState } from '@luminovo/design-system';
import { Capability } from '../../../../resources/pcb/pcbFunctions';
import { getPcbPdfExtractionId } from '../PcbAnalysis/utils/getPcbAnalysisId';
import { encodePcbFilePath, usePdfViewerDrawer } from '../PcbPdfViewer/usePdfViewerDrawer';

export function PdfExtractionMessageBox({ capabilities, pcbId }: { capabilities: Capability[]; pcbId: string }) {
    const [userHasSeenExtractionMessageBox, setUserHasSeenExtractionMessageBox] = usePersistedState<'true' | 'false'>(
        getPcbPdfExtractionId(pcbId),
        'false',
        localStorage,
    );
    const { openDrawer } = usePdfViewerDrawer();

    if (capabilities.length === 0 || userHasSeenExtractionMessageBox === 'true') {
        return <></>;
    }

    const pluralizedText = plural(capabilities.length, {
        one: 'field was',
        other: 'fields were',
        // eslint-disable-next-line spellcheck/spell-checker
    });

    return (
        <Message
            attention="high"
            size="large"
            variant="primary"
            title={t`PDF extraction`}
            // eslint-disable-next-line spellcheck/spell-checker
            message={t`${capabilities.length} ${pluralizedText} automatically extracted from uploaded PDFs`}
            action={{
                label: t`Open PDF`,
                onClick: () => {
                    openDrawer({
                        selectedPdf: {
                            fileName: encodePcbFilePath(capabilities[0].extractedFromPdf?.file?.path),
                            regions: capabilities.flatMap((cap) => cap.extractedFromPdf?.regions ?? []),
                        },
                    });
                },
            }}
            onClose={() => setUserHasSeenExtractionMessageBox('true')}
        />
    );
}
