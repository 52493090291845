import { Flexbox, colorSystem } from '@luminovo/design-system';
import { Search as SearchIcon } from '@mui/icons-material';
import { CircularProgress, InputAdornment, OutlinedInput, OutlinedInputProps, styled } from '@mui/material';
import { SPACING_MEDIUM } from '../../design-system/themes';

const StyledOutlinedInput = styled(OutlinedInput)({
    width: '100%',
    '&label.Mui-focused': {
        color: colorSystem.neutral[5],
    },
    '& input': {
        padding: `11.75px`,
    },
    '&.MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
            borderColor: colorSystem.neutral[5],
        },
    },
});

const SearchBarButton = ({
    isButtonActive,
    onClick,
}: {
    isButtonActive: boolean;
    onClick: () => void;
}): JSX.Element => {
    return (
        <Flexbox alignItems="center" borderLeft={1} paddingLeft={1} borderColor={colorSystem.neutral[5]}>
            <SearchIcon
                style={{
                    cursor: isButtonActive ? 'pointer' : undefined,
                    color: isButtonActive ? colorSystem.primary[7] : colorSystem.neutral[5],
                }}
                onClick={isButtonActive ? onClick : undefined}
            />
        </Flexbox>
    );
};

export const SearchBar = ({
    isLoading: loading,
    hasTextChanged,
    onSubmit,
    testId,
    ...props
}: OutlinedInputProps & {
    isLoading: boolean;
    hasTextChanged: boolean;
    onSubmit: () => void;
    testId?: string;
}): JSX.Element => {
    return (
        <StyledOutlinedInput
            data-testid={testId}
            endAdornment={
                <InputAdornment position="end">
                    {loading ? (
                        <CircularProgress size={SPACING_MEDIUM} />
                    ) : (
                        <SearchBarButton isButtonActive={hasTextChanged} onClick={onSubmit} />
                    )}
                </InputAdornment>
            }
            {...props}
        />
    );
};
