import { Trans } from '@lingui/macro';
import { Flexbox, Text } from '@luminovo/design-system';
import { MenuItem } from '@mui/material';
import { useFormContext, useWatch } from 'react-hook-form';
import { CheckboxFormControlController } from '../../../../components/formLayouts/reactHookFormComponents/reactHookFormComponents';
import { CalculationTableForm } from '../types/formTypes';

export const IncludeOneTimeCostCheckbox = (): JSX.Element => {
    const { control, setValue } = useFormContext<CalculationTableForm>();
    const name = 'calculationDetails.includeOneTimeCostInProjectCosts' as const;
    const value = useWatch({ control, name });

    return (
        <MenuItem onClick={() => setValue(name, !value, { shouldTouch: true, shouldDirty: true })}>
            <Flexbox alignItems="center">
                <CheckboxFormControlController
                    control={control}
                    name={name}
                    label={
                        <Flexbox width="max-content">
                            <Text>
                                <Trans>Separate one-time costs from material costs</Trans>
                            </Text>
                        </Flexbox>
                    }
                />
            </Flexbox>
        </MenuItem>
    );
};
