import { t } from '@lingui/macro';
import { Flexbox, StatusChip, Text } from '@luminovo/design-system';
import { PreferenceStatusEnum } from '@luminovo/http-client';
import { Popover } from '@mui/material';
import { useState } from 'react';

export type ManufacturerStatusChipProps = {
    manufacturerName: string;
    preferenceStatus: PreferenceStatusEnum | null;
    partCategory?: string;
};

export function ManufacturerStatusChip({ manufacturerName, preferenceStatus }: ManufacturerStatusChipProps) {
    if (!preferenceStatus) {
        return <StatusChip label={manufacturerName} color="green" />;
    }

    return (
        <StatusChip
            label={manufacturerName}
            color={preferenceStatus === PreferenceStatusEnum.Blocked ? 'red' : 'primary'}
        />
    );
}

type ManyManufacturerStatusChipProps = {
    manufacturerPreferences: ManufacturerStatusChipProps[];
    aggregatedPreference: PreferenceStatusEnum | null;
};

export function ManyManufacturersStatusChip({
    aggregatedPreference,
    manufacturerPreferences,
}: ManyManufacturerStatusChipProps) {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const handleClick: React.MouseEventHandler<HTMLElement> = (event) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const color = (status: PreferenceStatusEnum | null) => {
        if (!status) {
            return 'green';
        }
        switch (status) {
            case PreferenceStatusEnum.Blocked:
                return 'red';
            case PreferenceStatusEnum.Preferred:
                return 'primary';
        }
    };

    return (
        <>
            <StatusChip
                label={t`${manufacturerPreferences.length} manufacturers`}
                color={color(aggregatedPreference)}
                onClick={handleClick}
            />
            <Popover
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <Flexbox width={'250px'} flexDirection={'column'} padding={'12px'} borderRadius={'160px'} gap={'8px'}>
                    {manufacturerPreferences.map((pref, i) => (
                        <Flexbox key={i} justifyContent={'space-between'}>
                            <Text>{pref.manufacturerName}</Text>
                            <StatusChip label={pref.partCategory} color={color(pref.preferenceStatus)} />
                        </Flexbox>
                    ))}
                </Flexbox>
            </Popover>
        </>
    );
}
