import { t } from '@lingui/macro';
import { useHistory } from 'react-router';
import { ButtonDelete } from '../../../components/ButtonDelete';
import { useHttpMutation } from '../../../resources/mutation/useHttpMutation';
import { route } from '../../../utils/routes';

export function ButtonDeleteNegotiation({ negotiationId, href }: { negotiationId: number; href: string }) {
    const history = useHistory();
    const { mutateAsync: deleteNegotiation, isPending: isLoading } = useHttpMutation('DELETE /negotiation/:id', {
        snackbarMessage: t`Negotiation deleted`,
    });

    return (
        <ButtonDelete
            title={t`Delete negotiation`}
            description={t`Are you sure you want to delete this negotiation?`}
            onDelete={async () => {
                history.replace(route('/negotiations'));
                await deleteNegotiation({ pathParams: { id: negotiationId } });
            }}
            isLoading={isLoading}
            href={href}
        />
    );
}
