/* eslint-disable camelcase */
import { t, Trans } from '@lingui/macro';
import { compareByString, formatDecimal } from '@luminovo/commons';
import { ReleaseTag } from '@luminovo/design-system';
import { partSpecificationTypeTranslations, QuantityUnitRecordPlural } from '@luminovo/sourcing-core';
import { transEnum } from '../../components/localization/TransEnum';
import { roundToNDecimalPlaces } from '../../utils/converterUtils';
import { assertUnreachable } from '../../utils/typingUtils';
import {
    BomImporterIssue,
    DuplicatedDesignatorsBomImporterIssue,
    IssueNameEnum,
    missingRequirementsTranslations,
} from './bomImporterIssuesEnum';

export const convertIssueToUserFacingMessage = (
    issue: BomImporterIssue | DuplicatedDesignatorsBomImporterIssue,
): string | JSX.Element => {
    switch (issue.name) {
        case IssueNameEnum.AMBIGUOUS_QUANTITIES_TEMPLATE:
            return t`Ambiguous quantities: ${issue.content.quantities}.`;
        case IssueNameEnum.MULTIPLE_QUANTITY_DESIGNATOR_MATCHES:
            return t`Found multiple possible quantity / designator combinations.`;
        case IssueNameEnum.NO_QUANTITY_COLUMN:
            return t`No quantity column found.`;
        case IssueNameEnum.NO_DESIGNATOR_AND_QUANTITY:
            return t`Neither designator nor quantity column found. Rename your designator column to “Designator” and/or rename your quantity column to “Quantity”.`;
        case IssueNameEnum.NO_DNP_COLUMN:
            return t`No DNP column found. We are assuming none of the BOM items are DNP.`;
        case IssueNameEnum.NO_GENERIC_PART_SUGGESTION_BECAUSE_INDUCTOR_DESIGNATOR:
            // eslint-disable-next-line spellcheck/spell-checker
            return t`There are designators starting with an 'L'. We assume they are inductors and will not extract them as generic resistors. If they should be extracted as generic resistors, either rename them so that they start with an 'R', or remove the designator column tag and we'll generate designators for you.`;
        case IssueNameEnum.MANY_QUANTITIES_NO_DESIGNATOR:
            return t`Many quantities but no designators found.`;
        case IssueNameEnum.UNKNOWN_QUANTITY_UNIT:
            return t`Unknown quantity unit.`;
        case IssueNameEnum.DUPLICATE_DESIGNATORS_FOUND:
            if ('duplicatedDesignatorsPerLine' in issue.content) {
                return (
                    <Trans>
                        Designators must be unique per assembly. There was a duplicate designators error while trying to
                        merge the following lines. Make sure rows with the identical set of designators are consecutive.
                        Please adjust and re-upload the file.
                        <br />
                        Duplicates:{' '}
                        <ul>
                            {issue.content.duplicatedDesignatorsPerLine.map((designators, idx) => (
                                <li key={idx}>
                                    <b>{designators.join(', ')}</b>
                                </li>
                            ))}
                        </ul>
                    </Trans>
                );
            } else {
                return (
                    <Trans>
                        Designators must be unique per assembly. There was a duplicate designators error while trying to
                        merge the following lines. Make sure rows with the identical set of designators are consecutive.
                        Please adjust and re-upload the file.
                        <br />
                        Duplicates: <b>{issue.content.duplicatedDesignators.sort(compareByString).join(', ')}</b>
                    </Trans>
                );
            }
        case IssueNameEnum.PARTIAL_MPN_MATCH:
            return t`Partial MPN match.`;
        case IssueNameEnum.CONTRADICTING_DNP_INFORMATION_TEMPLATE:
            return t`Got contradicting DNP information: ${issue.content.extracted_dnps}`;
        case IssueNameEnum.NUMBER_OF_LINES:
            return t`We found ${issue.content.numberOfLines} lines in the Excel.`;
        case IssueNameEnum.AGGREGATED_LINES_SAME_DESIGNATORS:
            return t`Multiple Excel lines with the same designators were aggregated.`;
        case IssueNameEnum.AGGREGATED_LINES_SAME_CONTENT:
            return t`Multiple Excel lines with the same content were aggregated.`;
        case IssueNameEnum.NO_PART_OPTIONS:
            return t`No part options or generic part found.`;
        case IssueNameEnum.NO_HEADER_ROW:
            return t`No header row found.`;
        case IssueNameEnum.NO_MPN_COLUMN:
            return t`No MPN column found. Rename your MPN column to "MPN".`;
        case IssueNameEnum.DESIGNATOR_AND_QUANTITY_DO_NOT_MATCH_TEMPLATE:
            return t`Designator and quantity do not match. Number of designators: ${issue.content.num_designators}. Quantity: ${issue.content.quantity}. Using 1 piece per designator. If wrong, please fix the original file.`;
        case IssueNameEnum.NO_DESIGNATOR_COLUMN:
            return t`No designator column found. We automatically generated designators for you.`;
        case IssueNameEnum.COULD_NOT_RESOLVE_DESIGNATORS_AND_QUANTITY:
            return t`Could not resolve designators and quantity.`;
        case IssueNameEnum.ASSUME_TOTAL_QUANTITY_PROVIDED_TEMPLATE:
            const num_designators = formatDecimal(issue.content.num_designators);
            const quantity = formatDecimal(issue.content.quantity);
            const unit = transEnum(issue.content.unit, QuantityUnitRecordPlural);

            return t`${num_designators} designators for quantity ${quantity}. We are assuming ${num_designators} x ${formatDecimal(
                roundToNDecimalPlaces(issue.content.quantity / issue.content.num_designators, 2),
            )} = ${quantity} ${unit} in total. If wrong, please fix the original file.`;
        case IssueNameEnum.MANUFACTURER_DOES_NOT_MATCH_PART_OPTIONS:
            return t`Manufacturer mismatch: provided manufacturer ${issue.content.manufacturer_primary_name} not matching any part option.`;
        case IssueNameEnum.ASSUME_SMT_IMPERIAL_SYSTEM:
            return t`Assuming SMT package code ${issue.content.package_str} uses imperial system.`;
        case IssueNameEnum.ASSUME_CERAMIC_CAPACITOR:
            return t`Assuming capacitor is ceramic.`;
        case IssueNameEnum.ONLY_CERAMIC_CAPACITORS_SUPPORTED:
            return t`Only ceramic capacitors are supported at the moment.`;
        case IssueNameEnum.MISSING_REQUIRED_TECHNICAL_PARAMETER:
            const specificationType = transEnum(issue.content.generic_part_type, partSpecificationTypeTranslations);
            const missingRequirements = issue.content.missing_requirement
                .map((requirement) => transEnum(requirement, missingRequirementsTranslations))
                .join(', ');

            return t`${specificationType} specification is missing required ${missingRequirements}`;
        case IssueNameEnum.NO_BOM_ITEM_FOUND:
            return t`No Bom item found.`;
        case IssueNameEnum.IGNORED_MANUFACTURER:
            return t`Manufacturer ignored: provided manufacturer ${issue.content.provided_manufacturer} could not be verified.`;
        case IssueNameEnum.CONTRADICTING_DNP_AND_QUANTITY:
            return t`Contradicting DNP information: DNP is false but quantity is 0. Assuming DNP is true.`;
        case IssueNameEnum.NO_QUANTITY_MANY_DESIGNATORS:
            return t`Could not resolve designators without quantity.`;
        case IssueNameEnum.GENERIC_PART_SUGGESTION_BECAUSE_MANUFACTURER_GIVEN:
            return t`Generic part is only suggested, not added automatically, because a manufacturer is given.`;
        case IssueNameEnum.MANUFACTURER_DOES_NOT_MATCH_PART_OPTIONS_ALTERNATIVE_NAME:
            return t`Manufacturer mismatch: provided manufacturer ${issue.content.manufacturer_matched_alternative_name} is an alternative name for ${issue.content.manufacturer_primary_name} but does not match any part option.`;
        case IssueNameEnum.MULTI_LEVEL_BOM_STRUCTURE_DETECTED:
            return (
                <>
                    <span
                        style={{ marginRight: '4px' }}
                    >{t`We identified the BOM structure as a multi-level BOM`}</span>
                    <ReleaseTag color="violet" label="BETA" />
                </>
            );
        case IssueNameEnum.EMPTY_EXCEL_LINES:
            return t`No excel lines were submitted.`;
        default:
            assertUnreachable(issue);
    }
};
