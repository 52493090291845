import * as r from 'runtypes';
import { endpoint } from '../http/endpoint';
import { DemandDTORuntype } from '../negotiation';
import { LoadingOffersProgressDTORuntype } from '../part/partBackendTypes';
import { UserDTORuntype } from '../user';
import {
    CustomerPortalStateRuntype,
    QuotationWarningsRuntype,
    RfqDTORuntype,
    RfqInputDTORuntype,
    RfqListItemRuntype,
    RfqUpdateRuntype,
} from './rfqBackendTypes';
import { SnapshotMetadataItemRuntype, SnapshotRuntype, SnapshotTagRuntype } from './snapshotRuntype';

/* eslint-disable camelcase */

export const rfqEndpoints = {
    'GET /rfqs': endpoint({
        description: 'GET multiple RfQs',
        pathParams: r.Undefined,
        queryParams: r.Union(
            r.Record({ activity_id: r.String }),
            r.Record({}),
            r.Record({ expense_id: r.String }),
            r.Record({
                filter: r.Union(r.Literal('all'), r.Literal('archived'), r.Literal('not-archived')),
            }),
        ),
        requestBody: r.Undefined,
        responseBody: r.Record({
            data: r.Array(RfqListItemRuntype),
            total_pages: r.Number,
        }),
    }),

    'GET /gateway/test': endpoint({
        description: 'Gateway',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Unknown,
    }),

    'POST /rfqs': endpoint({
        description: 'Create an RfQ',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: RfqInputDTORuntype,
        responseBody: r.Record({ data: RfqDTORuntype }),
        invalidates: ['GET /rfqs', 'GET /rfqs/:rfqId', 'GET /organization-settings/auto-rfq-number/next'],
    }),

    'GET /rfqs/:rfqId': endpoint({
        description: 'GET a single RfQ',
        pathParams: r.Record({ rfqId: r.String }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({
            data: RfqDTORuntype,
        }),
    }),

    'GET /rfqs/snapshots': endpoint({
        description: 'Fetch a list of snapshot metadata items for all RfQs',
        pathParams: r.Undefined,
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({
            items: r.Array(SnapshotMetadataItemRuntype),
        }),
    }),

    'GET /rfqs/:rfqId/snapshot': endpoint({
        description: 'Fetch a list of snapshot metadata items',
        pathParams: r.Record({
            rfqId: r.String,
        }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({
            items: r.Array(SnapshotMetadataItemRuntype),
        }),
    }),

    'GET /rfqs/:rfqId/demands': endpoint({
        description: 'Fetch a list of demands for a single RfQ',
        pathParams: r.Record({
            rfqId: r.String,
        }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({
            items: r.Array(DemandDTORuntype),
        }),
    }),

    'POST /rfqs/:rfqId/snapshot/trigger': endpoint({
        description: 'Create a sourcing snapshot',
        pathParams: r.Record({
            rfqId: r.String,
        }),
        queryParams: r.Undefined,
        requestBody: r.Record({
            tags: r.Array(r.Unknown),
        }),
        responseBody: r.Unknown,
        invalidates: ['GET /rfqs/:rfqId/snapshot/:snapshotId', 'GET /rfqs/:rfqId/snapshot', 'GET /rfqs/snapshots'],
    }),

    'GET /rfqs/:rfqId/snapshot/:snapshotId': endpoint({
        description: 'Fetch a snapshot',
        pathParams: r.Record({
            rfqId: r.String,
            snapshotId: r.String,
        }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: SnapshotRuntype,
    }),

    'PATCH /rfqs/:rfqId/snapshot/:snapshotId': endpoint({
        description: 'Update a snapshot',
        pathParams: r.Record({
            rfqId: r.String,
            snapshotId: r.String,
        }),
        queryParams: r.Undefined,
        requestBody: r.Record({
            tags: r.Array(SnapshotTagRuntype),
        }),
        responseBody: r.Unknown,
        invalidates: ['GET /rfqs/:rfqId/snapshot/:snapshotId', 'GET /rfqs/:rfqId/snapshot', 'GET /rfqs/snapshots'],
    }),

    'POST /rfqs/:rfqId/update-offers': endpoint({
        description: 'Updates all the offers in an RfQ',
        pathParams: r.Record({ rfqId: r.String }),
        queryParams: r.Undefined,
        requestBody: r.Record({ since: r.String }),
        responseBody: r.Record({ since: r.String }),
        invalidates: ['GET /panels'],
    }),

    'GET /sourcing/progress/:rfqId': endpoint({
        description: 'Fetches the number of orders still being updated',
        pathParams: r.Record({ rfqId: r.String }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: LoadingOffersProgressDTORuntype,
    }),

    'PATCH /rfqs/:rfqId': endpoint({
        description: 'PATCH a single RfQ',
        pathParams: r.Record({ rfqId: r.String }),
        queryParams: r.Undefined,
        requestBody: RfqUpdateRuntype,
        responseBody: r.Record({}),
        invalidates: ['GET /rfqs', 'GET /rfqs/:rfqId', 'GET /order-management/order-selection-options'],
    }),

    'PATCH /rfqs/:rfqId/archive': endpoint({
        description: 'Archive or unarchive a single RfQ',
        pathParams: r.Record({ rfqId: r.String }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({}),
        invalidates: ['GET /rfqs', 'GET /rfqs/:rfqId'],
    }),

    'PATCH /rfqs/:rfqId/unarchive': endpoint({
        description: 'Archive or unarchive a single RfQ',
        pathParams: r.Record({ rfqId: r.String }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({}),
        invalidates: ['GET /rfqs', 'GET /rfqs/:rfqId'],
    }),

    'DELETE /rfqs/:rfqId': endpoint({
        description: 'Deletes a single RfQ',
        pathParams: r.Record({ rfqId: r.String }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({
            deleted: r.Number,
        }),
        invalidates: ['GET /rfqs'],
        removes: ['GET /rfqs/:rfqId', 'POST /sourcing-scenarios/sourcing-full/bulk'],
    }),
    'GET /rfqs/:rfqId/possible-contributors': endpoint({
        description: 'Returns a list of all possible contributors on a single RFQ',
        pathParams: r.Record({ rfqId: r.String }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({ data: r.Array(UserDTORuntype) }),
    }),

    'POST /rfqs/:rfqId/technical-support-request': endpoint({
        description: 'Requests technical support from the EMS',
        pathParams: r.Record({ rfqId: r.String }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({}),
    }),

    'POST /rfqs/:rfqId/edit-quotation-request': endpoint({
        description: 'Requests to edit quotation from the EMS',
        pathParams: r.Record({ rfqId: r.String }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({}),
    }),

    'PATCH /rfqs/:rfqId/request-quotation': endpoint({
        description: 'As a customer request a quotation from the EMS',
        pathParams: r.Record({ rfqId: r.String }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: r.Record({}),
        invalidates: ['GET /rfqs', 'GET /rfqs/:rfqId'],
    }),

    'POST /rfqs/:rfqId/customer-portal': endpoint({
        description:
            'Get the state of the customer portal as a customer. Potentially sets the RfQ status to QuotationAvailable when the workflow is automatic and the RfQ is ready for quotation.',
        pathParams: r.Record({ rfqId: r.String }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: CustomerPortalStateRuntype,
        invalidates: ['GET /rfqs', 'GET /rfqs/:rfqId'],
    }),

    'GET /rfqs/:rfqId/quotation/warnings': endpoint({
        description: 'Get warnings indicating if it is okay for an EMS to update the RfQ status to QuotationAvailable',
        pathParams: r.Record({ rfqId: r.String }),
        queryParams: r.Undefined,
        requestBody: r.Undefined,
        responseBody: QuotationWarningsRuntype,
    }),
};
