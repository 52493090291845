import { Trans } from '@lingui/macro';
import {
    colorSystem,
    SecondaryButton,
    SidebarLayout,
    Text,
    Toolbar,
    usePersistedVisibility,
} from '@luminovo/design-system';
import { UserType } from '@luminovo/http-client';
import { AcUnit, Home, Info } from '@mui/icons-material';
import { Box } from '@mui/material';
import { Avatar } from '../../../components/Navbar/Avatar';
import { useCurrentUserDetailsContext } from '../../../components/contexts/CurrentUserDetailsContext';
import { ErrorFallback } from '../../../components/errorHandlers/ErrorBoundary';
import { route } from '../../../utils/routes';
import { useQuoteRequest } from '../hooks/negotiationHandlers';
import { useIsQuoteRequestFrozen } from '../pages/supplier-portal/SupplierPortalQuoteRequestPage/useIsQuoteRequestFrozen';

export function LayoutSupplierPortal(props: {
    children: React.ReactNode;
    quoteRequestId?: string;
    actions?: React.ReactNode;
    breadcrumbLabel?: string;
    sidebar?: JSX.Element;
}) {
    const { organization } = useCurrentUserDetailsContext();
    const { data: quoteRequest } = useQuoteRequest(props.quoteRequestId);

    const breadcrumbs: Array<{ title: JSX.Element | string; href: string }> = [
        {
            title: (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                    <Home />
                    <Text>{`${organization.name} supplier portal`}</Text>
                </Box>
            ),
            href: route('/supplier-portal'),
        },
    ];
    if (props.quoteRequestId) {
        breadcrumbs.push({
            title: `Quote request #${quoteRequest?.number ?? ''}`,
            href: route(`/supplier-portal/quote-requests/:quoteRequestId`, { quoteRequestId: props.quoteRequestId }),
        });
    }
    if (props.breadcrumbLabel) {
        breadcrumbs.push({
            title: props.breadcrumbLabel,
            href: '',
        });
    }

    return (
        <>
            <SidebarLayout
                navbar={
                    <Toolbar
                        style={{
                            boxShadow: `inset ${colorSystem.neutral[2]} 0px -1px 0px 0px`,
                        }}
                        breadcrumbs={breadcrumbs}
                    >
                        {props.actions}
                        <div style={{ width: 2 }} />
                        <Avatar variant="light" style={{ margin: 0 }} />
                    </Toolbar>
                }
                sidebar={props.sidebar}
                defaultSidebarWidth="320px"
                fallback={ErrorFallback}
                contentBackground={colorSystem.neutral[1]}
                content={
                    <>
                        {props.quoteRequestId && <MessageQuoteRequestFrozen quoteRequestId={props.quoteRequestId} />}
                        <MessageNotSupplierUser />
                        {props.children}
                    </>
                }
            ></SidebarLayout>
        </>
    );
}

function MessageNotSupplierUser() {
    const { userType } = useCurrentUserDetailsContext();

    if (userType === UserType.Supplier) {
        return <></>;
    }

    return (
        <>
            <AlertMessage Icon={Info} persistenceKey="supplier-portal::alert-not-supplier-user" color="blue">
                <Trans>
                    You are viewing the supplier portal as a regular user. Suppliers can only access their own quote
                    requests.
                </Trans>
            </AlertMessage>
        </>
    );
}

function MessageQuoteRequestFrozen({ quoteRequestId }: { quoteRequestId: string }) {
    const isFrozen = useIsQuoteRequestFrozen(quoteRequestId);

    if (!isFrozen) {
        return null;
    }

    const color = 'blue';

    return (
        <AlertMessage Icon={AcUnit} persistenceKey="supplier-portal::message-quote-request-frozen" color={color}>
            <Trans>This quote request is frozen. You can no longer make changes to it.</Trans>
        </AlertMessage>
    );
}

function AlertMessage({
    persistenceKey,
    color,
    children,
    Icon,
}: {
    Icon: React.ElementType;
    persistenceKey: string;
    color: keyof typeof colorSystem;
    children: React.ReactNode;
}) {
    const { isVisible, hide } = usePersistedVisibility({
        id: persistenceKey,
        maxDisplayCount: 5,
    });

    if (!isVisible) {
        return null;
    }

    return (
        <Box
            sx={{
                display: 'grid',
                placeItems: 'center',
                background: colorSystem[color][1],
                border: `1px solid ${colorSystem[color][4]}`,
                boxSizing: 'border-box',
                height: 'fit-content',
            }}
        >
            <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center', padding: '8px' }}>
                <Icon style={{ color: colorSystem[color][7] }} />
                <Text color={colorSystem[color][8]}>{children}</Text>
                <SecondaryButton size="small" onClick={hide}>
                    <Trans>Hide</Trans>
                </SecondaryButton>
            </Box>
        </Box>
    );
}
