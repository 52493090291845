// TODO: Write test for the schemas in manufacturingSchema https://www.notion.so/luminovo/Write-test-for-validation-schemas-in-manufacturingSchema-74a8923371d74f53b5051023612ee32c

import { t } from '@lingui/macro';
import { DriverIdDTO, DurationUnitOptions, UserDriverDetailsDTO } from '@luminovo/http-client';
import * as Yup from 'yup';
import {
    mustBeANumberErrorMessage,
    mustBePositiveErrorMessage,
    requiredErrorMessage,
    zeroOrPositiveNumberRequired,
} from '../../utils/yupValidationUtils';

const durationYupObjectAllRequired = (): Yup.ObjectSchema =>
    Yup.object().shape({
        amount: zeroOrPositiveNumberRequired().required().typeError(requiredErrorMessage),
        unit: Yup.mixed().oneOf(DurationUnitOptions).required(requiredErrorMessage()),
    });

export const timeYupObjectDurationRequired = (): Yup.ObjectSchema =>
    Yup.object().shape({
        duration: durationYupObjectAllRequired(),
    });

const UserDriverIdSchema = Yup.object().shape({
    type: Yup.string().equals(['User']).required(),
    value: Yup.string().required(),
});

const isDriverPerPanel = (driverId: DriverIdDTO, drivers: UserDriverDetailsDTO[]): boolean | undefined => {
    const driver = drivers.find((driver) => driver.id === driverId.value);
    if (driver) {
        return driver.is_per_panel;
    } else {
        return undefined; // return undefined if driver not found
    }
};

const isDriverActive = (driverId: DriverIdDTO, drivers: UserDriverDetailsDTO[]): boolean | undefined => {
    const driver = drivers.find((driver) => driver.id === driverId.value);
    if (driver) {
        return driver.status === 'Active';
    } else {
        return undefined; // return undefined if driver not found
    }
};

export const driverYupObject = (drivers: UserDriverDetailsDTO[], shouldCheckForPerPanel: boolean = true) => {
    return UserDriverIdSchema.test('driverIsActive', t`Driver is inactive`, (driverId) => {
        return driverId === null || driverId === undefined || (isDriverActive(driverId, drivers) ?? false);
    }).test('driverIsPerPanel', '', (driverId) => {
        // no error message because message is shown in the ErrorBox.
        if (driverId) {
            if (!isDriverPerPanel(driverId, drivers) || shouldCheckForPerPanel === false) {
                return true;
            } else {
                return false;
            }
        }
        return true;
    });
};

export const driverNoPanelCheckYupObject = (drivers: UserDriverDetailsDTO[]) => {
    return UserDriverIdSchema.test('driverIsActive', t`Driver is inactive`, (driverId) => {
        return driverId === null || driverId === undefined || (isDriverActive(driverId, drivers) ?? false);
    });
};

export const createManufacturingScenarioTemplateSelectionSchema = (): Yup.ObjectSchema => {
    return Yup.object().shape({
        templateId: Yup.string().optional(),
    });
};

export const createEditConfigurationSchema = () => {
    return Yup.object().shape({
        count: Yup.number().min(0, mustBePositiveErrorMessage()).typeError(mustBeANumberErrorMessage()).required(),
    });
};

export const createAssemblyDetailsNotesSchema = () => {
    return Yup.object().shape({
        notes: Yup.string().nullable(),
    });
};
