import * as r from 'runtypes';
import { CalculationResultNullValue, DriverStatusWithDriverIdRuntype } from '../activityConfiguration';
import { CurrencyRuntype, MonetaryValueBackendRuntype } from '../backendTypes';
import { AvailabilityRuntype } from '../offer';
export * from './calculationAssemblyDetailsBackendTypes';
export * from './calculationScenarioCombinationBackendTypes';

export const FixedCostRuntype = r.Record({
    type: r.Literal('Fixed'),
    data: MonetaryValueBackendRuntype,
});

export type FixedCostDTO = r.Static<typeof FixedCostRuntype>;

export const FractionCostRuntype = r.Record({
    type: r.Literal('Fraction'),
    data: r.String,
});

export type FractionCostDTO = r.Static<typeof FractionCostRuntype>;

export const FixedFormulaRuntype = r.Record({
    type: r.Literal('Fixed'),
    data: r.Record({
        script: r.String,
        currency: CurrencyRuntype,
    }),
});
export type FixedFormulaDTO = r.Static<typeof FixedFormulaRuntype>;

export const FractionFormulaRuntype = r.Record({
    type: r.Literal('Fraction'),
    data: r.Record({ script: r.String }),
});
export type FractionFormulaDTO = r.Static<typeof FractionFormulaRuntype>;

export const NotOverwrittenFormulaRuntype = r.Record({
    type: r.Literal('Evaluated'),
    data: r.Record({
        calculated: r.Union(FixedFormulaRuntype, FractionFormulaRuntype),
    }),
});

export const OverwrittenFormulaRuntype = r.Record({
    type: r.Literal('Overridden'),
    data: r.Record({
        manually_overridden: r.String,
        calculated: r.Union(FixedFormulaRuntype, FractionFormulaRuntype),
    }),
});

export const OverridableFormulaRuntype = r.Union(NotOverwrittenFormulaRuntype, OverwrittenFormulaRuntype);

const UnknownIdentifierRuntype = r.Record({
    result: r.Literal('UnknownIdentifier'),
    data: r.String,
});

const ScriptErrorRuntype = r.Record({
    result: r.Literal('ScriptError'),
});
const ErrorResponseRuntype = r.Union(UnknownIdentifierRuntype, ScriptErrorRuntype, CalculationResultNullValue);

const OkResponseRuntype = r.Record({
    result: r.Literal('Ok'),
    data: r.String,
});

const FormulaResponseRuntype = ErrorResponseRuntype.Or(OkResponseRuntype);

const FormulaRuntype = r.Record({
    formula: OverridableFormulaRuntype,
    statuses: r.Array(DriverStatusWithDriverIdRuntype),
    result: FormulaResponseRuntype,
});

export type FormulaDTO = r.Static<typeof FormulaRuntype>;

const FormulaCostRuntype = r.Record({
    type: r.Literal('Formula'),
    data: FormulaRuntype,
});

export type FormulaCostDTO = r.Static<typeof FormulaCostRuntype>;

export type UnitCostDTO = FixedCostDTO | FractionCostDTO | FormulaCostDTO;

const CostCellRuntype = r.Record({
    cost_specification: r.Union(FixedCostRuntype, FractionCostRuntype, FormulaCostRuntype),
    unit_cost_value: MonetaryValueBackendRuntype,
    unit_cost_fraction: r.String,
    total_cost_value: MonetaryValueBackendRuntype,
    is_locked: r.Boolean,
});
export type CostCellDTO = r.Static<typeof CostCellRuntype>;

const ExtraCostRuntype = r.Record({
    name: r.String,
    cost: CostCellRuntype,
});
export type ExtraCostDTO = r.Static<typeof ExtraCostRuntype>;

export const CustomCostRuntype = r.Record({
    extra_costs: r.Array(ExtraCostRuntype),
    profit: CostCellRuntype.nullable(),
    discount: CostCellRuntype.nullable(),
});

export type CustomCostDTO = r.Static<typeof CustomCostRuntype>;

// GET Runtypes

export const FixedCostCellRuntype = r.Record({
    unit_cost: MonetaryValueBackendRuntype,
    total_cost: MonetaryValueBackendRuntype,
});

export type FixedCostCellDTO = r.Static<typeof FixedCostCellRuntype>;
const ManufacturingCostRuntype = r.Record({
    cost: FixedCostCellRuntype,
    base_cost: FixedCostCellRuntype,
    custom_cost: CustomCostRuntype,
    price: FixedCostCellRuntype,
    sub_total_cost: FixedCostCellRuntype,
});

export type ManufacturingCostDTO = r.Static<typeof ManufacturingCostRuntype>;

const MaterialCostRuntype = r.Record({
    cost: FixedCostCellRuntype,
    base_cost: FixedCostCellRuntype,
    excess_material_cost: FixedCostCellRuntype,
    custom_cost: CustomCostRuntype,
    price: FixedCostCellRuntype,
    sub_total_cost: FixedCostCellRuntype,
});

export type MaterialCostDTO = r.Static<typeof MaterialCostRuntype>;

export const AdditionalCostRuntype = r.Record({
    profit: CostCellRuntype.nullable(),
    discount: CostCellRuntype.nullable(),
    other_costs: r.Array(ExtraCostRuntype),
    post_profit_costs: r.Array(ExtraCostRuntype),
    sub_total_cost: FixedCostCellRuntype,
});

export type AdditionalCostDTO = r.Static<typeof AdditionalCostRuntype>;

export const ProjectEntityCostCellRuntype = r.Record({
    id: r.String,
    origin_id: r.String.nullable(),
    name: r.String,
    rank: r.Number,
    cost: FixedCostCellRuntype,
});

export type ProjectEntityCostCellDTO = r.Static<typeof ProjectEntityCostCellRuntype>;

export const ProjectCostRuntype = r.Record({
    cost: FixedCostCellRuntype,
    profit: CostCellRuntype.nullable(),
    price: FixedCostCellRuntype,
    activity_costs: r.Array(ProjectEntityCostCellRuntype),
    expense_costs: r.Array(ProjectEntityCostCellRuntype),
    one_time_costs: FixedCostCellRuntype.nullable(),
});

export type ProjectCostDTO = r.Static<typeof ProjectCostRuntype>;

export const BatchCostRuntype = r.Record({
    material_cost: MaterialCostRuntype,
    manufacturing_cost: ManufacturingCostRuntype,
    additional_cost: AdditionalCostRuntype,
    project_cost: ProjectCostRuntype,
    summarized_cost: r.Record({
        total_price: FixedCostCellRuntype,
        total_cost: FixedCostCellRuntype,
        total_profit: FixedCostCellRuntype,
    }),
    checkout_cost: r.Record({
        total_price: FixedCostCellRuntype,
        total_cost: FixedCostCellRuntype,
        total_profit: FixedCostCellRuntype,
    }),
});
export type BatchCostDTO = r.Static<typeof BatchCostRuntype>;

const BatchRuntype = r.Record({
    batch_size: r.Number,
    cost: BatchCostRuntype,
});

export type BatchDTO = r.Static<typeof BatchRuntype>;

export const ScenarioCostRuntype = r.Record({
    sourcing_combination_id: r.String,
    order_size: r.Number,
    sourcing_total_availability: AvailabilityRuntype.nullable(),
    manufacturing_lead_time: r.Number.nullable(),
    batch_size_costs: r.Array(BatchRuntype),
});
export type ScenarioCostDTO = r.Static<typeof ScenarioCostRuntype>;

export const SourcingCombinationCostsRuntype = r.Record({
    id: r.String,
    calculation_assembly_details: r.String,
    costs: r.Array(ScenarioCostRuntype),
    created_at: r.String,
    updated_at: r.String.nullable(),
});

export type SourcingCombinationCostsDTO = r.Static<typeof SourcingCombinationCostsRuntype>;

// POST. POST is different from the GET request, because it can only contain some of the costs and not the total_costs

const FixedCostCellPostRuntype = r.Record({
    unit_cost: MonetaryValueBackendRuntype,
});

export type FixedCostCellPostDTO = r.Static<typeof FixedCostCellPostRuntype>;

export const FormulaCostPostRuntype = r.Record({
    type: r.Literal('Formula'),
    data: OverridableFormulaRuntype,
});

export const CostCellPostRuntype = r.Record({
    unit_cost: r.Union(FixedCostRuntype, FractionCostRuntype, FormulaCostPostRuntype),
    is_locked: r.Boolean,
});

type FormulaCostPostDTO = r.Static<typeof FormulaCostPostRuntype>;

export type UnitCostPostDTO = FixedCostDTO | FractionCostDTO | FormulaCostPostDTO;

export type CostCellPostDTO = r.Static<typeof CostCellPostRuntype>;

const ExtraCostPostRuntype = r.Record({
    name: r.String,
    cost: CostCellPostRuntype,
});
export const CustomCostPostRuntype = r.Record({
    extra_costs: r.Array(ExtraCostPostRuntype),
    profit: CostCellPostRuntype.nullable(),
    discount: CostCellPostRuntype.nullable(),
});

const ProjectCostPostRuntype = r.Record({
    cost: FixedCostCellPostRuntype,
    profit: CostCellPostRuntype.nullable(),
    price: FixedCostCellPostRuntype,
});

export const AdditionalCostPostRuntype = r.Record({
    profit: CostCellPostRuntype.nullable(),
    discount: CostCellPostRuntype.nullable(),
    other_costs: r.Array(ExtraCostPostRuntype),
    post_profit_costs: r.Array(ExtraCostPostRuntype),
});

const CostPostRuntype = r.Record({
    custom_cost: CustomCostRuntype,
});
export type CostPostDTO = r.Static<typeof CostPostRuntype>;

const MaterialCostPostRuntype = r.Record({
    custom_cost: CustomCostPostRuntype,
});

const ManufacturingCostPostRuntype = r.Record({
    custom_cost: CustomCostPostRuntype,
});

export const BatchCostPostRuntype = r.Record({
    material_cost: MaterialCostPostRuntype,
    manufacturing_cost: ManufacturingCostPostRuntype,
    additional_cost: AdditionalCostPostRuntype,
    project_cost: ProjectCostPostRuntype,
});

export type SourcingCombinationBatchSizePostDTO = r.Static<typeof BatchCostPostRuntype>;

const BatchPostRuntype = r.Record({
    batch_size: r.Number,
    cost: BatchCostPostRuntype,
});

export type BatchPostDTO = r.Static<typeof BatchPostRuntype>;

export const ScenarioCostPostRuntype = r.Record({
    sourcing_combination_id: r.String,
    batch_size_costs: r.Array(BatchPostRuntype),
});

export type ScenarioCostPostDTO = r.Static<typeof ScenarioCostPostRuntype>;

export const SourcingCombinationCostsPostRuntype = r.Record({
    costs: r.Array(ScenarioCostPostRuntype),
});

export type SourcingCombinationCostsPostDTO = r.Static<typeof SourcingCombinationCostsPostRuntype>;

const OrderCalculationRuntype = r.Record({
    quantity: r.Number,
    sourcing_lead_time_availability: AvailabilityRuntype.nullable(),
    manufacturing_lead_time: r.Number.nullable(),
    total_price: MonetaryValueBackendRuntype,
    total_project_cost: MonetaryValueBackendRuntype,
    unit_price: MonetaryValueBackendRuntype,
});

const RfqExportCalculationPdfExportAssemblyRuntype = r.Record({
    name: r.String,
    order_calculations: r.Array(OrderCalculationRuntype),
});

export type RfqExportCalculationPdfExportAssemblyDTO = r.Static<typeof RfqExportCalculationPdfExportAssemblyRuntype>;

export const RfqCalculationPDFExportResponseRuntype = r.Record({
    rfq_name: r.String,
    rfq_ems_internal_number: r.String.nullable(),
    assemblies: r.Array(RfqExportCalculationPdfExportAssemblyRuntype),
});

export type RfqCalculationPDFExportResponseDTO = r.Static<typeof RfqCalculationPDFExportResponseRuntype>;
