import { t } from '@lingui/macro';
import { colorSystem, Flexbox, h5, highlight, Tag, Text } from '@luminovo/design-system';
import { OtsFullPart, OTSPartOriginEnum, RfqContext } from '@luminovo/http-client';
import { PackageChip, PreferredManufacturerTagWithText } from '@luminovo/sourcing-core';
import { Typography } from '@mui/material';
import React from 'react';
import { PdfFileImage } from '../../../modules/DesignItemDetails/components/PdfFileImage';
import { isComplianceError } from '../../PartComplianceView';
import { isLifecycleError } from '../../PartLifecycleView';
import { PartCardLayout } from '../PartCardLayout';
import { DatasheetButton, DetailsButton } from '../PartSpecificationCardFields';
import { useOtsPartDetailsDrawer } from './useOtsPartDetailsDrawer';

interface OTSPartCardProps {
    part: OtsFullPart;
    cardStyle?: React.CSSProperties;
    mpnSearchQuery?: (string | RegExp)[];
    /**
     *  If true, hovering on the card will not show the view details and datasheet buttons.
     */
    disableOnHover?: boolean;
    rfqContext: RfqContext;
}

const OTSPartCardNotMemoized = ({
    part,
    cardStyle,
    mpnSearchQuery = [],
    disableOnHover = false,
    rfqContext,
}: OTSPartCardProps): JSX.Element => {
    const { openDrawer } = useOtsPartDetailsDrawer();

    const isManual: boolean = part.origin.type === OTSPartOriginEnum.Manual;
    const isImported: boolean = part.origin.type === OTSPartOriginEnum.Import;
    const isPackage = part.package?.mounting || part.package?.name;

    return (
        <>
            <PartCardLayout
                headerSection={
                    <>
                        <Flexbox alignItems="center" gap={4} style={{ maxWidth: '340px' }}>
                            <Text color={colorSystem.neutral[5]} variant="h5">
                                MPN{' '}
                            </Text>

                            <HighlightedMpn mpns={[part.mpn, ...part.mpn_aliases]} matcher={mpnSearchQuery} />
                            <Text
                                variant="h5"
                                color={colorSystem.neutral[8]}
                                style={{
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                }}
                            >
                                {part.manufacturer.name}
                            </Text>
                        </Flexbox>
                        <Flexbox alignItems="center" gap={4}>
                            {part.is_manufacturer_preferred && <PreferredManufacturerTagWithText />}
                            {part.datasheet_url && <PdfFileImage color={colorSystem.neutral[4]} />}
                        </Flexbox>
                    </>
                }
                headerHover={<DetailsButton handleClick={() => openDrawer({ part, rfqContext })} />}
                footerSection={
                    <>
                        {isLifecycleError(part.lifecycle_status) && (
                            <Tag color="red" attention="low" label={t`Lifecycle`} />
                        )}
                        {(isComplianceError(part.reach_compliant) || isComplianceError(part.rohs_compliant)) && (
                            <Tag color="red" attention="low" label={t`Compliance`} />
                        )}
                        {isManual && <Tag color="neutral" attention="low" label={t`Manual`} />}
                        {isImported && <Tag color="neutral" attention="low" label={t`Imported`} />}

                        <Typography variant="subtitle1" color="textSecondary" noWrap>
                            {part.description}
                        </Typography>
                        {part.package && isPackage && <PackageChip formfit={part.package} />}
                    </>
                }
                footerHover={part.datasheet_url ? <DatasheetButton datasheetUrl={part.datasheet_url} /> : undefined}
                cardStyle={cardStyle}
                disableOnHover={disableOnHover}
            />
        </>
    );
};
// Memoization added because the component was re-rendering when the solution props changed and not the value https://medium.com/welldone-software/why-did-you-render-mr-big-pure-react-component-part-2-common-fixing-scenarios-667bfdec2e0f
export const OTSPartCard = React.memo(OTSPartCardNotMemoized);

function countHighlightedChars(segments: Array<{ isHighlighted: boolean; content: string }>): number {
    return segments.reduce((acc, segment) => acc + (segment.isHighlighted ? segment.content.length : 0), 0);
}

function findBestHighlight(
    mpns: string[],
    matcher: Array<RegExp | string>,
): Array<{ isHighlighted: boolean; content: string }> {
    const sortedMatcher = matcher.sort((a, b) => {
        const lengthA = typeof a === 'string' ? a.length : 0;
        const lengthB = typeof b === 'string' ? b.length : 0;
        return lengthB - lengthA;
    });

    let bestMatch: Array<{ isHighlighted: boolean; content: string }> = [];
    let bestMatchCount = -1;

    for (const mpn of mpns) {
        const result = highlight(mpn, ...sortedMatcher);
        const isPerfectMatch = result.length === 1 && result.every((x) => x.isHighlighted);
        if (isPerfectMatch) {
            return result;
        }

        const currentMatchCount = countHighlightedChars(result);
        if (currentMatchCount > bestMatchCount) {
            bestMatch = result;
            bestMatchCount = currentMatchCount;
        }
    }
    return bestMatch;
}

export function HighlightedMpn({
    mpns,
    matcher,
    nonHighlightedStyles,
}: {
    mpns: string[];
    matcher?: Array<RegExp | string>;
    nonHighlightedStyles?: React.CSSProperties;
}): JSX.Element | null {
    if (mpns.length === 0) {
        return null;
    }
    if (!matcher) {
        return (
            <Text variant="body-small" style={{ whiteSpace: 'nowrap' }}>
                {mpns[0]}
            </Text>
        );
    }

    const result = findBestHighlight(mpns, matcher);

    if (result.length <= 1 && result.every((x) => x.isHighlighted)) {
        return (
            <Text
                variant="h5"
                style={{
                    color: colorSystem.green[7],
                    background: colorSystem.green[2],
                    lineHeight: '20px',
                    whiteSpace: 'nowrap',
                }}
            >
                {result[0]?.content ?? ''}
            </Text>
        );
    }
    return (
        <Text variant="body-small" style={{ whiteSpace: 'nowrap' }}>
            {result.map(({ content, isHighlighted }, i) => {
                return (
                    <span
                        key={i}
                        style={
                            isHighlighted
                                ? {
                                      color: colorSystem.primary[7],
                                      background: colorSystem.primary[2],
                                      whiteSpace: 'nowrap',
                                      ...h5,
                                  }
                                : nonHighlightedStyles
                        }
                    >
                        {content}
                    </span>
                );
            })}
        </Text>
    );
}
