/* eslint-disable spellcheck/spell-checker */
import { detectLanguageEnum } from '@luminovo/commons';
import { createTheme } from '@luminovo/design-system';

export const SPACING_EXTRA_SMALL = '0.25rem';
export const SPACING_SMALL = '0.5rem';
export const SPACING_MEDIUM = '1rem';

export const themeLuminovo = createTheme(detectLanguageEnum());

export const CIRCULAR_PROGRESS_BUTTON_SIZE = 24;

export const PART_SPECIFICATION_CARD_WIDTH = '30rem';
export const PART_CARD_ROW_HEIGHT = '32px';
export const WIDTH_DASHBOARD_MENU = '11.3rem';
