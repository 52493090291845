import { t } from '@lingui/macro';
import { Currency, assertUnreachable, isEqual } from '@luminovo/commons';
import { CenteredLayout, Dropzone } from '@luminovo/design-system';
import { Packaging, QuantityUnit } from '@luminovo/http-client';
import { useMutation } from '@tanstack/react-query';
import { ParsedObjectsResult, Row, Schema } from 'read-excel-file';
import { useDebugErrorHandler } from '../../../../resources/http/debugErrorHandler';
import {
    CustomPartQuoteHeader,
    CustomPartQuoteLines,
    QuoteImportResult,
    StandardPartQuoteHeader,
    StandardPartQuoteLines,
} from './types';

const HEADER_HEIGHT = 2;

const standardPartSchema: Schema = {
    [StandardPartQuoteHeader.LumiQuoteId]: {
        prop: 'lumiQuoteId',
        type: String,
        required: false,
    },
    [StandardPartQuoteHeader.ManufacturerPartNumber]: {
        prop: 'mpn',
        type: String,
        required: false,
    },
    [StandardPartQuoteHeader.Manufacturer]: {
        prop: 'manufacturer',
        type: String,
        required: false,
    },
    [StandardPartQuoteHeader.Ipn]: {
        prop: 'ipn',
        type: String,
        required: false,
    },
    [StandardPartQuoteHeader.RequiredQuantity]: {
        prop: 'requiredQuantity',
        required: false,
        type: convertPositiveNumber,
    },
    [StandardPartQuoteHeader.PartCategory]: {
        prop: 'partCategory',
        type: String,
        required: false,
    },
    [StandardPartQuoteHeader.PartDescription]: {
        prop: 'partDescription',
        type: String,
        required: false,
    },
    [StandardPartQuoteHeader.Unit]: {
        prop: 'unit',
        required: false,
        type: (value) => {
            switch (value) {
                case 'Kg':
                    return QuantityUnit.Kg;
                case 'Meters':
                    return QuantityUnit.Meters;
                case 'Liters':
                    return QuantityUnit.Liters;
                case 'Pieces':
                    return QuantityUnit.Pieces;
                default:
                    throw new Error('Unit not supported');
            }
        },
    },
    [StandardPartQuoteHeader.UnitPrice]: {
        prop: 'unitPrice',
        required: false,
        type: convertPositiveNumber,
    },
    [StandardPartQuoteHeader.UnitPriceQuantity]: {
        prop: 'unitPriceQuantity',
        required: false,
        type: (value) => {
            if (typeof value === 'number') {
                return convertPositiveNumber(value);
            }

            if (typeof value === 'string') {
                const match = value.match(/\d+/);
                if (match) {
                    return convertPositiveNumber(match[0]);
                }
            }

            throw new Error('Unit price quantity not supported');
        },
    },
    [StandardPartQuoteHeader.Currency]: {
        prop: 'currency',
        required: false,
        type: (value) => {
            switch (value) {
                case 'EUR':
                    return Currency.EUR;
                case 'USD':
                    return Currency.USD;
                case 'GBP':
                    return Currency.GBP;
                case 'AUD':
                    return Currency.AUD;
                case 'BGN':
                    return Currency.BGN;
                case 'BRL':
                    return Currency.BRL;
                case 'CAD':
                    return Currency.CAD;
                case 'CHF':
                    return Currency.CHF;
                case 'CNY':
                    return Currency.CNY;
                case 'CZK':
                    return Currency.CZK;
                case 'DKK':
                    return Currency.DKK;
                case 'HKD':
                    return Currency.HKD;
                case 'HRK':
                    return Currency.HRK;
                case 'HUF':
                    return Currency.HUF;
                case 'IDR':
                    return Currency.IDR;
                case 'ILS':
                    return Currency.ILS;
                case 'INR':
                    return Currency.INR;
                case 'ISK':
                    return Currency.ISK;
                case 'JPY':
                    return Currency.JPY;
                case 'KRW':
                    return Currency.KRW;
                case 'MXN':
                    return Currency.MXN;
                case 'MYR':
                    return Currency.MYR;
                case 'NOK':
                    return Currency.NOK;
                case 'NZD':
                    return Currency.NZD;
                case 'PHP':
                    return Currency.PHP;
                case 'PLN':
                    return Currency.PLN;
                case 'RON':
                    return Currency.RON;
                case 'RUB':
                    return Currency.RUB;
                case 'SEK':
                    return Currency.SEK;
                case 'SGD':
                    return Currency.SGD;
                case 'THB':
                    return Currency.THB;
                case 'TRY':
                    return Currency.TRY;
                case 'ZAR':
                    return Currency.ZAR;
                case 'TWD':
                    return Currency.TWD;
                case 'VND':
                    return Currency.VND;
                default:
                    throw new Error('Currency not supported');
            }
        },
    },
    [StandardPartQuoteHeader.SupplierPartNumber]: {
        prop: 'supplierPartNumber',
        type: String,
        required: false,
    },
    [StandardPartQuoteHeader.Packaging]: {
        prop: 'packaging',
        required: false,
        type: (value) => {
            switch (value) {
                case 'Ammo Pack':
                case 'AMMO PACK':
                case 'AMMO':
                    return Packaging.AmmoPack;
                case 'Bag':
                case 'BAG':
                    return Packaging.Bag;
                case 'Bulk':
                case 'BULK':
                    return Packaging.Bulk;
                case 'Reel':
                case 'reel':
                case 'REEL':
                case 'TAPE/REEL':
                case 'Tape&Reel':
                case 'TR':
                case 'T&R':
                    return Packaging.Reel;
                case 'Re-Reel':
                case 'Re-Reeling':
                case 'MOUSEREEL':
                    return Packaging.ReReel;
                case 'Tape':
                case 'TAPE':
                case 'CT':
                case 'CUT TAPE':
                    return Packaging.Tape;
                case 'Tray':
                case 'TRAY':
                case 'Trays':
                    return Packaging.Tray;
                case 'Tube':
                case 'TUBE':
                    return Packaging.Tube;
                case 'Carton':
                case 'CARTON':
                case 'BOX':
                    return Packaging.Carton;
                case 'Click to select packaging':
                    return null;
                default:
                    throw new Error('Packaging not supported');
            }
        },
    },
    [StandardPartQuoteHeader.MinOrderQuantity]: {
        prop: 'minOrderQuantity',
        required: false,
        type: convertPositiveNumber,
    },
    [StandardPartQuoteHeader.MinPackagingQuantity]: {
        prop: 'minPackagingQuantity',
        required: false,
        type: convertPositiveNumber,
    },
    [StandardPartQuoteHeader.LeadTime]: {
        prop: 'leadTime',
        required: false,
        type: convertPositiveNumber,
    },
    [StandardPartQuoteHeader.LeadTimeUnit]: {
        prop: 'leadTimeUnit',
        type: String,
        required: false,
        oneOf: ['days', 'weeks'],
    },
    [StandardPartQuoteHeader.Stock]: {
        prop: 'stock',
        required: false,
        type: convertNumber,
    },
    [StandardPartQuoteHeader.ValidUntil]: {
        prop: 'validUntil',
        type: String,
        required: false,
        parse: (cell) => {
            return convertToDateISOString(cell);
        },
    },
    [StandardPartQuoteHeader.Ncnr]: {
        prop: 'ncnr',
        required: false,
        type: (value) => {
            switch (value) {
                case 'Yes':
                case 'Y':
                case 'yes':
                case 'YES':
                case 'NCNR':
                case 'ncnr':
                    return true;
                case 'No':
                case 'N':
                case 'no':
                case 'NO':
                    return false;
                case 'Click to select NCNR':
                    return null;
                default:
                    throw new Error('Ncnr not supported');
            }
        },
    },
    [StandardPartQuoteHeader.AlternativeMPN]: {
        prop: 'alternativeMpn',
        type: String,
        required: false,
    },
    [StandardPartQuoteHeader.AlternativeManufacturer]: {
        prop: 'alternativeManufacturer',
        type: String,
        required: false,
    },
    [StandardPartQuoteHeader.AdditionalNotes]: {
        prop: 'additionalNotes',
        type: String,
        required: false,
    },
};

const customPartSchema: Schema = {
    [CustomPartQuoteHeader.LumiQuoteId]: {
        prop: 'lumiQuoteId',
        type: String,
        required: false,
    },
    [CustomPartQuoteHeader.SourcingScenario]: {
        prop: 'sourcingScenarioId',
        type: String,
        required: false,
    },
    [CustomPartQuoteHeader.Assembly]: {
        prop: 'mpn',
        type: String,
        required: false,
    },
    [CustomPartQuoteHeader.Description]: {
        prop: 'description',
        type: String,
        required: false,
    },
    [CustomPartQuoteHeader.RequiredQuantity]: {
        prop: 'requiredQuantity',
        required: false,
        type: convertPositiveNumber,
    },
    [CustomPartQuoteHeader.Attachments]: {
        prop: 'attachments',
        type: String,
        required: false,
    },
    [CustomPartQuoteHeader.UnitPrice]: {
        prop: 'unitPrice',
        required: false,
        type: convertPositiveNumber,
    },
    [CustomPartQuoteHeader.OneTimeCost]: {
        prop: 'oneTimeCosts',
        required: false,
        type: convertPositiveNumber,
    },
    [CustomPartQuoteHeader.Currency]: {
        prop: 'currency',
        required: false,
        type: (value) => {
            switch (value) {
                case 'EUR':
                    return Currency.EUR;
                case 'USD':
                    return Currency.USD;
                case 'GBP':
                    return Currency.GBP;
                case 'AUD':
                    return Currency.AUD;
                case 'BGN':
                    return Currency.BGN;
                case 'BRL':
                    return Currency.BRL;
                case 'CAD':
                    return Currency.CAD;
                case 'CHF':
                    return Currency.CHF;
                case 'CNY':
                    return Currency.CNY;
                case 'CZK':
                    return Currency.CZK;
                case 'DKK':
                    return Currency.DKK;
                case 'HKD':
                    return Currency.HKD;
                case 'HRK':
                    return Currency.HRK;
                case 'HUF':
                    return Currency.HUF;
                case 'IDR':
                    return Currency.IDR;
                case 'ILS':
                    return Currency.ILS;
                case 'INR':
                    return Currency.INR;
                case 'ISK':
                    return Currency.ISK;
                case 'JPY':
                    return Currency.JPY;
                case 'KRW':
                    return Currency.KRW;
                case 'MXN':
                    return Currency.MXN;
                case 'MYR':
                    return Currency.MYR;
                case 'NOK':
                    return Currency.NOK;
                case 'NZD':
                    return Currency.NZD;
                case 'PHP':
                    return Currency.PHP;
                case 'PLN':
                    return Currency.PLN;
                case 'RON':
                    return Currency.RON;
                case 'RUB':
                    return Currency.RUB;
                case 'SEK':
                    return Currency.SEK;
                case 'SGD':
                    return Currency.SGD;
                case 'THB':
                    return Currency.THB;
                case 'TRY':
                    return Currency.TRY;
                case 'ZAR':
                    return Currency.ZAR;
                case 'TWD':
                    return Currency.TWD;
                case 'VND':
                    return Currency.VND;
                default:
                    throw new Error('Currency not supported');
            }
        },
    },
    [CustomPartQuoteHeader.SupplierPartNumber]: {
        prop: 'supplierPartNumber',
        type: String,
        required: false,
    },
    [CustomPartQuoteHeader.LeadTimeInDays]: {
        prop: 'leadTimeInDays',
        required: false,
        type: convertPositiveNumber,
    },
    [CustomPartQuoteHeader.ValidUntil]: {
        prop: 'validUntil',
        type: String,
        required: false,
        parse: convertToDateISOString,
    },
    [CustomPartQuoteHeader.AdditionalNotes]: {
        prop: 'additionalNotes',
        type: String,
        required: false,
    },
};

function convertToDateISOString(cell: unknown) {
    if (cell instanceof Date) {
        return cell.toISOString();
    }

    if (typeof cell === 'string') {
        const newDate = new Date(cell);
        if (!isNaN(newDate.getTime())) {
            return newDate.toISOString();
        }
    }
    return undefined;
}

function convertToString(cell: unknown) {
    if (typeof cell === 'string') {
        return cell;
    }

    if (typeof cell === 'number') {
        return cell.toString();
    }

    if (cell instanceof Date) {
        return cell.toISOString();
    }

    return undefined;
}

function convertNumber(cell: unknown): number {
    if (typeof cell === 'string') {
        const parsed = Number(cell);
        if (!isNaN(parsed) && parsed >= 0) {
            return parsed;
        }
    } else if (typeof cell === 'number' && cell >= 0) {
        return cell;
    }

    throw new Error('Value is negative');
}

function convertPositiveNumber(cell: unknown): number {
    if (typeof cell === 'string') {
        const parsed = Number(cell);
        if (!isNaN(parsed) && parsed > 0) {
            return parsed;
        }
    } else if (typeof cell === 'number' && cell > 0) {
        return cell;
    }

    throw new Error('Value is not positive');
}

const detectSchema = (
    rows: Row[],
):
    | { schema: 'standardPartQuote' | 'customPartQuote'; headerStart: number }
    | { schema: 'unknown'; headerStart: null } => {
    for (const [index, row] of rows.entries()) {
        const cellValues = row.map((cell) => convertToString(cell));

        if (cellValues.includes(StandardPartQuoteHeader.ManufacturerPartNumber)) {
            return { schema: 'standardPartQuote', headerStart: index };
        }

        if (cellValues.includes(CustomPartQuoteHeader.Assembly)) {
            return { schema: 'customPartQuote', headerStart: index };
        }
    }

    return { schema: 'unknown', headerStart: null };
};

function findStringBefore(searchString: string, rows: Row[]) {
    const flatRows = rows.flat();
    const position = flatRows.findIndex((cell) => isEqual(cell, searchString));

    if (position === -1 || position === 0) {
        return undefined;
    }

    return convertToString(flatRows[position - 1]);
}

function findStringAfter(searchString: string, rows: Row[]) {
    const flatRows = rows.flat();
    const position = flatRows.findIndex((cell) => isEqual(cell, searchString));

    if (position === -1 || flatRows.length <= position + 1) {
        return undefined;
    }

    return convertToString(flatRows[position + 1]);
}

function useMutationUpload({ onSuccess }: { onSuccess: (data: QuoteImportResult) => void }) {
    const onError = useDebugErrorHandler();

    return useMutation({
        mutationFn: async (files: File[]) => {
            const { default: readXlsxFile } = await import('read-excel-file');
            const { default: convertToJson } = await import('read-excel-file/schema');

            const rows = await readXlsxFile(files[0]);
            const { schema, headerStart } = detectSchema(rows);

            if (schema === 'unknown') {
                throw new Error('Could not detect schema');
            }

            const sharedOfferInfo = {
                file: files[0],
                quoteTrackingId: convertToString(rows[0][0]),
                supplierAndStockLocationId: findStringBefore('Supplier:', rows.slice(0, headerStart)),
                supplierName: findStringAfter('Supplier:', rows.slice(0, headerStart)),
                offerNumber: findStringAfter('Offer number:', rows.slice(0, headerStart)),
                offerValidity: findStringAfter('Offer validity (DD.MM.YYYY):', rows.slice(0, headerStart)),
            };

            if (schema === 'standardPartQuote') {
                const quoteLines = convertToJson(
                    rows.slice(headerStart),
                    standardPartSchema,
                ) as unknown as ParsedObjectsResult<StandardPartQuoteLines>;
                const quoteLineRow = quoteLines.rows.map((row, index) => ({
                    type: 'StandardPartLine',
                    excelRowNumber: index + headerStart + HEADER_HEIGHT,
                    row,
                    warnings: quoteLines.errors.filter((error) => error.row === index + HEADER_HEIGHT),
                }));

                return { ...sharedOfferInfo, quoteLineRow } as QuoteImportResult;
            }

            if (schema === 'customPartQuote') {
                const quoteLines = convertToJson(
                    rows.slice(headerStart),
                    customPartSchema,
                ) as unknown as ParsedObjectsResult<CustomPartQuoteLines>;
                const quoteLineRow = quoteLines.rows.map((row, index) => ({
                    type: 'CustomPartLine',
                    excelRowNumber: index + headerStart + HEADER_HEIGHT,
                    row,
                    warnings: quoteLines.errors.filter((error) => error.row === index + HEADER_HEIGHT),
                }));

                return { ...sharedOfferInfo, quoteLineRow } as QuoteImportResult;
            }

            assertUnreachable(schema);
        },
        onError,
        onSuccess,
    });
}

export function ExcelQuoteImportDropZone({
    onSuccess,
    isLoading: isLoadingOuter,
}: {
    onSuccess: (data: QuoteImportResult) => void;
    isLoading: boolean;
}) {
    const { mutateAsync, isPending: isLoading } = useMutationUpload({
        onSuccess: (value) => onSuccess(value),
    });

    return (
        <CenteredLayout minHeight={'80vh'}>
            <Dropzone
                title={t`Excel quote importer`}
                onDropAccepted={(files) => mutateAsync(files)}
                multiple={false}
                isLoading={isLoading || isLoadingOuter}
                accept={{
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
                }}
            />
        </CenteredLayout>
    );
}
