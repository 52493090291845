import { MessageDescriptor } from '@lingui/core';
import { defineMessage } from '@lingui/macro';
import { formatDecimal, isPresent, transEnum } from '@luminovo/commons';
import { QuantityUnit, QuantityUnitDTO } from '@luminovo/http-client';

export function formatQuantity(
    quantityDto: QuantityUnitDTO | null | undefined,
    options: { showPiecesUnit: boolean; maximumFractionDigits?: number } = {
        showPiecesUnit: false,
        maximumFractionDigits: 3,
    },
): string {
    if (!isPresent(quantityDto) || !isPresent(quantityDto.quantity) || !isPresent(quantityDto.unit)) {
        return '-';
    }
    const { unit, quantity } = quantityDto;

    if (unit === QuantityUnit.Pieces && !options.showPiecesUnit) {
        return formatDecimal(quantity, { maximumFractionDigits: options.maximumFractionDigits });
    }
    if (quantity === 1) {
        return `${formatDecimal(quantity, { maximumFractionDigits: options.maximumFractionDigits })} ${transEnum(
            unit,
            quantityUnitTranslations,
        )}`;
    }
    return `${formatDecimal(quantity, { maximumFractionDigits: options.maximumFractionDigits })} ${transEnum(
        unit,
        quantityUnitPluralTranslations,
    )}`;
}

export const quantityUnitTranslations: Record<QuantityUnit, MessageDescriptor> = {
    [QuantityUnit.Pieces]: defineMessage({
        message: 'Piece',
    }),
    [QuantityUnit.Kg]: defineMessage({
        message: 'Kg',
    }),
    [QuantityUnit.Liters]: defineMessage({
        message: 'Liter',
    }),
    [QuantityUnit.Meters]: defineMessage({
        message: 'Meter',
    }),
    [QuantityUnit.Boards]: defineMessage({
        message: 'Board',
    }),
};

const quantityUnitPluralTranslations: Record<QuantityUnit, MessageDescriptor> = {
    [QuantityUnit.Pieces]: defineMessage({
        message: 'Pieces',
    }),
    [QuantityUnit.Kg]: defineMessage({
        message: 'Kg',
    }),
    [QuantityUnit.Liters]: defineMessage({
        message: 'Liters',
    }),
    [QuantityUnit.Meters]: defineMessage({
        message: 'Meters',
    }),
    [QuantityUnit.Boards]: defineMessage({
        message: 'Boards',
    }),
};
