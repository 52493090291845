import { t } from '@lingui/macro';
import { colorSystem } from '@luminovo/design-system';
import { Box, styled, Typography } from '@mui/material';
import { useCurrentUserDetailsContext } from '../../contexts/CurrentUserDetailsContext';

export const LightTypography = styled(Typography)({
    color: colorSystem.neutral[6],
});

export const StyledBorder = ({ color }: { color: string }) => {
    return (
        <Box
            style={{
                width: '2px',
                borderRadius: '2px',
                background: color,
                margin: '0px 8px 0px 24px',
            }}
        />
    );
};

export function useIpnTooltip(): string {
    const { organization } = useCurrentUserDetailsContext();
    const emsName = organization.name;
    return t`The IPN is the internal part number of ${emsName}.`;
}
