import { useHttpQuery } from '../../../../../../resources/http/useHttpQuery';
import { convertResponseBodyToExtractedValues } from './converters';

export const useExtractedPcbSpecification = ({ pcbId }: { pcbId: string }) => {
    return useHttpQuery(
        'GET /pcb-pdf-specification-extraction',
        // eslint-disable-next-line camelcase
        { queryParams: { pcb_id: pcbId } },
        {
            select(data) {
                return data ? convertResponseBodyToExtractedValues(data) : undefined;
            },
        },
    );
};
