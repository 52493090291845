import { MonetaryValue } from '@luminovo/commons';
import { NegotiationLineItem } from '@luminovo/http-client';
import { getScenarioVolume } from './getScenarioVolume';
import { monetaryValue } from './monetaryValueMath';

export function getScenarioPricingDiff(
    negotiationLineItems: Array<NegotiationLineItem>,
    referenceScenarioId?: number,
    selectedScenarioId?: number,
): {
    savings: MonetaryValue;
    increase: MonetaryValue;
    result: MonetaryValue;
    resultRatio: number;
} {
    const referenceVolumes: MonetaryValue[] = [];
    let savings: MonetaryValue[] = [];
    let increases: MonetaryValue[] = [];

    for (const lineItem of negotiationLineItems) {
        const referenceVolume = getScenarioVolume(lineItem, referenceScenarioId);
        const selectedVolume = getScenarioVolume(lineItem, selectedScenarioId);

        if (!selectedVolume || !referenceVolume) {
            continue;
        }
        referenceVolumes.push(referenceVolume);

        const diff = monetaryValue.subtract(selectedVolume, referenceVolume);
        const diffScalar = monetaryValue.getScalar(diff);
        if (diffScalar > 0) {
            increases.push(diff);
        } else {
            savings.push(diff);
        }
    }

    const sumSavings = monetaryValue.sum(savings);
    const sumIncrease = monetaryValue.sum(increases);
    const result = monetaryValue.add(sumSavings, sumIncrease);
    const referenceVolume = monetaryValue.sum(referenceVolumes);
    const resultRatio = monetaryValue.getScalar(result) / monetaryValue.getScalar(referenceVolume);

    return {
        savings: sumSavings,
        increase: sumIncrease,
        result: monetaryValue.add(sumSavings, sumIncrease),
        resultRatio,
    };
}
