import { t } from '@lingui/macro';
import { format } from 'date-fns';

export interface DateFormatGlobalFieldValue {
    id: string;
    label: string;
    formats: string[];
    example: string;
}

const createDayMonthYearFormats = (separators: string[]): string[] => {
    return separators.flatMap((separator) => [`dd${separator}MM${separator}yy`, `dd${separator}MM${separator}yyyy`]);
};
const createMonthDayYearFormats = (separators: string[]): string[] => {
    return separators.flatMap((separator) => [`MM${separator}dd${separator}yy`, `MM${separator}dd${separator}yyyy`]);
};

const createYearMonthDayFormats = (separators: string[]): string[] => {
    return separators.flatMap((separator) => [`yyyy${separator}MM${separator}dd`]);
};

export const supportedSeparators = ['/', '.', '-', '', '_', '`', ' '];

export const dayMonthYearFormats = createDayMonthYearFormats(supportedSeparators);

export const monthDayYearFormats = createMonthDayYearFormats(supportedSeparators);

export const yearMonthDayFormats = createYearMonthDayFormats(supportedSeparators);

export const createCombinedDateFormats = (): DateFormatGlobalFieldValue[] => {
    return [
        {
            id: 'day-month-year',
            label: t`Day, Month, Year`,
            formats: dayMonthYearFormats,
            example: format(new Date('2026-05-31'), dayMonthYearFormats[0]),
        },
        {
            id: 'month-day-year',
            label: t`Month, Day, Year`,
            formats: monthDayYearFormats,
            example: format(new Date('2026-05-31'), monthDayYearFormats[0]),
        },
        {
            id: 'year-month-day',
            label: t`Year, Month, Day`,
            formats: yearMonthDayFormats,
            example: format(new Date('2026-05-31'), yearMonthDayFormats[0]),
        },
    ];
};
