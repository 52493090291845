import { Flexbox } from '@luminovo/design-system';
import { CustomOptionOfferDTO, SolutionConfigurationDTO, StandardPartOfferDTO } from '@luminovo/http-client';
import React from 'react';
import { Solution } from '../../../types';
import { CustomPartOfferInfos } from './components/CustomPartOfferInfos';
import { OfferCostBreakdown } from './components/OfferCostBreakdown';
import { StandardPartOfferInfos } from './components/StandardPartOfferInfos';

export function OfferDrawer({
    solution,
    solutionConfiguration,
    standardPartOffer,
    customPartOffer,
    header,
    isTotalCostOfOwnershipEnabled = false,
    isPcbCustomPartOffer = false,
}: {
    solution?: Solution;
    solutionConfiguration?: SolutionConfigurationDTO;
    standardPartOffer?: StandardPartOfferDTO;
    customPartOffer?: CustomOptionOfferDTO;
    header: JSX.Element;
    isTotalCostOfOwnershipEnabled?: boolean;
    isPcbCustomPartOffer?: boolean;
}): JSX.Element {
    return (
        <Flexbox flexDirection={'column'} justifyContent={'space-between'} height={'100%'} width="500px">
            <SectionHeader>{header}</SectionHeader>
            <SectionBody>
                {standardPartOffer && (
                    <StandardPartOfferInfos
                        offer={standardPartOffer}
                        solution={solution}
                        solutionConfiguration={solutionConfiguration}
                    />
                )}
                {customPartOffer && (
                    <CustomPartOfferInfos
                        offer={customPartOffer}
                        solution={solution}
                        solutionConfiguration={solutionConfiguration}
                        isPcbCustomPartOffer={isPcbCustomPartOffer}
                    />
                )}
            </SectionBody>

            {solution && (standardPartOffer || customPartOffer) && (
                <OfferCostBreakdown
                    solution={solution}
                    isTotalCostOfOwnershipEnabled={isTotalCostOfOwnershipEnabled}
                    isPcbCustomPartOffer={isPcbCustomPartOffer}
                />
            )}
        </Flexbox>
    );
}

function SectionHeader(props: React.PropsWithChildren<{}>) {
    return <>{props.children}</>;
}

function SectionBody(props: React.PropsWithChildren<{}>) {
    return <div style={{ flexGrow: 1 }}>{props.children}</div>;
}
