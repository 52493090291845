import { Trans, t } from '@lingui/macro';
import { MenuItem, Tooltip } from '@luminovo/design-system';
import { useUniversalImporter } from '../../context';

export function MenuItemExcludeWarnings(): JSX.Element {
    const { dispatch, state } = useUniversalImporter();

    const count = state.importerTable?.getParseStatusCount().warning ?? 0;

    return (
        <Tooltip title={t`Exclude ${count} rows with warnings`} placement="right">
            <MenuItem
                label={<Trans>Exclude warnings</Trans>}
                onClick={() => {
                    dispatch({ type: 'setImporterTable', table: state.importerTable?.excludeWarnings() });
                }}
            />
        </Tooltip>
    );
}
