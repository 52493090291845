import { t, Trans } from '@lingui/macro';
import { colorSystem, Text } from '@luminovo/design-system';
import { http } from '@luminovo/http-client';
import { MenuItem, TableCell } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import React from 'react';
import ConfirmationDialogBox from '../../../../../components/dialogBox/ConfirmationDialogBox';
import NestedTableMenu from '../../../../../components/NestedTableMenu';
import { useToken } from '../../../../../resources/hooks';
import { useDebugErrorHandler } from '../../../../../resources/http/debugErrorHandler';
import { httpQueryKey } from '../../../../../resources/http/httpQueryKey';
import { setAnchorElementAsNullAndStopPropagation } from '../../../../../utils/anchor';
import { useInformationAndConfirmationDialogs } from '../../../../Manufacturing/shared/manufacturingUtils';

interface CalculationTemplateMenuProps {
    templateId: string;
}

const useDeleteCalculationTemplate = (templateId: string) => {
    const { token } = useToken();
    const queryClient = useQueryClient();
    const debugErrorHandler = useDebugErrorHandler();

    const { mutateAsync } = useMutation({
        mutationFn: () => http('DELETE /calculations/templates/:templateId', { pathParams: { templateId } }, token),
        onSuccess: () => queryClient.invalidateQueries({ queryKey: httpQueryKey('GET /calculations/templates') }),
        onError: debugErrorHandler,
    });

    return {
        deleteOnClick: mutateAsync,
    };
};

const CalculationTemplateMenuComponent: React.FC<CalculationTemplateMenuProps> = ({ templateId }): JSX.Element => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = React.useCallback((event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    }, []);
    const handleClose = React.useCallback((event: React.MouseEvent<HTMLElement>) => {
        setAnchorElementAsNullAndStopPropagation(event, setAnchorEl);
    }, []);
    const { deleteOnClick } = useDeleteCalculationTemplate(templateId);

    const {
        openDialog: openDeleteDialog,
        closeDialog: closeDeleteDialog,
        isConfirmationDialogOpen: isConfirmationDialogDeleteOpen,
    } = useInformationAndConfirmationDialogs(false);

    return (
        <TableCell>
            <NestedTableMenu open={open} anchorEl={anchorEl} handleClick={handleClick} handleClose={handleClose}>
                <MenuItem
                    onClick={(e) => {
                        openDeleteDialog();
                        handleClose(e);
                    }}
                >
                    <Text variant="h4" style={{ color: colorSystem.red[6] }}>
                        <Trans>Delete</Trans>
                    </Text>
                </MenuItem>
            </NestedTableMenu>

            <ConfirmationDialogBox
                text={t`Are you sure you want to remove this template?`}
                title={t`Remove template`}
                onConfirm={() => {
                    deleteOnClick();
                }}
                onReject={() => {
                    closeDeleteDialog();
                }}
                isDialogOpen={isConfirmationDialogDeleteOpen}
                deleteText={t`Yes, remove`}
            />
        </TableCell>
    );
};

export default CalculationTemplateMenuComponent;
