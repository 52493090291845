/* eslint-disable camelcase */
import { Trans } from '@lingui/macro';
import { formatDecimal, formatToLongDate, isPresent, uniqBy } from '@luminovo/commons';
import {
    colorSystem,
    FieldMultiSelectControlled,
    FieldSelectControlled,
    Flexbox,
    StickyLayout,
    Tag,
    Text,
    Toolbar,
} from '@luminovo/design-system';
import {
    EmailTemplateDTO,
    OrganizationDTO,
    QuoteRequestDTO,
    RfqDTO,
    SupplierContactDTO,
    UserDTO,
} from '@luminovo/http-client';
import { renderTemplate, RichTextEditorInput } from '@luminovo/rich-text-editor';
import { formatSupplierDTO } from '@luminovo/sourcing-core';
import { Box, ListItem } from '@mui/material';
import React from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useHistory } from 'react-router';
import { useCurrentUserDetailsContext } from '../../../../components/contexts/CurrentUserDetailsContext';
import { FormContainer } from '../../../../components/formLayouts/FormContainer';
import { SubmitButton } from '../../../../components/formLayouts/SubmitButton';
import { PageLayoutCollapsibleSidebar } from '../../../../components/PageLayoutCollapsibleSidebar';
import { useHttpQuery, useSuspenseHttpQuery } from '../../../../resources/http/useHttpQuery';
import { useHttpMutation } from '../../../../resources/mutation/useHttpMutation';
import { useRfQ } from '../../../../resources/rfq/rfqHandler';
import {
    useSupplierContacts,
    useSupplierContactsFromSupplier,
} from '../../../../resources/supplierContact/supplierContactHandler';
import { useAllOrganizationUsers } from '../../../../resources/user/userHandler';
import { route, UrlParams } from '../../../../utils/routes';
import { useQuoteRequestsByNumber } from '../../../Negotiations/hooks/negotiationHandlers';
import { NavigationSidebarSourcing } from '../NavigationSidebarSourcing';
import { createVars, VarId } from '../QuoteExporter/createVars';
import { getQuoteRequestEmailTemplateBySupplier } from '../QuoteExporter/getQuoteRequestEmailTemplateBySupplier';

type MailData = {
    quoteRequest: QuoteRequestDTO;
    email: {
        template?: EmailTemplateDTO;
        subject: string;
        body: string;
    };
    recipients: Array<SupplierContactDTO>;
    ccs: Array<UserDTO>;
};
type FormValues = {
    selectedQuoteRequestId: string;
    mail_data: MailData[];
    include_pcb_specification: boolean;
    include_shipping_panel_specification: boolean;
    include_mail_attachments: boolean;
    sharedTemplateContext: {
        rfq: RfqDTO;
        sender: UserDTO;
        organization: OrganizationDTO;
    };
};

export function useRenderQuoteRequestEmailTemplate({ index }: { index: number }) {
    const { control } = useFormContext<FormValues>();
    const sharedTemplateContext = useWatch({ control, name: `sharedTemplateContext` });
    const quoteRequest = useWatch({ control, name: `mail_data.${index}.quoteRequest` });
    const recipients = useWatch({ control, name: `mail_data.${index}.recipients` }) ?? [];

    return (template: EmailTemplateDTO) => {
        return renderQuoteRequestEmailTemplate({
            template,
            quoteRequest,
            recipient: recipients[0],
            ...sharedTemplateContext,
        });
    };
}

function renderQuoteRequestEmailTemplate({
    quoteRequest,
    template,
    sender,
    organization,
    recipient,
    rfq,
}: {
    quoteRequest: QuoteRequestDTO;
    template: EmailTemplateDTO | null;
    sender: { first_name: string; last_name: string } | null;
    recipient: { first_name: string; last_name: string } | null;
    organization: { name: string } | null;
    rfq: RfqDTO | null;
}) {
    if (!template) {
        return { subject: '', body: '' };
    }

    const context: Record<VarId, string> = {
        'customer.name': rfq?.customer ?? '',
        'quote_request.due_date': quoteRequest.due_date ?? '',
        'quote_request.number': String(quoteRequest.id),
        'quote_request.notes': quoteRequest.notes ?? '',
        'quote_request.send_date': formatToLongDate(new Date()),
        'quote_request.supplier_portal_link':
            window.origin +
            route('/supplier-portal/quote-requests/:quoteRequestId', {
                quoteRequestId: quoteRequest.id,
            }),
        'rfq.name': rfq?.name ?? '',
        'rfq.number': rfq?.ems_internal_number ?? '',
        'sender.first_name': sender?.first_name ?? '',
        'sender.last_name': sender?.last_name ?? '',
        'sender.organization.name': organization?.name ?? '',
        'supplier.name': quoteRequest.supplier.name ?? '',
        'recipient.first_name': recipient?.first_name ?? '',
        'recipient.last_name': recipient?.last_name ?? '',
    };

    const subject = renderTemplate(template.subject, context, { asText: true });
    const body = renderTemplate(template.body, context, { asText: false });

    return { subject, body };
}

function useSubmitEmail() {
    const history = useHistory();
    const { mutateAsync: sendEmails } = useHttpMutation('POST /quote-request/send-many', {
        snackbarMessage: 'Email sent',
        onSuccess: () => history.goBack(),
    });

    return async (form: FormValues) => {
        await sendEmails({
            requestBody: {
                mail_data: form.mail_data.map((mail) => {
                    return {
                        quote_request_id: mail.quoteRequest.id,
                        email: { subject: mail.email.subject, body: mail.email.body },
                        recipient_emails: mail.recipients.map((recipient) => ({
                            email: recipient.email,
                            name: `${recipient.first_name} ${recipient.last_name}`,
                        })),
                        cc_emails: mail.ccs.map((cc) => ({
                            email: cc.email,
                            name: `${cc.first_name} ${cc.last_name}`,
                        })),
                    };
                }),
                include_mail_attachments: form.include_mail_attachments,
                include_pcb_specification: form.include_pcb_specification,
                include_shipping_panel_specification: form.include_shipping_panel_specification,
            },
        });
    };
}

function useDefaultValues(
    urlParams: UrlParams<'/rfqs/:rfqId/sourcing/negotiations/:negotiationId/quote-requests/preview-email'>,
): FormValues | undefined {
    const quoteRequestIds = urlParams.queryParams.quoteRequestNumbers
        .split(',')
        .map(Number)
        .filter((x) => !isNaN(x));

    // TODO useSuspenseHttpQuery should be used here
    const { data: rfq } = useRfQ(urlParams.pathParams.rfqId);
    const { data: quoteRequests } = useQuoteRequestsByNumber(quoteRequestIds);
    const { data: organizationUsers } = useAllOrganizationUsers();
    const { user: sender, organization } = useCurrentUserDetailsContext();
    const { data: supplierContacts } = useSupplierContacts({ refetchOnWindowFocus: true });
    const { data: templates } = useSuspenseHttpQuery(
        'GET /email-template',
        {},
        {
            select: (res) => res.items,
        },
    );

    if (
        !isPresent(rfq) ||
        !isPresent(quoteRequests) ||
        !isPresent(organizationUsers) ||
        !isPresent(organization) ||
        !isPresent(supplierContacts) ||
        !isPresent(templates)
    ) {
        return undefined;
    }

    return {
        selectedQuoteRequestId: quoteRequests[0].id,
        mail_data: quoteRequests.map((quoteRequest) => {
            const template = getQuoteRequestEmailTemplateBySupplier(templates, quoteRequest.supplier.id);
            const recipient = supplierContacts
                .filter((c) => c.supplier === quoteRequest.supplier.id)
                .find((c) => c.is_main_contact);
            const resolvedCcs = organizationUsers.filter((user) => template?.ccs.includes(user.id));

            const { subject, body } = renderQuoteRequestEmailTemplate({
                sender,
                organization,
                rfq,
                quoteRequest,
                recipient: recipient ?? null,
                template: template ?? null,
            });

            return {
                quoteRequest,
                email: { subject, body, template },
                recipients: isPresent(recipient) ? [recipient] : [],
                ccs: uniqBy([sender, ...resolvedCcs], (mail) => mail.email),
            };
        }),
        include_pcb_specification: false,
        include_shipping_panel_specification: false,
        include_mail_attachments: true,
        sharedTemplateContext: {
            rfq,
            sender,
            organization,
        },
    };
}

//------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------
//------------------------------------------------------------------------------------

function FormItemHorizontal({ label, children }: { label: string; children: React.ReactNode }) {
    return (
        <Box
            sx={{
                alignItems: 'center',
                display: 'grid',
                gridTemplateColumns: '1fr 6fr',
                height: '100%',
                width: '100%',
            }}
        >
            <Text variant={'h4'} color={colorSystem.neutral[6]}>
                {label}
            </Text>
            {children}
        </Box>
    );
}

const SupplierList: React.FunctionComponent<{}> = () => {
    const { control } = useFormContext<FormValues>();

    const mailData = useWatch({
        control: control,
        name: 'mail_data',
    });

    return (
        <StickyLayout
            style={{
                border: `1px solid ${colorSystem.neutral[2]}`,
                borderRadius: '8px',
                backgroundColor: 'white',
                zIndex: 3,
                height: '85vh',
                padding: '12px',
                boxSizing: 'border-box',
            }}
        >
            <Flexbox paddingX={'24px'} paddingY={'20px'} borderBottom={`1px solid ${colorSystem.neutral[2]}`}>
                <Text variant={'h3'} color={colorSystem.neutral[8]}>
                    <Trans>Quote requests</Trans>
                </Text>
            </Flexbox>
            <Flexbox flexDirection={'column'} flexGrow={1} style={{ overflowY: 'auto' }}>
                {mailData.map((mail, index) => (
                    <SupplierListItem key={mail.quoteRequest.id} index={index} mail={mail} />
                ))}
            </Flexbox>
        </StickyLayout>
    );
};

const SupplierListItem: React.FunctionComponent<{
    index: number;
    mail: FormValues['mail_data'][number];
}> = ({ index, mail }) => {
    const { setValue, control } = useFormContext<FormValues>();

    const selectedQuoteRequestId = useWatch({
        control: control,
        name: 'selectedQuoteRequestId',
    });

    const isSelected = selectedQuoteRequestId === mail.quoteRequest.id;

    return (
        <ListItem
            onClick={() => setValue('selectedQuoteRequestId', mail.quoteRequest.id)}
            style={{
                padding: 0,
                backgroundColor: isSelected ? colorSystem.primary[1] : undefined,
                borderRadius: '8px',
            }}
        >
            <Flexbox
                flexDirection={'column'}
                gap={8}
                marginX={'24px'}
                paddingY={'20px'}
                width={'100%'}
                borderBottom={`1px solid ${colorSystem.neutral[2]}`}
            >
                <Flexbox justifyContent="space-between">
                    <Text
                        variant={'h4'}
                        color={isSelected ? colorSystem.primary[7] : colorSystem.neutral[8]}
                        style={{
                            maxWidth: '200px',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}
                    >
                        {formatSupplierDTO(mail.quoteRequest.supplier)}
                    </Text>
                    <Tag attention="low" color={'neutral'} label={`#${mail.quoteRequest.number}`} />
                </Flexbox>
                <MissingRecipientLabel index={index} />
            </Flexbox>
        </ListItem>
    );
};

function MissingRecipientLabel({ index }: { index: number }) {
    const { control } = useFormContext<FormValues>();

    return (
        <Controller
            control={control}
            name={`mail_data.${index}.recipients`}
            rules={{
                validate: (value) => {
                    if (!value) {
                        return true;
                    }
                    if (value.length > 0) {
                        return true;
                    }
                    return 'At least one recipient is required';
                },
            }}
            render={({ field: { value } }) => {
                const firstSupplierContact = value[0];

                if (!isPresent(firstSupplierContact)) {
                    return (
                        <Text variant={'body-small'} color={colorSystem.yellow[7]}>
                            No recipient
                        </Text>
                    );
                }

                return (
                    <Text variant={'body-small'} color={colorSystem.neutral[8]}>
                        {`${firstSupplierContact.email}  •  ${firstSupplierContact.user_language.toLocaleUpperCase()}${value.length > 1 ? ` +${value.length - 1}` : ''}`}
                    </Text>
                );
            }}
        />
    );
}

const FormTitle = ({ index, mail }: { index: number; mail: MailData }) => {
    // TODO: here we need to open the supplier page but we can't because there is no supplier page yet
    /* 
    const handleOpenSupplierPage = () => {
        window.open(
            route('/supplier/:supplierAndStockLocationId', {
                supplierAndStockLocationId: supplierEmail.supplierAndStockLocation.id,
            }),
            '_blank',
            'noopener noreferrer',
        );
    };
    */

    return (
        <Flexbox alignItems={'center'} justifyContent="space-between">
            <Text variant={'h3'} color={colorSystem.neutral[8]}>
                <Trans>Email</Trans>
            </Text>
        </Flexbox>
    );
};

const FormItemEmailTemplate = function FormItemEmailTemplate({ index }: { index: number }): JSX.Element {
    const { control, setValue } = useFormContext<FormValues>();

    const { data = [] } = useHttpQuery(
        'GET /email-template',
        {},
        {
            refetchOnWindowFocus: true,
            select: (res) => Array.from(res.items).sort((a, b) => a.name.localeCompare(b.name)),
        },
    );

    const renderTemplate = useRenderQuoteRequestEmailTemplate({ index });
    const handleChange = React.useCallback(
        (value: EmailTemplateDTO) => {
            const { subject, body } = renderTemplate(value);
            setValue(`mail_data.${index}.email.template`, value);
            setValue(`mail_data.${index}.email.subject`, subject);
            setValue(`mail_data.${index}.email.body`, body);
        },
        [index, renderTemplate, setValue],
    );

    return (
        <FormItemHorizontal label="Template">
            <FieldSelectControlled
                control={control}
                name={`mail_data.${index}.email.template`}
                FieldProps={{
                    disableClearable: true,
                    options: data,
                    getOptionKey: (opt) => opt.id,
                    getOptionLabel: (opt) => opt.name,
                    isOptionEqualToValue: (option, value) => option.id === value.id,
                    afterChange: (value) => isPresent(value) && handleChange(value),
                }}
            />
        </FormItemHorizontal>
    );
};

const FormItemRecipients = ({ index, mail }: { index: number; mail: MailData }) => {
    const { control } = useFormContext<FormValues>();

    const { data: supplierContacts = [] } = useSupplierContactsFromSupplier(mail.quoteRequest.supplier.id, {
        refetchOnWindowFocus: true,
    });

    return (
        <FormItemHorizontal label="Recipient">
            <FieldMultiSelectControlled
                control={control}
                name={`mail_data.${index}.recipients`}
                FieldProps={{
                    options: supplierContacts,
                    fullWidth: true,
                    disableClearable: true,
                    getOptionKey: (opt) => opt.id,
                    getOptionLabel: (opt) => `${opt.first_name} ${opt.last_name}`,
                }}
            />
        </FormItemHorizontal>
    );
};

function FormItemCcs({ index }: { index: number }) {
    const { control } = useFormContext<FormValues>();
    const { data = [] } = useAllOrganizationUsers();

    return (
        <FormItemHorizontal label="Cc">
            <FieldMultiSelectControlled
                control={control}
                // eslint-disable-next-line spellcheck/spell-checker
                name={`mail_data.${index}.ccs`}
                FieldProps={{
                    options: data,
                    getOptionLabel: (contact) => `${contact.first_name} ${contact.last_name} <${contact.email}>`,
                    getOptionKey: (contact) => contact.email,
                }}
            />
        </FormItemHorizontal>
    );
}

const FormItemSubject = function FormItemSubject({ index }: { index: number }): JSX.Element {
    const { control } = useFormContext<FormValues>();
    const templateId = useWatch({ control, name: `mail_data.${index}.email.template.id` });

    // This is necessary because RichTextEditorInput wraps text in <p> tags,
    // but we want to store only the plain text content for the email subject
    const stripHtml = (html: string) => {
        const tmp = document.createElement('DIV');
        tmp.innerHTML = html;
        return tmp.textContent || tmp.innerText || '';
    };

    return (
        <FormItemHorizontal label="Subject">
            <Controller
                control={control}
                name={`mail_data.${index}.email.subject`}
                render={({ field }) => {
                    return (
                        <RichTextEditorInput
                            key={templateId}
                            namespace="quote-requests::subject"
                            vars={createVars()}
                            hideVariables
                            initialHtml={field.value}
                            disableRichText={true}
                            onChange={(html) => field.onChange(stripHtml(html))}
                            autosaveEveryMillis={200}
                        />
                    );
                }}
            />
        </FormItemHorizontal>
    );
};

const FormItemBody = function FormItemBody({ index }: { index: number }) {
    const { control } = useFormContext<FormValues>();
    const templateId = useWatch({ control, name: `mail_data.${index}.email.template.id` });

    return (
        <Controller
            control={control}
            name={`mail_data.${index}.email.body`}
            render={({ field }) => {
                return (
                    <RichTextEditorInput
                        key={templateId}
                        namespace="quote-requests::subject"
                        vars={createVars()}
                        hideVariables
                        initialHtml={field.value}
                        onChange={(html) => {
                            field.onChange(html);
                        }}
                        autosaveEveryMillis={200}
                    />
                );
            }}
        />
    );
};

const EmailEditor: React.FunctionComponent = () => {
    const { control } = useFormContext<FormValues>();

    const selectedQuoteRequestId = useWatch({
        control: control,
        name: 'selectedQuoteRequestId',
    });
    const mailData = useWatch({
        control: control,
        name: 'mail_data',
    });
    const index = mailData.findIndex((elem) => elem.quoteRequest.id === selectedQuoteRequestId);
    const mail = mailData.find((elem) => elem.quoteRequest.id === selectedQuoteRequestId);

    if (!isPresent(mail)) {
        return <></>;
    }

    return (
        <Flexbox key={mail.quoteRequest.id} flexDirection={'column'} gap={24} paddingX={'24px'} paddingY={'20px'}>
            <FormTitle key={`title-${index}`} index={index} mail={mail} />
            <Flexbox flexDirection={'column'} gap={24}>
                <FormItemEmailTemplate index={index} />
                <FormItemRecipients index={index} mail={mail} />
                <FormItemCcs index={index} />
                <FormItemSubject index={index} />
            </Flexbox>
            <FormItemBody index={index} />
        </Flexbox>
    );
};

function ToolbarInner({ rfqId, negotiationId }: { rfqId: string; negotiationId: number }) {
    const { control } = useFormContext<FormValues>();
    const quoteRequests = useWatch({ control, name: 'mail_data' });

    return (
        <Toolbar
            breadcrumbs={[
                {
                    title: `Sourcing negotiation`,
                    href: route(`/rfqs/:rfqId/sourcing/negotiations/:negotiationId/line-items`, {
                        rfqId,
                        negotiationId,
                    }),
                },
                {
                    title: `Email preview`,
                },
            ]}
        >
            <SubmitButton size="medium" label={`Send ${formatDecimal(quoteRequests.length)} emails`} />
        </Toolbar>
    );
}

export default function QuoteRequestEmailPreviewPage(
    urlParams: UrlParams<'/rfqs/:rfqId/sourcing/negotiations/:negotiationId/quote-requests/preview-email'>,
) {
    const { rfqId } = urlParams.pathParams;
    const negotiationId = Number(urlParams.pathParams.negotiationId);

    const defaultValues = useDefaultValues(urlParams);
    const onSubmit = useSubmitEmail();

    if (!isPresent(defaultValues)) {
        return <></>;
    }

    return (
        <FormContainer defaultValues={defaultValues} onSubmit={onSubmit} UNSAFE_disableStablePropCheck>
            <PageLayoutCollapsibleSidebar
                sidebar={<NavigationSidebarSourcing rfqId={rfqId} />}
                header={<ToolbarInner rfqId={rfqId} negotiationId={negotiationId} />}
                layoutVariant="fullWidth"
            >
                <Box display="grid" gridTemplateColumns="2fr 5fr" height={'100%'}>
                    <SupplierList />
                    <EmailEditor />
                </Box>
            </PageLayoutCollapsibleSidebar>
        </FormContainer>
    );
}
