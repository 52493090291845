import * as React from 'react';

// Core Negotiation Pages
export const LazyNegotiationCreatePage = React.lazy(async () => import('./pages/NegotiationCreatePage'));
export const LazyNegotiationListPage = React.lazy(async () => import('./pages/NegotiationListPage'));
export const LazyNegotiationLineItemDetailsPage = React.lazy(
    async () => import('./pages/NegotiationLineItemDetailsPage'),
);

// Negotiation Detail Pages
export const LazyNegotiationSummaryPage = React.lazy(async () => import('./pages/NegotiationDetails/SummaryPage'));
export const LazyNegotiationQuoteRequestsPage = React.lazy(
    async () => import('./pages/NegotiationDetails/QuoteRequestsPage'),
);
export const LazyNegotiationDemandsPage = React.lazy(async () => import('./pages/NegotiationDetails/DemandsPage'));
export const LazyNegotiationScenariosPage = React.lazy(async () => import('./pages/NegotiationDetails/ScenariosPage'));

// Quote Request Related Pages
export const LazyQuoteRequestAssignPage = React.lazy(async () => import('./pages/QuoteRequestAssignPage/index'));
export const LazyQuoteRequestEmailPreviewPage = React.lazy(async () => import('./pages/QuoteRequestEmailPreviewPage'));
export const LazyNegotiationQuoteImporterPage = React.lazy(
    async () => import('./NegotiationsQuoteImporter/ExcelQuoteImporterPage'),
);

// Supplier Portal Pages
export const LazySupplierPortalPage = React.lazy(async () => import('./pages/supplier-portal/SupplierPortalPage'));
export const LazySupplierPortalQuoteRequestPage = React.lazy(
    async () => import('./pages/supplier-portal/SupplierPortalQuoteRequestPage/index'),
);
