import { isProductionEnvironment } from '@luminovo/commons';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import App from './App';
import { getCustomerPortalTenant } from './permissions/tenants';
import { runCookieConsent } from './utils/analytics';

window.document.title = getCustomerPortalTenant()?.name ?? 'Luminovo';

runCookieConsent();

// @ts-ignore
window.hsConversationsSettings = {
    loadImmediately: false,
};

const container = document.getElementById('root') as HTMLElement;

const RUN_IN_LEGACY_MODE = true;

if (isProductionEnvironment() || RUN_IN_LEGACY_MODE) {
    // Use legacy ReactDOM.render to run in React 17 mode
    ReactDOM.render(<App />, container);
} else {
    // eslint-disable-next-line no-console
    console.log('Running in React 18 mode');
    // The react 18 concurrent mode does not work with the current version of the react router.
    const root = createRoot(container);
    root.render(<App />);
}
