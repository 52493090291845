import { t, Trans } from '@lingui/macro';
import { formatPercentage } from '@luminovo/commons';
import { colorSystem, Flexbox, PrimaryButton, SecondaryButton, Text } from '@luminovo/design-system';
import { SupplierAndStockLocationDTO } from '@luminovo/http-client';
import { Divider, Tooltip } from '@mui/material';
import React from 'react';
import { formatSupplierAndStockLocationDTO } from '../../../formatters';

export function PdfViewerToolbar({
    extractionRateOfRequiredFields,
    onImport,
    onCancel,
    supplierAndStockLocation,
    importLabel = <Trans>Import</Trans>,
}: {
    supplierAndStockLocation?: SupplierAndStockLocationDTO;
    extractionRateOfRequiredFields: number;
    importLabel?: JSX.Element;
    onImport: () => void;
    onCancel: () => void;
}): JSX.Element {
    const canImport = extractionRateOfRequiredFields >= 1;
    const extractionPercent = formatPercentage(extractionRateOfRequiredFields);

    return (
        <Flexbox flexDirection={'column'}>
            <Flexbox padding="8px 16px" alignItems={'center'} gap={8}>
                <Text color={colorSystem.neutral[8]} variant={'h2'}>
                    {t`Add off-the-shelf offers`}{' '}
                </Text>
                {supplierAndStockLocation ? (
                    <>
                        <span
                            style={{
                                display: 'inline-block',
                                borderRadius: '100%',
                                width: '4px',
                                height: '4px',
                                background: colorSystem.neutral[4],
                            }}
                        />
                        <Text color={colorSystem.neutral[8]} variant={'h2'}>
                            {formatSupplierAndStockLocationDTO(supplierAndStockLocation)}
                        </Text>
                    </>
                ) : null}

                <span style={{ flexGrow: 1 }} />
                <SecondaryButton onClick={onCancel} size="medium">
                    <Trans>Cancel</Trans>
                </SecondaryButton>
                <Tooltip title={t`${extractionPercent} extracted`}>
                    <span>
                        <ImportButton label={importLabel} canImport={canImport} onImport={onImport} />
                    </span>
                </Tooltip>
            </Flexbox>
            <Divider />
        </Flexbox>
    );
}

function ImportButton({
    label,
    canImport,
    onImport,
}: {
    label: JSX.Element;
    canImport: boolean;
    onImport: () => void;
}) {
    // TOOD(fhur) Hacky way to render a loading spinner inside the button.
    // A better way would be to pass an "is loading" prop.
    const [isLoading, setIsLoading] = React.useState(false);
    return (
        <PrimaryButton
            type="button"
            onClick={() => {
                setIsLoading(true);
                onImport();
            }}
            size="medium"
            disabled={!canImport}
            isLoading={isLoading}
        >
            {label}
        </PrimaryButton>
    );
}
