import { ItemClass } from '@luminovo/http-client';
import { cases } from '../combinators/cases';
import { Parser } from '../parsers/Parser';

export interface ItemClassProps {}

export const itemClass = (_?: ItemClassProps): Parser<ItemClass> =>
    cases({
        matches: [
            {
                when: [ItemClass.Standard],
                then: ItemClass.Standard,
            },
            {
                when: [ItemClass.NonStandard, 'non standard'],
                then: ItemClass.NonStandard,
            },
        ],
    });
