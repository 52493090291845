import { transEnum } from '@luminovo/commons';
import { Flexbox, Text } from '@luminovo/design-system';
import { ActivityLevel, TimeCalculation } from '@luminovo/http-client';
import { ErrorInFormulaBox } from '@luminovo/manufacturing-core';
import React from 'react';
import { MonacoEditor } from '../../../../../components/MonacoEditor';
import { MonacoEditorRef } from '../../../../../components/MonacoEditor/MonacoEditor';
import { Skeleton } from '../../../../../components/Spinners';
import { durationUnitPublicTranslations } from '../../../../../resources/activity/activityBackendTypes';
import { route } from '../../../../../utils/routes';
import { CalculationTypeCell } from '../../../shared/manufacturingComponents';
import { useFormulaInformation } from '../../../utils/useDriversFormulaInformation';
import { useFormulaOfAnActivity } from '../../../utils/useFormulaOfActivity';
import { useManufacturingInputControls } from '../../../utils/useInputControls';

export const FormulaOfActivity = ({
    activityId,
    timeLevel,
    timeCalculation,
}: {
    activityId: string;
    timeLevel: ActivityLevel;
    timeCalculation?: TimeCalculation | null;
}): JSX.Element => {
    const editorRef: MonacoEditorRef = React.useRef();
    const { formulaDetails, isLoading: isLoadingFormula } = useFormulaOfAnActivity(
        activityId,
        timeLevel,
        timeCalculation,
    );
    const { validationResponse, isLoading: isLoadingDriversInFormula } = useFormulaInformation(formulaDetails?.formula);
    const { inputControls, isLoading } = useManufacturingInputControls(formulaDetails?.formula ?? '');
    if (isLoading || isLoadingFormula || isLoadingDriversInFormula || formulaDetails === undefined) {
        return <Skeleton />;
    }

    return (
        <span>
            <Flexbox marginBottom={'8px'} gap={8}>
                <CalculationTypeCell calculationType="Formula" />{' '}
                <Text>({transEnum(formulaDetails.unit, durationUnitPublicTranslations)}) </Text>
            </Flexbox>
            <Flexbox flexDirection={'column'} gap={8}>
                {validationResponse?.result === 'Error' && (
                    <ErrorInFormulaBox route={route('/manufacturing/activity/:activityId/edit', { activityId })} />
                )}

                <MonacoEditor
                    editorRef={editorRef}
                    value={formulaDetails.formula}
                    readOnly
                    inputControls={inputControls}
                />
            </Flexbox>
        </span>
    );
};
