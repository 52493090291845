import { t } from '@lingui/macro';
import { CenteredLayout, colorSystem, NonIdealState } from '@luminovo/design-system';
import { StepEnum } from '@luminovo/http-client';
import { ErrorOutline } from '@mui/icons-material';
import { useIsMutating } from '@tanstack/react-query';
import * as React from 'react';
import { useIsCustomer } from '../../../../components/contexts/CurrentUserDetailsContext';
import { useHttpQuery } from '../../../../resources/http/useHttpQuery';
import { isFilesUploaded } from '../../../../resources/pcb/pcbFunctions';
import { UPLOAD_PCB_FILE_MUTATION_KEY, usePcbOfAssembly } from '../../../../resources/pcb/pcbHandlers';
import { helpHero } from '../../../../utils/analytics';
import { ViewContext } from '../../../Bom/components/ModuleTableData';
import { PcbAnalysisProgressBar } from '../PcbAnalysis';
import { PcbLoadingError, PcbLoadingProgress } from './components/PcbErrorLoading';
import { UploadPcbFilesDropzone } from './components/UploadPcbFilesDropzone';
interface BasePcbTabProps {
    assemblyId: string;
    rfqId: string;
    isEditable: boolean;
    children: React.ReactNode;
    viewContext: ViewContext;
}

export function PcbLoading({ assemblyId, rfqId, isEditable, children, viewContext }: BasePcbTabProps) {
    const { data: pcb, isLoading, isError } = usePcbOfAssembly({ assemblyId });

    const isPCBFilesUploading = useIsMutating({ mutationKey: UPLOAD_PCB_FILE_MUTATION_KEY }) > 0;

    const isCustomer = useIsCustomer();
    const { data: customerPortalSettings } = useHttpQuery('GET /organization-settings/customer-portal', {});
    const isPcbOptional =
        isCustomer && customerPortalSettings
            ? customerPortalSettings.customer_portal_settings.upload_pcb === StepEnum.optional
            : true;

    // Ensures HelpHero tour works as expected
    const hasPcbBeenImported = pcb !== undefined;
    helpHero?.update({ hasPcbBeenImported });

    if (isLoading || isPCBFilesUploading) {
        return <PcbLoadingProgress />;
    }

    if (isError) {
        return <PcbLoadingError />;
    }

    if (!pcb || !isFilesUploaded(pcb)) {
        if (isEditable) {
            return (
                <CenteredLayout bgcolor={colorSystem.neutral[1]} justifyContent="unset" paddingTop={'48px'}>
                    <UploadPcbFilesDropzone
                        rfqId={rfqId}
                        pcbId={pcb?.id}
                        assemblyId={assemblyId}
                        viewContext={viewContext}
                        isPcbOptional={isPcbOptional}
                    />
                </CenteredLayout>
            );
        } else {
            return (
                <CenteredLayout bgcolor={colorSystem.neutral[1]} justifyContent="unset" paddingTop={'48px'}>
                    <NonIdealState
                        title={t`No PCB uploaded`}
                        description={t`There was no PCB uploaded for this assembly.`}
                        withBorder={true}
                        Icon={ErrorOutline}
                    />
                </CenteredLayout>
            );
        }
    }

    // TODO: let's use a React Context here so pcb is available down the tree
    // and remove the wrapper components
    return (
        <>
            <PcbAnalysisProgressBar pcb={pcb} assemblyId={assemblyId} rfqId={rfqId} />
            {children}
        </>
    );
}
