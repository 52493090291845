import { t } from '@lingui/macro';
import { colorSystem } from '@luminovo/design-system';
import { Lock, LockOpen } from '@mui/icons-material';
import { ToggleButton, ToggleButtonGroup, Tooltip, styled } from '@mui/material';
import React, { useState } from 'react';
import { FieldArrayPath, useFormContext } from 'react-hook-form';
import { CalculationTemplateFormInput } from '../utils/types';

export type ProfitAndDiscountFields =
    | 'manufacturingCost.discount'
    | 'manufacturingCost.profit'
    | 'materialCost.discount'
    | 'materialCost.profit'
    | 'additionalCosts.discount'
    | 'additionalCosts.profit'
    | 'projectCost.profit';

const StyledToggleButton = styled(ToggleButton)({
    color: colorSystem.neutral[7],
    '&.MuiToggleButton-root.Mui-selected': {
        backgroundColor: colorSystem.neutral.white,
        color: colorSystem.neutral[9],
        boxShadow: `0px 2px 4px 1px rgba(76,83,102,0.15)`,
    },
    '&.MuiToggleButtonGroup-groupedHorizontal': {
        borderRadius: 4,
        margin: 0,
        border: 0,
    },
});

const StyledToggleButtonGroup = styled(ToggleButtonGroup)({
    height: 32,
    backgroundColor: colorSystem.neutral[2],
    padding: 4,
    gap: 4,
});

export function LockToggle({
    name,
}: {
    //TODO: this can be made much cleaner by accepting any type where the name is a boolean
    name: `${FieldArrayPath<CalculationTemplateFormInput>}.${number}.isLocked` | `${ProfitAndDiscountFields}.isLocked`;
}) {
    const { watch, setValue } = useFormContext<CalculationTemplateFormInput>();
    const [internalValue, setInternalValue] = useState<boolean>(watch(name));
    const handleChange = (_: React.MouseEvent<HTMLElement, MouseEvent>, value: boolean) => {
        if (value === null) {
            return;
        }
        if (value !== internalValue) {
            setValue(name, value, {
                shouldDirty: true,
                shouldTouch: true,
                shouldValidate: true,
            });
            setInternalValue(value);
        }
    };
    return (
        <StyledToggleButtonGroup
            value={internalValue}
            exclusive
            onChange={handleChange}
            style={{ backgroundColor: colorSystem.neutral[1] }}
        >
            <StyledToggleButton value={true}>
                <Tooltip title={t`Locking prevents changes to this value in the RfQ`}>
                    <Lock fontSize="small" style={{ color: colorSystem.neutral[7] }} />
                </Tooltip>
            </StyledToggleButton>
            <StyledToggleButton value={false}>
                <LockOpen fontSize="small" style={{ color: colorSystem.neutral[7] }} />
            </StyledToggleButton>
        </StyledToggleButtonGroup>
    );
}
