import { t } from '@lingui/macro';
import { isPresent } from '@luminovo/commons';
import { SecondaryButton } from '@luminovo/design-system';
import { PCBGraphics, PCBV2, PcbServerErrorType } from '@luminovo/http-client';
import { keepPreviousData } from '@tanstack/react-query';
import React from 'react';
import { useHttpQuery } from '../../../../../resources/http/useHttpQuery';
import { getImagePreviewStatus, pcbGraphicsFromArray } from '../../../../../resources/pcb/pcbFunctions';
import { route } from '../../../../../utils/routes';
import { PcbSide, getColorPreference } from '../PCBColorSchemas';
import { SvgImageRenderer } from '../SvgImageRenderer';
import { PreviewLoading, PreviewLoadingError } from '../components/PreviewPlaceholders';
import { createSvgComponents } from './utils/createSvgComponents';
import { getInitialViewBoxCoordinates } from './utils/getInitialViewBoxCoordinates';

export const PcbPreviewer: React.FunctionComponent<{
    assemblyId: string;
    rfqId: string;
    pcb: PCBV2;
    pcbSide: PcbSide;
    onPcbSideChange: () => void;
    style?: React.CSSProperties;
}> = ({ assemblyId, rfqId, pcb, pcbSide, style, onPcbSideChange }) => {
    const status = getImagePreviewStatus(pcb);
    const {
        data: svgResponse,
        isLoading,
        isError,
    } = useHttpQuery(
        'GET /pcb/:pcbId/graphics',
        {
            pathParams: {
                pcbId: pcb.id,
            },
        },
        {
            enabled: status === 'done' || status === 'done-intermediate',
            placeholderData: keepPreviousData,
        },
    );

    const isLoadingOrRenderingNewPreview = status === 'loading' || isLoading;

    // If the svgResponse is present and the status is 'ok', render the preview.
    // We might be loading another version (with drills) but we don't want to stop showing this one until we have the latest
    //
    // We do this first to avoid flickering the image if we start loading the final render,
    // which would cause us to go from render -> loading -> render
    if (svgResponse?.status === 'ok') {
        const svgData = pcbGraphicsFromArray(svgResponse.data);

        if (!isPresent(svgData)) {
            return <GenericTechnicalError />;
        }

        return (
            <PcbImageRenderer
                pcb={pcb}
                pcbSide={pcbSide}
                onPcbSideChange={onPcbSideChange}
                style={style}
                svgData={svgData}
            />
        );
    }

    if (isLoadingOrRenderingNewPreview) {
        return <PreviewLoading />;
    }

    if (status === 'without-files') {
        return (
            <PreviewLoadingError
                title={t`Unfortunately, we can't render previews without files.`}
                description={t`If you upload PCB production files we can generate a realistic board preview for you...`}
            />
        );
    }

    if (status === 'failed' || !isPresent(svgResponse) || isError) {
        return <GenericTechnicalError />;
    }

    if (svgResponse.status === 'error') {
        const errorType = svgResponse.error;
        switch (errorType) {
            case PcbServerErrorType.MissingLayerstack:
                return (
                    <PreviewLoadingError
                        title={t`No preview possible due to a problem in the layerstack.`}
                        description={t`We are not able to render a PCB preview due to a mismatch in the copper layers. To resolve it, please use the File Manager.`}
                    >
                        <SecondaryButton
                            href={route('/rfqs/:rfqId/bom/assembly/:assemblyId/pcb/files', { assemblyId, rfqId })}
                            size="small"
                        >
                            {/* eslint-disable-next-line spellcheck/spell-checker */}
                            {t`Go to File Manager`}
                        </SecondaryButton>
                    </PreviewLoadingError>
                );

            case PcbServerErrorType.MissingOutline:
                return (
                    <PreviewLoadingError
                        title={t`No preview possible due to a problem in the outline.`}
                        description={t`We are not able to render a PCB preview due to a problem with the PCB's outline.`}
                    />
                );

            default:
                return <GenericTechnicalError />;
        }
    }

    // we shouldn't reach this, because the 'ok' status is handled at the beginning
    return <GenericTechnicalError />;
};

const PcbImageRenderer = ({
    pcb,
    pcbSide,
    onPcbSideChange,
    style,
    svgData,
}: {
    pcb: PCBV2;
    pcbSide: PcbSide;
    onPcbSideChange: () => void;
    style?: React.CSSProperties;
    svgData: PCBGraphics;
}) => {
    const colorPreference = getColorPreference(pcb);
    const outerViewBox = getInitialViewBoxCoordinates(svgData.outline);

    return (
        <SvgImageRenderer
            controlOptions={['flip']}
            viewBox={outerViewBox}
            onFlipClick={onPcbSideChange}
            svgProps={{
                style: {
                    boxSizing: 'border-box',
                    width: '100%',
                    minWidth: '570px',
                    height: '100%',
                    transform: `scale(${pcbSide === 'front' ? '1' : '-1'}, -1)`,
                    backgroundSize: '100%',
                    backgroundImage: 'radial-gradient(#eee, #777)',
                    ...style,
                },
            }}
        >
            {createSvgComponents(pcbSide, svgData, colorPreference)}
        </SvgImageRenderer>
    );
};

function GenericTechnicalError() {
    return (
        <PreviewLoadingError
            title={t`Unfortunately, we couldn't render your PCB...`}
            description={t`This could happened for technical reasons, however, you can still fill in the information on the right and get a quote!`}
        />
    );
}
