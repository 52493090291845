import { t } from '@lingui/macro';
import { BrokenImageIcon, CenteredLayout, NonIdealState } from '@luminovo/design-system';
import { PCBV2 } from '@luminovo/http-client';
import { Box, CircularProgress } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { isPcbSetupWithoutFiles } from '../../../resources/pcb/pcbFunctions';
import { usePcbOfAssembly } from '../../../resources/pcb/pcbHandlers';
import { ViewContext } from '../../Bom/components/ModuleTableData';
import { PcbActionToolbar } from '../components/FormActionToolbar';
import { PcbLoading } from '../components/PcbLoading';
import { PcbSidebarLayout } from '../components/PcbSidebarLayout';
import { CadFilesViewer } from './components/CadFilesViewer';

export function CadViewerTab({
    assemblyId,
    rfqId,
    isEditable,
    viewContext,
}: {
    assemblyId: string;
    rfqId: string;
    isEditable: boolean;
    viewContext: ViewContext;
}) {
    return (
        <PcbLoading assemblyId={assemblyId} rfqId={rfqId} isEditable={isEditable} viewContext={viewContext}>
            <CadViewerLayoutWrapper assemblyId={assemblyId} rfqId={rfqId} viewContext={viewContext} />
        </PcbLoading>
    );
}

function CadViewerLayoutWrapper({
    assemblyId,
    rfqId,
    viewContext,
}: {
    assemblyId: string;
    rfqId: string;
    viewContext: ViewContext;
}) {
    const { data: pcb } = usePcbOfAssembly({ assemblyId });

    if (!pcb) {
        return <CircularProgress />;
    }

    return <CadViewerLayout assemblyId={assemblyId} rfqId={rfqId} pcb={pcb} viewContext={viewContext} />;
}

function CadViewerLayout({
    assemblyId,
    rfqId,
    pcb,
    viewContext,
}: {
    assemblyId: string;
    rfqId: string;
    pcb: PCBV2;
    viewContext: ViewContext;
}) {
    const useFormReturn = useForm();

    return (
        <PcbSidebarLayout pcb={pcb} assemblyId={assemblyId} rfqId={rfqId} viewContext={viewContext}>
            <FormProvider {...useFormReturn}>
                <PcbActionToolbar
                    viewContext={viewContext}
                    rfqId={rfqId}
                    assemblyId={assemblyId}
                    pcb={pcb}
                    pageTitle={t`CAD viewer`}
                    isEditable={false}
                    isEditing={false}
                />
            </FormProvider>
            {isPcbSetupWithoutFiles(pcb) ? (
                <CenteredLayout style={{ height: 'calc(100vh - 155px)' }}>
                    <NonIdealState
                        title={t`No PCB files uploaded`}
                        description={t`Please upload your PCB files to use the CAD viewer.`}
                        Icon={BrokenImageIcon}
                    />
                </CenteredLayout>
            ) : (
                <Box style={{ height: 'calc(100vh - 155px)' }}>
                    {pcb.files && pcb.files.length > 0 ? (
                        <CadFilesViewer assemblyId={assemblyId} pcb={pcb} />
                    ) : (
                        <CenteredLayout>
                            <CircularProgress />
                        </CenteredLayout>
                    )}
                </Box>
            )}
        </PcbSidebarLayout>
    );
}
