import { t, Trans } from '@lingui/macro';
import { colorSystem, Toolbar } from '@luminovo/design-system';
import { Box, Grid, Typography } from '@mui/material';
import { useHistory } from 'react-router';
import { PageLayout } from '../../../components/PageLayout';
import { SpinnerWithBackdrop } from '../../../components/Spinners';
import { route } from '../../../utils/routes';
import {
    AssemblyOverviewBlankCard,
    AssemblyOverviewBlankCardProps,
    AssemblyOverviewCard,
    AssemblyOverviewCardProps,
} from './AssemblyOverviewCard';
import { useAssemblyOverviewPage } from './useAssemblyOverviewPage';

function isAssemblyWithDetails(
    assembly: AssemblyOverviewCardProps | AssemblyOverviewBlankCardProps,
): assembly is AssemblyOverviewCardProps {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return typeof (assembly as AssemblyOverviewCardProps).manufacturingAssemblyDetailsId === 'string';
}

const AssemblyOverviewPage = ({
    rfqId,
    forwardIfSingleAssembly,
}: {
    rfqId: string;
    forwardIfSingleAssembly: boolean;
}): JSX.Element => {
    const { assemblyOverviews, isLoading } = useAssemblyOverviewPage(rfqId);
    const history = useHistory();

    if (forwardIfSingleAssembly && assemblyOverviews?.length === 1 && isAssemblyWithDetails(assemblyOverviews[0])) {
        const assemblyDetailsRoute = route('/rfqs/:rfqId/manufacturing/:manufacturingAssemblyDetailsId', {
            rfqId,
            manufacturingAssemblyDetailsId: assemblyOverviews[0].manufacturingAssemblyDetailsId,
        });
        history.replace(assemblyDetailsRoute);
    }

    return (
        <PageLayout
            layout={'full-width'}
            header={<Toolbar breadcrumbs={[{ title: t`Manufacturing` }]} />}
            style={{ backgroundColor: colorSystem.neutral[1] }}
        >
            <Typography variant={'h1'} style={{ marginBottom: 24, color: colorSystem.neutral[8] }}>
                <Trans>Assembly overview</Trans>
            </Typography>
            {isLoading ? (
                <Box marginTop={'20px'}>
                    <SpinnerWithBackdrop noBackdrop={true} />
                </Box>
            ) : (
                <Grid container spacing={2}>
                    {assemblyOverviews.map((assembly, i) => (
                        <Grid item key={i}>
                            {isAssemblyWithDetails(assembly) ? (
                                <AssemblyOverviewCard {...assembly} />
                            ) : (
                                <AssemblyOverviewBlankCard {...assembly} />
                            )}
                        </Grid>
                    ))}
                </Grid>
            )}
        </PageLayout>
    );
};

export default AssemblyOverviewPage;
