/* eslint-disable camelcase */
import { plural, t, Trans } from '@lingui/macro';
import { isEqual, isPresent } from '@luminovo/commons';
import {
    colorSystem,
    Comment,
    Comments,
    Flexbox,
    SecondaryButton,
    TertiaryButton,
    Text,
} from '@luminovo/design-system';
import { CommentCategory, CommentDTO, CommentEntity, UserType } from '@luminovo/http-client';
import { ExpandMore, Info } from '@mui/icons-material';
import { styled } from '@mui/material';
import React from 'react';
import {
    useComments,
    useMutationCreateComment,
    useMutationDeleteComment,
    useMutationUpdateComment,
} from '../../resources/comments/commentsHandler';
import { useHttpQuery } from '../../resources/http/useHttpQuery';
import { useMutationAddUserAsContributor } from '../../resources/rfq/rfqHandler';
import { useContributors } from '../../resources/user/userHandler';
import { useCurrentUserDetailsContext, useUserType } from '../contexts/CurrentUserDetailsContext';
export type CommunicationsDrawerThread =
    | {
          commentType: 'DesignItem';
          category: CommentCategory;
          typeIds: string[];
          group?: {
              groupId: string;
              label: string;
          };
          rfqId: string;
      }
    | {
          commentType: 'Rfq';
          category: CommentCategory;
          typeIds: string;
          rfqId: string;
          group?: undefined;
      }
    | {
          commentType: 'Calculation';
          category: 'Internal';
          typeIds: string;
          calculationAssemblyDetailsId: string;
          group?: undefined;
          rfqId: string;
      }
    | {
          commentType: 'QuoteTracking';
          category: CommentCategory;
          typeIds: string;
          quoteTrackingId: string;
          group?: undefined;
          rfqId: string;
      }
    | {
          commentType: 'Assembly';
          category: CommentCategory;
          typeIds: string;
          assemblyId: string;
          group?: undefined;
      }
    | {
          commentType: 'QuoteRequest';
          category: CommentCategory;
          typeIds: string;
          group?: undefined;
      };

export const CommentsBox = React.memo(
    function CommentsBox({
        rfqId,
        thread,
        hideIfNoComments = false,
    }: {
        rfqId?: string;
        thread: CommunicationsDrawerThread;
        hideIfNoComments?: boolean;
    }) {
        const [showResolvedComments, setShowResolvedComments] = React.useState(false);
        const currentUser = useCurrentUserDetailsContext().user;
        const userType = useUserType();
        const { data: users = [] } = useHttpQuery(
            'GET /rfqs/:rfqId/possible-contributors',
            {
                pathParams: { rfqId: rfqId ?? '' },
            },
            {
                select: (res) => {
                    return res.data.sort((a, b) => (a.customer?.id ?? '').localeCompare(b.customer?.id ?? ''));
                },
                staleTime: 60_000,
                enabled: Boolean(rfqId) && (userType === UserType.Internal || userType === UserType.Customer),
            },
        );
        const entity: CommentEntity = {
            type: thread.commentType,
            data: thread.commentType === 'DesignItem' ? Array.from(thread.typeIds).sort() : thread.typeIds,
        };

        const { data: commentsFromBE = [] } = useComments(entity, thread.category);
        const comments = commentsFromBE.map((comment) => {
            const userNameOrUnknownUser =
                comment.created_by !== null
                    ? `${comment.created_by.first_name} ${comment.created_by.last_name}`
                    : t`Unknown user`;

            return {
                id: comment.id,
                resolvedAt: comment.resolved_at,
                updatedAt: comment.updated_at,
                author: {
                    label: userNameOrUnknownUser,
                    isCurrent: comment.created_by?.id === currentUser.id,
                },
                content: { type: 'string.v1' as const, content: comment.content },
            };
        });

        const { mutateAsync: deleteComment } = useMutationDeleteComment({ entity });
        const { mutateAsync: createComment } = useMutationCreateComment({ entity, category: thread.category });

        if (comments.length === 0 && hideIfNoComments) {
            return null;
        }

        const hasResolvedComments = comments.some((c) => isPresent(c.resolvedAt));
        const filteredComments = comments.filter((c) => showResolvedComments || !isPresent(c.resolvedAt));

        return (
            <>
                {rfqId && <AddAsContributorInfo rfqId={rfqId} comments={commentsFromBE} />}
                {hasResolvedComments && (
                    <Flexbox justifyContent="space-between">
                        <ToggleResolvedCommentsButton
                            comments={comments}
                            showResolvedComments={showResolvedComments}
                            setShowResolvedComments={setShowResolvedComments}
                        />
                        {showResolvedComments && <ReopenCommentsButton comments={comments} />}
                    </Flexbox>
                )}

                <Comments
                    storageKey={`comments-box:rfq:${rfqId}:${
                        Array.isArray(thread.typeIds) ? Array.from(thread.typeIds).sort().join(',') : thread.typeIds
                    }`}
                    overrides={{ Container: StyledContainer }}
                    comments={filteredComments}
                    currentUser={currentUser}
                    formatMention={(u) => `@${u.first_name} ${u.last_name}`}
                    formatUser={(u) => {
                        return { firstName: u.first_name, lastName: u.last_name, email: u.email };
                    }}
                    formatOrg={(u) => u.email}
                    users={users}
                    onDelete={deleteComment}
                    onEdit={() => {}}
                    onSubmitNewComment={createComment}
                />
            </>
        );
    },
    (prev, next) => {
        return isEqual(prev, next);
    },
);

export const AssemblyCommentsBox = React.memo(
    function AssemblyCommentsBox({
        assemblyId,
        thread,
        hideIfNoComments = false,
    }: {
        assemblyId: string;
        thread: CommunicationsDrawerThread;
        hideIfNoComments?: boolean;
    }) {
        const [showResolvedComments, setShowResolvedComments] = React.useState(false);
        const currentUser = useCurrentUserDetailsContext().user;
        const userType = useUserType();
        const { data: users = [] } = useHttpQuery(
            'GET /assemblies/:assemblyId/commentators',
            {
                pathParams: { assemblyId },
            },
            {
                select: (res) => {
                    return res.data.sort((a, b) => (a.customer?.id ?? '').localeCompare(b.customer?.id ?? ''));
                },
                staleTime: 60_000,
                enabled: userType === UserType.Internal || userType === UserType.Customer,
            },
        );
        const entity: CommentEntity = {
            type: thread.commentType,
            data: thread.commentType === 'DesignItem' ? Array.from(thread.typeIds).sort() : thread.typeIds,
        };

        const { data: commentsFromBE = [] } = useComments(
            entity,
            thread.category,
            userType === UserType.Customer || userType === UserType.Internal,
        );
        const comments = commentsFromBE.map((comment) => {
            const userNameOrUnknownUser =
                comment.created_by !== null
                    ? `${comment.created_by.first_name} ${comment.created_by.last_name}`
                    : t`Unknown user`;

            return {
                id: comment.id,
                resolvedAt: comment.resolved_at,
                updatedAt: comment.updated_at,
                author: {
                    label: userNameOrUnknownUser,
                    isCurrent: comment.created_by?.id === currentUser.id,
                },
                content: { type: 'string.v1' as const, content: comment.content },
                createdBy: comment.created_by,
            };
        });

        const { mutateAsync: deleteComment } = useMutationDeleteComment({ entity });
        const { mutateAsync: createComment } = useMutationCreateComment({ entity, category: thread.category });

        if (comments.length === 0 && hideIfNoComments) {
            return null;
        }

        const hasResolvedComments = comments.some((c) => isPresent(c.resolvedAt));
        const filteredComments = comments.filter((c) => showResolvedComments || !isPresent(c.resolvedAt));

        return (
            <>
                {hasResolvedComments && (
                    <Flexbox justifyContent="space-between">
                        <ToggleResolvedCommentsButton
                            comments={comments}
                            showResolvedComments={showResolvedComments}
                            setShowResolvedComments={setShowResolvedComments}
                        />
                        {showResolvedComments && <ReopenCommentsButton comments={comments} />}
                    </Flexbox>
                )}

                <Comments
                    storageKey={`comments-box:assembly:${assemblyId}:${
                        Array.isArray(thread.typeIds) ? Array.from(thread.typeIds).sort().join(',') : thread.typeIds
                    }`}
                    overrides={{ Container: StyledContainer }}
                    comments={filteredComments}
                    currentUser={{
                        ...currentUser,
                        customer: currentUser.customer ?? null,
                    }}
                    formatMention={(u) => `@${u.first_name} ${u.last_name}`}
                    formatUser={(u) => {
                        return { firstName: u.first_name, lastName: u.last_name, email: u.email };
                    }}
                    formatOrg={(u) => u.email}
                    users={users}
                    onDelete={deleteComment}
                    onEdit={() => {}}
                    onSubmitNewComment={createComment}
                />
            </>
        );
    },
    (prev, next) => {
        return isEqual(prev, next);
    },
);

function ToggleResolvedCommentsButton({
    comments,
    showResolvedComments,
    setShowResolvedComments,
}: {
    comments: Comment[];
    showResolvedComments: boolean;
    setShowResolvedComments: React.Dispatch<React.SetStateAction<boolean>>;
}) {
    const numberOfResolvedComments = comments.filter((c) => isPresent(c.resolvedAt)).length;

    return (
        <TertiaryButton
            size="medium"
            onClick={() => setShowResolvedComments((show) => !show)}
            endIcon={
                <ExpandMore
                    style={{
                        transform: showResolvedComments ? 'rotate(0deg)' : 'rotate(-90deg)',
                        transition: 'transform 0.1s linear 0s',
                    }}
                />
            }
            style={{ color: colorSystem.neutral[6] }}
        >
            {plural(numberOfResolvedComments, {
                one: `${numberOfResolvedComments} resolved comment`,
                other: `${numberOfResolvedComments} resolved comments`,
            })}
        </TertiaryButton>
    );
}

function ReopenCommentsButton({ comments }: { comments: Comment[] }) {
    const { mutateAsync: updateComment, isPending: isLoadingUpdate } = useMutationUpdateComment();

    const onReopen = React.useCallback(async () => {
        return Promise.all(comments.map((comment) => updateComment({ id: comment.id, resolved: false })));
    }, [updateComment, comments]);

    return (
        <TertiaryButton size="medium" onClick={() => onReopen()} disabled={isLoadingUpdate}>
            <Trans>Reopen comments</Trans>
        </TertiaryButton>
    );
}

const AddAsContributorInfo: React.FunctionComponent<{ rfqId: string; comments: CommentDTO[] }> = ({
    rfqId,
    comments,
}): JSX.Element => {
    const userId = useCurrentUserDetailsContext().user.id;
    const userType = useUserType();
    const { mutateAsync, isPending: isLoading } = useMutationAddUserAsContributor(rfqId);
    const { data: contributorsData } = useContributors(rfqId, { enabled: userType === UserType.Internal });
    const contributors = [...(contributorsData?.external ?? []), ...(contributorsData?.internal ?? [])];

    const hasCommented = comments.some((e) => isPresent(e.created_by) && e.created_by.id === userId);
    const isNoContributor = contributors?.every((c) => c.id !== userId) ?? false;
    const hasCommentedAndIsNotContributor = hasCommented && isNoContributor;

    if (!hasCommentedAndIsNotContributor || userType !== UserType.Internal) {
        return <></>;
    }

    return (
        <Flexbox paddingY={'8px'}>
            <Flexbox
                gap={8}
                borderRadius={'8px'}
                bgcolor={colorSystem.neutral[1]}
                padding={'12px'}
                border={`1px solid ${colorSystem.neutral[2]}`}
            >
                <Info style={{ cursor: 'pointer', color: colorSystem.blue[6], alignSelf: 'start' }} fontSize="small" />
                <Flexbox flexDirection="column" gap={8}>
                    <Text variant="body-small-semibold">
                        <Trans>Do you want to add yourself as a contributor to this RfQ?</Trans>
                    </Text>
                    <Text variant="body-small">
                        <Trans>
                            You will be notified about new activity in the RfQ and other users can tag you in the
                            comments.
                        </Trans>
                    </Text>
                    <Flexbox justifyContent={'flex-end'}>
                        <SecondaryButton size="medium" onClick={() => mutateAsync({ userId })} disabled={isLoading}>
                            <Trans>Add yourself as a contributor</Trans>
                        </SecondaryButton>
                    </Flexbox>
                </Flexbox>
            </Flexbox>
        </Flexbox>
    );
};

const StyledContainer = styled(Flexbox)({
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
    border: 'none',
    marginLeft: -8,
});
