import React from 'react';
import { DataFunc, Mention, MentionsInput, MentionsInputProps, SuggestionDataItem } from 'react-mentions';
import { INTER, colorSystem } from '../../../theme';
import { Text } from '../../Text';

interface Props<TUser extends { id: string }> extends Omit<MentionsInputProps, 'children' | 'style'> {
    users: TUser[];

    formatMention: (user: TUser) => string;

    formatOrg: (user: TUser) => string;
}

const style = {
    fontFamily: INTER,
    flexGrow: 1,

    control: {
        fontSize: 14,
        fontWeight: 'normal',
        maxHeight: 200,
        overflow: 'auto',
    },

    '&multiLine': {
        control: {},
        highlighter: {
            padding: 0,
            border: '0px solid transparent',
        },
        input: {
            // padding and margins must be zero
            // otherwise the mentions aren't rendered properly
            // this seems to be a bug in react-mentions
            margin: 0,
            padding: 0,
            borderRadius: 0,
            border: `none`,
            outline: 'none',
        },
    },

    suggestions: {
        zIndex: 10,
        border: `1px solid ${colorSystem.neutral[2]}`,
        backgroundColor: 'white',
        borderRadius: 4,
        maxHeight: 300,
        overflowY: 'auto',
        list: {
            fontSize: 14,
        },
        item: {
            padding: '8px',
            borderBottom: `1px solid ${colorSystem.neutral[2]}`,
            '&focused': {
                backgroundColor: colorSystem.primary[1],
            },
        },
    },
};

export function CommentsInput<TUser extends { id: string }>({
    users,
    formatMention,
    formatOrg,
    onChange,
    ...props
}: Props<TUser>): JSX.Element {
    const dataFunc: DataFunc = React.useCallback(
        async (query, callback): Promise<void> => {
            const result: SuggestionDataItem[] = users
                .map((user) => {
                    return {
                        id: user.id,
                        display: formatMention(user),
                    };
                })

                .filter((r) =>
                    r.display
                        .toLowerCase()
                        .normalize('NFD')
                        .replace(/\p{Diacritic}/gu, '')
                        .includes(
                            query
                                .toLowerCase()
                                .normalize('NFD')
                                .replace(/\p{Diacritic}/gu, ''),
                        ),
                );

            callback(result);
        },
        [users, formatMention],
    );

    return (
        <MentionsInput style={style} spellCheck={false} onChange={onChange} ignoreAccents={true} {...props}>
            <Mention
                appendSpaceOnAdd={true}
                trigger={'@'}
                data={dataFunc}
                renderSuggestion={({ id }) => {
                    const user = users.find((u) => u.id === id);
                    if (!user) {
                        return null;
                    }

                    return (
                        <Text>
                            {formatMention(user).replace(/^@/, '')}
                            <br />
                            <small>{formatOrg(user)}</small>
                        </Text>
                    );
                }}
                style={{
                    backgroundColor: `${colorSystem.primary[1]}aa`,
                    borderRadius: 4,
                    color: colorSystem.primary[8],
                    position: 'relative',
                    zIndex: 1,
                    textShadow:
                        '1px 1px 1px rgba(255,255,255,0.5), 1px -1px 1px rgba(255,255,255,0.5), -1px 1px 1px rgba(255,255,255,0.5), -1px -1px 1px rgba(255,255,255,0.5)',
                    pointerEvents: 'none',
                }}
            />
        </MentionsInput>
    );
}
