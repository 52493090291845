/* eslint-disable camelcase */
import { assertUnreachable, formatDecimal, uniqBy } from '@luminovo/commons';
import {
    ButtonGroupItem,
    colorSystem,
    Flexbox,
    SecondaryButton,
    TanStackTable,
    Text,
    Toolbar,
} from '@luminovo/design-system';
import { PartIdTypes, PartLite, SupplierSelectionStrategyDTO } from '@luminovo/http-client';
import { Box, ButtonGroup } from '@mui/material';
import React from 'react';
import { FormContainer } from '../../../../../components/formLayouts/FormContainer';
import { LayoutCard } from '../../../../../components/LayoutCard';
import { PageLayoutCollapsibleSidebar } from '../../../../../components/PageLayoutCollapsibleSidebar';
import { useHttpMutation } from '../../../../../resources/mutation/useHttpMutation';
import { route, UrlParams } from '../../../../../utils/routes';
import { FormItemAwardScenario } from '../../../../Negotiations/pages/QuoteRequestAssignPage/FormItem';
import { NavigationSidebarSourcing } from '../../NavigationSidebarSourcing';
import {
    ButtonAddToQuoteRequest,
    FormItemSelectionStrategy,
    FormItemSuppliers,
    FormItemSupplierSelectionStrategy,
} from './FormItem';
import { useManufacturersTable } from './TableManufacturers';
import { usePartOptionsTable } from './TablePartOptions';
import { QuoteRequestAssignmentFormValues } from './types';

function convertToSupplierSelectionStrategy(form: QuoteRequestAssignmentFormValues): SupplierSelectionStrategyDTO {
    switch (form.supplierSelectionStrategy.type) {
        case 'SupplierPartTypeMatches':
            return { type: 'SupplierPartTypeMatches' as const };
        case 'SupplierLineCard':
            return {
                type: 'SupplierLineCard' as const,
                part_option_selection_strategy: form.supplierSelectionStrategy.partOptionSelectionStrategy,
            };
    }
}

function convertPartLiteToPartDTO(part: PartLite) {
    const { kind } = part;

    if (kind === 'OffTheShelf') {
        return { type: PartIdTypes.OffTheShelf, data: part.id };
    }
    if (kind === 'Generic') {
        return { type: PartIdTypes.Generic, data: part.id };
    }
    if (kind === 'Ipn') {
        return { type: PartIdTypes.Ipn, data: part.id };
    }
    if (kind === 'Custom') {
        return { type: PartIdTypes.Custom, data: part.id };
    }
    if (kind === 'CustomComponent') {
        return { type: PartIdTypes.CustomComponent, data: part.id };
    }
    if (kind === 'RawSpecification') {
        return { type: PartIdTypes.RawSpecification, data: part.id };
    }
    if (kind === 'Unknown') {
        throw new Error('Unknown part');
    }

    assertUnreachable(kind);
}

function InnerForm({ rfqId, negotiationId, lineItems }: { rfqId: string; negotiationId: number; lineItems: number[] }) {
    const [groupMode, setGroupMode] = React.useState<'manufacturers' | 'partOptions'>('manufacturers');
    const { table: manufacturersTable, isLoading: isManufacturersLoading } = useManufacturersTable(
        negotiationId,
        lineItems,
    );
    const { table: partOptionsTable, isLoading: isPartOptionsLoading } = usePartOptionsTable(negotiationId, lineItems);

    const { mutateAsync } = useHttpMutation('POST /quote-request/draft', {
        snackbarMessage: 'Successfully created quote requests',
        onSuccess: () => {
            manufacturersTable.resetRowSelection();
            partOptionsTable.resetRowSelection();
        },
    });

    const { manufacturersCount, partOptionsCount } = React.useMemo(() => {
        return {
            manufacturersCount: isManufacturersLoading ? undefined : manufacturersTable.getCoreRowModel().rows.length,
            partOptionsCount: isPartOptionsLoading ? undefined : partOptionsTable.getCoreRowModel().rows.length,
        };
    }, [manufacturersTable, partOptionsTable, isManufacturersLoading, isPartOptionsLoading]);

    const onSubmit = React.useCallback(
        async (form: QuoteRequestAssignmentFormValues) => {
            const getItems = () => {
                switch (groupMode) {
                    case 'manufacturers':
                        const manufacturerRows = manufacturersTable
                            .getSelectedRowModel()
                            .rows.flatMap((row) => (row.original.type === 'row' ? [row.original] : []));

                        const itemsFromManufacturers = manufacturerRows.flatMap((row) =>
                            row.items.map((i) => ({
                                part: convertPartLiteToPartDTO(i.part),
                                part_option_id: i.part_option_id,
                            })),
                        );

                        return uniqBy(
                            itemsFromManufacturers,
                            (i) => `${i.part.type}-${i.part.data}-${i.part_option_id}`,
                        );
                    case 'partOptions':
                        const partOptionRows = partOptionsTable
                            .getSelectedRowModel()
                            .rows.flatMap((row) => (row.original.type === 'row' ? [row.original] : []));

                        const itemsFromPartOptions = partOptionRows.map((row) => ({
                            part: convertPartLiteToPartDTO(row.part),
                            part_option_id: row.partOptionId,
                        }));

                        return uniqBy(itemsFromPartOptions, (i) => `${i.part.type}-${i.part.data}-${i.part_option_id}`);
                }
            };

            await mutateAsync({
                requestBody: {
                    supplier_selection_strategy: convertToSupplierSelectionStrategy(form),
                    items: getItems(),
                    supplier_ids: form.selectedSuppliers.map((supplier) => supplier.id.toString()),
                },
            });
        },
        [mutateAsync, groupMode, manufacturersTable, partOptionsTable],
    );

    const defaultValues: QuoteRequestAssignmentFormValues = {
        negotiationId,
        selectedSuppliers: [],
        supplierSelectionStrategy: {
            type: 'SupplierPartTypeMatches',
        },
    };

    const manufacturerRowSelection = manufacturersTable.getState().rowSelection;
    const partRowSelection = partOptionsTable.getState().rowSelection;
    const disabled = React.useMemo(() => {
        switch (groupMode) {
            case 'manufacturers':
                return Object.values(manufacturerRowSelection).every((value) => !value);
            case 'partOptions':
                return Object.values(partRowSelection).every((value) => !value);
        }
    }, [groupMode, manufacturerRowSelection, partRowSelection]);

    return (
        <FormContainer onSubmit={onSubmit} defaultValues={defaultValues} UNSAFE_disableStablePropCheck>
            <Box display="grid" gridTemplateColumns="380px 5fr" gap={'16px'} height={'100%'}>
                <LayoutCard title={undefined}>
                    <Flexbox justifyContent="space-between">
                        <Flexbox flexDirection="column" gap="8px">
                            <Text variant="body" color={colorSystem.neutral[8]}>
                                Negotiation line items
                            </Text>
                            <Text variant="h3">{formatDecimal(lineItems.length)}</Text>
                        </Flexbox>
                        <Flexbox flexDirection="column" gap="8px">
                            <Text variant="body" color={colorSystem.neutral[8]}>
                                Manufacturers
                            </Text>
                            <Text variant="h3">{formatDecimal(manufacturersTable.getCoreRowModel().rows.length)}</Text>
                        </Flexbox>
                    </Flexbox>
                    <FormItemSupplierSelectionStrategy />
                    <FormItemSelectionStrategy />
                    <FormItemSuppliers />
                    <FormItemAwardScenario />
                    <ButtonAddToQuoteRequest disabled={disabled} groupMode={groupMode} />
                </LayoutCard>
                <LayoutCard
                    title={
                        <Flexbox justifyContent="space-between" width="100%">
                            <ButtonGroup size="medium">
                                <ButtonGroupItem
                                    size="medium"
                                    selected={groupMode === 'manufacturers'}
                                    onClick={() => setGroupMode('manufacturers')}
                                    count={manufacturersCount}
                                >
                                    Manufacturer
                                </ButtonGroupItem>
                                <ButtonGroupItem
                                    size="medium"
                                    selected={groupMode === 'partOptions'}
                                    onClick={() => setGroupMode('partOptions')}
                                    count={partOptionsCount}
                                >
                                    Quote request line items
                                </ButtonGroupItem>
                            </ButtonGroup>
                        </Flexbox>
                    }
                >
                    <Box height={'calc(100% - 48px)'}>
                        {groupMode === 'manufacturers' && <TanStackTable table={manufacturersTable} />}
                        {groupMode === 'partOptions' && <TanStackTable table={partOptionsTable} />}
                    </Box>
                </LayoutCard>
            </Box>
        </FormContainer>
    );
}

function ToolbarInner({ rfqId, negotiationId }: { rfqId: string; negotiationId: number }) {
    return (
        <Toolbar
            breadcrumbs={[
                {
                    title: `Sourcing negotiation`,
                    href: route(`/rfqs/:rfqId/sourcing/negotiations/:negotiationId/line-items`, {
                        rfqId,
                        negotiationId,
                    }),
                },
                {
                    title: `Create quote requests`,
                },
            ]}
        >
            <SecondaryButton
                size="medium"
                href={route('/rfqs/:rfqId/sourcing/negotiations/:negotiationId/quote-requests', {
                    rfqId,
                    negotiationId,
                })}
            >
                Go to quote requests
            </SecondaryButton>
        </Toolbar>
    );
}

export default function QuoteRequestAssignPage({
    pathParams,
    queryParams,
}: UrlParams<'/rfqs/:rfqId/sourcing/negotiations/:negotiationId/quote-requests/assign'>) {
    const { rfqId } = pathParams;
    const negotiationId = Number(pathParams.negotiationId);
    const lineItems = queryParams.lineItems?.split(',').map(Number) ?? [];

    return (
        <PageLayoutCollapsibleSidebar
            sidebar={<NavigationSidebarSourcing rfqId={rfqId} />}
            header={<ToolbarInner rfqId={rfqId} negotiationId={negotiationId} />}
            layoutVariant="fullWidth"
        >
            <InnerForm rfqId={rfqId} negotiationId={negotiationId} lineItems={lineItems} />
        </PageLayoutCollapsibleSidebar>
    );
}
