import {
    AvailableSpaceContainer,
    CenteredLayout,
    Column,
    DataTable,
    ScrollableTableContainer,
    useDataTableState,
} from '@luminovo/design-system';
import { ActivityDTO } from '@luminovo/http-client';
import { sortByProcessThenCategoryThenName } from '@luminovo/manufacturing-core';
import { CircularProgress } from '@mui/material';
import React, { useMemo } from 'react';
import { useHttpQuery } from '../../../resources/http/useHttpQuery';
import {
    DriverColumnContext,
    SitesColumnContext,
    getCategoryColumn,
    getDescriptionColumn,
    getInternalNumberColumn,
    getNameColumn,
    getProcessColumn,
    getSitesColumn,
    useDriverColumnContext,
    useSitesColumnContext,
} from '../shared/columns';
import {
    ResourcesColumnContext,
    getDriverColumn,
    getLevelColumn,
    getPerPanelActivityColumn,
    getResourcesColumn,
    getTimeCalculationColumn,
    useResourcesContext,
} from './columns';
import { ActivityMenuColumnContext, getActivityMenuColumn, useActivityMenuColumnContext } from './columns/menuColumn';
import { getActivityStatusColumn } from './columns/statusColumn';
import {
    ActivityFormulasContext,
    TimeCalculationSummaryContext,
    useActivityFormulasContext,
    useTimeCalculationSummaryContext,
} from './columns/utils';

type ActivityDatabaseTableContext = ResourcesColumnContext &
    SitesColumnContext &
    DriverColumnContext &
    ActivityFormulasContext &
    TimeCalculationSummaryContext &
    ActivityMenuColumnContext;

// TODO: Use 'combine': https://tanstack.com/query/v5/docs/react/reference/useQueries#combine
const useActivitiesDatabaseContext = (activities: ActivityDTO[]): ActivityDatabaseTableContext => {
    const resourcesContext = useResourcesContext();
    const driversContext = useDriverColumnContext();
    const sitesContext = useSitesColumnContext();
    const formulasContext = useActivityFormulasContext(activities);
    const timeCalculationSummaryContext = useTimeCalculationSummaryContext(activities);
    const activityMenuColumnContext = useActivityMenuColumnContext();

    return useMemo(() => {
        const sharedContext: ActivityDatabaseTableContext = {
            ...resourcesContext,
            ...driversContext,
            ...sitesContext,
            ...formulasContext,
            ...timeCalculationSummaryContext,
            ...activityMenuColumnContext,
        };

        return sharedContext;
    }, [
        resourcesContext,
        driversContext,
        sitesContext,
        formulasContext,
        timeCalculationSummaryContext,
        activityMenuColumnContext,
    ]);
};

const ActivitiesDatabaseTable = ({ query }: { query: string }): React.ReactElement => {
    const { data, isLoading: isLoadingActivities } = useHttpQuery('GET /activities', { queryParams: {} });
    const activities: ActivityDTO[] = data?.data ?? [];

    const sharedContext = useActivitiesDatabaseContext(activities);

    const columns: Column<ActivityDTO, ActivityDatabaseTableContext>[] = [
        getActivityStatusColumn(),
        getInternalNumberColumn(),
        getNameColumn(),
        getSitesColumn(sharedContext),
        getPerPanelActivityColumn(),
        getResourcesColumn(activities, sharedContext),
        getProcessColumn(),
        getCategoryColumn(),
        getTimeCalculationColumn(),
        getLevelColumn(),
        getDriverColumn(sharedContext),
        getDescriptionColumn(),
        getActivityMenuColumn(),
    ];

    const sortedActivities = sortByProcessThenCategoryThenName(activities);

    const indexItemData = (activity: ActivityDTO, sharedContext: ActivityDatabaseTableContext): string[] => {
        return [activity.name, activity.internal_number ?? '', activity.description ?? ''];
    };

    const dataTableState = useDataTableState({
        persistenceId: 'activitiesDatabaseTable',
        columns,
        sharedContext,
        items: sortedActivities,
        paginationOptions: {
            showPagination: true,
            persistPagination: true,
        },
        query,
        searchOptions: {
            idExtractor: (activity: ActivityDTO): string => activity.id,
            contentSearchOptions: {
                indexingStrategy: 'data-content',
                indexItemData,
            },
        },
    });

    return isLoadingActivities ? (
        <CenteredLayout height={'30vh'}>
            <CircularProgress />
        </CenteredLayout>
    ) : (
        <DataTable
            size="medium"
            key={'activitiesDatabaseTable'}
            tableState={dataTableState}
            overrides={{ Container: AvailableSpaceContainer, TableContainer: ScrollableTableContainer }}
        />
    );
};

export default ActivitiesDatabaseTable;
