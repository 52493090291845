import { isPresent, throwErrorUnlessProduction } from '@luminovo/commons';
import React from 'react';

type SupportedPrimitive = number | boolean | string;
type SupportedArray = SupportedPrimitive[];
type SupportedValue = SupportedPrimitive | SupportedArray;

type SetValueAction<T> = T | ((prevValue: T) => T);

export function usePersistedState<T extends SupportedValue>(
    storageKey: string,
    initialValue: T,
    storage: Storage = sessionStorage,
): [T, (newState: SetValueAction<T>) => void] {
    const [state, setState] = React.useState<T>(() => {
        const storedItem = storage.getItem(storageKey);

        // If nothing stored, return initial directly
        if (!isPresent(storedItem)) {
            return initialValue;
        }

        switch (typeof initialValue) {
            case 'boolean':
                return (storedItem === 'true') as T;
            case 'number':
                const parsed = parseFloat(storedItem);
                return (isNaN(parsed) ? initialValue : parsed) as T;
            case 'string':
                return storedItem as T;
            case 'object':
                if (Array.isArray(initialValue)) {
                    try {
                        const parsedArray = JSON.parse(storedItem);
                        if (Array.isArray(parsedArray)) {
                            return parsedArray as T;
                        } else {
                            throwErrorUnlessProduction(
                                new Error(`Failed to parse array from storage for key ${storageKey}`),
                            );
                        }
                    } catch {
                        throwErrorUnlessProduction(
                            new Error(`Failed to parse array from storage for key ${storageKey}`),
                        );
                    }
                } else {
                    throwErrorUnlessProduction(new Error(`Unsupported object type for key ${storageKey}`));
                }
                break;
            default:
                throwErrorUnlessProduction(new Error(`Unsupported type for key ${storageKey}`));
        }

        return initialValue as T;
    });

    const handler = React.useCallback(
        (valueAction: SetValueAction<T>) => {
            setState((prev) => {
                const value =
                    typeof valueAction === 'function' ? (valueAction as (prevValue: T) => T)(prev) : valueAction;

                if (!isPresent(value)) {
                    storage.removeItem(storageKey);
                    return initialValue;
                }

                switch (typeof value) {
                    case 'boolean':
                        storage.setItem(storageKey, value.toString());
                        break;
                    case 'number':
                        storage.setItem(storageKey, value.toString());
                        break;
                    case 'string':
                        storage.setItem(storageKey, value);
                        break;
                    case 'object':
                        if (Array.isArray(value)) {
                            storage.setItem(storageKey, JSON.stringify(value));
                        } else {
                            throwErrorUnlessProduction(new Error(`Unsupported object type for key ${storageKey}`));
                        }
                        break;
                }

                return value;
            });
        },
        [storageKey, storage, initialValue],
    );

    return [state, handler];
}
