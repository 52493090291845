import { Trans, t } from '@lingui/macro';
import { Flexbox, MenuButton, Text, colorSystem } from '@luminovo/design-system';
import { Delete, Download, MoreHoriz } from '@mui/icons-material';
import { ListItemIcon, MenuItem } from '@mui/material';
import { useHttpQuery } from '../../../resources/http/useHttpQuery';
import { useHttpMutation } from '../../../resources/mutation/useHttpMutation';

export const PnpFileActionButton = ({
    assemblyId,
    isRfqEditable,
}: {
    assemblyId: string;
    isRfqEditable: boolean;
}): JSX.Element | null => {
    const { mutateAsync, isPending: isLoading } = useHttpMutation('DELETE /pnp/file', {
        snackbarMessage: t`Pick and place file deleted`,
    });

    const { data: pnpFileUrl } = useHttpQuery(
        'GET /pnp/file',
        { queryParams: { assembly: assemblyId } },
        { select: (response) => response.url ?? undefined },
    );

    if (!(pnpFileUrl || isRfqEditable)) {
        return null;
    }

    return (
        <MenuButton
            appearance="secondary"
            icon={<MoreHoriz fontSize="inherit" />}
            size="small"
            label={<Flexbox>{t`Options`}</Flexbox>}
        >
            {isRfqEditable && (
                <MenuItem
                    onClick={() => {
                        mutateAsync({ queryParams: { assembly: assemblyId } });
                    }}
                >
                    <ListItemIcon>
                        <Delete htmlColor={colorSystem.red[7]} />
                    </ListItemIcon>
                    <Text color={colorSystem.red[7]}>
                        {isLoading ? <Trans>Deleting...</Trans> : <Trans>Delete PnP file</Trans>}
                    </Text>
                </MenuItem>
            )}

            {pnpFileUrl && (
                <MenuItem onClick={() => window.open(pnpFileUrl)}>
                    <ListItemIcon>
                        <Download />
                    </ListItemIcon>
                    <Trans>Download</Trans>
                </MenuItem>
            )}
        </MenuButton>
    );
};
