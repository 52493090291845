import * as React from 'react';
import { useSuspenseHttpQuery } from '../../../resources/http/useHttpQuery';

export function useCustomFavicon(): void {
    const { data: assets } = useSuspenseHttpQuery(
        'GET /organization-settings/assets',
        {},
        {
            staleTime: Infinity,
        },
    );

    React.useEffect(() => {
        const favicon = assets?.favicon;
        const link = document.querySelector<HTMLLinkElement>('link[rel=icon]');
        if (!link) {
            return;
        }
        link.href = favicon || '/favicon.png';
    }, [assets]);
}
