import { t, Trans } from '@lingui/macro';
import { formatDays } from '@luminovo/commons';
import {
    ButtonGroup,
    ButtonGroupItem,
    colorSystem,
    Flexbox,
    Message,
    PrimaryButton,
    SecondaryButton,
    Tag,
    Text,
} from '@luminovo/design-system';
import {
    ManufacturingScenarioTemplateDTO,
    ManufacturingScenarioTemplatePatchDTO,
    ManufacturingScenarioTemplateStatusDTO,
} from '@luminovo/http-client';
import { Edit } from '@mui/icons-material';
import { Typography } from '@mui/material';
import React from 'react';
import ConfirmationDialogBox from '../../../components/dialogBox/ConfirmationDialogBox';
import InformationDialogBox from '../../../components/dialogBox/InformationDialogBox';
import { transEnum } from '../../../components/localization/TransEnum';
import { SitesWithIconFetching } from '../../../components/Site';
import { manufacturingScenarioTemplateStatusEnumPublicTranslations } from '../../../resources/manufacturingScenarioTemplates/manufacturingScenarioTemplatesFrontendTypes';
import {
    useMutationUpdateTemplate,
    useTemplateHasInactiveActivitiesOrExpenses,
} from '../../../resources/manufacturingScenarioTemplates/manufacturingScenarioTemplatesHandler';
import { useCanEditManufacturingDatabase } from '../utils/useCanEditManufacturingDatabase';
import { useActivitySelectionDialog } from './components/Activities/ActivitySelectionDialog';
import { SortableActivitiesTable } from './components/Activities/SortableActivitiesTable';
import { useExpenseSelectionDialog } from './components/Expenses/ExpenseSelectionDialog';
import { SortableExpensesTable } from './components/Expenses/SortableExpensesTable';
import { ManufacturingScenarioTemplateActionMenu } from './components/ManufacturingScenarioTemplateActionMenu';
import { useIsTemplateUsedInCustomerPortalSettings } from './utils/useIsTemplateUsedInCustomerPortalSettings';

const TemplateNotes = ({ notes }: { notes: string }): JSX.Element => {
    return (
        <>
            <Text variant="h4">
                <Trans>Notes</Trans>:
            </Text>
            <Text variant="body">{notes}</Text>
        </>
    );
};

const TemplateManufacturingLeadTime = ({ manufacturingLeadTime }: { manufacturingLeadTime: number }): JSX.Element => {
    return (
        <Flexbox gap={4} alignItems={'baseline'}>
            <Text variant="h4" style={{ color: colorSystem.neutral[7] }}>
                <Trans>Manufacturing lead time</Trans>:
            </Text>
            <Text variant="body">{formatDays(manufacturingLeadTime)}</Text>
        </Flexbox>
    );
};

const TemplateStatusTag = ({ status }: { status: ManufacturingScenarioTemplateStatusDTO }): JSX.Element => {
    return (
        <Tag
            color={status === 'Inactive' ? 'neutral' : 'green'}
            label={transEnum(status, manufacturingScenarioTemplateStatusEnumPublicTranslations)}
        />
    );
};

const TemplateStatusToggleButton = ({
    template,
    setIsStatusConfirmationDialogVisible,
    setIsCannotBeDeletedDialogOpen,
    switchStatus,
}: {
    template: ManufacturingScenarioTemplateDTO;
    setIsStatusConfirmationDialogVisible: (isVisible: boolean) => void;
    setIsCannotBeDeletedDialogOpen: (isDialogOpen: boolean) => void;
    switchStatus: (newStatus: ManufacturingScenarioTemplateStatusDTO) => Promise<void>;
}): JSX.Element => {
    const { hasInactiveEntity } = useTemplateHasInactiveActivitiesOrExpenses(template);
    const { isTemplateUsedInCustomerPortalSettings } = useIsTemplateUsedInCustomerPortalSettings(template.id);

    if (template.status === 'Inactive') {
        return (
            <SecondaryButton
                onClick={() =>
                    hasInactiveEntity ? setIsStatusConfirmationDialogVisible(true) : switchStatus('Active')
                }
                size="medium"
            >
                {t`Activate`}
            </SecondaryButton>
        );
    }

    return (
        <SecondaryButton
            onClick={() => {
                if (isTemplateUsedInCustomerPortalSettings) {
                    setIsCannotBeDeletedDialogOpen(true);
                } else {
                    switchStatus('Inactive');
                }
            }}
            size="medium"
        >
            {t`Deactivate`}
        </SecondaryButton>
    );
};

const ManufacturingScenarioTemplateView = ({
    template,
}: {
    template: ManufacturingScenarioTemplateDTO;
}): JSX.Element => {
    const [displayMode, setDisplayMode] = React.useState<'activities' | 'expenses'>('activities');
    const { openDialog: openActivityDialog } = useActivitySelectionDialog({
        orderedActivityIds: template.ordered_activities,
        manufacturingScenarioTemplateId: template.id,
    });
    const { openDialog: openExpensesDialog } = useExpenseSelectionDialog({
        orderedExpenseIds: template.ordered_expenses,
        manufacturingScenarioTemplateId: template.id,
    });

    const { mutateAsync } = useMutationUpdateTemplate({ templateId: template.id });
    const onClickStatusSwitch = async (newStatus: ManufacturingScenarioTemplateStatusDTO) => {
        const patchBody: ManufacturingScenarioTemplatePatchDTO = {
            status: newStatus,
        };
        await mutateAsync(patchBody);
    };
    const [isStatusConfirmationDialogVisible, setIsStatusConfirmationDialogVisible] = React.useState(false);
    const [isCannotBeDeletedDialogOpen, setIsCannotBeDeletedDialogOpen] = React.useState<boolean>(false);

    const canEditManufacturingDatabase = useCanEditManufacturingDatabase();
    return (
        <Flexbox flexDirection="column" gap={16} flex={1}>
            <Flexbox flexDirection="column" gap={'16px'}>
                <Flexbox gap={8} alignItems="center">
                    <Text variant="h2">{template.name}</Text>
                    <TemplateStatusTag status={template.status} />
                    <SitesWithIconFetching sites={template.sites} color={colorSystem.neutral[7]} />
                    {template.manufacturing_lead_time !== null && (
                        <TemplateManufacturingLeadTime manufacturingLeadTime={template.manufacturing_lead_time} />
                    )}
                    {template.notes && <TemplateNotes notes={template.notes} />}

                    {canEditManufacturingDatabase && (
                        <Flexbox flex="1" justifyContent="end" gap={8}>
                            <ManufacturingScenarioTemplateActionMenu template={template} />
                            <TemplateStatusToggleButton
                                template={template}
                                setIsStatusConfirmationDialogVisible={setIsStatusConfirmationDialogVisible}
                                setIsCannotBeDeletedDialogOpen={setIsCannotBeDeletedDialogOpen}
                                switchStatus={onClickStatusSwitch}
                            />
                        </Flexbox>
                    )}
                </Flexbox>
                <Message
                    variant="blue"
                    attention={'low'}
                    size={'small'}
                    message={t`Templates are a preselected grouping of Expenses and Activities which can be used for calculation (e.g. Line 1 or Prototypes).`}
                />
            </Flexbox>

            <Flexbox flexDirection="row" justifyContent="space-between" alignItems="center">
                <ButtonGroup size={'large'}>
                    <ButtonGroupItem
                        selected={displayMode === 'activities'}
                        onClick={() => setDisplayMode('activities')}
                        count={template.ordered_activities.length}
                    >
                        <Trans>Activities</Trans>
                    </ButtonGroupItem>
                    <ButtonGroupItem
                        selected={displayMode === 'expenses'}
                        onClick={() => setDisplayMode('expenses')}
                        count={template.ordered_expenses.length}
                    >
                        <Trans>Expenses</Trans>
                    </ButtonGroupItem>
                </ButtonGroup>
                <PrimaryButton
                    onClick={() => {
                        switch (displayMode) {
                            case 'activities':
                                openActivityDialog();
                                break;
                            case 'expenses':
                                openExpensesDialog();
                                break;
                        }
                    }}
                    size="medium"
                    startIcon={<Edit />}
                    disabled={!canEditManufacturingDatabase}
                >
                    <Trans>Edit</Trans>
                </PrimaryButton>
            </Flexbox>

            {displayMode === 'activities' && (
                <SortableActivitiesTable
                    orderedActivityIds={template.ordered_activities}
                    manufacturingScenarioTemplateId={template.id}
                />
            )}
            {displayMode === 'expenses' && (
                <SortableExpensesTable
                    manufacturingScenarioTemplateId={template.id}
                    orderedExpenseIds={template.ordered_expenses}
                />
            )}

            <ConfirmationDialogBox
                text={t`The template contains inactive activities and expenses. Activating the template will remove those entries from the template. Proceed?`}
                title={t`Activate template`}
                onConfirm={() => onClickStatusSwitch('Active')}
                onReject={() => setIsStatusConfirmationDialogVisible(false)}
                isDialogOpen={isStatusConfirmationDialogVisible}
                deleteText={t`Yes, proceed`}
            />
            <InformationDialogBox
                title={t`Deactivating not possible`}
                onReject={() => setIsCannotBeDeletedDialogOpen(false)}
                isDialogOpen={isCannotBeDeletedDialogOpen}
            >
                <Typography>
                    <Trans>
                        This template cannot be deactivated because it is used for the automatic generation of
                        manufacturing scenarios of the Customer portal. You can change this in the organization
                        settings.
                    </Trans>
                </Typography>
            </InformationDialogBox>
        </Flexbox>
    );
};

export default ManufacturingScenarioTemplateView;
