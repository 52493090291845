import { t } from '@lingui/macro';
import { getToken } from '@luminovo/auth';
import { compareByDate, transEnum } from '@luminovo/commons';
import { EmsRfqUpdateDTO, RfqContext, RfqStatus, http } from '@luminovo/http-client';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { analytics } from '../../utils/analytics';
import { route } from '../../utils/routes';
import { useToken } from '../hooks';
import { useDebugErrorHandler } from '../http/debugErrorHandler';
import { httpQueryKey } from '../http/httpQueryKey';
import { invalidateAllQueriesForEndpoint, useHttpQuery, useSuspenseHttpQuery } from '../http/useHttpQuery';
import { useHttpMutation } from '../mutation/useHttpMutation';
import { useContributors } from '../user/userHandler';
import { rfqWarningTranslations } from './i18n';

export function useRfQ(rfqId: string, enabled: boolean = true) {
    return useHttpQuery(
        'GET /rfqs/:rfqId',
        {
            pathParams: { rfqId },
        },
        {
            enabled,
            select: (res) => res.data,
        },
    );
}

export function useForcedOfferUpdate(rfqId: string) {
    const { token } = useToken();
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: async () => {
            await http(
                'POST /rfqs/:rfqId/update-offers',
                {
                    pathParams: { rfqId },
                    requestBody: { since: new Date().toISOString() },
                },
                token,
            );
        },
        onSuccess: async () => {
            await Promise.all([
                queryClient.invalidateQueries({ queryKey: httpQueryKey('GET /offers/off-the-shelf') }),
                queryClient.invalidateQueries({ queryKey: httpQueryKey('GET /assemblies/:assemblyId/descendants') }),
                queryClient.invalidateQueries({ queryKey: httpQueryKey('POST /sourcing-scenarios/bulk') }),
                queryClient.invalidateQueries({ queryKey: httpQueryKey('POST /offers/off-the-shelf/bulk') }),
                queryClient.invalidateQueries({
                    queryKey: httpQueryKey('POST /sourcing-scenarios/sourcing-full/bulk'),
                }),
                queryClient.invalidateQueries({ queryKey: httpQueryKey('GET /sourcing/progress/:rfqId') }),
                queryClient.invalidateQueries({
                    queryKey: httpQueryKey('POST /assemblies/:id/pcb/:pcbId/offer-state'),
                }),
            ]);
        },
    });
}

export function useMutationUpdateRfq(rfqId: string) {
    const queryClient = useQueryClient();
    const { token } = useToken();

    return useMutation({
        mutationFn: async ({ rfq }: { rfq: EmsRfqUpdateDTO }) => {
            await http(
                'PATCH /rfqs/:rfqId',
                {
                    pathParams: { rfqId },
                    requestBody: { type: 'Ems', data: rfq },
                },
                token,
            );
        },
        onSuccess: async () => {
            await Promise.all([
                queryClient.invalidateQueries({ queryKey: httpQueryKey('GET /rfqs') }),
                queryClient.invalidateQueries({ queryKey: httpQueryKey('GET /rfqs/:rfqId') }),
            ]);
        },
    });
}

export function useMutationAddUserAsContributor(rfqId: string) {
    const queryClient = useQueryClient();
    const { token } = useToken();
    const onError = useDebugErrorHandler();

    const { data: contributorsData } = useContributors(rfqId);
    const contributors = [...(contributorsData?.external ?? []), ...(contributorsData?.internal ?? [])];
    const contributorIds = contributors.map((c) => c.id);

    return useMutation({
        mutationFn: async ({ userId }: { userId: string }) => {
            await http(
                'PATCH /contributors/rfqs/:rfqId',
                {
                    pathParams: { rfqId },
                    requestBody: Array.from(new Set([userId, ...contributorIds])),
                },
                token,
            );
        },
        onSuccess: async () => {
            queryClient.invalidateQueries({ queryKey: httpQueryKey('GET /contributors/rfqs/:rfqId') });
        },
        onError,
    });
}

export function useMutationRequestQuotation(rfqId: string) {
    return useHttpMutation(`PATCH /rfqs/:rfqId/request-quotation`, {
        snackbarMessage: t`Quotation requested`,
        onSuccess: async () => {
            /* eslint-disable camelcase */
            analytics.track('request_quotation', {
                rfq_id: rfqId,
            });
        },
    });
}

export function useRfqs({ customerId }: { customerId?: string } = {}) {
    return useSuspenseHttpQuery(
        'GET /rfqs',
        { queryParams: customerId ? { filter: 'all', customer_id: customerId } : { filter: 'all' } },
        {
            select: (res) => Array.from(res.data).sort(compareByDate((a) => a.creation_date)),
        },
    );
}

export function useRfqListItem(id: string = '') {
    return useHttpQuery(
        'GET /rfqs',
        { queryParams: { filter: 'all' } },
        {
            select: (res) => res.data.find((rfq) => rfq.id === id),
            enabled: Boolean(id),
        },
    );
}

export function useMutationUpdateRfqStatus(rfqId: string) {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async ({
            status,
            shippingTrackingLink,
        }: {
            status: RfqStatus;
            shippingTrackingLink?: string | undefined;
        }) => {
            await http(
                'PATCH /rfqs/:rfqId',
                {
                    pathParams: { rfqId },
                    requestBody: {
                        type: 'Ems',
                        data: {
                            status: status,
                            shipping_tracking_link:
                                shippingTrackingLink && shippingTrackingLink.length > 0 ? shippingTrackingLink : null,
                        },
                    },
                },
                getToken(),
            );
        },
        onSuccess: async () => {
            await Promise.all([
                invalidateAllQueriesForEndpoint('GET /rfqs', queryClient),
                invalidateAllQueriesForEndpoint('GET /rfqs/:rfqId', queryClient),
            ]);
        },
    });
}

export function useMutationArchiveRfq(rfqId: string, archive: boolean) {
    const queryClient = useQueryClient();
    const debugErrorHandler = useDebugErrorHandler();
    const { token } = useToken();

    const requestPath = archive ? 'PATCH /rfqs/:rfqId/archive' : 'PATCH /rfqs/:rfqId/unarchive'; // eslint-disable-line spellcheck/spell-checker

    return useMutation({
        mutationFn: async (archive: boolean) => {
            await http(
                requestPath,
                {
                    pathParams: { rfqId },
                },
                token,
            );
        },
        onSuccess: async () => {
            await Promise.all([
                invalidateAllQueriesForEndpoint('GET /rfqs', queryClient),
                invalidateAllQueriesForEndpoint('GET /rfqs/:rfqId', queryClient),
            ]);
        },
        onError: debugErrorHandler,
    });
}

export const outsideRfQContext: RfqContext = { type: 'OutsideRfQ' };

export function useRequestTechnicalSupport(rfqId: string) {
    const queryClient = useQueryClient();
    const { token } = useToken();
    const onError = useDebugErrorHandler();

    return useMutation({
        mutationFn: async () => {
            await http('POST /rfqs/:rfqId/technical-support-request', { pathParams: { rfqId } }, token);
        },
        onSuccess: async () => {
            await invalidateAllQueriesForEndpoint('GET /rfqs/:rfqId', queryClient);
        },
        onError,
    });
}

export function useRequestEditQuotation(rfqId: string) {
    const queryClient = useQueryClient();
    const { token } = useToken();
    const onError = useDebugErrorHandler();

    return useMutation({
        mutationFn: async () => {
            await http('POST /rfqs/:rfqId/edit-quotation-request', { pathParams: { rfqId } }, token);
        },
        onSuccess: async () => {
            await invalidateAllQueriesForEndpoint('GET /rfqs/:rfqId', queryClient);
        },
        onError,
    });
}

export function useDuplicateRfq({ rfqId, onSuccess }: { rfqId: string; onSuccess?: () => void }) {
    const token = getToken();
    const history = useHistory();
    const onError = useDebugErrorHandler();
    const snackbar = useSnackbar();

    return useMutation({
        mutationFn: async (newRfqName?: string) => {
            const response = await http(
                'POST /assemblies/:id/duplicate',
                /* eslint-disable-next-line camelcase */
                { pathParams: { id: rfqId }, requestBody: { new_rfq_name: newRfqName } },
                token,
            );
            return response;
        },
        onSuccess: async ({ rfq_id: rfqId, warning }) => {
            if (onSuccess) {
                onSuccess();
            }

            if (warning) {
                const localized = transEnum(warning, rfqWarningTranslations);
                snackbar.enqueueSnackbar(localized, { variant: 'warning' });
            }

            history.push(route(`/rfqs/:rfqId/dashboard`, { rfqId }));
        },
        onError: async (e) => {
            onError(e);
        },
    });
}

export function useCustomerPortalState(rfqId: string) {
    const queryResult = useHttpQuery(
        'POST /rfqs/:rfqId/customer-portal',
        { pathParams: { rfqId } },
        { refetchOnMount: 'always' },
    );

    const customerPortalState = queryResult.data;

    const queryClient = useQueryClient();

    useEffect(() => {
        queryClient.invalidateQueries({ queryKey: httpQueryKey('GET /rfqs/:rfqId', { pathParams: { rfqId } }) });
        queryClient.invalidateQueries({ queryKey: httpQueryKey('GET /rfqs') });
    }, [customerPortalState, queryClient, rfqId]);

    return queryResult;
}
