import { t } from '@lingui/macro';
import { getToken } from '@luminovo/auth';
import { isPresent } from '@luminovo/commons';
import { Comment, CommentContent } from '@luminovo/design-system';
import { CommentCategory, CommentDTO, CommentEntity, ExtractResponseBody, http } from '@luminovo/http-client';
import { QueryClient, useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useCurrentUserDetailsContext } from '../../components/contexts/CurrentUserDetailsContext';
import { useDebugErrorHandler } from '../http/debugErrorHandler';
import { httpQueryKey } from '../http/httpQueryKey';
import { useHttpQuery } from '../http/useHttpQuery';
export const QUERY_KEY_MUTATION_CREATE_COMMENT = ['event-post-mutation-key'];

export function useComments(commentEntity: CommentEntity, category?: CommentCategory, enabled?: boolean) {
    return useHttpQuery(
        'POST /comments/bulk',
        {
            requestBody: {
                type: commentEntity.type,
                data: Array.isArray(commentEntity.data) ? Array.from(commentEntity.data).sort() : commentEntity.data,
            },
        },
        {
            select: (res) => res.comments.filter((c) => !isPresent(category) || c.category === category),
            enabled,
        },
    );
}

function queriesToInvalidateAfterCommentMutation(qc: QueryClient) {
    return Promise.all([
        qc.invalidateQueries({ queryKey: httpQueryKey('POST /comments/bulk') }),
        qc.invalidateQueries({ queryKey: httpQueryKey('GET /assemblies/:assemblyId/descendants') }),
        qc.invalidateQueries({ queryKey: httpQueryKey('POST /design-items/history') }),
    ]);
}

export function useMutationCreateComment({ entity, category }: { entity: CommentEntity; category: CommentCategory }) {
    const token = getToken();
    const qc = useQueryClient();
    const { user } = useCurrentUserDetailsContext();
    const onError = useDebugErrorHandler();
    const { enqueueSnackbar } = useSnackbar();

    return useMutation({
        mutationKey: QUERY_KEY_MUTATION_CREATE_COMMENT,
        mutationFn: (content: CommentContent) => {
            /* eslint-disable camelcase */
            const optimisticEvent: CommentDTO = {
                content: content.content,
                created_by: {
                    ...user,
                    customer: user.customer?.id ?? null,
                },
                // temporary ID
                id: Date.now() + '',
                updated_at: new Date().toISOString(),
                category,
                resolved_at: null,
            };
            /* eslint-enable camelcase */

            qc.setQueryData<ExtractResponseBody<'POST /comments/bulk'>>(
                httpQueryKey('POST /comments/bulk', {
                    requestBody: entity,
                }),
                (data) => {
                    if (!data) {
                        return { comments: [optimisticEvent] };
                    }
                    return {
                        ...data,
                        comments: [...data.comments, optimisticEvent],
                    };
                },
                {},
            );
            return http(
                'POST /comments',
                {
                    requestBody: {
                        category,
                        content: content.content,
                        /* eslint-disable-next-line camelcase */
                        comment_entity: entity,
                    },
                },
                token,
            );
        },
        onSuccess: () => {
            queriesToInvalidateAfterCommentMutation(qc);
            enqueueSnackbar(t`Comment sent`, {
                variant: 'success',
                anchorOrigin: { horizontal: 'center', vertical: 'top' },
                autoHideDuration: 1000,
            });
        },
        onError,
    });
}

export function useMutationUpdateComment() {
    const token = getToken();
    const qc = useQueryClient();
    const onError = useDebugErrorHandler();

    return useMutation({
        mutationFn: ({ id, resolved }: { id: string; resolved: boolean }) => {
            return http(
                'PATCH /comments/:id',
                {
                    pathParams: { id },
                    requestBody: { resolved },
                },
                token,
            );
        },
        onSuccess: () => {
            queriesToInvalidateAfterCommentMutation(qc);
        },
        onError,
    });
}

export function useMutationDeleteComment({ entity }: { entity: CommentEntity }) {
    const token = getToken();
    const qc = useQueryClient();
    const onError = useDebugErrorHandler();
    const { enqueueSnackbar } = useSnackbar();

    return useMutation({
        mutationFn: (comment: Comment) => {
            qc.setQueryData<ExtractResponseBody<'POST /comments/bulk'>>(
                httpQueryKey('POST /comments/bulk', { requestBody: entity }),
                (body) => {
                    if (!body) {
                        return { comments: [] };
                    }
                    return {
                        ...body,
                        comments: body.comments.filter((b) => b.id !== comment.id),
                    };
                },
            );
            return http('DELETE /comments/:id', { pathParams: { id: comment.id } }, token);
        },
        onSuccess: () => {
            queriesToInvalidateAfterCommentMutation(qc);
            enqueueSnackbar(t`Comment deleted`, {
                variant: 'success',
                anchorOrigin: { horizontal: 'center', vertical: 'top' },
            });
        },
        onError,
    });
}
