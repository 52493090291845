import { assertUnreachable, isPresent } from '@luminovo/commons';
import { Flexbox } from '@luminovo/design-system';
import {
    CustomComponentOfferDTO,
    CustomOptionOfferDTO,
    CustomPartOfferDTO,
    InternalPartNumberOfferDTO,
    OffTheShelfOfferDTO,
    RfqContext,
    StandardPartOfferDTO,
} from '@luminovo/http-client';
import {
    HeaderItemCancellationWindow,
    HeaderItemCustomPartDescription,
    HeaderItemIpn,
    HeaderItemItemclass,
    HeaderItemManufacturer,
    HeaderItemMpn,
    HeaderItemNcnr,
    HeaderItemNotes,
    HeaderItemOfferFiles,
    HeaderItemOfferNumber,
    HeaderItemPackage,
    HeaderItemPanelSpecification,
    HeaderItemPcbFiles,
    HeaderItemSku,
    HeaderItemSourcingBatchSize,
    HeaderItemUnitOfMeasurement,
    HeaderItemValidForCustomer,
    HeaderItemValidForRfq,
    HeaderItemValidFrom,
    HeaderItemValidUntil,
    Solution,
    isCustomComponentOffer,
    isCustomPartOffer,
    isInternalPartNumberOffer,
    isOffTheShelfPartOffer,
} from '@luminovo/sourcing-core';
import React from 'react';
import { useCustomer } from '../../../../../resources/customer/customerHandler';
import { useDownloadPcbOrderFiles } from '../../../../../resources/export/exportHandler';
import { useHttpQuery } from '../../../../../resources/http/useHttpQuery';
import { useCustomComponent, useCustomPart, useOtsFullPart } from '../../../../../resources/part/partHandler';
import { usePcb } from '../../../../../resources/pcb/pcbHandlers';
import { useRfQ } from '../../../../../resources/rfq/rfqHandler';
import { route } from '../../../../../utils/routes';
import { getPanelDimension } from '../../../../Pcb/PanelizationTab/utils/getPanelDimension';

export const StandardPartInformation: React.FunctionComponent<{
    offer: StandardPartOfferDTO;
    rfqContext: RfqContext;
    solution?: Solution;
}> = ({ offer, solution, rfqContext }): JSX.Element => {
    if (isInternalPartNumberOffer(offer)) {
        return <InternalPartNumberInformation offer={offer} solution={solution} />;
    }

    if (isOffTheShelfPartOffer(offer)) {
        return <OffTheShelfInformation offer={offer} solution={solution} rfqContext={rfqContext} />;
    }

    assertUnreachable(offer);
};

const InternalPartNumberInformation: React.FunctionComponent<{
    offer: InternalPartNumberOfferDTO;
    solution?: Solution;
}> = ({ offer, solution }): JSX.Element => {
    const { data: rfqDTO } = useRfQ(offer.rfq ?? '', isPresent(offer.rfq));
    const { data: customerDTO } = useCustomer(offer.customer ?? undefined);

    return (
        <Flexbox flexDirection={'column'} gap={12}>
            <HeaderItemIpn
                id={offer.linked_part.id}
                href={route('/parts/components/ipn', {}, { ipnId: offer.linked_part.id, q: undefined })}
            />
            <HeaderItemOfferNumber offer={offer} />
            <HeaderItemSku offer={offer} />
            <HeaderItemPackage offer={offer} />
            <HeaderItemValidForRfq
                solution={solution}
                validFor={rfqDTO}
                href={rfqDTO && route('/rfqs/:rfqId/dashboard', { rfqId: rfqDTO.id })}
            />
            <HeaderItemValidForCustomer
                solution={solution}
                validFor={customerDTO}
                href={customerDTO && route('/customers/:customerId', { customerId: customerDTO.id })}
            />
            <HeaderItemValidFrom offer={offer} />
            <HeaderItemValidUntil offer={offer} />
            <HeaderItemItemclass offer={offer} />
            <HeaderItemCancellationWindow offer={offer} />
            <HeaderItemUnitOfMeasurement offer={offer} />
            <HeaderItemNotes offer={offer} />
        </Flexbox>
    );
};

function useOffTheSelfPartOfferFiles(offerId: string) {
    return useHttpQuery(
        'GET /offers/off-the-shelf/:id/additional-files',
        { pathParams: { id: offerId } },
        { select: (data) => data.items },
    );
}

const OffTheShelfInformation: React.FunctionComponent<{
    offer: OffTheShelfOfferDTO;
    solution?: Solution;
    rfqContext: RfqContext;
}> = ({ offer, solution, rfqContext }): JSX.Element => {
    const { data: rfqDTO } = useRfQ(offer.rfq ?? '', isPresent(offer.rfq));
    const { data: customerDTO } = useCustomer(offer.customer ?? undefined);
    const { data: part } = useOtsFullPart({ partOptionId: offer.linked_part.id, rfqContext });
    const { data: files } = useOffTheSelfPartOfferFiles(offer.id);

    if (!isPresent(part)) {
        return <></>;
    }

    return (
        <Flexbox flexDirection={'column'} gap={12}>
            <HeaderItemMpn otsPart={part} />
            <HeaderItemManufacturer otsPart={part} />
            <HeaderItemOfferNumber offer={offer} />
            <HeaderItemSku offer={offer} />
            <HeaderItemPackage offer={offer} />
            <HeaderItemValidForRfq
                solution={solution}
                validFor={rfqDTO}
                href={rfqDTO && route('/rfqs/:rfqId/dashboard', { rfqId: rfqDTO.id })}
            />
            <HeaderItemValidForCustomer
                solution={solution}
                validFor={customerDTO}
                href={customerDTO && route('/customers/:customerId', { customerId: customerDTO.id })}
            />
            <HeaderItemValidFrom offer={offer} />
            <HeaderItemValidUntil offer={offer} />
            <HeaderItemItemclass offer={offer} />
            <HeaderItemCancellationWindow offer={offer} />
            <HeaderItemNcnr offer={offer} />
            <HeaderItemUnitOfMeasurement offer={offer} />
            <HeaderItemNotes offer={offer} />
            <HeaderItemOfferFiles files={files} />
        </Flexbox>
    );
};

export const LinkedCustomPartInformation: React.FunctionComponent<{
    offer: CustomOptionOfferDTO;
    sourcingScenarioId: string;
    rfqContext: RfqContext;
}> = ({ offer, sourcingScenarioId, rfqContext }): JSX.Element => {
    if (isCustomPartOffer(offer)) {
        return <CustomPartInformation offer={offer} sourcingScenarioId={sourcingScenarioId} />;
    }

    if (isCustomComponentOffer(offer)) {
        return <CustomComponentInformation offer={offer} rfqContext={rfqContext} />;
    }

    assertUnreachable(offer);
};

function useCustomPartOfferFiles(offerId: string) {
    return useHttpQuery(
        'GET /offers/custom-part/:id/additional-files',
        { pathParams: { id: offerId } },
        { select: (data) => data.items },
    );
}

export const CustomPartInformation: React.FunctionComponent<{
    offer: CustomPartOfferDTO;
    sourcingScenarioId: string;
}> = ({ offer, sourcingScenarioId }): JSX.Element => {
    const { mutateAsync, isPending: isLoading } = useDownloadPcbOrderFiles(offer.id, sourcingScenarioId);

    const { data: part } = useCustomPart(offer.linked_part.id);

    const { data: pcb } = usePcb(offer.specification?.data.pcb);

    const { data: files } = useCustomPartOfferFiles(offer.id);

    const panel = offer.specification?.data.panel;
    const panelDimensions = pcb && panel ? getPanelDimension(panel, pcb) : undefined;

    if (!isPresent(part)) {
        return <></>;
    }

    return (
        <Flexbox flexDirection={'column'} gap={12}>
            <HeaderItemCustomPartDescription description={part.description ?? ''} />
            <HeaderItemSourcingBatchSize offer={offer} />
            <HeaderItemOfferNumber offer={offer} />
            <HeaderItemPcbFiles offer={offer} part={part} onClick={() => mutateAsync()} isLoading={isLoading} />
            <HeaderItemValidUntil offer={offer} />
            <HeaderItemUnitOfMeasurement offer={offer} />
            {panel ? (
                <HeaderItemPanelSpecification panel={panel} panelDimensions={panelDimensions} />
            ) : (
                <HeaderItemNotes offer={offer} />
            )}
            <HeaderItemOfferFiles files={files} />
        </Flexbox>
    );
};

export const CustomComponentInformation: React.FunctionComponent<{
    offer: CustomComponentOfferDTO;
    rfqContext: RfqContext;
}> = ({ offer, rfqContext }): JSX.Element => {
    const { data: customComponent } = useCustomComponent(offer.linked_part.id, rfqContext);

    if (!isPresent(customComponent)) {
        return <></>;
    }

    return (
        <Flexbox flexDirection={'column'} gap={12}>
            <HeaderItemIpn
                id={offer.linked_part.id}
                href={route('/parts/components/ipn', {}, { ipnId: offer.linked_part.id, q: undefined })}
            />
            <HeaderItemCustomPartDescription description={customComponent.description || ''} />
            <HeaderItemOfferNumber offer={offer} />
            <HeaderItemValidUntil offer={offer} />
            <HeaderItemUnitOfMeasurement offer={offer} />
            <HeaderItemNotes offer={offer} />
        </Flexbox>
    );
};
