import { CustomPartOfferResponse, PCBV2 } from '@luminovo/http-client';
import { useHttpQuery } from '../../../../../resources/http/useHttpQuery';

export const checkIfDetailedOffersIsPending = (offers: CustomPartOfferResponse): boolean => {
    return offers.type === 'Detailed' ? offers.is_pending : false;
};

const checkIfSimpleOfferIsPending = (offers: CustomPartOfferResponse): boolean => {
    return offers.type === 'Simple' ? offers.data.type === 'Pending' : false;
};

export const usePcbOffersState = ({ pcb, assemblyId }: { pcb: PCBV2; assemblyId: string }) => {
    return useHttpQuery(
        'POST /assemblies/:id/pcb/:pcbId/offer-state',
        {
            pathParams: {
                id: assemblyId,
                pcbId: pcb.id,
            },
        },
        {
            refetchInterval: ({ state }) => {
                if (!state.data) return false;

                if (
                    state.data.status.type === 'NoSourcingScenarios' ||
                    state.data.status.type === 'NoSuppliersConfigured'
                )
                    return false;

                if (state.data.status.type === 'AnalysisInProgress') return 2_000;

                if (checkIfSimpleOfferIsPending(state.data.status) || checkIfDetailedOffersIsPending(state.data.status))
                    return 2_000;

                return false;
            },
        },
    );
};
