import * as r from 'runtypes';
import { runtypeFromEnum } from '../../utils/typingUtils';
import { MonetaryValueBackendRuntype, QuantityUnitDTORuntype } from '../backendTypes';
import { ItemClassEnumRunType, PriceTypeRuntype } from '../offer';
import { PartLiteRuntype } from '../part';
import { SupplierDTORuntype } from '../supplier';
import { SupplierAndStockLocationDTO } from '../supplierAndStockLocation';
import { UserDTORuntype } from '../user';

export enum QuoteRequestLineItemStatus {
    NotSent = 'NotSent', // not sent quote request
    Sent = 'Sent', // sent quote request
    Overdue = 'Overdue', // overdue quote request
    Received = 'Received', // received quote request with offer
    NoBid = 'NoBid', // received quote request, but no received offer
    Discarded = 'Discarded', // discarded quote request
    Awarded = 'Awarded', // Quote request line item's offer is in awarded award scenario
}

export enum QuoteRequestStatus {
    NotSent = 'NotSent',
    Sent = 'Sent',
    Overdue = 'Overdue',
    Received = 'Received',
    Discarded = 'Discarded',
}

export enum NegotiationLineItemStatus {
    NoQuoteRequests = 'NoQuoteRequests',
    NotSent = 'NotSent',
    Sent = 'Sent',
    PartiallyReceived = 'PartiallyReceived',
    Received = 'Received',
    NoBid = 'NoBid',
    Awarded = 'Awarded',
}

export enum QuoteRequestLineItemAssignmentReason {
    ManuallyAdded = 'ManuallyAdded',
    ReferenceSupplier = 'ReferenceSupplier',
    LineCard = 'LineCard',
    LineCardRule = 'LineCardRule',
}

export type QuoteRequestLineItemSummaryDTO = r.Static<typeof QuoteRequestLineItemSummaryDTORuntype>;
export const QuoteRequestLineItemSummaryDTORuntype = r.Record({
    id: r.Number,
    quote_request_id: r.String,
    assignment_reason: runtypeFromEnum(QuoteRequestLineItemAssignmentReason),
    status: runtypeFromEnum(QuoteRequestLineItemStatus),
});

export type PartOptionRequestSummaryDTO = r.Static<typeof PartOptionRequestSummaryDTORuntype>;
export const PartOptionRequestSummaryDTORuntype = r.Record({
    negotiation_line_item_id: r.Number,
    part_option_id: r.Number,
    component_origin: r.String.nullable(),
    part: PartLiteRuntype,
    description: r.String.nullable(),
    quote_request_line_items: r.Array(QuoteRequestLineItemSummaryDTORuntype),
});

export type QuoteRequestDTO = r.Static<typeof QuoteRequestDTORuntype>;
export const QuoteRequestDTORuntype = r.Record({
    id: r.String,
    number: r.Number,
    supplier: SupplierDTORuntype,
    created_at: r.String,
    discarded: r.Boolean,
    due_date: r.String.nullable().optional(),
    received_date: r.String.nullable().optional(),
    sent_date: r.String.nullable().optional(),
    notes: r.String.nullable().optional(),
    line_item_count: r.Number,
    status: runtypeFromEnum(QuoteRequestStatus),
    sent_by: r
        .Record({
            id: r.String,
            first_name: r.String,
            last_name: r.String,
            email: r.String,
        })
        .nullable(),
    sent_to: r.Array(
        r.Record({
            id: r.Number,
            supplier_contact: r.String.nullable(),
            quote_request: r.String,
            name: r.String.nullable(),
            email: r.String,
        }),
    ),
    configuration: r
        .Record({
            hidden_fields: r.Array(
                r.Union(r.Literal('Customer'), r.Literal('TargetPrice'), r.Literal('NegotiatedByCustomer')),
            ),
        })
        .optional(),
});

export type QuoteRequestLineItemDTO = r.Static<typeof QuoteRequestLineItemDTORuntype>;
export const QuoteRequestLineItemDTORuntype = r.Record({
    id: r.Number,
    quote_request_id: r.String,
    assignment_reason: runtypeFromEnum(QuoteRequestLineItemAssignmentReason),
    customers: r.String.optional().nullable(), // Refactor that in the future
    recipients: r.String.optional().nullable(),
    negotiation_line_item_id: r.Number.nullable(),
    description: r.String.optional().nullable(),
    target_price: MonetaryValueBackendRuntype.optional().nullable(),
    required_quantity: QuantityUnitDTORuntype,
    potential_quantity: QuantityUnitDTORuntype,
    component_origin: r.String.optional().nullable(),
    requested_part: PartLiteRuntype,
    received_offer: r
        .Record({
            offer_id: r.Record({
                kind: r.Union(r.Literal('OffTheShelf'), r.Literal('Custom')),
                id: r.String,
            }),
            part: PartLiteRuntype,
            supplier_and_stock_location_id: r.String,
            unit_price: MonetaryValueBackendRuntype,
            moq: r.Number,
            mpq: r.Number,
            cancellation_window_in_days: r.Number.nullable(),
            valid_from: r.String.nullable(),
            valid_until: r.String.nullable(),
            item_class: ItemClassEnumRunType.nullable(),
        })
        .nullable(),
    status: runtypeFromEnum(QuoteRequestLineItemStatus),
});

export type AwardedOfferDTO = r.Static<typeof AwardedOfferDTORuntype>;
const AwardedOfferDTORuntype = r.Record({
    id: r.Number,
    award_scenario_id: r.Number,
    negotiation_line_item_id: r.Number,
    price_type: r.Union(PriceTypeRuntype, PriceTypeRuntype.nullable()),
    awarded_solution: r.Record({
        offer_id: r.Record({
            kind: r.Union(r.Literal('OffTheShelf'), r.Literal('Custom')),
            id: r.String,
        }),
        part: PartLiteRuntype,
        supplier_and_stock_location_id: r.String,
        unit_price: MonetaryValueBackendRuntype,
        moq: r.Number,
        mpq: r.Number,
    }),
});

export type AwardedOffer = Omit<AwardedOfferDTO, 'awarded_solution'> & {
    awarded_solution: Omit<AwardedOfferDTO['awarded_solution'], 'supplier_and_stock_location_id'> & {
        supplier_and_stock_location: SupplierAndStockLocationDTO | undefined | null;
    };
};

export type AwardScenarioDTO = r.Static<typeof AwardScenarioDTORuntype>;
export const AwardScenarioDTORuntype = r.Record({
    id: r.Number,
    name: r.String,
    negotiation_id: r.Number,
    created_at: r.String,
    is_automatic: r.Boolean,
    kind: r.Union(
        r.Record({ tag: r.Literal('Awarded') }),
        r.Record({ tag: r.Literal('Manual') }),
        r.Record({ tag: r.Literal('BestListPrice') }),
        r.Record({ tag: r.Literal('BestPriceAcrossAllQuotes') }),
        r.Record({ tag: r.Literal('LastPurchasePrice') }),
        r.Record({ tag: r.Literal('LastStandardPrice') }),
        r.Record({ tag: r.Literal('LastTargetPrice') }),
        r.Record({
            tag: r.Literal('QuoteRequest'),
            data: r.Number, // for QuoteRequestId
        }),
    ),
    awarded_offers: r.Array(AwardedOfferDTORuntype),
});
export type AwardScenario = Omit<AwardScenarioDTO, 'awarded_offers'> & {
    awarded_offers: AwardedOffer[];
};

export type NegotiationDTO = r.Static<typeof NegotiationDTORuntype>;
export const NegotiationDTORuntype = r.Record({
    id: r.Number,
    name: r.String,
    created_at: r.String,
    created_by: UserDTORuntype,
    rfq_id: r.String.nullable(),
});

export type NegotiationLineItemDTO = r.Static<typeof NegotiationLineItemRuntype>;
export const NegotiationLineItemRuntype = r.Record({
    id: r.Number,
    negotiation_id: r.Number,
    quantity: QuantityUnitDTORuntype,
    parts: r.Array(
        r.Record({
            id: r.Number, // part option id
            part: PartLiteRuntype,
        }),
    ),
    awarded_offers: r.Array(AwardedOfferDTORuntype),
    demands: r.Array(r.String), // ids of the demands
});

export type NegotiationLineItem = Omit<NegotiationLineItemDTO, 'awarded_offers' | 'demands'> & {
    awarded_offers: AwardedOffer[];
    demands: DemandDTO[];
};

// Demand

export type DemandDTO = r.Static<typeof DemandDTORuntype>;
export const DemandDTORuntype = r.Record({
    id: r.String,
    context: r.String.nullable(),
    component_ipn_id: r.String.nullable(),
    created_at: r.String,
    quantity: QuantityUnitDTORuntype,
    delivery_start_date: r.String.nullable(),
    delivery_end_date: r.String.nullable(),
    customer: r
        .Record({
            id: r.String,
            name: r.String,
        })
        .nullable(),
    supplier_site: r
        .Record({
            id: r.String,
            name: r.String,
        })
        .nullable(),
    ship_to_site: r
        .Record({
            id: r.String,
            name: r.String,
        })
        .nullable(),
    assembly: r
        .Record({
            id: r.String,
            designator: r.String,
        })
        .nullable(),
});

export type QuoteRequestLineItemAggregatedStatusesDTO = r.Static<typeof QuoteRequestLineItemAggregatedStatusesRuntype>;
export const QuoteRequestLineItemAggregatedStatusesRuntype = r.Record({
    overview: r.Array(
        r.Record({
            status: runtypeFromEnum(QuoteRequestLineItemStatus),
            count: r.Number,
        }),
    ),
    statuses_by_negotiation_line_item: r.Array(
        r.Record({
            negotiation_line_item_id: r.Number,
            statuses: r.Array(
                r.Record({
                    status: runtypeFromEnum(QuoteRequestLineItemStatus),
                    count: r.Number,
                }),
            ),
        }),
    ),
});

export type SupplierSelectionStrategyDTO = r.Static<typeof SupplierSelectionStrategyRuntype>;
export const SupplierSelectionStrategyRuntype = r.Union(
    r.Record({
        type: r.Literal('SupplierPartTypeMatches'),
    }),
    r.Record({
        type: r.Literal('SupplierLineCard'),
        part_option_selection_strategy: r.Union(r.Literal('OnlyMatchingPartOptions'), r.Literal('AllPartOptions')),
    }),
    r.Record({
        type: r.Literal('ReferenceSupplier'),
        award_scenario_id: r.Number,
    }),
);
