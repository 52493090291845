import { t } from '@lingui/macro';
import {
    ManufacturingDatabaseActions,
    ManufacturingDatabasePage,
} from '../../../components/CostingTemplates/ManufacturingDatabasePage';
import ResourcesTable from './ResourcesTable';

const ResourcesViewPage = (): JSX.Element => {
    return (
        <ManufacturingDatabasePage
            DatabaseActionsComponent={ManufacturingDatabaseActions}
            DatabaseTableComponent={ResourcesTable}
            buttonText={t`Add resource`}
            path="/manufacturing/add-resource"
            descriptionText={t`Resources represent all machinery and employees relevant to production including their hourly rates (e.g. SMT Personnel or Fuji NXT Pick and Place Machine).`}
        />
    );
};

export default ResourcesViewPage;
