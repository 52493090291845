import { t } from '@lingui/macro';
import {
    ManufacturingDatabaseActions,
    ManufacturingDatabasePage,
} from '../../../components/CostingTemplates/ManufacturingDatabasePage';
import ExpensesDatabaseTable from './components/ExpensesDatabaseTable';

export const ExpensesViewPage = (): JSX.Element => {
    return (
        <ManufacturingDatabasePage
            DatabaseActionsComponent={ManufacturingDatabaseActions}
            DatabaseTableComponent={ExpensesDatabaseTable}
            buttonText={t`Add expense`}
            path="/manufacturing/add-expense"
            descriptionText={t`Expenses are one-time costs (NREs) that are incurred without using internal resources (e.g. Soldering Mask or Machine Programming).`}
        />
    );
};
