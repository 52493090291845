import { Trans, t } from '@lingui/macro';
import { Column, FieldSelect, Flexbox, Tag, Text, colorSystem, columnWidth } from '@luminovo/design-system';
import { ManufacturingDatabaseTableCell, TextRow } from '@luminovo/manufacturing-core';
import { TableCell } from '@mui/material';
import { transEnum } from '../../../components/localization/TransEnum';
import { levelEnumPublicTranslations } from '../../../resources/activity/activityBackendTypes';
import { AppendActivitiesButton } from '../ManufacturingScenario/components/AppendEntity';
import {
    ManufacturingScenarioTemplateInstanceRow,
    TemplateInstanceState,
    createHeaderOrDataColumn,
    renderEmptyHeaderRow,
} from '../shared/ActivityAndExpenseConfigurationTables';
import { UpDownButtonsActivityConfigurations } from './UpDownButtons';
import {
    ResourcesCell,
    TextCell,
    doesUnitActivityConfigurationHaveBatchTimeCalculation,
    renderTimeAndCost,
} from './activitiesTableComponents';
import { DeleteActivityConfiguration } from './deleteActivityConfigurations';
import { OverwriteActivityDialog } from './overwriteActivity';
import { TimeCalculationCell } from './timeCalculationCell';
import { ActivityTableRow } from './types';

const createRankColumn = (manufacturingScenarioId: string): Column<ActivityTableRow, TemplateInstanceState> => {
    return createHeaderOrDataColumn(
        {
            id: 'rank',
            label: <></>,
            width: columnWidth.extraSmall,
        },
        ({ data }): JSX.Element => (
            <TableCell>
                <UpDownButtonsActivityConfigurations activityConfiguration={data} />
            </TableCell>
        ),
        ({ data }, context) => (
            <ManufacturingScenarioTemplateInstanceRow
                data={data}
                context={context}
                AppendButton={
                    <AppendActivitiesButton
                        manufacturingScenarioId={manufacturingScenarioId}
                        manufacturingScenarioTemplateInstanceId={
                            typeof data.templateInstanceId === 'string' ? data.templateInstanceId : undefined
                        }
                    />
                }
            />
        ),
    );
};

const createActivityColumn = (): Column<ActivityTableRow, TemplateInstanceState> => {
    return createHeaderOrDataColumn(
        {
            label: <Trans>Activity</Trans>,
            id: `activity`,
            width: columnWidth.medium,
        },
        ({ data }): JSX.Element => (
            <ManufacturingDatabaseTableCell>
                <TextRow text={data.activityConfigurationSummary.activity_configuration_details.activity_name} />
            </ManufacturingDatabaseTableCell>
        ),
        renderEmptyHeaderRow,
    );
};

const createLevelColumn = (): Column<ActivityTableRow, TemplateInstanceState> => {
    return createHeaderOrDataColumn(
        {
            id: `level`,
            label: <Trans>Level</Trans>,
            width: columnWidth.small,
        },
        ({ data }): JSX.Element => {
            return (
                <ManufacturingDatabaseTableCell>
                    <Tag
                        color={'neutral'}
                        label={transEnum(
                            data.activityConfigurationSummary.activity_configuration_details.cost_components.level,
                            levelEnumPublicTranslations,
                        )}
                    />
                    {data.activityConfigurationSummary.activity_configuration_details.cost_components.level ===
                        'Unit' &&
                        doesUnitActivityConfigurationHaveBatchTimeCalculation(data.activityConfigurationSummary) && (
                            <Tag color={'neutral'} label={transEnum('Batch', levelEnumPublicTranslations)} />
                        )}
                </ManufacturingDatabaseTableCell>
            );
        },
        renderEmptyHeaderRow,
    );
};

const createActivityResourceColumn = (): Column<ActivityTableRow, TemplateInstanceState> => {
    return createHeaderOrDataColumn(
        {
            id: `resource`,
            label: <Trans>Resource</Trans>,
            width: columnWidth.medium,
        },
        ({ data }): JSX.Element => {
            return (
                <TextCell
                    firstRow={
                        <ResourcesCell
                            resourcesDetails={data.activityConfigurationSummary.resources_details.map((r) => ({
                                /* eslint-disable-next-line camelcase */
                                resource_id: r.resource.id,
                                multiplier: r.multiplier,
                            }))}
                        />
                    }
                />
            );
        },
        renderEmptyHeaderRow,
    );
};

const createTimeCalculationColumn = (): Column<ActivityTableRow, TemplateInstanceState> => {
    return createHeaderOrDataColumn(
        {
            id: 'timeCalculationColumn',
            label: <Trans>Time calculation</Trans>,
            width: columnWidth.medium,
        },
        ({ data }) => {
            return (
                <TimeCalculationCell
                    activity={data.activityConfigurationSummary}
                    manufacturingAssemblyDetails={data.manufacturingAssemblyDetails}
                />
            );
        },
        renderEmptyHeaderRow,
    );
};

const createCostsColumn = ({
    batchSizes,
    selectedBatchSize,
    setSelectedBatchSize,
}: {
    batchSizes: number[];
    selectedBatchSize: number | undefined;
    setSelectedBatchSize: (batchSize: number | undefined) => void;
}): Column<ActivityTableRow, TemplateInstanceState> => {
    const showBatchSizes = batchSizes && selectedBatchSize;

    return createHeaderOrDataColumn(
        {
            id: 'costColumns',
            width: columnWidth.extraLarge,
            label: '', // intentionally blank,
            renderHead: (): JSX.Element => {
                return (
                    <TableCell style={{ verticalAlign: 'center' }}>
                        <Flexbox gap={'16px'} alignItems={'center'}>
                            <Text
                                variant="h4"
                                style={{
                                    whiteSpace: 'nowrap',
                                    color: colorSystem.neutral[7],
                                    fontSize: '12px',
                                }}
                            >
                                <Trans>Time & cost</Trans>
                            </Text>

                            {showBatchSizes && (
                                <FieldSelect
                                    size="small"
                                    id="batch-size-select"
                                    value={selectedBatchSize}
                                    options={batchSizes.sort((a, b) => a - b)}
                                    getOptionLabel={(option) => option.toString()}
                                    onChange={(value) => setSelectedBatchSize(value ?? undefined)}
                                    style={{ backgroundColor: colorSystem.neutral['white'] }}
                                />
                            )}
                        </Flexbox>
                    </TableCell>
                );
            },
        },
        ({ data }): JSX.Element => {
            return (
                <>{showBatchSizes ? renderTimeAndCost(data.activityConfigurationSummary, selectedBatchSize) : <></>}</>
            );
        },
        renderEmptyHeaderRow,
    );
};

const createDeleteActivityConfigurationColumn = (
    selectedManufacturingScenarioId: string,
): Column<ActivityTableRow, TemplateInstanceState> => {
    return createHeaderOrDataColumn(
        {
            id: 'deleteActivityConfiguration',
            label: '', // intentionally blank
            width: columnWidth.extraSmall,
        },
        ({ data }) => (
            <TextCell
                firstRow={
                    <DeleteActivityConfiguration
                        activityConfigurationId={
                            data.activityConfigurationSummary.activity_configuration_details.activity_configuration_id
                        }
                        manufacturingScenarioId={selectedManufacturingScenarioId}
                    />
                }
            />
        ),
        renderEmptyHeaderRow,
    );
};
const createEditedColumn = (): Column<ActivityTableRow, TemplateInstanceState> => {
    return createHeaderOrDataColumn(
        {
            id: 'isActivityEdited',
            label: '', // intentionally blank
            width: columnWidth.extraSmall,
        },
        ({ data }) => (
            <TextCell
                firstRow={
                    <>
                        {data.activityConfigurationSummary.activity_configuration_details
                            .activity_is_manual_overwrite && <Tag label={t`Edited`} attention="low" />}
                    </>
                }
            />
        ),
        renderEmptyHeaderRow,
    );
};

const createOverwriteActivityColumn = (
    selectedManufacturingScenarioId: string,
): Column<ActivityTableRow, TemplateInstanceState> => {
    return createHeaderOrDataColumn(
        {
            id: 'overwriteActivityConfiguration',
            label: '', // intentionally blank
            width: columnWidth.extraSmall,
        },
        ({ data }) => (
            <TextCell
                firstRow={
                    <>
                        <OverwriteActivityDialog
                            activityId={data.activityConfigurationSummary.activity_configuration_details.activity_id}
                            isManualOverwrite={
                                data.activityConfigurationSummary.activity_configuration_details
                                    .activity_is_manual_overwrite
                            }
                            manufacturingScenarioId={selectedManufacturingScenarioId}
                            level={
                                data.activityConfigurationSummary.activity_configuration_details.cost_components.level
                            }
                            isActivityPerPanel={
                                data.activityConfigurationSummary.activity_configuration_details.activity_is_per_panel
                            }
                        />
                    </>
                }
            />
        ),
        renderEmptyHeaderRow,
    );
};

export const generateColumns = ({
    rows,
    manufacturingScenarioSummaryId,
    batchSizes,
    selectedBatchSize,
    setSelectedBatchSize,
}: {
    rows: ActivityTableRow[];
    manufacturingScenarioSummaryId: string;
    batchSizes: number[];
    selectedBatchSize: number | undefined;
    setSelectedBatchSize: (batchSize: number | undefined) => void;
}): Column<ActivityTableRow, TemplateInstanceState>[] => {
    return [
        createRankColumn(manufacturingScenarioSummaryId),
        createActivityColumn(),
        createLevelColumn(),
        createActivityResourceColumn(),
        createTimeCalculationColumn(),
        createCostsColumn({ batchSizes, selectedBatchSize, setSelectedBatchSize }),
        createEditedColumn(),
        createOverwriteActivityColumn(manufacturingScenarioSummaryId),
        createDeleteActivityConfigurationColumn(manufacturingScenarioSummaryId),
    ];
};
