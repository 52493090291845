import { t } from '@lingui/macro';
import { Flexbox, Message, PrimaryButton } from '@luminovo/design-system';
import { ManufacturingUpsellBanner } from '@luminovo/manufacturing-core';
import { Add } from '@mui/icons-material';
import React from 'react';

import { useManufacturingModuleVariant } from '../../featureFlags';
import { useCanEditManufacturingDatabase } from '../../modules/Manufacturing/utils/useCanEditManufacturingDatabase';
import { LumiQuoteRoute, route } from '../../utils/routes';
import { SearchInput } from '../SearchInput';
import { useIsAdminUser } from '../contexts/CurrentUserDetailsContext';
import { CostingTemplatePageLayout } from './CostingTemplatePageLayout';
import { ManufacturingDatabaseTabs } from './ManufacturingDatabaseTabs';
export const ReadOnlyAccessAlert = () => {
    const isAdmin = useIsAdminUser();

    if (!isAdmin) {
        return (
            <Message
                variant="red"
                attention={'high'}
                size={'small'}
                title={t`Read-only access`}
                message={t`You don't have permission to edit this database. Contact an admin to get access.`}
            />
        );
    }

    return <></>;
};

export const ManufacturingDatabaseActions = ({
    path,
    buttonText,
    searchQuery,
    setSearchQuery,
    descriptionText,
}: {
    path: string;
    buttonText: string;
    descriptionText: string;
    searchQuery: string;
    setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
}): JSX.Element => {
    const canEditManufacturingDatabase = useCanEditManufacturingDatabase();
    const manufacturingVariant = useManufacturingModuleVariant();
    return (
        <Flexbox flexDirection={'column'} gap={12}>
            <ReadOnlyAccessAlert />
            {manufacturingVariant === 'lite' && <ManufacturingUpsellBanner />}
            <Message message={descriptionText} variant="blue" attention="low" size="small" />
            <Flexbox alignItems={'center'} justifyContent={'space-between'}>
                <SearchInput value={searchQuery} onChange={setSearchQuery} style={{ width: '300px' }} />
                {canEditManufacturingDatabase && (
                    <PrimaryButton startIcon={<Add fontSize={'inherit'} />} href={path} size="medium">
                        {buttonText}
                    </PrimaryButton>
                )}
            </Flexbox>
        </Flexbox>
    );
};

interface DatabaseViewProps {
    DatabaseActionsComponent: typeof ManufacturingDatabaseActions;
    DatabaseTableComponent: React.ComponentType<{ query: string }>;
    buttonText: string;
    path: LumiQuoteRoute;
    descriptionText: string;
}

export const ManufacturingDatabasePage = ({
    DatabaseActionsComponent,
    DatabaseTableComponent,
    buttonText,
    path,
    descriptionText,
}: DatabaseViewProps): JSX.Element => {
    const [searchQuery, setSearchQuery] = React.useState<string>('');

    return (
        <ManufacturingDatabaseTemplatePageLayout>
            <DatabaseActionsComponent
                buttonText={buttonText}
                path={route(path)}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                descriptionText={descriptionText}
            />
            <Flexbox marginBottom={'24px'} marginTop={'16px'}>
                <DatabaseTableComponent query={searchQuery} />
            </Flexbox>
        </ManufacturingDatabaseTemplatePageLayout>
    );
};

export const ManufacturingDatabaseTemplatePageLayout = ({ children }: { children: React.ReactNode }): JSX.Element => {
    return (
        <CostingTemplatePageLayout tabs={<ManufacturingDatabaseTabs />} title={t`Manufacturing`}>
            {children}
        </CostingTemplatePageLayout>
    );
};
