import { Trans } from '@lingui/macro';
import { Dropzone, Flexbox, Link, Text, colorSystem } from '@luminovo/design-system';
import { PCBFileTypes, PCBV2, UploadFileResponse } from '@luminovo/http-client';
import { Box, CircularProgress, styled } from '@mui/material';
import React from 'react';
import { useMutationUpdatePcbOffers, useMutationUploadMissingPcbFiles } from '../../../../resources/pcb/pcbHandlers';
import { route } from '../../../../utils/routes';
import { useMutationUpdatePcbFileTypes } from '../../components/LayerAssignment/utils/useMutationUpdatePcbFileTypes';
import { StackUpFileSelectionButton } from './StackUpFileSelectionButton';

const FileExtensionsInfo = () => {
    return (
        <Text variant="caption" style={{ textAlign: 'center' }}>
            <Trans>Please upload your Stackup documentation in form of pdf, xlsx or docx files.</Trans>
        </Text>
    );
};

const MessageInfo = () => {
    return (
        <Text variant="body-small-semibold">
            <Trans>Enable edit to upload files.</Trans>
        </Text>
    );
};

const convertUploadedFilesToStackUp = (uploadedFiles: UploadFileResponse) => {
    return uploadedFiles.files.map((file) => {
        return {
            file: file.name,
            function: {
                ...file.fType,
                fileType: PCBFileTypes.STACK_UP,
                productionFile: true,
            },
        };
    });
};

export const StackUpFilesUpload = ({
    disabled,
    pcb,
    assemblyId,
    rfqId,
}: {
    assemblyId: string;
    rfqId: string;
    disabled: boolean;
    pcb: PCBV2;
}) => {
    const pcbId = pcb.id;
    const [fileNames, setFileNames] = React.useState<File[]>([]);
    const { mutateAsync, isPending: isUploading } = useMutationUploadMissingPcbFiles({ pcbId });
    const { mutateAsync: updateFileType, isPending: isUpdatingFiles } = useMutationUpdatePcbFileTypes({ pcbId });
    const { mutateAsync: updatePcbOffers } = useMutationUpdatePcbOffers();

    const handleDropAccepted = async (files: File[]) => {
        setFileNames(files);
        const uploadedFiles: UploadFileResponse = await mutateAsync(files);
        await updateFileType({ updates: convertUploadedFilesToStackUp(uploadedFiles) });
        await updatePcbOffers(pcbId);
    };

    const isLoading = isUploading || isUpdatingFiles;

    return (
        <>
            {isLoading ? (
                <>
                    {fileNames.map((fileName, index) => (
                        <Flexbox
                            key={index}
                            style={{
                                gap: '8px',
                                border: `1px solid ${colorSystem.neutral[7]}`,
                                padding: '16px',
                                borderRadius: '8px',
                                alignItems: 'center',
                            }}
                        >
                            <CircularProgress size={'16px'} />
                            <Text variant="body-small-semibold">{fileName.name}</Text>
                        </Flexbox>
                    ))}
                </>
            ) : (
                <Dropzone
                    multiple
                    title={''}
                    onDropAccepted={handleDropAccepted}
                    isLoading={isUploading}
                    disabled={disabled}
                    accept={{
                        'application/pdf': ['.pdf'],
                        // eslint-disable-next-line spellcheck/spell-checker
                        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
                        'application/vnd.ms-excel': ['.xls'],
                        'application/msword': ['.doc'],
                    }}
                    overrides={{
                        Container: DropzoneContainer,
                        MessageInfo: disabled ? MessageInfo : undefined,
                        FileExtensionsInfo,
                    }}
                    extraContent={
                        <Box marginBlockStart={'16px'} width={'100%'}>
                            <StackUpFileSelectionButton disabled={disabled} pcb={pcb} />
                            <Text variant="caption">
                                <Trans>
                                    You can also do it by changing the file’s category on the{' '}
                                    <Link
                                        variant="caption"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href={route('/rfqs/:rfqId/bom/assembly/:assemblyId/pcb/files', {
                                            rfqId,
                                            assemblyId,
                                        })}
                                    >
                                        file manager
                                    </Link>{' '}
                                    page
                                </Trans>
                            </Text>
                        </Box>
                    }
                />
            )}
        </>
    );
};

const DropzoneContainer = styled(Flexbox)({
    flexDirection: 'column',
    width: '100%',
});
