import { t } from '@lingui/macro';
import {
    FieldDateControlled,
    FieldNumericControlled,
    Flexbox,
    FormItem,
    SecondaryButton,
    TertiaryIconButton,
} from '@luminovo/design-system';
import { Delete } from '@mui/icons-material';
import { useTheme } from '@mui/material';
import { ArrayPath, Control, FieldArray, FieldArrayPathValue, FieldValues, Path, useFieldArray } from 'react-hook-form';
import { useSuspenseHttpQuery } from '../../resources/http/useHttpQuery';

type OrderScenariosFieldName<T extends FieldValues, K extends ArrayPath<T> & Path<T>> =
    OrderScenario[] extends FieldArrayPathValue<T, K> ? K : never;

export interface OrderScenario {
    id?: string;
    quantity: number | undefined;
    date: string | undefined;
}

interface Props<T extends FieldValues, K extends ArrayPath<T> & Path<T>> {
    control: Control<T>;
    name: OrderScenariosFieldName<T, K>;
    addButtonText: string;
    newScenario: FieldArray<T, OrderScenariosFieldName<T, K>>;
    hasAtLeastOneOrderScenario?: boolean;
    maxNumberOfScenarios?: number;
}

export function OrderScenariosFormItem<T extends FieldValues, K extends ArrayPath<T> & Path<T>>({
    name,
    control,
    addButtonText,
    newScenario,
    hasAtLeastOneOrderScenario,
    maxNumberOfScenarios = 1000,
}: Props<T, K>): JSX.Element {
    const theme = useTheme();
    const { fields, append, remove } = useFieldArray({
        control,
        name,
    });

    const makePath = (i: number, fieldName: Path<OrderScenario>): Path<T> => {
        // tsc cannot check that this concatenation results in a Path<T>. As our typings
        // guarantee `name` to be a valid path to an arry and `fieldName` to be a field
        // of the object that `name` points to, we can make this type assertion.
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        return `${name}.${i}.${fieldName}` as Path<T>;
    };

    const shouldShowDeleteButton = !hasAtLeastOneOrderScenario || fields.length > 1;

    const { data: customerPortalSettings } = useSuspenseHttpQuery(
        /* eslint-disable-next-line spellcheck/spell-checker */
        'GET /organization-settings/customer-portal',
        {},
        {},
    );

    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const now = new Date();
    const leadTimePreference =
        customerPortalSettings?.customer_portal_settings.default_sourcing_lead_time_preference ?? 0;
    now.setHours(0, 0, 0, 0);
    now.setDate(now.getDate() + leadTimePreference);
    return (
        <>
            {fields.map((field, i) => (
                <Flexbox gap={theme.spacing(2)} alignItems="start" key={field.id ?? i}>
                    <FormItem label={t`Order size`} required={false}>
                        <FieldNumericControlled
                            min={1}
                            max={100000000}
                            control={control}
                            name={makePath(i, 'quantity')}
                            required={
                                // if hasAtLeastOneOrderScenario, this effectively means you need to supply a quantity
                                // for every scenario.
                                // if !hasAtLeastOneOrderScenario, the first scenario is optional, but once you've added
                                // one, you need to supply a quantity for every scenario.
                                hasAtLeastOneOrderScenario || fields.length > 1
                            }
                            isInteger={true}
                            FieldProps={{ style: { width: 200 }, placeholder: t`Order size` }}
                        />
                    </FormItem>
                    <FormItem label={t`Desired date (ASAP if left empty)`} style={{ flex: 1 }}>
                        <FieldDateControlled
                            control={control}
                            name={makePath(i, 'date')}
                            inFuture={true}
                            minDate={now.toISOString()}
                        />
                    </FormItem>

                    {shouldShowDeleteButton && (
                        <TertiaryIconButton style={{ marginTop: theme.spacing(3) }} onClick={() => remove(i)}>
                            <Delete fontSize="inherit" />
                        </TertiaryIconButton>
                    )}
                </Flexbox>
            ))}

            <SecondaryButton
                style={{ whiteSpace: 'nowrap', alignSelf: 'start' }}
                onClick={() => append(newScenario)}
                disabled={fields.length >= maxNumberOfScenarios}
            >
                + {addButtonText}
            </SecondaryButton>
        </>
    );
}
