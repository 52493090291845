import {
    ActivityDTO,
    DriverIdDTO,
    ResourceDetails,
    TimeCalculation,
    TimeCalculationFormulaDetailsType,
    TimeComponents,
} from '@luminovo/http-client';
import { RecursivePartial, assertUnreachable } from '../../../utils/typingUtils';
import { getIsPerPanelFromTimeCalculation } from '../shared/manufacturingUtils';
import {
    AddActivityFormInputs,
    AddActivityFormInputsBatchTimeCalculationFixed,
    AddActivityFormInputsBatchTimeCalculationLinear,
    AddActivityFormInputsProjectTimeCalculationFixed,
    AddActivityFormInputsProjectTimeCalculationLinear,
    AddActivityFormInputsTimeCalculationFormula,
    AddActivityFormInputsUnitTimeCalculationFixed,
    AddActivityFormInputsUnitTimeCalculationLinear,
} from './AddActivityFormTypes';
import {
    defaultBatchFixedTimeCalculation,
    defaultFixedUnitTimeCalculation,
    defaultFormulaBatchTimeCalculation,
    defaultLinearBatchTimeCalculation,
    defaultLinearProjectTimeCalculation,
    defaultLinearUnitTimeCalculation,
    defaultProjectFixedTimeCalculation,
} from './addActivityFormFunctions';

export const getSelectedBatchCalculationType = (timeCalculation: TimeCalculation) => {
    switch (timeCalculation.level) {
        case 'Unit':
            return timeCalculation.details.batch_time_components?.type;
        case 'Batch':
            return timeCalculation.details.batch_time_components?.type;
        case 'Project':
            return timeCalculation.details.project_time_components?.type;
        default:
            assertUnreachable(timeCalculation);
    }
};

function isTimeCalculationFormulaDetailsType(
    timeComponents: TimeComponents,
): timeComponents is TimeCalculationFormulaDetailsType {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return (timeComponents as TimeCalculationFormulaDetailsType)?.details?.formula !== undefined;
}

export const convertFixedUnitCalculationToForm = (
    timeCalculation: TimeCalculation,
): AddActivityFormInputsUnitTimeCalculationFixed => {
    switch (timeCalculation.level) {
        case 'Unit':
            if (isTimeCalculationFormulaDetailsType(timeCalculation.details.unit_time_components)) {
                return defaultFixedUnitTimeCalculation;
            } else {
                return {
                    fixedUnitTime: {
                        duration: {
                            amount: timeCalculation.details.unit_time_components?.details.fixed_time?.amount,
                            unit: timeCalculation.details.unit_time_components?.details.fixed_time?.unit ?? 'Seconds',
                        },
                    },
                };
            }
        case 'Batch':
        case 'Project':
            return defaultFixedUnitTimeCalculation;
    }
};

export const convertFixedProjectCalculationToForm = (
    timeCalculation: TimeCalculation,
): AddActivityFormInputsProjectTimeCalculationFixed => {
    switch (timeCalculation.level) {
        case 'Project':
            if (isTimeCalculationFormulaDetailsType(timeCalculation.details.project_time_components)) {
                return defaultProjectFixedTimeCalculation;
            } else {
                return {
                    fixedProjectTime: {
                        duration: {
                            amount: timeCalculation.details.project_time_components?.details.fixed_time?.amount,
                            unit:
                                timeCalculation.details.project_time_components?.details.fixed_time?.unit ?? 'Seconds',
                        },
                    },
                };
            }
        case 'Batch':
        case 'Unit':
            return defaultProjectFixedTimeCalculation;
    }
};

export const convertFixedBatchCalculationToForm = (
    timeCalculation: TimeCalculation,
): AddActivityFormInputsBatchTimeCalculationFixed => {
    switch (timeCalculation.level) {
        case 'Unit':
            const batchTimeComponents = timeCalculation.details.batch_time_components;

            if (!batchTimeComponents || isTimeCalculationFormulaDetailsType(batchTimeComponents)) {
                return defaultBatchFixedTimeCalculation;
            }
            return {
                fixedBatchTime: {
                    duration: {
                        amount: batchTimeComponents.details.fixed_time?.amount,
                        unit: batchTimeComponents.details.fixed_time?.unit ?? 'Seconds',
                    },
                },
            };

        case 'Batch':
            if (isTimeCalculationFormulaDetailsType(timeCalculation.details.batch_time_components)) {
                return defaultBatchFixedTimeCalculation;
            }
            return {
                fixedBatchTime: {
                    duration: {
                        amount: timeCalculation.details.batch_time_components?.details.fixed_time?.amount,
                        unit: timeCalculation.details.batch_time_components?.details.fixed_time?.unit ?? 'Seconds',
                    },
                },
            };
        case 'Project':
            if (isTimeCalculationFormulaDetailsType(timeCalculation.details.project_time_components)) {
                return defaultBatchFixedTimeCalculation;
            }
            return {
                fixedBatchTime: {
                    duration: {
                        amount: timeCalculation.details.project_time_components?.details.fixed_time?.amount,
                        unit: timeCalculation.details.project_time_components?.details.fixed_time?.unit ?? 'Seconds',
                    },
                },
            };
    }
};

export const extractFormulaBatchType = (
    timeCalculation: TimeCalculation,
): AddActivityFormInputsTimeCalculationFormula | undefined => {
    switch (timeCalculation.level) {
        case 'Unit':
            const batchTimeComponents = timeCalculation.details.batch_time_components;

            if (batchTimeComponents && isTimeCalculationFormulaDetailsType(batchTimeComponents)) {
                return {
                    formula: batchTimeComponents.details.formula,
                    unit: batchTimeComponents.details.unit,
                };
            } else {
                return defaultFormulaBatchTimeCalculation;
            }

        case 'Batch':
            if (isTimeCalculationFormulaDetailsType(timeCalculation.details.batch_time_components)) {
                return {
                    formula: timeCalculation.details.batch_time_components.details.formula,
                    unit: timeCalculation.details.batch_time_components.details.unit,
                };
            } else {
                return defaultFormulaBatchTimeCalculation;
            }
        case 'Project':
            if (isTimeCalculationFormulaDetailsType(timeCalculation.details.project_time_components)) {
                return {
                    formula: timeCalculation.details.project_time_components.details.formula,
                    unit: timeCalculation.details.project_time_components.details.unit,
                };
            } else {
                return defaultFormulaBatchTimeCalculation;
            }
        default:
            assertUnreachable(timeCalculation);
    }
};

export const extractFormulaUnitType = (
    timeCalculation: TimeCalculation,
): AddActivityFormInputsTimeCalculationFormula | undefined => {
    const level = timeCalculation.level;

    switch (level) {
        case 'Unit':
            if (isTimeCalculationFormulaDetailsType(timeCalculation.details.unit_time_components)) {
                return {
                    formula: timeCalculation.details.unit_time_components.details.formula,
                    unit: timeCalculation.details.unit_time_components.details.unit,
                };
            } else {
                return defaultFormulaBatchTimeCalculation;
            }

        case 'Batch':
        case 'Project':
            return defaultFormulaBatchTimeCalculation;
        default:
            assertUnreachable(level);
    }
};

export const extractFormulaProjectType = (
    timeCalculation: TimeCalculation,
): AddActivityFormInputsTimeCalculationFormula | undefined => {
    const level = timeCalculation.level;

    switch (level) {
        case 'Project':
            if (isTimeCalculationFormulaDetailsType(timeCalculation.details.project_time_components)) {
                return {
                    formula: timeCalculation.details.project_time_components.details.formula,
                    unit: timeCalculation.details.project_time_components.details.unit,
                };
            } else {
                return defaultFormulaBatchTimeCalculation;
            }

        case 'Batch':
        case 'Unit':
            return defaultFormulaBatchTimeCalculation;
        default:
            assertUnreachable(level);
    }
};

export const convertLinearUnitCalculationToForm = (
    timeCalculation: TimeCalculation,
    driverId: DriverIdDTO,
): AddActivityFormInputsUnitTimeCalculationLinear => {
    if (timeCalculation.level !== 'Unit') {
        throw Error(`Unexpected level:  ${timeCalculation.level}`);
    }

    if (timeCalculation.details.unit_time_components?.type !== 'Linear') {
        throw Error(`Unexpected type:  ${timeCalculation.details.unit_time_components?.type}`);
    }

    return {
        driverId: driverId,
        fixedUnitTime: {
            duration: {
                amount: timeCalculation.details.unit_time_components.details.fixed_time?.amount,
                unit: timeCalculation.details.unit_time_components.details.fixed_time?.unit ?? 'Seconds',
            },
        },
        variableUnitTime: {
            duration: {
                amount: timeCalculation.details.unit_time_components.details.variable_time?.variable_unit?.amount,
                unit:
                    timeCalculation.details.unit_time_components.details.variable_time?.variable_unit?.unit ??
                    'Seconds',
            },
        },
    };
};

export const convertLinearProjectCalculationToForm = (
    timeCalculation: TimeCalculation,
    driverId: DriverIdDTO,
): AddActivityFormInputsProjectTimeCalculationLinear => {
    if (timeCalculation.level !== 'Project') {
        throw Error(`Unexpected level:  ${timeCalculation.level}`);
    }

    if (timeCalculation.details.project_time_components?.type !== 'Linear') {
        throw Error(`Unexpected type:  ${timeCalculation.details.project_time_components?.type}`);
    }

    return {
        driverId: driverId,
        fixedProjectTime: {
            duration: {
                amount: timeCalculation.details.project_time_components.details.fixed_time?.amount,
                unit: timeCalculation.details.project_time_components.details.fixed_time?.unit ?? 'Seconds',
            },
        },
        variableProjectTime: {
            duration: {
                amount: timeCalculation.details.project_time_components.details.variable_time?.variable_unit?.amount,
                unit:
                    timeCalculation.details.project_time_components.details.variable_time?.variable_unit?.unit ??
                    'Seconds',
            },
        },
    };
};

const convertLinearBatchCalculationToForm = (
    timeCalculation: TimeCalculation,
    driverId: DriverIdDTO,
): AddActivityFormInputsBatchTimeCalculationLinear => {
    switch (timeCalculation.level) {
        case 'Batch':
            if (timeCalculation.details.batch_time_components?.type !== 'Linear') {
                throw Error(`Unexpected type:  ${timeCalculation.details.batch_time_components?.type}`);
            }

            return {
                driverId: driverId,
                fixedBatchTime: {
                    duration: {
                        amount: timeCalculation.details.batch_time_components.details.fixed_time?.amount,
                        unit: timeCalculation.details.batch_time_components.details.fixed_time?.unit ?? 'Seconds',
                    },
                },
                variableBatchTime: {
                    duration: {
                        amount: timeCalculation.details.batch_time_components.details.variable_time?.variable_unit
                            ?.amount,
                        unit:
                            timeCalculation.details.batch_time_components.details.variable_time?.variable_unit?.unit ??
                            'Seconds',
                    },
                },
            };
        case 'Project':
            if (timeCalculation.details.project_time_components?.type !== 'Linear') {
                throw Error(`Unexpected type:  ${timeCalculation.details.project_time_components?.type}`);
            }

            return {
                driverId: driverId,
                fixedBatchTime: {
                    duration: {
                        amount: timeCalculation.details.project_time_components.details.fixed_time?.amount,
                        unit: timeCalculation.details.project_time_components.details.fixed_time?.unit ?? 'Seconds',
                    },
                },
                variableBatchTime: {
                    duration: {
                        amount: timeCalculation.details.project_time_components.details.variable_time?.variable_unit
                            ?.amount,
                        unit:
                            timeCalculation.details.project_time_components.details.variable_time?.variable_unit
                                ?.unit ?? 'Seconds',
                    },
                },
            };
        case 'Unit':
            if (timeCalculation.details.batch_time_components?.type !== 'Linear') {
                throw Error(`Unexpected type:  ${timeCalculation.details.batch_time_components?.type}`);
            }

            return {
                driverId: driverId,
                fixedBatchTime: {
                    duration: {
                        amount: timeCalculation.details.batch_time_components.details.fixed_time?.amount,
                        unit: timeCalculation.details.batch_time_components.details.fixed_time?.unit ?? 'Seconds',
                    },
                },
                variableBatchTime: {
                    duration: {
                        amount: timeCalculation.details.batch_time_components.details.variable_time?.variable_unit
                            ?.amount,
                        unit:
                            timeCalculation.details.batch_time_components.details.variable_time?.variable_unit?.unit ??
                            'Seconds',
                    },
                },
            };
    }
};
export const extractLinearUnitTimeCalculationType = (timeCalculation: TimeCalculation) => {
    switch (timeCalculation.level) {
        case 'Batch':
        case 'Project':
            return defaultLinearUnitTimeCalculation;
        case 'Unit':
            if (
                timeCalculation.details.unit_time_components?.type === 'Linear' &&
                timeCalculation.details.unit_time_components?.details.variable_time?.driver
            ) {
                return convertLinearUnitCalculationToForm(
                    timeCalculation,
                    timeCalculation.details.unit_time_components?.details.variable_time?.driver,
                );
            }
            return defaultLinearUnitTimeCalculation;

        default:
            assertUnreachable(timeCalculation);
    }
};

export const extractLinearProjectTimeCalculationType = (timeCalculation: TimeCalculation) => {
    switch (timeCalculation.level) {
        case 'Project':
            if (
                timeCalculation.details.project_time_components?.type === 'Linear' &&
                timeCalculation.details.project_time_components?.details.variable_time?.driver
            ) {
                return convertLinearProjectCalculationToForm(
                    timeCalculation,
                    timeCalculation.details.project_time_components?.details.variable_time?.driver,
                );
            }
            return defaultLinearProjectTimeCalculation;
        case 'Batch':
        case 'Unit':
            return defaultLinearProjectTimeCalculation;
        default:
            assertUnreachable(timeCalculation);
    }
};

export const extractLinearBatchTimeCalculationType = (timeCalculation: TimeCalculation) => {
    switch (timeCalculation.level) {
        case 'Batch':
            if (
                timeCalculation.details.batch_time_components?.type === 'Linear' &&
                timeCalculation.details.batch_time_components.details.variable_time?.driver
            ) {
                return convertLinearBatchCalculationToForm(
                    timeCalculation,
                    timeCalculation.details.batch_time_components.details.variable_time?.driver,
                );
            }
            return defaultLinearBatchTimeCalculation;
        case 'Project':
            if (
                timeCalculation.details.project_time_components?.type === 'Linear' &&
                timeCalculation.details.project_time_components.details.variable_time?.driver
            ) {
                return convertLinearBatchCalculationToForm(
                    timeCalculation,
                    timeCalculation.details.project_time_components.details.variable_time?.driver,
                );
            }
            return defaultLinearBatchTimeCalculation;
        case 'Unit':
            if (
                timeCalculation.details.batch_time_components?.type === 'Linear' &&
                timeCalculation.details.batch_time_components.details.variable_time?.driver
            ) {
                return convertLinearBatchCalculationToForm(
                    timeCalculation,
                    timeCalculation.details.batch_time_components.details.variable_time?.driver,
                );
            }
            return defaultLinearBatchTimeCalculation;

        default:
            assertUnreachable(timeCalculation);
    }
};

export const convertActivityToForm = (
    activity: ActivityDTO,
    resourcesDetails: ResourceDetails[],
): RecursivePartial<AddActivityFormInputs> => {
    const selectedUnitCalculationType =
        activity.time_calculation.level === 'Unit'
            ? activity.time_calculation.details.unit_time_components?.type
            : undefined;
    const selectedBatchCalculationType = getSelectedBatchCalculationType(activity.time_calculation) ?? 'None';
    const selectedProjectCalculationType =
        activity.time_calculation.level === 'Project'
            ? activity.time_calculation.details.project_time_components?.type
            : undefined;

    return {
        activity: {
            category: activity.category,
            description: activity.description ?? undefined,
            isActivityPerPanel: getIsPerPanelFromTimeCalculation(activity.time_calculation),
            level: activity.time_calculation.level,
            name: activity.name,
            process: activity.process,
            internalActivityNumber: activity.internal_number ?? undefined,
        },
        resourcesDetails: resourcesDetails.map((r) => ({
            resourceId: r.resource_id,
            multiplier: r.multiplier,
        })),

        selectedBatchCalculationType,
        selectedUnitCalculationType,
        selectedProjectCalculationType,
        fixedUnitCalculationType: convertFixedUnitCalculationToForm(activity.time_calculation),
        linearUnitCalculationType: extractLinearUnitTimeCalculationType(activity.time_calculation),
        fixedBatchCalculationType: convertFixedBatchCalculationToForm(activity.time_calculation),
        linearBatchCalculationType: extractLinearBatchTimeCalculationType(activity.time_calculation),
        formulaBatchCalculationType: extractFormulaBatchType(activity.time_calculation),
        formulaUnitCalculationType: extractFormulaUnitType(activity.time_calculation),
        formulaProjectCalculationType: extractFormulaProjectType(activity.time_calculation),
        fixedProjectCalculationType: convertFixedProjectCalculationToForm(activity.time_calculation),
        linearProjectCalculationType: extractLinearProjectTimeCalculationType(activity.time_calculation),
    };
};
