import { isPresent } from '@luminovo/commons';
import React, { useRef, useState } from 'react';
import {
    body,
    bodySemiBold,
    bodySmall,
    bodySmallSemiBold,
    caption,
    code,
    colorSystem,
    h1,
    h2,
    h3,
    h4,
    h5,
} from '../../theme';
import { Tooltip } from '../Tooltip';

export type FontVariant =
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'body'
    | 'body-semibold'
    | 'body-small'
    | 'body-small-semibold'
    | 'caption'
    | 'code'
    | 'inherit';

export interface Props extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement> {
    variant?: FontVariant;
    color?: string;
    /**
     * If true, adds a tooltip title to the ellipsis text to reveal what the full text is past the ellipsis.
     */
    showEllipsis?: boolean;

    /**
     * If true, adds tabular numeral features to the text. This should be used for numbers.
     */
    tabularNums?: boolean;
}

const variantStyles: Record<FontVariant, React.CSSProperties> = {
    h1: {
        ...h1,
        color: colorSystem.neutral[8],
    },
    h2: {
        ...h2,
        color: colorSystem.neutral[8],
    },
    h3: {
        ...h3,
        color: colorSystem.neutral[8],
    },
    h4: {
        ...h4,
        color: colorSystem.neutral[8],
    },
    h5: {
        ...h5,
        color: colorSystem.neutral[8],
    },
    body: {
        ...body,
        color: colorSystem.neutral[9],
    },
    'body-semibold': {
        ...bodySemiBold,
        color: colorSystem.neutral[9],
    },
    'body-small': {
        ...bodySmall,
        color: colorSystem.neutral[9],
    },
    'body-small-semibold': {
        ...bodySmallSemiBold,
        color: colorSystem.neutral[9],
    },
    caption: {
        ...caption,
        color: colorSystem.neutral[9],
    },
    code: {
        ...code,
        color: colorSystem.blue[6],
    },
    inherit: {},
};

export const ellipsisDefaultStyle: React.CSSProperties = {
    overflow: 'hidden',
    overflowWrap: 'break-word',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    wordBreak: 'break-all',
};

const TextWithEllipsisTooltip = ({ style, ...props }: Props) => {
    const [hasOverflowingChildren, setHasOverflowingChildren] = useState(false);
    const [text, setText] = useState<string | null>(null);
    const ref = useRef<HTMLDivElement>(null);

    const handleMouseEnter = React.useCallback(() => {
        if (!isPresent(ref.current)) {
            return;
        }

        const { offsetWidth, scrollWidth, textContent } = ref.current;
        const isOverflowing = offsetWidth < scrollWidth;

        setHasOverflowingChildren(isOverflowing);
        setText((prevText) => (prevText !== textContent ? textContent : prevText));
    }, [setHasOverflowingChildren, setText]);

    if (hasOverflowingChildren && isPresent(text)) {
        return (
            <Tooltip title={text} placement="right">
                <span
                    ref={ref}
                    style={{ ...ellipsisDefaultStyle, ...style }}
                    onMouseEnter={handleMouseEnter}
                    {...props}
                />
            </Tooltip>
        );
    }

    return <span ref={ref} style={{ ...ellipsisDefaultStyle, ...style }} onMouseEnter={handleMouseEnter} {...props} />;
};

// Helper function to merge font feature settings
function mergeFontFeatures(existing: string, addition: string): string {
    if (!existing) return addition;
    // Remove quotes around the entire string if they exist
    const cleaned = existing.replace(/^["'](.+)["']$/, '$1');
    return `"${cleaned}, ${addition.replace(/["']/g, '')}"`;
}

export const Text = React.forwardRef<HTMLSpanElement, Props>(function Text(
    { variant = 'body', color = variantStyles[variant].color, showEllipsis, tabularNums, style, ...props },
    ref,
) {
    const Wrapper = showEllipsis ? TextWithEllipsisTooltip : 'span';

    // Get base font feature settings from variant style
    const baseFeatureSettings = variantStyles[variant].fontFeatureSettings || '';

    // Merge tnum if enabled
    const fontFeatureSettings = tabularNums ? mergeFontFeatures(baseFeatureSettings, '"tnum" 1') : baseFeatureSettings;

    return <Wrapper ref={ref} style={{ ...variantStyles[variant], fontFeatureSettings, color, ...style }} {...props} />;
});
