import { groupBy } from '@luminovo/commons';
import { MenuItem, Tooltip } from '@luminovo/design-system';
import { unparse } from 'papaparse';
import { useUniversalImporter } from '../../context';
import { findBestAlternatives } from './findBestAlternatives';

export function MenuItemDownloadIssues(): JSX.Element {
    const { state } = useUniversalImporter();

    return (
        <Tooltip title="Download a CSV file with a list of all issues found in the file." placement="right">
            <MenuItem
                label="Download errors and warnings"
                onClick={() => {
                    const cellsWithIssues = state.importerTable
                        ?.getRows()
                        .filter((row) => !row.skip)
                        .flatMap((row) => row.cells)
                        .filter((cell) => cell.status.status !== 'done');

                    const groupedCells = Object.entries(
                        groupBy(cellsWithIssues, (cell) => [cell.text, cell.status.status, cell.field.label].join('|')),
                    );

                    const rows = groupedCells.map(([key, cells]) => {
                        const cell = cells[0];
                        const count = cells.length;
                        const [suggestion1, suggestion2, suggestion3, suggestion4, suggestion5, ...suggestions] =
                            findBestAlternatives(cell, cell.status.alternatives ?? [], { limit: 20 });

                        return {
                            Status: cell.status.status,
                            Column: cell.field.label,
                            'Cell text': cell.text,
                            'Error message': cell.status.message,
                            Count: count,
                            'suggestion 1': suggestion1?.id ?? '',
                            'suggestion 2': suggestion2?.id ?? '',
                            'suggestion 3': suggestion3?.id ?? '',
                            'suggestion 4': suggestion4?.id ?? '',
                            'suggestion 5': suggestion5?.id ?? '',
                            'other suggestions': suggestions.map((s) => s.id).join(', '),
                        };
                    });

                    const csv = unparse(rows ?? [], { header: true });

                    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
                    const url = URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = 'issues.csv';
                    a.click();
                }}
            />
        </Tooltip>
    );
}
