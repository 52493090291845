import * as r from 'runtypes';

export type EmailType = r.Static<typeof EmailTypeRuntype>;
export const EmailTypeRuntype = r.Union(r.Literal('QuoteRequest'));

export interface EmailTemplateDTO extends r.Static<typeof EmailTemplateDTORuntype> {}
export const EmailTemplateDTORuntype = r.Record({
    id: r.String,
    name: r.String,
    email_type: EmailTypeRuntype,
    suppliers: r.Array(r.String),
    is_default: r.Boolean,
    subject: r.String,
    body: r.String,
    updated_at: r.String,
    ccs: r.Array(r.String),
});
