import { t } from '@lingui/macro';
import { FieldTextControlled, Flexbox, FormItem } from '@luminovo/design-system';
import { useFormContext } from 'react-hook-form';
import { FormContainer } from '../../../../components/formLayouts/FormContainer';
import { SubmitButton } from '../../../../components/formLayouts/SubmitButton';

export type NegotiationFormState = {
    name: string;
};

export function NegotiationForm({
    defaultValues,
    onSubmit,
}: {
    defaultValues: NegotiationFormState;
    onSubmit: (formValues: NegotiationFormState) => void;
}) {
    return (
        <FormContainer defaultValues={defaultValues} onSubmit={onSubmit}>
            <Flexbox flexDirection={'column'} gap={20} paddingTop={'12px'}>
                <FormItemName />
                <Flexbox width={'100%'} justifyContent={'space-between'} flexDirection={'row-reverse'}>
                    <SubmitButton />
                </Flexbox>
            </Flexbox>
        </FormContainer>
    );
}

function FormItemName() {
    const { control } = useFormContext<NegotiationFormState>();

    return (
        <FormItem
            label={t`Name`}
            description={t`The name of the negotiation. Use a meaningful name to identify the negotiation.`}
            required
        >
            <FieldTextControlled
                name={'name'}
                control={control}
                required
                FieldProps={{
                    placeholder: t`Enter negotiation name`,
                    style: { width: '100%' },
                }}
            />
        </FormItem>
    );
}
