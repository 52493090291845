import { getToken } from '@luminovo/auth';
import { CustomerRfqUpdateDTO, http } from '@luminovo/http-client';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDebugErrorHandler } from '../../../resources/http/debugErrorHandler';
import { httpQueryKey } from '../../../resources/http/httpQueryKey';

export function useMutationUpdateRfqAsCustomer({ rfqId }: { rfqId: string }) {
    const onError = useDebugErrorHandler();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (update: CustomerRfqUpdateDTO) => {
            const token = getToken();

            await http(
                'PATCH /rfqs/:rfqId',
                {
                    pathParams: { rfqId },
                    requestBody: {
                        type: 'Customer',
                        data: update,
                    },
                },
                token,
            );
        },
        onError,
        onSuccess: () => {
            return Promise.all([
                queryClient.invalidateQueries({
                    queryKey: httpQueryKey('GET /order-management/order-selection-options'),
                }),
                queryClient.invalidateQueries({
                    queryKey: httpQueryKey('GET /order-management/orders'),
                }),
                queryClient.invalidateQueries({
                    queryKey: httpQueryKey('GET /rfqs/:rfqId', { pathParams: { rfqId } }),
                }),
            ]);
        },
    });
}
