import { t } from '@lingui/macro';
import { formatRelativeTime, formatToLongDate } from '@luminovo/commons';
import {
    AvatarIcon,
    createColumnHelper,
    Message,
    PrimaryButton,
    TanStackTable,
    Text,
    Tooltip,
    useTanStackTable,
} from '@luminovo/design-system';
import { NegotiationDTO } from '@luminovo/http-client';
import { Box } from '@mui/material';
import React from 'react';
import { useHistory } from 'react-router';
import { LayoutCard } from '../../../../components/LayoutCard';
import { PageLayoutCollapsibleSidebar } from '../../../../components/PageLayoutCollapsibleSidebar';
import { route, UrlParams } from '../../../../utils/routes';
import { ButtonDeleteNegotiation } from '../../../Negotiations/components/ButtonDeleteNegotiation';
import { ButtonEditNegotiation } from '../../../Negotiations/components/ButtonEditNegotiation';
import { useNegotiations } from '../../../Negotiations/hooks/negotiationHandlers';
import { NavigationSidebarSourcing } from '../NavigationSidebarSourcing';
import { useCreateNegotiationDialog } from './useCreateNegotiationDialog';

const columnHelper = createColumnHelper<NegotiationDTO>();

type SharedContext = {
    rfqId: string;
};

const columns = [
    columnHelper.text('name', { size: 100, label: () => t`Name` }),
    columnHelper.date('created_at', {
        size: 100,
        label: () => t`Created at`,
        cell: (item) => (
            <Tooltip title={formatToLongDate(item.getValue())}>
                <Text variant={'inherit'}>{formatRelativeTime(item.getValue())}</Text>
            </Tooltip>
        ),
    }),
    columnHelper.enum('created_by', {
        size: 40,
        label: () => t`Created by`,
        getOptionLabel(option) {
            return `${option.first_name} ${option.last_name}`;
        },
        getOptionKey: (option) => option.id,
        cell({ row }) {
            const user = row.original.created_by;
            if (!user) {
                return t`Unknown`;
            }

            return (
                <Tooltip title={`${user.first_name} ${user.last_name} ${user.email}`}>
                    <AvatarIcon
                        size="small"
                        user={{
                            firstName: user.first_name,
                            lastName: user.last_name,
                            email: user.email,
                        }}
                    />
                </Tooltip>
            );
        },
    }),

    columnHelper.action({
        id: 'actions',
        size: 100,
        align: 'right',
        cell: ({ row }) => (
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
                <ButtonEditNegotiation negotiationId={row.original.id} />
                <ButtonDeleteNegotiation href={route('/negotiations')} negotiationId={row.original.id} />
            </Box>
        ),
    }),
];

function useTableNegotiations({ rfqId }: { rfqId: string }) {
    const history = useHistory();
    const { data: unfilteredData } = useNegotiations();
    const data = React.useMemo(
        () => unfilteredData?.filter((negotiation) => negotiation.rfq_id === rfqId),
        [unfilteredData, rfqId],
    );

    return useTanStackTable({
        data,
        columns,
        onRowClick(row) {
            history.push(
                route('/rfqs/:rfqId/sourcing/negotiations/:negotiationId/line-items', {
                    rfqId,
                    negotiationId: row.original.id,
                }),
            );
        },
        sharedContext: { rfqId },
    });
}

function ActionButtons({ sharedContext }: { sharedContext: SharedContext }) {
    const { rfqId } = sharedContext;
    const { openDialog } = useCreateNegotiationDialog({ rfqId });
    return <PrimaryButton size="medium" onClick={openDialog}>{`Create negotiation`}</PrimaryButton>;
}

export default function SourcingNegotiationsPage({ pathParams }: UrlParams<'/rfqs/:rfqId/sourcing/negotiations'>) {
    const { rfqId } = pathParams;
    const { table } = useTableNegotiations({ rfqId });

    return (
        <PageLayoutCollapsibleSidebar
            sidebar={<NavigationSidebarSourcing rfqId={pathParams.rfqId} />}
            layoutVariant="fullWidth"
        >
            <LayoutCard title={t`Sourcing negotiations`} style={{ height: '100%' }}>
                <Message
                    size="small"
                    attention="high"
                    variant="red"
                    title={`Dev mode`}
                    message={`This page will be removed in the future, the negotiations will be created in the overview page.`}
                />
                <Box height={'calc(100% - 48px)'}>
                    <TanStackTable table={table} ActionButton={ActionButtons} />;
                </Box>
            </LayoutCard>
        </PageLayoutCollapsibleSidebar>
    );
}
