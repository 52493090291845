import { t } from '@lingui/macro';
import { colorSystem, Dialog, DialogContent, DialogTitle } from '@luminovo/design-system';
import {
    RegionsEnum,
    SupplierAndStockLocationDTO,
    SupplierAndStockLocationPostDTO,
    SupplierPartType,
    SupplierPreference,
    SupplierType,
} from '@luminovo/http-client';
import { useDialogContext } from '../../../../components/contexts/ModalContext';
import { useHttpMutation } from '../../../../resources/mutation/useHttpMutation';
import { useGlobalCurrency } from '../../../../resources/organizationSettings/currencySettingsHandler';
import { SupplierForm, SupplierFormState } from './SupplierForm';

export function useDialogAddSupplierAndStockLocation({
    onSuccessCallback,
    disableSupplierPreferenceField,
}: {
    onSuccessCallback: (createdValue: SupplierAndStockLocationDTO) => void;
    disableSupplierPreferenceField: boolean;
}) {
    const { setDialog, closeDialog } = useDialogContext();
    const { preferredCurrency } = useGlobalCurrency();

    const { mutateAsync } = useHttpMutation('POST /suppliers-and-stock-locations', {
        snackbarMessage: t`Supplier created`,
        onSuccess: (response) => {
            onSuccessCallback(response.data);
            closeDialog();
        },
    });

    const onSubmit = async (formValues: SupplierFormState) => {
        const requestBody: SupplierAndStockLocationPostDTO = {
            name: formValues.name,
            stock_location: formValues.stockLocation,
            supplier_number: Boolean(formValues.supplierNumber) ? formValues.supplierNumber : null,
            supplier_preference: formValues.supplierPreference,
            shipping_time_in_days: formValues.shippingTimeInDays ?? null,
            supplier_type: formValues.supplierType,
            supplier_part_type: formValues.supplierPartType,
            /* eslint-disable-next-line camelcase */
            default_currency: formValues.defaultCurrency,
        };
        await mutateAsync({ requestBody });
    };
    return {
        openDialog: (defaultValues?: SupplierFormState) => {
            setDialog(
                <Dialog
                    open={true}
                    maxWidth={'sm'}
                    fullWidth={true}
                    transitionDuration={0}
                    onClose={() => closeDialog()}
                >
                    <DialogTitle
                        title={t`Add supplier`}
                        handleClose={() => closeDialog()}
                        style={{ borderBottom: `1px solid ${colorSystem.neutral[2]}` }}
                    />
                    <DialogContent>
                        <SupplierForm
                            defaultValues={
                                defaultValues ?? {
                                    name: '',
                                    stockLocation: RegionsEnum.Unknown,
                                    supplierNumber: '',
                                    supplierPreference: SupplierPreference.Approved,
                                    supplierType: SupplierType.Distributor,
                                    supplierPartType: SupplierPartType.OffTheShelf,
                                    defaultCurrency: preferredCurrency,
                                }
                            }
                            onSubmit={onSubmit}
                            options={{ mode: 'create', disableSupplierPreferenceField }}
                        />
                    </DialogContent>
                </Dialog>,
            );
        },
    };
}
