import { t, Trans } from '@lingui/macro';
import { compareByString, formatDays, formatDecimal, isPresent, transEnum } from '@luminovo/commons';
import {
    colorSystem,
    createColumnHelper,
    Flexbox,
    SecondaryButton,
    StatusChip,
    Tag,
    TanStackTable,
    Text,
    Tooltip,
    useTanStackTable,
} from '@luminovo/design-system';
import {
    SupplierAndStockLocationDTO,
    SupplierContactDTO,
    SupplierPartType,
    SupplierPreference,
    SupplierTag,
} from '@luminovo/http-client';
import {
    formatSupplierAndStockLocationDTO,
    hasSupplierTag,
    supplierOriginTranslations,
    supplierPartTypeTranslations,
    supplierPreferenceTranslations,
    supplierTypeTranslations,
} from '@luminovo/sourcing-core';
import { Add, PersonRounded } from '@mui/icons-material';
import { Box } from '@mui/material';
import React from 'react';
import { useHistory } from 'react-router';
import { LayoutCard } from '../../components/LayoutCard';
import { PageLayout } from '../../components/PageLayout';
import {
    useGlobalApprovedSupplierAndStockLocations,
    useGlobalPreferredSupplierAndStockLocations,
    useNonExcludedSupplierAndStockLocations,
} from '../../resources/supplierAndStockLocation/supplierAndStockLocationHandler';
import { useSupplierContacts } from '../../resources/supplierContact/supplierContactHandler';
import { route } from '../../utils/routes';
import { ButtonAddSupplier } from './components/ButtonAddSupplier';
import { ButtonAddSupplierContacts } from './components/ButtonAddSupplierContacts';
import { ButtonEditSupplier } from './components/ButtonEditSupplier';

type SupplierItem = {
    supplierAndStockLocation: SupplierAndStockLocationDTO;
    preference: SupplierPreference;
    supplierContacts: SupplierContactDTO[];
};

function extractSupplierPreference(
    supplier: SupplierAndStockLocationDTO,
    approvedSuppliers: SupplierAndStockLocationDTO[],
    preferredSuppliers: SupplierAndStockLocationDTO[],
) {
    if (isPresent(preferredSuppliers) && preferredSuppliers.some((ps) => ps.id === supplier.id)) {
        return 'Preferred';
    }
    if (isPresent(approvedSuppliers) && approvedSuppliers.some((as) => as.id === supplier.id)) {
        return 'Approved';
    }
    return 'NotApproved';
}

const ActionButton: React.FunctionComponent = () => {
    return (
        <Flexbox alignItems="center" gap={4}>
            <ButtonAddSupplier />
            <SecondaryButton
                startIcon={<Add />}
                size="medium"
                href={route('/supplier/supplier-contacts-importer/bulk')}
            >
                <Trans>Add supplier contacts</Trans>
            </SecondaryButton>
        </Flexbox>
    );
};

const columnHelper = createColumnHelper<SupplierItem>();

const columns = [
    columnHelper.text((row) => formatSupplierAndStockLocationDTO(row.supplierAndStockLocation), {
        id: 'supplier.name',
        size: 250,
        label: () => t`Supplier name`,
    }),

    columnHelper.text('supplierAndStockLocation.supplier_number', {
        size: 100,
        label: () => t`Supplier number`,
        cell: (item) => item.getValue() ?? '-',
    }),

    columnHelper.enum('preference', {
        size: 160,
        label: () => t`Preference`,
        options: [SupplierPreference.Preferred, SupplierPreference.Approved, SupplierPreference.NotApproved],
        getOptionLabel: (option) => transEnum(option, supplierPreferenceTranslations),
        cell: (item) => {
            switch (item.getValue()) {
                case 'Preferred':
                    return <StatusChip color={'primary'} label={t`Preferred`} />;
                case 'Approved':
                    return <StatusChip color={'green'} label={t`Approved`} />;
                case 'NotApproved':
                    return <StatusChip color={'neutral'} label={t`Non-approved`} />;
            }
        },
        quickFilters: [
            {
                label: () => t`Preferred or approved`,
                value: [SupplierPreference.Preferred, SupplierPreference.Approved],
            },
        ],
    }),
    columnHelper.enum('supplierAndStockLocation.supplier.supplier_part_type', {
        size: 160,
        label: () => t`Part type`,
        options: [SupplierPartType.OffTheShelf, SupplierPartType.Custom, SupplierPartType.Pcb],
        getOptionLabel: (option) => transEnum(option, supplierPartTypeTranslations),
        cell: (item) => (
            <Tag attention="low" color={'neutral'} label={transEnum(item.getValue(), supplierPartTypeTranslations)} />
        ),
    }),
    columnHelper.enum('supplierAndStockLocation.supplier.origin', {
        size: 80,
        label: () => t`Origin`,
        getOptionLabel: (option) => transEnum(option, supplierOriginTranslations),
        cell: (item) => (
            <Tag attention="low" color={'neutral'} label={transEnum(item.getValue(), supplierOriginTranslations)} />
        ),
    }),
    columnHelper.enum((row) => hasSupplierTag(row.supplierAndStockLocation, SupplierTag.QuotePartner), {
        id: 'quotePartner',
        size: 80,
        label: () => t`Quote partner`,
        initialVisibility: false,
        description: () =>
            t`Quote partner are pre-qualified distributors that are not connected via API, but easy to request quotes`,
        options: [true, false],
        getOptionLabel: (option) => (option ? t`Yes` : t`No`),
        cell: (item) => (item.getValue() ? <Tag attention="low" color={'teal'} label={t`Quote partner`} /> : null),
        quickFilters: [
            {
                label: () => t`Quote partner`,
                value: [true],
            },
        ],
    }),
    columnHelper.enum('supplierAndStockLocation.supplier.supplier_type', {
        size: 160,
        label: () => t`Supplier type`,
        getOptionLabel: (option) => transEnum(option, supplierTypeTranslations),
        cell: (item) => (
            <Tag attention="low" color={'neutral'} label={transEnum(item.getValue(), supplierTypeTranslations)} />
        ),
    }),
    columnHelper.text((row) => row.supplierContacts.map((c) => `${c.first_name} ${c.last_name} ${c.email}`).join(' '), {
        id: 'supplierContacts',
        size: 100,
        label: () => t`Contacts`,
        enableGlobalFilter: false,
        enableSorting: false,
        cell: ({ row }) => (
            <Tooltip
                variant={'white'}
                title={
                    <>
                        {row.original.supplierContacts.length === 0 && (
                            <Text variant="body-small">{t`No supplier contacts`}</Text>
                        )}
                        {row.original.supplierContacts.length > 0 && (
                            <Flexbox flexDirection={'column'} gap={4}>
                                <Text variant="body-small">{t`Supplier contacts`}</Text>
                                <ul style={{ margin: 0, paddingLeft: 18, maxHeight: 200, overflowY: 'auto' }}>
                                    {row.original.supplierContacts.map((p) => (
                                        <li key={p.id}>{`${p.first_name} ${p.last_name}`}</li>
                                    ))}
                                </ul>
                            </Flexbox>
                        )}
                    </>
                }
            >
                <span>
                    <Flexbox gap={4} alignItems={'center'}>
                        <PersonRounded fontSize={'small'} style={{ color: colorSystem.neutral[4] }} />
                        {formatDecimal(row.original.supplierContacts.length)}
                    </Flexbox>
                </span>
            </Tooltip>
        ),
    }),
    columnHelper.action({
        id: 'actionMenu',
        size: 80,
        cell: ({ row }) => (
            <Flexbox gap={4}>
                <ButtonEditSupplier supplier={row.original.supplierAndStockLocation} />
                <ButtonAddSupplierContacts supplierId={row.original.supplierAndStockLocation.supplier.id} />
            </Flexbox>
        ),
    }),
    columnHelper.number('supplierAndStockLocation.shipping_time_in_days', {
        id: 'shippingTime',
        size: 80,
        label: () => t`Shipping time`,
        cell: (item) => {
            const value = item.getValue();
            return value ? formatDays(value) : '-';
        },
        initialVisibility: false,
    }),
];

function SupplierOverviewTable() {
    const history = useHistory();
    const { data: suppliers } = useNonExcludedSupplierAndStockLocations();
    const { data: supplierContacts = [] } = useSupplierContacts();
    const { data: approvedSuppliers = [] } = useGlobalApprovedSupplierAndStockLocations();
    const { data: preferredSuppliers = [] } = useGlobalPreferredSupplierAndStockLocations();

    const data = React.useMemo(
        () =>
            suppliers
                ?.map((supplierAndStockLocation) => {
                    return {
                        supplierAndStockLocation,
                        preference: extractSupplierPreference(
                            supplierAndStockLocation,
                            approvedSuppliers,
                            preferredSuppliers,
                        ),
                        supplierContacts: supplierContacts.filter(
                            (c) => c.supplier === supplierAndStockLocation.supplier.id,
                        ),
                    };
                })
                .sort((a, b) =>
                    compareByString(
                        formatSupplierAndStockLocationDTO(a.supplierAndStockLocation),
                        formatSupplierAndStockLocationDTO(b.supplierAndStockLocation),
                    ),
                ),
        [suppliers, supplierContacts, approvedSuppliers, preferredSuppliers],
    );

    const { table } = useTanStackTable({
        columns,
        data,
        enableColumnOrdering: true,
        enableColumnHiding: true,
        enablePersistentColumnFilters: true,
        enablePersistentGlobalFilter: true,
        enableExcelExport: true,
        initialState: {
            columnFilters: [
                {
                    id: 'preference',
                    value: [SupplierPreference.Preferred, SupplierPreference.Approved],
                },
            ],
        },
        onRowClick: (item) => {
            history.push(
                route('/supplier/:supplierAndStockLocationId', {
                    supplierAndStockLocationId: item.original.supplierAndStockLocation.id,
                }),
            );
        },
    });

    return <TanStackTable table={table} size={'medium'} ActionButton={ActionButton} />;
}

export function SupplierManagementPage() {
    return (
        <PageLayout style={{ background: colorSystem.neutral[1] }} layout={'fragment'}>
            <LayoutCard
                title={t`Suppliers`}
                style={{ marginTop: '24px', marginLeft: '32px', marginRight: '32px', height: '100%' }}
            >
                <Box height={'calc(100% - 48px)'}>
                    <SupplierOverviewTable />
                </Box>
            </LayoutCard>
        </PageLayout>
    );
}
