import { Flexbox, Link, Tag } from '@luminovo/design-system';
import { OtsFullPart, PreferenceStatusEnum, RfqContext } from '@luminovo/http-client';
import { formatPart } from '@luminovo/sourcing-core';
import { DescriptionOutlined, OpenInNewRounded } from '@mui/icons-material';
import { useOtsPartDetailsDrawer } from '../../../../components/partSpecificationCards/OTSPart/useOtsPartDetailsDrawer';
import { route } from '../../../../utils/routes';

export const RenderOtsLinkedPart = ({ part, rfqContext }: { part: OtsFullPart; rfqContext: RfqContext }) => {
    const { openDrawer } = useOtsPartDetailsDrawer();
    return (
        <Flexbox key={part.id} justifyContent={'space-between'}>
            <Flexbox gap="8px">
                <Link
                    attention="high"
                    onClick={(e) => {
                        e.stopPropagation();
                        openDrawer({ part: { id: part.id }, rfqContext });
                    }}
                >
                    {formatPart(part)}
                </Link>
                <Flexbox gap="4px">
                    <RenderOtsPartDatasheet datasheetUrl={part.datasheet_url} />
                    <Link
                        attention="high"
                        href={route('/parts/details/:partId', { partId: part.id })}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <span>
                            <OpenInNewRounded style={{ marginBottom: '-2px' }} />
                        </span>
                    </Link>
                </Flexbox>
            </Flexbox>
            {part.preference_status && (
                <Tag
                    attention="low"
                    label={part.preference_status.toString()}
                    color={part.preference_status === PreferenceStatusEnum.Preferred ? 'violet' : 'red'}
                />
            )}
        </Flexbox>
    );
};

const RenderOtsPartDatasheet = ({ datasheetUrl }: { datasheetUrl: string | null }) => {
    if (!datasheetUrl) {
        return null;
    }
    return (
        <Link
            attention="high"
            onClick={(e) => {
                e.stopPropagation();
                window.open(datasheetUrl, '_blank', 'noopener,noreferrer');
            }}
        >
            <span>
                <DescriptionOutlined style={{ marginBottom: '-2px' }} />
            </span>
        </Link>
    );
};
