import { Currency, MonetaryValue } from '@luminovo/commons';

const zero = {
    amount: '0',
    currency: Currency.EUR,
};

function getScalar(a: MonetaryValue): number {
    return parseFloat(a.amount);
}

function isZero(a: MonetaryValue): boolean {
    return parseFloat(a.amount) === 0;
}

function isNegative(a: MonetaryValue): boolean {
    return getScalar(a) < 0;
}

export function compareMonetaryValues(a: MonetaryValue, b: MonetaryValue): number {
    return getScalar(a) - getScalar(b);
}

function add(a: MonetaryValue, b: MonetaryValue): MonetaryValue {
    if (isZero(a)) {
        return b;
    }
    if (isZero(b)) {
        return a;
    }
    assertCurrenciesMatch(a, b);
    const aNumber = getScalar(a);
    const bNumber = getScalar(b);
    return {
        amount: (aNumber + bNumber).toString(),
        currency: a.currency,
    };
}

function scale(a: MonetaryValue, b: number): MonetaryValue {
    const aValue = getScalar(a);
    return {
        amount: (aValue * b).toString(),
        currency: a.currency,
    };
}

function subtract(a: MonetaryValue, b: MonetaryValue): MonetaryValue {
    if (isZero(a)) {
        return b;
    }
    if (isZero(b)) {
        return a;
    }
    assertCurrenciesMatch(a, b);
    const aNumber = getScalar(a);
    const bNumber = getScalar(b);
    return {
        amount: (aNumber - bNumber).toString(),
        currency: a.currency,
    };
}

function sum(values: MonetaryValue[]): MonetaryValue {
    if (values.length === 0) {
        return zero;
    }
    return values.reduce((acc, value) => add(acc, value), zero);
}

function relativeDifference(baseValue: MonetaryValue | null, comparedValue: MonetaryValue | null): number | null {
    if (!baseValue || !comparedValue) {
        return null;
    }
    const comparedAmount = getScalar(comparedValue);
    const baseAmount = getScalar(baseValue);
    return 1 - comparedAmount / baseAmount;
}

export const monetaryValue = {
    add,
    scale,
    subtract,
    sum,
    zero,
    isZero,
    isNegative,
    getScalar,
    relativeDifference,
};

function assertCurrenciesMatch(a: MonetaryValue, b: MonetaryValue): void {
    if (a.currency !== b.currency) {
        // eslint-disable-next-line no-console
        console.warn(`Currencies do not match: ${a.currency} and ${b.currency}`);
    }
}
