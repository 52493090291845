import { colorSystem } from '@luminovo/design-system';
import { StatusDTO } from '@luminovo/http-client';
import { Circle } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import {
    GreenCheckCircleOutlineRoundedIcon,
    RedHighlightOffRoundedIcon,
    YellowHelpOutlineRoundedIcon,
} from '../../../components/Icons/icons';

interface StatusWithInformationError {
    type: 'Error';
}
interface StatusZeroFixedTime {
    type: 'ZeroFixedTime';
}

interface StatusWithInformationWarning {
    type: 'Warning';
    incompleteComponentDesignators?: string[];
}

interface StatusWithInformationOk {
    type: 'Ok';
}

export type StatusWithInformation =
    | StatusWithInformationError
    | StatusWithInformationWarning
    | StatusWithInformationOk
    | StatusZeroFixedTime;

const statusStyles: Record<
    StatusDTO,
    {
        fill: string;
        stroke: string;
        component: JSX.Element;
    }
> = {
    Ok: {
        fill: colorSystem.green[4],
        stroke: colorSystem.green[5],
        component: <GreenCheckCircleOutlineRoundedIcon />,
    },
    Warning: {
        fill: colorSystem.yellow[4],
        stroke: colorSystem.yellow[5],
        component: <YellowHelpOutlineRoundedIcon />,
    },
    Error: {
        fill: colorSystem.red[4],
        stroke: colorSystem.red[5],
        component: <RedHighlightOffRoundedIcon />,
    },
    ZeroFixedTime: {
        fill: colorSystem.yellow[4],
        stroke: colorSystem.yellow[5],
        component: <YellowHelpOutlineRoundedIcon />,
    },
};

export type DriverStatusIconType = 'circle' | 'status';

export const DriverStatusIconWithTooltip = ({
    status,
    iconType,
    description,
    tooltipText,
}: {
    status: StatusDTO;
    iconType: DriverStatusIconType;
    tooltipText?: string;
    description: string;
}) => {
    return (
        <Tooltip title={tooltipText ?? description} arrow placement={'top'}>
            {iconType === 'circle' ? (
                // The material-ui tooltip needs to have a child component that can hold a ref, hence the box component around the SVG icons https://github.com/mui/material-ui/issues/24118
                <span>
                    <Circle
                        style={{
                            color: statusStyles[status].fill,
                            stroke: statusStyles[status].stroke,
                            strokeWidth: '4px',
                            fontSize: '12px',
                        }}
                    />
                </span>
            ) : (
                <span>{statusStyles[status].component}</span>
            )}
        </Tooltip>
    );
};
