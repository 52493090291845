import * as r from 'runtypes';
import { runtypeFromEnum } from '../../utils/typingUtils';
import { QuantityUnitRuntype } from '../backendTypes';

export enum IssueNameEnum {
    NO_DESIGNATOR_AND_QUANTITY = 'NO_DESIGNATOR_AND_QUANTITY',
    NO_DESIGNATOR_COLUMN = 'NO_DESIGNATOR_COLUMN',
    NO_QUANTITY_COLUMN = 'NO_QUANTITY_COLUMN',
    NO_MPN_COLUMN = 'NO_MPN_COLUMN',
    NO_HEADER_ROW = 'NO_HEADER_ROW',
    NO_DNP_COLUMN = 'NO_DNP_COLUMN',
    NO_GENERIC_PART_SUGGESTION_BECAUSE_INDUCTOR_DESIGNATOR = 'NO_GENERIC_PART_SUGGESTION_BECAUSE_INDUCTOR_DESIGNATOR',
    DESIGNATOR_AND_QUANTITY_DO_NOT_MATCH_TEMPLATE = 'DESIGNATOR_AND_QUANTITY_DO_NOT_MATCH_TEMPLATE',

    AMBIGUOUS_QUANTITIES_TEMPLATE = 'AMBIGUOUS_QUANTITIES_TEMPLATE',
    MANY_QUANTITIES_NO_DESIGNATOR = 'MANY_QUANTITIES_NO_DESIGNATOR',
    NO_QUANTITY_MANY_DESIGNATORS = 'NO_QUANTITY_MANY_DESIGNATORS',
    MULTIPLE_QUANTITY_DESIGNATOR_MATCHES = 'MULTIPLE_QUANTITY_DESIGNATOR_MATCHES',
    COULD_NOT_RESOLVE_DESIGNATORS_AND_QUANTITY = 'COULD_NOT_RESOLVE_DESIGNATORS_AND_QUANTITY',
    UNKNOWN_QUANTITY_UNIT = 'UNKNOWN_QUANTITY_UNIT',

    CONTRADICTING_DNP_INFORMATION_TEMPLATE = 'CONTRADICTING_DNP_INFORMATION_TEMPLATE',
    CONTRADICTING_DNP_AND_QUANTITY = 'CONTRADICTING_DNP_AND_QUANTITY',
    MANUFACTURER_DOES_NOT_MATCH_PART_OPTIONS_ALTERNATIVE_NAME = 'MANUFACTURER_DOES_NOT_MATCH_PART_OPTIONS_ALTERNATIVE_NAME',
    MANUFACTURER_DOES_NOT_MATCH_PART_OPTIONS = 'MANUFACTURER_DOES_NOT_MATCH_PART_OPTIONS',
    MISSING_REQUIRED_TECHNICAL_PARAMETER = 'MISSING_REQUIRED_TECHNICAL_PARAMETER',

    ASSUME_TOTAL_QUANTITY_PROVIDED_TEMPLATE = 'ASSUME_TOTAL_QUANTITY_PROVIDED_TEMPLATE',

    IGNORED_MANUFACTURER = 'IGNORED_MANUFACTURER',
    ASSUME_SMT_IMPERIAL_SYSTEM = 'ASSUME_SMT_IMPERIAL_SYSTEM',
    PARTIAL_MPN_MATCH = 'PARTIAL_MPN_MATCH',
    AGGREGATED_LINES_SAME_DESIGNATORS = 'AGGREGATED_LINES_SAME_DESIGNATORS',
    AGGREGATED_LINES_SAME_CONTENT = 'AGGREGATED_LINES_SAME_CONTENT',
    ASSUME_CERAMIC_CAPACITOR = 'ASSUME_CERAMIC_CAPACITOR',
    ONLY_CERAMIC_CAPACITORS_SUPPORTED = 'ONLY_CERAMIC_CAPACITORS_SUPPORTED',
    GENERIC_PART_SUGGESTION_BECAUSE_MANUFACTURER_GIVEN = 'GENERIC_PART_SUGGESTION_BECAUSE_MANUFACTURER_GIVEN',
    MULTI_LEVEL_BOM_STRUCTURE_DETECTED = 'MULTI_LEVEL_BOM_STRUCTURE_DETECTED',
    EMPTY_EXCEL_LINES = 'EMPTY_EXCEL_LINES',

    // Frontend issues
    NUMBER_OF_LINES = 'NUMBER_OF_LINES',
    NO_BOM_ITEM_FOUND = 'NO_BOM_ITEM_FOUND',
    DUPLICATE_DESIGNATORS_FOUND = 'DUPLICATE_DESIGNATORS_FOUND',
    NO_PART_OPTIONS = 'NO_PART_OPTIONS',
}

export enum Level {
    Error = 'Error',
    Warning = 'Warning',
    Notification = 'Notification',
}
const LevelRuntype = runtypeFromEnum(Level);

export enum MissingRequirementsEnum {
    resistance = 'resistance',
    capacitance = 'capacitance',
    package = 'package',
    dielectric = 'dielectric',
}

/* eslint-disable camelcase */

export type BomImporterIssue = r.Static<typeof BomImporterIssueRuntype>;
export const BomImporterIssueRuntype = r
    .Record({
        level: LevelRuntype,
    })
    .And(
        r.Union(
            r.Record({
                name: r.Literal(IssueNameEnum.MULTIPLE_QUANTITY_DESIGNATOR_MATCHES),
            }),
            r.Record({
                name: r.Literal(IssueNameEnum.NO_QUANTITY_COLUMN),
            }),
            r.Record({
                name: r.Literal(IssueNameEnum.MULTI_LEVEL_BOM_STRUCTURE_DETECTED),
            }),
            r.Record({
                name: r.Literal(IssueNameEnum.NO_DESIGNATOR_AND_QUANTITY),
            }),
            r.Record({
                name: r.Literal(IssueNameEnum.NO_DNP_COLUMN),
            }),
            r.Record({
                name: r.Literal(IssueNameEnum.NO_GENERIC_PART_SUGGESTION_BECAUSE_INDUCTOR_DESIGNATOR),
            }),
            r.Record({
                name: r.Literal(IssueNameEnum.MANY_QUANTITIES_NO_DESIGNATOR),
            }),
            r.Record({
                name: r.Literal(IssueNameEnum.MANUFACTURER_DOES_NOT_MATCH_PART_OPTIONS),
                content: r.Record({
                    manufacturer_primary_name: r.String,
                }),
            }),
            r.Record({
                name: r.Literal(IssueNameEnum.MANUFACTURER_DOES_NOT_MATCH_PART_OPTIONS_ALTERNATIVE_NAME),
                content: r.Record({
                    manufacturer_matched_alternative_name: r.String,
                    manufacturer_primary_name: r.String,
                }),
            }),
            r.Record({
                name: r.Literal(IssueNameEnum.MISSING_REQUIRED_TECHNICAL_PARAMETER),
                content: r.Record({
                    generic_part_type: r.String,
                    missing_requirement: r.Array(runtypeFromEnum(MissingRequirementsEnum)),
                }),
            }),
            r.Record({
                name: r.Literal(IssueNameEnum.CONTRADICTING_DNP_INFORMATION_TEMPLATE),
                content: r.Record({
                    extracted_dnps: r.Array(r.Boolean),
                }),
            }),
            r.Record({
                name: r.Literal(IssueNameEnum.CONTRADICTING_DNP_AND_QUANTITY),
            }),
            r.Record({
                name: r.Literal(IssueNameEnum.AMBIGUOUS_QUANTITIES_TEMPLATE),
                content: r.Record({
                    quantities: r.Array(r.Number),
                }),
            }),
            r.Record({
                name: r.Literal(IssueNameEnum.DESIGNATOR_AND_QUANTITY_DO_NOT_MATCH_TEMPLATE),
                content: r.Record({
                    num_designators: r.Number,
                    quantity: r.Number,
                }),
            }),
            r.Record({
                name: r.Literal(IssueNameEnum.IGNORED_MANUFACTURER),
                content: r.Record({
                    provided_manufacturer: r.String,
                }),
            }),
            r.Record({
                name: r.Literal(IssueNameEnum.ASSUME_SMT_IMPERIAL_SYSTEM),
                content: r.Record({
                    package_str: r.String,
                }),
            }),
            r.Record({
                name: r.Literal(IssueNameEnum.ASSUME_TOTAL_QUANTITY_PROVIDED_TEMPLATE),
                content: r.Record({
                    quantity: r.Number,
                    num_designators: r.Number,
                    unit: QuantityUnitRuntype,
                }),
            }),
            r.Record({
                name: r.Literal(IssueNameEnum.DUPLICATE_DESIGNATORS_FOUND),
                content: r.Record({
                    duplicatedDesignators: r.Array(r.String),
                }),
            }),
            r.Record({
                name: r.Literal(IssueNameEnum.PARTIAL_MPN_MATCH),
            }),
            r.Record({
                name: r.Literal(IssueNameEnum.NUMBER_OF_LINES),
                content: r.Record({
                    numberOfLines: r.Number,
                }),
            }),
            r.Record({
                name: r.Literal(IssueNameEnum.AGGREGATED_LINES_SAME_DESIGNATORS),
            }),
            r.Record({
                name: r.Literal(IssueNameEnum.AGGREGATED_LINES_SAME_CONTENT),
            }),
            r.Record({
                name: r.Literal(IssueNameEnum.ASSUME_CERAMIC_CAPACITOR),
            }),
            r.Record({
                name: r.Literal(IssueNameEnum.ONLY_CERAMIC_CAPACITORS_SUPPORTED),
            }),
            r.Record({
                name: r.Literal(IssueNameEnum.UNKNOWN_QUANTITY_UNIT),
            }),
            r.Record({
                name: r.Literal(IssueNameEnum.NO_PART_OPTIONS),
            }),
            r.Record({
                name: r.Literal(IssueNameEnum.NO_HEADER_ROW),
            }),
            r.Record({
                name: r.Literal(IssueNameEnum.NO_MPN_COLUMN),
            }),
            r.Record({
                name: r.Literal(IssueNameEnum.NO_DESIGNATOR_COLUMN),
            }),
            r.Record({
                name: r.Literal(IssueNameEnum.COULD_NOT_RESOLVE_DESIGNATORS_AND_QUANTITY),
            }),
            r.Record({
                name: r.Literal(IssueNameEnum.NO_BOM_ITEM_FOUND),
            }),
            r.Record({
                name: r.Literal(IssueNameEnum.NO_QUANTITY_MANY_DESIGNATORS),
            }),
            r.Record({
                name: r.Literal(IssueNameEnum.GENERIC_PART_SUGGESTION_BECAUSE_MANUFACTURER_GIVEN),
            }),
            r.Record({
                name: r.Literal(IssueNameEnum.EMPTY_EXCEL_LINES),
            }),
        ),
    );
