import { t } from '@lingui/macro';
import { assertUnreachable, formatDecimal, formatToLongDate } from '@luminovo/commons';
import { INTER, Text, colorSystem } from '@luminovo/design-system';
import { SolutionPreferenceDTO, SourcingPreferenceOptions, SourcingScenarioDTO } from '@luminovo/http-client';
import { Box } from '@mui/material';
import { getTotalQuantity } from '../../../../../resources/sourcingScenario/getTotalQuantity';
import { useSourcingScenarios } from '../../../../../resources/sourcingScenario/sourcingScenarioHandlers';
import { assertPresent } from '../../../../../utils/assertPresent';

export function SourcingScenarioTags({ sourcingScenarioIds }: { sourcingScenarioIds: string[] }) {
    const { data: sourcingScenarios = [] } = useSourcingScenarios(sourcingScenarioIds);

    return (
        <>
            {sourcingScenarios.map((s) => {
                return <SourcingScenarioTag key={s.id} sourcingScenario={s} />;
            })}
        </>
    );
}

function SourcingScenarioTag({ sourcingScenario }: { sourcingScenario: SourcingScenarioDTO }) {
    const total = getTotalQuantity(sourcingScenario);

    return (
        <Box
            display={'inline-flex'}
            alignItems="center"
            style={{ background: colorSystem.neutral[1], borderRadius: 16, padding: '4px 12px', gap: 16 }}
        >
            <Box
                borderRadius="16px"
                style={{
                    background: colorSystem.neutral[3],
                    padding: '4px 3px',
                    margin: '-4px -12px',
                    minWidth: 20,
                    display: 'grid',
                    placeItems: 'center',
                }}
            >
                <Text
                    style={{
                        fontWeight: 600,
                        fontFamily: INTER,
                        color: colorSystem.neutral[9],
                        fontSize: 12,
                        lineHeight: '16px',
                    }}
                >
                    {formatDecimal(total)}
                </Text>
            </Box>

            <Text style={{ color: colorSystem.neutral[7], fontSize: 12, lineHeight: '16px' }}>
                {formatSolutionPreference(sourcingScenario.solution_preference)}
            </Text>
        </Box>
    );
}

function formatSolutionPreference(solutionPref: SolutionPreferenceDTO): string {
    if (solutionPref.lead_time_preference.type === SourcingPreferenceOptions.Fastest) {
        return t`ASAP`;
    }
    if (solutionPref.lead_time_preference.type === SourcingPreferenceOptions.BestPrice) {
        return t`Best price`;
    }
    if (solutionPref.lead_time_preference.type === SourcingPreferenceOptions.BestPriceBy) {
        const date = assertPresent(solutionPref.lead_time_preference.target);
        return t`Best price by ${formatToLongDate(date)}`;
    }
    assertUnreachable(solutionPref.lead_time_preference.type);
}
