import { t } from '@lingui/macro';
import type { ParsedValue, Parser } from '../types';

export interface Opts {
    /**
     * List of suppliers to compare the supplier number against.
     *
     * Use this snippet to convert a `SupplierAndStockLocationDTO` to a `ParsedValue<string> & { isSystem: boolean }`:
     *
     * ```
     * function toParsedValue(supplier: SupplierAndStockLocationDTO): ParsedValue<string> & { isSystem: boolean } {
     *     return {
     *         id: supplier.supplier_number ?? '',
     *         label: String(supplier.supplier_number),
     *         description: supplier.supplier.name,
     *         existing: true,
     *         isSystem: supplier.tags.some((s) => s.tag === SupplierTag.System),
     *     };
     * }
     * ```
     */
    suppliers: Array<ParsedValue<string> & { isSystem: boolean }>;
    /**
     * If true, an error will be shown if the supplier number is not in the list of suppliers given
     */
    errorOnUnknown?: boolean;
    /**
     * If true, a warning will be shown if the supplier number is currently linked to a system supplier.
     */
    warnOnSystemSupplier?: boolean;
}

export const parseSupplierNumber: Parser<string, Opts> = async (
    [cell],
    { suppliers, errorOnUnknown = false, warnOnSystemSupplier = true },
    field,
) => {
    cell = cell.trim();
    const alternatives = suppliers;

    if (cell.length === 0 && field.defaultValue) {
        // Honestly, I'm not sure if we will ever support default values for supplier numbers
        // but I'm keeping it here for consistency and completeness
        return {
            status: 'done',
            value: field.defaultValue,
            message: t`Using default value`,
            alternatives,
        };
    }

    if (cell.length === 0) {
        return {
            status: 'error',
            message: t`The supplier number is required`,
            alternatives,
        };
    }

    const found = alternatives.find((alt) => {
        const supplierNumberMatch = alt.label && alt.label === cell;
        return supplierNumberMatch;
    });

    if (found && found.isSystem && warnOnSystemSupplier) {
        return {
            status: 'warning',
            value: found,
            existing: false,
            alternatives,
            message: t`This supplier number is currently linked to the system supplier ${found.description}. Importing this row will insert a new supplier and unlink the number from the system supplier.`,
        };
    }

    if (found) {
        return {
            status: 'done',
            value: found,
            existing: true,
            alternatives,
        };
    }

    if (!found && errorOnUnknown) {
        return {
            status: 'error',
            message: t`Supplier number is not part of existing suppliers`,
            alternatives,
        };
    }

    return {
        value: {
            id: cell,
            label: cell,
            description: t`NEW`,
            existing: false,
        },
        status: 'done',
        alternatives,
    };
};
