import { t } from '@lingui/macro';
import {
    ManufacturingDatabaseActions,
    ManufacturingDatabasePage,
} from '../../../components/CostingTemplates/ManufacturingDatabasePage';
import ActivitiesDatabaseTable from './ActivitiesDatabaseTable';

const ActivitiesViewPage = (): JSX.Element => (
    <ManufacturingDatabasePage
        DatabaseActionsComponent={ManufacturingDatabaseActions}
        DatabaseTableComponent={ActivitiesDatabaseTable}
        buttonText={t`Add activity`}
        path="/manufacturing/add-activity"
        descriptionText={t`Activities are all production processes that involve internal resources (e.g. Pick and Place or AOI Inspection).`}
    />
);

export default ActivitiesViewPage;
