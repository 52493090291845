import { FontVariant, Link, Text } from '@luminovo/design-system';
import { PartLite, PartLiteTypes } from '@luminovo/http-client';
import { useCustomComponentDrawer } from '../../../components/partSpecificationCards/CustomComponent/useCustomComponentDrawer';
import { useCustomPartCardDrawer } from '../../../components/partSpecificationCards/CustomPart/CustomPartCard';
import { useGenericPartDetailsDrawer } from '../../../components/partSpecificationCards/GenericPart/GenericPartCardDetails';
import { useIpnDetailsDrawer } from '../../../components/partSpecificationCards/Ipn/useIpnDetailsDrawer';
import { useOtsPartDetailsDrawer } from '../../../components/partSpecificationCards/OTSPart/useOtsPartDetailsDrawer';
import { formatPartLite } from '../model/formatPartLite';

export function PartLiteLabel({
    part,
    variant,
    color,
}: {
    part?: PartLite;
    variant?: FontVariant;
    color?: string;
}): JSX.Element {
    const { openDrawer: openOtsPartDrawer } = useOtsPartDetailsDrawer();
    const { openDrawer: openIpnPartDrawer } = useIpnDetailsDrawer();
    const { openDrawer: openGenericPartDrawer } = useGenericPartDetailsDrawer();
    const { openDrawer: openCustomComponentDrawer } = useCustomComponentDrawer();
    const { openDrawer: openCustomPartDrawer } = useCustomPartCardDrawer();

    if (!part) {
        return <Text variant={variant}>-</Text>;
    }

    const attention = color ? 'mui-default' : 'high';

    switch (part.kind) {
        case PartLiteTypes.OffTheShelf:
            return (
                <Link
                    attention={attention}
                    color={color}
                    variant={variant}
                    onClick={(e) => {
                        e.stopPropagation();
                        openOtsPartDrawer({ part: { id: part.id }, rfqContext: { type: 'OutsideRfQ' } });
                    }}
                >
                    {formatPartLite(part)}
                </Link>
            );
        case PartLiteTypes.Ipn:
            return (
                <Link
                    attention={attention}
                    color={color}
                    variant={variant}
                    onClick={(e) => {
                        e.stopPropagation();
                        openIpnPartDrawer({ ipnId: part.id, rfqContext: { type: 'OutsideRfQ' } });
                    }}
                >
                    {formatPartLite(part)}
                </Link>
            );
        case PartLiteTypes.Generic:
            return (
                <Link
                    attention={attention}
                    color={color}
                    variant={variant}
                    onClick={(e) => {
                        e.stopPropagation();
                        openGenericPartDrawer({
                            genericPartId: part.id,
                            isEditEnable: false,
                            rfqContext: { type: 'OutsideRfQ' },
                        });
                    }}
                >
                    {formatPartLite(part)}
                </Link>
            );
        case PartLiteTypes.RawSpecification:
        case PartLiteTypes.Unknown:
            return (
                <Text variant={variant} color={color}>
                    {formatPartLite(part)}
                </Text>
            );
        case PartLiteTypes.Custom:
            return (
                <Link
                    attention={attention}
                    color={color}
                    variant={variant}
                    onClick={(e) => {
                        e.stopPropagation();
                        openCustomPartDrawer({ partId: part.id });
                    }}
                >
                    {formatPartLite(part)}
                </Link>
            );
        case PartLiteTypes.CustomComponent:
            return (
                <Link
                    attention={color ? 'mui-default' : 'high'}
                    color={color}
                    variant={variant}
                    onClick={(e) => {
                        e.stopPropagation();
                        openCustomComponentDrawer({ componentId: part.id, rfqContext: { type: 'OutsideRfQ' } });
                    }}
                >
                    {formatPartLite(part)}
                </Link>
            );
    }
}
