import { t, Trans } from '@lingui/macro';
import { getToken } from '@luminovo/auth';
import { throwErrorUnlessProduction } from '@luminovo/commons';
import { colorSystem, Flexbox, SplitButton, Tag, TertiaryButton, Text } from '@luminovo/design-system';
import { BomExportTemplate, http } from '@luminovo/http-client';
import { Menu, styled } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { useMutation } from '@tanstack/react-query';
import fileDownload from 'js-file-download';
import { useHistory } from 'react-router-dom';
import { RoutesHistoryStateProps } from '../const';
import { useSuspenseHttpQuery } from '../resources/http/useHttpQuery';
import { route } from '../utils/routes';

export const ExportBomSplitButton = ({ assemblyId }: { assemblyId: string }) => {
    const { mutateAsync: downloadBomFile, isLoading, templates } = useDownloadExportedBomFile(assemblyId);
    const history = useHistory<RoutesHistoryStateProps>();

    const downloadDefaultTemplate = () => {
        const defaultTemplateId = templates.find((template) => template.is_default)?.id;
        if (defaultTemplateId) {
            downloadBomFile({ templateId: defaultTemplateId });
        }
    };

    const clickTemplate = (templateId: string) => {
        downloadBomFile({ templateId });
    };
    const templateOptions = templates.map((template) => ({
        id: template.id,
        label: <TemplateContent template={template} />,
        onClick: () => clickTemplate(template.id),
        overrides: { MenuItem: TemplateNameMenuItem },
    }));

    const allTemplates = [
        ...templateOptions,
        {
            id: 'viewAllTemplates',
            label: <ViewTemplateLabel />,
            overrides: { MenuItem: ViewTemplatesMenuItem },
            onClick: () => {
                history.push(route('/settings/organization/export-templates'), {
                    previousUrl: history.location.pathname,
                });
            },
        },
    ];
    return (
        <SplitButton
            overrides={{
                button: {
                    size: 'medium',
                },
                menu: {
                    Component: StyledMenu,
                },
            }}
            disabled={isLoading}
            dataTrackingId="exportBOMButton"
            pinnedSelectedOption={{
                id: 'exportDefaultBom',
                onClick: downloadDefaultTemplate,
                label: t`Export BOM`,
            }}
            options={allTemplates}
        />
    );
};

function useDownloadExportedBomFile(assemblyId: string) {
    const token = getToken();
    const { data: templates = [], isLoading: areTemplatesLoading } = useSuspenseHttpQuery(
        'GET /export/bom/templates',
        {},
        { select: (data) => data.items },
    );
    const {
        mutateAsync,
        isPending: isLoading,
        isError,
    } = useMutation({
        mutationFn: async ({ templateId }: { templateId?: string }) => {
            return http('GET /export/bom-xlsx', { queryParams: { assemblyId, templateId } }, token);
        },
        onSuccess: async (response) => {
            fileDownload(response.blob, response.fileName);
        },
        onError: (error) => throwErrorUnlessProduction(error),
    });

    return {
        templates,
        mutateAsync,
        isLoading: isLoading || areTemplatesLoading,
        isError,
    };
}

const StyledMenu = styled(Menu)({
    '& .MuiList-padding': {
        padding: 0,
    },
});

const TemplateNameMenuItem = styled(MenuItem)({
    height: '32px',
    minWidth: '250px',
});

const ViewTemplatesMenuItem = styled(TemplateNameMenuItem)({
    backgroundColor: colorSystem.neutral[1],
});

const ViewTemplateLabel = () => {
    return (
        <TertiaryButton size="small" style={{ paddingLeft: 0 }}>
            <Trans>View templates</Trans>
        </TertiaryButton>
    );
};

const TemplateContent = ({ template }: { template: BomExportTemplate }) => {
    return (
        <Flexbox
            style={{
                width: '100%',
            }}
            alignItems={'center'}
            justifyContent={'space-between'}
        >
            <div>
                <Text>{template.name}</Text>
            </div>
            <div>
                {template.is_default && (
                    <Tag
                        attention={'low'}
                        color={'primary'}
                        label={t`Default`}
                        style={{
                            marginLeft: '8px',
                        }}
                    />
                )}
            </div>
        </Flexbox>
    );
};
